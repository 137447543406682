import { createMuiTheme } from '@material-ui/core'
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides'

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P]
}

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#01a3f8',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#ffffff',
      },
    },
    MuiInputBase: {
      input: {
        cursor: 'pointer',
      },
    },
    MuiSvgIcon: {
      root: {
        height: '18px',
        weight: '18px',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '0px',
          content: 'none',
        },
        '&:after': {
          borderBottom: '0px',
        },
      },
    },
  },
})
