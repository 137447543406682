import React from 'react'

const PreviewsIcon = () => (
  <svg className="icon" width="22" height="22" viewBox="0 0 22 22">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-31.000000, -171.000000)">
        <g transform="translate(31.000000, 171.000000)">
          <path d="M20.8006,20.5682 C20.8006,20.6682 20.7196,20.7492 20.6196,20.7492 L2.1816,20.7492 C2.0816,20.7492 2.0006,20.6682 2.0006,20.5682 L2.0006,9.9332 C2.0006,9.8342 2.0816,9.7532 2.1816,9.7532 L20.6196,9.7532 C20.7196,9.7532 20.8006,9.8342 20.8006,9.9332 L20.8006,20.5682 Z M19.5996,1.2322 L20.1596,3.7972 L1.7906,7.8012 L1.2316,5.2362 L19.5996,1.2322 Z M20.6196,8.5522 L3.9816,8.5522 L20.5736,4.9352 L20.5746,4.9342 C21.1326,4.8122 21.4876,4.2582 21.3666,3.7002 L20.7376,0.8162 C20.6146,0.2562 20.0606,-0.0988 19.5036,0.0242 L0.8176,4.0982 C0.5466,4.1572 0.3146,4.3182 0.1646,4.5522 C0.0146,4.7852 -0.0344,5.0632 0.0246,5.3332 L0.6536,8.2162 C0.7256,8.5472 0.9546,8.7972 1.2406,8.9282 C0.9716,9.1802 0.8006,9.5362 0.8006,9.9332 L0.8006,20.5682 C0.8006,21.3292 1.4206,21.9492 2.1816,21.9492 L20.6196,21.9492 C21.3816,21.9492 22.0006,21.3292 22.0006,20.5682 L22.0006,9.9332 C22.0006,9.1722 21.3816,8.5522 20.6196,8.5522 L20.6196,8.5522 Z"></path>
          <polygon points="5.217 14.3141 17.611 14.3141 17.611 13.0141 5.217 13.0141"></polygon>
          <polygon points="5.217 17.4881 17.611 17.4881 17.611 16.1881 5.217 16.1881"></polygon>
        </g>
      </g>
    </g>
  </svg>
)

export default PreviewsIcon
