import React from 'react'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import '../VideoChaptersInformationDialog/VideoChaptersInformationDialog.scss'
import useWorkspace from '../../../state/useWorkspace'
import { WorkspaceType } from '../../../helpers/enum'

type Props = {
  isShowing: boolean
  onClose: () => void
}

const VideoChaptersInformationDialog: React.FC<Props> = ({ isShowing, onClose }) => {
  const { currentWorkspace } = useWorkspace(false)
  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      <div className="video-chapters-information-dialog">
        <h3 className="title">Why?</h3>
        {currentWorkspace?.type === WorkspaceType.User ? (
          <ul className="information-points">
            <li>Shorter videos are easier for you to create and easier for others to watch.</li>
            <li>
              Encourages you to give each chapter a clear focus. E.g. Chapter 1: 'About me', Chapter 2: 'My experience'
              Chapter 3: 'My fit for the job'.
            </li>
            <li>
              Creating videos in chapters makes videos more versatile for use across multiple Previews - E.g. 'About me'
              can be generic and easily re-used across Previews.
            </li>
          </ul>
        ) : (
          <ul className="information-points">
            <li>Shorter videos are easier for you to create and easier for others to watch.</li>
            <li>
              Each chapter should have a clear focus. E.g. Chapter 1: 'About the Business', Chapter 2: 'About the
              Opportunity' Chapter 3: 'Meet the Team'.
            </li>
            <li>
              Creating videos in chapters makes videos more versatile for use across multiple Previews - E.g. 'About the
              Business' can be generic and easily re-used across Previews.
            </li>
          </ul>
        )}
      </div>
    </CloseableDialog>
  )
}

export default VideoChaptersInformationDialog
