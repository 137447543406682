import CancelIcon from 'assets/icons/CancelIcon'
import GetInTouchMessage from 'components/preview/GetInTouchMessage/GetInTouchMessage'
import TemplateCards from 'components/preview/TemplateCards/TemplateCards'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { PreviewCategory, WorkspaceType } from 'helpers/enum'
import { getWorkspaceType } from 'helpers/organization'
import { useBodyColor } from 'helpers/useBodyColor'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import usePreviews from 'state/usePreviews'
import PreviewsAvailablityByCategory from '../PreviewsAvailablityByCategory/PreviewsAvailablityByCategory'
import './CreatePreviewPage.scss'

const CreatePreviewPage = () => {
  const { isBusinessCountAtMaxForFree, isIndividualCountAtMaxForFree, isPreviewCategoryCountLoading } = usePreviews(
    false,
    true
  )
  const { push } = useHistory()
  const isOrganization = getWorkspaceType() === WorkspaceType.Organization

  useBodyColor('cream')

  return (
    <div className="create-preview-page">
      <div className="page-content upper">
        <div className="navigation-buttons">
          <Link to="/">
            <CancelIcon />
          </Link>
        </div>
        <h1>Create a Preview</h1>
        <div className="subtitle">What kind of Preview would you like to create?</div>
        <GetInTouchMessage />
      </div>
      <div className="lower-section-background">
        {isPreviewCategoryCountLoading ? (
          <LoadingSpinner container className="create-preview-page-spinner" />
        ) : (
          <>
            <div className="page-content lower">
              <PreviewsAvailablityByCategory
                category={isOrganization ? PreviewCategory.Business : PreviewCategory.Individual}
                isCategoryAtMaxForFree={isOrganization ? isBusinessCountAtMaxForFree : isIndividualCountAtMaxForFree}
              />
              <TemplateCards
                isInvitational={false}
                templateUsageTypesToDisplay={['any', 'adhoc']}
                previewCategory={isOrganization ? PreviewCategory.Business : PreviewCategory.Individual}
                onTemplateSelected={async id =>
                  push(
                    `/create/template/${id}?category=${
                      isOrganization ? PreviewCategory.Business : PreviewCategory.Individual
                    }`
                  )
                }
              />
              <GetInTouchMessage className="mobile" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CreatePreviewPage
