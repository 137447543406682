import React, { useState, useEffect } from 'react';
import WebFont from 'webfontloader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './FontDropdown.scss';
import { makeStyles } from '@material-ui/core/styles';
import AddedFonts, {addedFonts} from './AddedFonts'


const GOOGLE_FONTS_API_KEY = 'AIzaSyCuiHr7BiShOEiihgsNd_FjogYJTDFMihI';
const GOOGLE_FONTS_URL = `https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_FONTS_API_KEY}`;

const useStyles = makeStyles({
  autoComplete: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      padding:'0px 0px 0px 15px !important',
      '& fieldset': {
        borderRadius: '5px',
      },
      '& input': {
        padding: '0px !important',
        border:'none'
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      top: '2px',
      right:'9px'
    },
  },
});

type Props = {
  onFontChange:(fontName: string,displayName: string,variant: number) => void;
  existingValue:string;
}
type Font = {
  family: string;
  displayName:string;
  variants: string[];
};

const FontDropdown: React.FC<Props> = ({onFontChange,existingValue }) => {
  const [fonts, setFonts] = useState<Font[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedFont, setSelectedFont] = useState<Font | null>(null);
  const classes = useStyles();

  useEffect(() => {
    const fetchFonts = async () => {
      const cachedFonts = localStorage.getItem('googleFonts');

      try {
        const response = await fetch(GOOGLE_FONTS_URL);
        const data = await response.json();
        let modifiedFonts = [...addedFonts];

        for (let font of data.items) {
          if (font.family === "DM Sans") {
            if (font.variants.includes("regular")) {
              modifiedFonts.push({ ...font, displayName: "DM Sans Regular", variants: 400 });
            }
            if (font.variants.includes("600")) {
              modifiedFonts.push({ ...font, displayName: "DM Sans Bold", variants: 600 });
            }
            if (font.variants.includes("500")) {
              modifiedFonts.push({ ...font, displayName: "DM Sans Medium", variants: 500 });
            }
          } else {
            font.displayName = font.family;
            modifiedFonts.push(font);
          }
        }

        // If there's no cached fonts or the cached fonts are different from the fetched data.
        if (!cachedFonts || JSON.stringify(modifiedFonts) !== cachedFonts) {
          localStorage.setItem('googleFonts', JSON.stringify(modifiedFonts));
        }

        setFonts(modifiedFonts);

        const matchedFont = modifiedFonts.find(font => font.displayName === existingValue);
        if (matchedFont) {
          setSelectedFont(matchedFont);
        } else {
          setSelectedFont(null);
        }

        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the fonts", error);
        setLoading(false);
      }
    };


    fetchFonts();

    if (existingValue) {
      WebFont.load({
        google: {
          families: [existingValue]
        }
      });
    }
  }, [existingValue]);

  if (loading) {
    return <div>Loading...</div>;
  }
  const getFontWeightFromDisplayName = (displayName:string) => {
    switch(displayName) {
      case 'DM Sans Bold':
        return 600;
      case 'DM Sans Regular':
        return 400;
      case 'DM Sans Medium':
        return 500;
      default:
        return 0;
    }
  }

  return loading ? <div>Loading...</div> : (
    <>
      <AddedFonts/>
      <Autocomplete
        options={fonts}
        className={classes.autoComplete}
        onInputChange={(event, newInputValue) => {
          const matchedFonts = fonts.filter(font =>
            (font.displayName || font.family).toLowerCase().includes(newInputValue.toLowerCase())
          );
          const exactMatch = matchedFonts.find(font => font.displayName === newInputValue);
          if (exactMatch) {
            setSelectedFont(exactMatch);
            const fontWeight = getFontWeightFromDisplayName(exactMatch.displayName);
            onFontChange(exactMatch.family, exactMatch.displayName, fontWeight);
            WebFont.load({
              google: {
                families: [exactMatch.family]
              }
            });
          }
        }}
        value={selectedFont}
        getOptionLabel={(option) => option.displayName}
        onChange={(event, newValue) => {
          setSelectedFont(newValue);
          if (newValue) {
            const fontWeight = getFontWeightFromDisplayName(newValue.displayName);
            onFontChange(newValue.family, newValue.displayName, fontWeight);
            WebFont.load({
              google: {
                families: [newValue.family]
              }
            });
          } else {
            onFontChange('','',0);
          }
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            variant="outlined"
            style={{ fontFamily: selectedFont ? selectedFont.family : "inherit" }}
          />
        )}
        renderOption={(option) => (
          <div style={{ fontFamily: option.family }}>{option.displayName || option.family}</div>
        )}
      />
    </>
  );
};

export default FontDropdown;
