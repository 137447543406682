import React from 'react'
import './InvitationUpgradeDialog.scss'
import useProduct from '../../../state/useProduct'
import { SubscriptionPlan } from '../../../helpers/enum'
import useSubscription from '../../../state/useSubscription'
import { UpgradeToEnterpriseDialog } from '../UpgradeToEnterpriseDialog/UpgradeToEnterpriseDialog'
import { getSubscription } from '../../../services/subscriptionService'
import { getCurrentPlan } from '../../../helpers/subscription'

type Props = {
  isShowing: boolean
  onClose: () => void
}

export const InvitationUpgradeDialog = function ({ isShowing, onClose }: Props) {
  const { allProducts } = useProduct(true)
  const enterpriseProductId = allProducts
    ? allProducts.find(product => product.name === SubscriptionPlan.Enterprise)?.id
    : undefined

  const currentProduct = allProducts?.find(product => product.name)
  //const priceIndex = currentProduct?.prices.indexOf(price => price.name === planName)
  const { newProduct, newSubscription, changePlan, subscription } = useSubscription(
    enterpriseProductId !== undefined,
    true,
    enterpriseProductId
  )

  return (
    <UpgradeToEnterpriseDialog
      isShowing={isShowing}
      onClose={onClose}
      invitationLimit
      newProduct={newProduct}
      newSubscription={newSubscription}
      changePlan={changePlan}
    />
  )
}
