import React from 'react'

const ShareWithEmailIcon = () => (
  <svg className="icon" width="50px" height="50px" viewBox="0 0 50 50">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-943.000000, -442.000000)">
        <ellipse cx="967.978166" cy="465" rx="20" ry="20" fill="#25262A"></ellipse>
        <path
          d="M975.628073,469.7091 L960.328259,469.7091 C960.184599,469.7091 960.067325,469.591056 960.067325,469.445543 L960.067325,459.567693 L967.688647,466.416484 C967.772204,466.49221 967.876284,466.529331 967.980365,466.529331 C968.085178,466.529331 968.189259,466.491468 968.272816,466.416484 L975.888274,459.566951 L975.888274,469.445543 C975.888274,469.591056 975.771733,469.7091 975.628073,469.7091 L975.628073,469.7091 Z M975.537919,458.6909 L967.980365,465.487721 L960.41768,458.6909 L975.537919,458.6909 Z M975.628073,457.8 L960.328259,457.8 C959.699379,457.8 959.187773,458.318207 959.187773,458.9552 L959.187773,469.445543 C959.187773,470.081793 959.699379,470.6 960.328259,470.6 L975.628073,470.6 C976.256953,470.6 976.768559,470.081793 976.768559,469.445543 L976.768559,458.9552 C976.768559,458.318207 976.256953,457.8 975.628073,457.8 L975.628073,457.8 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </svg>
)

export default ShareWithEmailIcon
