import { Invitation, Preview, PreviewLite } from 'state/types'

export const getPublishedPreviewTitles = (preview: Preview) => {
  const fields = getFieldsWithLookupTags(preview)
  let title = preview.titlePattern && fields ? doSubstitution(preview.titlePattern, fields) : undefined
  let subtitle = preview.invitation ? preview.name + ', ' + preview.invitation.ownerName : undefined

  if (!preview.duplicate) {
    if (!title) {
      title = 'Preview Title'
    }
    if (!subtitle) {
      subtitle = preview.subtitlePattern && fields ? doSubstitution(preview.subtitlePattern, fields) : undefined
    }
    if (!subtitle) {
      subtitle = preview.name || preview.templateName || 'Preview Subtitle'
    }
    return { title, subtitle }
  }

  return { title: preview.title, subtitle: preview.subtitle }
}

export const getDashboardTitles = (preview: Preview) =>
  extractTitleAndSubtitleFromFields(
    preview.name,
    preview.title,
    preview.subtitle,
    preview.templateName,
    preview.invitation,
    preview.dashboardTitlePattern,
    preview.dashboardSubtitlePattern,
    preview.duplicate,
    getFieldsWithLookupTags(preview)
  )

export const getDashboardTitlesForPreviewLite = (preview: PreviewLite) =>
  extractTitleAndSubtitleFromFields(
    preview.name,
    preview.title,
    preview.subtitle,
    preview.templateName,
    preview.invitation,
    preview.dashboardTitlePattern,
    preview.dashboardSubtitlePattern,
    preview.duplicate,
    preview.lookupValues
  )

export const getConcatenatedTitles = (preview: Preview) => {
  const { title, subtitle } = getDashboardTitles(preview)
  return title && subtitle ? `${title}, ${subtitle}` : title ? title : preview.templateName
}

export const getConcatenatedTitlesForPreviewLite = (preview: PreviewLite) => {
  const { title, subtitle } = getDashboardTitlesForPreviewLite(preview)
  return title && subtitle ? `${title}, ${subtitle}` : title ? title : preview.templateName
}

const extractTitleAndSubtitleFromFields = (
  name?: string,
  previewTitle?: string,
  previewSubTitle?: string,
  templateName?: string,
  invitation?: Invitation,
  dashboardTitlePattern?: string,
  dashboardSubtitlePattern?: string,
  duplicate?: boolean,
  fields?: { lookupTag: string; value?: string }[]
) => {
  let title: string | undefined
  let subtitle: string | undefined

  if (invitation) {
    title = invitation.name
    subtitle = invitation.ownerName
  } else {
    title = !duplicate && dashboardTitlePattern && fields ? doSubstitution(dashboardTitlePattern, fields) : previewTitle
    subtitle =
      !duplicate && dashboardSubtitlePattern && fields
        ? doSubstitution(dashboardSubtitlePattern, fields)
        : previewSubTitle

    // if no title is found, we will just use templateName as title except that General Introducion will use previewName
    // The title may never return undefined because preview always has templateName, but we handle the undefined just in case
    // if no subtitle is found, we will just return undefined
    if (!title) {
      title = dashboardTitlePattern === '{{previewName}}' && name ? name : templateName ? templateName : undefined
    }
  }

  return { title, subtitle }
}

const getFieldsWithLookupTags = (preview: Preview) =>
  preview.contentBlocks?.flatMap(b => b.contentBlockFields).filter(f => f.lookupTag)

const doSubstitution = (pattern: string, fields: { lookupTag: string; value?: string; required?: boolean }[]) => {
  const result = fields?.reduce((partial, f) => {
    const placeholder = `{{${f.lookupTag}}}`
    if (partial.includes(placeholder) && f.value) {
      try {
        // This is for DropDownFreeText.
        const jsonValue = JSON.parse(f.value)
        if (jsonValue.dropdown == 'other') {
          return partial.replace(placeholder, jsonValue.freeText)
        } else if (jsonValue.dropdown) {
          return partial.replace(placeholder, jsonValue.dropdown)
        }
      } catch (e) {}
      return partial.replace(placeholder, f.value)
    } else if (partial.includes(placeholder) && !f.value && !f.required) {
      return partial.replace(placeholder, '')
    } else {
      return partial
    }
  }, pattern)
  return result?.includes('{{') ? undefined : result
}

export const getPluralisedTitle = (title: string) => {
  if (title.endsWith('ies') || title.endsWith('s')) {
    return title
  } else {
    return title.endsWith('y') ? title.slice(0, -1) + 'ies' : title + 's'
  }
}
