import React from 'react'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import './TemplateCard.scss'

type Props = {
  name?: string
  description?: string
  buttonText: string
  isLoading?: boolean
  onClick: () => void
}

const TemplateCard: React.FC<Props> = ({ name, description, buttonText, isLoading, onClick }) => (
  <button className="template-card plain" onClick={onClick}>
    <div className="template-card-text">
      <h2 className="template-name">{name}</h2>
      {description && <div className="template-description">{description}</div>}
    </div>
    <div className="select-template-text">
      {isLoading ? (
        <div className="creating-preview-spinner-container">
          <LoadingSpinner />
        </div>
      ) : (
        buttonText
      )}
    </div>
  </button>
)

export default TemplateCard
