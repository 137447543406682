import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon'
import './PreviewEditorBottomNav.scss'


type Props = {
  prevLink?: string
  nextLink?: string
  showSaveButton?: boolean
  isSaveButtonDisabled?: boolean
  isSaving?: boolean
  onSaveClick?: () => void
}

const PreviewEditorBottomNav: React.FC<Props> = ({
  prevLink,
  nextLink,
  showSaveButton,
  isSaveButtonDisabled,
  onSaveClick,
  isSaving,
}) => {
  const nextScreenIsPublish = nextLink?.includes('/preview/')
  const canSave = showSaveButton && !isSaveButtonDisabled


  return (
    <div className="preview-editor-bottom-nav">
      {showSaveButton && (
        <LoadingButton
          className={'save-changes-button-mobile primary' + (isSaveButtonDisabled ? ' disabled' : '')}
          isLoading={isSaving}
          onClick={onSaveClick}
          disabled={isSaveButtonDisabled}
        >
          Save changes
        </LoadingButton>
      )}
      <div className="navigation-links">
        {prevLink && (
          <Link className="prev-link caps" to={prevLink}>
            <ChevronLeftIcon />
            <span>Prev</span>
          </Link>
        )}
        {nextLink && (
          <Link
            className={
              'next-link button' + (!canSave ? ' primary' : '') + (nextScreenIsPublish ? ' next-is-publish' : '')
            }
            to={nextLink}
          >
            {nextScreenIsPublish ? 'Review and Publish' : 'Next'}
          </Link>
        )}
      </div>
      {showSaveButton && (
        // Has type=submit (the default) so hitting enter in form can save changes if button is present.
        <LoadingButton
          className={'save-changes-button primary' + (isSaveButtonDisabled ? ' disabled' : '')}
          isLoading={isSaving}
          onClick={onSaveClick}
          disabled={isSaveButtonDisabled}
        >
          Save changes
        </LoadingButton>
      )}
    </div>
  )
}

export default PreviewEditorBottomNav
