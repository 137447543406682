import React, { useEffect, useState } from 'react'
import './InvitationVideoChaptersForm.scss'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import { InvitationEditorStep, Option, TempInvitation, VideoCategoryTemplate } from '../../../state/types'
import { getVideoCategoryOptions } from '../../../services/configService'
import MoreOptionsIcon from '../../../assets/icons/MoreOptionsIcon'
import MoveUpArrowIcon from '../../../assets/icons/MoveUpArrowIcon'
import MoveDownArrowIcon from '../../../assets/icons/MoveDownArrowIcon'
import DropdownMenuOption from '../../shared/DropdownMenuOption/DropdownMenuOption'
import DropdownMenu from '../../shared/DropdownMenu/DropdownMenu'
import { EditInvitationVideoChapterDialog } from '../EditInvitationVideoChapterDialog/EditInvitationVideoChapterDialog'
import * as invitationService from '../../../services/invitationService'
import InvitationEditorStepper from '../InvitationEditorStepper/InvitationEditorStepper'
import CreateCustomVideoChapter from '../CreateCustomVideoChapter/CreateCustomVideoChapter'

type FormProps = {
  index: number
  currentInvitationEditorStepIndex: number
  setCurrentInvitationEditorStepIndex: (value: number) => void
  tempInvitation?: TempInvitation
  setTempInvitation: (value: TempInvitation) => void
  templateId?: string
  invitationId?: string
  steps: InvitationEditorStep[]
  showEnterpriseFeatures?: boolean
}

const InvitationVideoChaptersForm: React.FC<FormProps> = ({
  index,
  currentInvitationEditorStepIndex,
  setCurrentInvitationEditorStepIndex,
  tempInvitation,
  setTempInvitation,
  templateId,
  invitationId,
  steps,
  showEnterpriseFeatures,
}) => {
  const [hideForm, setHideForm] = useState(currentInvitationEditorStepIndex !== index)
  const [options, setOptions] = useState<Option[]>([])
  const [stepIndex, setStepIndex] = useState<{ index: number }>({ index: currentInvitationEditorStepIndex })
  const [addedVideoChapters, setAddedVideoChapters] = useState<VideoCategoryTemplate[]>(
    tempInvitation?.videoCategoryTemplates ? tempInvitation.videoCategoryTemplates : []
  )

  useEffect(() => {
    if (!tempInvitation?.videoCategoryTemplates) {
      return
    }
    setAddedVideoChapters(tempInvitation.videoCategoryTemplates)
  }, [tempInvitation?.videoCategoryTemplates])

  const onFormSubmit = async ({
    selectedFormat,
    selectedOption,
    formatDisplay,
    otherField,
    description,
  }: {
    selectedOption: string
    otherField: string
    selectedFormat: string
    formatDisplay: string
    description: string
  }) => {
    if (!tempInvitation) {
      return
    }
    const newVideoCategoryTemplate: VideoCategoryTemplate = {
      videoCategoryId: selectedOption,
      videoCategoryIdTitle:
        selectedOption === 'other'
          ? otherField
          : options.filter(option => option.value === selectedOption)[0].displayValue,
      helperText: description,
      position: addedVideoChapters.length + 1,
      format: selectedFormat,
      formatDisplay,
    }

    if (addedVideoChapters.length > 0) {
      setAddedVideoChapters([...addedVideoChapters, newVideoCategoryTemplate])
      tempInvitation.videoCategoryTemplates = [...addedVideoChapters, newVideoCategoryTemplate]
    } else {
      setAddedVideoChapters([newVideoCategoryTemplate])
      tempInvitation.videoCategoryTemplates = [newVideoCategoryTemplate]
    }
    setTempInvitation(tempInvitation)
  }

  useEffect(() => {
    const loadVideosCategoryOptions = async () => {
      if (templateId) {
        setOptions(await getVideoCategoryOptions(templateId))
        return
      }

      if (!invitationId) {
        return
      }

      const { ok, data: invitation } = await invitationService.getInvitationSummary(invitationId)
      if (ok && invitation?.templateId) {
        setOptions(await getVideoCategoryOptions(invitation.templateId))
        return
      }
    }

    loadVideosCategoryOptions()
  }, [templateId])

  useEffect(() => {
    setHideForm(currentInvitationEditorStepIndex !== index)
  }, [currentInvitationEditorStepIndex])

  const onFormChange = () => {
    if (!tempInvitation) {
      return
    }
    tempInvitation.videoCategoryTemplates = addedVideoChapters
    setTempInvitation(tempInvitation)
  }

  useEffect(() => {
    setCurrentInvitationEditorStepIndex(stepIndex.index)
  }, [stepIndex])

  return (
    <>
      {!hideForm && (
        <div className={'invitation-video-chapters-form'}>
          <div className={'added-video-chapters-form'}>
            {showEnterpriseFeatures !== undefined && (
              <InvitationEditorStepper
                steps={steps}
                onClick={setStepIndex}
                showEnterpriseFeatures={showEnterpriseFeatures}
              />
            )}
            <h1>Specify Video/Audio Content</h1>
            <div className={'info-text'}>
              <p>Request tailored video or audio chapters you would like to receive from applicants. </p>
            </div>
            <div className={'video-chapter-box'}>
              <h2>Create Chapter</h2>
              <CreateCustomVideoChapter options={options} buttonText="Add" onFormSubmit={onFormSubmit} />
            </div>
            <div className={'added-video-chapters'}>
              {addedVideoChapters.length > 0 && <h2>Video Chapters Added</h2>}
              {addedVideoChapters.map((addedVideoChapter, index) => {
                return (
                  <InvitationAddedVideoChaptersCard
                    key={index}
                    index={index}
                    options={options}
                    addedVideoChapter={addedVideoChapter}
                    addedVideoChapters={addedVideoChapters}
                    setAddedVideoChapters={setAddedVideoChapters}
                  />
                )
              })}
            </div>

            <div className={'bottom-buttons'}>
              <div
                className={'prev-button'}
                onClick={() => {
                  onFormChange()
                  setCurrentInvitationEditorStepIndex(index - 1)
                }}
              >
                {'< PREV'}
              </div>
              <LoadingButton
                className="next-button primary"
                onClick={() => {
                  onFormChange()
                  setCurrentInvitationEditorStepIndex(index + 1)
                }}
              >
                Next
              </LoadingButton>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default InvitationVideoChaptersForm

type CardProps = {
  index: number
  options: Option[]
  addedVideoChapter: VideoCategoryTemplate
  addedVideoChapters: VideoCategoryTemplate[]
  setAddedVideoChapters: (value: VideoCategoryTemplate[]) => void
}

const InvitationAddedVideoChaptersCard: React.FC<CardProps> = ({
  index,
  options,
  addedVideoChapter,
  addedVideoChapters,
  setAddedVideoChapters,
}) => {
  const [editInvitationVideoChapterDialog, setEditInvitationVideoChapterDialog] = useState(false)

  const onMoveUpClick = (currentIndex: number) => {
    const tempAddedVideoChapters = addedVideoChapters.slice()
    const currentAddedVideoChapters = tempAddedVideoChapters[currentIndex]
    const aboveAddedVideoChapters = tempAddedVideoChapters[currentIndex + 1]

    currentAddedVideoChapters.position = currentAddedVideoChapters.position + 1
    aboveAddedVideoChapters.position = aboveAddedVideoChapters.position - 1

    tempAddedVideoChapters[currentIndex] = aboveAddedVideoChapters
    tempAddedVideoChapters[currentIndex + 1] = currentAddedVideoChapters
    setAddedVideoChapters(tempAddedVideoChapters)
  }

  const onMoveDownClick = (currentIndex: number) => {
    const tempAddedVideoChapters = addedVideoChapters.slice()
    const currentAddedVideoChapters = tempAddedVideoChapters[currentIndex]
    const belowAddedVideoChapters = tempAddedVideoChapters[currentIndex - 1]

    currentAddedVideoChapters.position = currentAddedVideoChapters.position - 1
    belowAddedVideoChapters.position = belowAddedVideoChapters.position + 1

    tempAddedVideoChapters[currentIndex] = belowAddedVideoChapters
    tempAddedVideoChapters[currentIndex - 1] = currentAddedVideoChapters
    setAddedVideoChapters(tempAddedVideoChapters)
  }

  const onRemoveClick = (currentIndex: number) => {
    const tempAddedVideoChapters: VideoCategoryTemplate[] = []

    addedVideoChapters.forEach((addedVideoChapter, index) => {
      if (index !== currentIndex) {
        if (index > currentIndex) {
          addedVideoChapter.position = addedVideoChapter.position - 1
        }
        tempAddedVideoChapters.push(addedVideoChapter)
      }
    })

    setAddedVideoChapters(tempAddedVideoChapters)
  }

  return (
    <div className={'added-video-chapter-card'} key={index}>
      <EditInvitationVideoChapterDialog
        index={index}
        isShowing={editInvitationVideoChapterDialog}
        onClose={() => setEditInvitationVideoChapterDialog(false)}
        options={options}
        addedVideoChapter={addedVideoChapter}
        addedVideoChapters={addedVideoChapters}
        setAddedVideoChapters={setAddedVideoChapters}
      />

      <div className={'added-video-chapter'}>
        <h3>{`${addedVideoChapter.position}. ${addedVideoChapter.videoCategoryIdTitle} (${addedVideoChapter.formatDisplay})`}</h3>
        <p>{addedVideoChapter.helperText}</p>
      </div>

      <div className={'added-video-chapter-buttons'}>
        <div className={'action-buttons'}>
          <DropdownMenu
            trigger={<MoreOptionsIcon />}
            menuContent={
              <>
                <DropdownMenuOption optionName="Edit" onSelect={() => setEditInvitationVideoChapterDialog(true)} />
                <DropdownMenuOption optionName="Remove" onSelect={() => onRemoveClick(index)} />
              </>
            }
            horizontalAlignment="right"
          />
        </div>
        {addedVideoChapters.length > 0 && (
          <div className="move-buttons">
            {index > 0 && (
              <div className="move-button" onClick={() => onMoveDownClick(index)}>
                <MoveUpArrowIcon />
              </div>
            )}
            {index < addedVideoChapters.length - 1 && (
              <div className="move-button" onClick={() => onMoveUpClick(index)}>
                <MoveDownArrowIcon />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
