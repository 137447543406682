import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Invitation } from 'state/types'
import * as invitationService from 'services/invitationService'
import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import CopyableLink from 'components/shared/CopyableLink/CopyableLink'
import './CreateInvitationSuccessPage.scss'
import BackLink from 'components/shared/BackLink/BackLink'
import CopyableQR from 'components/shared/CopyableQR/CopyableQR'

const CreateInvitationSuccessPage = () => {
  const [invitation, setInvitation] = useState<Invitation | undefined>()
  const [isLoading, setLoading] = useState(true)
  const { invitationId } = useParams<any>()

  useEffect(() => {
    const loadInvitation = async () => {
      if (invitationId) {
        setLoading(true)
        const { ok, data } = await invitationService.getInvitationSummary(invitationId)
        setLoading(false)
        if (ok && data) {
          setInvitation(data)
        }
      }
    }
    loadInvitation()
  }, [invitationId])

  return (
    <FullScreenPageFrame
      className="create-invitation-success-page"
      cancelLink="/invitations"
      title={isLoading ? undefined : invitation ? 'Success!' : 'Invitation Not Found'}
      subtitle={
        isLoading
          ? undefined
          : invitation
          ? `Your invitation titled ${invitation.name} has been created.`
          : 'An unknown error occurred. Please try again later.'
      }
      notFound={!isLoading && !invitation}
    >
      {isLoading ? (
        <LoadingSpinner container />
      ) : invitation ? (
        <>
          <div className="invitation-link-container">
            <div className="share-link-label">Share this link with your applicants:</div>
            <CopyableLink to={`/i/${invitation?.id}`} />
            <p>Or</p>
            <CopyableQR to={`/i/${invitation?.id}`} />
          </div>
          <div className="invitation-hints-text">
            <span>When an applicant creates a Preview in response to this invitation, it will appear in your </span>
            <Link to="/invitations">Invitations</Link>
            <span> page.</span>
          </div>
          <BackLink to="/invitations">Back to Invitations</BackLink>
        </>
      ) : (
        <>
          <BackLink className="not-found-back-link" to="/invitations">
            Back to Invitations
          </BackLink>
        </>
      )}
    </FullScreenPageFrame>
  )
}

export default CreateInvitationSuccessPage
