import { useEffect, useRef } from 'react'

// Set full-page background color on body element so that the color shows when overflow-scrolling on iOS.
export const useBodyColor = (style?: 'cream' | 'darkblue' | 'lightCream' | 'white', customBackground?: boolean) => {
  const addedRef = useRef(false)

  useEffect(() => {
    if (!customBackground) {
      const className = style ? 'body-color-' + style : undefined
      if (className && !document.body.classList.contains(className)) {
        document.body.classList.add(className)
        addedRef.current = true
      }
      return () => {
        if (className && addedRef.current) {
          document.body.classList.remove(className)
        }
      }
    }
  }, [style])
}
