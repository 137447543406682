import React, { useEffect, useState } from 'react'
import { getOrganizationsById } from '../../../services/organizationService'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'
import useWorkspace from '../../../state/useWorkspace'

interface AuthorizationProps {
  UnauthorizedComponent: React.ComponentType;
  children: React.ReactNode;
}

const AuthorizedPage = ({UnauthorizedComponent, children}: AuthorizationProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const { currentWorkspace } = useWorkspace(true)

  useEffect(() => {
    if (currentWorkspace.id) {
      getOrganizationsById(currentWorkspace.id)
        .then(data => {
          setIsLoading(false)
          if (data.status === 403) {
            setIsAuthorized(false)
          } else {
            setIsAuthorized(true)
          }
        })
    }
  }, [currentWorkspace.id]);

  if (isLoading) {
    return <LoadingSpinner container expand />
  }

  return isAuthorized? <>{children}</>: <UnauthorizedComponent />

};

export default AuthorizedPage;
