import React from 'react'
import VideoSpeedSlider from 'components/video/VideoSpeedSlider/VideoSpeedSlider'
import BackLink from 'components/shared/BackLink/BackLink'
import './VideoRecorderPageHeader.scss'
import ExpandMoreIcon from '../../../assets/icons/ExpandMoreIcon'
import DropdownMenu from '../../shared/DropdownMenu/DropdownMenu'
import DropdownMenuOption from '../../shared/DropdownMenuOption/DropdownMenuOption'
import { TeleprompterFontSize } from '../../../state/types'

type Props = {
  showEditScriptButton: boolean
  isTeleprompterTextLoading: boolean
  isTeleprompterShowing: boolean
  isTeleprompterScrolling: boolean
  teleprompterText: string
  teleprompterSpeed: number
  isRecording: boolean
  backLink: string
  toggleTeleprompterShowing: () => void
  setTeleprompterSpeed: (speed: number) => void
  stopTeleprompter: () => void
  toggleTeleprompterScrolling: () => void
  editScript: () => void
  setIsTeleprompterFullscreen: (v: boolean) => void
  teleprompterFontSize: TeleprompterFontSize
  setTeleprompterFontSize: (v: TeleprompterFontSize) => void
}

const VideoRecorderPageHeader: React.FC<Props> = ({
  showEditScriptButton,
  isTeleprompterTextLoading,
  isTeleprompterShowing,
  isTeleprompterScrolling,
  teleprompterText,
  teleprompterSpeed,
  isRecording,
  backLink,
  toggleTeleprompterShowing,
  setTeleprompterSpeed,
  stopTeleprompter,
  toggleTeleprompterScrolling,
  editScript,
  setIsTeleprompterFullscreen,
  teleprompterFontSize,
  setTeleprompterFontSize,
}) => {
  const editDisabled = isRecording || isTeleprompterTextLoading

  const dropdownMenuContent = [
    { name: 'Small Text', onSelect: () => setTeleprompterFontSize('20px'), pixel: '20px' },
    { name: 'Medium Text', onSelect: () => setTeleprompterFontSize('26px'), pixel: '26px' },
    { name: 'Large Text', onSelect: () => setTeleprompterFontSize('32px'), pixel: '32px' },
  ]

  return (
    <div className="video-recorder-page-header">
      <div className="header-content">
        <div className="left-buttons">
          <BackLink to={backLink}>Back</BackLink>
        </div>
        <div className="middle-buttons">
          <VideoSpeedSlider
            isRecording={isRecording}
            isTeleprompterTextLoading={isTeleprompterTextLoading}
            isTeleprompterScrolling={isTeleprompterScrolling}
            isTeleprompterShowing={isTeleprompterShowing}
            teleprompterText={teleprompterText}
            teleprompterSpeed={teleprompterSpeed}
            toggleTeleprompterScrolling={toggleTeleprompterScrolling}
            setTeleprompterSpeed={setTeleprompterSpeed}
          />
          <DropdownMenu
            trigger={<button className="font-size-button">Aa</button>}
            menuContent={dropdownMenuContent.map(({ name, onSelect, pixel }) => (
              <div className={teleprompterFontSize === pixel ? 'active-font' : ''}>
                <DropdownMenuOption optionName={name} onSelect={onSelect} />
              </div>
            ))}
            horizontalAlignment="left"
          />
          {showEditScriptButton && (
            <button
              className="edit-script-button link"
              onClick={() => {
                stopTeleprompter()
                editScript()
              }}
              disabled={editDisabled}
            >
              Edit script
            </button>
          )}
        </div>
        <div className="right-buttons">
          <button
            className={`toggle-teleprompter-button ${isTeleprompterShowing ? 'rotate' : ''}`}
            onClick={() => {
              stopTeleprompter()
              toggleTeleprompterShowing()
              setIsTeleprompterFullscreen(false)
            }}
          >
            <ExpandMoreIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default VideoRecorderPageHeader
