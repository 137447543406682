import React from 'react'
import './SelectionRing.scss'

type Props = {
  selected: boolean
}

const SelectionRing: React.FC<Props> = ({ selected }) => (
  <div className="selection-ring">{selected && <div className="center" />}</div>
)

export default SelectionRing
