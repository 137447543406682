import React from 'react'
import { parseLink } from 'helpers/validation'
import FormInput from 'components/shared/FormInput/FormInput'
import './LinkInput.scss'

type Props = {
  name?: string
  value?: string
  onChange?: (value: string) => void
}

const LinkInput: React.FC<Props> = ({ name, value, onChange }) => {
  const { text, url } = parseLink(value)

  const notifyChange = (t: string, u: string) => onChange?.(JSON.stringify({ text: t, url: u }))

  return (
    <div className="link-input">
      <FormInput
        name={name + '-text'}
        label="Link text"
        value={text}
        onChange={e => notifyChange(e.target.value, url)}
      />
      <FormInput
        name={name + '-url'}
        label="Link URL"
        value={url}
        onChange={e => notifyChange(text, e.target.value)}
        placeholder="www.example.com"
      />
    </div>
  )
}

export default LinkInput
