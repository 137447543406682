import React, { useEffect } from 'react'
import { Option } from 'state/types'
import './FormSelect.scss'
import Instructions from '../Instructions/Instructions'

type Props = {
  hideNoSelection?: boolean
  label?: string
  instructions?: string | undefined
  error?: string
  errors?: any
  disabled?: boolean
  defaultValue?: string
  options: Array<Option>
} & React.SelectHTMLAttributes<HTMLSelectElement>

const FormSelect: React.FC<Props> = ({
  hideNoSelection,
  name,
  value,
  label,
  instructions,
  error,
  errors,
  disabled,
  defaultValue,
  options,
  ...rest
}) => {
  const selectId = 'select' + (name ? '-' + name : '')

  const errorToShow = error || (name && errors?.[name]?.message)

  return (
    <div className="form-input-container">
      <div className="form-input-label-row">
        <div>
          {label && (
            <label className="form-input-label" htmlFor={selectId}>
              {label}
            </label>
          )}
          {instructions && <Instructions instructions={instructions} />}
        </div>
        {errorToShow && <div className="form-input-validation-error">{errorToShow}</div>}
      </div>
      <div className={disabled ? ' disabled' : ''}>
        <select
          id={selectId}
          disabled={disabled}
          name={name}
          className={'form-select' + (error ? ' has-error' : '')}
          value={value ? value : defaultValue ? defaultValue : ''}
          {...rest}
        >
          {!hideNoSelection && (
            <option key="no-selection" value="">
              {''}
            </option>
          )}
          {options.map(({ value, displayValue }) => (
            <option key={value} value={value}>
              {displayValue}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default FormSelect
