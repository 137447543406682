import React from 'react'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import './Table.scss'
import { Link } from 'react-router-dom'
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip'

type Props<T> = {
  className?: string
  columns: {
    name: string
    className: string
    label?: string | React.ReactNode
    render?: (item: T, rowIndex: number) => React.ReactNode
    notLink?: boolean
    sortable?: boolean
    hide?: boolean
  }[]
  rows: T[]
  sortable?: boolean
  sortingBy?: string
  sortingAsc?: boolean
  rowLink?: (item: T, rowIndex: number) => string
  onSortingByChange?: (sortingBy: string) => void
  onSortingAscChange?: (sortingAsc: boolean) => void
}

const Table: <T>(props: Props<T>) => React.ReactElement<Props<T>> = ({
  className,
  columns,
  rows,
  sortable,
  sortingBy,
  sortingAsc,
  rowLink,
  onSortingByChange,
  onSortingAscChange,
}) => (
  <div className={'table' + (className ? ' ' + className : '')}>
    <div className="header-row">
      {columns.map(column => {
        if (column.hide) return null
        const sortActive = column.name === sortingBy
        return (
          <div key={column.name} className={'header-cell' + (column.className ? ' ' + column.className : '')}>
            {sortable && (column.sortable || column.sortable === undefined) ? (
              column.label && (
                <div
                  className={'sort-button plain' + (sortActive ? ' sort-active' + (sortingAsc ? ' ' : ' asc') : '')}
                  onClick={() => {
                    if (sortActive) {
                      onSortingAscChange?.(!sortingAsc)
                    } else {
                      onSortingByChange?.(column.name)
                      onSortingAscChange?.(true)
                    }
                  }}
                >
                  <OverflowTooltip>{column.label}</OverflowTooltip>
                  <ChevronRightIcon />
                </div>
              )
            ) : (
              <OverflowTooltip>{column.label}</OverflowTooltip>
            )}
          </div>
        )
      })}
    </div>
    {rows.map((row, i) => {
      const rowContent = columns.map((column, i) => {
        if (column.hide) return null
        if (!rowLink || column.notLink) {
          return (
            <div key={column.name} className={'cell' + (column.className ? ' ' + column.className : '')}>
              <OverflowTooltip>{column.render ? column.render(row, i) : (row as any)[column.name]}</OverflowTooltip>
            </div>
          )
        }
        if (rowLink && /\/preview\/(\w+)\?i=(\w+)/.test(rowLink(row, i))) {
          const li = rowLink(row, i);
          return (
            <a
              key={column.name}
              className={'link cell' + (column.className ? ' ' + column.className : '')}
              href={li}
            >
              <OverflowTooltip>{column.render ? column.render(row, i) : (row as any)[column.name]}</OverflowTooltip>
            </a>
          );
        }
        return (
          <Link
            key={column.name}
            className={'link cell' + (column.className ? ' ' + column.className : '')}
            to={rowLink(row, i)}
          >
            <OverflowTooltip>{column.render ? column.render(row, i) : (row as any)[column.name]}</OverflowTooltip>
          </Link>
        )
      })
      return (
        <div key={i} className="row" style={{ zIndex: 1000 - i }}>
          {rowContent}
        </div>
      )
    })}
  </div>
)

export default Table
