import React from 'react'

const RecordIcon = () => (
  <svg className="icon record-icon" width="27px" height="17px" viewBox="0 0 27 17">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-842.000000, -93.000000)">
        <g transform="translate(592.000000, -77.000000)">
          <g transform="translate(251.000000, 170.000000)" strokeWidth="1.3">
            <polygon points="0.56875 15.65 19.56875 15.65 19.56875 0.65 0.56875 0.65"></polygon>
            <circle cx="7" cy="6" r="2"></circle>
            <polyline points="19.56875 5.17135922 24.56875 1.65 24.56875 14.65 19.56875 11.1286408"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default RecordIcon
