export const removeHttpPrefix = (url: string) => url.substring(url.indexOf('://') + 3)

export const createFullLink = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url
  } else {
    return url
  }
}
export const createFullLinkConvert = (url: string) => {
  url = url.trim();

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  if (url.startsWith('www.')) {
    url = url.replace('www.', '');
    return `https://${url}`;
  }

  return `https://${url}`;
}


export const formatDisplayedLink = (url: string) => {
  let displayedLink = url
  if (displayedLink.startsWith('http://') || displayedLink.startsWith('https://')) {
    displayedLink = removeHttpPrefix(displayedLink)
  }
  if (displayedLink.length > 8 && displayedLink.endsWith('/')) {
    displayedLink = displayedLink.substring(0, displayedLink.length - 1)
  }
  return displayedLink
}

export const isOnNavLink = (pathName: string) => {
  return pathName === '/' || pathName === '/invitations' || pathName === '/analytics' || pathName.includes('/organization/settings')
}
