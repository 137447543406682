import { useState, useEffect, useCallback, useMemo } from 'react'
import useStore from 'state/useStore'
import { BrandTheme, OrganizationBrandSetting } from './types'
import * as organizationBrandSettingService from 'services/organizationBrandSettingService'
import { getCurrentPlan } from 'helpers/subscription'
import { SubscriptionPlan, WorkspaceType } from 'helpers/enum'
import useWorkspace from './useWorkspace'
import useSubscription from './useSubscription'
import * as imageService from 'services/imageService'
import { useHistory } from 'react-router-dom'

const useOrganizationBrandSetting = () => {
  //hooks for brand settings
  const { state, dispatch } = useStore();
  const { currentWorkspace, organizationsLoaded } = useWorkspace(true)
  const { subscription, isPaymentDetailsLoading } = useSubscription(organizationsLoaded)
  const isWorkspaceTypeUser = currentWorkspace?.type === WorkspaceType.User
  const [isDataLoaded, setDataLoaded] = useState(false)
  const [isDataLoadedBrandTheme, setIsDataLoadedBrandTheme] = useState(true)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [isUpdatingBrandUrl, setIsUpdatingBrandUrl] = useState(false)
  const [brandTheme, setBrandTheme] = useState<BrandTheme | undefined>()
  const [organizationBrandSetting, setOrganizationBrandSetting] = useState<OrganizationBrandSetting | undefined>()
  const [brandUrl, setBrandUrl] = useState<string | undefined>(undefined)
  const [brandThemeID, setBrandThemeID] = useState<string | undefined>('')
  const [brandThemeItem, setBrandThemeItem] = useState<string | undefined>(undefined)
  const [previewReferenceDisabled, setPreviewReferenceDisabled] = useState<boolean | undefined>(false)
  const [showUpdateURLPopup, setShowUpdateURLPopup] = useState<boolean>(false)
  const [brandMode, setBrandMode] = useState<any | undefined>()
  const [brandModeDataGet, setBrandModeDataGet] = useState<any | undefined>()
  const [brandStyle, setBrandStyle] = useState<any | undefined>()
  const history = useHistory()
  const previewDisableData = state.previewDisableData
  const cloudinaryStorageIdData = state.cloudinaryStorageId
  const faviconCloudinaryStorageIdData = state.faviconCloudinaryStorageId


  const setHidePreviewMeBranding = useCallback((data: boolean | undefined) => {
    dispatch({ type: 'SET_HIDE_PREVIEW_ME_BRANDING', value: data });
  }, [dispatch]);

  const setCloudinaryStorageIdData = useCallback((data: string | undefined) => {
    dispatch({ type: 'SET_CLOUDINARY_STORAGE_ID', value: data });
  }, [dispatch]);

  const setFaviconCloudinaryStorageIdData = useCallback((data: string | undefined) => {
    dispatch({ type: 'SET_FAVICON_CLOUDINARY_STORAGE_ID', value: data });
  }, [dispatch]);

  const applyBrandSettings = useMemo(() => {
    if (
      subscription &&
      organizationsLoaded &&
      isPaymentDetailsLoading &&
      (getCurrentPlan(subscription) === SubscriptionPlan.Basic ||
        getCurrentPlan(subscription) === SubscriptionPlan.Solo) &&
      !isWorkspaceTypeUser
    )
      setDataLoaded(true)

    return (
      (getCurrentPlan(subscription) === SubscriptionPlan.Enterprise ||
        getCurrentPlan(subscription) === SubscriptionPlan.Professional ||
        getCurrentPlan(subscription) === SubscriptionPlan.Business) &&
      !isWorkspaceTypeUser &&
      subscription &&
      organizationsLoaded &&
      isPaymentDetailsLoading
    )
  }, [subscription, isWorkspaceTypeUser, organizationsLoaded, isPaymentDetailsLoading])

  const applyDisablePreviewLogo = useMemo(() => {
    return (
      getCurrentPlan(subscription) === SubscriptionPlan.Enterprise &&
      !isWorkspaceTypeUser &&
      subscription &&
      organizationsLoaded &&
      isPaymentDetailsLoading
    )
  }, [subscription, isWorkspaceTypeUser, organizationsLoaded, isPaymentDetailsLoading])

  useEffect(() => {
    (async () => {
      try {
        if (currentWorkspace?.id && applyBrandSettings) {
          await getOrganizationBrandSettingByOrganization();
        }

        if(getCurrentPlan(subscription) === SubscriptionPlan.Basic || getCurrentPlan(subscription) === SubscriptionPlan.Solo) {
          setHidePreviewMeBranding(false);
          setCloudinaryStorageIdData('');
          setFaviconCloudinaryStorageIdData('');
        }
      } catch (error) {
        console.log("Error fetching organization brand setting:", error);
      }
    })();

  }, [applyBrandSettings]);


  const getOrganizationBrandSettingByOrganization = async () => {
    if (currentWorkspace?.id) {
      const { data, ok } = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(
        currentWorkspace.id
      )
      setOrganizationBrandSetting(data)
      setBrandUrl(data?.brandUrl)
      setPreviewReferenceDisabled(data?.previewReferenceDisabled)
      setHidePreviewMeBranding(data?.previewReferenceDisabled)
      setCloudinaryStorageIdData(data?.cloudinaryStorageId)
      setDataLoaded(true)
      setFaviconCloudinaryStorageIdData(data?.faviconCloudinaryStorageId)
      setBrandMode(data?.brandMode)
      setBrandModeDataGet(data?.brandMode)
      return ok
    }
  }

  const getBrandTheme = async (id: string) => {
    if (currentWorkspace?.id) {
      setDataLoaded(false)

      const { data, ok } = await organizationBrandSettingService.getBrandThemeByID(
        currentWorkspace.id
      )

      return ok
    }
  }

  const getBrandThemeByID = async () => {
    if (currentWorkspace?.id) {
      setDataLoaded(false)
      const { data, ok } = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(
        currentWorkspace.id
      )
      if (data?.brandMode) {
        await updateBrandModeStorageIdByOrganization(data?.brandMode)
      } else {
        const { data, ok } = await organizationBrandSettingService.getBrandThemeByID(
          currentWorkspace.id
        )
        setBrandTheme(data)
        setBrandThemeID(data?.id)
        setBrandThemeItem(data?.brandStyle)
        await updateBrandModeStorageIdByOrganization(data?.id ?? '')

        return ok
      }

      return ok
    }
  }



  const updateFaviconCloudinaryStorageIdByOrganization = async (faviconCloudinaryStorageId: string) => {
    if (currentWorkspace?.id) {
      setIsUploadingImage(true)
      const { ok } = await organizationBrandSettingService.updateFaviconCloudinaryStorageIdByOrganization(
        currentWorkspace.id,
        faviconCloudinaryStorageId
      )
      setIsUploadingImage(false)
      return ok
    }
  }
  const updateBrandModeStorageIdByOrganization = async (brandMode: string) => {
    if (currentWorkspace?.id) {
      const { ok } = await organizationBrandSettingService.updateBrandModeStorageIdByOrganization(
        currentWorkspace.id,
        brandMode
      )
      return ok
    }
  }

  const updateItemBrandTheme = async (brandMode: string) => {
    if (currentWorkspace?.id) {
      const organizationData = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(currentWorkspace.id)
      const { ok } = await organizationBrandSettingService.updateBrandThemeByID(
        currentWorkspace.id,
        organizationData?.data?.brandMode ?? '',
        brandMode
      )
      return ok
    }
  }
  const updatePreviewReferenceDisabledByOrganization = async (enabled: boolean) => {
    if (currentWorkspace?.id) {
      const { data, ok } = await organizationBrandSettingService.updatePreviewReferenceDisabledByOrganization(
        currentWorkspace.id,
        enabled
      )
      setHidePreviewMeBranding(enabled)
      return ok
    }
  }

  const updateCloudinaryStorageIdByOrganization = async (cloudinaryStorageId: string) => {
    if (currentWorkspace?.id) {
      setIsUploadingImage(true)
      const { ok } = await organizationBrandSettingService.updateCloudinaryStorageIdByOrganization(
        currentWorkspace.id,
        cloudinaryStorageId
      )
      setIsUploadingImage(false)

      return ok
    }
  }

  const updateOrganizationBrandUrlByOrganization = async (brandUrl: string) => {
    if (currentWorkspace?.id) {
      setIsUpdatingBrandUrl(true)
      const { ok } = await organizationBrandSettingService.updateOrganizationBrandUrlByOrganization(
        currentWorkspace.id,
        brandUrl
      )
      if (organizationBrandSetting) setOrganizationBrandSetting({ ...organizationBrandSetting, brandUrl: brandUrl })
      setShowUpdateURLPopup(true)
      setIsUpdatingBrandUrl(false)

      return ok
    }
  }

  const updateOrganizationBrandLogo = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    if (applyBrandSettings) {
      const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
      if (status === 'success' && !!storageId) {
        await updateOrganizationLogoImageStorageId(storageId)
        setCloudinaryStorageIdData(storageId)
      }
    }
  }

  const updateOrganizationLogoImageStorageId = async (profileImageStorageId: string) => {
    if (currentWorkspace?.id && applyBrandSettings) {
      await organizationBrandSettingService.updateCloudinaryStorageIdByOrganization(
        currentWorkspace.id,
        profileImageStorageId
      )
    }
  }

  const updateOrganizationBrandLogoFavicon = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    if (applyBrandSettings) {
      const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
      if (status === 'success' && !!storageId) {
        await updateOrganizationLogoImageStorageIdFavicon(storageId)
        setFaviconCloudinaryStorageIdData(storageId)
      }
    }
  }

  const updateOrganizationLogoImageStorageIdFavicon = async (profileImageStorageId: string) => {
    if (currentWorkspace?.id && applyBrandSettings) {
      await organizationBrandSettingService.updateFaviconCloudinaryStorageIdByOrganization(
        currentWorkspace.id,
        profileImageStorageId
      )
    }
  }

  const updateOrganizationBrandMode = async (brandMode: string) => {
    if (applyBrandSettings && brandMode) {
      await updateOrganizationBrandModeStorageId(brandMode)
      setBrandMode(brandMode)
      setBrandModeDataGet(brandMode)
    }
  }
  const removeOrganizationLogoImage = () => {
    if (currentWorkspace?.id && applyBrandSettings) {
      updateOrganizationLogoImageStorageId('')
      setCloudinaryStorageIdData(undefined)
    }
  }

  const removeOrganizationLogoFaviconImage = async () => {
    if (currentWorkspace?.id && applyBrandSettings) {
      updateOrganizationLogoImageStorageIdFavicon('')
      setFaviconCloudinaryStorageIdData(undefined)
    }
  }

  const updateOrganizationBrandModeStorageId = async (brandMode: string) => {
    if (currentWorkspace?.id && applyBrandSettings) {
      await organizationBrandSettingService.updateBrandModeStorageIdByOrganization(
        currentWorkspace.id,
        brandMode
      )
    }
  }

  return {
    brandStyle,
    previewDisableData,
    brandUrl,
    isDataLoaded,
    organizationBrandSetting,
    isUploadingImage,
    isUpdatingBrandUrl,
    previewReferenceDisabled,
    cloudinaryStorageIdData,
    setCloudinaryStorageIdData,
    faviconCloudinaryStorageIdData,
    setFaviconCloudinaryStorageIdData,
    showUpdateURLPopup,
    brandMode,
    brandModeDataGet,
    setBrandUrl,
    getBrandThemeByID,
    getBrandTheme,
    updateItemBrandTheme,
    getOrganizationBrandSettingByOrganization,
    updatePreviewReferenceDisabledByOrganization,
    updateCloudinaryStorageIdByOrganization,
    updateFaviconCloudinaryStorageIdByOrganization,
    updateOrganizationBrandUrlByOrganization,
    setPreviewReferenceDisabled,
    updateOrganizationBrandLogo,
    updateOrganizationBrandLogoFavicon,
    updateBrandModeStorageIdByOrganization,
    removeOrganizationLogoImage,
    removeOrganizationLogoFaviconImage,
    setShowUpdateURLPopup,
    applyBrandSettings,
    applyDisablePreviewLogo,
  }
}

export const usePublicOrganizationBrandSetting = (organizationId: string | undefined) => {
  const [organizationBrandSetting, setOrganizationBrandSetting] = useState<OrganizationBrandSetting | undefined>(
    undefined
  )
  const [isBrandLoading, setIsBrandLoading] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      if (organizationId) {
        await getPublicOrganizationSettingByOrganization();
      }
    })();
  }, [organizationId]);

  const getPublicOrganizationSettingByOrganization = async () => {
    if (organizationId) {
      setIsBrandLoading(true)
      const { data, ok } = await organizationBrandSettingService.getPublicOrganizationSettingByOrganization(
        organizationId
      )
      setIsBrandLoading(false)
      setOrganizationBrandSetting(data)
    }
  }

  return {
    organizationBrandSetting,
    isBrandLoading,
  }
}

export default useOrganizationBrandSetting
