import Auth, { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth'
import { cognitoConfig } from 'helpers/config'

export const emailAlreadyRegisteredError = 'Email address is already registered.'
const emailUnverifiedError = 'Please verify your email address.'
const wrongPasswordError = 'Incorrect email or password.'
const tooManyAttemptsError = 'Too many attempts, please try again later.'
export const alreadyVerifiedMessage = 'Your email is already verified!'
const expiredCodeError = 'Your code has expired.'
export const emailNotFoundError = 'Email address cannot be found in our system.'
const genericError = 'Oops, something went wrong.'
const weakPasswordError =
  'Password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.'

Auth.configure(cognitoConfig)

export const getToken = async () => {
  // Refresh the token first if necessary:
  const session = await Auth.currentSession()
  return session?.getIdToken()?.getJwtToken() || ''
}

export const loadSession = async () => {
  try {
    const user = await Auth.currentSession()
    const accessToken = user?.getIdToken()?.getJwtToken()
    if (accessToken) {
      return true
    } else {
      return false
    }
  } catch (ignored) {
    // Not an error, just means user doesn't have a session.
    return false
  }
}

// Forces a new token to be retrieved even if the current one is valid.
export const forceRefreshSession = async () =>
  new Promise<void>(async resolve => {
    try {
      const currentSession = await Auth.currentSession()
      const user: CognitoUser = await Auth.currentAuthenticatedUser()
      user.refreshSession(currentSession.getRefreshToken(), () => resolve())
    } catch (e) {
      // If the user doesn't have a valid session just do nothing.
      resolve()
    }
  })

export const logIn = async (email: string, password: string) => {
  try {
    const user: CognitoUser = await Auth.signIn(email, password)
    const accessToken = user.getSignInUserSession()?.getIdToken().getJwtToken() || null
    if (accessToken) {
      return { success: true }
    }
  } catch (e) {
    if (e.message && e.message.includes('not confirmed')) {
      return { success: false, errorMessage: emailUnverifiedError }
    }
  }
  return { success: false, errorMessage: wrongPasswordError }
}

export const logOut = async () => {
  await Auth.signOut()
}

export const register = async (email: string, password: string) => {
  try {
    await Auth.signUp({ username: email, password })
    return { success: true }
  } catch (e) {
    let errorMessage
    if (e.code === 'InvalidParameterException' || e.code === 'InvalidPasswordException') {
      errorMessage = weakPasswordError
    } else if (e.code === 'UsernameExistsException') {
      errorMessage = emailAlreadyRegisteredError
    } else {
      errorMessage = genericError
    }
    return { success: false, errorMessage }
  }
}

export const confirmRegistration = async (email: string, code: string) => {
  try {
    await Auth.confirmSignUp(email, code)
    return { success: true }
  } catch (e) {
    let errorMessage
    if (e.code === 'CodeMismatchException' || e.code === 'ExpiredCodeException') {
      errorMessage = expiredCodeError
    } else if (e.message?.includes('Current status is CONFIRMED')) {
      errorMessage = alreadyVerifiedMessage
    } else {
      errorMessage = genericError
    }
    return { success: false, errorMessage }
  }
}

export const initiatePasswordReset = async (email: string) => {
  try {
    await Auth.forgotPassword(email)
    return { success: true }
  } catch (e) {
    let errorMessage
    if (e.code === 'LimitExceededException') {
      errorMessage = tooManyAttemptsError
    } else if (e.code === 'UserNotFoundException') {
      errorMessage = emailNotFoundError
    } else {
      errorMessage = genericError
    }
    return { success: false, errorMessage }
  }
}

export const submitNewPassword = async (email: string, code: string, newPassword: string) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword)
    return { success: true }
  } catch (e) {
    let errorMessage
    if (e.code === 'LimitExceededException') {
      errorMessage = tooManyAttemptsError
    } else if (e.code === 'CodeMismatchException' || e.code === 'ExpiredCodeException') {
      errorMessage = expiredCodeError
    } else if (e.code === 'InvalidParameterException' || e.code === 'InvalidPasswordException') {
      errorMessage = weakPasswordError
    } else {
      errorMessage = genericError
    }
    return { success: false, errorMessage }
  }
}

export const logInWithGoogle = async () => {
  try {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    return { success: true }
  } catch (e) {
    return { success: false, errorMessage: 'Oops, something went wrong.' }
  }
}

export const logInWithFacebook = async () => {
  try {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
    return { success: true }
  } catch (e) {
    return { success: false, errorMessage: 'Oops, something went wrong.' }
  }
}
