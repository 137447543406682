import OptionsMenuButton from 'components/shared/OptionsMenuButton/OptionsMenuButton'
import React, { useState } from 'react'
import { PreviewAlgolia } from 'state/types'

type Props = {
  preview: PreviewAlgolia
  options: any
}

const TableOptionButton: React.FC<Props> = ({ preview, options }) => {
  const [isShowing, setIsShowing] = useState(false)

  return <OptionsMenuButton isShowing={isShowing} options={options} setShowing={setIsShowing} />
}

export default TableOptionButton