import AnalyticsBreakdown from 'components/analytics/AnalyticsBreakdown/AnalyticsBreakdown'
import AnalyticsCta from 'components/analytics/AnalyticsCta/AnalyticsCta'
import AnalyticsOverview from 'components/analytics/AnalyticsOverview/AnalyticsOverview'
import OnboardedRoute from 'components/app/OnboardedRoute/OnboardedRoute'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import TabBar from 'components/shared/TabBar/TabBar'
import React from 'react'
import { Switch } from 'react-router-dom'
import useAuth from 'state/useAuth'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import { isOnPaymentPlanOrAdmin } from '../../../helpers/subscription'
import './AnalyticsPage.scss'

const tabs = [
  { to: '/analytics', label: 'Overview' },
  { to: '/analytics/breakdown', label: 'Breakdown by Preview' },
]

const AnalyticsPage: React.FC = () => {
  const { user } = useAuth()
  const { organizationsLoaded } = useWorkspace(true)
  const { isSubscriptionLoading, subscription } = useSubscription(organizationsLoaded)

  return (
    <>
      {isSubscriptionLoading ? (
        <LoadingSpinner container expand />
      ) : (
        <PageFrame
          className={'analytics-page' + (!user?.admin ? ' hide-tabs' : '')}
          header={
            <>
              <div className="top-row">
                <h1>Analytics</h1>
              </div>
              <TabBar tabs={tabs} />
              {!isOnPaymentPlanOrAdmin(subscription, user!) && (
                <AnalyticsCta
                  title="Upgrade your subscription to access Analytics"
                  body="We're collating data all the time to deliver insights on what really matters so you can make better decisions concerning your Previews and content."
                />
              )}
            </>
          }
        >
          <Switch>
            <OnboardedRoute exact path="/analytics" component={AnalyticsOverview} />
            <OnboardedRoute exact path="/analytics/breakdown" component={AnalyticsBreakdown} />
          </Switch>
        </PageFrame>
      )}
    </>
  )
}

export default AnalyticsPage
