import React from 'react'
import MenuButton from 'components/shared/MenuButton/MenuButton'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import BusinessLogoImageSelector from 'components/preview/BusinessLogoImageSelector/BusinessLogoImageSelector'
import './BusinessLogoEditor.scss'

type Props = {
  previewBusinessLogoImageStorageId?: string
  isBusinessLogoEditorShowing: boolean
  setBusinessLogoEditorShowing: (showing: boolean) => void
  updateBusinessLogoImage: (blob: Blob, onProgressChange: (progress: number) => void) => Promise<void>
  removeBusinessLogoImage: () => void
  hideButton?: boolean
}

const BusinessLogoEditor: React.FC<Props> = ({
  previewBusinessLogoImageStorageId,
  isBusinessLogoEditorShowing,
  setBusinessLogoEditorShowing,
  updateBusinessLogoImage,
  removeBusinessLogoImage,
  hideButton,
}) => {
  const deleteImageConfirmationText =
    'If you remove this image from your preview it will be permanently deleted. Would you like to continue?'

  return (
    <MenuButton
      className={'business-logo-editor' + (isBusinessLogoEditorShowing ? ' showing' : '')}
      buttonClassName="toolbar-option plain"
      buttonContent={
        !hideButton && (
          <>
            <span>Add Logo</span>
            <ChevronRightIcon />
          </>
        )
      }
      showing={isBusinessLogoEditorShowing}
      setShowing={setBusinessLogoEditorShowing}
    >
      <div className="business-logo-editor-content">
        <BusinessLogoImageSelector
          previewBusinessLogoImageStorageId={previewBusinessLogoImageStorageId}
          canDelete
          warnBeforeDeleting
          deleteConfirmationText={deleteImageConfirmationText}
          uploadButtonText="logo image"
          showUploadingLabel
          updateBusinessLogoImage={updateBusinessLogoImage}
          removeBusinessLogoImage={removeBusinessLogoImage}
        />
      </div>
    </MenuButton>
  )
}

export default BusinessLogoEditor
