import React from 'react'
import Tooltip from 'components/shared/Tooltip/Tooltip'
import './InvitationEditorStepper.scss'
import { InvitationEditorStep } from '../../../state/types'

type Props = {
  steps: InvitationEditorStep[]
  disable?: boolean
  showEnterpriseFeatures: boolean
  onClick: (value: { index: number }) => void
}

const InvitationEditorStepper: React.FC<Props> = ({ steps, disable, showEnterpriseFeatures, onClick }) => (
  <>
    {showEnterpriseFeatures && (
      <div className="invitation-editor-stepper">
        {steps.map(({ blockTitle, isActive, isComplete, isIncomplete }, i) => {
          const greenLine = i > 0 && isComplete && steps[i - 1].isComplete
          const tooltipText = `${i + 1}. ${blockTitle}`
          return (
            <React.Fragment key={i}>
              {i > 0 && <div className={'step-line' + (greenLine ? ' green' : '')} />}
              <Tooltip content={tooltipText}>
                <div
                  className={'step-button'}
                  onClick={
                    !disable
                      ? () => {
                          onClick({ index: i })
                        }
                      : () => {}
                  }
                >
                  <div
                    className={
                      'step' +
                      (isComplete ? ' completed' : isIncomplete ? ' incomplete' : '') +
                      (isActive && !isIncomplete ? ' active' : '')
                    }
                  />
                </div>
              </Tooltip>
            </React.Fragment>
          )
        })}
      </div>
    )}
  </>
)

export default InvitationEditorStepper
