import React from 'react'
import { Link } from 'react-router-dom'
import { getPublishedPreviewNavigationLink } from 'helpers/navigation'
import ViewPreviewArrowIcon from 'assets/icons/ViewPreviewArrowIcon'
import './ViewPreviewLink.scss'

type Props = {
  previewId: string
  isPublished: boolean
  fromParam?: number | 'publish' | 'editor' | 'analytics' // Used to add query param to link to know where to go back to.
}

const ViewPreviewLink: React.FC<Props> = ({ previewId, isPublished, fromParam }) => (
  <Link className="view-preview-link" to={getPublishedPreviewNavigationLink(previewId, fromParam)}>
    <span>{isPublished ? 'View Preview' : 'View draft'}</span>
    <ViewPreviewArrowIcon />
  </Link>
)

export default ViewPreviewLink
