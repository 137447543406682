import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import React from 'react'
import { useHistory } from 'react-router-dom'
import './LimitReachedPage.scss'
import * as previewService from 'services/previewService'
import { useUrlParams } from 'helpers/urlParams'
import { MaxPreviewsByCategoryForFree, PreviewCategory, PreviewMeAction } from 'helpers/enum'
import usePublishedPreview from '../../../state/usePublishedPreview'
import useBrandTheme from '../../../state/useBrandTheme'

const LimitReachedPage = () => {
  const { push } = useHistory()
  const { action } = useUrlParams('action')
  const { category } = useUrlParams('category')
  const { templateId } = useUrlParams('templateId')
  const { previewId } = useUrlParams('previewId')
  const {
    updateThemeByID
  } = usePublishedPreview()
  const {
    planType,
    brandModeData,
  } = useBrandTheme('main');

  const createPreview = async () => {
    const { ok, data } = await previewService.createPreview(templateId)
    if (ok && data) {
      if(brandModeData !== undefined) {
        if(Object.keys(brandModeData).length !== 0 && (planType === 'Enterprise' || planType === 'Professional' || planType === 'Business')) {
          updateThemeByID('custom-workspace-theme',data)
        }
        else {
          updateThemeByID('white',data)
        }
      }
      push(`/edit/${data}`)
    }
  }
  const savePreviewAsDraft = () => {
    push('/')
  }

  const upgradeNow = () => {
    push(`/plan/select?from=${encodeURIComponent(window.location.pathname + window.location.search)}`)
  }

  return (
    <FullScreenPageFrame
      className="example-page"
      cancelLink={action === PreviewMeAction.Publish ? `/edit/${previewId}/publish` : ``}
    >
      <div className="limit-reached-page">
        <div className="text-content">
          <h2>You've reached your limit!</h2>
          {category === PreviewCategory.Business ? (
            <>
              <p>
                Your Free Plan lets you publish{' '}
                <b>a total of {MaxPreviewsByCategoryForFree.Business} Business Preview</b> - you're currently maxed out!
              </p>
              {action === PreviewMeAction.Create ? (
                <p>(You can still continue to create this Business Preview, you just can't publish it.)</p>
              ) : (
                <p>Upgrade or unpublish an existing Business Preview to proceed</p>
              )}
            </>
          ) : category === PreviewCategory.Individual ? (
            <>
              <p>
                Your Free Plan lets you publish{' '}
                <b>a total of {MaxPreviewsByCategoryForFree.Individual} Individual Previews</b> - you're currently maxed
                out!
              </p>
              {action === PreviewMeAction.Create ? (
                <p>(You can still continue to create this Individual Preview, you just can't publish it.)</p>
              ) : (
                <p>Upgrade or unpublish an existing Individual Preview to proceed</p>
              )}
            </>
          ) : (
            ''
          )}
          <div className="limit-reached-buttons">
            <button className="button primary upgrade-button" onClick={upgradeNow}>
              Upgrade now
            </button>
            <p
              className="continue-button"
              onClick={
                action === PreviewMeAction.Create
                  ? createPreview
                  : action === PreviewMeAction.Publish
                  ? savePreviewAsDraft
                  : undefined
              }
            >
              {action === PreviewMeAction.Create ? (
                <b>Ok, continue anyway</b>
              ) : action === PreviewMeAction.Publish ? (
                <b>Ok, save as draft</b>
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
        <div className="img" />
      </div>
    </FullScreenPageFrame>
  )
}

export default LimitReachedPage
