import React, { createContext, useReducer, useContext } from 'react'
import { AppState } from 'state/types'
import reducer, { initialState } from 'state/reducer'
import Action from 'state/actions'

type ContextState = { state: AppState; dispatch: (action: Action) => void }

const Context = createContext<ContextState>({ state: initialState, dispatch: () => {} })

export const StoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
}

const useStore = () => useContext(Context)

export default useStore
