import React from 'react'
import { TeamMember } from 'state/types'
import './TeamMembersListMobile.scss'
import ProfileCell from '../../shared/ProfileCell/ProfileCell'
import DropdownMenu from '../../shared/DropdownMenu/DropdownMenu'
import SettingsIcon from '../../../assets/icons/SettingsIcon'
import { TeamMemberStatus } from '../../../helpers/enum'
import DropdownMenuOption from '../../shared/DropdownMenuOption/DropdownMenuOption'
import useAuth from '../../../state/useAuth'

type Props = {
  teamMembers: TeamMember[]
  deleteInvite: (email: string) => Promise<void>
  deleteTeamMember: (userId: string) => Promise<void>
}

const TeamMembersListMobile: React.FC<Props> = ({ teamMembers, deleteInvite, deleteTeamMember }) => {
  const { user } = useAuth()
  return (
    <div className="team-members-list-mobile">
      {teamMembers.map(({ userId, givenName, familyName, profileImageStorageId, email, status }, index) => {
        return (
          <div key={index} className="team-members-list-card">
            <ProfileCell
              givenName={givenName}
              familyName={familyName}
              email={email}
              profileImageId={profileImageStorageId}
            />
            <div className={'settings-button'}>
              {user && user.id !== userId && (
                <DropdownMenu
                  trigger={<SettingsIcon color={'black'} />}
                  menuContent={
                    <>
                      {status === TeamMemberStatus.Pending ? (
                        <DropdownMenuOption
                          optionName="Cancel invitation"
                          onSelect={async () => {
                            await deleteInvite(email)
                          }}
                        />
                      ) : (
                        <DropdownMenuOption
                          optionName="Remove from organization"
                          onSelect={async () => {
                            await deleteTeamMember(userId)
                          }}
                        />
                      )}
                    </>
                  }
                  horizontalAlignment="right"
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TeamMembersListMobile
