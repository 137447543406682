import PublishReviewDialog from 'components/preview/PublishReviewDialog/PublishReviewDialog'
import SharingDialog from 'components/preview/SharingDialog/SharingDialog'
import UnpublishConfirmationDialog from 'components/preview/UnpublishConfirmationDialog/UnpublishConfirmationDialog'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import Tooltip from 'components/shared/Tooltip/Tooltip'
import { formatDate, formatShortDate } from 'helpers/date'
import { PreviewMeObject, PreviewViewMode } from 'helpers/enum'
import { getDashboardTitles } from 'helpers/titles'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Preview, PreviewsQueryState } from 'state/types'
import LimitReachedDialog from '../LimitReachedDialog/LimitReachedDialog'
import './PreviewTable.scss'
import { MasterTickCheckBox, TickCheckBox } from './TickCheckBox'
import Table from 'components/shared/Table/Table'
import TableOptionButton from '../../shared/TableOptionButton/TableOptionButton'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import Pagination from 'components/shared/Table/Pagination'
import PreviewDuplicateModal from 'components/preview/PreviewDuplicateModal/PreviewDuplicateModal'
import * as previewService from '../../../services/previewService'
import useAuth from '../../../state/useAuth'
import useSubscription from '../../../state/useSubscription'

type Props = {
  previews: Preview[]
  sortingBy: string
  sortingAsc: boolean
  previewsSelected: Preview[]
  currentPage: number
  tableViewCurrentPage: number
  itemCount: number
  totalItemPerPage: number
  paginationRange: any
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  bulkSelectPreviews: (previews: Preview[]) => void
  onArchive: (previewId: string, isSuspended: boolean, viewMode?: PreviewViewMode) => Promise<void>
  onRestore: (previewId: string, isSuspended: boolean, viewMode?: PreviewViewMode) => Promise<void>
  onPublish: (previewId: string, isSuspended: boolean) => Promise<boolean>
  onUnpublish: (previewId: string, isSuspended: boolean) => Promise<boolean>
  checkCanPublish: (previewId: string) => Promise<{ isPublishable?: boolean; reviewLink?: string }>
  onDelete: (previewId: string, viewMode?: PreviewViewMode) => Promise<void>
  onDuplicate: (newPreview: Preview, duplicatedPreviewId?: string, viewMode?: PreviewViewMode) => Promise<void>
  onSelect: (preview: Preview) => void
  onUnselect: (id: string) => void
  onPageChange: (page: number) => void
  onTotalItemPerPageChange: (itemPerPage: number) => void
}

const PreviewTablePersonal: React.FC<Props> = ({
                                         previews,
                                         currentPage,
                                         sortingBy,
                                         sortingAsc,
                                         previewsSelected,
                                         tableViewCurrentPage,
                                         itemCount,
                                         totalItemPerPage,
                                         paginationRange,
                                         onSortingByChange,
                                         onSortingAscChange,
                                         bulkSelectPreviews,
                                         onArchive,
                                         onRestore,
                                         onPublish,
                                         onUnpublish,
                                         checkCanPublish,
                                         onDelete,
                                         onDuplicate,
                                         onSelect,
                                         onUnselect,
                                         onPageChange,
                                         onTotalItemPerPageChange,
                                       }) => {
  const [isShareDialogShowing, setShareDialogShowing] = useState(false)
  const [isUnpublishConfirmationDialogShowing, setUnpublishConfirmationDialogShowing] = useState(false)
  const [isArchiveConfirmationDialogShowing, setArchiveConfirmationDialogShowing] = useState(false)
  const [isDeleteConfirmationDialogShowing, setDeleteConfirmationDialogShowing] = useState(false)
  const [isDuplicateDialogShowing, setIsDuplicateDialogShowing] = useState(false)
  const [isLimitReachedDialogShowing, setLimitReachedDialogShowing] = useState(false)
  const [publishReviewLink, setPublishReviewLink] = useState('')
  const [selectedPreview, setSelectedPreview] = useState<Preview | null>(null)
  const {  subscription } = useAuth()
  useSubscription(true)
  const { push } = useHistory()
  const showSaveAsDraft = false

  const tooltipText = 'You have exceeded your published Previews limit on the plan'

  const onShareClick = (preview: Preview) => {
    setSelectedPreview(preview)
    setShareDialogShowing(true)
  }

  const showLimitReached = async () => {
    const { ok, data } = await previewService.getPreviewCategoryCount();

    if (ok && subscription?.plan?.name === 'Basic' && subscription.plan.status === 'active') {
      const threshold = subscription.plan.type === 'personal' ? 3 : 4;

      const previewCount = subscription.plan.type === 'personal'
        ? data?.individualPreviewsCount ?? 0
        : data?.businessPreviewsCount ?? 0;

      if (previewCount > threshold) {
        return true;
      }
    }

    return false;
  };

  const onPublishClick = async (preview: Preview) => {
    const { id, suspended } = preview
    setSelectedPreview(preview)

    const { isPublishable, reviewLink } = await checkCanPublish(id)
    if (await showLimitReached()) {
      setLimitReachedDialogShowing(true)
    }
    else if (isPublishable) {
      setLimitReachedDialogShowing(!(await onPublish(id, suspended)))
    } else if (reviewLink) {
      setPublishReviewLink(reviewLink)
    }
  }

  const onUnpublishClick = (preview: Preview) => {
    setSelectedPreview(preview)
    setUnpublishConfirmationDialogShowing(true)
  }

  const onPublishReviewClick = () => push(publishReviewLink)

  const onArchiveClick = (preview: Preview) => {
    setSelectedPreview(preview)
    if (preview.status === 'published') {
      setArchiveConfirmationDialogShowing(true)
    } else {
      onArchive(preview.id, preview.suspended, PreviewViewMode.TABLE_VIEW)
    }
  }

  const handleRestoreClick = (preview: Preview) => {
    onRestore(preview.id, preview.suspended, PreviewViewMode.TABLE_VIEW)
  }

  const handleDuplicateClick = (preview: Preview) => {
    setIsDuplicateDialogShowing(true)
    setSelectedPreview(preview)
  }

  const onDuplicateSuccess = (newPreview: Preview) => {
    onDuplicate(newPreview, selectedPreview?.id, PreviewViewMode.TABLE_VIEW)
  }

  const onDeleteClick = (preview: Preview) => {
    setSelectedPreview(preview)
    setDeleteConfirmationDialogShowing(true)
  }

  const onSelectAll = () => {
    bulkSelectPreviews(previews)
  }

  const onUnselectAll = () => {
    bulkSelectPreviews([])
  }

  const handlePageChange = (page: number) => {
    onUnselectAll()
    onPageChange(page)
  }

  const convertNumber = (number: number) => {
    if (number < 1000) return number

    const COUNT_ABBRS = ['', 'K', 'M', 'G']

    const i = 0 === number ? number : Math.floor(Math.log(number) / Math.log(1000))
    let result = parseFloat((number / Math.pow(1000, i)).toFixed(2)).toString()
    result += `${COUNT_ABBRS[i]}`

    return result
  }

  const handleSortingByChange = (sortingBy: string) => {
    onUnselectAll()
    onSortingByChange(sortingBy)
  }

  const handleSortingAscChange = (sortingAsc: boolean) => {
    onUnselectAll()
    onSortingAscChange(sortingAsc)
  }

  const handleTotalItemsPerPageChange = (itemPerPage: number) => {
    onUnselectAll()
    onTotalItemPerPageChange(itemPerPage)
  }
  return previews.length!== 0 && (
    <>
      <Table
        className="previews-table personal-item"
        columns={[
          {
            name: 'checkbox',
            label: (
              <MasterTickCheckBox
                isSelected={previews.length === previewsSelected.length}
                onSelect={onSelectAll}
                onUnselect={onUnselectAll}
              />
            ),
            className: 'preview-checkbox',
            render: preview => (
              <TickCheckBox
                preview={preview}
                isSelected={!!previewsSelected.find(p => p.id === preview.id)}
                onSelect={onSelect}
                onUnselect={onUnselect}
              />
            ),
            sortable: false,
          },
          {
            name: 'status',
            label: 'Status',
            className: 'status',
            render: preview => {
              const { archived, firstPublishDate, lastPublishDate, status } = preview
              const isPublished = preview.status === 'published'
              return preview.suspended ? (
                <Tooltip className="suspended-preview-tooltip" content={tooltipText}>
                  <div className="preview-status content suspended" onClick={e => e.preventDefault()}>
                    Suspended
                  </div>
                </Tooltip>
              ) : (
                <div className={'preview-status content ' + (preview.archived ? 'archived' : status)}>
                  {archived
                    ? 'Archived'
                    : isPublished
                      ? lastPublishDate
                        ? 'Published ' + formatShortDate(lastPublishDate.toString())
                        : firstPublishDate
                          ? 'Published ' + formatShortDate(firstPublishDate.toString())
                          : 'Published'
                      : 'Draft'}
                </div>
              )
            },
          },
          {
            name: 'title',
            label: 'Primary Title',
            className: 'title-1',
            render: preview => {
              const { title } = getDashboardTitles(preview)
              return <Link to={`/preview/${preview.id}`}>{title}</Link>
            },
          },
          {
            name: 'subtitle',
            label: 'Subtitle',
            className: 'title-2',
            render: preview => {
              const { subtitle } = getDashboardTitles(preview)
              return !!subtitle ? subtitle : '-'
            },
          },
          {
            name: 'type',
            label: 'Type',
            className: 'type',
            render: preview => {
              return preview.invitation ? "Invitation" : preview.previewCategory
            }
          },
          {
            name: 'closedDate',
            label: 'Closed Date',
            className: 'closedDate',
            render: preview => {
              return preview.invitation?.closed ? formatDate(preview?.invitation?.endDate || '') : "-"
            }
          },
          {
            name: 'views',
            label: 'Views',
            className: 'views',
            render: preview => {
              return preview.views ? convertNumber(preview.views) : '0'
            }
          },
          {
            name: 'settings',
            className: 'settings',
            render: preview => {
              const { id, archived, singular, invitation } = preview
              const isPublished = preview.status === 'published'
              const isCategoryIndividual = preview.previewCategory === 'individual' && preview.invitation === undefined
              const isCategoryBusiness = preview.previewCategory === 'business' && preview.invitation === undefined
              const isCategoryInvitation = preview.invitation !== undefined
              const hasNoCategory = !(isCategoryIndividual || isCategoryBusiness || isCategoryInvitation)
              return (
                <TableOptionButton
                  options={[
                    { option: 'Edit', onClick: () => push(`/edit/${id}`), hide: preview.archived },
                    { option: 'Share', onClick: () => onShareClick(preview), hide: !isPublished },
                    { option: 'Publish', onClick: () => onPublishClick(preview), hide: archived || isPublished },
                    { option: 'Unpublish', onClick: () => onUnpublishClick(preview), hide: archived || !isPublished },
                    { option: 'Restore', onClick: () => handleRestoreClick(preview), hide: !archived },
                    {
                      option: 'Duplicate',
                      onClick: () => handleDuplicateClick(preview),
                      hide: singular || !!invitation || hasNoCategory,
                    },
                    { option: 'Archive', onClick: () => onArchiveClick(preview), hide: archived },
                    { option: 'Delete', onClick: () => onDeleteClick(preview), hide: !archived },
                  ]}
                ></TableOptionButton>
              )
            },
            notLink: true,
            sortable: false,
          },
        ]}
        rows={previews}
        sortable
        sortingBy={sortingBy}
        sortingAsc={sortingAsc}
        onSortingByChange={handleSortingByChange}
        onSortingAscChange={handleSortingAscChange}
      />

      {selectedPreview && (
        <>
          <SharingDialog
            id={selectedPreview.id}
            isShowing={isShareDialogShowing}
            onClose={() => setShareDialogShowing(false)}
            object={PreviewMeObject.Preview}
          />
          <UnpublishConfirmationDialog
            isShowing={isUnpublishConfirmationDialogShowing}
            isClosed={selectedPreview.invitation?.closed || selectedPreview.closedTemplate}
            endDate={selectedPreview.invitation?.endDate || selectedPreview.endDate}
            invitationOwner={selectedPreview.invitation?.ownerName}
            onClose={() => setUnpublishConfirmationDialogShowing(false)}
            onOk={() => onUnpublish(selectedPreview.id, selectedPreview.suspended)}
          />
          <UnpublishConfirmationDialog
            isShowing={isArchiveConfirmationDialogShowing}
            isClosed={selectedPreview.invitation?.closed || selectedPreview.closedTemplate}
            endDate={selectedPreview.invitation?.endDate || selectedPreview.endDate}
            isArchiving={true}
            invitationOwner={selectedPreview.invitation?.ownerName}
            onClose={() => setArchiveConfirmationDialogShowing(false)}
            onOk={() => onArchive(selectedPreview.id, selectedPreview.suspended, PreviewViewMode.TABLE_VIEW)}
          />
          <PublishReviewDialog
            isShowing={!!publishReviewLink}
            onClose={() => setPublishReviewLink('')}
            onOk={onPublishReviewClick}
          />
          <LimitReachedDialog
            showSaveAsDraft={showSaveAsDraft}
            category={selectedPreview.previewCategory!}
            isShowing={isLimitReachedDialogShowing}
            onClose={() => setLimitReachedDialogShowing(false)}
          />
          <ConfirmationDialog
            className="delete-confirmation-dialog"
            isShowing={isDeleteConfirmationDialogShowing}
            isWarning
            cancelButtonText="No, thanks"
            okButtonText={`Yes, delete this Preview`}
            title="Heads up!"
            onClose={() => setDeleteConfirmationDialogShowing(false)}
            onOk={() => onDelete(selectedPreview.id, PreviewViewMode.TABLE_VIEW)}
          >
            This will permanently delete all videos and other content within. Are you sure you wish to continue?
          </ConfirmationDialog>
          <PreviewDuplicateModal
            isShowing={isDuplicateDialogShowing}
            hideModal={() => setIsDuplicateDialogShowing(false)}
            preview={selectedPreview}
            onDuplicateSuccess={preview => {
              if (preview) onDuplicateSuccess(preview)
            }}
          />
        </>
      )}

      <div className="previews-page-mobile-content">
        <div className="previews-list-mobile">
          {previews.map(preview => {
            const { id, archived, singular, invitation, suspended, firstPublishDate, views } = preview
            const isPublished = preview.status === 'published'
            const isCategoryIndividual = preview.previewCategory === 'individual' && preview.invitation === undefined
            const isCategoryBusiness = preview.previewCategory === 'business' && preview.invitation === undefined
            const isCategoryInvitation = preview.invitation !== undefined
            const hasNoCategory = !(isCategoryIndividual || isCategoryBusiness || isCategoryInvitation)
            const { title, subtitle } = getDashboardTitles(preview)

            return (
              <div key={id} className="preview-list-card">
                <div className="preview-list-header">
                  <Link className="preview-title" to={`/preview/${id}`}>
                    {title}
                  </Link>
                  <TableOptionButton
                    options={[
                      { option: 'Edit', onClick: () => push(`/edit/${id}`), hide: preview.archived },
                      { option: 'Share', onClick: () => onShareClick(preview), hide: !isPublished },
                      { option: 'Publish', onClick: () => onPublishClick(preview), hide: archived || isPublished },
                      { option: 'Unpublish', onClick: () => onUnpublishClick(preview), hide: archived || !isPublished },
                      { option: 'Restore', onClick: () => handleRestoreClick(preview), hide: !archived },
                      {
                        option: 'Duplicate',
                        onClick: () => handleDuplicateClick(preview),
                        hide: singular || !!invitation || hasNoCategory,
                      },
                      { option: 'Archive', onClick: () => onArchiveClick(preview), hide: archived },
                      { option: 'Delete', onClick: () => onDeleteClick(preview), hide: !archived },
                    ]}
                  ></TableOptionButton>
                </div>
                <div className="sub-title">{subtitle}</div>

                <div className="preview-footer">
                  <div className="status">
                    {suspended ? (
                      <Tooltip className="suspended-preview-tooltip" content={tooltipText}>
                        <div className="preview-status suspended" onClick={e => e.preventDefault()}>
                          Suspended
                        </div>
                      </Tooltip>
                    ) : (
                      <div className={'preview-status' + (archived ? ' archived' : isPublished ? ' published' : '')}>
                        {archived
                          ? 'Archived'
                          : isPublished
                            ? firstPublishDate
                              ? 'Published ' + formatShortDate(firstPublishDate.toString())
                              : 'Published'
                            : 'Draft'}
                      </div>
                    )}
                  </div>
                    {views > 2 ? convertNumber(views) + ' views' : views + ' view'}
                </div>
                <Link className="details-link" to={`/preview/${id}`}>
                  <div className="">
                    <TickCheckBox
                      preview={preview}
                      isSelected={!!previewsSelected.find(p => p.id === preview.id)}
                      onSelect={onSelect}
                      onUnselect={onUnselect}
                    />
                  </div>
                  <ChevronRightIcon />
                </Link>
              </div>
            )
          })}
        </div>
      </div>
      <Pagination
        className="preview-pagination"
        currentPage={tableViewCurrentPage}
        totalCount={itemCount}
        pageSize={totalItemPerPage}
        onPageChange={(page: number) => handlePageChange(page)}
        onChangeTotalItemsPerPage={(itemPerPage: number) => handleTotalItemsPerPageChange(itemPerPage)}
        paginationRange={paginationRange}
      />
    </>
  )
}

export default PreviewTablePersonal
