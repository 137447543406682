import React from 'react'
import { ContentBlockField } from 'state/types'
import { parseKeyValuePair } from 'helpers/validation'
import './PublishedPreviewKeyValuePair.scss'

type Props = {
  fields: ContentBlockField[]
}

const PublishedPreviewKeyValuePair: React.FC<Props> = ({ fields }) => {
  return (
    <div>
      {fields.map(field => {
        const { key, value } = parseKeyValuePair(field.value)
        return key && value && <div key={field.id}>{`${key}: ${value}`}</div>
      })}
    </div>
  )
}

export default PublishedPreviewKeyValuePair
