import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PreviewTheme } from 'state/types'
import ThemeSelector from 'components/preview/ThemeSelector/ThemeSelector'
import OptionsMenuButton from 'components/shared/OptionsMenuButton/OptionsMenuButton'
import BackLink from 'components/shared/BackLink/BackLink'
import BusinessLogoEditor from 'components/preview//BusinessLogoEditor/BusinessLogoEditor'
import './PublishedPreviewMobileToolbar.scss'
import useWorkspace from '../../../state/useWorkspace'
import * as organizationBrandSettingService from '../../../services/organizationBrandSettingService'

type Props = {
  isPublished: boolean
  isArchived: boolean
  isSuspended: boolean
  backLink: string
  backLinkText: string
  editLink: string
  theme?: PreviewTheme
  businessLogoImageStorageId?: string
  updateBusinessLogoImage: (blob: Blob, onProgressChange: (progress: number) => void) => Promise<void>
  removeBusinessLogoImage: () => void
  onTogglePublishClick: () => void
  onShareClick: () => void
  updateTheme: (theme?: PreviewTheme) => void
  allowThemeUpdate: boolean
  allowCustomisation: boolean
  planType:string
}

const PublishedPreviewMobileToolbar: React.FC<Props> = ({
  isPublished,
  isArchived,
  isSuspended,
  backLink,
  backLinkText,
  editLink,
  theme,
  businessLogoImageStorageId,
  updateBusinessLogoImage,
  onTogglePublishClick,
  removeBusinessLogoImage,
  onShareClick,
  updateTheme,
  allowThemeUpdate,
  allowCustomisation,
  planType
}) => {
  const { push } = useHistory()
  const [isMenuButtonShowing, setMenuButtonShowing] = useState(false)
  const [isThemeSelectorShowing, setThemeSelectorShowing] = useState(false)
  const [isBusinessLogoEditorShowing, setBusinessLogoEditorShowing] = useState(false)
  const [isDataLoaded, setDataLoaded] = useState(false)
  const { organizationsLoaded, currentWorkspace } = useWorkspace(true)
  const [brandModeDataGet, setBrandModeDataGet] = useState('')

  const options = [
    { option: 'Editor', onClick: () => push(editLink) },
    { option: 'Theme', onClick: () => setThemeSelectorShowing(true), hide: !allowThemeUpdate },
    { option: 'Add Logo', onClick: () => setBusinessLogoEditorShowing(true), hide: !allowCustomisation },
    { option: 'Share', onClick: onShareClick, hide: !isPublished },
    { option: 'Unpublish', onClick: onTogglePublishClick, hide: !isPublished },
  ]

  useEffect(() => {
    getOrganizationBrandSettingByOrganization()

  }, [brandModeDataGet])

  const getOrganizationBrandSettingByOrganization = async () => {
    if (currentWorkspace?.id) {
      setDataLoaded(false)
      const { data, ok } = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(
        currentWorkspace.id
      )
      setDataLoaded(true)
      setBrandModeDataGet(data?.brandMode ?? '')
      return ok
    }
  }

  const onMenuButtonShowingChange = (showing: boolean) => {
    // Dont open menu if this click is the click that closed the theme selector popup.
    if (!showing || !document.querySelector('.theme-selector .popup')) {
      setMenuButtonShowing(showing)
    }
  }

  return (
    <nav className="published-preview-mobile-toolbar">
      <div className="top-row">
        <BackLink to={backLink}>{backLinkText}</BackLink>
        {!isPublished && (
          <button onClick={onTogglePublishClick} className="publish-button">
            Publish
          </button>
        )}
        {!isArchived && (
          <OptionsMenuButton isShowing={isMenuButtonShowing} options={options} setShowing={onMenuButtonShowingChange} />
        )}
      </div>
      <div className={'preview-status' + (isPublished ? ' published' : isSuspended ? ' suspended' : '')}>
        {isPublished ? 'Published' : isSuspended ? 'Suspended' : 'Draft'}
      </div>
      <ThemeSelector
        isShowing={isThemeSelectorShowing}
        theme={theme}
        hideButton
        planType={planType}
        setShowing={setThemeSelectorShowing}
        onThemeChange={updateTheme}
        brandModeDataGet={brandModeDataGet}
      />
      <BusinessLogoEditor
        previewBusinessLogoImageStorageId={businessLogoImageStorageId}
        isBusinessLogoEditorShowing={isBusinessLogoEditorShowing}
        setBusinessLogoEditorShowing={setBusinessLogoEditorShowing}
        updateBusinessLogoImage={updateBusinessLogoImage}
        removeBusinessLogoImage={removeBusinessLogoImage}
        hideButton
      />
    </nav>
  )
}

export default PublishedPreviewMobileToolbar
