import React, { useState } from 'react'
import { Invitation } from 'state/types'
import './InvitationsTable.scss'
import SharingDialog from 'components/preview/SharingDialog/SharingDialog'
import { PreviewMeObject } from 'helpers/enum'
import InvitationDuplicateModal from '../InvitationDuplicateModal/InvitationDuplicateModal'
import ArchiveInvitationDialog from '../ArchiveInvitationDialog/ArchiveInvitationDialog'
import GeneralInvitationsTable from '../../GeneralInvitationsTable/GeneralInvitationsTable'

type Props = {
  invitations: Invitation[]
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  loadInvitations: (duplicate?: boolean) => void
  onArchiveChange: (invitationId: string, nowArchived: boolean) => void
}

const InvitationsTable: React.FC<Props> = ({
  invitations,
  sortingBy,
  sortingAsc,
  onSortingByChange,
  onSortingAscChange,
  loadInvitations,
  onArchiveChange,
}) => {
  const [showShareDialog, setShareDialog] = useState(false)
  const [shareInvitationId, setShareInvitationId] = useState<string>()
  const [organisationName, setOrganisationName] = useState<string>()

  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const [invitationToBeDuplicated, setDuplicateInvitation] = useState<Invitation>()

  const [invitationToBeArchived, setInvitationToBeArchived] = useState<Invitation>()
  const [showArchiveDialog, setShowArchiveDialog] = useState(false)

  const onShare = function (invitation: Invitation) {
    setShareDialog(true)
    setShareInvitationId(invitation.id)
    setOrganisationName(invitation.ownerName)
  }

  const onDuplicate = function (invitation: Invitation) {
    setShowDuplicateModal(true)
    setDuplicateInvitation(invitation)
  }

  const onArchive = function (invitation: Invitation) {
    setShowArchiveDialog(true)
    setInvitationToBeArchived(invitation)
  }

  const dropdownMenuContent = [
    { name: 'Share', onSelect: onShare },
    { name: 'Duplicate', onSelect: onDuplicate },
    { name: 'Archive', onSelect: onArchive },
  ]

  return (
    <>
      <SharingDialog
        id={shareInvitationId!}
        isShowing={showShareDialog}
        onClose={() => {
          setShareDialog(false)
          setShareInvitationId(undefined)
        }}
        object={PreviewMeObject.Invitation}
        invitationOrgName={organisationName}
      />
      {invitationToBeDuplicated && (
        <InvitationDuplicateModal
          isShowing={showDuplicateModal}
          hideModal={() => setShowDuplicateModal(false)}
          invitation={invitationToBeDuplicated}
          loadInvitations={loadInvitations}
        />
      )}
      {invitationToBeArchived && showArchiveDialog && (
        <ArchiveInvitationDialog
          onClose={() => setShowArchiveDialog(false)}
          invitation={invitationToBeArchived}
          onArchiveChange={onArchiveChange}
        />
      )}
      <GeneralInvitationsTable
        invitations={invitations}
        sortingBy={sortingBy}
        sortingAsc={sortingAsc}
        onSortingByChange={onSortingByChange}
        onSortingAscChange={onSortingAscChange}
        dropdownMenuContent={dropdownMenuContent}
      />
    </>
  )
}

export default InvitationsTable
