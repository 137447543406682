import { IntegrationType } from 'helpers/enum'
import { Integration, IntegrationSetting, IntegrationsResponse, TagOption } from 'state/types'
import { getApi, getApiWithAuthorization } from './api'

export const getEnabledIntegrationsByOrganization = async (organizationId: string) => {
  const api = await getApi()
  return api.get<Integration[]>(`/api/enabledIntegrations/organization/${organizationId}`)
}

export const getAllIntegrationsByOrganization = async (organizationId: string, archived: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<IntegrationsResponse>(
    `/api/integrations/organization/${organizationId}?archived=${!!archived}`
  )
}

export const updateIntegrationEnabled = async (organizationId: string, integrationId: string, value: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<void>(
    `/api/integration/${integrationId}/updateEnabled/organization/${organizationId}?value=${value}`
  )
}

export const updateIntegrationArchived = async (organizationId: string, integrationId: string, value: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<void>(
    `/api/integration/${integrationId}/updateArchived/organization/${organizationId}?value=${value}`
  )
}

export const updateIntegrationPlatformIdOrKey = async (
  organizationId: string,
  integrationId: string,
  value: string
) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<void>(
    `/api/integration/${integrationId}/updatePlatformIdOrKey/organization/${organizationId}?value=${value}`
  )
}

export const updateIntegrationName = async (organizationId: string, integrationId: string, value: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<void>(
    `/api/integration/${integrationId}/updateName/organization/${organizationId}?value=${value}`
  )
}

export const deleteIntegrationById = async (organizationId: string, integrationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.delete<boolean>(`/api/integration/${integrationId}?organizationId=${organizationId}`)
}

export const createIntegration = async (
  organizationId: string,
  integrationType: IntegrationType,
  integrationName: string
) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.post<Integration>(
    `/api/integration/${integrationType}?organizationId=${organizationId}&name=${integrationName}`
  )
}

export const getIntegrationById = async (organizationId: string, integrationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<Integration>(`/api/integration/${integrationId}?organizationId=${organizationId}`)
}

export const saveIntegrationSettings = async (
  organizationId: string,
  integrationId: string,
  settings: IntegrationSetting[]
) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.put<IntegrationSetting[]>(
    `/api/saveIntegrationSettings?organizationId=${organizationId}`,
    settings
  )
}

export const getIntegrationSettingsByIntegrationId = async (organizationId: string, integrationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<IntegrationSetting[]>(
    `/api/getIntegrationSettings/${integrationId}?organizationId=${organizationId}`
  )
}
