import React, { useEffect, useState } from 'react'
import ConfirmationDialog from '../../shared/ConfirmationDialog/ConfirmationDialog'
import { updateArchived } from '../../../services/invitationService'
import { Invitation } from '../../../state/types'

interface Props {
  onClose: () => void
  invitation: Invitation
  onArchiveChange: (invitationId: string, nowArchived: boolean) => void
}

const ArchiveInvitationDialog = function ({ onClose, invitation, onArchiveChange }: Props) {
  const [isConfirm, setIsConfirm] = useState(false)

  const canArchive = isConfirm || invitation.closed || invitation.archived

  useEffect(() => {
    if (canArchive) {
      updateInvitationArchived()
    }
  }, [isConfirm])

  const updateInvitationArchived = async function () {
    const { ok } = await updateArchived(invitation.id, !invitation.archived)
    if (ok) {
      onArchiveChange(invitation.id, !invitation.archived)
    }
    onClose()
  }

  if (canArchive) return <></>

  return (
    <ConfirmationDialog
      className="unpublish-preview-confirmation-dialog"
      isShowing={true}
      isWarning
      cancelButtonText="No, cancel that"
      okButtonText={`Yes, archive Invitation`}
      title={`Are you sure you want to archive?`}
      onClose={onClose}
      onOk={() => setIsConfirm(true)}
    >
      By archiving your Invitation, the Invitation will automatically close and no new applications will be able to be
      submitted.
    </ConfirmationDialog>
  )
}

export default ArchiveInvitationDialog
