import React, { useState, useEffect } from 'react';
import { PreviewTheme } from 'state/types'
import MenuButton from 'components/shared/MenuButton/MenuButton'
import SelectionRing from 'components/shared/SelectionRing/SelectionRing'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import './ThemeSelector.scss'
import useBrandTheme from '../../../state/useBrandTheme'

const themeOptionsWithoutCustom: { option?: PreviewTheme; label: string; className: string }[] = [
  {
    option: 'light',
    className: 'light',
    label: 'Light',
  },
  {
    option: 'dark',
    className: 'dark',
    label: 'Dark',
  },
  {
    option: 'contrast',
    className: 'contrast',
    label: 'Contrast',
  },
  {
    option: 'forest',
    className: 'forest',
    label: 'Forest',
  },
  {
    option: 'white',
    className: 'white',
    label: 'White',
  },
  {
    option: 'sky',
    className: 'sky',
    label: 'Sky',
  },
  {
    option: 'business-blue',
    className: 'business-blue',
    label: 'Business Blue',
  },
]

const themeOptionsWithCustom: { option?: PreviewTheme; label: string; className: string }[] = [
  {
    option: 'custom-workspace-theme',
    className: 'white',
    label: 'Workspace Theme',
  },
  {
    option: 'light',
    className: 'light',
    label: 'Light',
  },
  {
    option: 'dark',
    className: 'dark',
    label: 'Dark',
  },
  {
    option: 'contrast',
    className: 'contrast',
    label: 'Contrast',
  },
  {
    option: 'forest',
    className: 'forest',
    label: 'Forest',
  },
  {
    option: 'white',
    className: 'white',
    label: 'White',
  },
  {
    option: 'sky',
    className: 'sky',
    label: 'Sky',
  },
  {
    option: 'business-blue',
    className: 'business-blue',
    label: 'Business Blue',
  },
]

type Props = {
  isShowing: boolean
  theme?: PreviewTheme
  hideButton?: boolean
  setShowing: (showing: boolean) => void
  onThemeChange: (theme?: PreviewTheme) => void
  brandModeDataGet:string
  planType:string
}

const ThemeSelector: React.FC<Props> = ({ planType, isShowing, theme, hideButton, setShowing, onThemeChange, brandModeDataGet }) => {
  const [selectedThemeOptions, setSelectedThemeOptions] = useState<typeof themeOptionsWithoutCustom | null>(null);
  const {
    brandModeData,
  } = useBrandTheme('main');
  useEffect(() => {
    if (brandModeData !== undefined && Object.keys(brandModeData).length !== 0 && (planType === 'Enterprise' || planType === 'Professional' || planType === 'Business')) {
      setSelectedThemeOptions(themeOptionsWithCustom);
    } else {
      setSelectedThemeOptions(themeOptionsWithoutCustom);
    }
  }, [brandModeDataGet, planType]);

  return (

  <MenuButton
    className={'theme-selector' + (isShowing ? ' showing' : '')}
    buttonClassName="toolbar-option plain"
    buttonContent={
      !hideButton && (
        <>
          <span>Theme</span>
          <ChevronRightIcon />
        </>
      )
    }
    showing={isShowing}
    setShowing={setShowing}
  >

    <div className="theme-selector-content">
      {selectedThemeOptions && selectedThemeOptions.map(({ option, className, label }) => (
        <>

        <button
          key={label}
          className="theme-button plain 1"
          onClick={() => {
            setShowing(false)
            onThemeChange(option)
          }}
        >
          <div className={'theme-example-graphic ' + className} />
          <div className="theme-label">
            <SelectionRing selected={option === theme} />
            <div className="theme-label-text">{label}</div>
          </div>
        </button>
        </>
      ))}
    </div>
  </MenuButton>
);
}

export default ThemeSelector
