import React from 'react'

const AnalyticsIcon = () => (
  <svg className="icon" width="21px" height="20px" viewBox="0 0 21 20">
    <g strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0.5" y="12.5" width="4" height="7"></rect>
      <rect x="8.5" y="6.5" width="4" height="13"></rect>
      <rect x="16.5" y="0.5" width="4" height="19"></rect>
    </g>
  </svg>
)

export default AnalyticsIcon
