import React from 'react'
import './PermissionsPage.scss'
import PageFrame from '../../shared/PageFrame/PageFrame'
import BackLink from '../../shared/BackLink/BackLink'
import { useHistory } from 'react-router'
import ProfileCell from '../../shared/ProfileCell/ProfileCell'
import { useLocation } from 'react-router-dom'
import { customTheme } from '../../shared/RadioButtonToggle/MuiTheme'
import { FormControlLabel, FormLabel, Radio, RadioGroup, ThemeProvider } from '@material-ui/core'
import * as organizationService from '../../../services/organizationService'
import { OrganizationUserPermission } from '../../../state/types'
import { TeamMemberType } from '../../../helpers/enum'

type Props = {
  orgId: string
  userId: string
  defaultOption?: string
}
const PermissionListing: React.FC<Props> = ({defaultOption, orgId, userId}) => {
  const [selectedOption, setSelectedOption] = React.useState<string|undefined>(defaultOption);

  const updatePermission = async (value: string) => {
    setSelectedOption(value)
    // for now do not process `guest` permission
    if (value && value.trim().length > 1) {
      const key = Object.keys(TeamMemberType).find(k => TeamMemberType[k] === value)
      if (key !== undefined) {
        const payload: OrganizationUserPermission = {
          type: TeamMemberType[key as keyof typeof TeamMemberType]
        }
        await organizationService.updateOrgUserPermission(orgId, userId, payload)
      } else {
        console.error(`Invalid TeamMemberType value: ${value}`)
      }
    }
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    try {
      await updatePermission(value)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <ThemeProvider theme={customTheme}>
      <FormLabel>User Permissions</FormLabel><br />
      <RadioGroup value={selectedOption} onChange={handleChange}>
        <FormControlLabel key="1" value="owner" control={<Radio />} label="Owner" />
        <p className="permission-description">Created the Workspace. Has unlimited access to all Workspace plan features and settings including adding or removing all member classifications and billing.</p>
        <FormControlLabel key="2" value="administrator" control={<Radio />} label="Admin" />
        <p className="permission-description">Has unlimited access to all Workspace plan features and settings including adding or removing all member classifications and billing.</p>
        <FormControlLabel key="3" value="team member" control={<Radio />} label="Member" />
        <p className="permission-description">Has access to Workspace plan features. Cannot access Workspace Settings, member management or billing.</p>
      </RadioGroup>
    </ThemeProvider>
  )
}

const PermissionsPage: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const previousPath = history.location.state?.from
  const profileRowData = location.state?.profileRowData

  return (
    <PageFrame className="manage-permissions-page" header={
      <div className="link-row">
        <BackLink to={previousPath}>Back</BackLink>
      </div>
    }>
      <ProfileCell {...profileRowData} /><br />
      <PermissionListing
        defaultOption={profileRowData.type}
        orgId={profileRowData.organizationId}
        userId={profileRowData.userId}
      />
    </PageFrame>
  )
}

export default PermissionsPage