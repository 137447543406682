import React from 'react'

type Props = {
  index: number
  setPageIndex: (value: number) => void
}
const LearnMoreIndicator: React.FC<Props> = ({ index, setPageIndex }) => (
  <svg width="74px" height="11px" viewBox="0 0 74 11">
    <title>F2DB40D0-7DBD-442C-8986-AFF33A6AE7D6</title>
    <g id="Upgrade-Features---pop-ups" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Invitations---Learn-More-pop-up-1" transform="translate(-863.000000, -703.000000)">
        <g id="Group-16" transform="translate(863.000000, 703.000000)">
          {index === 0 ? (
            <>
              <circle
                id="Oval"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="5.5"
                cy="5.5"
                r="5.5"
                onClick={() => setPageIndex(0)}
              ></circle>
              <circle
                id="Oval-Copy"
                cursor={'pointer'}
                stroke="#01A3F8"
                fill="#ffffff"
                cx="26.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(1)}
              ></circle>
              <circle
                id="Oval-Copy-2"
                cursor={'pointer'}
                stroke="#01A3F8"
                fill="#ffffff"
                cx="47.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(2)}
              ></circle>
              <circle
                id="Oval-Copy-3"
                cursor={'pointer'}
                stroke="#01A3F8"
                fill="#ffffff"
                cx="68.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(3)}
              ></circle>
            </>
          ) : index === 1 ? (
            <>
              <circle
                id="Oval"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="5.5"
                cy="5.5"
                r="5.5"
                onClick={() => setPageIndex(0)}
              ></circle>
              <circle
                id="Oval-Copy"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="26.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(1)}
              ></circle>
              <circle
                id="Oval-Copy-2"
                cursor={'pointer'}
                stroke="#01A3F8"
                fill="#ffffff"
                cx="47.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(2)}
              ></circle>
              <circle
                id="Oval-Copy-3"
                cursor={'pointer'}
                stroke="#01A3F8"
                fill="#ffffff"
                cx="68.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(3)}
              ></circle>
            </>
          ) : index === 2 ? (
            <>
              <circle
                id="Oval"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="5.5"
                cy="5.5"
                r="5.5"
                onClick={() => setPageIndex(0)}
              ></circle>
              <circle
                id="Oval-Copy"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="26.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(1)}
              ></circle>
              <circle
                id="Oval-Copy-2"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="47.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(2)}
              ></circle>
              <circle
                id="Oval-Copy-3"
                cursor={'pointer'}
                stroke="#01A3F8"
                fill="#ffffff"
                cx="68.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(3)}
              ></circle>
            </>
          ) : (
            <>
              <circle
                id="Oval"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="5.5"
                cy="5.5"
                r="5.5"
                onClick={() => setPageIndex(0)}
              ></circle>
              <circle
                id="Oval-Copy"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="26.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(1)}
              ></circle>
              <circle
                id="Oval-Copy-2"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="47.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(2)}
              ></circle>
              <circle
                id="Oval-Copy-3"
                cursor={'pointer'}
                fill="#01A3F8"
                cx="68.5"
                cy="5.5"
                r="5"
                onClick={() => setPageIndex(3)}
              ></circle>
            </>
          )}
        </g>
      </g>
    </g>
  </svg>
)

export default LearnMoreIndicator
