import React from 'react'
import errorIllustration from 'assets/error-illustration.png'
import './ErrorWidget.scss'

type Props = {
  title: string
  message: string
}

const ErrorWidget: React.FC<Props> = ({ title, message }) => (
  <div className="error-widget">
    <div className="text">
      <h2>{title}</h2>
      <div className="message">{message}</div>
    </div>
    <div className="illustration" style={{ backgroundImage: `url('${errorIllustration}')` }} />
  </div>
)

export default ErrorWidget
