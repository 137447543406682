import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import useAuth from 'state/useAuth'
import FormInput from 'components/shared/FormInput/FormInput'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import SuccessDialog from 'components/shared/SuccessDialog/SuccessDialog'
import './AccountChangePasswordForm.scss'

type FormData = {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('Please enter your old password'),
  newPassword: yup.string().required('Please enter your new password'),
  confirmNewPassword: yup.string().required('Please confirm your new password'),
})

const AccountChangePasswordForm = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false)
  const [oldPasswordIncorrect, setOldPasswordIncorrect] = useState(false)
  const [isSuccessDialogShowing, setSuccessDialogShowing] = useState(false)
  const { user, changePassword } = useAuth()
  const { handleSubmit, register, errors, reset } = useForm<FormData>({ validationSchema })

  const onSubmit = handleSubmit(async ({ oldPassword, newPassword, confirmNewPassword }) => {
    setPasswordsDontMatch(newPassword !== confirmNewPassword)
    setOldPasswordIncorrect(false)

    if (newPassword === confirmNewPassword) {
      setSubmitting(true)
      const status = await changePassword(oldPassword, newPassword)
      if (status === 200) {
        setSuccessDialogShowing(true)
        reset()
      } else if (status === 403) {
        setOldPasswordIncorrect(true)
      }
      setSubmitting(false)
    }
  })

  return user?.socialAuth ? (
    <Redirect to="/account" />
  ) : (
    <form className="account-change-password-form" onSubmit={onSubmit} noValidate>
      <div className="form-section-header">Change password</div>
      <FormInput
        name="oldPassword"
        label="Old password"
        inputRef={register}
        error={oldPasswordIncorrect ? 'Password is incorrect' : (errors?.oldPassword?.message as string)}
        type="password"
      />
      <FormInput
        name="newPassword"
        label="New password"
        inputRef={register}
        error={errors?.newPassword?.message as string}
        type="password"
      />
      <FormInput
        name="confirmNewPassword"
        label="Confirm new password"
        inputRef={register}
        error={passwordsDontMatch ? 'Passwords do not match' : (errors?.confirmNewPassword?.message as string)}
        type="password"
      />
      <LoadingButton className="submit-button primary" isLoading={isSubmitting}>
        Change password
      </LoadingButton>
      <SuccessDialog isShowing={isSuccessDialogShowing} text="Password changed" setShowing={setSuccessDialogShowing} />
    </form>
  )
}

export default AccountChangePasswordForm
