import React, { useRef } from 'react'
import './FileSelectorButton.scss'

type Props = {
  onSelectedFileChange: (file: File | null) => void
  accept?: string
  className?: string
  disabled?: boolean
}

const FileSelectorButton: React.FC<Props> = ({ onSelectedFileChange, accept, className, children, disabled }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onFileInputChange = () => {
    const fileInput = fileInputRef.current
    if (fileInput) {
      const files = fileInput.files
      if (files && files.length > 0) {
        onSelectedFileChange(files[0] || null)
      }
    }
  }

  const onClick = () => {
    const fileInput = fileInputRef.current
    if (fileInput) {
      fileInput.value = ''
    }
  }

  return (
    <label className="file-selector-button">
      <input
        type="file"
        accept={accept}
        disabled={disabled}
        onChange={onFileInputChange}
        ref={fileInputRef}
        onClick={onClick}
      />
      <div className={'button' + (className ? ' ' + className : '')}>{children}</div>
    </label>
  )
}

export default FileSelectorButton
