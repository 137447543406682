const localStorageKeyFrom = 'location-from-before-login'
const localStorageKeyClient = 'client-query-param-before-login'
const localStorageKeyInvitationData = 'invitation-data'

// Used to remember the 'from' param through sign up / email validation / social auth. E.g. if you go a secured route
// and are not logged in, and then you log in via social auth, you end up back at that route afterwards.
export const storeLocationFrom = (from: string) => localStorage.setItem(localStorageKeyFrom, from)

export const getLocationFrom = () => localStorage.getItem(localStorageKeyFrom)

// Used to remember the 'client' query param used for magic links through sign up / email validation / social auth.
export const storeClientQueryParam = (client: string) => localStorage.setItem(localStorageKeyClient, client)

export const getClientQueryParam = () => localStorage.getItem(localStorageKeyClient)

// Used to remember invitation data through sign up / email validation / social auth.
export const storeInvitationData = (data: { id: string; ownerName: string }) => {
  const dataString = JSON.stringify(data)
  localStorage.setItem(localStorageKeyInvitationData, dataString)
}

export const getInvitationData = () => {
  const dataString = localStorage.getItem(localStorageKeyInvitationData)
  if (dataString) {
    try {
      const parsed = JSON.parse(dataString)
      if (typeof parsed.id === 'string' && (!parsed.ownerName || typeof parsed.ownerName === 'string')) {
        return { id: parsed.id as string, ownerName: (parsed.ownerName || '') as string }
      }
    } catch (ignored) {}
  }
  return null
}

export const clearAllStoredData = () => {
  localStorage.removeItem(localStorageKeyFrom)
  localStorage.removeItem(localStorageKeyClient)
  localStorage.removeItem(localStorageKeyInvitationData)
}
