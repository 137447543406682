import { AccountType, Status } from 'helpers/enum'
import { getWorkspaceOrganizationId } from 'helpers/organization'
import { Product } from 'state/types'
import { getApiWithAuthorization } from './api'

export const retreiveAllProducts = async (status: Status) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.get<Product[]>(
    `/api/product?type=${
      getWorkspaceOrganizationId() === undefined ? AccountType.Personal : AccountType.Organization.toUpperCase()
    }&status=${status.toUpperCase()}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const retreiveProduct = async (productId: string, workspaceOrganizationId: string | undefined) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<Product>(
    `/api/product/${productId}` + (workspaceOrganizationId ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}
