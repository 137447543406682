import { getApiWithAuthorization } from './api'

/**
 * Will return image URL from Cloudinary to be use in og:image tag.
 * Use this method only in Edit mode of Preview, it will use the current previewId.
 *
 */
export const generateImage = async () => {
  try {
    const paths = window.location.pathname.split("/");
    const index = paths.indexOf("edit") + 1;
    const previewId = paths[index];

    const apiWithAuth = await getApiWithAuthorization();
    const response = await apiWithAuth.get<string>(`/api/preview/${previewId}/gif`);
    return response.data;
  } catch {
    return "";
  }
};