import * as organizationBrandSettingService from '../services/organizationBrandSettingService'
import useStore from './useStore'
import useWorkspace from './useWorkspace'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { updateThemeAllByOrganization } from '../services/organizationBrandSettingService'


const useBrandTheme = (componentName:string) => {
  const { state, dispatch } = useStore();
  const { currentWorkspace, organizationsLoaded } = useWorkspace(true)
  const [isLoading, setIsLoadingData] = useState(false)
  const [isDataLoaded, setDataLoaded] = useState(false)
  const brandModeData = state.brand
  const subscriptionData = state.subscription
  const planType = subscriptionData?.plan.name

  const setBrandModeData = useCallback((data: any) => {
      dispatch({ type: 'SET_BRAND_MODE_DATA', value: data });
  }, [dispatch]);

  const getBrandThemeByID = async () => {
    if (currentWorkspace?.id) {
      setDataLoaded(false)
      const { data, ok } = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(
        currentWorkspace.id
      )
      if (data?.brandMode) {
        await updateBrandModeStorageIdByOrganization(data?.brandMode)
      } else {
        const { data, ok } = await organizationBrandSettingService.getBrandThemeByID(
          currentWorkspace.id
        )
        setBrandModeData(data?.brandStyle)
        await updateBrandModeStorageIdByOrganization(data?.id ?? '')

        return ok
      }

      return ok
    }
  }

  const updateBrandModeStorageIdByOrganization = async (brandMode: string) => {
    if (currentWorkspace?.id) {
      const { ok } = await organizationBrandSettingService.updateBrandModeStorageIdByOrganization(
        currentWorkspace.id,
        brandMode
      )
      return ok
    }
  }


  const updateThemeAllByOrganization = async (theme: string) => {
    if (currentWorkspace?.id) {
      const { ok } = await organizationBrandSettingService.updateThemeAllByOrganization(
        currentWorkspace.id,
        theme
      )
      return ok
    }
  }


  const updateItemBrandTheme = async (brandMode: string) => {
    if (currentWorkspace?.id) {
      const organizationData = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(currentWorkspace.id)
      const { ok } = await organizationBrandSettingService.updateBrandThemeByID(
        currentWorkspace.id,
        organizationData?.data?.brandMode ?? '',
        brandMode
      )
      return ok
    }
  }

  useEffect(() => {
    (async () => {
      if (currentWorkspace && componentName === 'main') {
        try {
          const { data, ok } = await organizationBrandSettingService.getBrandThemeByID(currentWorkspace.id);

          if (ok) {
            if (data?.brandStyle && typeof data.brandStyle === "string" && data.brandStyle.trim() !== "") {
              setBrandModeData(JSON.parse(data.brandStyle));
            } else {
              setBrandModeData({});
            }
          } else {
            console.log("Failed to fetch brand theme for organization with ID:", currentWorkspace.id);
          }
        } catch (error) {
          console.log("Error fetching brand theme for organization:", error);
        } finally {
          setIsLoadingData(true);
        }
      }
    })();
  }, []);




  return {
    setBrandModeData,
    brandModeData,
    planType,
    subscriptionData,
    isLoading,
    updateItemBrandTheme,
    getBrandThemeByID,
    updateThemeAllByOrganization,
  }
}

export default useBrandTheme
