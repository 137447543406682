import { VIEW_PREVIEW } from 'helpers/events'
import { getWorkspaceOrganizationId } from 'helpers/organization'
import { getInitialSortingAsc, getInitialSortingBy, getPreviousAndNextPreviews } from 'helpers/sort'
import { useUrlParams } from 'helpers/urlParams'
import update from 'immutability-helper'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as eventTrackingService from 'services/eventTrackingService'
import * as imageService from 'services/imageService'
import * as previewService from 'services/previewService'
import { Preview, PreviewStatus, PreviewTheme } from 'state/types'
import useAuth from 'state/useAuth'
import useInvitation from 'state/useInvitation'
import useStore from 'state/useStore'
import useWorkspace from './useWorkspace'

type PreviewData = { isLoading: boolean; preview?: Preview }

const usePublishedPreview = () => {
  // Here we store in local state a map of all previews, so we can pre-load the previous and next previews
  // when viewing in the context of an invitation.
  const { previewId } = useParams<any>()
  const { state, dispatch } = useStore()
  const [previews, setPreviews] = useState<Record<string, PreviewData>>({})
  const [error, setError] = useState(false)
  const [status, setStatus] = useState<number | undefined>()
  const [isPublishing, setPublishing] = useState(false)
  const [isUnpublishing, setUnpublishing] = useState(false)
  const { user } = useAuth()
  const { i: invitationId } = useUrlParams('i')
  const { invitation } = useInvitation(invitationId)
  const { trackEventsBasedOnWorkspace } = useWorkspace(true)
  const [isUpdatingPreviewBusinessLogoImage, setUpdatingPreviewBusinessLogoImage] = useState(false)

  const previewsRef = useRef(previews)
  previewsRef.current = previews

  const isCurrentPreviewLoading = !!previewId && previews[previewId]?.isLoading
  const currentPreview = previewId ? previews[previewId]?.preview : undefined
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const isUserInOrganization = workspaceOrganizationId
    ? workspaceOrganizationId === currentPreview?.organizationId
    : false

  const isOwnPreview = user?.id === currentPreview?.userId

  const setLoading = (previewId: string, isLoading: boolean) => setPreviewData(previewId, { isLoading })

  const setPreview = (previewId: string, preview: Preview) => setPreviewData(previewId, { isLoading: false, preview })

  const setPreviewData = (id: string, data: PreviewData) => {
    const newPreviews = !previewsRef.current[id]
      ? update(previewsRef.current, { [id]: { $set: data } })
      : update(previewsRef.current, { [id]: { $merge: data } })

    previewsRef.current = newPreviews
    setPreviews(newPreviews)
  }

  const updatePublished = async (published: boolean, setLoading: (loading: boolean) => void) => {
    if (currentPreview) {
      setLoading(true)
      const { ok } = await previewService.updatePreviewPublished(previewId!, published)
      setLoading(false)
      if (ok) {
        const status: PreviewStatus = published ? 'published' : 'unpublished'
        const updatedPreview = { ...currentPreview, status }
        setPreview(previewId!, updatedPreview)

        // Also update currently edited preview if it exists in global state.
        if (state.preview?.id === previewId) {
          dispatch({ type: 'SET_PREVIEW', value: updatedPreview })
        }
      }
    }
  }

  const publish = () => updatePublished(true, setPublishing)

  const unpublish = () => updatePublished(false, setUnpublishing)

  const updateTheme = (theme?: PreviewTheme) => {
    if (currentPreview) {
      const updatedPreview = { ...currentPreview, theme }
      setPreview(previewId!, updatedPreview)
      previewService.updateTheme(previewId!, theme)
      // Also update currently edited preview if it exists in global state.
      if (state.preview?.id === previewId) {
        dispatch({ type: 'SET_PREVIEW', value: updatedPreview })
      }
    }
  }

  const updateThemeByID = (theme: string, id: string): void => {
    // Check if theme and id are non-empty strings.
    if (typeof theme === 'string' && theme.trim() !== '' && typeof id === 'string' && id.trim() !== '') {
      try {
        previewService.updateThemeUponCreate(id, theme);
      } catch (error) {
        console.error('Error updating the theme:', error);
        throw error;
      }
    } else {
      console.warn('Invalid theme or id provided:', { theme, id });
    }
  }
  const updatePreviewBusinessLogoImage = async (businessLogoImageStorageId?: string) => {
    if (currentPreview) {
      const updatedPreview = { ...currentPreview, businessLogoImageStorageId }
      setPreview(previewId!, updatedPreview)

      setUpdatingPreviewBusinessLogoImage(true)
      const { ok } = await previewService.updatePreviewBusinessLogoImage(previewId!, businessLogoImageStorageId)
      setUpdatingPreviewBusinessLogoImage(false)

      if (ok && state.preview?.id === previewId) {
        dispatch({ type: 'SET_PREVIEW', value: updatedPreview })
      }
      return ok
    }
  }

  // upload image to Cloudinary in frontend, then send storageId to backend to update DB
  const updateBusinessLogoImage = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
    if (status === 'success' && !!storageId) {
      updatePreviewBusinessLogoImage(storageId)
    }
  }

  // delete the bussiness logo from Cloudinary in backend
  const removeBusinessLogoImage = () => {
    updatePreviewBusinessLogoImage(undefined)
  }

  useEffect(() => {
    if (previewId && currentPreview?.id !== previewId) {
      if (state.preview?.id === previewId && state.preview?.userId === user?.id) {
        setPreview(previewId, state.preview)
      }
      const load = async () => {
        setLoading(previewId, true)

        const apiCall = user ? previewService.getPreviewDetails : previewService.getPublishedPreviewDetails
        const { ok, status, data } = await apiCall(previewId)

        setLoading(previewId, false)
        setStatus(status)

        if (ok && data) {
          setPreview(previewId, data)
          if (trackEventsBasedOnWorkspace(data)) {
            eventTrackingService.trackEvent({ eventType: VIEW_PREVIEW, previewId })
          }
        } else {
          setError(true)
        }
      }
      load()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewId, user])

  useEffect(() => {
    if (previewId && invitation) {
      const { previous, next } = getPreviousAndNextPreviews(
        previewId,
        invitation?.previews,
        getInitialSortingBy(),
        getInitialSortingAsc()
      )

      const onLoaded = ({ ok, data }: { ok: boolean; data?: Preview }) => {
        if (ok && data) {
          setPreview(data.id, data)
        }
      }

      if (previous) {
        previewService.getPreviewDetails(previous.id).then(onLoaded)
      }

      if (next) {
        previewService.getPreviewDetails(next.id).then(onLoaded)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewId, invitation])

  return {
    isLoading: isCurrentPreviewLoading,
    preview: currentPreview,
    error,
    status,
    isOwnPreview,
    isUserInOrganization,
    isPublishing,
    isUnpublishing,
    isUpdatingPreviewBusinessLogoImage,
    isInvitationMode: !!invitationId,
    publish,
    unpublish,
    updateTheme,
    updateBusinessLogoImage,
    removeBusinessLogoImage,
    updateThemeByID
  }
}

export default usePublishedPreview
