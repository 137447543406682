import React from 'react'
import './SelectAllButton.scss'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import DropdownMenuOption from '../DropdownMenuOption/DropdownMenuOption'
import { PreviewAlgolia, ViewingSelection } from '../../../state/types'

interface Props {
  previews: PreviewAlgolia[]
  bulkSelectPreviews: (previews: PreviewAlgolia[]) => void
  viewingSelection: ViewingSelection
}

/**
 * SelectAllButton is used for Previews and ArchivedPreviews.
 * used to store ids to be used for bulk action. e.g. Archive, Restore, Delete
 */

const SelectAllButton = function ({ previews, bulkSelectPreviews, viewingSelection }: Props) {
  const publishedPreviewCount = previews.filter(p => p.status.startsWith('Published')).length
  const draftPreviewCount = previews.filter(p => p.status.startsWith('Draft')).length

  const selectAllPreviews = function () {
    bulkSelectPreviews(previews)
  }

  const selectAllPublishedPreviews = function () {
    const publishedPreviews = previews.filter(p => p.status.startsWith('Published'))
    bulkSelectPreviews(publishedPreviews)
  }

  const selectAllDraftPreviews = function () {
    const draftPreviews = previews.filter(p => p.status.startsWith('Draft'))
    bulkSelectPreviews(draftPreviews)
  }

  const optionsNormal = [
    <DropdownMenuOption
      optionName={`${publishedPreviewCount} Published`}
      onSelect={() => {
        selectAllPublishedPreviews()
      }}
    />,
    <DropdownMenuOption
      optionName={`${draftPreviewCount} Draft`}
      onSelect={() => {
        selectAllDraftPreviews()
      }}
    />,
  ]

  const optionsArchived = [
    <DropdownMenuOption
      optionName={`${previews.length} Archived`}
      onSelect={() => {
        selectAllPreviews()
      }}
    />,
  ]

  return (
    <div className="select-all-container">
      <DropdownMenu
        menuContent={<>{viewingSelection === 'active' ? optionsNormal : optionsArchived}</>}
        trigger={<div className="select-all-button button secondary">Select All</div>}
        horizontalAlignment="left"
      />
    </div>
  )
}

export default SelectAllButton
