import * as imageService from '../services/imageService'
import * as watermarkService from '../services/watermarkService'
import useStore from './useStore'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useVideoChapter from './useVideoChapter'
import { parseIndexParam } from '../helpers/urlParams'
import { getWorkspaceOrganizationId } from '../helpers/organization'
import { VideoSuite, VideoWatermark } from './types'
import { string } from 'yup'

const useWatermark = () => {
  const { state, dispatch } = useStore()
  const watermarkImage = state.watermarkData?.cloudinaryId
  const watermarkOpacity =  state.watermarkData?.opacity
  const watermarkCoords = {
    x: state.watermarkData?.x,
    y: state.watermarkData?.y
  }
  const watermarkImageDimension = {
    width: state.watermarkData?.imageWidth,
    height: state.watermarkData?.imageHeight
  }
  const { previewId, index, fieldIndex } = useParams<any>()
  const organizationId = getWorkspaceOrganizationId()
  const[watermarkData, setWatermarkData]  = useState()
  const type = 'watermark'
  const [ xAxis, setXAxis] = useState()
  const [ yAxis, setYAxis] = useState()
  const { videoBlob, videoChapter } = useVideoChapter(
    previewId,
    parseIndexParam(index),
    parseIndexParam(fieldIndex),
    true
  )

  const setWatermarkOpacity = useCallback((data: number) => {
    dispatch({ type: 'SET_WATERMARK_DATA', value: { ...watermarkData, opacity: data } });
  }, [dispatch]);

  const setWatermarkImage = useCallback((data: string | undefined) => {
    dispatch({ type: 'SET_WATERMARK_DATA', value: { ...watermarkData, cloudinaryId:data } });
  }, [dispatch]);

  const setWatermarkCoords = useCallback((x: number, y: number) => {
    dispatch({ type: 'SET_WATERMARK_DATA', value: {...watermarkData, x:x, y:y } });
  }, [dispatch]);

  const setWatermarkImageDimension = useCallback((width: number | undefined, height: number | undefined) => {
    dispatch({ type: 'SET_WATERMARK_DATA', value: {...watermarkData, imageWidth: width, imageHeight: height } });
  }, [dispatch]);


  const getWatermarkDataFromService = async (organizationId: string | undefined, videoChapterId: string | undefined) => {
    try {
      const { data, ok } = await watermarkService.getWatermarkData(organizationId, videoChapterId);
      const response = data as VideoSuite;
      if(response.watermark){
        const parsedWatermarkData = JSON.parse(response.watermark);
        setWatermarkData(parsedWatermarkData)
        setWatermarkImage(parsedWatermarkData.watermarkCloudinaryId);
        setWatermarkOpacity(parsedWatermarkData.watermarkOpacity)
        setWatermarkCoords(parsedWatermarkData.x, parsedWatermarkData.y)
        return parsedWatermarkData
      } else {
        setWatermarkImage("")
        setWatermarkCoords(0,0)
        setWatermarkOpacity(100)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVideoSuiteId = async () => {
    try{
      const { data, ok } = await watermarkService.getWatermarkData(organizationId, videoChapter?.id);
      const typedData = data as VideoSuite
      if(ok) {
        return(typedData.id)
      }
    }catch (error) {
      console.log(error);
    }
  }

  const getVideoSuiteVideoId = async (organizationId: string | undefined, videoChapterId: string | undefined) => {
    try{
      const { data, ok } = await watermarkService.getWatermarkData(organizationId, videoChapterId);
      const typedData = data as VideoSuite
      if(ok) {
        return(typedData.videoId)
      }
    }catch (error) {
      console.log(error);
    }
  }

  const updateWatermarkImageLocally = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    setWatermarkCoords(0,0)
    try {
      const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
      if(status === 'success' && !!storageId){
        setWatermarkImage(storageId)
      }
    } catch (error){
      console.error('Error uploading image:', error);
      setWatermarkImage(undefined);
    }
  }

  const updateWatermarkData = async () => {
    const videoSuiteID = await getVideoSuiteId()
    try {
      const payload = {
        type: type,
        data: JSON.stringify({
          watermarkCloudinaryId: watermarkImage,
          watermarkOpacity: watermarkOpacity,
          x: watermarkCoords?.x,
          y: watermarkCoords?.y,
          imageWidth: watermarkImageDimension.width,
          imageHeight: watermarkImageDimension.height
        })
      }
      const { ok, data } = await watermarkService.updateWatermarkProperties(
        organizationId,
        videoSuiteID,
        payload
      )
      return ok
    } catch (error) {
      console.error('Error uploading image:', error);
      setWatermarkImage(undefined);
    }
  };

  const updateWatermarkOpacity = (opacity: number) => {
    setWatermarkOpacity(opacity)
  }

  const defaultWatermarkData = {
    watermarkCloudinaryId: "",
    watermarkOpacity: 100,
    x:0,
    y:0,
    imageHeight: 0,
    imageWidth: 0
  }


  return {
    getWatermarkDataFromService,
    getVideoSuiteVideoId,
    getVideoSuiteId,
    setWatermarkImage,
    setWatermarkOpacity,
    setWatermarkCoords,
    setWatermarkImageDimension,
    watermarkImageDimension,
    watermarkImage,
    watermarkOpacity,
    watermarkCoords,
    watermarkData,
    updateWatermarkData,
    updateWatermarkOpacity,
    updateWatermarkImageLocally,
    setXAxis,
    setYAxis,
    xAxis,
    yAxis,
    defaultWatermarkData
  }
}

export default useWatermark