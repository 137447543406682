import React from 'react'
import Table from 'components/shared/Table/Table'
import InfoPopup from 'components/shared/InfoPopup/InfoPopup'
import { LinkAnalytics } from 'state/types'
import { createFullLink } from 'helpers/links'
import './LinkClicksTable.scss'

type Props = {
  data: LinkAnalytics[]
}

const LinkClicksTable: React.FC<Props> = ({ data }) => (
  <div className="link-clicks-table-card">
    <Table
      className="link-clicks-table"
      columns={[
        {
          name: 'viewLink',
          label: 'Link',
          className: 'view-link',
          render: ({ linkUrl }) => {
            return (
              <a href={createFullLink(linkUrl!)} target="_blank" rel="noopener noreferrer">
                {linkUrl}
              </a>
            )
          },
        },
        {
          name: 'viewLink',
          label: 'Title',
          className: 'title',
          render: ({ linkText }) => <div className="link-text">{linkText}</div>,
        },
        {
          name: 'uniqueUserCount',
          label: (
            <div className="unique-views-label">
              <span>By unique user</span>
              <InfoPopup>
                The number of link clicks by distinct individuals, regardless of how often they clicked.
              </InfoPopup>
            </div>
          ),
          className: 'unique-views',
        },
        {
          name: 'totalCount',
          label: 'Total',
          className: 'total-views',
        },
      ]}
      rows={data}
    />
  </div>
)

export default LinkClicksTable
