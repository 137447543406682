import React from 'react'
import './FormattedText.scss'

type Items = Array<{ p?: string; ul?: string[] }>

type Props = {
  jsonString: string
}

const FormattedText: React.FC<Props> = ({ jsonString }) => {
  let items: Items = []
  try {
    const parsed = JSON.parse(jsonString)
    if (parsed) {
      items = parsed
    }
  } catch {}

  return (
    <div className="formatted-text">
      {items.map((item, i) => (
        <React.Fragment key={i}>
          {item.p && <p>{item.p}</p>}
          {item.ul && (
            <ul>
              {item.ul.map((li: string) => (
                <li key={li}>{li}</li>
              ))}
            </ul>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default FormattedText
