import React, { useState } from 'react'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import { useHistory } from 'react-router-dom'
import './UpgradeToEnterpiseDialog.scss'
import { Product, Subscription } from '../../../state/types'
import { ApiResponse } from 'apisauce'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import ViewPreviewArrowIcon from '../../../assets/icons/ViewPreviewArrowIcon'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'
import useAuth from '../../../state/useAuth'
import useWorkspace from '../../../state/useWorkspace'
import { BillingType, Country } from '../../../helpers/enum'

type Props = {
  isShowing: boolean
  onClose: () => void
  invitationLimit?: boolean
  newSubscription?: Subscription
  newProduct?: Product
  changePlan?: (subscription: Subscription, billingType: string) => Promise<ApiResponse<Subscription, Subscription>>
  showEnterpriseFeatures?: boolean
  setShowEnterpriseFeatures?: (value: boolean) => void
}

export const UpgradeToEnterpriseDialog: (props: Props) => JSX.Element = (props: Props) => {
  const { currentWorkspace } = useWorkspace(true)
  const { state } = useHistory()
  const { push } = useHistory()
  const [isUpgrading, setUpgrading] = useState(false)
  const [isUpgradeSuccessful, setUpgradeSuccessful] = useState<boolean | undefined>(undefined)
  const getMonthlyPrice = (title: string, price: number) => {
      switch (title){
        case 'Solo' :  return price
        case 'Business' : return price/3
        case 'Enterprise' : return price/3
        default: return price
      }
  }

  const upgradeNow = async () => {
    if (props.changePlan && props.newSubscription) {
      setUpgrading(true)
      const { ok } = await props.changePlan(props.newSubscription, BillingType.Monthly)
      if (ok) {
        setUpgradeSuccessful(true)
      }
      setUpgrading(false)
    }
  }

  const onClose = () => {
    if (isUpgradeSuccessful && props.setShowEnterpriseFeatures) {
      props.setShowEnterpriseFeatures(true)
    }
    if (isUpgradeSuccessful && props.invitationLimit) {
      push('/invitations/create')
    }
    props.onClose()
  }
  return (
    <>
      <CloseableDialog className="upgrade-dialog" isShowing={props.isShowing} onClose={onClose}>
        {isUpgradeSuccessful === undefined ? (
          <>
            <div>
              <h2>Upgrade your plan</h2>
              {props.newProduct && props.newSubscription ? (
                <p>
                  {props.invitationLimit && (
                    <>
                      You have reached your limit of <b>two active invitations</b> on your Business or Professional Plan. To
                      continue,{' '}
                    </>
                  )}
                  <b>
                    A one-off payment of {'US$' + props.newSubscription.amountDue}
                  </b>{' '}
                  will be charged now for the upgrade to our {props.newProduct.name} plan for the current billing
                  period. From the next billing period you will be charged{' '}
                  {`${'US$' + getMonthlyPrice(props.newProduct.name,props.newProduct.prices[0].amount)}/month`}.
                </p>
              ) : (
                <LoadingSpinner expand />
              )}
            </div>
            <LoadingButton
              className="primary upgrade-to-enterprise-button"
              isLoading={isUpgrading}
              onClick={upgradeNow}
            >
              Upgrade to enterprise
            </LoadingButton>
          </>
        ) : (
          <div>
            {isUpgradeSuccessful ? (
              <>
                <h2>Upgrade successful!</h2>
                <p>You now have access to all features in PreviewMe.</p>
                <p>Use them to create even more engaging and bespoke Previews and Invitations!</p>
                <button className="continue-button" onClick={() => onClose()}>
                  Continue
                </button>
              </>
            ) : (
              <>
                <h2>Hmmm, looks there's an issue with your credit card details...</h2>
                <p>We weren't able to process the payment with the card linked to your account.</p>
                <div className="link-button" onClick={() => push('/organization/settings/planBilling')}>
                  <b>Update my details</b>
                  <ViewPreviewArrowIcon />
                </div>
              </>
            )}
          </div>
        )}
      </CloseableDialog>
    </>
  )
}
