import React, { useEffect, useMemo } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { parseIndexParam } from 'helpers/urlParams'
import { validatePreview } from 'helpers/validation'
import { getNavigationData } from 'helpers/navigation'
import usePreview from 'state/usePreview'
import InvitationInstructions from 'components/invitation/InvitationInstructions/InvitationInstructions'
import PreviewEditorHeader from 'components/preview/PreviewEditorHeader/PreviewEditorHeader'
import PreviewTitleEditor from 'components/preview/PreviewTitleEditor/PreviewTitleEditor'
import FormBlockEditor from 'components/preview/FormBlockEditor/FormBlockEditor'
import TitleBlockEditor from 'components/preview/FormBlockEditor/TitleBlockEditor'
import DocumentBlockEditor from 'components/preview/DocumentBlockEditor/DocumentBlockEditor'
import PreviewEditorPublishStep from 'components/preview/PreviewEditorPublishStep/PreviewEditorPublishStep'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import BackLink from 'components/shared/BackLink/BackLink'
import VideoBlockEditor from 'components/video/VideoBlockEditor/VideoBlockEditor'
import './PreviewEditorPage.scss'
import { getDashboardTitles } from 'helpers/titles'
import { FIRST_BLOCK_INDEX } from 'helpers/constant'
import TagFieldEditor from 'components/preview/TagFieldEditor/TagFieldEditor'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import { getCurrentPlan } from 'helpers/subscription'
import { SubscriptionPlan, WorkspaceType } from 'helpers/enum'


// Props common to all block editors.
export type BlockProps = {
  key: string // Key ensures the component will always unmount and re-mount e.g. when navigating between two form blocks.
  previewId: string
  blockIndex: number
  prevLink?: string
  nextLink?: string
  isPreviewPublished: boolean
  hideTitles?: boolean | undefined
}

const PreviewEditorPage = () => {
  const location = useLocation()
  const history = useHistory()
  const { previewId, index } = useParams<any>()
  const { isLoading, preview, setValidationActive, checkValidateParam, updatePreviewSelectedTags } =
    usePreview(previewId)
  const isTitlePage = location.pathname.endsWith('title')
  const isInstructionsPage = location.pathname.endsWith('instructions')
  const isPublishPage = location.pathname.endsWith('publish')
  const blockIndex = parseIndexParam(index)
  const block = blockIndex !== undefined ? preview?.contentBlocks?.[blockIndex] : undefined
  const blockType = block?.contentBlockType?.name
  const isPreviewPublished = preview?.status === 'published'
  const isPreviewArchived = !!preview?.archived
  const { organizationsLoaded, currentWorkspace } = useWorkspace(true)
  const { subscription, isPaymentDetailsLoading } = useSubscription(organizationsLoaded)
  const isWorkspaceTypeOrg = currentWorkspace?.type === WorkspaceType.Organization

  const { prevLink, nextLink, redirect } = getNavigationData(
    preview,
    blockIndex,
    isTitlePage,
    isInstructionsPage,
    isPublishPage
  )

  const commonBlockProps: BlockProps = {
    key: block?.id!,
    previewId: preview?.id!,
    blockIndex: blockIndex!,
    prevLink: prevLink,
    nextLink: nextLink,
    isPreviewPublished,
  }

  const showValidationErrorsInStepper = isPublishPage || !!preview?.isValidationActive

  const previewValidation = validatePreview(preview, preview?.duplicate && !!preview.organizationId)

  const steps =
    previewValidation.blocks
      .map(({ isValid, isVisible, isOptional }, i) => ({
        blockTitle: preview!.contentBlocks![i].previewLabel || preview!.contentBlocks![i].formLabel,
        link: `/edit/${previewId}/block/${i}`,
        isActive: i === blockIndex,
        isValid,
        isVisible,
        isComplete: isValid && !isOptional, // Tick styling in stepper.
        isIncomplete: !isValid && !isOptional && showValidationErrorsInStepper, // Cross styling in stepper.
      }))
      // Important that filter occurs after map here so we don't mess up our block indices.
      .filter(({ isVisible }) => isVisible) || []

  if (preview?.nameEditable) {
    steps.unshift({
      blockTitle: 'Title',
      link: `/edit/${previewId}/title`,
      isActive: isTitlePage,
      isValid: previewValidation.isNameValid,
      isVisible: true,
      isComplete: previewValidation.isNameValid,
      isIncomplete: !previewValidation.isNameValid && showValidationErrorsInStepper,
    })
  } else if (preview?.invitation?.instructions) {
    steps.unshift({
      blockTitle: `Instructions from ${preview.invitation.ownerName}`,
      link: `/edit/${previewId}/instructions`,
      isActive: isInstructionsPage,
      isValid: true,
      isVisible: true,
      isComplete: true,
      isIncomplete: false,
    })
  }

  const onReviewClick = () => {
    setValidationActive(true)
    if (!previewValidation.isNameValid) {
      history.push(`/edit/${preview?.id}/title`)
    } else {
      const indexOfFirstInvalidBlock = previewValidation.blocks.findIndex(b => !b.isValid && b.isVisible)
      history.push(`/edit/${preview?.id}/block/${indexOfFirstInvalidBlock}`)
    }
  }

  const onCancelClick = () => {
    setValidationActive(false)
    history.push('/')
  }

  useEffect(() => {
    if (blockIndex !== undefined) {
      // Because nothing new is loaded when navigating between blocks, new content is rendered immediately and
      // updated in-place, with no scroll reset. So we have to manually trigger a scroll to the top of the page.
      window.scrollTo(0, 0)
    }
  }, [blockIndex])

  useEffect(() => {
    if (preview) {
      checkValidateParam()
    }
  }, [preview, checkValidateParam])

  const getVideoChapters = () => {
    const chapters: { previewLabel: string; format: string }[] = []
    preview?.contentBlocks
      ?.filter(contentBlock => contentBlock.contentBlockType.name === 'Video')[0]
      .contentBlockFields.forEach(videoChapter => {
        if (videoChapter.previewLabel) {
          chapters.push({ previewLabel: videoChapter.previewLabel, format: videoChapter.format })
        }
      })
    return chapters
  }

  const applyTag =
    isWorkspaceTypeOrg &&
    blockIndex === FIRST_BLOCK_INDEX &&
    getCurrentPlan(subscription) !== SubscriptionPlan.Basic

  return redirect ? (
    <Redirect to={redirect} />
  ) : isPreviewArchived ? (
    <main className="preview-archived-error-page">
      <div className="preview-archived-error-message">This Preview has been archived.</div>
      <BackLink to="/">Back to Previews</BackLink>
    </main>
  ) : (
    <PageFrame
      className={
        'preview-editor-page' +
        (isPublishPage ? ' publish-page' : '') +
        (isInstructionsPage ? ' instructions-page' : '')
      }
      hideHeader={isPublishPage && preview?.status === 'published'}
      header={
        !isLoading && (
          <PreviewEditorHeader
            preview={preview}
            blockIndex={blockIndex}
            isTitlePage={isTitlePage}
            isInstructionsPage={isInstructionsPage}
            isPublishPage={isPublishPage}
            steps={steps}
          />
        )
      }
    >
      {isLoading ? (
        <LoadingSpinner container expand />
      ) : (
        <>
          {isTitlePage && preview && (
            <PreviewTitleEditor
              previewId={preview.id}
              isPreviewPublished={isPreviewPublished}
              isValidationActive={preview.isValidationActive}
              nextLink={nextLink}
            />
          )}
          {isInstructionsPage && preview && preview.invitation && (
            <InvitationInstructions
              previewId={preview.id}
              invitation={preview.invitation}
              nextLink={nextLink}
              chapters={getVideoChapters()}
            />
          )}
          {blockType === 'Form' &&
            //render first content block
            (blockIndex === FIRST_BLOCK_INDEX && preview ? (
              preview.organizationId && isPaymentDetailsLoading && !!subscription ? (
                <>
                  {/* if preview is duplicated */}
                  {preview?.duplicate ? (
                    <>
                      <TitleBlockEditor
                        {...commonBlockProps}
                        previewId={preview.id}
                        title={getDashboardTitles(preview).title}
                        subtitle={getDashboardTitles(preview).subtitle}
                      />
                      <FormBlockEditor {...commonBlockProps} hideTitles />
                    </>
                  ) : (
                    <FormBlockEditor {...commonBlockProps} />
                  )}

                  {applyTag && (
                    <TagFieldEditor
                      preview={preview}
                      organizationId={currentWorkspace?.id}
                      updatePreviewSelectedTags={updatePreviewSelectedTags}
                    />
                  )}
                </>
              ) : (
                //if user => render form, if org, need checking subscription
                <>{!preview.organizationId ? <FormBlockEditor {...commonBlockProps} /> : <LoadingSpinner />}</>
              )
            ) : (
              //render other blocks
              <FormBlockEditor {...commonBlockProps} />
            ))}

          {blockType === 'Video' && (
            <VideoBlockEditor
              {...commonBlockProps}
              templateId={preview?.templateId}
              isInvitationResponse={preview?.invitation !== undefined}
              areCustomInstructionsPresent={getVideoChapters().length > 0}
            />
          )}
          {blockType === 'Documents' && <DocumentBlockEditor {...commonBlockProps} />}
          {isPublishPage && preview && (
            <PreviewEditorPublishStep
              previewId={preview.id}
              prevLink={prevLink}
              isReadyToPublish={previewValidation.isValid}
              isPreviewPublished={isPreviewPublished}
              onReviewClick={onReviewClick}
              onCancelClick={onCancelClick}
              invitationOrgName={preview.invitation?.ownerName}
              invitationSuccessPage={preview.invitationSuccessPage}
            />
          )}
        </>
      )}
    </PageFrame>
  )
}

export default PreviewEditorPage
