import React from 'react'
import './AuthTermsText.scss'

const AuthTermsText = () => (
  <div className="terms-text">
    By continuing you agree to PreviewMe's{' '}
    <a href="https://previewme.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
      Terms of Service
    </a>{' '}
    and{' '}
    <a href="https://previewme.com/privacy" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>
    . For the best experience use the latest version of Chrome or Firefox.
  </div>
)

export default AuthTermsText
