import React from 'react'
import { register, logInWithFacebook, logInWithGoogle } from 'services/authService'
import AuthPage from 'components/auth/AuthPage/AuthPage'
import RegistrationForm from 'components/auth/RegistrationForm/RegistrationForm'

const RegistrationPage = () => (
  <AuthPage>
    <RegistrationForm
      submitRegistration={register}
      logInWithGoogle={logInWithGoogle}
      logInWithFacebook={logInWithFacebook}
    />
  </AuthPage>
)

export default RegistrationPage
