import React, { useEffect, useRef, useState } from 'react'
import "./BrandSettingsPage.scss";
import PageFrame from "components/shared/PageFrame/PageFrame";
import PlayButtonPlain from "assets/icons/PlayButtonPlain";
import BrandingSideBar from "./BrandingSideBar/BrandingSideBar";
import { getBusinessLogoImageUrl } from "../../../helpers/cloudinary";
import LoadingSpinner from "../../shared/LoadingSpinner/LoadingSpinner";
import previewmeLogo from "../../../assets/previewme-logo.svg";
import useStore from '../../../state/useStore'
import WebFont from 'webfontloader'
import { makeStyles } from '@material-ui/core/styles'
import useBrandTheme from '../../../state/useBrandTheme'
import useOrganizationBrandSetting from '../../../state/useOrganizationBrandSetting'
import useBranding from '../../../state/useBranding'
import AuthorizedPage from '../../auth/AuthorizedPage/AuthorizedPage'
import UnauthorizedPage from '../../auth/UnauthorizedPage/UnauthorizedPage'
import useWorkspace from '../../../state/useWorkspace'


interface HeadingData {
  color: string;
  font: string;
  displayName:string;
  variant:number
}

interface BrandDataProps {
  lowerColor?: string;
}

const BrandSettingsPage: React.FC = () => {

  const [logoValue, setLogoValue] = useState("/logo.svg");
  const {
    brandModeData,
    setBrandModeData,
    isLoading,
    planType} = useBrandTheme('main')
  const {
    previewDisableData,
    cloudinaryStorageIdData
  } = useBranding()
  const { currentWorkspace } = useWorkspace(false)
  const prevWorkspaceIdRef = useRef<string|undefined>()

  const getDefaultBrandData = () => ({
    headerColor: brandModeData?.headerColor,
    upperColor: brandModeData?.upperColor,
    lowerColor: brandModeData?.lowerColor,
    buttonColor: brandModeData?.buttonColor,
    heading1: brandModeData?.heading1,
    heading2: brandModeData?.heading2,
    heading3: brandModeData?.heading3,
    body: brandModeData?.body,
    button02: brandModeData?.button02,
    link: brandModeData?.link,
    borderRadius: brandModeData?.borderRadius,
    pageTitle: brandModeData?.pageTitle
  });

  useEffect(() => {
    if (currentWorkspace?.id !== prevWorkspaceIdRef.current) {
      setBrandModeData("")
    }

    prevWorkspaceIdRef.current = currentWorkspace?.id;
  }, [])

  const handleTextChange = async  (color: string, font: string, type: string, displayName:string, variant:number) => {
    const updatedHeadingData: HeadingData = { color, font,displayName,variant };
    const brandData = getDefaultBrandData();

    const textTypes: { [key: string]: keyof typeof brandData } = {
      heading_1: "heading1",
      heading_2: "heading2",
      heading_3: "heading3",
      body_copy_text: "body",
      button_text: "button02",
      link_text: "link"
    };

    if (textTypes[type]) brandData[textTypes[type]] = updatedHeadingData;
    await setBrandModeData(brandData);
  }

  const handleBackgroundColor = async (color: string, type: string) => {
    const brandData = getDefaultBrandData();

    const colorTypes: { [key: string]: keyof typeof brandData } = {
      header_bg_color: "headerColor",
      upper_bg_color: "upperColor",
      lower_bg_color: "lowerColor",
      button_bg_color: "buttonColor"
    };

    if (colorTypes[type]) brandData[colorTypes[type]] = color;

    await setBrandModeData(brandData);
  }

  const handleBorderRadius = async (data: number) => {
    const brandData = getDefaultBrandData();
    brandData.borderRadius = Number(data);

    await setBrandModeData(brandData);
  }

  const handlePageTitle = async (data: string) => {
    const brandData = getDefaultBrandData()
    brandData.pageTitle = String(data)
    await setBrandModeData(brandData);
  }

  const getTextStyles = (color: string, font: string, variant:number) => {
    if(font === "DM Sans"){
      return {
        color: color,
        fontFamily: font,
        fontWeight:variant
      };
    }
    return {
      color: color,
      fontFamily: font,
    };
  };

  // Reusable style function for background elements
  const getBackgroundStyles = (color: string) => {
    return {
      backgroundColor: color,
    };
  };

  useEffect(() => {

    (async () => {
      if (cloudinaryStorageIdData) {
        setLogoValue(getBusinessLogoImageUrl(cloudinaryStorageIdData));
      } else {
        setLogoValue("/logo.svg");
      }

      if (previewDisableData === true ) {
        setLogoValue(cloudinaryStorageIdData ? getBusinessLogoImageUrl(cloudinaryStorageIdData) : "")
      } else {
        setLogoValue(cloudinaryStorageIdData ? getBusinessLogoImageUrl(cloudinaryStorageIdData) : "/logo.svg");
      }

    })();

  }, [cloudinaryStorageIdData, previewDisableData]);

  return (
    <>
      <AuthorizedPage UnauthorizedComponent={UnauthorizedPage}>
        <PageFrame
          className={`brand-settings-page`}
          sidebar={
            <BrandingSideBar
              planType={planType ?? ''}
              data={brandModeData}
              header={handleBackgroundColor}
              upper={handleBackgroundColor}
              lower={handleBackgroundColor}
              button={handleBackgroundColor}
              heading1={handleTextChange}
              heading2={handleTextChange}
              heading3={handleTextChange}
              body={handleTextChange}
              link={handleTextChange}
              button02={handleTextChange}
              borderRadius={handleBorderRadius}
              pageTitle = {handlePageTitle}
            />
          }
          header={<h1>Brand Settings</h1>}
        >
          <p className="brand-settings-desc">
            Add your own brand elements to all outbound Previews.{" "}
          </p>
          {isLoading ? (
            <div className="wrapper-viewer">
              <div
                className="viewer--navbar"
                style={getBackgroundStyles(brandModeData?.headerColor)}
              >

                {previewDisableData === true || cloudinaryStorageIdData? (
                  <div
                    className="app-header-custom-logo"
                    style={{ backgroundImage: "url(" + logoValue + ")" }}
                  ></div>
                ) : (
                  <div
                    className="app-header-personal-logo"
                    style={{ backgroundImage: "url(" + logoValue + ")" }}
                  ></div>
                )}

              </div>
              <div
                className="viewer--body"
                style={getBackgroundStyles(brandModeData?.upperColor)}
              >
                <p
                  className="text-heading01"
                  style={getTextStyles(brandModeData?.heading1?.color, brandModeData?.heading1?.font,brandModeData?.heading1?.variant)}
                >
                  Heading 1
                </p>
                <p
                  className="text-heading02"
                  style={getTextStyles(brandModeData?.heading2?.color, brandModeData?.heading2?.font,brandModeData?.heading2?.variant)}
                >
                  Heading 2
                </p>
                <div
                  className="video-container"
                >
                  <PlayButtonPlain />
                </div>
              </div>
              <div
                className="viewer--footer"
                style={getBackgroundStyles(brandModeData?.lowerColor)}
              >
                <div className="viewer--footer__container">
                  <div className="item--link">
                    <p
                      id="viewer_footer_link_title"
                      style={getTextStyles(brandModeData?.heading3?.color, brandModeData?.heading3?.font,brandModeData?.heading3?.variant)}
                    >
                      Heading 3
                    </p>
                    <p
                      id="viewer_footer_link_desc"
                      style={getTextStyles(brandModeData?.body?.color, brandModeData?.body?.font,brandModeData?.body?.variant)}
                    >
                      Body copy text. Body copy text. Body copy text. Body copy
                      text.
                    </p>
                    <a
                      id="viewer_footer_link"
                      style={getTextStyles(brandModeData?.link?.color, brandModeData?.link?.font,brandModeData?.link?.variant)}
                    >
                      Link text
                    </a>
                  </div>
                  <div className="item--button">
                    <p
                      id="viewer_footer_button_title"
                      style={getTextStyles(brandModeData?.heading3?.color, brandModeData?.heading3?.font,brandModeData?.heading3?.variant)}
                    >
                      Heading 3
                    </p>
                    <p
                      id="viewer_footer_button_desc"
                      style={getTextStyles(brandModeData?.body?.color, brandModeData?.body?.font,brandModeData?.body?.variant)}
                    >
                      Body copy text. Body copy text. Body copy text. Body copy
                      text.
                    </p>
                    <button
                      id="viewer_footer_button"
                      style={{
                        color: brandModeData?.button02?.color,
                        fontFamily: brandModeData?.button02?.font,
                        backgroundColor: brandModeData?.buttonColor,
                        borderColor: brandModeData?.buttonColor,
                        borderRadius: brandModeData?.borderRadius + "px",
                        fontWeight:brandModeData?.button02?.font === "DM Sans" ? brandModeData?.button02?.variant: ''
                      }}
                    >
                      Button example
                    </button>
                  </div>
                </div>

                {previewDisableData === true ? (
                  <div></div>
                ) : (
                  <div className="footer-logo-container">
                    <span>Powered by</span>{" "}
                    <img className="footer-logo" alt="footer preview me icon" src={previewmeLogo} />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <LoadingSpinner fullScreen />
            </div>
          )}
        </PageFrame>
      </AuthorizedPage>
    </>
  );
};

export default BrandSettingsPage;
