import React, { useState, useRef } from 'react'
import useOutsideClick from 'helpers/useOutsideClick'
import './Tooltip.scss'

type Props = {
  className?: string
  content: React.ReactNode | React.ReactNodeArray
}

const Tooltip: React.FC<Props> = ({ className, content, children }) => {
  const [showing, setShowing] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick(ref, () => setShowing(false))

  return (
    <div className={'tooltip-host' + (className ? ' ' + className : '') + (showing ? ' showing' : '')}>
      <div className="tooltip-host-container" ref={ref} onClick={() => setShowing(!showing)}>
        {children}
      </div>
      <div className="tooltip-anchor">
        <div className="tooltip">
          <div className="tooltip-text">{content}</div>
        </div>
      </div>
    </div>
  )
}

export default Tooltip
