import { BillingType, Status, SubscriptionPlan, SubscriptionStatus } from 'helpers/enum'
import { useEffect, useState } from 'react'
import * as productService from 'services/productService'
import * as subscriptionService from 'services/subscriptionService'
import { PaymentDetails, Price, Product, Subscription } from 'state/types'
import useAuth from 'state/useAuth'
import useStore from './useStore'
import { getWorkspaceOrganizationId } from '../helpers/organization'
import useWorkspace from './useWorkspace'
import useProduct from './useProduct'
import { useUrlParams } from '../helpers/urlParams'

// Does NOT use global state, i.e. it will load from the server in each place it's used.
const useSubscription = (
  retrieveSubscriptionOnLoad: boolean,
  previewSubscription?: boolean,
  newProductId?: string,
  retrievePaymentDetails?: boolean,
  allProducts?: Product[]
) => {
  const { user } = useAuth()
  const { state } = useStore()
  const [isSubscriptionLoading, setSubscriptionLoading] = useState(true)
  const [isPreviewSubscriptionLoading, setPreviewSubscriptionLoading] = useState(true)
  const [isPaymentDetailsLoading, setPaymentDetailsLoading] = useState(true)
  const [subscription, setSubscription] = useState<Subscription | undefined>(undefined)
  const [subscriptionError, setSubscriptionError] = useState(false)
  const [previewSubscriptionError, setPreviewSubscriptionError] = useState(false)
  const [subscriptionLoaded, setSubscriptionLoaded] = useState<boolean>(false)
  const [previewSubscriptionLoaded, setPreviewSubscriptionLoaded] = useState<boolean>(false)
  const [newSubscription, setNewSubscription] = useState<Subscription>()
  const [newProduct, setNewProduct] = useState<Product>()
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>()
  const [isRedirecting, setRedirecting] = useState(false)
  const admin = !!user?.admin
  const { toggle } = useUrlParams("toggle")
  const { dispatch } = useStore()

  const storeSubscription = async (subscriptionData: Subscription | undefined) => {
    if (subscriptionData && subscriptionData.status !== SubscriptionStatus.Pending && subscriptionData.productId) {
      const { ok, data: productData } = await productService.retreiveProduct(
        subscriptionData.productId,
        getWorkspaceOrganizationId()
      )
      if (
        ok &&
        productData &&
        (productData.name === SubscriptionPlan.Plus ||
          productData.name === SubscriptionPlan.Solo ||
          productData.name === SubscriptionPlan.Professional ||
          productData.name === SubscriptionPlan.Enterprise ||
          productData.name === SubscriptionPlan.Business)
      ) {
        subscriptionData.plan = productData
        dispatch({ type: 'SET_SUBSCRIPTION', value: subscriptionData })
        setSubscription(subscriptionData || undefined)
        setSubscriptionLoading(false)
      }
    } else if (!subscriptionData || (subscriptionData && subscriptionData.status === SubscriptionStatus.Pending)) {
      const { data: allProductData } = await productService.retreiveAllProducts(Status.Active)
      const freeProduct = allProductData?.find(product => product.prices[0].amount === 0)
      if (freeProduct) {
        const userSubscription = { plan: freeProduct }
        dispatch({ type: 'SET_SUBSCRIPTION', value: userSubscription })
        setSubscription(userSubscription || undefined)
        setSubscriptionLoading(false)
      }
    } else {
      setSubscriptionError(true)
      setSubscriptionLoading(false)
    }
  }

  useEffect(() => {
    const loadSubscription = async () => {
      setSubscriptionLoading(true)
      if (!admin) {
        const { ok, data: subscriptionData } = await subscriptionService.getSubscription()
        if (ok) {
          await storeSubscription(subscriptionData)
          if (subscriptionData && subscriptionData.nextProductId) {
            const { ok, data: newProductData } = await productService.retreiveProduct(
              subscriptionData.nextProductId,
              getWorkspaceOrganizationId()
            )
            if (ok && newProductData) {
              setNewProduct(newProductData)
            }
          }
        } else {
          setSubscriptionLoading(false)
        }
      } else {
        setSubscriptionLoading(false)
      }
      setSubscriptionLoaded(true)
    }
    if (retrieveSubscriptionOnLoad) {
      loadSubscription()
    }
  }, [admin, retrieveSubscriptionOnLoad])

  useEffect(() => {
    const loadPreviewSubscription = async () => {
      setPreviewSubscriptionLoading(true)
      if (!admin) {
        const { ok, data: subscriptionData } = await subscriptionService.getSubscription()
        if (ok && subscriptionData && newProductId) {
          const { ok, data: previewSubscriptionData } = await subscriptionService.getPreviewSubscription(
            subscriptionData,
            newProductId,
          )
          if (ok && previewSubscriptionData) {
            const { ok, data: newProductData } = await productService.retreiveProduct(
              newProductId,
              getWorkspaceOrganizationId()
            )
            if (ok && newProductData) {
              setNewProduct(newProductData)
              setNewSubscription(previewSubscriptionData)
            } else {
              setPreviewSubscriptionError(true)
            }
          } else {
            setPreviewSubscriptionError(true)
          }
        } else {
          setPreviewSubscriptionError(true)
        }
      } else {
        setPreviewSubscriptionError(true)
      }

      setPreviewSubscriptionLoading(false)
      setPreviewSubscriptionLoaded(true)
    }

    if (previewSubscription) {
      loadPreviewSubscription()
    }
  }, [admin, previewSubscription, newProductId])

  useEffect(() => {
    const loadPaymentDetails = async () => {
      setPaymentDetailsLoading(true)
      if (!admin) {
        const { ok, data: subscriptionData } = await subscriptionService.getSubscription()
        if (ok && subscriptionData) {
          const { ok, data: paymentDetailsData } = await subscriptionService.getPaymentDetails(subscriptionData)
          if (ok && paymentDetailsData) {
            setPaymentDetails(paymentDetailsData)
          }
        }
        setPaymentDetailsLoading(false)
      }
    }

    if (retrievePaymentDetails) {
      loadPaymentDetails()
    }
  }, [admin, retrievePaymentDetails])

  const changePlan = async (subscription: Subscription, billingType: string | undefined ) => {
    return subscriptionService.changePlan(subscription)
  }
  const editedSubscriptionName = () => {
    const currentProduct = allProducts?.find(product => product.id === subscription?.plan.id)
    const priceIndex = currentProduct?.prices.findIndex((price: Price) => price.stripePriceId === subscription?.plan.prices[0].stripePriceId);
    return (priceIndex === 1 && subscription?.plan.name !== "Basic") ? subscription?.plan.name + " Annual " : subscription?.plan.name === 'Basic' ? 'Free' : subscription?.plan.name
  }

  const editedNextSubscriptionName = (index: number) => {
    return index === 1 ? newProduct?.name + " Annual " : newProduct?.name === 'Basic' ? 'Free' : newProduct?.name
  }

  const getPriceIndex = () => {
    const currentProduct = allProducts?.find(product => product.id === subscription?.plan.id)
    return currentProduct?.prices.findIndex((price: Price) => price.stripePriceId === subscription?.plan.prices[0].stripePriceId) || 0
  }
  const defaultBillingType = () => {
    return Number(toggle) - 1 === 0 ? BillingType.Monthly : BillingType.Annual
  }

  const updatePaymentMethod = async (backTo: string) => {
    setRedirecting(true)
    const ok = subscriptionService.updatePaymentMethod(backTo)
    if (!ok) {
      setRedirecting(false)
    }
  }

  return {
    isSubscriptionLoading,
    subscription,
    subscriptionError,
    subscriptionLoaded,
    isPreviewSubscriptionLoading,
    isPaymentDetailsLoading,
    previewSubscriptionLoaded,
    newSubscription,
    newProduct,
    previewSubscriptionError,
    paymentDetails,
    isRedirecting,
    changePlan,
    updatePaymentMethod,
    editedSubscriptionName,
    editedNextSubscriptionName,
    getPriceIndex
  }
}

export default useSubscription
