import * as React from 'react'

const StarIcon = () => (
  <svg className="icon" width="20px" height="19px" viewBox="0 0 20 19">
    <g strokeWidth="1">
      <g transform="translate(-1290.000000, -297.000000)">
        <path d="M1300,298.129776 L1297.39311,303.411919 L1291.56392,304.25895 L1295.78196,308.370525 L1294.78621,314.176162 L1300,311.435112 L1305.21379,314.176162 L1304.21804,308.370525 L1308.43608,304.25895 L1302.60689,303.411919 L1300,298.129776 Z"></path>
      </g>
    </g>
  </svg>
)

export default StarIcon
