import React, { useState, useRef, useEffect } from 'react'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import './Teleprompter.scss'
import { TeleprompterFontSize } from '../../../state/types'
import { isMobile } from '../../../helpers/browser'
import TeleprompterExitFullScreenIcon from '../../../assets/icons/TeleprompterExitFullScreenIcon'
import TeleprompterEnterFullScreen from '../../../assets/icons/TeleprompterEnterFullScreen'

type Props = {
  text: string
  speed: number
  isScrolling: boolean
  isRecording: boolean
  isTextLoading: boolean
  editScript: () => void
  isFullscreen: boolean
  setIsFullscreen: (v: boolean) => void
  teleprompterFontSize: TeleprompterFontSize
}

const Teleprompter: React.FC<Props> = ({
  text,
  speed,
  isScrolling,
  isRecording,
  isTextLoading,
  editScript,
  isFullscreen,
  setIsFullscreen,
  teleprompterFontSize,
}) => {
  const [animationDuration, setAnimationDuration] = useState(0)
  const [animationDistance, setAnimationDistance] = useState(0)
  const [animationDelay, setAnimationDelay] = useState(0)
  const textViewportRef = useRef<HTMLDivElement | null>(null)
  const scrollingTextBlockRef = useRef<HTMLDivElement | null>(null)
  const editDisabled = isRecording || isTextLoading

  useEffect(() => {
    if (isScrolling) {
      const textViewport = textViewportRef.current
      const textElement = scrollingTextBlockRef.current
      if (textViewport && textElement) {
        const viewportHeight = textViewport.getBoundingClientRect().height
        const textHeight = textElement.getBoundingClientRect().height
        const distance = Math.max(0, textHeight - viewportHeight + 30)
        const duration = (distance / speed) * 1000
        setAnimationDistance(distance)
        setAnimationDuration(duration)
        setAnimationDelay(1000)
      }
    } else {
      setAnimationDistance(0)
      setAnimationDuration(1000)
      setAnimationDelay(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrolling])

  const style = {
    transform: 'translateY(-' + animationDistance + 'px)',
    transitionDuration: animationDuration + 'ms',
    transitionDelay: animationDelay + 'ms',
  }

  return (
    <div className={'teleprompter' + (isScrolling ? ' scrolling' : '') + (isFullscreen ? ' fullscreen' : '')}>
      <div className="teleprompter-content">
        {isTextLoading ? (
          <LoadingSpinner container />
        ) : text ? (
          <div className="teleprompter-text" ref={textViewportRef} style={{ fontSize: teleprompterFontSize }}>
            <div className="scrolling-text-block" ref={scrollingTextBlockRef} style={style}>
              {text}
            </div>
            <div className="transparent-gradient-overlay top" />
            <div className="transparent-gradient-overlay bottom" />
            <div className="edit-button-overlay">
              <button className="edit-script-button link" disabled={editDisabled} onClick={editScript}>
                Edit script
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="teleprompter-placeholder">
              <div className={'placeholder-text' + (isRecording ? ' disabled' : '')}>
                Add a script or bullet points to help you remember important talking points.
              </div>
              <button className="add-script-button primary outline" onClick={editScript} disabled={isRecording}>
                Add script
              </button>
            </div>
          </>
        )}
      </div>
      {isFullscreen && (
        <div className="fullscreen-footer">
          <div className="exit-fullscreen-button" onClick={() => setIsFullscreen(!isFullscreen)}>
            <TeleprompterExitFullScreenIcon />
          </div>
        </div>
      )}
      {!isFullscreen && !isMobile && (
        <div className="enter-fullscreen-button" onClick={() => setIsFullscreen(!isFullscreen)}>
          <TeleprompterEnterFullScreen />
        </div>
      )}
    </div>
  )
}

export default Teleprompter
