import React, { useEffect, useState } from 'react'
import "./VideoTrimSideBar.scss";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from '@material-ui/core/styles'
import CallsAction from '../CallsAction/CallsAction'
import Watermark from '../Watermark/Watermark'
import MenuIcon from "@material-ui/icons/Menu";
import useWorkspace from '../../../../state/useWorkspace'
import { getCurrentPlan } from '../../../../helpers/subscription'
import ProfileImage from '../AudioImage/AudioImage'
import videoChapterCard from '../../VideoChapterCard/VideoChapterCard'
import { useParams } from 'react-router-dom'
import useVideoChapter from '../../../../state/useVideoChapter'
import { parseIndexParam } from '../../../../helpers/urlParams'


type Props = {
  responseData: {
    watermark: boolean;
    cta: boolean;
    videoFx: boolean;
    images: boolean;
    captions: boolean;
  };
  drawerClass:string;
  plan: string;
  currentWorkspaceType: string
};


const VideoTrimSideBar = ({ responseData,drawerClass, plan, currentWorkspaceType }: Props) => {

  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
  const { previewId, index, fieldIndex } = useParams<any>()
  const {  videoChapter } = useVideoChapter(
    previewId,
    parseIndexParam(index),
    parseIndexParam(fieldIndex),
    true
  )

  const scrollPositionY = 0;
  const useStyles = makeStyles({
    root: {
      '--custom-scroll-translate':  `${scrollPositionY ?? 0}px`,
    },
  })

  const classes = useStyles(scrollPositionY)

  const handleAccordionToggle = (panel: string) => () => {
    if (panel !== "images" && panel !== "video-fx" && panel !== "captions") {
      setExpandedPanel((prevExpandedPanel) =>
        prevExpandedPanel === panel ? false : panel
      );
    }
  };

  return (
    <>
      <div className="video-trim-sidebar">
        <Drawer
          variant="permanent"
          className={`sidebar-container ${drawerClass}`}
          anchor="right">
          {videoChapter?.type === 'audio' &&
            <Accordion
              className="accordion-container"
              expanded={expandedPanel === "audio-image"}
              onChange={handleAccordionToggle("audio-image")}
            >
              <AccordionSummary
                className="accordion-summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="video-fx-content"
                id="audio-image-header"
              >
                <Typography>Audio Image</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordition-details">
                <ProfileImage/>
              </AccordionDetails>
            </Accordion>
          }
          {currentWorkspaceType === 'organization' &&
            <>
              <Accordion
                className="accordion-container"
                expanded={expandedPanel === "video-fx"}
                onChange={handleAccordionToggle("video-fx")}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="video-fx-content"
                  id="video-fx-header"
                >
                  <Typography>Video FX <span className="coming-soon-text">(Coming Soon)</span></Typography>
                </AccordionSummary>
                <AccordionDetails className="accordition-details">
                  <p>Video FX Content</p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="accordion-container"
                expanded={expandedPanel === "captions"}
                onChange={handleAccordionToggle("captions")}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="captions-content"
                  id="captions-header"
                >
                  <Typography>Captions <span className="coming-soon-text">(Coming Soon)</span></Typography>
                </AccordionSummary>
                <AccordionDetails className="accordition-details">
                  <p>Caption Content</p>
                </AccordionDetails>
              </Accordion>
              {responseData.watermark &&
                <Accordion
                  className="accordion-container"
                  expanded={expandedPanel === "watermark"}
                  onChange={handleAccordionToggle("watermark")}
                >
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="watermark-content"
                    id="watermark-header"
                  >
                    <Typography>Watermark</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordition-details">
                    <Watermark plan={plan}/>
                  </AccordionDetails>
                </Accordion>
              }
              <Accordion
                className="accordion-container"
                expanded={expandedPanel === "images"}
                onChange={handleAccordionToggle("images")}
              >
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  <Typography>Images <span className="coming-soon-text">(Coming Soon)</span></Typography>
                </AccordionSummary>
                <AccordionDetails className="accordition-details">
                  <p>images content</p>
                </AccordionDetails>
              </Accordion>
              {responseData.cta && (
                <Accordion
                  className="accordion-container"
                  expanded={expandedPanel === "calls-action"}
                  onChange={handleAccordionToggle("calls-action")}
                >
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="calls-action-content"
                    id="calls-action-header"
                  >
                    <Typography>Calls to Action</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordition-details">
                    <CallsAction enabled={responseData.cta} />
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          }
        </Drawer>
      </div>
    </>
  );
};

export default VideoTrimSideBar;
