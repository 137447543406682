import PublishReviewDialog from 'components/preview/PublishReviewDialog/PublishReviewDialog'
import SharingDialog from 'components/preview/SharingDialog/SharingDialog'
import UnpublishConfirmationDialog from 'components/preview/UnpublishConfirmationDialog/UnpublishConfirmationDialog'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import Tooltip from 'components/shared/Tooltip/Tooltip'
import { compareWithCurrentTimeUTC, formatShortDate } from 'helpers/date'
import { PreviewMeObject, PreviewViewMode } from 'helpers/enum'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { InvitationAlgolia, PreviewAlgolia, TagOption } from 'state/types'
import LimitReachedDialog from '../LimitReachedDialog/LimitReachedDialog'
import './PreviewTable.scss'
import { MasterTickCheckBox, TickCheckBox } from './TickCheckBox'
import Table from 'components/shared/Table/Table'
import TableOptionButton from './TableOptionButton'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import Pagination from 'components/shared/Table/Pagination'
import PreviewDuplicateModal from 'components/preview/PreviewDuplicateModal/PreviewAlgoliaDuplicateModal'
import PreviewUpdateTagsModal from 'components/preview/PreviewUpdateTagsModal/PreviewUpdateTagsModal'

import {
  getDisplayTags,
  getDisplayTagsOnMobile,
  getInvitationFromAlgoliaPreview,
  getPreviewSortingIndex,
} from 'helpers/algolia'

type Props = {
  previews: PreviewAlgolia[]
  sortingBy: string
  sortingAsc: boolean
  previewsSelected: PreviewAlgolia[]
  currentPage: number
  itemCount: number
  totalItemPerPage: number
  paginationRange: any
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  bulkSelectPreviews: (previews: PreviewAlgolia[]) => void
  onArchive: (previewId: string, isSuspended: boolean, viewMode?: PreviewViewMode) => Promise<void>
  onRestore: (previewId: string, isSuspended: boolean, viewMode?: PreviewViewMode) => Promise<void>
  onPublish: (preview: PreviewAlgolia, isSuspended: boolean) => Promise<boolean>
  onUnpublish: (preview: PreviewAlgolia, isSuspended: boolean) => Promise<boolean>
  checkCanPublish: (previewId: string) => Promise<{ isPublishable?: boolean; reviewLink?: string }>
  onDelete: (previewId: string, viewMode?: PreviewViewMode) => Promise<void>
  onDuplicate: (newPreview: PreviewAlgolia, duplicatedPreviewId?: string, viewMode?: PreviewViewMode) => Promise<void>
  onSelect: (preview: PreviewAlgolia) => void
  onUnselect: (id: string) => void
  onPageChange: (page: number) => void
  onTotalItemPerPageChange: (itemPerPage: number) => void
  setSortingIndex: (index: string) => void
  applyTag: boolean | null
  tagOptions: TagOption[]
  onUpdateTags: (previewId: string, tagOptions: TagOption[]) => void
}

const PreviewTable: React.FC<Props> = ({
  previews,
  currentPage,
  sortingBy,
  sortingAsc,
  previewsSelected,
  itemCount,
  totalItemPerPage,
  paginationRange,
  applyTag,
  tagOptions,
  onSortingByChange,
  onSortingAscChange,
  bulkSelectPreviews,
  onArchive,
  onRestore,
  onPublish,
  onUnpublish,
  checkCanPublish,
  onDelete,
  onDuplicate,
  onSelect,
  onUnselect,
  onPageChange,
  setSortingIndex,
  onTotalItemPerPageChange,
  onUpdateTags,
}) => {
  const [isShareDialogShowing, setShareDialogShowing] = useState(false)
  const [isUnpublishConfirmationDialogShowing, setUnpublishConfirmationDialogShowing] = useState(false)
  const [isArchiveConfirmationDialogShowing, setArchiveConfirmationDialogShowing] = useState(false)
  const [isDeleteConfirmationDialogShowing, setDeleteConfirmationDialogShowing] = useState(false)
  const [isDuplicateDialogShowing, setIsDuplicateDialogShowing] = useState(false)
  const [isUpdateTagsDialogShowing, setIsUpdateTagsDialogShowing] = useState(false)
  const [isLimitReachedDialogShowing, setLimitReachedDialogShowing] = useState(false)
  const [publishReviewLink, setPublishReviewLink] = useState('')
  const [selectedPreview, setSelectedPreview] = useState<PreviewAlgolia | null>(null)
  const [invitation, setInvitation] = useState<InvitationAlgolia | null>(null)
  const { push } = useHistory()

  const showSaveAsDraft = false

  const tooltipText = 'You have exceeded your published Previews limit on the plan'

  const onShareClick = (preview: PreviewAlgolia) => {
    setSelectedPreview(preview)
    setInvitation(getInvitationFromAlgoliaPreview(preview))
    setShareDialogShowing(true)
  }

  const onPublishClick = async (preview: PreviewAlgolia) => {
    const { id, suspended } = preview
    setSelectedPreview(preview)
    setInvitation(getInvitationFromAlgoliaPreview(preview))

    const { isPublishable, reviewLink } = await checkCanPublish(id)
    if (isPublishable) {
      setLimitReachedDialogShowing(!(await onPublish(preview, suspended)))
    } else if (reviewLink) {
      setPublishReviewLink(reviewLink)
    }
  }

  const onUnpublishClick = (preview: PreviewAlgolia) => {
    setSelectedPreview(preview)
    setInvitation(getInvitationFromAlgoliaPreview(preview))
    setUnpublishConfirmationDialogShowing(true)
  }

  const onPublishReviewClick = () => push(publishReviewLink)

  const onArchiveClick = (preview: PreviewAlgolia) => {
    setSelectedPreview(preview)
    setInvitation(getInvitationFromAlgoliaPreview(preview))
    if (preview.status === 'published') {
      setArchiveConfirmationDialogShowing(true)
    } else {
      onArchive(preview.id, preview.suspended)
    }
  }

  const handleRestoreClick = (preview: PreviewAlgolia) => {
    onRestore(preview.id, preview.suspended)
  }

  const handleDuplicateClick = (preview: PreviewAlgolia) => {
    setIsDuplicateDialogShowing(true)
    setSelectedPreview(preview)
    setInvitation(getInvitationFromAlgoliaPreview(preview))
  }

  const onDuplicateSuccess = (newPreview: PreviewAlgolia) => {
    onDuplicate(newPreview, selectedPreview?.id)
  }

  const onDeleteClick = (preview: PreviewAlgolia) => {
    setSelectedPreview(preview)
    setInvitation(getInvitationFromAlgoliaPreview(preview))
    setDeleteConfirmationDialogShowing(true)
  }

  const onSelectAll = () => {
    bulkSelectPreviews(previews)
  }

  const onUnselectAll = () => {
    bulkSelectPreviews([])
  }

  const handlePageChange = (page: number) => {
    onUnselectAll()
    onPageChange(page)
  }

  const convertNumber = (number: number) => {
    if (number < 1000) return number

    const COUNT_ABBRS = ['', 'K', 'M', 'G']

    const i = 0 === number ? number : Math.floor(Math.log(number) / Math.log(1000))
    let result = parseFloat((number / Math.pow(1000, i)).toFixed(2)).toString()
    result += `${COUNT_ABBRS[i]}`

    return result
  }

  const handleSortingAscChange = (sortingAsc: boolean) => {
    onUnselectAll()
    onSortingAscChange(sortingAsc)
  }

  const handleSortingByChange = (sortingBy: string) => {
    onUnselectAll()
    onSortingByChange(sortingBy)
  }

  useEffect(() => {
    setSortingIndex(getPreviewSortingIndex(sortingBy, sortingAsc))
  }, [sortingAsc, sortingBy])

  const handleTotalItemsPerPageChange = (itemPerPage: number) => {
    onUnselectAll()
    onTotalItemPerPageChange(itemPerPage)
  }

  const handleEditTagClick = (preview: PreviewAlgolia) => {
    setSelectedPreview(preview)
    setIsUpdateTagsDialogShowing(true)
  }

  const handleUpdateTagsSuccess = (previewId: string, newSelectedTagIds: string[]) => {
    let newSelectedTags: TagOption[] = []
    tagOptions.forEach((tag: TagOption) => {
      newSelectedTagIds.forEach((selectedTagId: string) => {
        if (tag.id === selectedTagId) newSelectedTags.push(tag)
      })
    })

    onUpdateTags(previewId, newSelectedTags)
  }

  const renderTagsOnMobile = (tags: string) => {
    return getDisplayTagsOnMobile(tags)?.map(tag => <div className="mobile-tag-item">{tag}</div>)
  }

  return (
    <>
      <Table
        className={'previews-table' + (applyTag ? ' with-tag' : '')}
        columns={[
          {
            name: 'checkbox',
            label: (
              <MasterTickCheckBox
                isSelected={previews.length === previewsSelected.length}
                onSelect={onSelectAll}
                onUnselect={onUnselectAll}
              />
            ),
            className: 'preview-checkbox',
            render: preview => (
              <TickCheckBox
                preview={preview}
                isSelected={!!previewsSelected.find(p => p.id === preview.id)}
                onSelect={onSelect}
                onUnselect={onUnselect}
              />
            ),
            sortable: false,
          },
          {
            name: 'status',
            label: 'Status',
            className: 'status',
            render: preview => {
              const { status, archived } = preview

              const previewStatus = status.split(' ')[0]
              const statusDate = !!status.split(' ')[1] ? ' ' + formatShortDate(status.split(' ')[1]) : ''
              const renderedStatus =
                previewStatus === 'Published' ? 'Published' + statusDate : previewStatus
              const statusClass = previewStatus.toLowerCase()

              return preview.suspended ? (
                <Tooltip className="suspended-preview-tooltip" content={tooltipText}>
                  <div className="preview-status content suspended" onClick={e => e.preventDefault()}>
                    Suspended
                  </div>
                </Tooltip>
              ) : (
                <div className={'preview-status content ' + (preview.archived ? 'archived' : statusClass)}>
                  {archived ? 'Archived' : renderedStatus}
                </div>
              )
            },
          },
          {
            name: 'title',
            label: 'Primary Title',
            className: 'title-1',
            render: preview => {
              const { title } = preview
              return <Link to={`/preview/${preview.id}`}>{title}</Link>
            },
          },
          {
            name: 'subtitle',
            label: 'Subtitle',
            className: 'title-2',
            render: preview => {
              const { subtitle } = preview
              return !!subtitle ? subtitle : '-'
            },
          },
          {
            name: 'creator',
            label: 'Creator',
            className: 'creator',
            render: preview => preview.creator || '-',
          },
          {
            name: 'tags',
            label: 'Tags',
            className: 'tags',
            hide: !applyTag,
            render: preview => {
              if (applyTag) {
                if (preview?.tags?.startsWith('{')) return getDisplayTags(preview.tags)
                return preview?.tags || '-'
              }
              return null
            },
          },
          {
            name: 'views',
            label: 'Views',
            className: 'views',
            render: preview => {
              return (
                <Link to={`/analytics/p/${preview.id}`} className="views">
                  {convertNumber(preview.views)}
                </Link>
              )
            },
          },
          {
            name: 'settings',
            className: 'settings',
            render: preview => {
              const { id, archived, singular, invitation } = preview
              const isPublished = preview.status.startsWith('Published')
              const isCategoryIndividual = preview.previewCategory === 'individual' && preview.invitation === null
              const isCategoryBusiness = preview.previewCategory === 'business' && preview.invitation === null
              const isCategoryInvitation = preview.invitation !== null
              const hasNoCategory = !(isCategoryIndividual || isCategoryBusiness || isCategoryInvitation)
              return (
                <TableOptionButton
                  preview={preview}
                  options={[
                    { option: 'Edit', onClick: () => push(`/edit/${id}`), hide: preview.archived },
                    { option: 'Share', onClick: () => onShareClick(preview), hide: !isPublished },
                    { option: 'Analytics', onClick: () => push(`/analytics/p/${id}`) },
                    { option: 'Publish', onClick: () => onPublishClick(preview), hide: archived || isPublished },
                    { option: 'Unpublish', onClick: () => onUnpublishClick(preview), hide: archived || !isPublished },
                    { option: 'Restore', onClick: () => handleRestoreClick(preview), hide: !archived },
                    { option: 'Edit tags', onClick: () => handleEditTagClick(preview), hide: !applyTag || archived },
                    {
                      option: 'Duplicate',
                      onClick: () => handleDuplicateClick(preview),
                      hide: singular || !!invitation || hasNoCategory,
                    },
                    { option: 'Archive', onClick: () => onArchiveClick(preview), hide: archived },
                    { option: 'Delete', onClick: () => onDeleteClick(preview), hide: !archived },
                  ]}
                ></TableOptionButton>
              )
            },
            notLink: true,
            sortable: false,
          },
        ]}
        rows={previews}
        sortable
        sortingBy={sortingBy}
        sortingAsc={sortingAsc}
        onSortingByChange={handleSortingByChange}
        onSortingAscChange={handleSortingAscChange}
      />

      {selectedPreview && (
        <>
          <SharingDialog
            id={selectedPreview.id}
            isShowing={isShareDialogShowing}
            onClose={() => setShareDialogShowing(false)}
            object={PreviewMeObject.Preview}
          />
          <UnpublishConfirmationDialog
            isShowing={isUnpublishConfirmationDialogShowing}
            isClosed={
              (invitation?.endDate !== null && invitation?.endDate !== undefined
                ? compareWithCurrentTimeUTC(invitation?.endDate) === -1
                : false) || selectedPreview.endDate
                ? compareWithCurrentTimeUTC(selectedPreview.endDate) === -1
                : false
            }
            endDate={invitation?.endDate || selectedPreview.endDate}
            invitationOwner={invitation?.ownerName}
            onClose={() => setUnpublishConfirmationDialogShowing(false)}
            onOk={() => onUnpublish(selectedPreview, selectedPreview.suspended)}
          />
          <UnpublishConfirmationDialog
            isShowing={isArchiveConfirmationDialogShowing}
            isClosed={
              (invitation?.endDate ? compareWithCurrentTimeUTC(invitation?.endDate) === -1 : false) ||
              (selectedPreview.endDate ? compareWithCurrentTimeUTC(selectedPreview.endDate) === -1 : false)
            }
            endDate={invitation?.endDate || selectedPreview.endDate}
            isArchiving={true}
            invitationOwner={invitation?.ownerName}
            onClose={() => setArchiveConfirmationDialogShowing(false)}
            onOk={() => onArchive(selectedPreview.id, selectedPreview.suspended, PreviewViewMode.TABLE_VIEW)}
          />
          <PublishReviewDialog
            isShowing={!!publishReviewLink}
            onClose={() => setPublishReviewLink('')}
            onOk={onPublishReviewClick}
          />
          <LimitReachedDialog
            showSaveAsDraft={showSaveAsDraft}
            category={selectedPreview.previewCategory!}
            isShowing={isLimitReachedDialogShowing}
            onClose={() => setLimitReachedDialogShowing(false)}
          />
          <ConfirmationDialog
            className="delete-confirmation-dialog"
            isShowing={isDeleteConfirmationDialogShowing}
            isWarning
            cancelButtonText="No, thanks"
            okButtonText={`Yes, delete this Preview`}
            title="Heads up!"
            onClose={() => setDeleteConfirmationDialogShowing(false)}
            onOk={() => onDelete(selectedPreview.id, PreviewViewMode.TABLE_VIEW)}
          >
            This will permanently delete all videos and other content within. Are you sure you wish to continue?
          </ConfirmationDialog>
          <PreviewDuplicateModal
            isShowing={isDuplicateDialogShowing}
            hideModal={() => setIsDuplicateDialogShowing(false)}
            preview={selectedPreview}
            onDuplicateSuccess={preview => {
              if (preview) onDuplicateSuccess(preview)
            }}
          />
          {applyTag && (
            <PreviewUpdateTagsModal
              tagOptions={tagOptions}
              isShowing={isUpdateTagsDialogShowing}
              hideModal={() => {
                setSelectedPreview(null)
                setIsUpdateTagsDialogShowing(false)
              }}
              preview={selectedPreview}
              onUpdateTagsSuccess={handleUpdateTagsSuccess}
            />
          )}
        </>
      )}

      <div className="previews-page-mobile-content">
        <div className="previews-list-mobile">
          {previews.map(preview => {
            const { id, status, title, subtitle, archived, singular, invitation, suspended, views } = preview
            const isPublished = preview.status === 'published'
            const isCategoryIndividual = preview.previewCategory === 'individual' && preview.invitation === undefined
            const isCategoryBusiness = preview.previewCategory === 'business' && preview.invitation === undefined
            const isCategoryInvitation = preview.invitation !== undefined
            const hasNoCategory = !(isCategoryIndividual || isCategoryBusiness || isCategoryInvitation)

            const previewStatus = status.split(' ')[0]
            const statusDate = !!status.split(' ')[1] ? ' ' + formatShortDate(status.split(' ')[1]) : ''
            const renderedStatus = previewStatus === 'Published' ? 'Published' + statusDate : 'Draft'
            const statusClass = previewStatus.toLowerCase()

            return (
              <div key={id} className="preview-list-card">
                <div className="preview-list-header">
                  <Link className="preview-title" to={`/preview/${id}`}>
                    {title}
                  </Link>
                  <TableOptionButton
                    preview={preview}
                    options={[
                      { option: 'Edit', onClick: () => push(`/edit/${id}`), hide: preview.archived },
                      { option: 'Share', onClick: () => onShareClick(preview), hide: !isPublished },
                      { option: 'Analytics', onClick: () => push(`/analytics/p/${id}`) },
                      { option: 'Publish', onClick: () => onPublishClick(preview), hide: archived || isPublished },
                      { option: 'Unpublish', onClick: () => onUnpublishClick(preview), hide: archived || !isPublished },
                      { option: 'Restore', onClick: () => handleRestoreClick(preview), hide: !archived },
                      { option: 'Edit tags', onClick: () => handleEditTagClick(preview), hide: !applyTag || archived },
                      {
                        option: 'Duplicate',
                        onClick: () => handleDuplicateClick(preview),
                        hide: singular || !!invitation || hasNoCategory,
                      },
                      { option: 'Archive', onClick: () => onArchiveClick(preview), hide: archived },
                      { option: 'Delete', onClick: () => onDeleteClick(preview), hide: !archived },
                    ]}
                  ></TableOptionButton>
                </div>
                <div className="sub-title">{subtitle}</div>

                <div className="preview-footer">
                  <div className="status">
                    {suspended ? (
                      <Tooltip className="suspended-preview-tooltip" content={tooltipText}>
                        <div className="preview-status suspended" onClick={e => e.preventDefault()}>
                          Suspended
                        </div>
                      </Tooltip>
                    ) : (
                      <div className={'preview-status content ' + (preview.archived ? 'archived' : statusClass)}>
                        {archived ? 'Archived' : renderedStatus}
                      </div>
                    )}
                  </div>
                </div>
                {applyTag && (
                  <div className="tags-container">
                    Tags: <div className="d-flex"> {renderTagsOnMobile(preview.tags)}</div>
                  </div>
                )}
                <Link className="details-link" to={`/preview/${id}`}>
                  <div className="">
                    <TickCheckBox
                      preview={preview}
                      isSelected={!!previewsSelected.find(p => p.id === preview.id)}
                      onSelect={onSelect}
                      onUnselect={onUnselect}
                    />
                  </div>
                  <ChevronRightIcon />
                </Link>
              </div>
            )
          })}
        </div>
      </div>
      <Pagination
        className="preview-pagination"
        currentPage={currentPage}
        totalCount={itemCount}
        pageSize={totalItemPerPage}
        onPageChange={(page: number) => handlePageChange(page)}
        onChangeTotalItemsPerPage={(itemPerPage: number) => handleTotalItemsPerPageChange(itemPerPage)}
        paginationRange={paginationRange}
      />
    </>
  )
}

export default PreviewTable
