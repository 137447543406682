import { Invitation, InvitationSuccessPageInfo, VideoCategoryTemplate } from 'state/types'
import { getWorkspaceOrganizationId } from '../helpers/organization'
import { getApi, getApiWithAuthorization } from './api'

export const createInvitation = async (
  templateId: string,
  name: string,
  ownerName: string,
  organizationId: string,
  industries?: string,
  startDate?: string,
  endDate?: string,
  instructions?: string,
  sendEmailToInviter?: boolean,
  brandingImageStorageId?: string,
  invitationSuccessPageInfo?: InvitationSuccessPageInfo,
  videoCategoryTemplates?: VideoCategoryTemplate[]
) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.post<string>(`/api/invitation/create`, {
    templateId: templateId,
    name: name,
    ownerName: ownerName,
    industries: industries,
    startDate: startDate,
    endDate: endDate,
    instructions: instructions,
    sendEmailToInviter: sendEmailToInviter,
    organizationId: organizationId,
    businessLogoImageStorageId: brandingImageStorageId,
    invitationSuccessPage: invitationSuccessPageInfo,
    videoCategoryTemplates: videoCategoryTemplates,
  })
}

export const updateInvitation = async (
  invitationId: string,
  name: string,
  ownerName: string,
  industries?: string,
  startDate?: string,
  endDate?: string,
  instructions?: string,
  sendEmailToInviter?: boolean,
  businessLogoImageStorageId?: string,
  invitationSuccessPage?: InvitationSuccessPageInfo,
  videoCategoryTemplates?: VideoCategoryTemplate[]
) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<string>(`/api/invitation/${invitationId}`, {
    name,
    ownerName,
    industries,
    startDate,
    endDate,
    instructions,
    sendEmailToInviter,
    organizationId: workspaceOrganizationId,
    businessLogoImageStorageId: businessLogoImageStorageId,
    invitationSuccessPage: invitationSuccessPage,
    videoCategoryTemplates: videoCategoryTemplates,
  })
}

export const duplicateInvitation = (templateId: string, name: string, invitation: Invitation) => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  return createInvitation(
    templateId,
    name,
    invitation.ownerName,
    invitation.organizationId || '',
    invitation.industries,
    undefined,
    yesterday.toISOString(),
    invitation.instructions,
    invitation.sendEmailToInviter,
    invitation.businessLogoImageStorageId,
    invitation.invitationSuccessPage,
    invitation.videoCategoryTemplates
  )
}

export const getInvitationSummaries = async (organizationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<Invitation[]>(`/api/invitation/summaries/${organizationId}`)
}

export const getInvitationSummaries2 = async (organizationId: string, archived?: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  const archivedString = archived === undefined ? '' : archived ? '?archived=true' : '?archived=false'
  return apiWithAuth.get<{
    invitationSummaries: Invitation[]
    activeInvitationCount: number
    archivedInvitationCount: number
  }>(`/api/invitation/summaries/${organizationId}/2${archivedString}`)
}

export const getInvitationSummary = async (invitationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<Invitation>(`/api/invitation/${invitationId}/summary`)
}

export const getInvitationDetails = async (invitationId: string, page: number | undefined, selectedLabelFilter: string | undefined, searchQuery: string | undefined, sortingBy: string | undefined, sortingAsc: boolean | undefined) => {
  const apiWithAuth = await getApiWithAuthorization()

  return await apiWithAuth.get<Invitation>(`/api/invitation/${invitationId}/details?page=${page ? page: 1}&selectedLabelFilter=${selectedLabelFilter ? selectedLabelFilter : "All"}
  &searchQuery=${searchQuery ? searchQuery : ""}&sortingBy=${sortingBy}&sortingAsc=${sortingAsc}`)
}

export const getInvitationDetailsWithAllResponses = async (invitationId: string | undefined) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<Invitation>(`/api/invitation/${invitationId}/allDetails`)
}

export const findPreview = async (invitationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.get<string>(
    `/api/invitation/${invitationId}/preview` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const createPreview = async (invitationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.post<string>(
    `/api/invitation/${invitationId}/preview` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updateArchived = async (invitationId: string, value: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<void>(`/api/invitation/${invitationId}/updateArchived?archived=${value}`)
}

export const updateStarred = async (invitationId: string, previewId: string, value: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<void>(`/api/invitation/${invitationId}/preview/${previewId}/starred?value=${value}`)
}

export const updateLabel = async (invitationId: string, previewId: string, value: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<void>(`/api/invitation/${invitationId}/preview/${previewId}/label?value=${value}`)
}

export const getPublicInvitationSummary = async (invitationId: string) => {
  return getApi().get<Invitation>(`/api/invitationSummary/${invitationId}`)
}
