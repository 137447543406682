import { AccountType } from 'helpers/enum'
import { capitalize } from 'lodash'
import React from 'react'
import './AccountTypeSelector.scss'
type Props = {
  inputRef?: any
  label?: string
  otherErrorMessages?: string
  name?: string
  formErrors?: any
  isPersonalAccount: boolean
  isOrganizationAccount: boolean
  setOrganizationAccount: (state: boolean) => void
  setPersonalAccount: (state: boolean) => void
}

const AccountTypeSelector: React.FC<Props> = ({
  inputRef,
  label,
  name,
  formErrors,
  isPersonalAccount,
  isOrganizationAccount,
  setOrganizationAccount,
  setPersonalAccount,
}) => {
  const accountTypeError = name && formErrors?.[name]?.message

  const onAccountTypeSelected = (type: AccountType) => {
    if (type === AccountType.Personal) {
      setPersonalAccount(true)
      setOrganizationAccount(false)
    } else if (type === AccountType.Organization) {
      setOrganizationAccount(true)
      setPersonalAccount(false)
    }
  }

  return (
    <div className="account-type">
      <div className="form-input-label-row">
        {label && <label className="form-input-label">{label}</label>}
        {accountTypeError && <div className="form-input-validation-error">{accountTypeError}</div>}
      </div>

      <div className="option" onClick={() => onAccountTypeSelected(AccountType.Personal)}>
        <div className="check-box">
          <input
            type="radio"
            name={name}
            value={AccountType.Personal}
            ref={inputRef}
            checked={isPersonalAccount}
            onChange={() => onAccountTypeSelected(AccountType.Personal)}
          />
        </div>
        <div className="text">
          <p className="title">{capitalize(AccountType.Personal)} Account</p>
          <p className="subtext">
            The best option if you're an individual who'll be showcasing themselves/making applications via PreviewMe
          </p>
        </div>
      </div>
      <div className="option" onClick={() => onAccountTypeSelected(AccountType.Organization)}>
        <div className="check-box">
          <input
            type="radio"
            name={name}
            value={AccountType.Organization}
            ref={inputRef}
            checked={isOrganizationAccount}
            onChange={() => onAccountTypeSelected(AccountType.Organization)}
          />
        </div>

        <div className="text">
          <p className="title">Business Workspace</p>
          <p className="subtext">For solo professionals and businesses</p>
        </div>
      </div>
    </div>
  )
}

export default AccountTypeSelector
