import AssetsIcon from 'assets/icons/AssetsIcon'
import BackArrowSmallIcon from 'assets/icons/BackArrowSmallIcon'
import RecordIcon from 'assets/icons/RecordIcon'
import ScreenIcon from 'assets/icons/ScreenIcon'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import OptionsMenuButton from 'components/shared/OptionsMenuButton/OptionsMenuButton'
import ProgressBar from 'components/shared/ProgressBar/ProgressBar'
import SingleFieldForm from 'components/shared/SingleFieldForm/SingleFieldForm'
import VideoHintsDialog from 'components/video/VideoHintsDialog/VideoHintsDialog'
import VideoPlayer from 'components/video/VideoPlayer/VideoPlayer'
import { getAbsoluteStyle } from 'helpers/cardAnimation'
import { formatChapterLabel, isMediaRecorderSupported, videoFileSizeLimitInMb } from 'helpers/media'
import React, {  useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Rect } from 'state/types'
import useVideoChapter from 'state/useVideoChapter'
import './VideoChapterCard.scss'
import TeleprompterScriptDialog from '../TeleprompterScriptDialog/TeleprompterScriptDialog'
import UploadVideoProfileImageDialog from '../UploadProfileImageDialog/UploadVideoProfileImageDialog'
import UploadVideoAudioButton from './UploadVideoAudioButton'
import VideoAudioIdentifierIcon from './VideoAudioIdentifierIcon'
import { formatContentBlockFieldFormat } from '../../../helpers/formatting'
import { downloadChapterContent } from '../../../services/videoService'
import useWatermark from '../../../state/useWatermark'
import { getWorkspaceOrganizationId } from '../../../helpers/organization'
import useVideoSuite from '../../../state/useVideoSuite'
import useStore from '../../../state/useStore'
import { parseIndexParam } from '../../../helpers/urlParams'
import { useHistory } from 'react-router-dom';


type Props = {
  previewId: string
  isPreviewPublished: boolean
  blockIndex: number
  fieldIndex: number
  chapterCount: number
  absolutePosition?: Rect // Used for animating cards when reordering.
  moveLeft: () => void
  moveRight: () => void
}

const VideoChapterCard = ({
  previewId,
  isPreviewPublished,
  blockIndex,
  fieldIndex,
  chapterCount,
  absolutePosition,
  moveLeft,
  moveRight,
}: Props) => {
  const [isUploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isRemoveVideoLoading, setRemoveVideoLoading] = useState(false)
  const [isInstructionDialogShowing, setInstructionDialogShowing] = useState(false)
  const [isDeleteDialogShowing, setDeleteDialogShowing] = useState(false)
  const [isDeleteChapterDialogShowing, setDeleteChapterDialogShowing] = useState(false)
  const [isDeleteErrorDialogShowing, setDeleteErrorDialogShowing] = useState(false)
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)
  const [isRenameChapterDialogShowing, setRenameChapterDialogShowing] = useState(false)
  const [isTeleprompterScriptDialogShowing, setTeleprompterScriptDialogShowing] = useState(false)
  const [isOptionsMenuShowing, setOptionsMenuShowing] = useState(false)
  const organizationId = getWorkspaceOrganizationId()
  const { defaultWatermarkData } = useWatermark()
  const chapterIndex = parseIndexParam(String(fieldIndex)) || 0
  const { index } = useParams<any>()
  //Added a separate feature flag config because the config from VideoTrimEditorPage is inaccessible
  const responseData = {
    cta: true,
    watermark: true,
    videoFx: true,
    images: true,
    captions: true
  };


  const {
    videoChapter,
    contentBlockField,
    contentBlock,
    isValidationActive,
    uploadVideo,
    deleteVideo,
    remove,
    rename,
    checkPermanentlyDeleted,
    saveProfileImageStorageId,
    saveType,
    deleteVideoSuite
  } = useVideoChapter(previewId, blockIndex, fieldIndex)

  const field = contentBlockField!
  const chapter = videoChapter!
  const { dispatch } = useStore()
  const abortRef = useRef<(() => void) | null>(null)
  const baseLink = `/edit/${previewId}/video/${blockIndex}`
  const recordLink = `${baseLink}/record/${fieldIndex}`
  const recordScreenLink = `${baseLink}/screen/${fieldIndex}`
  const editLink = `${baseLink}/trim/${fieldIndex}`
  const assetsLink = `${baseLink}/assets/${fieldIndex}`
  const videoTrimEditorPath = `/edit/${previewId}/video/${index}/trim/${chapterIndex}/true`
  const showValidationError = isValidationActive && field.required && !chapter.rawVideoStorageId
  const canRename = !field.required && !!contentBlock?.editable
  const isOnlyRecordedVideo = !contentBlock?.contentBlockFields?.some(
    f => f !== field && !!f.videoChapter?.rawVideoStorageId
  )
  const canDelete = !field.required && !!contentBlock?.editable
  const canMoveRight = !!contentBlock?.editable && chapterCount > 1 && fieldIndex < chapterCount - 1
  const canMoveLeft = !!contentBlock?.editable && chapterCount > 1 && fieldIndex > 0
  const storageId = chapter.rawVideoStorageId
  const videoUrl = storageId ? chapter.trimmedMp4Url : undefined
  const history = useHistory();
  const onCancel = () => {
    if (isUploading) {
      setUploading(false)
      setProgress(0)
      if (abortRef.current) {
        abortRef.current()
        abortRef.current = null
      }
    }
  }

  const onEditButtonClick = async () => {
    await new Promise(resolve => setTimeout(resolve, 300));
    history.push(editLink);
  };

  const onRemoveVideoClick = async () => {
    if ((field.required || isOnlyRecordedVideo) && isPreviewPublished) {
      setDeleteErrorDialogShowing(true)
    } else {
      setRemoveVideoLoading(true)
      const willBePermanentlyDeleted = await checkPermanentlyDeleted()
      if (willBePermanentlyDeleted) {
        setRemoveVideoLoading(false)
        setDeleteDialogShowing(true)
      } else {
        deleteVideo()
        setRemoveVideoLoading(false)
      }
    }

  }

  const onDeleteChapterClick = async () => {
    if (isOnlyRecordedVideo && isPreviewPublished) {
      setDeleteErrorDialogShowing(true)
    } else {
      const willBePermanentlyDeleted = await checkPermanentlyDeleted()
      if (willBePermanentlyDeleted) {
        setDeleteChapterDialogShowing(true)
      } else {
        remove()
      }
    }
  }
  //downloadChapterContent(chapter.id)
  const onDownloadContent = async () => {
    if (videoChapter?.id) {
      await downloadChapterContent(videoChapter?.id, responseData.watermark, false)
    }
  };

  // Can't cancel when the cloudinary upload is finished and the backend metadata update is in progress.
  const isCancelDisabled = progress >= 100

  if (isUploading) {
    document.body.classList.add('uploading')
  } else {
    document.body.classList.remove('uploading')
  }

  return (
    <div
      id={'video-chapter-card-' + fieldIndex}
      className={'video-chapter-card' + (showValidationError ? ' show-validation-error' : '')}
      style={getAbsoluteStyle(absolutePosition)}
    >
      <div className="top-row">
        <div className="arrow-buttons">
          {canMoveLeft && (
            <button className="arrow-button plain left" onClick={moveLeft}>
              <BackArrowSmallIcon />
            </button>
          )}
          {canMoveRight && (
            <button className="arrow-button plain right" onClick={moveRight}>
              <BackArrowSmallIcon />
            </button>
          )}
        </div>
        {videoChapter?.type && <VideoAudioIdentifierIcon isCenter={!field.instructions}  videoChapterType={videoChapter?.type} />}
        <div className={'top-buttons'}>
          {field.instructions && (
          <div className="instructions-button-background" onClick={() => setInstructionDialogShowing(true)}>
            View Question
          </div>
          )}
          <div style={{ zIndex: 1000 }}>
            <OptionsMenuButton
              isShowing={isOptionsMenuShowing}
              setShowing={setOptionsMenuShowing}
              options={[
                { option: 'Rename chapter', hide: !canRename, onClick: () => setRenameChapterDialogShowing(true) },
                { option: 'Delete chapter', hide: !canDelete, onClick: onDeleteChapterClick },
                {
                  option: 'Teleprompter script',
                  hide: !videoChapter?.teleprompterText,
                  onClick: () => setTeleprompterScriptDialogShowing(true),
                },
                {
                  option: 'Download Content',
                  hide: !videoChapter?.rawVideoStorageId,
                  onClick:() => onDownloadContent()
                  ,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="chapter-text">{formatChapterLabel(fieldIndex)}</div>
      <h2>{chapter.name}</h2>
      <div className="card-body">
        {!chapter.rawVideoStorageId ? (
          <>
            {!isUploading ? (
              <div className={'record-and-upload-buttons' + (!isMediaRecorderSupported ? ' no-record' : '')}>
                {isMediaRecorderSupported && (
                  <Link className="record-link button primary outline" to={recordLink}>
                    <RecordIcon />
                    Record {formatContentBlockFieldFormat(field.format)}
                  </Link>
                )}
                {isMediaRecorderSupported && field.format !== 'audio-only' && (
                  <Link className="record-screen-link button primary outline" to={recordScreenLink}>
                    <ScreenIcon />
                    Record screen
                  </Link>
                )}
                <UploadVideoAudioButton
                  onFileSizeTooLargeError={() => setFileSizeErrorDialogShowing(true)}
                  onStartUploading={() => setUploading(true)}
                  onFinishUploading={() => setUploading(false)}
                  uploadVideo={uploadVideo}
                  setProgress={setProgress}
                  abortRef={abortRef}
                  saveType={saveType}
                  audioOnly={field.format === 'audio-only'}
                  videoOnly={field.format === 'video-only'}
                  videoTrimEditorPath={videoTrimEditorPath}
                />
                <Link className="assets-link button" to={assetsLink}>
                  <div className="assets-icon-container">
                    <AssetsIcon />
                  </div>
                  Add from Assets
                </Link>
              </div>
            ) : (
              <div className="uploading-buttons">
                <button
                  type="button"
                  className={'cancel-upload-button' + (isCancelDisabled ? ' disabled' : '')}
                  onClick={onCancel}
                  disabled={isCancelDisabled}
                >
                  Cancel upload
                </button>
                <ProgressBar progress={progress} />
              </div>
            )}
          </>
        ) : (
          <>
            <VideoPlayer
              src={videoUrl}
              audioOnly={chapter.type === 'audio'}
              profileImageStorageId={chapter.profileImageStorageId}
            />
            <div className="card-buttons">
              <LoadingButton
                className="remove-video-button link"
                onClick={onRemoveVideoClick}
                isLoading={isRemoveVideoLoading}
                sideBySideLayout
              >
                Remove
              </LoadingButton>
              <button className="edit-button button link"  onClick={onEditButtonClick}>
                Effects
              </button>
            </div>
          </>
        )}
      </div>
      {field?.instructions && (
        <VideoHintsDialog
          isShowing={isInstructionDialogShowing}
          onClose={() => setInstructionDialogShowing(false)}
          chapterIndex={fieldIndex}
          chapterName={chapter.name}
          instructions={field.instructions}
        />
      )}
      {videoChapter?.teleprompterText && (
        <TeleprompterScriptDialog
          isShowing={isTeleprompterScriptDialogShowing}
          teleprompterScript={videoChapter.teleprompterText}
          onClose={() => setTeleprompterScriptDialogShowing(false)}
        />
      )}
      <ConfirmationDialog
        isShowing={isDeleteDialogShowing}
        cancelButtonText="No, cancel that"
        okButtonText="Yes, delete this video"
        title="Heads up!"
        isWarning
        onClose={() => setDeleteDialogShowing(false)}
        onOk={deleteVideo}
      >
        If you remove this video from your preview it will be permanently deleted. Would you like to continue?
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isDeleteChapterDialogShowing}
        cancelButtonText="No, cancel that"
        okButtonText="Yes, delete chapter"
        title="Heads up!"
        isWarning
        onClose={() => setDeleteChapterDialogShowing(false)}
        onOk={() => {
          remove()
          deleteVideoSuite()
        }}
      >
        If you remove this chapter your video will be permanently deleted. Would you like to continue?
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isDeleteErrorDialogShowing}
        okButtonText="Ok"
        title={isOnlyRecordedVideo ? 'At least one chapter is required' : 'This chapter is required'}
        onClose={() => setDeleteErrorDialogShowing(false)}
        className={'delete-video-error-dialog' + (isOnlyRecordedVideo ? ' wide' : '')}
      >
        {contentBlock?.editable
          ? 'To remove this video, first record or upload a video for another chapter, or unpublish your Preview.'
          : 'You must first unpublish your preview to remove this video.'}
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Video too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
        className="video-file-size-error-dialog"
      >
        {`This video is too large. Please choose a video file smaller than ${videoFileSizeLimitInMb} MB.`}
      </ConfirmationDialog>
      {canRename && (
        <CloseableDialog
          className="rename-chapter-dialog"
          isShowing={isRenameChapterDialogShowing}
          onClose={() => setRenameChapterDialogShowing(false)}
        >
          <h2>Rename Chapter</h2>
          <SingleFieldForm
            placeholder="Chapter title"
            validationErrorText="Please enter a title for your chapter"
            submitButtonText="Save"
            initialValue={chapter.name}
            triggerReset={isRenameChapterDialogShowing}
            onCancel={() => setRenameChapterDialogShowing(false)}
            onSubmit={async name => {
              const ok = await rename(name)
              if (ok) {
                setRenameChapterDialogShowing(false)
              }
            }}
          />
        </CloseableDialog>
      )}
    </div>
  )
}

export default VideoChapterCard
