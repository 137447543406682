import React from 'react'
import { ContentBlockField } from 'state/types'
import { getValues } from 'helpers/validation'
import { getProfileImageUrl } from 'helpers/cloudinary'
import PublishedPreviewWebsiteLinks from 'components/preview/PublishedPreviewWebsiteLinks/PublishedPreviewWebsiteLinks'
import './PublishedPreviewTeamMembers.scss'

type Props = {
  fields: ContentBlockField[]
  trackLinkClick: (fieldId: string) => void
}

const PublishedPreviewTeamMembers: React.FC<Props> = ({ fields, trackLinkClick }) => {
  const combineNamesAndRoles = fields.every(field => {
    const { name, role } = getValues(field)
    return name && role && name.length + role.length < 20
  })

  return (
    <div className="team-members">
      {fields.map(field => {
        const { name, role, email, phone, location, links, profileImage } = getValues(field)
        return (
          <div key={field.id} className={'team-member' + (profileImage ? ' has-profile-image' : '')}>
            <div className="team-member-profile">
              <div className="team-member-values">
                <div className="value team-member-name">{name + (combineNamesAndRoles ? ', ' + role : '')}</div>
                {role && !combineNamesAndRoles && <div className="value">{role}</div>}
                {email && (
                  <a className="value" href={`mailto:${email}`}>
                    {email}
                  </a>
                )}
                {phone && <div className="value">{phone}</div>}
                {location && <div className="value">{location}</div>}
              </div>
              {profileImage && (
                <div className="team-member-profile-image">
                  <img src={getProfileImageUrl(profileImage)} alt="" />
                </div>
              )}
            </div>
            {links && <PublishedPreviewWebsiteLinks fields={links} hasLabel={false} trackLinkClick={trackLinkClick} />}
          </div>
        )
      })}
    </div>
  )
}

export default PublishedPreviewTeamMembers
