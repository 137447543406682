import React, { useState } from 'react'
import { removeHttpPrefix } from 'helpers/links'
import CopyIcon from 'assets/icons/CopyIcon'
import './CopyableLink.scss'
import { Tooltip } from '@material-ui/core'

type Props = {
  to: string
}

const CopyableLink: React.FC<Props> = ({ to }) => {
  const beforeCopyText = 'Copy link'
  const afterCopyText = 'Link copied!'
  const [previewCopyText, setPreviewCopyText] = useState<string>(beforeCopyText)

  const fullLink = window.location.origin + to
  const linkToDisplay = removeHttpPrefix(fullLink)

  const onCopyLinkClick = () => {
    setPreviewCopyText(afterCopyText)
    navigator?.clipboard?.writeText(fullLink)
  }

  return (
    <div className="copyable-link">
      <Tooltip title={previewCopyText} placement={'top'}>
        <span onClick={onCopyLinkClick}>{linkToDisplay}</span>
      </Tooltip>
      <Tooltip title={previewCopyText} placement={'top'}>
        <button
          className="copy-to-clipboard-button plain"
          aria-label="Copy link to clipboard"
          onClick={onCopyLinkClick}
        >
          <CopyIcon />
        </button>
      </Tooltip>
    </div>
  )
}

export default CopyableLink
