import React from 'react'
import { AnalyticsPreviewStatus } from 'state/types'
import Dropdown from 'components/shared/Dropdown/Dropdown'
import './AnalyticsPreviewStatusDropdown.scss'

type Props = {
  className?: string
  value?: AnalyticsPreviewStatus
  onChange?: (value: AnalyticsPreviewStatus) => void
}

const storageKey = 'analytics-preview-status'

export const getDefaultPreviewStatus = (): AnalyticsPreviewStatus =>
  (localStorage.getItem(storageKey) as AnalyticsPreviewStatus) || 'all'

const options: { value: AnalyticsPreviewStatus; displayValue: string }[] = [
  { value: 'all', displayValue: 'All Previews' },
  { value: 'published', displayValue: 'Published Previews' },
  { value: 'archived', displayValue: 'Archived Previews' },
  { value: 'draft', displayValue: 'Draft Previews' },
]

const AnalyticsPreviewStatusDropdown: React.FC<Props> = ({ className, value, onChange }) => {
  const onValueChange = (value: any) => {
    localStorage.setItem(storageKey, value)
    onChange?.(value)
  }

  return (
    <div className={className ? ' ' + className : ''}>
      <div className="analytics-preview-status-dropdown-label">Show:</div>
      <Dropdown selectedValue={value} onChange={onValueChange} options={options} />
    </div>
  )
}

export default AnalyticsPreviewStatusDropdown
