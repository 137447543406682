import { useCallback } from 'react'
import * as authService from 'services/authService'
import { setEventTrackingUserId } from 'services/eventTrackingService'
import * as organizationService from 'services/organizationService'
import * as userService from 'services/userService'
import { Plan, User } from 'state/types'
import useStore from 'state/useStore'
import { currentWorkspaceKey, hideSwitchToPersonalPreviewMeDialogKey, workspacesKey } from './useWorkspace'

export const loginPageSuccessMessageKey = 'login-page-success-message'
export const logoutRedirectImminentKey = 'logout-redirect-imminent'
export const logoutRedirectUrl = 'logout-redirect-url'

const useAuth = () => {
  const { state, dispatch } = useStore()

  const onUserLoaded = (user: User) => {
    dispatch({ type: 'SET_USER', value: user })
    dispatch({ type: 'SET_LOGGED_IN', value: true })
    setEventTrackingUserId(user.id)
  }

  const isLoggedIn = () => {
    return state.isLoggedIn
  }

  // Call this on page load to check if the user is logged in, and if so store the access token in memory.
  const loadSession = async () => {
    dispatch({ type: 'SET_LOADING_SESSION', value: true })
    const sessionExists = await authService.loadSession()
    if (sessionExists) {
      try {
        const user = await userService.matchUser()
        if (user) {
          onUserLoaded(user)
        } else {
          authService.logOut()
        }
      } catch (e) {
        // Probably only come here if Cognito user is deleted or something but browser cache has a valid token.
        authService.logOut()
      }
    }

    const redirectUrl = localStorage.getItem(logoutRedirectUrl)
    if (redirectUrl) {
      window.location.href = redirectUrl
      localStorage.removeItem(logoutRedirectUrl)
    }

    dispatch({ type: 'SET_LOADING_SESSION', value: false })
  }

  const onLoginSuccess = async () => {
    try {
      const user = await userService.matchUser()
      if (user) {
        onUserLoaded(user)
      } else {
        authService.logOut()
      }
    } catch {
      authService.logOut()
    }
  }

  const logOut = async (redirectUrl?: string) => {
    dispatch({ type: 'SET_LOGGED_IN', value: false })
    dispatch({ type: 'SET_USER', value: undefined })
    setEventTrackingUserId(undefined)
    if (redirectUrl) {
      localStorage.setItem(logoutRedirectUrl, redirectUrl)
    }

    await authService.logOut()
  }

  const completeUserOnboarding = async (user: Partial<User>) => {
    const ok = await userService.completeUserOnboarding(user)
    if (ok) {
      dispatch({ type: 'UPDATE_USER', value: { ...user, onboarded: true } })
    }
    return ok
  }

  const onboardUserInSessionStorage = async (user: Partial<User>) => {
    dispatch({ type: 'UPDATE_USER', value: { ...user, onboardedUser: true } })
  }

  const addUserToOrganization = async (organizationId: string, user: Partial<User>) => {
    const { ok } = await organizationService.addUserToOrganization(organizationId)
    if (ok) {
      dispatch({ type: 'UPDATE_USER', value: { ...user, onboarded: true } })
    }
    return ok
  }

  const acknowledgeDowngrade = async (user: Partial<User>) => {
    const ok = await userService.acknowledgeDowngrade()
    if (ok) {
      dispatch({ type: 'UPDATE_USER', value: { ...user, downgradeAcknowledged: true } })
    }
    return ok
  }

  const updateUser = async (user: Partial<User>) => {
    if (state.user) {
      const ok = await userService.updateUser({ ...state.user, ...user })
      if (ok) {
        dispatch({ type: 'UPDATE_USER', value: user })
      }
      return ok
    }
    return false
  }

  const removeWorkspaceLocally = () => {
    localStorage.removeItem('organizations')
    localStorage.removeItem('workspaceId')
    localStorage.removeItem(currentWorkspaceKey)
    localStorage.removeItem(workspacesKey)
    localStorage.removeItem(hideSwitchToPersonalPreviewMeDialogKey)
  }

  const changePassword = userService.changePassword

  const deleteAccount = async () => {
    const ok = await userService.deleteAccount(state.user?.id!)
    if (ok) {
      removeWorkspaceLocally()
      localStorage.setItem(
        loginPageSuccessMessageKey,
        "Your account has been deleted. Thanks for using PreviewMe, you're welcome back anytime."
      )
      if (state.user?.socialAuth) {
        localStorage.setItem(logoutRedirectImminentKey, 'true')
      }
      await logOut()
    }
    return ok
  }

  // Used to update loaded user data after successfully changing to a new plan.
  const updateNewUserPlan = (plan: Plan) => {
    dispatch({ type: 'UPDATE_USER', value: { plan, cancelAtPeriodEnd: false, subscriptionStatus: 'active' } })
  }

  return {
    isLoggedIn: isLoggedIn(),
    isLoadingSession: state.isLoadingSession,
    user: state.user,
    subscription: state.subscription,
    loadSession: useCallback(loadSession, []),
    onLoginSuccess,
    logOut,
    completeUserOnboarding,
    addUserToOrganization,
    updateUser,
    changePassword,
    deleteAccount,
    acknowledgeDowngrade,
    updateNewUserPlan: useCallback(updateNewUserPlan, []),
    onboardUserInSessionStorage,
  }
}

export default useAuth
