import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import FormattedText from 'components/shared/FormattedText/FormattedText'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import { event } from 'helpers/analytics'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'
import usePreviewsAlgolia from 'state/usePreviewsAlgolia'
import useSubscription from 'state/useSubscription'
import './Dashboard.scss'
import { PreviewsCreatorFilterOption, WorkspaceType } from '../../../helpers/enum'
import Filter from '../../shared/Filter/Filter'
import ActiveArchivedHeaderDropdown from '../../shared/ActiveArchivedHeaderDropdown/ActiveArchivedHeaderDropdownAlgolia'
import useSelectPreviewsAlgolia from '../../../state/useSelectPreviewsAlgolia'
import SelectAllButtonAlgolia from '../../shared/SelectAllButton/SelectAllButtonAlgolia'
import BulkActionButtonAlgolia from '../../shared/BulkActionButton/BulkActionButtonAlgolia'
import { SearchBox, CountSearchBox } from './SearchBox'
import PreviewTableAlgolia from 'components/preview/PreviewTableAlgolia/PreviewTable'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { Workspace } from 'state/types'
import { algoliaApplicationId } from 'helpers/config'
import algoliasearch from 'algoliasearch'
import { DEFAULT_DASHBOARD_ALGOLIA_PREVIEW_INDEX } from 'helpers/constant'
import useTag from 'state/useTag'
import TagFilter from 'components/shared/TagFilter/TagFilter'
import G2ReviewComponent from 'components/preview/G2ReviewComponent/G2ReviewComponent';
import CircleRecordIcon from '../../../assets/icons/CircleRecordIcon'

type Props = {
  organizationsLoaded: boolean
  currentWorkspace: Workspace
  algoliaSearchApiKey: string
  setIsGrantedPermission: (grantedPermission: boolean) => void
  applyTag: boolean | null
}

const Dashboard: React.FC<Props> = ({
  organizationsLoaded,
  currentWorkspace,
  algoliaSearchApiKey,
  setIsGrantedPermission,
  applyTag,
}) => {
  useSubscription(organizationsLoaded)

  const {
    previews,
    loadingId,
    removingId,
    viewingSelection,
    archivedPreviewsCount,
    activePreviewsCount,
    currentPage,
    creator,
    sortingBy,
    sortingAsc,
    itemCount,
    totalItemPerPage,
    paginationRange,
    searchKey,
    filters,
    sortingIndex,
    algoliaSearchClient,
    activePreviewsCountFilter,
    archivedPreviewsCountFilter,
    cachedUpdatedPreviews,
    cachedHiddenPreviews,
    tagFilter,
    setTagFilter,
    setViewingSelection,
    createPreview,
    archivePreview,
    restorePreview,
    publishPreview,
    unpublishPreview,
    checkCanPublish,
    deletePreview,
    duplicatePreview,
    archiveBulkPreviews,
    deleteBulkPreviews,
    publishBulkPreviews,
    setCurrentPage,
    setSortingAsc,
    setSortingBy,
    setCreator,
    onTotalItemPerPageChange,
    setSearchKey,
    setPreviews,
    setArchivedPreviewsCount,
    setActivePreviewsCount,
    setItemCount,
    setSortingIndex,
    setAlgoliaSearchClient,
    onUpdateTags,
  } = usePreviewsAlgolia(organizationsLoaded, algoliaSearchApiKey)

  const { previewsSelected, selectPreview, bulkSelectPreviews, unselectPreview, clear } = useSelectPreviewsAlgolia({
    viewingSelection,
  })

  const { organizationTags } = useTag(currentWorkspace.id)
  const [isNoRecord, setIsNoRecord] = useState<boolean>(false)

  const [createPreviewDialogData, setCreatePreviewDialogData] = useState<{
    startMessage: string
    templateId: string
  } | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isNoResult, setNoResult] = useState(false)

  const handleCreatePreview = (templateId: string) => {
    ReactGA.event({
      category: event.previewEditor.category,
      action: event.previewEditor.actions.create,
      label: templateId,
    })
    return createPreview(templateId)
  }

  const onSortingByChange = (sortingBy: string) => {
    setSortingBy(sortingBy)
  }

  const onSortingAscChange = (sortingAsc: boolean) => {
    setSortingAsc(sortingAsc)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const renderNoDataView = useCallback(() => {
    const isViewingArchived = viewingSelection === 'archived'
    if ((isViewingArchived && archivedPreviewsCount > 0) || (!isViewingArchived && activePreviewsCount > 0)) return null
    return (
      <div className={'no-previews-container' + (isViewingArchived ? ' archived' : '')}>
        <div className="no-previews-inner-container">
          <div className="no-previews-text">
            {isViewingArchived
              ? 'You have no archived Previews.'
              : archivedPreviewsCount > 0
              ? "Looks like you don't have any Previews. The good news is it's easy to get started!"
              : "Looks like you don't have any Previews yet. The good news is it's easy to get started!"}
          </div>
          {!isViewingArchived && (
            <Link className="caps" to="/create">
              Create a Preview
            </Link>
          )}
        </div>
        {!isViewingArchived && <div className="no-previews-illustration" />}
      </div>
    )
  }, [viewingSelection, archivedPreviewsCount, activePreviewsCount])

  useEffect(() => {
    //reset client when back from other page.
    if (algoliaSearchApiKey) setAlgoliaSearchClient(algoliasearch(algoliaApplicationId, algoliaSearchApiKey))
  }, [algoliaSearchApiKey])

  const handleFetchActivePreviewsCountDone = (previewCount: number) => {
    if (previewCount === 0) setIsNoRecord(true)
    else setIsNoRecord(false)
    setActivePreviewsCount(previewCount)
  }

  return (
    <>
      <PageFrame
        className="dashboard-page"
        header={
          <>
            <ActiveArchivedHeaderDropdown
              selection={viewingSelection}
              activeCount={activePreviewsCount}
              archivedCount={archivedPreviewsCount}
              onSelectionChange={setViewingSelection}
              pageType='preview'
            />
            <div className={'buttons-container'}>
              <button className='create-preview-link button primary'
                      onClick={() => createPreview('4e83d4ff-7c23-403d-b024-dea27fea0caa')}>
                <div className={'add-icon'}>+</div>
                <span>Record</span>
                <div className={'record-icon-dashboard'}><CircleRecordIcon /></div>
              </button>
              <button className='create-preview-mobile-link button primary'
                      onClick={() => createPreview('4e83d4ff-7c23-403d-b024-dea27fea0caa')}>
                <div className={'record-icon-dashboard'}><CircleRecordIcon /></div>
              </button>

              <Link className='view-templates-link' to='/create'>
                <span>View templates</span>
              </Link>
            </div>
          </>
        }
      >
        <div className='g2-review-container'>
          <G2ReviewComponent />
        </div>
        <>
          <div className='sub-header'>
            <div className='filters-wrapper apply-algolia'>
              <div>
                {algoliaSearchClient && (
                  <InstantSearch searchClient={algoliaSearchClient} indexName={sortingIndex}>
                    <Configure hitsPerPage={totalItemPerPage} page={currentPage - 1} filters={filters}></Configure>
                    <SearchBox
                      cachedUpdatedPreviews={cachedUpdatedPreviews}
                      cachedHiddenPreviews={cachedHiddenPreviews}
                      setSearchResults={setPreviews}
                      setHitsCount={null}
                      searchKey={searchKey}
                      setSearchKey={setSearchKey}
                      setItemCount={setItemCount}
                      setCurrentPage={setCurrentPage}
                      setLoading={setIsLoading}
                      setNoResult={setNoResult}
                      setIsGrantedPermission={setIsGrantedPermission}
                    />
                  </InstantSearch>
                )}
                {archivedPreviewsCountFilter && algoliaSearchClient && (
                  <InstantSearch searchClient={algoliaSearchClient} indexName={DEFAULT_DASHBOARD_ALGOLIA_PREVIEW_INDEX}>
                    <Configure filters={archivedPreviewsCountFilter}></Configure>
                    <CountSearchBox
                      setPreviewsCount={setArchivedPreviewsCount}
                      offsetPreviewCount={
                        viewingSelection === 'archived' ? cachedHiddenPreviews.size : -cachedHiddenPreviews.size
                      }
                    />
                  </InstantSearch>
                )}
                {activePreviewsCountFilter && algoliaSearchClient && (
                  <InstantSearch searchClient={algoliaSearchClient} indexName={DEFAULT_DASHBOARD_ALGOLIA_PREVIEW_INDEX}>
                    <Configure filters={activePreviewsCountFilter}></Configure>
                    <CountSearchBox
                      setPreviewsCount={handleFetchActivePreviewsCountDone}
                      offsetPreviewCount={
                        viewingSelection === 'active' ? cachedHiddenPreviews.size : -cachedHiddenPreviews.size
                      }
                    />
                  </InstantSearch>
                )}
              </div>
              {currentWorkspace && currentWorkspace.type === WorkspaceType.Organization && (
                <div className="creator-filter">
                  <Filter
                    filterName={'Creator'}
                    filterOptions={Object.values(PreviewsCreatorFilterOption)}
                    onSelect={setCreator}
                    selectedFilterOption={creator}
                  />
                </div>
              )}
              {currentWorkspace &&
                currentWorkspace.type === WorkspaceType.Organization &&
                applyTag &&
                organizationTags && (
                  <div className="tag-filter">
                    <TagFilter
                      filterOptions={[{ id: 'all', name: 'All' }, ...organizationTags]}
                      onSelect={setTagFilter}
                      selectedFilterOption={tagFilter}
                    />
                  </div>
                )}
              <div className="menu-group">
                <SelectAllButtonAlgolia
                  previews={previews}
                  bulkSelectPreviews={bulkSelectPreviews}
                  viewingSelection={viewingSelection}
                />
                {previewsSelected.length > 0 && (
                  <BulkActionButtonAlgolia
                    selectedPreviews={previewsSelected}
                    viewingSelection={viewingSelection}
                    doBulkArchive={archiveBulkPreviews}
                    doBulkDelete={deleteBulkPreviews}
                    doBulkPublish={publishBulkPreviews}
                    checkCanPublish={checkCanPublish}
                    clearSelectedIds={clear}
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            {isLoading && (
              <div className="no-previews-inner-container spinner">
                <LoadingSpinner />
              </div>
            )}
            {isNoResult && !isNoRecord && <div className="no-previews-inner-container spinner">No results found!</div>}
            {previews.length !== 0 && (
              <div className="preview-thumbnails">
                <PreviewTableAlgolia
                  onUpdateTags={onUpdateTags}
                  tagOptions={organizationTags || []}
                  applyTag={applyTag}
                  previews={previews}
                  sortingBy={sortingBy}
                  sortingAsc={sortingAsc}
                  onSortingByChange={onSortingByChange}
                  onSortingAscChange={onSortingAscChange}
                  previewsSelected={previewsSelected}
                  onArchive={archivePreview}
                  onRestore={restorePreview}
                  onPublish={publishPreview}
                  onUnpublish={unpublishPreview}
                  checkCanPublish={checkCanPublish}
                  onDelete={deletePreview}
                  onDuplicate={duplicatePreview}
                  bulkSelectPreviews={bulkSelectPreviews}
                  onSelect={selectPreview}
                  onUnselect={unselectPreview}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  itemCount={itemCount}
                  onTotalItemPerPageChange={onTotalItemPerPageChange}
                  totalItemPerPage={totalItemPerPage}
                  paginationRange={paginationRange}
                  setSortingIndex={setSortingIndex}
                />
              </div>
            )}
          </div>
          {isNoRecord && renderNoDataView()}
        </>

        <ConfirmationDialog
          className="create-preview-dialog"
          isShowing={!!createPreviewDialogData}
          onClose={() => setCreatePreviewDialogData(null)}
          title="Before we begin"
          cancelButtonText="No thanks"
          okButtonText="Yes, let's get started"
          onOk={() => handleCreatePreview(createPreviewDialogData?.templateId!)}
        >
          {createPreviewDialogData?.startMessage && (
            <FormattedText jsonString={createPreviewDialogData?.startMessage} />
          )}
        </ConfirmationDialog>
      </PageFrame>
    </>
  )
}

export default Dashboard
