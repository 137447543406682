import React from 'react'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import ModalTickIcon from 'assets/icons/ModalTickIcon'
import './SuccessDialog.scss'

type Props = {
  isShowing: boolean
  text: string
  setShowing: (showing: boolean) => void
}

// No options, just shows a tick to confirm that an operation was successful.
const SuccessDialog: React.FC<Props> = ({ isShowing, text, setShowing }) => (
  <ConfirmationDialog
    className="success-dialog"
    isShowing={isShowing}
    okButtonText="Ok, thanks"
    isOkButtonLinkStyle
    onClose={() => setShowing(false)}
  >
    <ModalTickIcon />
    <h2>{text}</h2>
  </ConfirmationDialog>
)

export default SuccessDialog
