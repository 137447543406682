import React from 'react'
import './FormRadioButton.scss'

const defaultOptions = [
  { value: 'Yes', displayValue: 'Yes', description: undefined },
  { value: 'No', displayValue: 'No', description: undefined },
]

type Props = {
  name?: string
  label?: string
  error?: string
  options?: Array<{ value: string; displayValue: string; description?: string }>
  value?: string
  onChange?: (value: string) => void
}

const FormRadioButton: React.FC<Props> = ({ name, value, onChange, label, error, options }) => {
  const groupLabel = 'radio-' + name
  const optionsToUse = options && options.length > 0 ? options : defaultOptions
  return (
    <div className="form-radio-button bordered">
      <div className="label-row">
        <label htmlFor={groupLabel}>{label}</label>
        {error && <div className="validation-error">{error}</div>}
      </div>
      <div className="inputs">
        {optionsToUse.map(option => (
          <label key={option.value}>
            <input type="radio" checked={value === option.value} onChange={() => onChange?.(option.value)} />
            <div className="check" />
            <div className="value">
              <div className="display-value">{option.displayValue}</div>
              {option.description && <div className="description">{option.description}</div>}
            </div>
          </label>
        ))}
      </div>
    </div>
  )
}

export default FormRadioButton
