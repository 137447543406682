import { getWorkspaceOrganizationId } from 'helpers/organization'
import { ContentBlockField } from 'state/types'
import { getApiWithAuthorization } from './api'

export const addContentBlockField = async (
  contentBlockId: string,
  name: string,
  controlType: string,
  categoryId?: string
) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return authApi.post<ContentBlockField>(
    `/api/contentBlock/${contentBlockId}/contentBlockField?name=${encodeURIComponent(
      name
    )}&controlType=${controlType}` +
      (categoryId ? `&categoryId=${encodeURIComponent(categoryId)}` : '') +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const deleteContentBlockField = async (contentBlockFieldId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return authApi.delete<void>(
    `/api/contentBlockField/${contentBlockFieldId}` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const renameContentBlockField = async (contentBlockFieldId: string, name: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return authApi.patch<void>(
    `/api/contentBlockField/${contentBlockFieldId}/rename?name=${name}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const saveContentBlockValues = async (values: Array<{ id: string; value: string }>, previewId: string | undefined) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/contentBlock/saveContentBlockValues` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : `?previewId=${previewId}`),
    { values }
  )
}

export const addContentBlockFieldToList = async (listContentBlockFieldId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return authApi.post<ContentBlockField>(
    `/api/contentBlockField/${listContentBlockFieldId}/addToList` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const deleteContentBlockFieldFromList = async (childContentBlockFieldId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return authApi.delete<void>(
    `/api/contentBlockField/${childContentBlockFieldId}/deleteFromList` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const reorderContentBlockFields = async (fieldIds: string[]) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/contentBlock/reorderFields` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : ''),
    { fieldIds }
  )
}

export const updatePreviewLabel = async (contentBlockId: string, previewLabel: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/contentBlock/${contentBlockId}/updatePreviewLabel?previewLabel=${encodeURIComponent(previewLabel)}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}
