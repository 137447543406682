import { fetchVideoBlob, upload as doCloudinaryUpload } from 'helpers/cloudinary'
import { getWorkspaceOrganizationId } from 'helpers/organization'
import * as videoCache from 'helpers/videoCache'
import {
  CloudinaryUploadResult,
  ContentBlockField,
  PreviewVideoChapters,
  RecordingType,
  VideoChapter,
} from 'state/types'
import { getApi, getApiWithAuthorization } from './api'

export const downloadVideoBlob = async (videoChapter: VideoChapter) => {
  const cachedBlob = videoCache.get(videoChapter.rawVideoStorageId!)
  if (cachedBlob) {
    return cachedBlob
  } else {
    const fetchedBlob = await fetchVideoBlob(videoChapter)
    if (fetchedBlob) {
      videoCache.put(videoChapter.rawVideoStorageId!, fetchedBlob)
    }
    return fetchedBlob
  }
}

export const deleteVideo = async (videoChapterId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return authApi.patch<void>(
    `/api/videoChapter/${videoChapterId}/deleteStorageId` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updateTeleprompterText = async (videoChapterId: string, teleprompterText: string | null) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return authApi.patch<void>(
    `/api/videoChapter/${videoChapterId}/updateTeleprompterText` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : ''),
    { teleprompterText }
  )
}

export const getVideoDuration = async (videoChapterId: string) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return getApi().get<number>(
    `/api/publishedPreview/${videoChapterId}/duration` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const uploadVideo = (
  videoChapterId: string,
  blob: Blob,
  onProgressChange: (progress: number) => void,
  updateVideoChapter: (videoChapter: VideoChapter) => Promise<void>
) => {
  const cloudinaryUploadHandle = doCloudinaryUpload(blob, false, onProgressChange)

  // We want to return a promise that resolves when both the cloudinary upload and database update are done:
  const combinedPromise = new Promise<CloudinaryUploadResult>(async resolve => {
    const { status, storageId } = await cloudinaryUploadHandle.response
    if (status === 'success' && storageId) {
      const { ok, data } = await updateVideoChapterStorageId(videoChapterId, storageId)

      if (ok && data) {
        updateVideoChapter(data)
      }
      resolve({
        status: ok ? 'success' : 'error',
        storageId,
      })
    } else {
      resolve({ status }) // Error or aborted.
    }
  })

  return {
    response: combinedPromise,
    abort: cloudinaryUploadHandle.abort,
  }
}

export const updateVideoChapterStorageId = async (videoChapterId: string, rawVideoStorageId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.patch<VideoChapter>(
    `/api/videoChapter/${videoChapterId}/updateRawStorageId?rawVideoStorageId=${rawVideoStorageId}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getEditedTrimmedMP4 = async (videoChapterId: string, rawVideoStorageId: string | undefined, videoSuiteVideoId: string | undefined, disableModifications: boolean) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const queryString =
    `&workspaceOrganizationId=${disableModifications ? null : workspaceOrganizationId || ''}` +
    `${videoSuiteVideoId ? `&videoSuiteVideoId=${videoSuiteVideoId}` : ''}`;

  return authApi.get<VideoChapter>(
    `/api/videoSuite/videoChapter/${videoChapterId}/getEditedTrimmedMP4?rawVideoStorageId=${rawVideoStorageId}${queryString}`
  )
}

export const trimVideo = async (videoChapterId: string, startOffsetTime: number, endOffsetTime: number) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  const baseUrl = `/api/videoChapter/${videoChapterId}/trim`
  const params =
    `?startOffsetTime=${startOffsetTime}&endOffsetTime=${endOffsetTime}` +
    (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')

  return authApi.patch<VideoChapter>(baseUrl + params)
}

export const removeTrimmedVideo = async (videoChapterId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.patch<VideoChapter>(
    `/api/videoChapter/${videoChapterId}/removeEditedVideo` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}
export const getVideoChapterDetails = async (videoChapterId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.get<VideoChapter>(
    `/api/videoChapter/${videoChapterId}/details` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getAllVideoChapters = async () => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.get<PreviewVideoChapters[]>(
    `/api/me/videoChapters` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const copyVideo = async (destinationChapterId: string, sourceChapterId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.patch<VideoChapter>(
    `/api/videoChapter/${destinationChapterId}/copy?sourceChapterId=${sourceChapterId}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const copyVideoToNewChapter = async (blockId: string, sourceChapterId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.post<ContentBlockField>(
    `/api/videoChapter/copy?contentBlockId=${blockId}&sourceChapterId=${sourceChapterId}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const copyScript = async (destinationChapterId: string, sourceChapterId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.patch<VideoChapter>(
    `/api/videoChapter/${destinationChapterId}/copyScript?sourceChapterId=${sourceChapterId}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const copyScriptToNewChapter = async (blockId: string, sourceChapterId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.post<ContentBlockField>(
    `/api/videoChapter/copyScript?contentBlockId=${blockId}&sourceChapterId=${sourceChapterId}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const checkPermanentlyDeleted = async (chapterId: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  return authApi.get<boolean>(
    `/api/videoChapter/${chapterId}/checkPermanentlyDeleted` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updateVideoChapterType = async (chapterId: string, type?: RecordingType) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  const queryString =
    type && workspaceOrganizationId
      ? `?type=${type}&organizationId=${workspaceOrganizationId}`
      : type
      ? `?type=${type}`
      : workspaceOrganizationId
      ? `?organizationId=${workspaceOrganizationId}`
      : ''

  return authApi.patch<VideoChapter>(`/api/videoChapter/${chapterId}/updateType${queryString}`)
}

export const updateVideoChapterProfileImage = async (chapterId: string, profileImageStorageId?: string) => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()

  const queryString =
    profileImageStorageId && workspaceOrganizationId
      ? `?profileImageStorageId=${profileImageStorageId}&organizationId=${workspaceOrganizationId}`
      : profileImageStorageId
      ? `?profileImageStorageId=${profileImageStorageId}`
      : workspaceOrganizationId
      ? `?organizationId=${workspaceOrganizationId}`
      : ''

  return authApi.patch<VideoChapter>(`/api/videoChapter/${chapterId}/updateProfileImageStorageId${queryString}`)
}

export const downloadChapterContent = async function (videoChapterId: string, watermarkFeatureFlag: boolean, disableModification: boolean) {
  const apiWithAuth = await getApiWithAuthorization();
  const workspaceOrganizationId = getWorkspaceOrganizationId();
  const queryString = workspaceOrganizationId && `&workspaceOrganizationId=${ !disableModification ? workspaceOrganizationId : null}`
  const response = await apiWithAuth.get<string>(
    `/api/videoChapter/${videoChapterId}/getDownloadUrl?watermarkFeatureFlag=${watermarkFeatureFlag}${queryString}`
  );

  if (response.data) {
    const link = document.createElement('a');
    link.href = response.data;
    link.click();
  }
};
