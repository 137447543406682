import { IntegrationType } from './enum'
import hub_spot_icon from 'assets/hub_spot_icon.png'
import gmail_icon from 'assets/gmail_icon.png'
import google_analytics_icon from 'assets/google_analytics_icon.png'
import linkedin_icon from 'assets/linkedin_icon.png'
import outlook_icon from 'assets/outlook_icon.png'
import salesloft_icon from 'assets/salesloft_icon.png'
import meta_icon from 'assets/meta_icon.png'

export const getIntegrationDocumentationLink = (integrationType: IntegrationType) => {
  return 'https://previewme.com/frequently-asked-questions?tab=integrations'
}

export const integrationPlatform = [
  // {
  //   type: IntegrationType.GMAIL,
  //   icon: gmail_icon,
  //   name: 'Gmail',
  //   link: 'https://previewme.com/frequently-asked-questions?tab=integrations',
  // },
  {
    type: IntegrationType.GOOGLE_ANALYTIC,
    icon: google_analytics_icon,
    name: 'Google Analytics',
    link: 'https://previewme.com/frequently-asked-questions?tab=integrations',
  },
  {
    type: IntegrationType.HUBSPOT,
    icon: hub_spot_icon,
    name: 'HubSpot',
    link: 'https://previewme.com/frequently-asked-questions?tab=integrations',
  },
  // {
  //   type: IntegrationType.LINKEDIN,
  //   icon: linkedin_icon,
  //   name: 'LinkedIn',
  //   link: 'https://previewme.com/frequently-asked-questions?tab=integrations',
  // },
  // {
  //   type: IntegrationType.OUTLOOK,
  //   icon: outlook_icon,
  //   name: 'Outlook',
  //   link: 'https://previewme.com/frequently-asked-questions?tab=integrations',
  // },
  // {
  //   type: IntegrationType.SALESLOFT,
  //   icon: salesloft_icon,
  //   name: 'Salesloft',
  //   link: 'https://previewme.com/frequently-asked-questions?tab=integrations',
  // },
  {
    type: IntegrationType.META_PIXEL,
    icon: meta_icon,
    name: 'Meta Pixel',
    link: 'https://previewme.com/frequently-asked-questions?tab=integrations',
  },
]

export const getDefaultIntegrationName = (integrationType: IntegrationType) => {
  return integrationPlatform.find(platform => platform.type === integrationType)?.name || ''
}

export const getIntegrationIcon = (integrationType: IntegrationType) => {
  return integrationPlatform.find(platform => platform.type === integrationType)?.icon || ''
}
