import React from 'react'

const ViewPreviewArrowIcon = () => (
  <svg className="icon" width="8px" height="8px" viewBox="0 0 8 8">
    <g strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g transform="translate(-1466.000000, -37.000000)" strokeWidth="1.5">
        <g transform="translate(1369.000000, 28.000000)">
          <g transform="translate(98.000000, 10.000000)">
            <polyline
              strokeLinejoin="round"
              transform="translate(3.000000, 3.000000) rotate(-90.000000) translate(-3.000000, -3.000000) "
              points="6 0 6 6 0 6"
            ></polyline>
            <path d="M6,0 L0,6"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ViewPreviewArrowIcon
