import { User } from 'state/types'
import { currentWorkspaceKey } from 'state/useWorkspace'
import { SubscriptionPlan, WorkspaceType } from './enum'

export const getCurrentPaymentPlan = (user: User) => {
  return user.plan
}

export const isSubscriptionStatusActiveOrOnFree = (user: User) => {
  return user.subscriptionStatus === 'active' || !user.subscriptionStatus
}

export const getCurrentPaymentPlanTitle = (user: User) => {
  const plan = getCurrentPaymentPlan(user)
  return plan === SubscriptionPlan.Professional ? 'Professional' : plan === SubscriptionPlan.Basic ? 'Basic' : 'Free'
}

export const isOnPaymentPlan = (user: User) => {
  return getCurrentPaymentPlan(user) !== undefined
}

export const isOnPaymentPlanOrAdmin = (user: User) => {
  return isOnPaymentPlan(user) || user.admin
}

export const isOnProfessionalBasic = (user: User) => {
  return isOnPaymentPlan(user) && getCurrentPaymentPlan(user) === SubscriptionPlan.Basic
}

export const isAdmin = (user: User) => {
  return user.admin
}

export const getFullName = (user?: User) => {
  if (user) {
    const { familyName, givenName } = user
    return familyName && givenName ? givenName + ' ' + familyName : givenName
  }
}

export const getWorkspaceUser = () => {
  const currentWorkspace = localStorage.getItem(currentWorkspaceKey)
  if (currentWorkspace && JSON.parse(currentWorkspace).type === WorkspaceType.User) {
    return JSON.parse(currentWorkspace) as User
  }
}
