import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Route, useLocation } from 'react-router-dom'
import { googleAnalyticsId } from 'helpers/config'

let isInitialized = false

const GoogleAnalyticsPageTracker = () => {
  const location = useLocation()

  useEffect(() => {
    const page = location.pathname + location.search
    const origin = window.location.origin
    ReactGA.set({ page, location: origin + page })
    ReactGA.pageview(page)
  }, [location])

  return null
}

const RouteTracker = () => <Route component={GoogleAnalyticsPageTracker} />

export const initGA = (gaOptions?: ReactGA.GaOptions) => {
  if (!isInitialized) {
    ReactGA.initialize([{ trackingId: googleAnalyticsId, gaOptions }])
    isInitialized = true
  }
  return true
}

export default RouteTracker
