import React from 'react'
import isPast from 'date-fns/isPast'
import differenceInDays from 'date-fns/differenceInDays'
import { parseAvailabilityString } from 'helpers/validation'
import { formatDate } from 'helpers/date'

type Props = {
  value?: string
  highlight?: boolean // True for the availability value showing in the highlights banner.
}

const PublishedPreviewAvailability: React.FC<Props> = ({ value, highlight }) => {
  const { selection, date, notes } = parseAvailabilityString(value)

  let formattedValue = ''
  if (selection === 'immediately') {
    formattedValue = highlight ? 'Available now' : "I'm available now."
  } else if (selection === 'date' && date) {
    const startOfDay = new Date()
    startOfDay.setHours(0, 0, 0, 0)
    const daysInFuture = differenceInDays(date, startOfDay)

    if (isPast(date) || daysInFuture <= 1) {
      formattedValue = "I'm available now."
    } else {
      formattedValue = formatDate(date.toString())
    }
  }

  return formattedValue ? (
    <>
      <div className="value">{formattedValue}</div>
      {!!notes?.trim() && !highlight && (
        <>
          <div className="label">Notes</div>
          <div className="text-area">{notes}</div>
        </>
      )}
    </>
  ) : !!notes?.trim() && !highlight ? (
    <div className="text-area">{notes}</div>
  ) : null
}

export default PublishedPreviewAvailability
