import React, { useState } from 'react'
import './PercentageInput.scss'
import Instructions from '../Instructions/Instructions'

type Props = {
  formLabel?: string
  value?: string
  instructions?: string | undefined
  onChange?: (value?: string) => void
}

/**
 * A field dedicated to percentage inputs. This is currently a basic field but if we need
 * to add any type of percentage specific transformation/validation to the input it can be done here
 * @param formLabel
 * @param value
 * @param instructions
 * @param onChange
 * @constructor
 */
const PercentageInput: React.FC<Props> = ({ formLabel, value, instructions, onChange }) => {
  const [numericalValue, setNumericalValue] = useState<number | undefined>(value ? +value : undefined)

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericalValue: number = parseFloat(e.target.value)
    setNumericalValue(numericalValue)

    // Push the change to the database
    notifyChange(numericalValue)
  }

  const notifyChange = (v: number) => onChange?.(JSON.stringify(v))

  return (
    <div className="form-input-container">
      <div className="top-label-row">
        <label>{formLabel}</label>
        {instructions && <Instructions instructions={instructions} />}
      </div>
      <div className="inputs">
        <div className="numerical-input">
          <input type="number" pattern="[0-9]*" value={numericalValue} onChange={handleValueChange} />
          <label className="units">%</label>
        </div>
      </div>
    </div>
  )
}

export default PercentageInput
