import React from 'react'

const InvitationsIcon = () => (
  <svg className="icon" width="24px" height="18px" viewBox="0 0 24 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-31.000000, -228.000000)">
        <rect x="0" y="0" width="290" height="1000"></rect>
        <path
          d="M53.43,244.041 L32.556,244.041 C32.36,244.041 32.2,243.882 32.2,243.686 L32.2,230.381 L42.598,239.606 C42.712,239.708 42.854,239.758 42.996,239.758 C43.139,239.758 43.281,239.707 43.395,239.606 L53.785,230.38 L53.785,243.686 C53.785,243.882 53.626,244.041 53.43,244.041 L53.43,244.041 Z M53.307,229.2 L42.996,238.355 L32.678,229.2 L53.307,229.2 Z M53.43,228 L32.556,228 C31.698,228 31,228.698 31,229.556 L31,243.686 C31,244.543 31.698,245.241 32.556,245.241 L53.43,245.241 C54.288,245.241 54.986,244.543 54.986,243.686 L54.986,229.556 C54.986,228.698 54.288,228 53.43,228 L53.43,228 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </svg>
)

export default InvitationsIcon
