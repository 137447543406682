import DeleteAccountDialog from 'components/account/DeleteAccountDialog/DeleteAccountDialog'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { formatDate } from 'helpers/date'
import { BillingType, SubscriptionPlan, WorkspaceType } from 'helpers/enum'
import { isAdmin } from 'helpers/user'
import { capitalize } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useAuth from 'state/useAuth'
import useSubscription from 'state/useSubscription'
import './AccountBillingForm.scss'
import useWorkspace from '../../../state/useWorkspace'
import { Price } from '../../../state/types'
import useProduct from '../../../state/useProduct'
import useStore from '../../../state/useStore'
import { getCurrentWorkspace } from '../../../helpers/organization'

const formatPlanDowngraded = (newProductName: string | undefined, nextPaymentDate: string) => (
  <>
    Your Plan will be downgraded to our {newProductName} Plan on {formatDate(nextPaymentDate)}.
  </>
)

type Props = {
  organizationSettings?: boolean
}

const AccountBillingForm: React.FC<Props> = ({ organizationSettings }) => {
  const [isDeleteAccountDialogShowing, setDeleteAccountDialogShowing] = useState(false)
  const currentWorkspace = getCurrentWorkspace()
  const { allProducts } = useProduct(true)
  const {
    isSubscriptionLoading,
    subscription,
    subscriptionError,
    paymentDetails,
    isPaymentDetailsLoading,
    newProduct,
    isRedirecting,
    updatePaymentMethod,
    editedSubscriptionName,
    editedNextSubscriptionName
  } = useSubscription(true, false, undefined, true, allProducts)
  const { user } = useAuth()
  const hasMatchingId = allProducts && allProducts.some(product => product.id === subscription?.plan.id);

  return (
    <div className="account-billing-form">
      {isSubscriptionLoading || isPaymentDetailsLoading ? (
        <LoadingSpinner container />
      ) : (
        <>
          {!isAdmin(user!) && (
            <>
              <div className="form-section-header">Plan</div>
              {subscriptionError ? (
                <div className="subscription-error">Unable to load plan data at this time.</div>
              ) : (
                <>
                  <div className="plan-name">{subscription?.plan ? editedSubscriptionName() : ''}</div>
                  {subscription && newProduct && subscription.nextBillingStartPeriod ? (
                    <>
                      <div className="plan-cancelled">
                        {formatPlanDowngraded(editedNextSubscriptionName(0), subscription.nextBillingStartPeriod)}
                      </div>
                      {hasMatchingId ?
                        <Link
                          to={`/plan/change?to=${subscription.plan.id}&from=${encodeURIComponent(
                            window.location.pathname + window.location.search)}`}
                          className="button view-plan-button primary"
                        >
                          Reactivate my plan
                        </Link> :
                        <button
                          className="button view-plan-button primary disabled"
                          disabled
                        >
                          Reactivate my plan
                        </button>
                      }

                    </>
                  ) : (
                    currentWorkspace?.type !== WorkspaceType.User && <Link
                      to={`/plan/select?from=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                      className="button view-plan-button primary"
                    >
                      View/change plan
                    </Link>
                  )}
                </>
              )}
              <>
                {paymentDetails && (
                  <>
                    <div className="form-section-header">Payment Method</div>

                    <div className="credit-card-line">
                      {paymentDetails.brand
                        ? `${capitalize(paymentDetails.brand)} ending in ${paymentDetails.last4 || '??'}`
                        : 'No payment method recorded'}
                    </div>
                    {paymentDetails.expiryYear !== undefined && paymentDetails.expiryYear !== undefined && (
                      <div className="credit-card-line">
                        Expires {paymentDetails.expiryMonth} / {paymentDetails.expiryYear}
                      </div>
                    )}
                    <div className="edit-card-details-button-row">
                      <button
                        className="edit-card-details-button link plain"
                        onClick={() => updatePaymentMethod(window.location.pathname)}
                      >
                        Edit card details
                      </button>
                      {isRedirecting && <LoadingSpinner />}
                    </div>
                  </>
                )}
              </>
            </>
          )}
          {!organizationSettings && (
            <>
              <div className="form-section-header">Danger zone</div>
              <div className="close-account-text">Close your account and delete all your Previews.</div>
              <button className="delete-account-button primary" onClick={() => setDeleteAccountDialogShowing(true)}>
                Delete account
              </button>
              <DeleteAccountDialog
                isShowing={isDeleteAccountDialogShowing}
                setShowing={setDeleteAccountDialogShowing}
                planName={
                  subscription?.plan && subscription?.plan.name !== SubscriptionPlan.Basic
                    ? capitalize(subscription.plan.name)
                    : undefined
                }
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default AccountBillingForm
