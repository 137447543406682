import React from 'react'

const HandDrawnArrowIcon = () => (
  <svg className="icon" width="47px" height="197px" viewBox="0 0 47 197">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path
        d="M2.89,2.78 C22.17,13.64 35.28,33.66 40.79,55.09 C46.3,76.52 44.79,99.27 39.85,120.84 C34.1492882,145.712262 24.0396584,169.36236 10,190.67"
        stroke="#01A3F8"
        strokeWidth="4"
      ></path>
      <path
        d="M10.53,151.27 C9.64,170.78 6.89,180.51 6.86,194.21 C18.6643803,191.133953 30.1417108,186.917449 41.13,181.62"
        stroke="#01A3F8"
        strokeWidth="4"
      ></path>
    </g>
  </svg>
)

export default HandDrawnArrowIcon
