import React from 'react'
import { formatChapterLabel } from 'helpers/media'
import FormattedText from 'components/shared/FormattedText/FormattedText'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import './VideoHintsDialog.scss'

type Props = {
  isShowing: boolean
  chapterIndex: number
  chapterName: string
  hintsText?: string
  instructions?: string
  onClose: () => void
}

const VideoHintsDialog: React.FC<Props> = ({
  isShowing,
  chapterIndex,
  chapterName,
  hintsText,
  instructions,
  onClose,
}) => (
  <CloseableDialog className="video-hints-dialog" isShowing={isShowing} onClose={onClose}>
    <div className={'instructions'}>
      <div className="chapter-label">{formatChapterLabel(chapterIndex)}</div>
      <h2>{chapterName}</h2>
      {hintsText && <FormattedText jsonString={hintsText} />}
      {instructions && <p>{instructions}</p>}
    </div>
  </CloseableDialog>
)

export default VideoHintsDialog
