import React from 'react'
import Dropdown from 'components/shared/Dropdown/Dropdown'
import './ActiveArchivedHeaderDropdown.scss'
import { ViewingSelection } from '../../../state/types'

type Props = {
  selection: ViewingSelection
  activeCount: number
  archivedCount: number
  onSelectionChange: (selection: ViewingSelection) => void
  pageType: 'preview' | 'invitation'
}

/**
 * This component is used in two places.
 * Dashboard.tsx
 * InvitationsPage.tsx
 * This component is used to set the viewingSelection from active previews/invitations to archived and vice versa.
 * @param selection
 * @param activeCount
 * @param archivedCount
 * @param onSelectionChange
 * @param pageType
 * @constructor
 */

const ActiveArchivedHeaderDropdown: React.FC<Props> = ({
  selection,
  activeCount,
  archivedCount,
  onSelectionChange,
  pageType,
}) => {
  const displayName = pageType === 'preview' ? 'Previews' : 'Invitations'
  const options = [
    { value: 'active', displayValue: `My ${displayName}` },
    { value: 'archived', displayValue: `Archived ${displayName}` },
  ]

  const onChange = (value: string) => onSelectionChange(value === 'archived' ? 'archived' : 'active')
  return (
    <Dropdown
      id="active-archived-header-dropdown"
      className="active-archived-header-dropdown"
      selectedValue={selection}
      options={options}
      onChange={onChange}
      renderOption={value =>
        value === 'active' ? `My ${displayName} (${activeCount})` : `Archived (${archivedCount})`
      }
    />
  )
}

export default ActiveArchivedHeaderDropdown
