import React, { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import usePreview from 'state/usePreview'
import PreviewEditorBottomNav from 'components/preview/PreviewEditorBottomNav/PreviewEditorBottomNav'
import FormInput from 'components/shared/FormInput/FormInput'
import SuccessDialog from 'components/shared/SuccessDialog/SuccessDialog'
import './PreviewTitleEditor.scss'

type Props = {
  previewId: string
  isPreviewPublished: boolean
  isValidationActive?: boolean
  nextLink?: string
}

const PreviewTitleEditor: React.FC<Props> = ({ previewId, isPreviewPublished, isValidationActive, nextLink }) => {
  const { preview, isUpdatingName, updateName } = usePreview(previewId)
  const [isSaveConfirmationDialogShowing, setSaveConfirmationDialogShowing] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const initialValue = preview?.name || ''
  const [title, setTitle] = useState(initialValue)
  const dirty = title !== initialValue
  const errors = (isValidationActive || submitAttempted) &&
    !title.trim() && { title: { message: 'Please enter a title' } }

  const onTitleChange = (title: string) => {
    setTitle(title)
    checkToSaveDebounced(title)
  }

  const checkToSave = (title: string) => {
    if (!isPreviewPublished) {
      updateName(title, true)
    }
  }

  const checkToSaveCallback = useCallback(checkToSave, [])
  const checkToSaveDebounced = useCallback(debounce(checkToSaveCallback, 1000), [checkToSaveCallback])

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isPreviewPublished && dirty) {
      if (title.trim()) {
        const ok = await updateName(title)
        if (ok) {
          setSaveConfirmationDialogShowing(true)
        }
      } else {
        setSubmitAttempted(true)
      }
    }
  }

  return (
    <form className="form-block-editor" noValidate onSubmit={onSubmit}>
      <div className="form-fields">
        <FormInput
          name="title"
          label="Preview title*"
          placeholder="Name your preview"
          value={title}
          errors={errors}
          onChange={e => onTitleChange(e.target.value)}
          maxLength={75}
        />
      </div>
      <PreviewEditorBottomNav
        nextLink={nextLink}
        showSaveButton={isPreviewPublished}
        isSaveButtonDisabled={!dirty}
        isSaving={isUpdatingName}
      />
      <SuccessDialog
        isShowing={isSaveConfirmationDialogShowing}
        text="Preview updated"
        setShowing={setSaveConfirmationDialogShowing}
      />
    </form>
  )
}

export default PreviewTitleEditor
