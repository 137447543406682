import React, { useEffect, useState } from 'react'
import { ContentBlockField, Option } from 'state/types'
import './FormConditionalGroupSelect.scss'
import FormFieldEditor from '../../preview/FormFieldEditor/FormFieldEditor'
import DropdownFreeText from '../DropdownFreeText/DropdownFreeText'
import { parseDropdownFreeText } from '../../../helpers/validation'

type Props = {
  hideNoSelection?: boolean
  label?: string
  error?: string
  placeholder?: string
  errors?: any
  disabled?: boolean
  defaultValue?: string
  onChange?: (value?: string) => void
  options: Array<Option>
  field: ContentBlockField
  previewId: string
  blockIndex: number
  register: any
  control: any
  removeFromList?: () => void
} & React.SelectHTMLAttributes<HTMLSelectElement>

/**
 * This field allows us to display a field group based on a users' selection.
 * Currently this field shows all group fields. Once the filter/tagging functionality
 * has been created in the back-end we need to add the filter functionality to this field.
 *
 * @ToDo: Add filter functionality to field
 */
const FormConditionalGroupSelect: React.FC<Props> = ({
                                                       hideNoSelection,
                                                       name,
                                                       value,
                                                       label,
                                                       error,
                                                       errors,
                                                       placeholder,
                                                       disabled,
                                                       defaultValue,
                                                       onChange,
                                                       options,
                                                       field,
                                                       previewId,
                                                       blockIndex,
                                                       register,
                                                       control,
                                                       removeFromList,
                                                       ...rest
                                                     }) => {


  const [activeTagFilter, setActiveTagFilter] = useState<Array<string>>(value ? [value.toString()] : [])

  useEffect(() => {
    // Ensure we show/hide the active field group based on the users selection
    if (value) {
      const activeTagFilterValue = parseDropdownFreeText(String(value)).dropdown
      if (!activeTagFilter.includes(activeTagFilterValue)) {
        setActiveTagFilter([activeTagFilterValue])
      }
    } else {
      setActiveTagFilter([])
    }
  }, [value])

  const renderFilteredChildren = () => {
    return (
      activeTagFilter.length > 0 &&
      field.children?.map(child => (
        <FormFieldEditor
          key={child.id}
          previewId={previewId}
          blockIndex={blockIndex}
          field={child}
          register={register}
          control={control}
          errors={errors}
          tagFilter={activeTagFilter}
        />
      ))
    )
  }

  return (
    <>
      <DropdownFreeText label={label}
                        options={options}
                        value={String(value)}
                        onChange={onChange}
                        otherFreeText
                        placeholder={field.placeholder}
                        instructions={field.instructions}
      />
      {renderFilteredChildren()}
    </>
  )
}

export default FormConditionalGroupSelect
