import ExamplePage from 'components/preview/ExamplePage/ExamplePage'
import { PreviewCategory, PreviewMeAction } from 'helpers/enum'
import { isOnPaymentPlanOrAdmin } from 'helpers/subscription'
import { useUrlParams } from 'helpers/urlParams'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as previewService from 'services/previewService'
import useAuth from 'state/useAuth'
import usePublishedPreview from '../../../state/usePublishedPreview'
import useBrandTheme from '../../../state/useBrandTheme'

const TemplateExamplePage = () => {
  const { push } = useHistory()
  const { user, subscription } = useAuth()
  const { category } = useUrlParams('category')
  const {
    updateThemeByID
  } = usePublishedPreview()
  const {
    planType,
    brandModeData,
  } = useBrandTheme('main');

  const createPreview = async (
    id: string,
    isBusinessCountAtMaxForFree: boolean,
    isIndividualCountAtMaxForFree: boolean
  ) => {
    if (
      !isOnPaymentPlanOrAdmin(subscription, user!) &&
      isBusinessCountAtMaxForFree &&
      category === PreviewCategory.Business
    ) {
      push(`/limitReached?templateId=${id}&category=${category}&action=${PreviewMeAction.Create}`)
    } else if (
      !isOnPaymentPlanOrAdmin(subscription, user!) &&
      isIndividualCountAtMaxForFree &&
      category === PreviewCategory.Individual
    ) {
      push(`/limitReached?templateId=${id}&category=${category}&action=${PreviewMeAction.Create}`)
    } else {
      const { ok, data } = await previewService.createPreview(id)
      if (ok && data) {
        if(brandModeData !== undefined) {
          if(Object.keys(brandModeData).length !== 0 && (planType === 'Enterprise' || planType === 'Professional' || planType === 'Business')) {
            updateThemeByID('custom-workspace-theme',data)
          }
          else {
            updateThemeByID('white',data)
          }
        }
        push(`/edit/${data}`)
      }
    }
  }

  return (
    <ExamplePage
      cancelLink="/"
      prevLink="/create"
      createPreviewPage={createPreview}
      isInvitation={false}
      titleText="This is an example of what you can create"
    />
  )
}

export default TemplateExamplePage
