import * as React from 'react'

const ContactUsIcon = () => (
  <svg className="icon" viewBox="0 0 25.58 22.59" width="25.58" height="22.59">
    <g>
      <path d="M6.6 7.35h12.39v1.3H6.6zM6.6 11.17h12.39v1.3H6.6z" />
      <path d="M18.12 22.35a1.5 1.5 0 01-1-.36L13.64 19H3.72a1.57 1.57 0 01-1.57-1.57V2.35A1.58 1.58 0 013.72.78h18.16a1.57 1.57 0 011.57 1.57v15.12A1.57 1.57 0 0121.88 19h-2.23v1.78a1.54 1.54 0 01-1.53 1.53zM3.72 2.08a.27.27 0 00-.27.27v15.12a.27.27 0 00.27.27h10.4L18 21a.24.24 0 00.24 0 .23.23 0 00.13-.21v-3.05h3.53a.27.27 0 00.27-.27V2.35a.27.27 0 00-.27-.27z" />
    </g>
  </svg>
)

export default ContactUsIcon
