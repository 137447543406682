import * as React from 'react'

const ModalTickIcon = () => (
  <svg className="icon" width="26" height="26" viewBox="0 0 26 26">
    <g strokeWidth={2} fill="none" fillRule="evenodd">
      <circle cx={13} cy={13} r={12} />
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 13.953l3.676 3.422L17.52 8" />
    </g>
  </svg>
)

export default ModalTickIcon
