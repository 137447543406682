import React, { useState, useEffect, useRef } from 'react'
import "./BrandingSideBar.scss";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LogoUploader from "./../LogoUploader/LogoUploader";
import FontPicker from "./../FontPicker/FontPicker";
import ColorPicker from "./../ColorPicker/ColorPicker";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import useWorkspace from '../../../../state/useWorkspace'
import { makeStyles } from '@material-ui/core/styles'
import { SubscriptionPlan } from '../../../../helpers/enum'

type Props = {
  header: (color: string, type: string) => void;
  upper: (color: string, type: string) => void;
  lower: (color: string, type: string) => void;
  button: (color: string, type: string) => void;
  heading1: (color: string, font: string, type: string, displayName:string, variant:number) => void;
  heading2: (color: string, font: string, type: string, displayName:string, variant:number) => void;
  heading3: (color: string, font: string, type: string, displayName:string, variant:number) => void;
  body: (color: string, font: string, type: string, displayName:string, variant:number) => void;
  link: (color: string, font: string, type: string, displayName:string, variant:number) => void;
  button02: (color: string, font: string, type: string, displayName:string, variant:number) => void;
  borderRadius: (data: number) => Promise<void>;
  pageTitle:(data: string) => Promise<void>
  data: BrandData;

  planType:string;
};

interface BrandData {
  headerColor: string;
  upperColor: string;
  lowerColor: string;
  buttonColor: string;
  heading1: HeadingData;
  heading2: HeadingData;
  heading3: HeadingData;
  body: HeadingData;
  link: HeadingData;
  button02: HeadingData;
  borderRadius: number;
  pageTitle: string;
}

interface HeadingData {
  color: string;
  font: string;
  displayName:string;
  variant:number
}


const BrandingSideBar: React.FC<Props> = ({ header, upper, lower, button, heading1, heading2, heading3, body, link, button02, borderRadius, data,planType, pageTitle }) => {
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
  const [subscriptionPlanDisable, setSubscriptionPlanDisable] = useState(false);
  const [subscriptionPlanDisableSwitch, setSubscriptionPlanDisableSwitch] = useState(false);
  const { currentWorkspace, organizationsLoaded } = useWorkspace(true)
  const [scrollPositionY, setScrollPositionY] = useState(0)
  const [isScrollable, setIsScrollable] = useState(false)
  const useStyles = makeStyles({
    root: {
      '--custom-scroll-translate':  `${scrollPositionY ?? 0}px`,
    },
  })

  const classes = useStyles(scrollPositionY)

  useEffect(() => {
    const fetchBrandTheme = async () => {
      try {
        const isDisabledThree = (planType === 'Solo' || planType === 'Basic' || planType === 'Professional' || planType === 'Business');
        const isDisabledTwo = (planType === 'Solo' || planType === 'Basic');
        setSubscriptionPlanDisable(isDisabledTwo);
        setSubscriptionPlanDisableSwitch(isDisabledThree);

      } catch (error) {
        console.error("Error in fetching and processing brand theme:", error);
      }
    };

    fetchBrandTheme();
  }, [planType, currentWorkspace]);


  const handleAccordionToggle = (panel: string) => () => {
    if(panel === "fonts" || panel === "branding"){
      setIsScrollable(true)
    }else{
      setIsScrollable(false)
    }
    setExpandedPanel((prevExpandedPanel) =>
      prevExpandedPanel === panel ? false : panel
    );
  };

  const handleTextChange = (color: string, font: string, type: string, displayName:string, variant:number) => {
    switch (type) {
      case "heading_1":
        heading1(color, font, type,displayName,variant);
        break;
      case "heading_2":
        heading2(color, font, type,displayName,variant);
        break;
      case "heading_3":
        heading3(color, font, type,displayName,variant);
        break;
      case "body_copy_text":
        body(color, font, type,displayName,variant);
        break;
      case "link_text":
        link(color, font, type,displayName,variant);
        break;
      case "button_text":
        button02(color, font, type,displayName,variant);
        break;
      default:
        break;
    }
  };

  const handleBackgroundColor = (color: string, type: string) => {
    switch (type) {
      case "header_bg_color":
        header(color, type);
        break;
      case "upper_bg_color":
        upper(color, type);
        break;
      case "lower_bg_color":
        lower(color, type);
        break;
      case "button_bg_color":
        button(color, type);
        break;
      default:
        break;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value }= event.target;
    const inputValue = value.replace(/-/g, "");

    // Check if the input value is a valid number
    const numericValue = Number(inputValue);
    if (!isNaN(numericValue)) {
      borderRadius(numericValue);
    }
  };

  const handlePageTitle = (title: string) => {
    pageTitle(title)
  }


  const container = document.querySelector(".sidebar-container .MuiDrawer-paperAnchorRight")

  useEffect(() => {
    if(!container) return
    const handleMouseEnter = () => {
      if(isScrollable) {
        container.classList.add("hovered");
      }else if(!isScrollable){
        container.classList.add("hovered-no-scroll")
      }
    };

    const handleMouseLeave = () => {
      container.classList.remove("hovered");
      container.classList.remove("hovered-no-scroll")
    };

    const elementsToObserve1 = [
      "#branding-content .logo-uploader-settings",
      ".item-container-hover",
    ];
    const elementsToObserve2 = [
      ".info-icon",
      ".hide-brand-settings .hint-popup-container"
    ];

    const addEventOnElements = (elementsToObserve: string[] ) => {
      elementsToObserve.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          element.addEventListener("mouseenter", handleMouseEnter);
          element.addEventListener("mouseleave", handleMouseLeave);
        });
      });
    }

    const removeEventOnElements = (elementsToObserve: string[] ) => {
      elementsToObserve.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          element.removeEventListener("mouseenter", handleMouseEnter);
          element.removeEventListener("mouseleave", handleMouseLeave);
        });
      });
    }

    if(planType === SubscriptionPlan.Basic || planType === SubscriptionPlan.Solo){
        addEventOnElements(elementsToObserve1)
    }
    else if (planType === SubscriptionPlan.Professional || planType === SubscriptionPlan.Business){
        addEventOnElements(elementsToObserve2)
    }
    else{
      addEventOnElements(elementsToObserve2.slice(0,1))
    }

    return () => {
      if(planType === SubscriptionPlan.Basic || planType === SubscriptionPlan.Solo){
        removeEventOnElements(elementsToObserve1)
      }
      else if (planType === SubscriptionPlan.Professional || planType === SubscriptionPlan.Business){
        removeEventOnElements(elementsToObserve2)
      }
      else{
        const elementToObserve = [".info-icon"]
        removeEventOnElements(elementsToObserve2.slice(0,1))
      }
    };
  }, [planType, currentWorkspace, isScrollable, container]);

  const handleScroll = () => {
    if(container){
      setScrollPositionY(-container.scrollTop)
    }
  }

  return (
    <>
      <div className="brand-settings-sidebar">
        <Drawer
          variant="permanent"
          className={`sidebar-container ${classes.root}`}
          anchor="right"
          onScroll={handleScroll}
        >
          <Accordion
            className="accordion-container"
            expanded={expandedPanel === "branding"}
            onChange={handleAccordionToggle("branding")}
          >
            <AccordionSummary
              className="accordion-summary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="branding-content"
              id="branding-header"
            >
              <Typography>Branding</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordition-details">
              <LogoUploader
                plan={subscriptionPlanDisable}
                planSwitchDisable={subscriptionPlanDisableSwitch}
                onSubmit={handlePageTitle}/>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="accordion-container"
            expanded={expandedPanel === "background"}
            onChange={handleAccordionToggle("background")}
          >
            <AccordionSummary
              className="accordion-summary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="background-content"
              id="background-header"
            >
              <Typography>Background & Button Colours</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordition-details">
              <ColorPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.headerColor}
                label="Header Strip Background"
                type="header_bg_color"
                onColorBgChange={handleBackgroundColor}
              />
              <ColorPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.upperColor}
                label="Upper Section Background"
                type="upper_bg_color"
                onColorBgChange={handleBackgroundColor}
              />
              <ColorPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.lowerColor}
                label="Lower Section Background"
                type="lower_bg_color"
                onColorBgChange={handleBackgroundColor}
              />
              <ColorPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.buttonColor}
                label="Button Colour"
                type="button_bg_color"
                onColorBgChange={handleBackgroundColor}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="accordion-container"
            expanded={expandedPanel === "fonts"}
            onChange={handleAccordionToggle("fonts")}
          >
            <AccordionSummary
              className="accordion-summary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="fonts-content"
              id="fonts-header"
            >
              <Typography>Fonts</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordition-details">
              <FontPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.heading1}
                label="Heading 1"
                type="heading_1"
                onFontChange={handleTextChange}
              />
              <FontPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.heading2}
                label="Heading 2"
                type="heading_2"
                onFontChange={handleTextChange}
              />
              <FontPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.heading3}
                label="Heading 3"
                type="heading_3"
                onFontChange={handleTextChange}
              />
              <FontPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.body}
                label="Body Copy Text"
                type="body_copy_text"
                onFontChange={handleTextChange}
              />
              <FontPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.link}
                label="Link Text"
                type="link_text"
                onFontChange={handleTextChange}
              />
              <FontPicker
                plan={subscriptionPlanDisable}
                existingValue={data?.button02}
                label="Button Text"
                type="button_text"
                onFontChange={handleTextChange}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="accordion-container"
            expanded={expandedPanel === "corner"}
            onChange={handleAccordionToggle("corner")}
          >
            <AccordionSummary
              className="accordion-summary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="corner-content"
              id="corner-header"
            >
              <Typography>Corner Rounding</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordition-details">
              <div className="hover-container-disable">
                <div
                  className={
                    "hint-popup-container hint-popup-trigger" +
                    (subscriptionPlanDisable === true ? " disabled-popup" : "")
                  }
                >
                  <div className="corner-radius-container item-container-hover">
                    <div className="image-uploader-hint-popup">
                      <div className="hint-popup">
                        <div className="left-arrow-bg"></div>
                        <div className="left-arrow"></div>
                        <div className="hint-title">Plan upgrade required</div>
                        <p className="hint-text">
                          If you’d like to use this feature you need to upgrade
                          your current plan.
                          <Link
                            className="hint-link"
                            to="/plan/select?from=/organization/settings/brand"
                          >
                            Upgrade now.
                          </Link>
                        </p>
                      </div>
                    </div>
                    <p className="txt-desc">
                      Corner rounding allows you to specify the degree of
                      curvature on button edges and the corners of your video
                      pane. A higher number represents a greater degree of
                      curvature.
                    </p>
                    <div className="field-container">
                      <TextField
                        className={`txt-corner-radius ${subscriptionPlanDisable === true ? "disabled" : ""
                          }`}
                        type="text"
                        value={subscriptionPlanDisable === true ? 0 : data?.borderRadius || 0}
                        onChange={handleChange}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                      />
                      <p>Pixels</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Drawer>
      </div>
    </>
  );
};

export default BrandingSideBar;
