import { aspectRatio } from '../../../helpers/media'
import React from 'react'
import { VideoChapter } from '../../../state/types'
import AudioOnlyProfileImage from '../../video/Audio/AudioOnlyProfileImage/AudioOnlyProfileImage'

interface Props {
  i: number
  selectedChapterIndex: number
  chapter: VideoChapter
  onThumbnailClick: (i: number) => void
  duration: string
}

const PublishedPreviewVideoThumbnail = function ({
  i,
  selectedChapterIndex,
  chapter,
  onThumbnailClick,
  duration,
}: Props) {
  let child: JSX.Element
  if (chapter.type === 'audio') {
    child = (
      <div style={{ width: '62.5px', height: '82.5px', padding: '10px 0' }}>
        <AudioOnlyProfileImage cloudinaryId={chapter.profileImageStorageId} />
      </div>
    )
  } else {
    child = <img alt="" src={chapter.thumbnailUrl} style={{ width: '110px', height: 110 * aspectRatio + 'px' }} />
  }

  return (
    <div
      id={'video-chapter-thumbnail-' + i}
      key={chapter.id}
      className={'chapter-thumbnail' + (i === selectedChapterIndex ? ' selected' : '')}
    >
      <button className="chapter-thumbnail__button" onClick={() => onThumbnailClick(i)}>
        {child}
      </button>
      <div className="video-label">
        <div className="name">{chapter.name}</div>
        <div className="duration">{duration}</div>
      </div>
    </div>
  )
}

export default PublishedPreviewVideoThumbnail
