import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import debounce from 'lodash/debounce'
import { buildValidationSchema } from 'helpers/validation'
import { flattenChildren } from 'helpers/treeUtils'
import useContentBlock from 'state/useContentBlock'
import { ContentBlock, ContentBlockField } from 'state/types'
import { BlockProps } from 'components/preview/PreviewEditorPage/PreviewEditorPage'
import PreviewEditorBottomNav from 'components/preview/PreviewEditorBottomNav/PreviewEditorBottomNav'
import PreviewProfileImageSelector from 'components/preview/PreviewProfileImageSelector/PreviewProfileImageSelector'
import FormFieldEditor from 'components/preview/FormFieldEditor/FormFieldEditor'
import SuccessDialog from 'components/shared/SuccessDialog/SuccessDialog'
import AvailabilitySelector from 'components/preview/AvailabilitySelector/AvailabilitySelector'
import FormListEditor from 'components/preview/FormListEditor/FormListEditor'
import FormMonthYear from 'components/shared/FormMonthYear/FormMonthYear'
import FormRadioButton from 'components/shared/FormRadioButton/FormRadioButton'
import './FormBlockEditor.scss'
import DateSelector from '../DateSelector/DateSelector'
import { FieldName } from 'helpers/enum'
import { fieldsToHide } from 'helpers/constant'

const getFormFields = (block: ContentBlock) =>
  flattenChildren(block.contentBlockFields)?.filter(f => f.controlType !== 'ProfileImage' && f.controlType !== 'List')

const getInitialValues = (block: ContentBlock) =>
  getFormFields(block)?.reduce((values, f) => {
    if (f.value !== undefined) {
      values[f.id] = f.value
    }
    return values
  }, {} as Record<string, string>)

const FormBlockEditor: React.FC<BlockProps> = ({
  previewId,
  blockIndex,
  prevLink,
  nextLink,
  isPreviewPublished,
  hideTitles,
}) => {
  const [isSaveConfirmationDialogShowing, setSaveConfirmationDialogShowing] = useState(false)
  const {
    contentBlock,
    isValidationActive,
    isUpdatingValues,
    updateValues,
    getValueUpdateStatusRecord,
    updateSessionValueUpdateStatusRecord,
  } = useContentBlock(previewId, blockIndex)
  const block = contentBlock! // Can't be undefined inside this component.
  const validationSchema = useMemo(() => buildValidationSchema(block), [block])
  const defaultValues = useMemo(() => getInitialValues(block), [block])
  const [blockContentBlockFields, setBlockContentBlockFields] = useState<ContentBlockField[]>([])

  useEffect(() => {
    setBlockContentBlockFields(block.contentBlockFields)
  }, [block.contentBlockFields])

  const {
    errors,
    control,
    register,
    watch,
    handleSubmit,
    triggerValidation,
    reset,
    formState: { dirty },
    setValue,
  } = useForm({
    defaultValues,
    validationSchema,
  })

  const getValuesToSave = (formValues: FieldValues) => {
    // Only include values where the form value differs from the current store value.
    const fieldsToUpdate = getFormFields(block)
      ?.map(f => ({ id: f.id, value: f.value !== formValues[f.id] ? formValues[f.id] : undefined }))
      ?.filter(v => {
        if (!hideTitles) return v.value !== undefined
        return (
          v.value !== undefined &&
          !contentBlock?.contentBlockFields.some(
            cbf => cbf.id === v.id && (cbf.formLabel === FieldName.Title || cbf.formLabel === FieldName.Subtitle)
          )
        )
      })
    return fieldsToUpdate
  }

  const updateValueUpdateStatusRecord = (valuesToUpdate: { id: string; value: any }[], status: boolean) => {
    const sessionRecord = getValueUpdateStatusRecord()
    let updatedRecord: Record<string, boolean> = sessionRecord ? sessionRecord : {}

    valuesToUpdate.forEach(valueToUpdate => {
      updatedRecord[valueToUpdate.id] = status
    })

    updateSessionValueUpdateStatusRecord(updatedRecord)
    setBlockContentBlockFields(block.contentBlockFields)
  }

  const checkToSaveFormValues = async (formValues: { [x: string]: any }) => {
    const valuesToSave = getValuesToSave(formValues)
    if (!isPreviewPublished && valuesToSave.length > 0) {
      const ok = await updateValues(valuesToSave, true) //don't apply for title and subtitle if preview is duplicate
      if (ok !== undefined) {
        updateValueUpdateStatusRecord(valuesToSave, ok)
      }
    }
  }

  // Note: debounce delay of 700ms here means we autosave after 700ms of inactivity.
  const checkToSaveFormValuesCallback = useCallback(checkToSaveFormValues, [block])
  const checkToSaveFormValuesDebounced = useCallback(debounce(checkToSaveFormValuesCallback, 700), [
    checkToSaveFormValuesCallback,
  ])

  if (!isPreviewPublished) {
    // This really sucks because it causes a rerender every keypress. Need to find a better way to listen to changes
    // in react-hook-form. See https://github.com/react-hook-form/react-hook-form/issues/912.
    checkToSaveFormValuesDebounced(watch())
  }

  const onSubmit = handleSubmit(async formValues => {
    const valuesToSave = getValuesToSave(formValues)
    if (isPreviewPublished && dirty) {
      const ok = await updateValues(valuesToSave)
      if (ok) {
        reset(formValues)
        setSaveConfirmationDialogShowing(true)
      }

      if (ok !== undefined) {
        updateValueUpdateStatusRecord(valuesToSave, ok)
      }
    }
  })

  useEffect(() => {
    if (isValidationActive) {
      triggerValidation()
    }
  }, [isValidationActive, triggerValidation])

  return (
    <form className="form-block-editor" noValidate onSubmit={onSubmit}>
      <div className="form-fields">
        {blockContentBlockFields.map((field, i) => {
          const props = {
            key: field.id,
            name: field.id,
            error: isValidationActive || isPreviewPublished ? (errors?.[field.id]?.message as string) : undefined,
          }
          const label = field.formLabel ? field.formLabel + (field.required ? '*' : '') : ''
          const options = field.options
            ? Object.entries(field.options).map(o => ({ value: o[0], displayValue: o[1] }))
            : []

          switch (field.controlType) {
            case 'DateOfBirth':
              const dateOfBirthSelector = <DateSelector label={field.formLabel} disableFuture />
              return <Controller {...props} control={control} as={dateOfBirthSelector} />
            case 'Date':
              const dateSelector = <DateSelector />
              return <Controller {...props} control={control} as={dateSelector} />
            case 'Radio':
              const radioButton = <FormRadioButton label={label} options={options} />
              return <Controller {...props} control={control} as={radioButton} />
            case 'MonthYear':
              const monthYear = <FormMonthYear label={label} />
              return <Controller {...props} control={control} as={monthYear} />
            case 'Availability':
              const availabilitySelector = <AvailabilitySelector label={label} placeholder={field.placeholder} />
              return <Controller {...props} control={control} as={availabilitySelector} />
            case 'ProfileImage':
              return (
                <PreviewProfileImageSelector
                  key={field.id}
                  label={label}
                  previewId={previewId}
                  blockIndex={blockIndex}
                  fieldIndex={i}
                  isPreviewPublished={isPreviewPublished}
                />
              )
            case 'List':
              return (
                <FormListEditor
                  {...props}
                  label={label}
                  field={field}
                  previewId={previewId}
                  blockIndex={blockIndex}
                  fieldIndex={i}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
              )
            default:
              // All control types handled inside this component can also be repeated inside the List control type:
              if (
                !hideTitles ||
                (hideTitles &&
                 !fieldsToHide.includes(field.formLabel))
              )
                return (
                  <FormFieldEditor
                    {...props}
                    previewId={previewId}
                    blockIndex={blockIndex}
                    field={field}
                    register={register}
                    control={control}
                    setValue={setValue}
                  />
                )
              return null
          }
        })}
      </div>
      <PreviewEditorBottomNav
        prevLink={prevLink}
        nextLink={nextLink}
        showSaveButton={isPreviewPublished}
        isSaveButtonDisabled={!dirty}
        isSaving={isUpdatingValues}
      />
      <SuccessDialog
        isShowing={isSaveConfirmationDialogShowing}
        text="Preview updated"
        setShowing={setSaveConfirmationDialogShowing}
      />
    </form>
  )
}

export default FormBlockEditor
