import React from 'react'

const ShareIconSmall = () => (
  <svg width="30px" height="30px" viewBox="0 0 30 30">
    <title>Share icon 30x30</title>
    <g id="Share-icon-30x30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-8">
        <rect id="Rectangle" fill="#01A3F8" x="0" y="0" width="30" height="30" rx="3"></rect>
        <g id="share-svgrepo-com" transform="translate(7.5, 6.75)" fill="#FFFFFF" fill-rule="nonzero">
          <path d="M11.3457516,9.80677772 C10.4480033,9.80677772 9.64430557,10.2257333 9.11103675,10.8824483 L5.64214677,8.86766279 C5.75002021,8.55711991 5.80930994,8.223217 5.80930994,7.87524261 C5.80930994,7.52733754 5.75002021,7.19343463 5.64214677,6.88289175 L9.11076571,4.86762098 C9.64396677,5.52454393 10.4478001,5.94363818 11.3456161,5.94363818 C12.9470488,5.94363818 14.25,4.61031402 14.25,2.97136853 C14.25,1.33290826 12.9471843,0 11.3457516,0 C9.74411561,0 8.44109661,1.33290826 8.44109661,2.97129921 C8.44109661,3.3192736 8.50031859,3.65324582 8.60825979,3.96385802 L5.13943757,5.97919812 C4.60623651,5.32262175 3.80260649,4.90380477 2.90506153,4.90380477 C1.30322227,4.90380477 0,6.23671303 0,7.87517329 C0,9.51356425 1.30322227,10.8464725 2.90506153,10.8464725 C3.80260649,10.8464725 4.60616875,10.4276555 5.13936981,9.77114847 L8.60825979,11.7860033 C8.50031859,12.0966155 8.44102885,12.4306571 8.44102885,12.7787008 C8.44102885,14.4170917 9.74404785,15.75 11.3456838,15.75 C12.9471165,15.75 14.25,14.4170224 14.25,12.7787008 C14.25,11.1400326 12.9471843,9.80677772 11.3457516,9.80677772 Z M11.3457516,1.0397641 C12.3867438,1.0397641 13.2336723,1.90623418 13.2336723,2.97129921 C13.2336723,4.03691878 12.3867438,4.90380477 11.3457516,4.90380477 C10.3045561,4.90380477 9.45749208,4.03691878 9.45749208,2.97129921 C9.45749208,1.90623418 10.3045561,1.0397641 11.3457516,1.0397641 Z M2.90512929,9.80677772 C1.86373049,9.80677772 1.01646323,8.94023832 1.01646323,7.87524261 C1.01646323,6.81010827 1.86373049,5.94363818 2.90512929,5.94363818 C3.94612153,5.94363818 4.79298224,6.81010827 4.79298224,7.87524261 C4.79298224,8.94023832 3.94605377,9.80677772 2.90512929,9.80677772 Z M11.3457516,14.7103052 C10.3045561,14.7103052 9.45749208,13.8437658 9.45749208,12.7787701 C9.45749208,11.7133585 10.3045561,10.8465418 11.3457516,10.8465418 C12.3867438,10.8465418 13.2336723,11.7133585 13.2336723,12.7787701 C13.2336723,13.8437658 12.3867438,14.7103052 11.3457516,14.7103052 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default ShareIconSmall