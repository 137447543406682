import { useRef, useState, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export const useResizeObserver = (elementRef: React.RefObject<Element>) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const observerRef = useRef(
    new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect // Take first entry, assume we only observe one element.
      setWidth(width)
      setHeight(height)
    })
  )

  useEffect(() => {
    const element = elementRef.current
    const observer = observerRef.current
    if (element && observer) {
      const { width, height } = element.getBoundingClientRect()
      setWidth(width)
      setHeight(height)

      observer.observe(element)
    }
    return () => {
      if (element && observer) {
        observer.unobserve(element)
      }
    }
  }, [observerRef, elementRef])

  return { width, height }
}
