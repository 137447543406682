import React from 'react'
import { formatDate } from 'helpers/date'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import './ArchiveIntegrationConfirmationDialog.scss'

type Props = {
  isShowing: boolean
  isArchiving?: boolean
  onClose: () => void
  onOk: () => void
}

const ArchiveIntegrationConfirmationDialog: React.FC<Props> = ({ isShowing, onClose, onOk }) => {
  return (
    <ConfirmationDialog
      className="unpublish-integration-confirmation-dialog"
      isShowing={isShowing}
      isWarning
      cancelButtonText="No, cancel that"
      okButtonText={`Yes, archive Integration`}
      title={`Are you sure you want to archive?`}
      onClose={onClose}
      onOk={onOk}
    >
      <p>
        If you archive your Integration, it will be disabled and won't gather information from you published previews.
        Do you wish to proceed?
      </p>
    </ConfirmationDialog>
  )
}

export default ArchiveIntegrationConfirmationDialog
