import React from 'react'
import DoneIcon from 'assets/icons/DoneIcon'
import './AuthSuccessMessage.scss'

type Props = {
  text: String
}

const AuthSuccessMessage = ({ text }: Props) => (
  <div className="auth-success-message">
    <DoneIcon />
    <div className="message-text">{text}</div>
  </div>
)

export default AuthSuccessMessage
