import React from 'react'
import { FormControlLabel, FormLabel, Radio, RadioGroup, ThemeProvider } from '@material-ui/core'
import { customTheme } from './MuiTheme'

type Props = {
  options: string[]
  onSelect: (value: string) => void
  selectedOption?: string
}
const RadioButtonToggle: React.FC<Props> = ({ options, onSelect, selectedOption }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelect((event.target as HTMLInputElement).value)
  }

  return (
    <ThemeProvider theme={customTheme}>
      <FormLabel>Unit Type</FormLabel>
      <RadioGroup value={selectedOption} onChange={handleChange}>
        <>
          {options.map((option, index) => (
            <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
          ))}
        </>
      </RadioGroup>
    </ThemeProvider>
  )
}

export default RadioButtonToggle
