import React from 'react'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import './FormListEditor.scss'

type Props = {
  isShowing: boolean
  onClose: () => void
  hideActions?: boolean
}

const HintDialog: React.FC<Props> = ({ isShowing, onClose }) => {
  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      <div className="preview-link-hint-dialog">
        <h3 className="title"> Add Organization information via Preview link. </h3>
        <p>
          Our platform supports adding organization information from other previews. You should following these
          requirements or we will ignore your link:
        </p>
        <p>
          - Your preview should be started with: <b>{window.location.origin + '/p/'}</b> and refer to an existing
          preview.
        </p>
        <p>- The referred preview should be created from on of these template:</p>
        <ul>
          <li>Promote a Job Opportunity </li>
          <li>Re-Leased Onboarding</li>
          <li>Promote a Business or Service</li>
          <li>Onboarding</li>
          <li>Business, Product & Service Summary</li>
        </ul>
        <p>
          - If the referred preview is not published, the referred organization information will not be shown.  
        </p>
      </div>
    </CloseableDialog>
  )
}

export default HintDialog
