import { Toolbar } from '@material-ui/core'
import DropdownMenu from 'components/shared/DropdownMenu/DropdownMenu'
import DropdownMenuOption from 'components/shared/DropdownMenuOption/DropdownMenuOption'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import useAuth from 'state/useAuth'
import './AppHeaderToolbar.scss'
import useWorkspace from '../../../state/useWorkspace'
import ProfileImage from '../../shared/ProfileImage/ProfileImage'
import { getFullName } from '../../../helpers/user'
import { getProfileImageUrl } from '../../../helpers/cloudinary'
import { WorkspaceType } from '../../../helpers/enum'
import { useLocation } from 'react-router-dom';
import SwitchToPersonalAccountDialog from '../SwitchToPersonalAccountDialog/SwitchToPersonalAccountDialog'
import CircularProgress from '@material-ui/core/CircularProgress';
import useBrandTheme from './../../../state/useBrandTheme'
import AuthorizedPage from '../../auth/AuthorizedPage/AuthorizedPage'
import { getOrganizationsById } from '../../../services/organizationService'
import { is } from 'date-fns/locale'

interface Props {
  modalProfileHighlight?: boolean
}

const AppHeaderToolbar = ({ modalProfileHighlight }: Props) => {
  const { user, logOut } = useAuth()
  const { currentWorkspace, switchToUserWorkspace, getHideSwitchToMyPreviewMeDialog, getHideSwitchToMySettingsDialog } =
    useWorkspace(false)
  const [isSwitchToPersonalDialogOpen, setSwitchToPersonalDialogOpen] = useState<boolean>(false)
  const [isMyPreviewClicked, setMyPreviewClicked] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState(false)
  const { push } = useHistory()
  const [isSaveDisabled ,setIsSaveDisabled] = useState(true)
  const location = useLocation();
  const {brandModeData, updateItemBrandTheme, setBrandModeData, getBrandThemeByID,updateThemeAllByOrganization } = useBrandTheme('header')
  const [isAuthorized, setIsAuthorized] = useState(true)
  const [isLoading, setIsLoading] = useState()
  const prevBrandModeDataRef = useRef();
  const prevWorkspaceIdRef = useRef<string | undefined>(undefined);


  const switchToPersonalDashboard = () => {
    setMyPreviewClicked(true)
    if (
      !getHideSwitchToMyPreviewMeDialog() &&
      currentWorkspace &&
      currentWorkspace.type === WorkspaceType.Organization
    ) {
      setSwitchToPersonalDialogOpen(true)
    } else {
      switchWorkspace(true)
    }
  }


  const handleSaveButtonClick = async () => {
    setIsSaving(true);

    try {

      if(brandModeData) {
        const fetchedBrandTheme = await getBrandThemeByID();
        await updateThemeAllByOrganization("custom-workspace-theme");
        if (fetchedBrandTheme) {
          setBrandModeData(brandModeData)
          const updateResponse = await updateItemBrandTheme(JSON.stringify(brandModeData));
          console.log('Responses:', fetchedBrandTheme, updateResponse);
        } else {
          console.error('No brand theme found.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSaving(false);
      setIsSaveDisabled(true);
    }
  };

  useEffect(() => {
    if (currentWorkspace?.id !== prevWorkspaceIdRef.current) {
      prevBrandModeDataRef.current = undefined;
    }

    if (brandModeData && JSON.stringify(brandModeData) !== JSON.stringify(prevBrandModeDataRef.current)
      && prevBrandModeDataRef.current) {
      setIsSaveDisabled(false);
    }

    prevBrandModeDataRef.current = brandModeData;
    prevWorkspaceIdRef.current = currentWorkspace?.id;
  }, [brandModeData, currentWorkspace?.id]);

  useEffect(() => {
    if (currentWorkspace?.id) {
      getOrganizationsById(currentWorkspace?.id)
        .then(data => {
          if (data.status === 403) {
            setIsAuthorized(false)
          } else {
            setIsAuthorized(true)
          }
        })
    }
  }, [currentWorkspace?.id]);

  const switchToPersonalSettings = () => {
    setMyPreviewClicked(false)
    if (
      !getHideSwitchToMySettingsDialog() &&
      currentWorkspace &&
      currentWorkspace.type === WorkspaceType.Organization
    ) {
      setSwitchToPersonalDialogOpen(true)
    } else {
      switchWorkspace(false)
    }
  }

  const switchWorkspace = (isMyPreviewClicked: boolean) => {
    setSwitchToPersonalDialogOpen(false)
    switchToUserWorkspace()

    if (isMyPreviewClicked) {
      push('/')
      window.location.reload()
    } else {
      push('/account')
    }
  }

  const handleContactUsSelect = () => {
    window.open('https://previewme.com/contact', '_blank')
  }

  const handleFAQSelect = () => {
    window.open('https://previewme.com/frequently-asked-questions', '_blank')
  }

  const handleLogOutSelect = () => {
    logOut()
  }

  return (
    <>
      <SwitchToPersonalAccountDialog
        isShowing={isSwitchToPersonalDialogOpen}
        setShowing={setSwitchToPersonalDialogOpen}
        isMyPreviewClicked={isMyPreviewClicked}
        switchWorkspace={switchWorkspace}
      />
      <Toolbar className="app-header-content">
        {(location.pathname == '/organization/settings/brand' && isAuthorized) && (
          <div className="button-top-container">
            <button className={`${!isSaveDisabled? "save-button enabled": "save-button disabled"}`}
             disabled={isSaveDisabled} onClick={handleSaveButtonClick}>
              {isSaving ?
                <>
                  <CircularProgress  className='loader' size={18} />
                  <span>Saving...</span>
                </>
                : 'Save'}
            </button>
            <button className="cancel-button">Cancel</button>
          </div>
        )}
        <Toolbar>
          <div
            className={
              currentWorkspace && currentWorkspace.type === 'user'
                ? 'app-header-personal-logo'
                : 'app-header-business-logo'
            }
            onClick={() => {
              push('/')
            }}
          />
        </Toolbar>
        <div className="tool-bar-buttons">
          <div>
            <DropdownMenu
              trigger={
                <ProfileImage
                  imageUrl={user?.profileImageStorageId ? getProfileImageUrl(user?.profileImageStorageId) : undefined}
                  cssClassName="display-image"
                  name={getFullName(user)}
                  isModalHighlight={modalProfileHighlight}
                />
              }
              menuContent={
                <>
                  <DropdownMenuOption
                    optionName="My Settings"
                    onSelect={() => switchToPersonalSettings()}
                    showBottomBorder
                  />
                  {currentWorkspace && currentWorkspace.type !== WorkspaceType.User && (
                    <DropdownMenuOption
                      optionName="My PreviewMe"
                      onSelect={() => switchToPersonalDashboard()}
                      showBottomBorder
                    />
                  )}
                  <DropdownMenuOption optionName="FAQ" onSelect={handleFAQSelect} showBottomBorder />
                  <DropdownMenuOption optionName="Contact Us" onSelect={handleContactUsSelect} showBottomBorder />
                  <DropdownMenuOption optionName="Log Out" onSelect={handleLogOutSelect} />
                </>
              }
              horizontalAlignment="right"
            />
          </div>
        </div>
      </Toolbar>
    </>
  )
}

export default AppHeaderToolbar
