import { useCallback, useEffect, useRef, useState } from 'react'
import * as contentBlockService from 'services/contentBlockService'
import { ContentBlockFieldValues } from 'state/types'
import useStore from 'state/useStore'
import usePreview from 'state/usePreview'

const useContentBlock = (previewId?: string, blockIndex?: number) => {
  const { state, dispatch } = useStore()
  const { isLoading, preview } = usePreview(previewId)
  const [isUpdatingValues, setUpdatingValues] = useState(false)
  const mountedRef = useRef(true)
  const contentBlock = blockIndex !== undefined ? preview?.contentBlocks?.[blockIndex] : undefined
  const isValidationActive = !!preview?.isValidationActive

  const addField = async (name: string, controlType: 'Video' | 'File', categoryId?: string) => {
    const response = await contentBlockService.addContentBlockField(contentBlock?.id!, name, controlType, categoryId)
    if (response.ok && response.data && blockIndex !== undefined) {
      dispatch({ type: 'ADD_CONTENT_BLOCK_FIELD', blockIndex, field: response.data })
    }
  }

  const saveContentBlockValues = async (values: Array<{ id: string; value: string }>) => {
    const result = await contentBlockService.saveContentBlockValues(values, previewId)
    return result.ok
  }

  const getValueUpdateStatusRecord = () => {
    return state.valueUpdateStatusRecord
  }

  const updateSessionValueUpdateStatusRecord = (valueUpdateStatusRecord: Record<string, boolean>) => {
    dispatch({ type: 'UPDATE_VALUE_UPDATE_STATUS_RECORD', value: valueUpdateStatusRecord })
  }

  const updateValues = useCallback(
    async (values: ContentBlockFieldValues, optimistic?: boolean) => {
      if (blockIndex !== undefined) {
        if (optimistic) {
          dispatch({ type: 'UPDATE_CONTENT_BLOCK_FIELD_VALUES', blockIndex, values })
        }

        // Only trim values we send to server, not what we update in the store, because we compare the form
        // values to the store values to see if we need to autosave, so we don't want these to diverge.
        const trimmedValues = values.map(v => ({ ...v, value: v.value.trim() }))

        if (mountedRef.current) {
          setUpdatingValues(true)
        }
        const result = await contentBlockService.saveContentBlockValues(trimmedValues, previewId)
        if (mountedRef.current) {
          setUpdatingValues(false)
        }

        if (!optimistic && result.ok) {
          dispatch({ type: 'UPDATE_CONTENT_BLOCK_FIELD_VALUES', blockIndex, values })
        }

        return result.ok
      }
    },
    [dispatch, blockIndex]
  )

  const reorderFields = async (fieldIds: string[], stateUpdateDelay: number) => {
    if (blockIndex !== undefined) {
      contentBlockService.reorderContentBlockFields(fieldIds)

      setTimeout(() => {
        dispatch({ type: 'REORDER_CONTENT_BLOCK_FIELDS', blockIndex, fieldIds })
      }, stateUpdateDelay)
    }
  }

  const updatePreviewLabel = async (previewLabel: string) => {
    if (blockIndex !== undefined && contentBlock) {
      const { ok } = await contentBlockService.updatePreviewLabel(contentBlock.id, previewLabel)
      if (ok) {
        dispatch({ type: 'UPDATE_CONTENT_BLOCK_PREVIEW_LABEL', blockIndex, previewLabel })
      }
      return ok
    }
  }

  useEffect(
    () => () => {
      mountedRef.current = false
    },
    []
  )

  return {
    isLoading,
    contentBlock,
    isUpdatingValues,
    isValidationActive,
    addField,
    updateValues,
    reorderFields,
    updatePreviewLabel,
    saveContentBlockValues,
    getValueUpdateStatusRecord,
    updateSessionValueUpdateStatusRecord,
  }
}

export default useContentBlock
