import { MuiThemeProvider } from '@material-ui/core/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import Routes from 'components/app/Routes/Routes'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import React, { useEffect, useState } from 'react'
import useAuth from 'state/useAuth'
import { StoreProvider } from 'state/useStore'
import 'theme/BaseStyles.scss'
import { Helmet } from 'react-helmet'
import useBrandTheme from '../../../state/useBrandTheme'

const AppContent = () => {
  const { isLoadingSession, user, loadSession } = useAuth()
  const { brandModeData } = useBrandTheme("main")

  useEffect(() => {
    loadSession()
  }, [loadSession])

  return isLoadingSession ? <LoadingSpinner fullScreen /> :
    <>
      <Helmet><title>{brandModeData?.pageTitle? brandModeData?.pageTitle : "PreviewMe"}</title></Helmet>
      <Routes userId={user?.id} />
    </>
}

const theme = createMuiTheme({
  palette: {
    primary: { main: '#01a3f8' },
    secondary: { main: '#fbf6ed' },
    action: { active: '#000000' },
  },

  typography: {
    fontFamily: 'DM Sans, sans-serif',
  },
})

const App = () => {
  return (
    <StoreProvider>
      <MuiThemeProvider theme={theme}>
        <AppContent />
      </MuiThemeProvider>
    </StoreProvider>
    )
}

export default App
