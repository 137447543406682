import React from 'react'
import { initiatePasswordReset } from 'services/authService'
import AuthPage from '../AuthPage/AuthPage'
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm'

const ForgotPasswordPage = () => (
  <AuthPage>
    <ForgotPasswordForm initiatePasswordReset={initiatePasswordReset} />
  </AuthPage>
)

export default ForgotPasswordPage
