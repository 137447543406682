import { Dialog } from '@material-ui/core'
import DialogCloseIcon from 'assets/icons/DialogCloseIcon'
import { isMobile } from 'helpers/browser'
import React from 'react'
import './CloseableDialog.scss'

type Props = {
  isShowing: boolean
  className?: string
  darkMode?: boolean // Phone only.
  onClose: () => void
}

const CloseableDialog: React.FC<Props> = ({
  isShowing,
  className,
  children,
  darkMode,
  onClose,
}) => {
  return (
    <Dialog
      className={'closeable-dialog' + (className ? ' ' + className : '') + (darkMode ? ' dark-mode' : '')}
      open={isShowing}
      fullScreen={isMobile}
      onClose={onClose}
    >
      <button className="close-button plain" onClick={onClose}>
        <DialogCloseIcon />
      </button>
      <div className="dialog-content">{children}</div>
      <div className="spacer" />
    </Dialog>
  )
}

export default CloseableDialog
