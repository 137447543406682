import React, { useEffect, useRef, useState } from 'react'
import useStore from '../../../../state/useStore'
import { getWatermarkImage } from '../../../../helpers/cloudinary'
import './WatermarkImage.scss'
import { makeStyles } from '@material-ui/core/styles'
import useWatermark from '../../../../state/useWatermark'
import { SubscriptionPlan } from '../../../../helpers/enum'
import { useParams } from 'react-router-dom'
import useVideoChapter from '../../../../state/useVideoChapter'
import { parseIndexParam } from '../../../../helpers/urlParams'
import { isAndroid, isIos } from '../../../../helpers/browser'

type Props = {
  videoRef: HTMLVideoElement | null
  plan: string
}

function WatermarkImage({videoRef, plan}: Props) {
  const { state } = useStore()
  const isOnBasicPlan = plan === SubscriptionPlan.Basic
  const containerRef = useRef<HTMLDivElement>(null)
  const boxRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const isCLicked = useRef<boolean>(false)
  const watermarkFeatureFlag = state.videoSuiteFeatureFlag?.watermark
  const watermarkImage = state.watermarkData?.cloudinaryId
  const watermarkOpacity = state.watermarkData?.opacity
  const { setWatermarkCoords, setWatermarkImageDimension, watermarkImageDimension } = useWatermark()
  const { previewId, index, fieldIndex } = useParams<any>()
  const {  videoChapter } = useVideoChapter(
    previewId,
    parseIndexParam(index),
    parseIndexParam(fieldIndex),
    true
  )
  const defaultHeight = (videoChapter && videoChapter.height === 480 && videoChapter.width === 640 || videoChapter?.type === 'audio') ? 491 : (videoChapter && videoChapter.height < 480 ? videoChapter.height : 493);
  const [documentWidthTemp, setDocumentWidthTemp] = useState<number>(0);
  const documentWidth =  documentWidthTemp < 690 ?  documentWidthTemp - 40 : 650
  const dynamicHeight = documentWidth * 0.75
  const dynamicWidth = documentWidth
  const videoHeight = videoChapter?.height || (videoChapter?.type === 'video' && state.videoDimension?.height) || dynamicHeight;
  const videoWidth = videoChapter?.width || (videoChapter?.type === 'video' && state.videoDimension?.width) || dynamicWidth;
  const videoHeightStatic = videoChapter?.height || (videoChapter?.type === 'video' && state.videoDimension?.height) || 489;
  const videoWidthStatic = videoChapter?.width || (videoChapter?.type === 'video' && state.videoDimension?.width) || 651;
  const widthBiggerThanHeight = videoChapter ? videoWidth / videoHeight > 1 : false
  const aspectRatioWidth = videoChapter ? (videoWidth / videoHeight < 1 ? 1.33 : 1) : 1;
  const aspectRatioHeight = videoChapter ? (videoWidth / videoHeight > 1 ? 0.75 : 1) : 1;
  const scaleWidth = widthBiggerThanHeight ? videoWidthStatic ? (videoWidthStatic/650) * aspectRatioWidth : 1
    : videoHeightStatic ? (videoHeightStatic/650) * aspectRatioWidth : 1
  const scaleHeight = !widthBiggerThanHeight ? videoHeightStatic ? (videoHeightStatic/defaultHeight) * aspectRatioHeight: 1
    : videoWidthStatic ? (videoWidthStatic/defaultHeight) * aspectRatioHeight : 1
  const scaleWidth2 = widthBiggerThanHeight ? videoWidthStatic ? (videoWidthStatic/dynamicWidth) * aspectRatioWidth : 1
    : videoHeightStatic ? (videoHeightStatic/dynamicWidth) * aspectRatioWidth : 1
  const scaleHeight2 = !widthBiggerThanHeight ? videoHeightStatic ? (videoHeightStatic/dynamicHeight) * aspectRatioHeight: 1
    : videoWidthStatic ? (videoWidthStatic/dynamicHeight) * aspectRatioHeight : 1
  const watermarkCoords = {
    x: (state.watermarkData?.x ?? 0) / scaleWidth,
    y: (state.watermarkData?.y ?? 0) / scaleHeight
  };

  const coords = useRef<{
    startX:number,
    startY:number,
    lastX: number,
    lastY: number
  }>({
    startX: 0,
    startY: 0,
    lastX:0,
    lastY:0
  })
  const mousePosition = useRef({x:0,y:0})
  const prevMousePosition = useRef({x:0,y:0})
  const freezeMousePosition = useRef({x:0,y:0})

  const touchPosition = useRef({x:0,y:0})
  const prevTouchPosition = useRef({x:0,y:0})
  const freezeTouchPosition = useRef({x:0,y:0})
  const updateDocumentWidth = () => {
    const width = document.documentElement.offsetWidth;
    setDocumentWidthTemp(width);
  };

  useEffect(() => {
    const handleResize = () => {
      updateDocumentWidth();
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Initial call
    updateDocumentWidth();

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array for running only on mount

  const useStyles = makeStyles({
    root: {
      '--custom-opacity':  `${watermarkOpacity ? watermarkOpacity/100 : 0}`,
      '--custom-left':`${watermarkCoords?.x ? watermarkCoords.x : 0}px`,
      '--custom-top': `${watermarkCoords?.y ? watermarkCoords.y : 0}px`,
      '--custom-container-width': `${widthBiggerThanHeight ? dynamicWidth : (videoHeight ? dynamicHeight / (videoHeight/videoWidth) : dynamicWidth)}px`,
      '--custom-container-height': `${!widthBiggerThanHeight ? dynamicHeight : (videoWidth ? dynamicWidth / (videoWidth / videoHeight): dynamicHeight)}px`,
      '--custom-fixed-height': `${dynamicHeight}px`,
      '--custom-fixed-width': `${dynamicWidth}px`,
      '--custom-scale-width': `${dynamicWidth/650|| "auto"}`,
      '--custom-scale-height': `${dynamicHeight/487.5|| "auto"}`
    },
  })

  const classes = useStyles(watermarkOpacity)
  let nextX, nextY

  useEffect(() => {
    const box = boxRef.current
    if (!state.watermarkData?.cloudinaryId && box){
      coords.current.lastY = 0
      coords.current.lastX = 0
      coords.current.startY = 0
      coords.current.startX = 0
      nextY = 0
      nextX = 0
      box.style.left = "0px"
      box.style.top = "0px"
    }
  }, [state.watermarkData?.cloudinaryId])

  const handleImageLoaded = () => {
    const box = boxRef.current
    let width, height
    if(box && videoChapter){
      width = box?.offsetWidth * scaleWidth
      height = box?.offsetHeight * scaleHeight
      setWatermarkImageDimension(width, height)
    }
  }
  useEffect(() => {
    if(!containerRef.current || !boxRef.current) return;

    const container = containerRef.current
    const box = boxRef.current
    let nextX = 0, nextY = 0
    const onMouseDown = (e: any) => {
      isCLicked.current = true
      if(!state.watermarkData?.cloudinaryId){
        e.clientY = 0;
        e.clientX = 0
      }
      if(!watermarkCoords){
        coords.current.startX = e.clientX
        coords.current.startY = e.clientY
      } else {
        coords.current.startX = e.clientX - (watermarkCoords.x ? watermarkCoords.x  : 0)
        coords.current.startY =  e.clientY - (watermarkCoords.y ? watermarkCoords.y  : 0)
      }
      container.style.zIndex='100'
      box.style.zIndex = '100'
    }

    const onMouseMove = (e: any) => {
      const dWidth = document.documentElement.offsetWidth < 690 ? document.documentElement.offsetWidth - 40 : 650;
      const dragSensitivityX = (650 / dWidth) - 1;
      const dragSensitivityY = (488 / (dWidth * 0.75)) - 1;

      freezeMousePosition.current.x = Object.freeze(e.clientX);
      freezeMousePosition.current.y = Object.freeze(e.clientY);

      if (!isCLicked.current) {
        mousePosition.current.x = 0;
        mousePosition.current.y = 0;
        return;
      }

      if (!state.watermarkData?.cloudinaryId) {
        e.clientY = 0;
        e.clientX = 0;
      }

      mousePosition.current.x += (e.clientX > prevMousePosition.current.x) ? dragSensitivityX : (e.clientX < prevMousePosition.current.x) ? - dragSensitivityX : 0;
      mousePosition.current.y += (e.clientY > prevMousePosition.current.y) ? dragSensitivityY : (e.clientY < prevMousePosition.current.y) ? - dragSensitivityY : 0;

      nextX = (freezeMousePosition.current.x + mousePosition.current.x) - coords.current.startX + coords.current.lastX;
      nextY = (freezeMousePosition.current.y + mousePosition.current.y) - coords.current.startY + coords.current.lastY;

        box.style.left = `${nextX}px`;
        box.style.top = `${nextY}px`;
      prevMousePosition.current.x = e.clientX;
      prevMousePosition.current.y = e.clientY;
    };

    const onMouseUp = () => {
      const dWidth = document.documentElement.offsetWidth < 690 ? document.documentElement.offsetWidth - 40 : 650;
      prevMousePosition.current.x = 0
      prevMousePosition.current.y = 0
      isCLicked.current = false;
      coords.current.lastX = (box.offsetLeft - (watermarkCoords.x ? watermarkCoords.x : 0));
      coords.current.lastY = (box.offsetTop - (watermarkCoords.y ? watermarkCoords.y : 0));
      setWatermarkCoords(box.offsetLeft * scaleWidth, box.offsetTop * scaleHeight);
      container.style.zIndex = '10';
      box.style.zIndex = '10';
    };

    const onTouchStart = (e: any) => {
      const touch = e.touches[0]
      isCLicked.current = true
      if(!state.watermarkData?.cloudinaryId){
        touch.clientY = 0;
        touch.clientX = 0
      }
      if(!watermarkCoords){
        coords.current.startX = touch.clientX
        coords.current.startY = touch.clientY
      } else {
        coords.current.startX = touch.clientX - (watermarkCoords.x ? watermarkCoords.x  : 0)
        coords.current.startY =  touch.clientY - (watermarkCoords.y ? watermarkCoords.y  : 0)
      }
      container.style.zIndex='100'
      box.style.zIndex = '100'
    }

    const onTouchMove = (e: any) => {
      const startTime = performance.now();
      const dWidth = document.documentElement.offsetWidth < 690 ? document.documentElement.offsetWidth - 40 : 650;
      const adjustedScreenWidth = Math.min(650, Math.max(0, dWidth));
      const x = (1 - adjustedScreenWidth / 650);
      const dragSensitivityX = ((650 / dWidth) - 1) + x;
      const dragSensitivityY = ((488 / (dWidth * 0.75)) - 1) + x;
      const { clientX, clientY } = e.touches[0];

      freezeTouchPosition.current.x = Object.freeze(clientX);
      freezeTouchPosition.current.y = Object.freeze(clientY);

      if (!isCLicked.current) {
        touchPosition.current.x = 0;
        touchPosition.current.y = 0;
        return;
      }

      // Calculate touch position deltas for X and Y
      const deltaX = clientX - prevTouchPosition.current.x;
      const deltaY = clientY - prevTouchPosition.current.y;

      // Update touch position based on drag sensitivity
      touchPosition.current.x += deltaX > 0 ? dragSensitivityX : deltaX < 0 ? -dragSensitivityX : 0;
      touchPosition.current.y += deltaY > 0 ? dragSensitivityY : deltaY < 0 ? -dragSensitivityY : 0;

      // Calculate next X and Y positions
      const nextX = freezeTouchPosition.current.x + touchPosition.current.x - coords.current.startX + coords.current.lastX;
      const nextY = freezeTouchPosition.current.y + touchPosition.current.y - coords.current.startY + coords.current.lastY;

      // Update box position
      box.style.left = `${nextX}px`;
      box.style.top = `${nextY}px`;

      // Update previous touch position
      prevTouchPosition.current.x = clientX;
      prevTouchPosition.current.y = clientY;

      const endTime = performance.now();
      const elapsedTime = endTime - startTime;
    };

    const onTouchUp = () => {
      touchPosition.current.x = 0;
      touchPosition.current.y = 0;
      isCLicked.current = false;
      coords.current.lastX = box.offsetLeft - (watermarkCoords.x ? watermarkCoords.x : 0);
      coords.current.lastY = box.offsetTop - (watermarkCoords.y ? watermarkCoords.y : 0);
      setWatermarkCoords(box.offsetLeft* scaleWidth, box.offsetTop* scaleHeight);
      container.style.zIndex = '10';
      box.style.zIndex = '10';
    };

    box.addEventListener('mousedown', onMouseDown)
    box.addEventListener('mouseup', onMouseUp)
    container.addEventListener('mousemove', onMouseMove)
    container.addEventListener('mouseup', onMouseUp)
    container.addEventListener('mouseleave', onMouseUp)
    box.addEventListener('touchstart', onTouchStart)
    box.addEventListener('touchend', onTouchUp)
    container.addEventListener('touchmove', onTouchMove)

    return () => {
      box.removeEventListener('mousedown', onMouseDown);
      box.removeEventListener('mouseup', onMouseUp);
      container.removeEventListener('mousemove', onMouseMove);
      container.removeEventListener('mouseup', onMouseUp)
      container.removeEventListener('mouseleave', onMouseUp)
      box.removeEventListener('touchstart', onTouchStart)
      box.removeEventListener('touchend', onTouchUp)
      container.removeEventListener('touchmove', onTouchMove)
    };
  }, [state.watermarkData?.cloudinaryId])

  return  (
    <div ref={containerRef} className={`watermark-image-container ${classes.root}`}>
      <div ref={boxRef} className={`watermark-image-box ${classes.root}`}>
        {watermarkImage  && !isOnBasicPlan && watermarkFeatureFlag &&
          <img alt={"watermark image"}
               className='watermark'
               onLoad={handleImageLoaded}
               ref={imageRef}
               src={getWatermarkImage(state.watermarkData?.cloudinaryId as string)}
               draggable="false"
          />}
      </div>
    </div>
  )
}

export default WatermarkImage