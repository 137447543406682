import React, { useEffect, useState } from 'react'
import { isMobile } from 'helpers/browser'
import { Option } from 'state/types'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import FormInput from 'components/shared/FormInput/FormInput'
import FormSelect from 'components/shared/FormSelect/FormSelect'
import './AddFieldDialog.scss'

type Props = {
  title: string
  description?: string
  placeholder: string
  addButtonText: string
  validationErrorText: string
  isShowing: boolean
  options?: Option[]
  areOptionsLoading?: boolean
  onClose: () => void
  onAddClicked: (name: string, selectedOption?: string) => Promise<void>
}

const AddFieldDialog: React.FC<Props> = ({
  title,
  description,
  placeholder,
  addButtonText,
  validationErrorText,
  isShowing,
  options,
  onClose,
  onAddClicked,
}) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [fieldName, setFieldName] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [showErrors, setShowErrors] = useState(false)

  const optionValid = !options || !!selectedOption
  const inputValid = options ? (selectedOption === 'other' ? !!fieldName : true) : !!fieldName
  const optionError = showErrors && !optionValid ? '*Please select an option' : undefined
  const inputError = showErrors && !inputValid ? '*' + validationErrorText : undefined

  const onOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowErrors(false)
    setSelectedOption(e.target.value)
  }

  const onFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (optionValid && inputValid) {
      setShowErrors(false)
      setSubmitting(true)

      const name =
        !options || selectedOption === 'other' ? fieldName : options.find(o => o.value === selectedOption)?.displayValue

      await onAddClicked(name || '', selectedOption || undefined)
      setSubmitting(false)
      onClose()
    } else {
      setShowErrors(true)
    }
  }

  useEffect(() => {
    if (isShowing) {
      setFieldName('')
      setSelectedOption('')
      setShowErrors(false)
    }
  }, [isShowing])

  return (
    <CloseableDialog className="add-field-dialog" isShowing={isShowing} onClose={onClose}>
      <h2>{title}</h2>
      {description && <div className="description">{description}</div>}
      <form className="add-field-dialog-form" onSubmit={onFormSubmit} noValidate>
        {options && (
          <FormSelect value={selectedOption} options={options} error={optionError} onChange={onOptionChange} />
        )}
        {(!options || selectedOption === 'other') && (
          <FormInput
            name="fieldName"
            type="text"
            placeholder={placeholder}
            maxLength={60}
            autoFocus={!isMobile}
            autoComplete="off"
            value={fieldName}
            error={inputError}
            onChange={e => setFieldName(e.target.value)}
          />
        )}
        <div className="buttons">
          <button type="button" className="cancel-button link" onClick={onClose}>
            Cancel
          </button>
          <LoadingButton className="submit-button primary" isLoading={isSubmitting}>
            <div className="add-icon">+</div>
            <span>{addButtonText}</span>
          </LoadingButton>
        </div>
      </form>
    </CloseableDialog>
  )
}

export default AddFieldDialog
