import React from 'react'
import HomeIcon from 'assets/icons/HomeIcon'
import LinkedInIcon from 'assets/icons/LinkedInIcon'
import TwitterIcon from 'assets/icons/TwitterIcon'
import FacebookIcon from 'assets/icons/FacebookIcon'
import InstagramIcon from 'assets/icons/InstagramIcon'
import YoutubeIcon from 'assets/icons/YoutubeIcon'
import GitHubIcon from 'assets/icons/GitHubIcon'
import DribbbleIcon from 'assets/icons/DribbbleIcon'
import PinterestIcon from 'assets/icons/PinterestIcon'
import MediumIcon from 'assets/icons/MediumIcon'
import PreviewMeIcon from '../assets/icons/PreviewMeIcon'

export const lookupIcon = (linkType: string) => {
  switch (linkType) {
    case 'homepage':
      return <HomeIcon />
    case 'previewme':
      return <PreviewMeIcon />
    case 'linkedin':
      return <LinkedInIcon />
    case 'twitter':
      return <TwitterIcon />
    case 'facebook':
      return <FacebookIcon />
    case 'instagram':
      return <InstagramIcon />
    case 'youtube':
      return <YoutubeIcon />
    case 'github':
      return <GitHubIcon />
    case 'dribbble':
      return <DribbbleIcon />
    case 'pinterest':
      return <PinterestIcon />
    case 'medium':
      return <MediumIcon />
    default:
      return null
  }
}
