import React, { useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { isMobile } from 'helpers/browser'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import FormInput from 'components/shared/FormInput/FormInput'
import './SingleFieldForm.scss'

type Props = {
  placeholder: string
  submitButtonText: string | React.ReactNode
  validationErrorText: string
  initialValue?: string
  triggerReset?: boolean // Resets form each time this changes to true.
  onSubmit: (value: string) => Promise<void>
  onCancel: () => void
}

type FormData = {
  value: string
}

const SingleFieldForm: React.FC<Props> = ({
  placeholder,
  submitButtonText,
  validationErrorText,
  initialValue,
  triggerReset,
  onSubmit,
  onCancel,
}) => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        value: yup
          .string()
          .trim()
          .required('*' + validationErrorText),
      }),
    [validationErrorText]
  )

  const { handleSubmit, register, errors, reset } = useForm<FormData>({
    validationSchema,
    defaultValues: { value: initialValue },
  })

  const [isSubmitting, setSubmitting] = useState(false)

  const onFormSubmit = handleSubmit(async ({ value }) => {
    setSubmitting(true)
    await onSubmit(value.trim())
    setSubmitting(false)
  })

  useEffect(() => {
    if (triggerReset) {
      reset()
    }
  }, [triggerReset, reset])

  return (
    <form className="single-field-form" onSubmit={onFormSubmit} noValidate>
      <FormInput
        name="value"
        type="text"
        placeholder={placeholder}
        errors={errors}
        inputRef={register}
        maxLength={60}
        autoFocus={!isMobile}
        autoComplete="off"
      />
      <div className="buttons">
        <button type="button" className="cancel-button link" onClick={onCancel}>
          Cancel
        </button>
        <LoadingButton className="submit-button primary" isLoading={isSubmitting}>
          {submitButtonText}
        </LoadingButton>
      </div>
    </form>
  )
}

export default SingleFieldForm
