import React from 'react'
import ContactUsIcon from 'assets/icons/ContactUsIcon'
import './GetInTouchMessage.scss'

type Props = {
  className?: string
}

const GetInTouchMessage: React.FC<Props> = ({ className }) => (
  <div className={'get-in-touch-message' + (className ? ' ' + className : '')}>
    <ContactUsIcon />
    <span>Want something more custom?</span>
    <a
      className="get-in-touch-link"
      href="https://previewme.com/contactus"
      target="_blank"
      rel="noopener noreferrer"
    >
      Get in touch
    </a>
  </div>
)

export default GetInTouchMessage
