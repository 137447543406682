import React, { useState } from 'react'
import { Option, Rect } from 'state/types'
import { getAbsoluteStyle } from 'helpers/cardAnimation'
import AddFieldDialog from 'components/shared/AddFieldDialog/AddFieldDialog'
import AddIcon from 'assets/icons/AddIcon'
import './AddFieldButton.scss'

type Props = {
  buttonText: string
  dialogTitle: string
  description?: string
  placeholder: string
  dialogAddButtonText: string
  validationErrorText: string
  options?: Option[]
  areOptionsLoading?: boolean
  absolutePosition?: Rect
  onSubmit: (name: string, selectedOption?: string) => Promise<void>
}

const AddFieldButton: React.FC<Props> = ({
  buttonText,
  dialogTitle,
  description,
  placeholder,
  dialogAddButtonText,
  validationErrorText,
  options,
  areOptionsLoading,
  absolutePosition,
  onSubmit,
}) => {
  const [isDialogShowing, setDialogShowing] = useState(false)
  return (
    <>
      <button
        id="add-field-button"
        className="add-field-button"
        style={getAbsoluteStyle(absolutePosition)}
        onClick={() => setDialogShowing(true)}
      >
        <div className="add-icon-container">
          <AddIcon />
        </div>
        <div className="text">{buttonText}</div>
      </button>
      <AddFieldDialog
        title={dialogTitle}
        description={description}
        placeholder={placeholder}
        addButtonText={dialogAddButtonText}
        validationErrorText={validationErrorText}
        isShowing={isDialogShowing}
        options={options}
        areOptionsLoading={areOptionsLoading}
        onClose={() => setDialogShowing(false)}
        onAddClicked={onSubmit}
      />
    </>
  )
}

export default AddFieldButton
