import React, { useEffect, useState } from 'react'
import './InvitationBrandingForm.scss'
import ProfileImageSelector from '../../shared/ProfileImageSelector/ProfileImageSelector'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import { InvitationEditorStep, TempInvitation } from '../../../state/types'
import InvitationEditorStepper from '../InvitationEditorStepper/InvitationEditorStepper'

type Props = {
  index: number
  currentInvitationEditorStepIndex: number
  setCurrentInvitationEditorStepIndex: (value: number) => void
  tempInvitation?: TempInvitation
  setTempInvitation: (value: TempInvitation) => void
  setUpdatedBrandingImage: (value: boolean) => void
  steps: InvitationEditorStep[]
  showEnterpriseFeatures?: boolean
}

const InvitationBrandingForm: React.FC<Props> = ({
  index,
  currentInvitationEditorStepIndex,
  setCurrentInvitationEditorStepIndex,
  tempInvitation,
  setTempInvitation,
  setUpdatedBrandingImage,
  steps,
  showEnterpriseFeatures,
}) => {
  const [hideForm, setHideForm] = useState(currentInvitationEditorStepIndex !== index)
  const [stepIndex, setStepIndex] = useState<{ index: number }>({ index: currentInvitationEditorStepIndex })

  useEffect(() => {
    setHideForm(currentInvitationEditorStepIndex !== index)
  }, [currentInvitationEditorStepIndex])

  useEffect(() => {
    setCurrentInvitationEditorStepIndex(stepIndex.index)
  }, [stepIndex])

  const deleteImageConfirmationText =
    'If you remove this image from your profile it will be permanently deleted. Would you like to continue?'

  const uploadTempInvitationBrandingImage = (blob: Blob) => {
    if (tempInvitation) {
      const uploadedTempInvitation: TempInvitation = {
        ...tempInvitation,
        brandingImage: blob,
      }
      setUpdatedBrandingImage(true)
      setTempInvitation(uploadedTempInvitation)
    }
  }

  const deleteTempInvitationBrandingImage = () => {
    if (tempInvitation) {
      const uploadedTempInvitation: TempInvitation = {
        ...tempInvitation,
        brandingImage: undefined,
      }

      setUpdatedBrandingImage(true)
      setTempInvitation(uploadedTempInvitation)
    }
  }

  return (
    <>
      {!hideForm && (
        <div className={'invitation-branding-form'}>
          {showEnterpriseFeatures !== undefined && (
            <InvitationEditorStepper
              steps={steps}
              onClick={setStepIndex}
              showEnterpriseFeatures={showEnterpriseFeatures}
            />
          )}
          <h1>Add your Branding</h1>
          <div className={'info-text'}>
            <p>
              Each Preview created in response to this Invitation can include custom branding and is visible when a
              Preview is shared. For example, when sharing applicant Previews with clients or stakeholders. <br />
              <br />
              This branding will appear on the top Left-Hand-Side of the Previews received. It is in addition to any
              default branding that can be set on the top Right-Hand-Side via workspace settings &gt; brand &gt; default
              branding.
            </p>
          </div>
          <div className={'upload-image-box'}>
            <div className={'profile-image-selector'}>
              <ProfileImageSelector
                localValue={tempInvitation?.brandingImage}
                canDelete
                warnBeforeDeleting={false}
                deleteConfirmationText={deleteImageConfirmationText}
                uploadButtonText=" workspace profile image"
                showUploadingLabel
                uploadImageLocally={uploadTempInvitationBrandingImage}
                deleteImage={deleteTempInvitationBrandingImage}
              />
            </div>
          </div>
          <div className={'bottom-buttons'}>
            <div className={'prev-button'} onClick={() => setCurrentInvitationEditorStepIndex(index - 1)}>
              {'< PREV'}
            </div>
            <LoadingButton
              className="next-button primary"
              onClick={() => setCurrentInvitationEditorStepIndex(index + 1)}
            >
              Next
            </LoadingButton>
          </div>
        </div>
      )}
    </>
  )
}

export default InvitationBrandingForm
