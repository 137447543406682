import React, { useEffect, useRef } from 'react'
import { select } from 'd3-selection'
import { arc, pie } from 'd3-shape'
import { useResizeObserver } from 'helpers/resizeObserver'
import './DonutChart.scss'

type Props = {
  title: React.ReactNode
  data: { id: string; label: string; value: number }[]
}

const formatPercentValue = (value: number, total: number) => {
  const percent = (value / total) * 100
  return `(${Math.round(percent)}%)`
}

const DonutChart: React.FC<Props> = ({ title, data }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)
  const total = data.reduce((sum, { value }) => value + sum, 0)
  const { width, height } = useResizeObserver(containerRef)

  useEffect(() => {
    if (width > 0 && height > 0) {
      select(svgRef.current).select('*').remove()

      const radius = Math.min(width / 2, height / 2)
      const arcFunc = arc()
        .innerRadius(radius * 0.5)
        .outerRadius(radius)

      const arcs = pie().sort(null)(data.map(d => d.value))

      select(svgRef.current)
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`)
        .selectAll('path')
        .data(arcs)
        .join('path')
        .attr('d', arcFunc as any)
        .attr('class', (_, i) => 'item-' + (i + 1))
    }
  })

  return (
    <div className="donut-chart">
      <div className="title">{title}</div>
      <div className="svg-container" ref={containerRef}>
        <svg ref={svgRef} />
      </div>
      <div className="legend">
        {data.map(({ id, label, value }, i) => (
          <div key={id} className={'legend-item item-' + (i + 1)}>
            <div className="legend-color-marker" />
            <div className="legend-text">
              <span className="label">{label}</span>
              <span className="value">{value}</span>
              <span className="percent">{formatPercentValue(value, total)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DonutChart
