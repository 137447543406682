import React from 'react'

const BackArrowIcon = () => (
  <svg className="icon" width="40px" height="40px" viewBox="0 0 40 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-310.000000, -25.000000)">
        <g transform="translate(310.000000, 25.000000)">
          <g>
            <circle fill="#F2EDE2" cx="20" cy="20" r="20"></circle>
            <g transform="translate(13.000000, 14.000000)" stroke="#25262A" strokeLinecap="round">
              <polyline
                strokeLinejoin="round"
                transform="translate(5.500000, 5.500000) rotate(-135.000000) translate(-5.500000, -5.500000) "
                points="2 2 9 2 9 9"
              ></polyline>
              <path d="M0.550252532,5.5 L13.5068359,5.5"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default BackArrowIcon
