import WorkspaceMenuButton from 'components/account/WorkspaceMenuButton/WorkspaceMenuButton'
import NavItems from 'components/app/NavItems/NavItems'
import React from 'react'
import { ModalHighlight, Workspace } from 'state/types'
import './DesktopNav.scss'

type Props = {
  isWorkspaceTypeOrganization: boolean
  currentWorkspace?: Workspace
  allWorkspaces?: Workspace[]
  setWorkspace: (workspace: Workspace) => void
  modalHighlight?: ModalHighlight
}

const DesktopNav: React.FC<Props> = ({
  isWorkspaceTypeOrganization,
  currentWorkspace,
  allWorkspaces,
  setWorkspace,
  modalHighlight,
}) => {
  return (
    <nav className={`desktop-nav ${modalHighlight && 'absolute'}`}>
      <div className="nav-scrollable-content">
        {currentWorkspace && allWorkspaces && (
          <div className="nav-top-section">
            <WorkspaceMenuButton
              currentWorkspace={currentWorkspace}
              allWorkspaces={allWorkspaces}
              setWorkspace={setWorkspace}
            />
          </div>
        )}
        <NavItems isWorkspaceTypeOrganization={isWorkspaceTypeOrganization} modalHighlight={modalHighlight} />
        <div className="grower" />
      </div>
    </nav>
  )
}

export default DesktopNav
