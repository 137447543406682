import { findRootField, isSelfOrDescendant } from 'helpers/treeUtils'
import update, { Spec } from 'immutability-helper'
import Action from 'state/actions'
import { AppState, ContentBlock, ContentBlockField } from 'state/types'
import { number } from 'yup'

export const initialState = {
  // Important that isLoadingSession starts as true, otherwise if you are logged in and do a page refresh,
  // you will get redirected to the login screen.
  isLoadingSession: true,
  isLoggedIn: false,
  isPreviewLoading: false,
  areVideoAssetsLoading: false,
  hasVideoAssets: false,
  areDocumentAssetsLoading: false,
  hasDocumentAssets: false,
  isInvitationLoading: false,
}

const updateContentBlock = (state: AppState, blockIndex: number, spec: Spec<ContentBlock>) =>
  update(state, { preview: { contentBlocks: { [blockIndex]: spec } } })

const updateContentBlockField = (
  state: AppState,
  blockIndex: number,
  fieldIndex: number,
  spec: Spec<ContentBlockField>
) => updateContentBlock(state, blockIndex, { contentBlockFields: { [fieldIndex]: spec } })

// Handles both top-level content block fields AND children of other fields.
const updateNestedContentBlockField = (
  state: AppState,
  blockIndex: number,
  fieldId: string,
  initialSpec: Spec<ContentBlockField>
) => {
  const blockToUpdate = state.preview?.contentBlocks?.[blockIndex]
  const rootField = findRootField(fieldId, blockToUpdate?.contentBlockFields)
  const rootFieldIndex = blockToUpdate?.contentBlockFields?.findIndex(f => f.id === rootField?.id)

  if (blockToUpdate && rootField) {
    let fieldToUpdate: ContentBlockField | undefined = rootField
    let childIndices: number[] = []
    let spec = initialSpec

    // Keep looking inside the lists of children until we find the field to update:
    while (fieldToUpdate && fieldToUpdate.id !== fieldId) {
      const childIndex: number | undefined = fieldToUpdate?.children?.findIndex(f => isSelfOrDescendant(fieldId, f))
      if (childIndex !== undefined && childIndex >= 0) {
        childIndices = childIndices.concat(childIndex)
        fieldToUpdate = fieldToUpdate.children?.[childIndex]
      } else {
        fieldToUpdate = undefined
      }
    }

    childIndices.reverse().forEach(childIndex => (spec = { children: { [childIndex]: spec } }))

    if (fieldToUpdate && rootFieldIndex !== undefined) {
      return updateContentBlockField(state, blockIndex, rootFieldIndex, spec)
    }
  }
  return state
}

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case 'SET_BRAND_MODE_DATA':
      return { ...state, brand: action.value }
    case 'SET_CALLS_ACTION_DATA':
      return { ...state, callsAction: action.value }
    case 'SET_BILLING_TYPE':
      return {...state, billingType: action.value}
    case 'SET_INITIAL_FADE_IN':
      return { ...state, initialFadeIn: action.value }
    case 'SET_INITIAL_FADE_OUT':
      return { ...state, initialFadeOut: action.value }
    case 'SET_WATERMARK_DATA':
      return { ...state, watermarkData: { ...state.watermarkData, ...action.value } }
    case 'SET_VIDEO_SUITE_FEATURE_FLAG':
      return {...state, videoSuiteFeatureFlag: {...state.videoSuiteFeatureFlag, ...action.value}}
    case 'SET_HIDE_PREVIEW_ME_BRANDING':
      return { ...state, previewDisableData: action.value }
    case 'SET_CLOUDINARY_STORAGE_ID':
      return {...state, cloudinaryStorageId: action.value}
    case 'SET_FAVICON_CLOUDINARY_STORAGE_ID':
      return {...state, faviconCloudinaryStorageId: action.value}
    case 'SET_PREVIEW_CUSTOM_STYLES':
      return {...state, previewCustomStyles: action.value}
    case 'SET_CURRENT_WORKSPACE':
      return { ...state, currentWorkspace: action.value }
    case 'SET_LOG_OUT_REDIRECT_URL':
      return { ...state, logOutRedirectUrl: action.value }
    case 'SET_LOADING_SESSION':
      return { ...state, isLoadingSession: action.value }
    case 'SET_LOGGED_IN':
      return { ...state, isLoggedIn: action.value }
    case 'SET_USER':
      return { ...state, user: action.value }
    case 'UPDATE_USER':
      return { ...state, user: { ...state.user!, ...action.value } }
    case 'SET_PREVIEW_LOADING':
      return { ...state, isPreviewLoading: action.value }
    case 'SET_PREVIEW':
      return { ...state, preview: action.value }
    case 'SET_SUBSCRIPTION':
      return { ...state, subscription: action.value }
    case 'SET_PROFILE_IMAGE_STORAGE_ID':
      return {...state, profileImage: action.value}
    case 'UPDATE_PREVIEW_NAME':
      return update(state, { preview: { name: { $set: action.value || undefined } } })
    case 'UPDATE_PREVIEW_TITLE':
      return update(state, { preview: { title: { $set: action.value || undefined } } })
    case 'UPDATE_PREVIEW_SUBTITLE':
      return update(state, { preview: { subtitle: { $set: action.value || undefined } } })
    case 'ADD_CONTENT_BLOCK_FIELD':
      return updateContentBlock(state, action.blockIndex, { contentBlockFields: { $push: [action.field] } })
    case 'REMOVE_CONTENT_BLOCK_FIELD':
      return updateContentBlock(state, action.blockIndex, { contentBlockFields: { $splice: [[action.fieldIndex, 1]] } })
    case 'RENAME_CONTENT_BLOCK_FIELD':
      return updateContentBlockField(state, action.blockIndex, action.fieldIndex, {
        formLabel: { $set: action.name },
        previewLabel: { $set: action.name },
      })
    case 'UPDATE_CONTENT_BLOCK_FIELD_VALUES':
      return action.values.reduce(
        (newState, { id, value }) =>
          updateNestedContentBlockField(newState, action.blockIndex, id, { value: { $set: value } }),
        state
      )
    case 'UPDATE_CONTENT_BLOCK_FIELD_VALUE':
      return updateNestedContentBlockField(state, action.blockIndex, action.fieldId, { value: { $set: action.value } })
    case 'UPDATE_VALUE_UPDATE_STATUS_RECORD':
      return { ...state, valueUpdateStatusRecord: action.value }
    case 'ADD_CONTENT_BLOCK_FIELD_TO_LIST':
      const addContentBlockFieldSpec: Spec<ContentBlockField> = { children: { $push: [action.child] } }
      return action.fieldIndex
        ? updateContentBlockField(state, action.blockIndex, action.fieldIndex, addContentBlockFieldSpec)
        : action.fieldId
          ? updateNestedContentBlockField(state, action.blockIndex, action.fieldId, addContentBlockFieldSpec)
          : state
    case 'DELETE_CONTENT_BLOCK_FIELD_FROM_LIST':
      const deleteContentBlockFieldSpec: Spec<ContentBlockField> = { children: { $splice: [[action.childIndex, 1]] } }
      return action.fieldIndex
        ? updateContentBlockField(state, action.blockIndex, action.fieldIndex, deleteContentBlockFieldSpec)
        : action.fieldId
          ? updateNestedContentBlockField(state, action.blockIndex, action.fieldId, deleteContentBlockFieldSpec)
          : state
    case 'UPDATE_VIDEO_CHAPTER':
      return updateContentBlockField(state, action.blockIndex, action.fieldIndex, {
        videoChapter: { $set: action.chapter },
      })
    case 'UPDATE_RAW_VIDEO_STORAGE_ID':
      return updateContentBlockField(state, action.blockIndex, action.fieldIndex, {
        videoChapter: { rawVideoStorageId: { $set: action.storageId } },
      })
    case 'UPDATE_VIDEO_CHAPTER_PROFILE_IMAGE_STORAGE_ID':
      return updateContentBlockField(state, action.blockIndex, action.fieldIndex, {
        videoChapter: { profileImageStorageId: { $set: action.storageId } },
      })
    case 'UPDATE_VIDEO_CHAPTER_TYPE':
      return updateContentBlockField(state, action.blockIndex, action.fieldIndex, {
        videoChapter: { type: { $set: action.videoType } },
      })
    case 'UPDATE_VIDEO_TRIM_DATA':
      return updateContentBlockField(state, action.blockIndex, action.fieldIndex, {
        videoChapter: {
          startOffsetTime: { $set: action.startOffset },
          endOffsetTime: { $set: action.endOffset },
        },
      })
    case 'UPDATE_TELEPROMPTER_TEXT':
      return updateContentBlockField(state, action.blockIndex, action.fieldIndex, {
        videoChapter: { teleprompterText: { $set: action.text } },
      })
    case 'RENAME_VIDEO_CHAPTER':
      return updateContentBlockField(state, action.blockIndex, action.fieldIndex, {
        videoChapter: { name: { $set: action.name } },
      })
    case 'SET_PREVIEW_VALIDATION_ACTIVE':
      return update(state, { preview: { isValidationActive: { $set: action.value || undefined } } })
    case 'SET_VIDEO_ASSETS_LOADING':
      return { ...state, areVideoAssetsLoading: action.value }
    case 'SET_HAS_VIDEO_ASSETS':
      return { ...state, hasVideoAssets: action.value }
    case 'SET_VIDEO_DIMENSION':
      return {...state, videoDimension: action.value}
    case 'SET_VIDEO_ASSETS':
      return { ...state, videoAssets: action.value }
    case 'SET_DOCUMENT_ASSETS_LOADING':
      return { ...state, areDocumentAssetsLoading: action.value }
    case 'SET_HAS_DOCUMENT_ASSETS':
      return { ...state, hasDocumentAssets: action.value }
    case 'SET_DOCUMENT_ASSETS':
      return { ...state, documentAssets: action.value }
    case 'SET_STATUS_FILTER':
      return { ...state, invitationsStateFilter: action.value }
    case 'SET_CREATOR_FILTER':
      return { ...state, invitationsCreatorFilter: action.value }
    case 'SET_INVITATION_LOADING':
      return { ...state, isInvitationLoading: action.value }
    case 'SET_INVITATION':
      return { ...state, invitation: action.value }
    case 'SET_DEFAULT_INVITATION':
      return { ...state, defaultInvitation: action.value }
    case 'SET_INVITATION_TABLE_CURRENT_PAGE':
      return { ...state, invitationTableCURRENT_PAGE: action.value }
    case 'SET_VIDEO_NEW_START':
      return { ...state, videoStartData: action.value }
    case 'SET_VIDEO_NEW_END':
      return { ...state, videoEndData: action.value }
    case 'SET_PREVIEW_STARRED':
      const indexOfStarredPreviewToUpdate = state.invitation?.previews?.findIndex(p => p.id === action.previewId)
      return indexOfStarredPreviewToUpdate !== undefined
        ? update(state, {
          invitation: { previews: { [indexOfStarredPreviewToUpdate]: { starred: { $set: action.value } } } },
        })
        : state
    case 'SET_PREVIEW_LABEL':
      const indexOfLabeledPreviewToUpdate = state.invitation?.previews?.findIndex(p => p.id === action.previewId)
      return indexOfLabeledPreviewToUpdate !== undefined
        ? update(state, {
          invitation: { previews: { [indexOfLabeledPreviewToUpdate]: { label: { $set: action.value } } } },
        })
        : state
    case 'SET_PREVIEW_VIEWED':
      const indexOfPreviewToSetAsViewed = state.invitation?.previews?.findIndex(p => p.id === action.previewId)
      return indexOfPreviewToSetAsViewed !== undefined
        ? update(state, { invitation: { previews: { [indexOfPreviewToSetAsViewed]: { viewed: { $set: true } } } } })
        : state
    case 'REORDER_CONTENT_BLOCK_FIELDS':
      if (state.preview?.contentBlocks) {
        const fields = state.preview.contentBlocks[action.blockIndex].contentBlockFields
        const newFields = [...fields]?.sort((a, b) => {
          const indexOfA = action.fieldIds?.findIndex(id => id === a.id)
          const indexOfB = action.fieldIds?.findIndex(id => id === b.id)
          return indexOfA - indexOfB
        })
        return updateContentBlock(state, action.blockIndex, { contentBlockFields: { $set: newFields } })
      } else {
        return state
      }
    case 'UPDATE_CONTENT_BLOCK_PREVIEW_LABEL':
      return updateContentBlock(state, action.blockIndex, { previewLabel: { $set: action.previewLabel } })
    case 'UPDATE_PREVIEW_TAGS':
      return update(state, {
        preview: {
          previewTagDetail: {
            $set: action.value,
          },
        },
      })
    default:
      return state
  }
}

export default reducer
