import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import useAuth from 'state/useAuth'
import { globalAuthAppRedirect, globalAuthUrl } from '../../../helpers/config'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'
import { storeLocationFrom } from '../../../helpers/paramStorage'

type Props = {
  component: any
  componentProps?: any
} & RouteProps

const SecuredRoute = ({ component: Component, componentProps, location, ...rest }: Props) => {
  const { isLoggedIn } = useAuth()
  if (!isLoggedIn) {
    const urlExtension: string = location?.state
      ? location.state.from + location.search // this is for invitations e.g. /i/2aed43
      : location
      ? location.pathname + location.search // this is for organisation Invite e.g. /o/{orgId}/i/{InvId}?organisationName={orgName}
      : ''

    // This stores the original url ie: http://localhost:3000/i/2aed43 /i/2aed43, so after onboarding success, can redirect
    storeLocationFrom(urlExtension)

    // If already onboarded, can directly redirect to initial url after loginSuccess
    window.location.assign(globalAuthUrl + globalAuthAppRedirect + urlExtension)
    return (
      <div>
        <LoadingSpinner fullScreen />
      </div>
    )
  }

  return <Route render={() => <Component {...componentProps} />} {...rest} />
}

export default SecuredRoute
