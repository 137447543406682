import MenuIcon from 'assets/icons/MenuIcon'
import WorkspaceMenuButton from 'components/account/WorkspaceMenuButton/WorkspaceMenuButton'
import NavItems from 'components/app/NavItems/NavItems'
import Dialog from 'components/shared/Dialog/Dialog'
import { getFullName } from 'helpers/user'
import React, { useState } from 'react'
import { Workspace } from 'state/types'
import useAuth from 'state/useAuth'
import './MobileNav.scss'

type Props = {
  isWorkspaceTypeOrganization: boolean
  currentWorkspace?: Workspace
  allWorkspaces?: Workspace[]
  setWorkspace: (workspace: Workspace) => void
}

const MobileNav: React.FC<Props> = ({ isWorkspaceTypeOrganization, currentWorkspace, allWorkspaces, setWorkspace }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { user } = useAuth()
  const fullName = getFullName(user)

  return (
    <nav className="mobile-nav">
      <div className="mobile-nav-header">
        {currentWorkspace && allWorkspaces ? (
          <WorkspaceMenuButton
            currentWorkspace={currentWorkspace}
            allWorkspaces={allWorkspaces}
            setWorkspace={setWorkspace}
          />
        ) : (
          <p className="mobile-nav-name">{fullName}</p>
        )}

        <button onClick={() => setMenuOpen(!menuOpen)}>
          <MenuIcon open={menuOpen} />
        </button>
      </div>
      <Dialog className="mobile-nav-dialog" isShowing={menuOpen} scrollTopOnOpen onClose={() => setMenuOpen(false)}>
        <NavItems
          onLinkClick={() => setMenuOpen(false)}
          isWorkspaceTypeOrganization={isWorkspaceTypeOrganization}
          showMobileView
        />
      </Dialog>
    </nav>
  )
}

export default MobileNav
