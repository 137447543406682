import React from 'react';
import FileSelectorButton from '../../../../shared/FileSelectorButton/FileSelectorButton';
import '../FileSelector/FileSelector.scss';
import useStore from '../../../../../state/useStore';
import { getBusinessLogoImageUrl, getProfileImageUrl, getWatermarkImage } from '../../../../../helpers/cloudinary'
import DialogCloseIcon from '../../../../../assets/icons/DialogCloseIcon';
import ProgressBar from '../../../../shared/ProgressBar/ProgressBar';
import ConfirmationDialog from '../../../../shared/ConfirmationDialog/ConfirmationDialog';
import { imageFileSizeLimitInMb, watermarkImageFileSizeLimitInKb } from '../../../../../helpers/media'
import { SubscriptionPlan } from '../../../../../helpers/enum'

type Props = {
  onSelectedFileChange: (file: File | null) => void;
  onDeleteImageClick: () => void;
  value: string | undefined;
  disable: boolean;
  isUploading: boolean;
  uploadProgress: number;
  imageFormat: string
};

function FileSelector({ onSelectedFileChange, onDeleteImageClick, value, disable, isUploading, uploadProgress, imageFormat}: Props) {
  return (
    <div className={`file-selector ${disable ? 'disabled' : ''}`}>
      {value && !disable &&
        <>
          {imageFormat === 'watermark' && <img className='watermark-image' src={getWatermarkImage(value)} />}
          {imageFormat === "audio-image" && <img className={'watermark-image'} src={getProfileImageUrl(value)}/>}
          <button type='button' className='close-button plain' onClick={onDeleteImageClick}>
            <DialogCloseIcon />
          </button>
        </>
      }

      {!isUploading ? (
        <div className={`upload-text-block ${!!value && !disable ? 'has-value' : ''}`}>
          <FileSelectorButton
            accept='.svg,.png,.jpeg'
            disabled={disable}
            onSelectedFileChange={onSelectedFileChange}
            className='upload-image-button link'
          >
            Upload your Image
          </FileSelectorButton>
          {imageFormat === 'audio-image' && <span className='upload-text-small'>Max file size is {imageFileSizeLimitInMb} MB</span>}
          {imageFormat === 'watermark' && <span className='upload-text-small'>Max file size is {watermarkImageFileSizeLimitInKb} KB</span>}
        </div>
      ) : (
        <div className='uploading-buttons'>
          <div className="uploading-label">Uploading...</div>
          <div className="progress-bar-container">
            <ProgressBar progress={uploadProgress} />
          </div>
        </div>
      )}
    </div>
  );
}

export default FileSelector;
