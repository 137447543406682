import React from 'react'
import './ProfileCell.scss'
import ProfileImage from '../ProfileImage/ProfileImage'
import { getProfileImageUrl } from '../../../helpers/cloudinary'
import { getFullName } from '../../../helpers/names'
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip'

type Props = {
  givenName?: string
  familyName?: string
  name?: string
  email?: string
  notViewed?: boolean
  profileImageId?: string
}

const ProfileCell: React.FC<Props> = ({ givenName, familyName, name, email, notViewed, profileImageId }) => {
  return (
    <div className={'profile-cell'}>
      <ProfileImage
        imageUrl={profileImageId ? getProfileImageUrl(profileImageId) : undefined}
        cssClassName={'table-image'}
        name={getFullName(givenName, familyName)}
      />
      <div className={'info'}>
        <div className={'full-name ' + (notViewed ? 'not-viewed' : '')}>
          <OverflowTooltip>{name || getFullName(givenName, familyName)}</OverflowTooltip>
        </div>
        {email && (
          <div className="email">
            <OverflowTooltip>{email}</OverflowTooltip>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileCell
