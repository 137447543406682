import React from 'react'
import { ContentBlockField } from 'state/types'
import { formatDropdownFreeText } from '../../../helpers/formatting'

type Props = {
  fields: ContentBlockField[]
}

const PublishedPreviewDropdownFreeText: React.FC<Props> = ({ fields }) => {
  return (
    <div>
      {fields.map(field => {
        return field.controlType === 'DropdownFreeText' && <div key={field.id}>{formatDropdownFreeText(field)}</div>
      })}
    </div>
  )
}

export default PublishedPreviewDropdownFreeText
