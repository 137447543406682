import Filter from 'components/shared/Filter/Filter'
import { InvitationsCreatorFilterOption, InvitationsStatusFilterOption } from 'helpers/enum'
import React from 'react'
import './InvitationsFilters.scss'
import ClearAllFiltersButton from '../../shared/ClearAllFiltersButton/ClearAllFiltersButton'

type Props = {
  selectedStatus: string
  selectedCreator: string
  onStatusSelect: (value: string) => void
  onCreatorSelect: (value: string) => void
  onClearAllSelect: () => void
}

const InvitationsFilters: React.FC<Props> = ({
  selectedStatus,
  selectedCreator,
  onStatusSelect,
  onCreatorSelect,
  onClearAllSelect,
}) => {
  return (
    <div className="invitations-filters">
      <Filter
        filterName="Status"
        selectedFilterOption={selectedStatus}
        filterOptions={Object.values(InvitationsStatusFilterOption)}
        onSelect={onStatusSelect}
      />
      <Filter
        filterName="Creator"
        selectedFilterOption={selectedCreator}
        filterOptions={Object.values(InvitationsCreatorFilterOption)}
        onSelect={onCreatorSelect}
      />
      <ClearAllFiltersButton onSelect={onClearAllSelect} />
    </div>
  )
}

export default InvitationsFilters
