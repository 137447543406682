import React, { useState } from 'react'

import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import PageIndicator from './PageIndicator'
import './OnboardingModal.scss'
import { ModalHighlight } from '../../../state/types'
import useAuth from '../../../state/useAuth'
import { BusinessModalPages, PersonalModalPages } from './OnboardingModalPages'

interface Props {
  onClose: () => void
  isWorkspaceTypeOrganization: boolean
  setModalHighlight: (modalHighlight: ModalHighlight) => void
}

const OnboardingModal = function ({ onClose, isWorkspaceTypeOrganization, setModalHighlight }: Props) {
  const { user } = useAuth()
  if (!user || !user.givenName) throw new Error('user is not authenticated')

  const pages = isWorkspaceTypeOrganization ? BusinessModalPages(user.givenName) : PersonalModalPages(user.givenName)
  const [pageIndex, setPageIndex] = useState<number>(0)

  setModalHighlight(pages[pageIndex].id)

  const nextPage = function () {
    if (pageIndex === pages.length - 1) {
      onClose()
    } else {
      setPageIndex(pageIndex + 1)
    }
  }

  return (
    <CloseableDialog isShowing={true} onClose={onClose}>
      <div className="onboarding-modal">
        {pages[pageIndex].content}
        <button
          className="next-button primary"
          onClick={() => {
            nextPage()
          }}
        >
          <span>Next</span>
        </button>
        <PageIndicator pageCount={pages.length} pageIndex={pageIndex} setPageIndex={setPageIndex} />
      </div>
    </CloseableDialog>
  )
}
export default OnboardingModal
