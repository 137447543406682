import FileSelectorButton from '../../shared/FileSelectorButton/FileSelectorButton'
import { isMediaRecorderSupported, videoFileSizeLimitInBytes } from '../../../helpers/media'
import UploadIcon from '../../../assets/icons/UploadIcon'
import React from 'react'
import { CloudinaryUploadResult, RecordingType } from '../../../state/types'
import useStore from '../../../state/useStore'
import { useHistory, useParams } from 'react-router-dom'
import { parseIndexParam } from '../../../helpers/urlParams'

interface Props {
  onFileSizeTooLargeError: () => void
  onStartUploading: () => void
  onFinishUploading: () => void
  uploadVideo: (
    blob: Blob,
    onProgressChange: (progress: number) => void
  ) => { response: Promise<CloudinaryUploadResult>; abort: (() => void) | undefined }
  setProgress: (progress: number) => void
  abortRef: React.MutableRefObject<(() => void) | null>
  saveType: (type: RecordingType) => void
  audioOnly?: boolean
  videoOnly?: boolean
  videoTrimEditorPath: string
}

const UploadVideoAudioButton = function ({
  onFileSizeTooLargeError,
  onStartUploading,
  onFinishUploading,
  uploadVideo,
  setProgress,
  abortRef,
  saveType,
  audioOnly,
  videoOnly,
  videoTrimEditorPath
}: Props) {
  const { dispatch } = useStore()
  const history = useHistory()

  const onSelectedFileChange = async (file: File | null) => {
    if (file) {
      if (file.size > videoFileSizeLimitInBytes) {
        onFileSizeTooLargeError()
      } else {
        onStartUploading();
        setProgress(0)

        const video = document.createElement('video')
        video.src = URL.createObjectURL(file)

        await new Promise((resolve) => {
          video.onloadedmetadata = resolve
        });

        dispatch({type:"SET_VIDEO_DIMENSION",
          value: {
            width: video.videoWidth,
            height: video.videoHeight
          }})

        URL.revokeObjectURL(video.src)

        const { response, abort } = uploadVideo(file, setProgress)
        abortRef.current = abort || null
        await response
        updateVideoChapterType(file.type)
        onFinishUploading()
        setTimeout(() => 200)
        history.push(videoTrimEditorPath)
      }
    }
  }

  const updateVideoChapterType = function (fileType: string) {
    let type: RecordingType | undefined
    if (audioOnly) {
      type = 'audio'
    } else if (videoOnly) {
      type = 'video'
    } else if (fileType.includes('audio')) {
      type = 'audio'
    } else {
      type = 'video'
    }
    saveType(type)
  }

  const accept = audioOnly
    ? 'audio/*'
    : videoOnly
    ? 'video/mp4,video/x-m4v,video/*'
    : 'video/mp4,video/x-m4v,video/*,audio/*'
  const buttonText = audioOnly ? 'Upload Audio' : videoOnly ? 'Upload Video' : 'Upload Video/Audio'

  return (
    <FileSelectorButton
      accept={accept}
      onSelectedFileChange={onSelectedFileChange}
      className={!isMediaRecorderSupported ? 'primary outline' : undefined}
    >
      <UploadIcon />
      {buttonText}
    </FileSelectorButton>
  )
}

export default UploadVideoAudioButton
