import React from 'react'
import { ContentBlockField } from 'state/types'
import { lookupIcon } from 'helpers/lookupIcon'
import { parseWebsiteLink, validateWebsiteLink } from 'helpers/validation'
import { createFullLink, formatDisplayedLink } from 'helpers/links'
import './PublishedPreviewWebsiteLinks.scss'

type Props = {
  hasLabel: boolean
  fields: ContentBlockField[]
  trackLinkClick: (fieldId: string) => void
}

const PublishedPreviewWebsiteLinks: React.FC<Props> = ({ hasLabel, fields, trackLinkClick }) => {
  const anyIconsShowing = fields?.some(
    field => validateWebsiteLink(field.value) && !!lookupIcon(parseWebsiteLink(field.value).icon)
  )

  return (
    <div className={'website-links' + (hasLabel ? ' has-label' : '')}>
      {fields.map(field => {
        const { icon, url } = parseWebsiteLink(field.value)
        const valid = validateWebsiteLink(field.value)
        const iconNode = lookupIcon(icon)
        return valid ? (
          <div key={field.id} className="website-link">
            {iconNode || (anyIconsShowing ? <div className="icon-placeholder" /> : null)}
            <a
              href={createFullLink(url)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackLinkClick(field.id)}
            >
              {formatDisplayedLink(url)}
            </a>
          </div>
        ) : null
      })}
    </div>
  )
}

export default PublishedPreviewWebsiteLinks
