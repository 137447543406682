import { getWorkspaceOrganizationId } from 'helpers/organization'
import { getConcatenatedTitlesForPreviewLite } from 'helpers/titles'
import {
  AnalyticsEvent,
  AnalyticsPreviewStatus,
  LinkAnalytics,
  PreviewAnalyticsRange,
  PreviewLite,
  PreviewViewAnalytic,
  PreviewViewsByCategory,
  PreviewViewsByDay,
  PreviewViewsOverTime,
  VideoAnalytics,
  VideoChapter,
} from 'state/types'
import { getApiWithAuthorization } from './api'

const timeZoneOffset = new Date().getTimezoneOffset() / 60

const getDuration = (range: PreviewAnalyticsRange) => {
  switch (range) {
    case 'last-24-hours':
      return 'LAST_24_HOURS'
    case 'last-7-days':
      return 'LAST_7_DAYS'
    case 'last-30-days':
      return 'LAST_30_DAYS'
    case 'last-90-days':
      return 'LAST_90_DAYS'
    case 'all-time':
      return 'ALL_TIME'
    default:
      return null
  }
}

const getTimeParams = (range: PreviewAnalyticsRange) =>
  `timeZoneOffset=${timeZoneOffset}&duration=${getDuration(range)}`

export const getAnalyticsSummary = async (
  range: PreviewAnalyticsRange,
  previewId?: string,
  analyticsPreviewStatus?: AnalyticsPreviewStatus
) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()

  return apiWithAuth.get<AnalyticsEvent[]>(
    `/api/analytics/summary?${getTimeParams(range)}` +
      (previewId !== undefined ? `&previewId=${previewId}` : '') +
      (analyticsPreviewStatus !== undefined ? `&analyticsPreviewStatus=${analyticsPreviewStatus}` : '') +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getTopVideos = async (range: PreviewAnalyticsRange, analyticsPreviewStatus?: AnalyticsPreviewStatus) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  const { ok, data } = await apiWithAuth.get<
    {
      preview: PreviewLite
      videoChapter: VideoChapter
      viewCount: number
      engagementPercentage: number
      numberOfTimesWatchInFull: number
    }[]
  >(
    `/api/analytics/topVideos?${getTimeParams(range)}` +
      (analyticsPreviewStatus !== undefined ? `&analyticsPreviewStatus=${analyticsPreviewStatus}` : '') +
      (workspaceOrganizationId !== undefined
        ? (analyticsPreviewStatus !== undefined ? `&` : `?`) + `organizationId=${workspaceOrganizationId}`
        : '')
  )

  return {
    ok,
    data: data?.map(d => {
      const previewName = getConcatenatedTitlesForPreviewLite(d.preview)
      const firstPublishDate = d.preview.firstPublishDate
      const previewViewsOverTime: VideoAnalytics = {
        previewId: d.preview.id,
        previewName,
        firstPublishDate,
        videoChapter: d.videoChapter,
        viewCount: d.viewCount,
        engagementPercentage: d.engagementPercentage,
        timesWatchedInFull: d.numberOfTimesWatchInFull,
      }
      return previewViewsOverTime
    }),
  }
}

export const getVideoChapterAnalytics = async (range: PreviewAnalyticsRange, videoChapterId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.get<AnalyticsEvent[]>(
    `/api/analytics/videoChapter/${videoChapterId}?${getTimeParams(range)}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const isPreviewViewedByInviter = async (previewId: string) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<boolean>(
    `/api/analytics/${previewId}/hasBeenViewedByInviter?timeZoneOffset=${timeZoneOffset}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getPreviewViewsOverTime = async (range: PreviewAnalyticsRange, previewId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const { ok, data } = await apiWithAuth.get<{ day: string; count: number }[]>(
    `/api/analytics/previewViewsOverTime?previewId=${previewId}&${getTimeParams(range)}${
      workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : ''
    }`
  )

  return {
    ok,
    data: data?.map(d => {
      const day = new Date(new Date(d.day).getTime() + timeZoneOffset * 60 * 60 * 1000)
      const viewsByDay: PreviewViewsByDay = { ...d, day }
      return viewsByDay
    }),
  }
}

export const getTopPreviewViewsOverTime = async (
  range: PreviewAnalyticsRange,
  analyticsPreviewStatus?: AnalyticsPreviewStatus
) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  const { ok, data } = await apiWithAuth.get<
    { preview: PreviewLite; eventCountsByDate: { day: string; count: number }[] }[]
  >(
    `/api/analytics/topPreviewViewsOverTime?${getTimeParams(range)}` +
      (analyticsPreviewStatus !== undefined ? `&analyticsPreviewStatus=${analyticsPreviewStatus}` : '') +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )

  return {
    ok,
    data: data?.map(d => {
      const previewId = d.preview.id
      const previewName = getConcatenatedTitlesForPreviewLite(d.preview)
      const counts =
        d.eventCountsByDate?.map(d => {
          const day = new Date(new Date(d.day).getTime() + timeZoneOffset * 60 * 60 * 1000)
          const viewsByDay: PreviewViewsByDay = { ...d, day }
          return viewsByDay
        }) || []
      const previewViewsOverTime: PreviewViewsOverTime = { previewId, previewName, counts }
      return previewViewsOverTime
    }),
  }
}

export const getPreviewViewsByLocation = async (
  range: PreviewAnalyticsRange,
  previewId?: string,
  analyticsPreviewStatus?: AnalyticsPreviewStatus
) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<PreviewViewsByCategory[]>(
    `/api/analytics/topPreviewViewsByLocation?${getTimeParams(range)}` +
      (previewId !== undefined ? `&previewId=${previewId}` : '') +
      (analyticsPreviewStatus !== undefined ? `&analyticsPreviewStatus=${analyticsPreviewStatus}` : '') +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getPreviewViewsByPlatform = async (
  range: PreviewAnalyticsRange,
  previewId?: string,
  analyticsPreviewStatus?: AnalyticsPreviewStatus
) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<PreviewViewsByCategory[]>(
    `/api/analytics/topPreviewViewsByPlatform?${getTimeParams(range)}` +
      (previewId !== undefined ? `&previewId=${previewId}` : '') +
      (analyticsPreviewStatus !== undefined ? `&analyticsPreviewStatus=${analyticsPreviewStatus}` : '') +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getPreviewViewsByReferrer = async (
  range: PreviewAnalyticsRange,
  previewId?: string,
  analyticsPreviewStatus?: AnalyticsPreviewStatus
) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<PreviewViewsByCategory[]>(
    `/api/analytics/topPreviewViewsByReferrer?${getTimeParams(range)}` +
      (previewId !== undefined ? `&previewId=${previewId}` : '') +
      (analyticsPreviewStatus !== undefined ? `&analyticsPreviewStatus=${analyticsPreviewStatus}` : '') +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getPreviewDocumentDownloads = async (range: PreviewAnalyticsRange, contentBlockFieldId?: string) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<AnalyticsEvent[]>(
    `/api/analytics/document/${contentBlockFieldId}?${getTimeParams(range)}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getPreviewLinkClicks = async (range: PreviewAnalyticsRange, previewId?: string) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<LinkAnalytics[]>(
    `/api/analytics/links/${previewId}?${getTimeParams(range)}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getPreviewsViewAnalytic = async (previewIds?: string) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<PreviewViewAnalytic[]>(
    `/api/analytics/previewViews?previewIds=${previewIds}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}
