import { Box, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { AddCircleOutline, ChevronLeft } from '@material-ui/icons'
import WorkspaceInfo from 'components/shared/WorkspaceInfo/WorkspaceInfo'
import { isOnNavLink } from 'helpers/links'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Workspace } from 'state/types'
import './WorkspaceMenuButton.scss'
import ProfileImage from '../../shared/ProfileImage/ProfileImage'
import { getProfileImageUrl } from '../../../helpers/cloudinary'

type Props = {
  currentWorkspace: Workspace
  allWorkspaces: Workspace[]
  setWorkspace: (workspace: Workspace) => void
}

const WorkspaceMenuButton: React.FC<Props> = ({ currentWorkspace, allWorkspaces, setWorkspace }) => {
  const { push } = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleCreateNewOrganization = () => {
    push(
      `/welcome/organization?onboardingAdditionalOrganization=true&from=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`
    )
    setAnchorEl(null)
  }

  const switchWorkspace = (workspace: Workspace) => {
    setWorkspace(workspace)
    setAnchorEl(null)
    if (isOnNavLink(window.location.pathname)) {
      window.location.reload()
    } else {
      push('/')
    }
  }

  return (
    <div>
      <div onClick={handleMenu} className="workspace-menu-button">
        <div className="workspace-info-tablet">
          <WorkspaceInfo workspace={currentWorkspace} />
        </div>
        <div className="workspace-info-desktop">
          <WorkspaceInfo workspace={currentWorkspace} showProfileImage />
        </div>
        <ChevronLeft className="icon" />
      </div>
      <Menu
        id="menu-appbar"
        className="workspace-switcher"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoFocus={false}
        onClose={() => {
          handleMenuClose()
        }}
      >
        {allWorkspaces.map(workspace => {
          return (
            workspace.id !== currentWorkspace.id && (
              <Tooltip title={workspace.name} key={workspace.id}>
                <Box borderBottom={1}>
                  <MenuItem
                    onClick={() => {
                      switchWorkspace(workspace)
                    }}
                  >
                    <div className="workspace-option">
                      <div className="workspace-image">
                        <ProfileImage
                          imageUrl={
                            workspace.profileImageStorageId
                              ? getProfileImageUrl(workspace.profileImageStorageId)
                              : undefined
                          }
                          cssClassName={'display-image'}
                          name={workspace.name}
                        />
                      </div>
                      <div className="workspace-name">
                        <label>{workspace.name}</label>
                      </div>
                    </div>
                  </MenuItem>
                </Box>
              </Tooltip>
            )
          )
        })}
        <MenuItem
          onClick={() => {
            handleCreateNewOrganization()
          }}
        >
          <div className="create-new-organization-button">
            <AddCircleOutline className="icon" />
            <label>Create New Workspace</label>
          </div>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default WorkspaceMenuButton
