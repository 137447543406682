import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import React, { useEffect, useState } from 'react'
import { PreviewAlgolia, TagOption } from '../../../state/types'
import { updatePreviewTags } from '../../../services/previewService'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import './PreviewUpdateTagsModal.scss'
import DashboardTagFieldEditorAlgolia from '../TagFieldEditor/DashboardTagFieldEditorAlgolia'

interface Props {
  isShowing: boolean
  hideModal: () => void
  preview: PreviewAlgolia
  onUpdateTagsSuccess: (previewId: string, newSelectedTagIds: string[]) => void
  tagOptions: TagOption[]
}

const PreviewUpdateTags = function ({ preview, isShowing, tagOptions, hideModal, onUpdateTagsSuccess }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTagIds, setSelectedTagIds] = useState(preview?.tagIds || [])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!preview) return
    setIsLoading(true)
    if (preview.organizationId) {
      await updatePreviewTags(preview.id, preview.organizationId, selectedTagIds)
      setIsLoading(false)
      onUpdateTagsSuccess(preview.id, selectedTagIds)
      hideModal()
    }
  }

  const onClose = function () {
    hideModal()
  }

  useEffect(() => {
    setSelectedTagIds(preview?.tagIds || [])
  }, [preview?.tagIds])

  return (
    <CloseableDialog isShowing={isShowing} className="overflow-visible" onClose={onClose}>
      <div className="preview-update-tags-modal">
        <h2>Preview: {preview.title}</h2>
        <form>
          <div className="tag-field-container">
            <span>Tags: </span>
            <DashboardTagFieldEditorAlgolia
              preview={preview}
              tagOptions={tagOptions}
              selectedOptions={selectedTagIds}
              setSelectedOptions={setSelectedTagIds}
            />
          </div>
          <LoadingButton isLoading={isLoading} className="primary" onClick={handleSubmit}>
            Update
          </LoadingButton>
        </form>
      </div>
    </CloseableDialog>
  )
}

export default PreviewUpdateTags
