import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { parseIndexParam } from 'helpers/urlParams'
import * as documentService from 'services/documentService'
import useStore from 'state/useStore'
import useContentBlock from 'state/useContentBlock'

const useDocumentAssets = () => {
  const { state, dispatch } = useStore()
  const { previewId, index } = useParams<any>()
  const blockIndex = parseIndexParam(index)
  const { isLoading: isBlockLoading, contentBlock } = useContentBlock(previewId, blockIndex)
  const hasEmptyDocumentCard =
    contentBlock &&
    contentBlock.contentBlockFields.length > 0 &&
    contentBlock.contentBlockFields.some(f => f.controlType === 'File' && !!!f.value)

  // Only show video assets if (a) there are assets to copy, and (b) there are empty document cards to copy to or new chapters can be created
  const showDocumentAssets = state.hasVideoAssets && (hasEmptyDocumentCard || contentBlock?.editable)

  useEffect(() => {
    const loadAssets = async () => {
      if (contentBlock) {
        if (contentBlock.contentBlockType.name === 'Documents') {
          dispatch({ type: 'SET_DOCUMENT_ASSETS_LOADING', value: true })
          const { ok, data } = await documentService.getAllUploadedFile()
          if (ok && data) {
            const documentAssets = data.filter(previewDocument => previewDocument.previewLite.id !== previewId)
            dispatch({ type: 'SET_HAS_DOCUMENT_ASSETS', value: documentAssets.length > 0 })
            dispatch({ type: 'SET_DOCUMENT_ASSETS', value: documentAssets })
          } else {
            dispatch({ type: 'SET_HAS_DOCUMENT_ASSETS', value: false })
          }
          dispatch({ type: 'SET_DOCUMENT_ASSETS_LOADING', value: false })
        } else {
          dispatch({ type: 'SET_HAS_DOCUMENT_ASSETS', value: false })
        }
      }
    }
    loadAssets()
  }, [contentBlock, previewId, dispatch])

  useEffect(() => {
    if (!previewId) {
      dispatch({ type: 'SET_HAS_DOCUMENT_ASSETS', value: false })
    }
  }, [previewId, dispatch])

  return {
    isLoading: isBlockLoading || state.areDocumentAssetsLoading,
    showDocumentAssets,
    documentAssets: state.documentAssets,
    documentBlock: contentBlock,
  }
}

export default useDocumentAssets
