import React, { useCallback, useEffect, useState } from 'react'
import FileSelector from '../Components/FileSelector/FileSelector'
import { SubscriptionPlan } from '../../../../helpers/enum'
import { Link } from 'react-router-dom'
import './Watermark.scss'
import InputType from '../Components/InputType/InputType'
import useWatermark from '../../../../state/useWatermark'
import {
  watermarkImageFileSizeLimitInBytes,
  watermarkImageFileSizeLimitInKb
} from '../../../../helpers/media'
import ConfirmationDialog from '../../../shared/ConfirmationDialog/ConfirmationDialog'
import useStore from '../../../../state/useStore'

type Props = {
plan: string
}

function Watermark({plan} : Props) {
  const {
    setWatermarkImage,
    setWatermarkOpacity,
    setWatermarkCoords,
    watermarkImage,
    watermarkOpacity,
    updateWatermarkImageLocally,
    updateWatermarkOpacity,
  } = useWatermark()

  const { state } = useStore()
  const currentPlan = state.subscription?.plan.name
  const isOnBasicPlan = String(plan) === SubscriptionPlan.Basic
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)
  const onSelectedFileChange = async (file: File | null) => {
     if (file) {
      if (file.size > watermarkImageFileSizeLimitInBytes) {
        setFileSizeErrorDialogShowing(true)
      } else {
        setUploading(true)
        setProgress(0)
        await updateWatermarkImageLocally(file, setProgress)
        setUploading(false)
      }

    }
  }
  const onDeleteImageClick = async () => {
    setWatermarkImage("")
    setWatermarkOpacity(100)
  }
  const onOpacityChange = (value: number) => {
    updateWatermarkOpacity(value)
  }

  useEffect(() => {
    if(!watermarkOpacity){
      setWatermarkOpacity(100)
    }
  }, [])
  return (
    <div className='watermark'>
      {isOnBasicPlan &&
        <div className='upgrade-container'>
          Upgrade to use premium features
          <Link className='upgrade-button button' to={`/plan/select?from=${encodeURIComponent(window.location.pathname + window.location.search)}`}>
            VIEW UPGRADE OPTIONS
          </Link>
        </div>
      }
      <div>
        <FileSelector
          onSelectedFileChange={onSelectedFileChange}
          onDeleteImageClick={onDeleteImageClick}
          value={state.watermarkData?.cloudinaryId}
          disable={isOnBasicPlan}
          isUploading={uploading}
          uploadProgress={progress}
          imageFormat={"watermark"}
        />
      </div>
      {(watermarkImage && !isOnBasicPlan) &&
        <div className='opacity-container'>
          <p className='opacity-text'>Opacity:</p>
          <InputType
            unit={'percent'}
            onOpacityChange={(value) => onOpacityChange(Number(value))}
            opacity={String(state.watermarkData?.opacity)}
            setOpacity={setWatermarkOpacity}
            disabled={isOnBasicPlan}
          />
        </div>
      }
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Image too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
      >
        {`This image is too large. Please choose an image file smaller than ${watermarkImageFileSizeLimitInKb} KB.`}
      </ConfirmationDialog>

    </div>

  )
}

export default Watermark