import AccountSettingsPage from 'components/account/AccountSettingsPage/AccountSettingsPage'
import OrganizationProfilePage from 'components/account/OrganizationProfilePage/OrganizationProfilePage'
import AnalyticsPage from 'components/analytics/AnalyticsPage/AnalyticsPage'
import AnalyticsPreviewPage from 'components/analytics/AnalyticsPreviewPage/AnalyticsPreviewPage'
import DesktopNav from 'components/app/DesktopNav/DesktopNav'
import MobileNav from 'components/app/MobileNav/MobileNav'
import OnboardedRoute from 'components/app/OnboardedRoute/OnboardedRoute'
import EditInvitationPage from 'components/invitation/EditInvitationPage/EditInvitationPage'
import InvitationDetailPage from 'components/invitation/InvitationDetailPage/InvitationDetailPage'
import InvitationsPage from 'components/invitation/InvitationsPage/InvitationsPage'
import DashboardWrapper from 'components/preview/Dashboard/DashboardWrapper'
import PreviewEditorPage from 'components/preview/PreviewEditorPage/PreviewEditorPage'
import { WorkspaceType } from 'helpers/enum'
import { getAllOrganizationWorkspaces, getCurrentWorkspace } from 'helpers/organization'
import React, { useState } from 'react'
import { Switch } from 'react-router-dom'
import useWorkspace from 'state/useWorkspace'
import AppHeader from '../AppHeader/AppHeader'
import OrganizationPlanBillingPage from '../../account/OrganizationPlanBillingPage/OrganizationPlanBillingPage'
import TeamMembersPage from '../../account/TeamMembersPage/TeamMembersPage'
import OnboardingModal from '../OnboardingModal/OnboardingModal'
import { ModalHighlight } from '../../../state/types'
import InvitationDetailPageWrapper from 'components/invitation/InvitationDetailPage/InvitationDetailPageWrapper'
import IntegrationsPage from '../../account/IntegrationsPage/IntegrationsPage'
import IntegrationDetailPage from '../../account/IntegrationDetailPage/IntegrationDetailPage'
import BrandSettingsPage from '../../account/BrandSettingsPage/BrandSettingsPage'
import PermissionsPage from '../../account/PermissionsPage/PermissionsPage'

export const paths = [
  '/',
  '/account',
  '/account/password',
  '/account/plan',
  '/edit/:previewId',
  '/edit/:previewId/title',
  '/edit/:previewId/instructions',
  '/edit/:previewId/block/:index',
  '/edit/:previewId/publish',
  '/invitations',
  '/invitation/:invitationId',
  '/invitation/:invitationId/edit',
  '/analytics',
  '/analytics/breakdown',
  '/analytics/p/:previewId',
  '/organization/settings/profile',
  '/organization/settings/planBilling',
  '/organization/settings/teamMembers',
  '/invitation/:organizationId/:invitationId',
  '/invitation/:organizationId/:invitationId/edit',
  '/organization/settings/integrations',
  '/organization/settings/integrations/:integrationId',
  '/organization/settings/brand',
  '/organization/settings/teamMembers/permissions'
]

// These routes all have the left-nav showing.
const NavRoutes = () => {
  const { organizationsLoaded, setCurrentWorkspace } = useWorkspace(false)
  const allOrganizationWorkspaces = getAllOrganizationWorkspaces()
  const currentWorkspace = getCurrentWorkspace()
  const isWorkspaceTypeOrganization = currentWorkspace?.type === WorkspaceType.Organization
  const [showOnboardingModal, setShowOnboardingModal] = useState(localStorage.getItem('onboarding') !== null)
  const [modalHighlight, setModalHighlight] = useState<ModalHighlight | undefined>()

  const onboardingModalClose = function () {
    localStorage.removeItem('onboarding')
    setShowOnboardingModal(false)
    setModalHighlight(undefined)
  }

  return (
    <>
      {showOnboardingModal && (
        <OnboardingModal
          onClose={() => onboardingModalClose()}
          isWorkspaceTypeOrganization={isWorkspaceTypeOrganization}
          setModalHighlight={setModalHighlight}
        />
      )}

      <AppHeader modalProfileHighlight={modalHighlight === 'previewme-settings'} />
      <MobileNav
        isWorkspaceTypeOrganization={isWorkspaceTypeOrganization}
        currentWorkspace={currentWorkspace}
        allWorkspaces={allOrganizationWorkspaces}
        setWorkspace={setCurrentWorkspace}
      />
      <DesktopNav
        isWorkspaceTypeOrganization={isWorkspaceTypeOrganization}
        currentWorkspace={currentWorkspace}
        allWorkspaces={allOrganizationWorkspaces}
        setWorkspace={setCurrentWorkspace}
        modalHighlight={modalHighlight}
      />
      <Switch>
        <OnboardedRoute exact path={paths[0]} component={DashboardWrapper} />
        <OnboardedRoute exact path={paths.slice(1, 4)} component={AccountSettingsPage} />
        <OnboardedRoute exact path={paths.slice(4, 9)} component={PreviewEditorPage} />
        <OnboardedRoute exact path={paths[9]} component={InvitationsPage} />
        <OnboardedRoute exact path={paths[10]} component={InvitationDetailPageWrapper} />
        <OnboardedRoute exact path={paths[11]} component={EditInvitationPage} />
        <OnboardedRoute exact path={paths.slice(12, 14)} component={AnalyticsPage} />
        <OnboardedRoute exact path={paths[14]} component={AnalyticsPreviewPage} />
        <OnboardedRoute exact path={paths[15]} component={OrganizationProfilePage} />
        <OnboardedRoute exact path={paths[16]} component={OrganizationPlanBillingPage} />
        <OnboardedRoute exact path={paths[17]} component={TeamMembersPage} />
        <OnboardedRoute exact path={paths[18]} component={InvitationDetailPageWrapper} />
        <OnboardedRoute exact path={paths[19]} component={EditInvitationPage} />
        <OnboardedRoute exact path={paths[20]} component={IntegrationsPage} />
        <OnboardedRoute exact path={paths[21]} component={IntegrationDetailPage} />
        <OnboardedRoute exact path={paths[22]} component={BrandSettingsPage} />
        <OnboardedRoute exact path={paths[23]} component={PermissionsPage} />
      </Switch>
    </>
  )
}

export default NavRoutes
