import React from 'react'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import './LimitReachedDialog.scss'
import { PreviewCategory as PreviewCategoryType } from 'state/types'
import { MaxPreviewsByCategoryForFree, PreviewCategory as PreviewCategoryEnum } from 'helpers/enum'
import { useHistory } from 'react-router-dom'

type Props = {
  category: PreviewCategoryType
  isShowing: boolean
  showSaveAsDraft: boolean
  onClose: () => void
}

const LimitReachedDialog: React.FC<Props> = ({ category, isShowing, showSaveAsDraft, onClose }) => {
  const { push } = useHistory()

  const savePreviewAsDraft = () => {
    push('/')
  }

  const upgradeNow = () => {
    push(`/plan/select?from=${encodeURIComponent(window.location.pathname + window.location.search)}`)
  }
  return (
    <CloseableDialog className={'limit-reached-dialog'} isShowing={isShowing} onClose={onClose}>
      <h2>You've reached your limit!</h2>
      {category === PreviewCategoryEnum.Business ? (
        <>
          <p>
            Your Free Plan lets you publish <b>a total of {MaxPreviewsByCategoryForFree.Business} Business Preview</b> -
            you're currently maxed out!
          </p>
          <p>Upgrade or unpublish an existing Business Preview to proceed</p>
        </>
      ) : category === PreviewCategoryEnum.Individual ? (
        <>
          <p>
            Your Free Plan lets you publish{' '}
            <b>a total of {MaxPreviewsByCategoryForFree.Individual} Individual Previews</b> - you're currently maxed
            out!
          </p>
          <p>Upgrade or unpublish an existing Individual Preview to proceed</p>
        </>
      ) : (
        ''
      )}
      <button className="button primary upgrade-button" onClick={upgradeNow}>
        Upgrade now
      </button>
      {showSaveAsDraft && (
        <p className="continue-button" onClick={savePreviewAsDraft}>
          <b>Ok, save as draft</b>
        </p>
      )}
    </CloseableDialog>
  )
}

export default LimitReachedDialog
