import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { BillingType, SubscriptionPlan } from 'helpers/enum'
import { getCurrentWorkspace, getWorkspaceOrganizationFreeTrial } from 'helpers/organization'
import { getCurrentPlan } from 'helpers/subscription'
import { useUrlParams } from 'helpers/urlParams'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as subscriptionService from 'services/subscriptionService'
import { Price, Product } from 'state/types'
import useProduct from 'state/useProduct'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import PlanCard from '../PlanCard/PlanCard'
import './PlanSelectionPage.scss'
import AuthorizedPage from '../../auth/AuthorizedPage/AuthorizedPage'
import UnauthorizedPage from '../../auth/UnauthorizedPage/UnauthorizedPage'
import useStore from '../../../state/useStore'

const PlanSelectionPage = () => {
  const { dispatch } = useStore()
  const currentWorkspace = getCurrentWorkspace()
  const { allProducts } = useProduct(true)
  const { isSubscriptionLoading, subscription, getPriceIndex, editedSubscriptionName, newProduct }
    = useSubscription(true,false, undefined, false, allProducts)
  const revisedAllProducts = allProducts?.splice(5,0)
  const { push } = useHistory()
  const { from } = useUrlParams('from')
  const search = window.location.search
  const workspaceOrganizationFreeTrial = getWorkspaceOrganizationFreeTrial()
  const [togglePage, setTogglePage] = useState(1)
  const plan = getCurrentPlan(subscription)

  const onSelect = async (newProduct?: Product, newPrice?: Price) => {
    if (plan === SubscriptionPlan.Basic && newProduct && newPrice) {
      await subscriptionService.redirectToStripeCheckout(
        newProduct.id,
        newPrice.id,
        search.substring(1),
        window.location.pathname + window.location.search,
        editedSubscriptionName(),
        togglePage,
        newProduct

      )
    } else if (plan && newProduct) {
      push(`/plan/change?to=${newProduct.id}&${search.substring(1)}&toggle=${togglePage}`)
    }
  }

  useEffect(() => {
    dispatch({ type: 'SET_BILLING_TYPE', value: togglePage === 1 ? BillingType.Monthly : BillingType.Annual })
  }, [togglePage])



  const hasMatchingId = allProducts && allProducts.some(product => product.id === subscription?.plan.id);
  const getPreviousPlan = (title: string) => {
    switch (title){
      case 'Basic': return ""
      case 'Solo': return 'Basic'
      case 'Professional': return 'Solo'
      case 'Business': return 'Solo'
      case 'Enterprise': return 'Business'
      case 'Solo Annual': return 'Basic'
      case 'Professional Annual': return 'Solo Annual'
      case 'Business Annual': return 'Solo Annual'
      case 'Enterprise Annual': return 'Business Annual'
      default: break
    }
  }

  const RestrictedPlanPage = () => {
    return <UnauthorizedPage
      body='Please contact a team member with Owner or Admin permissions to upgrade your Workspace.'
      previousPath={from} />
  }


  return (
    <AuthorizedPage UnauthorizedComponent={RestrictedPlanPage}>
      <FullScreenPageFrame className="plan-selection-page" cancelLink={from} title="Select a plan">
        {/*Button for changing to annual is disabled and hidden for now*/}
        {/*<div className={'plan-toggle'}>*/}
        {/*  <button className={`toggle-button ${togglePage === 1 ? ' active':''}`} onClick={() => setTogglePage(1)}>Monthly</button>*/}
        {/*  <button className={`toggle-button ${togglePage === 2 ? ' active':''}`} onClick={() => setTogglePage(2)}>Annual</button>*/}
        {/*</div>*/}
      {isSubscriptionLoading && (!hasMatchingId) && allProducts? (
        <LoadingSpinner fullScreen />
      ) : allProducts && allProducts.length > 0 ? (
        <div className="plan-cards">
          {(subscription && !hasMatchingId) &&
            <PlanCard
              key={subscription?.id}
              title={subscription?.plan.name}
              accountType={subscription?.plan.type}
              subtitle={subscription?.plan.subtitle}
              showVAT={false}
              currency={'US$'}
              price={subscription?.plan.prices[0].amount}
              previousPlanName={getPreviousPlan(subscription.plan.name)}
              frequency="/month"
              theme={subscription?.plan.name}
              freeTrial={
                subscription?.plan.name !== SubscriptionPlan.Basic && workspaceOrganizationFreeTrial
                  ? workspaceOrganizationFreeTrial
                  : false
              }
              current={getCurrentPlan(subscription) === subscription?.plan.name}
              features={subscription?.plan.description}
              onSelect={() => onSelect(subscription?.plan, subscription?.plan.prices[0])}
              currentPlan={true}
              currentWorkspace={currentWorkspace}
            />
          }
          {allProducts.map((product, index) => {
            const selectedPrice =  product.prices[0];
            return (
              <PlanCard
                key={product.id}
                title={(togglePage === 2 && product.name !== SubscriptionPlan.Basic) ? product.name + " Annual" : product.name}
                accountType={product.type}
                subtitle={product.subtitle}
                previousPlanName={getPreviousPlan(togglePage === 2 ? product.name + " Annual" : product.name)}
                currency={'US$'}
                price={product.prices[0].amount}
                frequency="/month"
                theme={product.name}
                freeTrial={
                  product.name !== SubscriptionPlan.Basic && workspaceOrganizationFreeTrial
                    ? workspaceOrganizationFreeTrial
                    : false
                }
                current={getCurrentPlan(subscription) === product.name && subscription?.productId === product.id}
                features={product.description}
                onSelect={() => onSelect(product, selectedPrice)}
                togglePage={togglePage}
                currentWorkspace={currentWorkspace}
              />
            )
          })}
        </div>
      ) : (
        ''
      )}
    </FullScreenPageFrame>
    </AuthorizedPage>
  )
}

export default PlanSelectionPage
