import React, { useEffect, useState } from 'react'
import useSubscription from '../../../state/useSubscription'
import InvitationForm from '../InvitationForm/InvitationForm'
import { SubscriptionPlan } from '../../../helpers/enum'
import useProduct from '../../../state/useProduct'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'
import InvitationBrandingForm from '../InvitationBrandingForm/InvitationBrandingForm'
import InvitationReview from '../InvitationReview/InvitationReview'
import {
  InvitationEditorStep,
  TempInvitation,
  TempInvitationSuccessPageInfo,
  VideoCategoryTemplate,
} from '../../../state/types'
import { getBusinessLogoImageUrl } from '../../../helpers/cloudinary'
import { getWorkspaceOrganizationId } from '../../../helpers/organization'
import InvitationSuccessPageForm from '../InvitationSuccessPageForm/InvitationSuccessPageForm'
import useStore from '../../../state/useStore'
import InvitationVideoChaptersForm from '../InvitationVideoChaptersForm/InvitationVideoChaptersForm'

type Props = {
  templateId?: string
  editingDefaultInvitationDetailsValues?:
    | {
        name: string
        ownerName: string
        industries: string | undefined
        startDate: Date | undefined
        endDate: Date | undefined
        instructions: string | undefined
        sendEmailToInviter: boolean | undefined
      }
    | undefined
  editingBrandingImageStorageId?: string
  invitationId?: string
  editingDefaultInvitationSuccessPageValues?:
    | { title?: string; message?: string; returnLinkTitle?: string; returnLink?: string; logo?: string }
    | undefined
  editingVideoCategoryTemplates?: VideoCategoryTemplate[]
}

const InvitationEditor: React.FC<Props> = ({
  templateId,
  editingDefaultInvitationDetailsValues,
  invitationId,
  editingBrandingImageStorageId,
  editingDefaultInvitationSuccessPageValues,
  editingVideoCategoryTemplates,
}) => {
  const { state } = useStore()
  const disableSubscriptionLoading = state.subscription?.plan.name === SubscriptionPlan.Enterprise
  const { allProducts } = useProduct(!disableSubscriptionLoading)
  const enterpriseProductId = allProducts
    ? allProducts.find(product => product.name === SubscriptionPlan.Enterprise)?.id
    : undefined
  const { newProduct, isPreviewSubscriptionLoading, newSubscription, changePlan } = useSubscription(
    enterpriseProductId !== undefined && !disableSubscriptionLoading,
    !disableSubscriptionLoading,
    enterpriseProductId
  )

  const [showEnterpriseFeatures, setShowEnterpriseFeatures] = useState<boolean>(false)
  const [currentInvitationEditorStepIndex, setCurrentInvitationEditorStepIndex] = useState<number>(0)
  const [invitationEditorSteps, setInvitationEditorSteps] = useState<InvitationEditorStep[]>([])
  const [tempInvitation, setTempInvitation] = useState<TempInvitation>()
  const [updatedBrandingImage, setUpdatedBrandingImage] = useState(false)
  const [updatedSuccessPageLogoImage, setUpdatedSuccessPageLogoImage] = useState(false)
  const [isEnterpriseCheckLoading, setEnterpriseCheckLoading] = useState<boolean>(true)

  useEffect(() => {
    const workspaceOrganizationId = getWorkspaceOrganizationId()
    if (!workspaceOrganizationId) {
      return
    }

    if (editingDefaultInvitationDetailsValues) {
      const editingTempInvitation: TempInvitation = {
        name: editingDefaultInvitationDetailsValues.name,
        ownerName: editingDefaultInvitationDetailsValues.ownerName,
        industries: editingDefaultInvitationDetailsValues.industries,
        startDate: editingDefaultInvitationDetailsValues.startDate
          ? new Date(editingDefaultInvitationDetailsValues.startDate)
          : undefined,
        endDate: editingDefaultInvitationDetailsValues.endDate
          ? new Date(editingDefaultInvitationDetailsValues.endDate)
          : undefined,
        instructions: editingDefaultInvitationDetailsValues.instructions,
        sendEmailToInviter: editingDefaultInvitationDetailsValues.sendEmailToInviter,
        organizationId: workspaceOrganizationId,
        existingBrandingImageId: editingBrandingImageStorageId,
      }

      if (editingBrandingImageStorageId) {
        fetch(getBusinessLogoImageUrl(editingBrandingImageStorageId))
          .then(image => {
            return image.blob()
          })
          .then(image => {
            editingTempInvitation.brandingImage = image
          })
      }

      if (editingDefaultInvitationSuccessPageValues) {
        const editingTempInvitationSuccessPageInfo: TempInvitationSuccessPageInfo = {
          ...editingDefaultInvitationSuccessPageValues,
          logo: undefined,
          existingLogoImageId: editingDefaultInvitationSuccessPageValues.logo,
        }

        if (editingDefaultInvitationSuccessPageValues.logo) {
          fetch(getBusinessLogoImageUrl(editingDefaultInvitationSuccessPageValues.logo))
            .then(image => {
              return image.blob()
            })
            .then(image => {
              editingTempInvitationSuccessPageInfo.logo = image
            })
        }
        editingTempInvitation.invitationSuccessPage = editingTempInvitationSuccessPageInfo
      }

      editingTempInvitation.videoCategoryTemplates = editingVideoCategoryTemplates

      setTempInvitation(editingTempInvitation)
    }
  }, [
    editingDefaultInvitationDetailsValues,
    editingVideoCategoryTemplates,
    editingBrandingImageStorageId,
    editingDefaultInvitationSuccessPageValues,
  ])

  useEffect(() => {
    if (state.subscription?.plan.name) {
      setShowEnterpriseFeatures(
        state.subscription.plan.name === SubscriptionPlan.Enterprise ||
          state.subscription.plan.name === SubscriptionPlan.Professional ||
        state.subscription.plan.name === SubscriptionPlan.Business
      )
      setEnterpriseCheckLoading(false)
    }
  }, [state.subscription?.plan.name])

  useEffect(() => {
    setInvitationEditorSteps([
      {
        blockTitle: 'Invitation Details',
        isActive: 0 === currentInvitationEditorStepIndex,
        isComplete: 0 < currentInvitationEditorStepIndex,
        isIncomplete: 0 > currentInvitationEditorStepIndex,
      },
      {
        blockTitle: 'Specify Video Content',
        isActive: 1 === currentInvitationEditorStepIndex,
        isComplete: 1 < currentInvitationEditorStepIndex,
        isIncomplete: 1 > currentInvitationEditorStepIndex,
      },
      {
        blockTitle: 'Add your Branding',
        isActive: 2 === currentInvitationEditorStepIndex,
        isComplete: 2 < currentInvitationEditorStepIndex,
        isIncomplete: 2 > currentInvitationEditorStepIndex,
      },
      {
        blockTitle: 'Create Success Page',
        isActive: 3 === currentInvitationEditorStepIndex,
        isComplete: 3 < currentInvitationEditorStepIndex,
        isIncomplete: 3 > currentInvitationEditorStepIndex,
      },
      {
        blockTitle: 'Review & Confirm',
        isActive: 4 === currentInvitationEditorStepIndex,
        isComplete: 4 < currentInvitationEditorStepIndex,
        isIncomplete: 4 > currentInvitationEditorStepIndex,
      },
    ])
  }, [currentInvitationEditorStepIndex])

  const creationDefaultInvitationDetailsValues = tempInvitation
    ? {
        name: tempInvitation.name,
        ownerName: tempInvitation.ownerName,
        industries: tempInvitation.industries,
        startDate: tempInvitation.startDate ? new Date(tempInvitation.startDate) : undefined,
        endDate: tempInvitation.endDate ? new Date(tempInvitation.endDate) : undefined,
        instructions: tempInvitation.instructions,
        sendEmailToInviter: tempInvitation.sendEmailToInviter,
      }
    : undefined

  const creationDefaultSuccessPageValues = tempInvitation?.invitationSuccessPage
    ? {
        title: tempInvitation.invitationSuccessPage.title,
        message: tempInvitation.invitationSuccessPage.message,
        returnLinkTitle: tempInvitation.invitationSuccessPage.returnLinkTitle,
        returnLink: tempInvitation.invitationSuccessPage.returnLink,
      }
    : undefined

  return (
    <>
      {isEnterpriseCheckLoading && isPreviewSubscriptionLoading ? (
        <LoadingSpinner container />
      ) : (
        <div className="form-container">
          <InvitationForm
            index={0}
            invitationId={invitationId}
            isEditing={editingDefaultInvitationDetailsValues !== undefined}
            defaultValues={
              creationDefaultInvitationDetailsValues
                ? creationDefaultInvitationDetailsValues
                : editingDefaultInvitationDetailsValues
                ? editingDefaultInvitationDetailsValues
                : undefined
            }
            setTempInvitation={setTempInvitation}
            tempInvitation={tempInvitation}
            newSubscription={newSubscription}
            templateId={templateId}
            newProduct={newProduct}
            changePlan={changePlan}
            currentInvitationEditorStepIndex={currentInvitationEditorStepIndex}
            setCurrentInvitationEditorStepIndex={setCurrentInvitationEditorStepIndex}
            showEnterpriseFeatures={showEnterpriseFeatures}
            setShowEnterpriseFeatures={setShowEnterpriseFeatures}
            steps={invitationEditorSteps}
            submitText={
              showEnterpriseFeatures ? (
                <>
                  <span>Next</span>
                </>
              ) : editingDefaultInvitationDetailsValues === undefined ? (
                <>
                  <div className="add-icon">+</div>
                  <span>Create Invitation</span>
                </>
              ) : (
                <>
                  <span>Save Changes</span>
                </>
              )
            }
          />
          <InvitationVideoChaptersForm
            index={1}
            invitationId={invitationId}
            tempInvitation={tempInvitation}
            setTempInvitation={setTempInvitation}
            setCurrentInvitationEditorStepIndex={setCurrentInvitationEditorStepIndex}
            currentInvitationEditorStepIndex={currentInvitationEditorStepIndex}
            templateId={templateId}
            steps={invitationEditorSteps}
            showEnterpriseFeatures={showEnterpriseFeatures}
          />
          <InvitationBrandingForm
            index={2}
            tempInvitation={tempInvitation}
            setTempInvitation={setTempInvitation}
            setUpdatedBrandingImage={setUpdatedBrandingImage}
            setCurrentInvitationEditorStepIndex={setCurrentInvitationEditorStepIndex}
            currentInvitationEditorStepIndex={currentInvitationEditorStepIndex}
            steps={invitationEditorSteps}
            showEnterpriseFeatures={showEnterpriseFeatures}
          />
          <InvitationSuccessPageForm
            index={3}
            setTempInvitation={setTempInvitation}
            defaultValues={
              creationDefaultSuccessPageValues
                ? creationDefaultSuccessPageValues
                : editingDefaultInvitationSuccessPageValues
                ? editingDefaultInvitationSuccessPageValues
                : undefined
            }
            tempInvitation={tempInvitation}
            currentInvitationEditorStepIndex={currentInvitationEditorStepIndex}
            setCurrentInvitationEditorStepIndex={setCurrentInvitationEditorStepIndex}
            setUpdatedSuccessPageLogoImage={setUpdatedSuccessPageLogoImage}
            steps={invitationEditorSteps}
            showEnterpriseFeatures={showEnterpriseFeatures}
          />
          <InvitationReview
            index={4}
            isEditing={editingDefaultInvitationDetailsValues !== undefined}
            invitationId={invitationId}
            tempInvitation={tempInvitation}
            updatedBrandingImage={updatedBrandingImage}
            updatedSuccessPageLogoImage={updatedSuccessPageLogoImage}
            setCurrentInvitationEditorStepIndex={setCurrentInvitationEditorStepIndex}
            currentInvitationEditorStepIndex={currentInvitationEditorStepIndex}
            steps={invitationEditorSteps}
            showEnterpriseFeatures={showEnterpriseFeatures}
          />
        </div>
      )}
    </>
  )
}

export default InvitationEditor
