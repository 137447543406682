import React, { useEffect, useState } from 'react'
import * as configService from 'services/configService'
import useContentBlock from 'state/useContentBlock'
import { Option } from 'state/types'
import { totalCardAnimationDuration, useCardAnimation } from 'helpers/cardAnimation'
import { isMediaRecorderSupported } from 'helpers/media'
import { BlockProps } from 'components/preview/PreviewEditorPage/PreviewEditorPage'
import PreviewEditorBottomNav from 'components/preview/PreviewEditorBottomNav/PreviewEditorBottomNav'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import AddFieldButton from 'components/shared/AddFieldButton/AddFieldButton'
import VideoChapterCard from 'components/video/VideoChapterCard/VideoChapterCard'
import './VideoBlockEditor.scss'
import VideoSuiteDialog, {
  adhocDontShowAgainMessageKey,
  customVideoChaptersInvitationResponseDontShowAgainMessageKey,
  nonCustomVideoChaptersInvitationResponseDontShowAgainMessageKey,
} from '../VideoSuiteDialog/VideoSuiteDialog'

const maxChapters = 50
const teleprompterDialogKey = 'teleprompter-supported-dialog-suppressed'
const isTeleprompterSupportedDialogSuppressed = () => localStorage.getItem(teleprompterDialogKey) === 'true' || true
const suppressTeleprompterSupportedDialog = () => localStorage.setItem(teleprompterDialogKey, 'true')

type Props = {
  templateId?: string
  isInvitationResponse?: boolean
  areCustomInstructionsPresent?: boolean
} & BlockProps

const VideoBlockEditor: React.FC<Props> = ({
  previewId,
  templateId,
  isPreviewPublished,
  blockIndex,
  prevLink,
  nextLink,
  isInvitationResponse,
  areCustomInstructionsPresent,
}) => {
  const [isVideoSuiteDialogShowing, setVideoSuiteDialogShowing] = useState(true)
  const [areVideoCategoriesLoading, setVideoCategoriesLoading] = useState(false)
  const [videoCategories, setVideoCategories] = useState<Option[]>([])
  const [teleprompterSupportedDialogShowing, setTeleprompterSupportedDialogShowing] = useState(false)
  const { contentBlock, addField, reorderFields } = useContentBlock(previewId, blockIndex)
  const { isAnimating, cardPositions, buttonPosition, animateReorder } = useCardAnimation()
  const block = contentBlock!
  const chapterCount = block.contentBlockFields.length

  const moveChapter = (chapterIndex: number, direction: 'left' | 'right') => {
    if ((direction === 'left' && chapterIndex > 0) || (direction === 'right' && chapterCount - 1)) {
      const fieldIds = block.contentBlockFields.map(f => f.id)
      const newFieldIds = [...fieldIds]
      const offset = direction === 'left' ? -1 : 1
      newFieldIds[chapterIndex] = fieldIds[chapterIndex + offset]
      newFieldIds[chapterIndex + offset] = fieldIds[chapterIndex]

      // Don't animate on mobile because the animation assumes all card widths and heights are equal.
      const doAnimation = window.innerWidth > 600
      if (doAnimation) {
        animateReorder(fieldIds, newFieldIds)
      }
      reorderFields(newFieldIds, doAnimation ? totalCardAnimationDuration : 0)
    }
  }

  useEffect(() => {
    if (!isMediaRecorderSupported && !isTeleprompterSupportedDialogSuppressed()) {
      setTeleprompterSupportedDialogShowing(true)
    }
  }, [setTeleprompterSupportedDialogShowing])

  useEffect(() => {
    if (templateId) {
      const loadCategories = async () => {
        setVideoCategoriesLoading(true)
        const categories = await configService.getVideoCategoryOptions(templateId)
        setVideoCategories(categories)
        setVideoCategoriesLoading(false)
      }
      loadCategories()
    }
  }, [templateId])

  const videoSuiteDialog = () => {
    let hideDialog

    if (isInvitationResponse && areCustomInstructionsPresent) {
      hideDialog = localStorage.getItem(customVideoChaptersInvitationResponseDontShowAgainMessageKey)
    } else if (isInvitationResponse && !areCustomInstructionsPresent) {
      hideDialog = localStorage.getItem(nonCustomVideoChaptersInvitationResponseDontShowAgainMessageKey)
    } else {
      hideDialog = localStorage.getItem(adhocDontShowAgainMessageKey)
    }

    if (hideDialog === 'true') {
      return
    }

    return (
      <VideoSuiteDialog
        isShowing={isVideoSuiteDialogShowing}
        areCustomInstructionsPresent={areCustomInstructionsPresent}
        onClose={() => setVideoSuiteDialogShowing(false)}
        isInvitationResponse={isInvitationResponse}
      />
    )
  }

  return (
    <div className={'video-block-editor' + (isAnimating ? ' animating' : '')}>
      {videoSuiteDialog()}
      <div className="video-chapters">
        {block.contentBlockFields.map((field, index) => (
          <VideoChapterCard
            key={field.id}
            previewId={previewId}
            isPreviewPublished={isPreviewPublished}
            blockIndex={blockIndex}
            fieldIndex={index}
            chapterCount={chapterCount}
            absolutePosition={cardPositions?.[field.id]}
            moveLeft={() => moveChapter(index, 'left')}
            moveRight={() => moveChapter(index, 'right')}
          />
        ))}
        {block.editable && block.contentBlockFields?.length < maxChapters && (
          <AddFieldButton
            buttonText="Add chapter"
            dialogTitle="Select Chapter Type"
            placeholder="Chapter title"
            dialogAddButtonText="Add chapter"
            validationErrorText="Please enter a title for your chapter"
            options={videoCategories}
            areOptionsLoading={areVideoCategoriesLoading}
            absolutePosition={buttonPosition}
            onSubmit={(name, id) => addField(name, 'Video', id)}
          />
        )}
      </div>
      <PreviewEditorBottomNav prevLink={prevLink} nextLink={nextLink} />
      <ConfirmationDialog
        isShowing={teleprompterSupportedDialogShowing}
        okButtonText="Continue"
        title="Heads up"
        onClose={() => {
          setTeleprompterSupportedDialogShowing(false)
          suppressTeleprompterSupportedDialog()
        }}
        className="teleprompter-support-dialog"
      >
        If you want to use our teleprompting tools, you can switch to Chrome or Firefox. Otherwise, carry on!
      </ConfirmationDialog>
    </div>
  )
}

export default VideoBlockEditor
