import React from 'react'
import ErrorPage from 'components/shared/ErrorPage/ErrorPage'
import { browserInfo, isIE, isSafari } from 'helpers/browser'
import './BrowserCheck.scss'

const versionNumber = parseFloat(browserInfo.browser.version || '')
const isOldSafari = isSafari && !isNaN(versionNumber) && versionNumber < 11

const BrowserCheck: React.FC = ({ children }) =>
  isIE || isOldSafari ? (
    <ErrorPage titleText="Your browser is not supported" className="old-browser-error-page">
      {isIE ? (
        <span>You can view other people's previews, but to create one you'll need a modern browser. Try </span>
      ) : (
        <span>{`To create previews you'll need Safari version 12 or higher. Alternatively you can try `}</span>
      )}
      <a href="https://www.google.com/chrome" target="_blank" rel="noopener noreferrer">
        Chrome
      </a>
      <span> or </span>
      <a href="https://www.mozilla.org/firefox" target="_blank" rel="noopener noreferrer">
        Firefox
      </a>
      .
    </ErrorPage>
  ) : (
    <>{children}</>
  )

export default BrowserCheck
