import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import React, { useState } from 'react'
import './SwitchToPersonalAccountDialog.scss'
import useWorkspace from '../../../state/useWorkspace'
import { Checkbox } from '@material-ui/core'

type Props = {
  isShowing: boolean
  setShowing: (showing: boolean) => void
  switchWorkspace: (isMyPreviewMeClicked: boolean) => void
  isMyPreviewClicked: boolean
}

const SwitchToPersonalAccountDialog: React.FC<Props> = ({
  isShowing,
  setShowing,
  isMyPreviewClicked,
  switchWorkspace,
}) => {
  const { setHideSwitchToMyPreviewDialog, setHideSwitchToMySettingsDialog } = useWorkspace(false)
  const [isDontShowAgainChecked, setDontShowAgainChecked] = useState<boolean>(false)

  const handleClose = () => {
    setShowing(false)
    setDontShowAgainChecked(false)
  }

  const handleDontShowAgainClick = () => {
    setDontShowAgainChecked(!isDontShowAgainChecked)
    isMyPreviewClicked
      ? setHideSwitchToMyPreviewDialog(!isDontShowAgainChecked)
      : setHideSwitchToMySettingsDialog(!isDontShowAgainChecked)
  }

  return (
    <CloseableDialog isShowing={isShowing} onClose={handleClose}>
      {isMyPreviewClicked ? (
        <>
          <p>
            Welcome to My PreviewMe. This space was created when you registered with PreviewMe. It sits outside of the
            workspace (or workspaces) you are a member of.{' '}
          </p>
          <p>You can switch between My PreviewMe and workspaces you are a member of at anytime.</p>
        </>
      ) : (
        <p>
          Your settings are managed from the ‘My PreviewMe space’. Once you’ve made any changes click the dropdown arrow
          next to your name to return to this workspace
        </p>
      )}
      <div className={'dont-show-again'} onClick={handleDontShowAgainClick}>
        <Checkbox className={'checkbox'} color={'primary'} checked={isDontShowAgainChecked} />
        <p className={'message'}>Don’t show this message again</p>
      </div>
      <div className={'actions'}>
        <button className={'primary'} onClick={() => switchWorkspace(isMyPreviewClicked)}>
          Continue
        </button>
        <p className={'stay-in-organization-button'} onClick={handleClose}>
          Stay in Workspace
        </p>
      </div>
    </CloseableDialog>
  )
}

export default SwitchToPersonalAccountDialog
