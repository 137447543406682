import React from 'react'
import { aspectRatio } from 'helpers/media'
import './VideoLayoutContainer.scss'

type Props = {
  className?: string
  elementRef?: React.RefObject<HTMLDivElement>
}

const VideoLayoutContainer: React.FC<Props> = ({ className, elementRef, children }) => (
  <div className={'video-layout-container' + (className ? ' ' + className : '')} ref={elementRef}>
    <div className="inner-container" style={{ paddingBottom: aspectRatio * 100 + '%' }}>
      {children}
    </div>
  </div>
)

export default VideoLayoutContainer
