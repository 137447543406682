import React from 'react'
import { Helmet } from 'react-helmet'


type Font = {
  family: string;
  displayName:string;
  variants: string[];
};
export const addedFonts: Font[] = [
  {
    family: 'Comic Sans MS',
    displayName: "Comic Sans MS",
    variants: ["400"]
  },
  {
    family: 'Garamond',
    displayName: "Garamond",
    variants: ["400"]
  },
  {
    family: 'Georgia',
    displayName: "Georgia",
    variants: ["400"]
  },
  {
    family: 'Microsoft Sans Serif',
    displayName: "Sans Serif",
    variants:["400"]
  },
  {
    family: 'Tahoma',
    displayName: "Tahoma",
    variants:["400"]
  },
  {
    family: 'Trebuchet MS',
    displayName: "Trebuchet",
    variants:["400"]
  },
  {
    family: 'Verdana',
    displayName: 'Verdana',
    variants:["400"]
  },
]

const AddedFonts = () => {
  return(
    <>
      <Helmet>
        <link href="https://db.onlinewebfonts.com/c/7cc6719bd5f0310be3150ba33418e72e?family=Comic+Sans+MS" rel="stylesheet"/>
        <link href="https://fonts.cdnfonts.com/css/microsoft-sans-serif" rel="stylesheet"/>
        <link href="https://db.onlinewebfonts.com/c/2bf40ab72ea4897a3fd9b6e48b233a19?family=Garamond" rel="stylesheet"/>
        <link href="https://db.onlinewebfonts.com/c/7dca09e227fdfe16908cebb4244589e4?family=Georgia" rel="stylesheet"/>
        <link href="https://db.onlinewebfonts.com/c/cd0381aa3322dff4babd137f03829c8c?family=Tahoma" rel="stylesheet"/>
        <link href="https://db.onlinewebfonts.com/c/03e852a9d1635cf25800b41001ee80c7?family=Trebuchet+MS" rel="stylesheet"/>
        <link href="https://db.onlinewebfonts.com/c/562fa31bba08b3f71cb71257ddb880d5?family=Verdana" rel="stylesheet"/>
      </Helmet>
    </>
  )
}

export default AddedFonts