import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useUrlParamOnce } from 'helpers/urlParams'
import { loginPageSuccessMessageKey, logoutRedirectImminentKey } from 'state/useAuth'
import AuthTermsText from 'components/auth/AuthTermsText/AuthTermsText'
import AuthSuccessMessage from 'components/auth/AuthSuccessMessage/AuthSuccessMessage'
import AuthErrorMessage from 'components/auth/AuthErrorMessage/AuthErrorMessage'
import SocialAuth from 'components/auth/SocialAuth/SocialAuth'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import FormInput from 'components/shared/FormInput/FormInput'
import './LoginForm.scss'

type Props = {
  logIn: (email: string, password: string) => Promise<{ success: boolean; errorMessage?: string }>
  logInWithGoogle: () => Promise<{ success: boolean; errorMessage?: string }>
  logInWithFacebook: () => Promise<{ success: boolean; errorMessage?: string }>
  onLoginSuccess: () => void
}

type FormData = {
  email: string
  password: string
  confirmPassword: string
}

const validationSchema = yup.object().shape({
  email: yup.string().required('*Please enter an email address').email('*Please enter a valid email address'),
  password: yup.string().required('*Please enter a password'),
})

const LoginForm: React.FC<Props> = ({ logIn, logInWithGoogle, logInWithFacebook, onLoginSuccess }) => {
  const { handleSubmit, register, errors, setValue } = useForm<FormData>({ validationSchema })
  const [isLoading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setSuccessMessage('')
    setErrorMessage('')

    setLoading(true)
    const response = await logIn(email, password)
    if (response.success) {
      onLoginSuccess()
    } else {
      setErrorMessage(response.errorMessage || '')
      setLoading(false)
    }
  })

  useUrlParamOnce('email', (value: string) => setValue('email', value))
  useUrlParamOnce('successMessage', setSuccessMessage)
  useUrlParamOnce('errorMessage', setErrorMessage)

  useEffect(() => {
    const storedSuccessMessage = localStorage.getItem(loginPageSuccessMessageKey)
    const isLogoutRedirectImminent = localStorage.getItem(logoutRedirectImminentKey)

    if (storedSuccessMessage !== null) {
      if (isLogoutRedirectImminent) {
        localStorage.removeItem(logoutRedirectImminentKey)
      } else {
        setSuccessMessage(storedSuccessMessage)
        localStorage.removeItem(loginPageSuccessMessageKey)
      }
    }
  }, [])

  return (
    <form className="login-form" onSubmit={onSubmit} noValidate>
      {successMessage && <AuthSuccessMessage text={successMessage} />}
      {errorMessage && <AuthErrorMessage text={errorMessage} />}
      <div className="header-row">
        <h1>Log in</h1>
        <div className="create-account-link">
          <span>Or</span>
          <Link className="button link" to="/auth/register">
            Create an account
          </Link>
        </div>
      </div>
      <SocialAuth
        logInWithGoogle={logInWithGoogle}
        logInWithFacebook={logInWithFacebook}
        clearSuccess={() => setSuccessMessage('')}
        setErrorMessage={setErrorMessage}
      />
      <FormInput
        name="email"
        type="email"
        placeholder="Email address"
        spellCheck={false}
        errors={errors}
        inputRef={register}
      />
      <FormInput name="password" type="password" placeholder="Password" errors={errors} inputRef={register} />
      <div className="login-button-row">
        <Link to="forgotPassword">Forgot password?</Link>
        <LoadingButton type="submit" className="primary" isLoading={isLoading}>
          Log in
        </LoadingButton>
      </div>

      <AuthTermsText />
    </form>
  )
}

export default LoginForm
