import React from 'react'
import { Link } from 'react-router-dom'
import { PreviewAnalytics } from 'state/types'
import { formatDate } from 'helpers/date'
import PreviewsIcon from 'assets/icons/PreviewsIcon'
import RecordIcon from 'assets/icons/RecordIcon'
import DownloadIcon from 'assets/icons/DownloadIcon'
import ClicksIcon from 'assets/icons/ClicksIcon'
import './AnalyticsPreviewCard.scss'

type Props = PreviewAnalytics

type CardItemProps = {
  className: string
  label: string
  icon: React.ReactNode
  value?: number
  obfuscated?: boolean
}

const CardItem: React.FC<CardItemProps> = ({ className, label, icon, value }) => (
  <div className={'card-item ' + className}>
    {icon}
    <div className="label">{label}</div>
    <div className="value">{value}</div>
  </div>
)

const AnalyticsPreviewCard: React.FC<Props> = ({
  previewId,
  title,
  subtitle,
  status,
  archived,
  firstPublishDate,
  previewViews,
  videoPlays,
  documentDownloads,
  linkClicks,
  obfuscated,
}) => {
  const displayStatus = status === 'published' ? 'Published' : archived ? 'Archived' : 'Draft'
  return (
    <div className="analytics-preview-card">
      <div className="top-bar">
        <div className={'preview-status ' + displayStatus.toLowerCase()}>{displayStatus}</div>
        {firstPublishDate && <div className="first-publish-date">{formatDate(firstPublishDate)}</div>}
      </div>
      <div className="main-content">
        {title && <div className="title">{title}</div>}
        {subtitle && <div className="subtitle">{subtitle}</div>}
        <div className="spacer" />
        <div className="card-items">
          <CardItem className="preview-views" label="Preview views" icon={<PreviewsIcon />} value={previewViews} />
          <CardItem className="video-plays" label="Media plays" icon={<RecordIcon />} value={videoPlays} />
          <CardItem
            className="document-downloads"
            label="Document downloads"
            icon={<DownloadIcon />}
            value={documentDownloads}
          />
          <CardItem className="link-clicks" label="Links clicked" icon={<ClicksIcon />} value={linkClicks} />
        </div>
        {obfuscated ? (
          <div className="preview-analytics-link button primary outline">View all analytics</div>
        ) : (
          <Link className="preview-analytics-link button primary outline" to={`/analytics/p/${previewId}`}>
            View all analytics
          </Link>
        )}
      </div>
    </div>
  )
}

export default AnalyticsPreviewCard
