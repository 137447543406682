import React, { useEffect, useState } from 'react'
import './InvitationsLabelSelector.scss'
import useInvitation from '../../../state/useInvitation'
import { InvitationResponseLabel } from '../../../helpers/enum'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { customTheme } from './MuiTheme'

type Props = {
  label?: string
  invitationId: string
  previewId: string
}

const InvitationsLabelSelector: React.FC<Props> = ({ label, invitationId, previewId }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)
  const { setLabel } = useInvitation(invitationId)

  useEffect(() => {
    if (label || label === '') {
      setSelectedOption(label)
    }
  }, [label])

  const handleOptionChange = async (event: object, value: any) => {
    setSelectedOption(value)
    if (value !== null) {
      await setLabel(previewId, value)
      return
    }
    await setLabel(previewId, '')
  }

  return (
    <div className="invitations-label-selector">
      <ThemeProvider theme={customTheme}>
        <Autocomplete
          onChange={handleOptionChange}
          className="label-selector"
          value={selectedOption ? selectedOption : ''}
          options={Object.values(InvitationResponseLabel)}
          getOptionLabel={option => option}
          renderInput={params => (
            <TextField {...params} hiddenLabel={true} placeholder="Add Label" variant="outlined" />
          )}
        />
      </ThemeProvider>
    </div>
  )
}

export default InvitationsLabelSelector
