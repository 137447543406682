import AnalyticsMan from 'assets/analytics-man.png'
import React from 'react'
import './UnauthorizedPage.scss'
import PageFrame from '../../shared/PageFrame/PageFrame'
import { Link } from 'react-router-dom'

type Props = {
  title: string
  body: string
  previousPath?: string
}

const UnauthorizedPage: React.FC<Props> = ({ title, body , previousPath}) => {
  return (
    <PageFrame className="unauthorized-page">
      <div className="unauthorized-page-wrap">
        <div className="unauthorized-page-content">
          <h2 className="unauthorized-page-subtitle">{title}</h2>
          <p>{body}</p>
          {previousPath && <Link to={previousPath} className="button primary">Got it!</Link>}
        </div>
        <img className="unauthorized-page-image" src={AnalyticsMan} alt="" />
      </div>
    </PageFrame>
  )
}
UnauthorizedPage.defaultProps = {
  title: 'Sorry, but you don’t have permission to access Settings.',
  body: 'Contact your owner or administrator to modify your permissions'
}

export default UnauthorizedPage
