import { Avatar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import './ProfilePlaceholder.scss'

type Props = {
  cssClassName?: string
  name?: string
}

const ProfilePlaceholder: React.FC<Props> = ({ cssClassName, name }) => {
  const [colorNumber, setColorNumber] = useState<number>()
  const [initials, setInitials] = useState<string | undefined>()

  const getInitials = (): string | undefined => {
    if (name) {
      let initials: string = ''
      const wordsInName: string[] = name.split(' ')
      initials = wordsInName[0].charAt(0).concat(wordsInName[1] ? wordsInName[1].charAt(0) : '')
      return initials.toUpperCase()
    } else {
      return ''
    }
  }

  const getColorNumber = (): number => {
    if (name) {
      const numberOfColors: number = 8
      let charCodeSum = 0
      for (let i: number = 0; i < name.length; i++) {
        charCodeSum += name.charCodeAt(i)
      }
      return (charCodeSum % numberOfColors) + 1
    } else {
      return 1
    }
  }

  useEffect(() => {
    setColorNumber(getColorNumber())
    setInitials(getInitials())
  })

  return (
    <div className="profile-placeholder">
      <Avatar
        className={'color-' + colorNumber + (cssClassName ? ' ' + cssClassName + '-placeholder' : '')}
        variant="rounded"
      >
        {name ? initials : undefined}
      </Avatar>
    </div>
  )
}

export default ProfilePlaceholder
