import AuthPage from 'components/auth/AuthPage/AuthPage'
import LoginForm from 'components/auth/LoginForm/LoginForm'
import { getClientQueryParam, getLocationFrom, storeClientQueryParam, storeLocationFrom } from 'helpers/paramStorage'
import { removeUrlParam, useUrlParams } from 'helpers/urlParams'
import React, { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { logIn, logInWithFacebook, logInWithGoogle} from 'services/authService'
import useAuth from 'state/useAuth'
import './LoginPage.scss'
import { Helmet } from 'react-helmet'

const LoginPage = () => {
  const { isLoggedIn, onLoginSuccess } = useAuth()
  const { client } = useUrlParams('client')
  const { state, search } = useLocation()
  const urlRegex = /\/o\/[a-f0-9-]+\/i\/[a-f0-9-]+/;
  const fromInvitationUrl = urlRegex.test(search);


  const getRedirectUrl = () => {
    const storedFrom = getLocationFrom()
    const storedClient = getClientQueryParam()
    return (storedFrom || '/') + (storedClient ? `?client=${storedClient}` : '')
  }

  useEffect(() => {
    if (client) {
      storeClientQueryParam(client)
      removeUrlParam('client', window.history, 'replace')

    }
  }, [client])

  useEffect(() => {
    if (state?.from) {
      storeLocationFrom(state.from)
    }
  }, [state])

  if (isLoggedIn) {
    return <Redirect to={getRedirectUrl()} />;
  }

  return fromInvitationUrl ? (
    <Redirect to={'register'}/>
  ) : (
    <AuthPage>
      <Helmet>
        <link rel="shortcut icon" type="image/png" href='/favicon.png' />
      </Helmet>
      <div className="login-form-with-illustration">
        <div className="illustration" />
        <LoginForm
          logIn={logIn}
          logInWithGoogle={logInWithGoogle}
          logInWithFacebook={logInWithFacebook}
          onLoginSuccess={onLoginSuccess}
        />
      </div>
    </AuthPage>
  );
}

export default LoginPage
