import React from 'react'

const UploadIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg className="icon" viewBox="0 0 25.58 22.59" width="25.58" height="22.59" {...props}>
    <path d="M25.39 11.73a5.24 5.24 0 00-.51-2.28 5.4 5.4 0 00-5-3.13 7.06 7.06 0 00-14 0A5.38 5.38 0 00.7 9.44a5.32 5.32 0 00-.51 2.29 5.38 5.38 0 005.08 5.4h4.54v-1.3H5.38a4.1 4.1 0 11.94-8.14l.75.14v-.77a5.76 5.76 0 0111.51 0v.74l.74-.13a4.34 4.34 0 01.63 0A4.11 4.11 0 0123.7 10a4 4 0 01.39 1.73 4.1 4.1 0 01-3.89 4.1h-4.35v1.3h4.46a5.39 5.39 0 005.08-5.4z" />
    <path d="M16.55 13.58L12.9 9.93l-3.66 3.65.92.92 2.05-2.05v10.03h1.31V12.39l2.11 2.11.92-.92z" />
  </svg>
)

export default UploadIcon
