import DropdownMenu from '../DropdownMenu/DropdownMenu'
import DropdownMenuOption from '../DropdownMenuOption/DropdownMenuOption'
import React, { useState } from 'react'
import './BulkActionButton.scss'
import { Preview, ViewingSelection } from '../../../state/types'
import BulkArchiveConfirmationDialog from '../../preview/BulkArchiveConfirmationDialog/BulkArchiveConfirmationDialog'
import BulkDeleteConfirmationDialog from '../../preview/BulkDeleteConfirmationDialog/BulkDeleteConfirmationDialog'
import BulkUnpublishConfirmationDialog from '../../preview/BulkUnpublishConfirmationDialog/BulkUnpublishConfirmationDialog'
import BulkPublishErrorDialog from '../../preview/BulkPublishErrorDialog/BulkPublishErrorDialog'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

interface Props {
  selectedPreviews: Preview[]
  viewingSelection: ViewingSelection
  doBulkArchive: (previewIds: string[], archived: boolean) => void
  doBulkDelete: (previewIds: string[]) => void
  doBulkPublish: (previewIds: string[], published: boolean) => void
  checkCanPublish: (previewId: string) => Promise<{ isPublishable?: boolean; reviewLink?: string }>
  clearSelectedIds: () => void
}

const BulkActionButton = function ({
  selectedPreviews,
  viewingSelection,
  doBulkArchive,
  doBulkDelete,
  doBulkPublish,
  checkCanPublish,
  clearSelectedIds,
}: Props) {
  const [showBulkArchiveDialog, setShowBulkArchiveDialog] = useState(false)
  const [showBulkDeleteDialog, setShowBulkDeleteDialog] = useState(false)
  const [showBulkUnpublishDialog, setShowBulkUnpublishDialog] = useState(false)
  const [showBulkPublishError, setShowBulkPublishError] = useState(false)
  const [unpublishablePreviewsCount, setUnpublishablePreviewsCount] = useState<number>(0)
  const [isLoading, setLoading] = useState(false)

  const previewIds = selectedPreviews.map(p => p.id)

  const bulkArchiveClick = function () {
    if (selectedPreviews.find(p => p.status === 'published')) {
      setShowBulkArchiveDialog(true)
    } else {
      bulkArchive()
    }
  }

  const bulkPublishClick = async function () {
    setLoading(true)
    let unpublishablePreviewsCount: number = 0
    for (const preview of selectedPreviews) {
      const { isPublishable } = await checkCanPublish(preview.id)
      if (!isPublishable) {
        unpublishablePreviewsCount++
      }
    }
    if (unpublishablePreviewsCount > 0) {
      setShowBulkPublishError(true)
      setUnpublishablePreviewsCount(unpublishablePreviewsCount)
    } else {
      await doBulkPublish(previewIds, true)
      clearSelectedIds()
    }
    setLoading(false)
  }

  const bulkArchive = async function () {
    await doBulkArchive(previewIds, true)
    clearSelectedIds()
  }

  const bulkRestore = async function () {
    await doBulkArchive(previewIds, false)
    clearSelectedIds()
  }

  const bulkDelete = async function () {
    await doBulkDelete(previewIds)
    clearSelectedIds()
  }

  const optionsActive = [
    <DropdownMenuOption
      optionName="Archive"
      onSelect={() => {
        bulkArchiveClick()
      }}
    />,
  ]

  if (selectedPreviews.every(p => p.status === 'published')) {
    optionsActive.push(
      <DropdownMenuOption
        optionName="Unpublish"
        onSelect={() => {
          setShowBulkUnpublishDialog(true)
        }}
      />
    )
  }
  if (selectedPreviews.every(p => p.status !== 'published')) {
    optionsActive.push(<DropdownMenuOption optionName="Publish" onSelect={bulkPublishClick} />)
  }

  const optionsArchived = [
    <DropdownMenuOption
      optionName="Restore"
      onSelect={() => {
        bulkRestore()
      }}
    />,
    <DropdownMenuOption
      optionName="Delete"
      onSelect={() => {
        setShowBulkDeleteDialog(true)
      }}
    />,
  ]

  return (
    <>
      {showBulkArchiveDialog && (
        <BulkArchiveConfirmationDialog onClose={() => setShowBulkArchiveDialog(false)} onOk={() => bulkArchive()} />
      )}
      {showBulkDeleteDialog && (
        <BulkDeleteConfirmationDialog onClose={() => setShowBulkDeleteDialog(false)} onOk={() => bulkDelete()} />
      )}
      {showBulkUnpublishDialog && (
        <BulkUnpublishConfirmationDialog
          onClose={() => setShowBulkUnpublishDialog(false)}
          onOk={async () => {
            setLoading(true)
            await doBulkPublish(previewIds, false)
            clearSelectedIds()
            setLoading(false)
          }}
        />
      )}
      {showBulkPublishError && (
        <BulkPublishErrorDialog
          onClose={() => setShowBulkPublishError(false)}
          unpublishablePreviewsCount={unpublishablePreviewsCount}
        />
      )}
      <div className="bulk-action-container">
        <DropdownMenu
          menuContent={<>{viewingSelection === 'archived' ? optionsArchived : optionsActive}</>}
          trigger={
            <div className="bulk-action-button button secondary">{isLoading ? <LoadingSpinner /> : 'Bulk Action'}</div>
          }
          horizontalAlignment="left"
        />
      </div>
    </>
  )
}

export default BulkActionButton
