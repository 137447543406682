import React, { useEffect, useRef, useState } from 'react'
import { VideoChapter } from 'state/types'
import { PLAY_VIDEO, PLAYING_VIDEO } from 'helpers/events'
import { formatVideoTime, getVideoDuration, isVideoPlaying } from 'helpers/media'
import * as videoService from 'services/videoService'
import VideoPlayerPublished from 'components/video/VideoPlayerWithTrimFeatures/VideoPlayerPublished'
import { trackEvent } from 'services/eventTrackingService'
import './PublishedPreviewVideo.scss'
import PublishedPreviewVideoThumbnail from './PublishedPreviewVideoThumbnail'
import { getEditedTrimmedMP4 } from 'services/videoService'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'
import useStore from '../../../state/useStore'
import useVideoSuite from '../../../state/useVideoSuite'
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import * as previewService from '../../../services/previewService'
import * as subscriptionService from '../../../services/subscriptionService'
import * as productService from '../../../services/productService'
import { SubscriptionPlan } from '../../../helpers/enum'


type Props = {
  videoChapters?: VideoChapter[]
  durations: Record<string, number | undefined>
  onSelectVideoChapter?: () => void
  trackEvents: boolean
  previewId: string
  contentBlockId?: string
}

const tickInterval = 2500 // Time between VIDEO_PLAYING events in milliseconds.
const allowCustomControls = true

const PublishedPreviewVideo: React.FC<Props> = ({
                                                  videoChapters,
                                                  durations,
                                                  onSelectVideoChapter,
                                                  trackEvents,
                                                  previewId,
                                                  contentBlockId,
                                                }) => {
  const { state } = useStore()
  const [selectedChapterIndex, setSelectedChapterIndex] = useState(0)
  const [currentPlan, setCurrentPlan] = useState()
  const [isPersonal, setIsPersonal] = useState(false)
  const [isPlayingQueued, setPlayingQueued] = useState(false)
  const [isVideoLoading, setIsVideoLoading] = useState(true)
  const [videoSource, setVideoSource] = useState()
  const [justLoaded, setJustLoaded] = useState(true)
  const videoChaptersToShow = videoChapters?.filter(chapter => !!chapter.rawVideoStorageId) || []
  const handle = useFullScreenHandle();
  const [hideCta, setHideCta] = useState(true);
  const [startLoadingVideos, setStartLoadingVideos] = useState(false)
  const selectedChapter = videoChaptersToShow[selectedChapterIndex]
  const selectedStorageId = selectedChapter ? selectedChapter.rawVideoStorageId : undefined
  const src = selectedStorageId ? videoSource : undefined
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [preview, setPreview] = useState()
  const previewRef = useRef<string | null>(null)
  const responseData = {
    cta: true,
    watermark: true,
    videoFx: true,
    images: true,
    captions: true
  };
  const {setVideoSuite,setDataLoaded,videoSuiteData,getVideoSuiteByPreviewID } = useVideoSuite(responseData?.cta)
  useEffect(() => {
    (async () => {
      setCurrentPlan("")
      setIsVideoLoading(true)
      setJustLoaded(true)
      const response = await previewService.getPreviewDetails(previewId)
      const preview = response.data
      setIsPersonal(!preview?.organizationId);
      let product
      if(preview?.invitation){
        const inviteeSubscription = await subscriptionService.getSubscriptionByOrganizationId(preview.organizationId)
        product = await productService.retreiveProduct(inviteeSubscription.data?.productId || '',preview.organizationId)

      } else {
        const subscription = await subscriptionService.getSubscription()
        product = await productService.retreiveProduct(subscription.data?.productId || '',preview?.organizationId)
      }
      setCurrentPlan(product.ok? product.data?.name : "Basic")
      setStartLoadingVideos(true)
    })()
  }, [previewId])

  useEffect(() => {
    (async () => {
      try {
        if(startLoadingVideos){
          if ((responseData.watermark || responseData.cta) && (currentPlan !== SubscriptionPlan.Basic)){
            const { data } = await getEditedTrimmedMP4(selectedChapter?.id, selectedChapter?.rawVideoStorageId, selectedChapter?.id, false);
            await getVideoSuiteData()
            setVideoSource(data || selectedChapter?.trimmedMp4Url);
            setIsVideoLoading(false);
            if(!justLoaded){
              setPlayingQueued(true)
            }
            await new Promise((resolve) => setTimeout(resolve, 200));
            setHideCta(true)
          } else {
            setIsVideoLoading(false);
            if(!justLoaded){
              setPlayingQueued(true)
            }
            setVideoSource(selectedChapter?.trimmedMp4Url)
            await new Promise((resolve) => setTimeout(resolve, 200));
            setHideCta(true)
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedChapter?.id, startLoadingVideos, currentPlan]);


  const toggleFullScreen = async (isFullScreen: boolean) => {
    const documentFullscreenElement = document.fullscreenElement;

    if (!isFullScreen && documentFullscreenElement) {
      handle.exit();
    } else if (isFullScreen && !documentFullscreenElement) {
      handle.enter();
    }
  };


  const onButtonClick = (chapterIndex: number) => {
    const video = videoRef.current
    setJustLoaded(false)
    if(chapterIndex !== selectedChapterIndex){
      if (video) {
        if (isVideoPlaying(video)) {
          video.pause()
        }
        setSelectedChapterIndex(chapterIndex)
        onSelectVideoChapter && onSelectVideoChapter()
        setIsVideoLoading(true)
      }
    } else {
      video?.play()
    }
  }

  const onVideoEnded = () => {
    if (selectedChapterIndex < videoChaptersToShow.length - 1) {
      setSelectedChapterIndex(selectedChapterIndex + 1)
      setIsVideoLoading(true)
      setJustLoaded(false)
      setHideCta(false)
    }
  }

  const intervalRef = useRef<number | undefined>()

  const clearPlayingInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }
  const onPlay = () => {
    if (trackEvents) {
      trackEvent({
        eventType: PLAY_VIDEO,
        videoChapterId: selectedChapter.id,
        previewId: previewId,
        contentBlockId: contentBlockId,
        contentBlockFieldId: selectedChapter.contentBlockFieldId,
      })
      clearPlayingInterval()
      intervalRef.current = setInterval(() => {
        const video = videoRef.current
        if (video) {
          const tickIntervalInSeconds = tickInterval / 1000
          const roundedVideoTimeInSeconds = Math.ceil(video.currentTime / tickIntervalInSeconds) * tickIntervalInSeconds
          trackEvent({
            eventType: PLAYING_VIDEO,
            videoChapterId: selectedChapter.id,
            previewId: previewId,
            contentBlockId: contentBlockId,
            contentBlockFieldId: selectedChapter.contentBlockFieldId,
            videoTime: roundedVideoTimeInSeconds,
          })
        }
      }, tickInterval) as any
    }
  }

  const onPause = () => {
    if (trackEvents) {
      clearPlayingInterval()
    }
  }

  const onPlayClickAtEndOfVideo = () => {
    if (selectedChapterIndex === videoChaptersToShow.length - 1) {
      if (videoChaptersToShow.length > 1){
        setIsVideoLoading(true)
      }
      setJustLoaded(false)
      setSelectedChapterIndex(0)
      return true
    }
    return false
  }

  useEffect(() => {
    if (isPlayingQueued && !justLoaded) {
      getVideoSuiteData()
      const video = videoRef.current
      if (video) {
        if (!isVideoPlaying(video)) {
          video.play()
        }
      }
      setPlayingQueued(false)
    }
  }, [isPlayingQueued, justLoaded])

  useEffect(
    () => () => {
      getVideoSuiteData()
      clearPlayingInterval()
      clearSessionStorage()
    },
    []
  )

  useEffect(() => {
    setSelectedChapterIndex(0)
  }, [previewId])


  const getVideoSuiteData = async () => {
    if (selectedChapter && selectedChapter.rawVideoStorageId) {
      try {
        let response;
        const videoSuiteData = sessionStorage.getItem('getAllVideoSuiteData');
        if (!videoSuiteData) {
          response = await getVideoSuiteByPreviewID(previewId);
          if (response !== null) {
            const responseString = JSON.stringify(response);
            sessionStorage.setItem('getAllVideoSuiteData', responseString);

          }
        } else {
          response = JSON.parse(videoSuiteData);
        }

        if (response !== null && typeof response === 'object') {
          const formattedResponse = Object.values(response) as any[];

          const updatedVideoChaptersToShow = videoChaptersToShow.map(chapter => {

            const matchedCta = formattedResponse.find((item: any) => chapter.id === item.videoId);

            if (matchedCta) {
              return {
                ...chapter,
                watermark: matchedCta.watermark,
                cta: matchedCta.cta
              };
            }

            return chapter;
          });


          setVideoSuite(updatedVideoChaptersToShow);
        } else {
          setVideoSuite([]);
        }

      } catch (error) {
        console.log("Error fetching video suite:", error);
      } finally {
        setDataLoaded(true);
      }
    }
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("getAllVideoSuiteData");
  };

  return (
    <div className="published-preview-video">
      <FullScreen handle={handle}>
        {!isVideoLoading && currentPlan && selectedChapter? (
          <VideoPlayerPublished
            videoSuiteSelected={isPersonal ? '' : videoSuiteData[selectedChapterIndex]}
            responseData={responseData}
            hideCta={hideCta}
            key={src}
            src={src}
            customControls={allowCustomControls}
            nativeControls={!allowCustomControls}
            videoRef={videoRef}
            onEnded={onVideoEnded}
            onPlay={onPlay}
            onPause={onPause}
            onPlayClickAtEndOfVideo={onPlayClickAtEndOfVideo}
            audioOnly={selectedChapter && selectedChapter.type === 'audio'}
            profileImageStorageId={selectedChapter && selectedChapter.profileImageStorageId}
            onFullScreenChange={toggleFullScreen}
            showLayeredAudioVisuals={src === selectedChapter.trimmedMp4Url}
            currentPlan={currentPlan}
          />
        ) : <div className='video-margin'></div>}
      </FullScreen>
      {videoChaptersToShow.length > 1 ? (
        <div className="chapter-thumbnails">
          <div className="scrollable-content">
            {videoChaptersToShow?.map((chapter, i) => {
              return (
                <PublishedPreviewVideoThumbnail
                  key={chapter.id}
                  i={i}
                  chapter={chapter}
                  selectedChapterIndex={selectedChapterIndex}
                  onThumbnailClick={onButtonClick}
                  duration={durations[chapter.id] ? formatVideoTime(durations[chapter.id] as number) : ''}
                />
              )
            })}
          </div>
        </div>
      ) : videoChaptersToShow.length === 1 ? (
        <div className="single-video-label video-label">
          <div className="name">{videoChaptersToShow[0].name}</div>
          <div className="duration">
            {durations[videoChaptersToShow[0].id]
              ? formatVideoTime(durations[videoChaptersToShow[0].id] as number)
              : ''}
          </div>
        </div>
      ) : null}
    </div>
  )
}

type ContainerProps = {
  videoChapters?: VideoChapter[]
  trackEvents: boolean
  previewId: string
  contentBlockId?: string
  onSelectVideoChapter?: () => void
}

const PublishedPreviewVideoContainer: React.FC<ContainerProps> = ({
                                                                    videoChapters,
                                                                    trackEvents,
                                                                    previewId,
                                                                    contentBlockId,
                                                                    onSelectVideoChapter,
                                                                  }) => {
  const [durations, setDurations] = useState<Record<string, number | undefined>>({})

  useEffect(() => {
    let canSet = true
    const loadDurations = async () => {
      if (videoChapters) {
        const loadedDurations = await Promise.all(
          videoChapters.map(async (chapter: VideoChapter) => {
            if (chapter.rawVideoStorageId) {
              let value
              if (chapter.duration) {
                value = getVideoDuration(chapter)
              } else {
                const { data } = await videoService.getVideoDuration(chapter.id)
                value = data
              }
              return { id: chapter.id, value }
            }
            return { id: chapter.id }
          })
        )
        if (canSet) {
          setDurations(
            loadedDurations.reduce((result, d) => {
              result[d.id] = d.value
              return result
            }, {} as Record<string, number | undefined>)
          )
        }
      }
    }
    loadDurations()
    return () => {
      canSet = false
    }
  }, [videoChapters])

  return (
    <PublishedPreviewVideo
      videoChapters={videoChapters}
      durations={durations}
      onSelectVideoChapter={onSelectVideoChapter}
      trackEvents={trackEvents}
      previewId={previewId}
      contentBlockId={contentBlockId}
    />
  )
}

export default PublishedPreviewVideoContainer
