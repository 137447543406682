import { useEffect } from 'react'

// Calls the given handler when a document receives a key press for the given key.
const useDocumentKeyPress = (onDocumentKeyPress: (key: string, keyCode: number, e: KeyboardEvent) => void) => {
  useEffect(() => {
    const onDocumentKeyDown = (e: KeyboardEvent) => {
      onDocumentKeyPress(e.key, e.keyCode, e)
    }
    document.addEventListener('keydown', onDocumentKeyDown)
    return () => document.removeEventListener('keydown', onDocumentKeyDown)
  }, [onDocumentKeyPress])
}

export const useEscapeKeyPress = (onEscapeKeyPress: () => void) =>
  useDocumentKeyPress((key, keyCode) => (key === 'Escape' || keyCode === 27) && onEscapeKeyPress())

export default useDocumentKeyPress
