import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './TabBar.scss'

type Props = {
  tabs: { to: string; label: string; disabled?: boolean }[]
}

const TabBar: React.FC<Props> = ({ tabs }) => {
  const location = useLocation()
  return (
    <div className="tab-bar">
      {tabs.map(({ to, label, disabled }) => {
        // The reason for the following structure is so the size of the link does not
        // change as the link text switches between normal/bold style.
        const linkContent = (
          <div className="tab-label-container">
            <span className="inactive-label">{label}</span>
            <span className="active-label" aria-hidden>
              {label}
            </span>
          </div>
        )
        return disabled ? (
          <div key="to" className="tab disabled">
            {linkContent}
          </div>
        ) : (
          <Link key={to} to={to} className={'tab' + (to === location.pathname ? ' active' : '')}>
            {linkContent}
          </Link>
        )
      })}
    </div>
  )
}

export default TabBar
