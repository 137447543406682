import React, { useState } from 'react'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import './IntegrationDetailPage.scss'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import {
  getEnumKey,
  IntegrationSettingFieldType,
} from 'helpers/enum'
import { useIntegration, useIntegrations } from 'state/useIntegrations'
import { Link, useParams } from 'react-router-dom'
import SwitchButton from 'components/shared/SwitchButton/SwitchButton'
import hub_spot_icon from 'assets/hub_spot_icon.png'
import PenIcon from 'assets/icons/PenIcon'
import MoveUpRightArrowIcon from 'assets/icons/MoveUpRightArrowIcon'
import { Tab, Tabs } from '@material-ui/core'
import FormInput from 'components/shared/FormInput/FormInput'
import * as yup from 'yup'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import { convertToLongDateTimeFormat } from 'helpers/date'
import { getDefaultIntegrationName, getIntegrationIcon } from 'helpers/integration'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import SingleFieldForm from 'components/shared/SingleFieldForm/SingleFieldForm'
import { IntegrationType } from 'helpers/enum'
import { IntegrationSetting } from 'state/types'
import UnauthorizedPage from '../../auth/UnauthorizedPage/UnauthorizedPage'
import AuthorizedPage from '../../auth/AuthorizedPage/AuthorizedPage'

const IntegrationPageDetail: React.FC = () => {
  const { integrationId } = useParams<{ integrationId: string }>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState('settings')
  const [isEditTitleDialogShowing, setEditTitleDialogShowing] = useState(false)

  const {
    currentIntegration,
    integrationLoaded,
    platformIdOrKey,
    settings,
    setPlatformIdOrKey,
    updateAuthentication,
    updateName,
    updateEnabled,
    setCurrentIntegration,
    updateSettings,
  } = useIntegration(integrationId)

  const handleSettingChange = (setting: IntegrationSetting) => {
    updateSettings(setting)
  }

  const handleMetaGDPRChange = (value: boolean) => {
    let setting = settings.find(setting => setting.fieldType === IntegrationSettingFieldType.META_PIXEL_GDPR)
    if (!setting) {
      setting = {
        fieldType: IntegrationSettingFieldType.META_PIXEL_GDPR,
        value: JSON.stringify(value),
        integrationId: integrationId,
      }
    } else setting.value = JSON.stringify(value)
    handleSettingChange(setting)
  }

  const renderTabs = () => {
    return (
      <div className="integration-tab-bar">
        <Tabs
          value={selectedTab}
          onChange={(e, value) => setSelectedTab(value)}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          {/* <Tab value="authentication" label="Authentication" /> */}
          <Tab value="settings" label="Settings" />
        </Tabs>
      </div>
    )
  }

  const renderTabPanel = () => {
    const disabled =
      (!!currentIntegration && currentIntegration?.platformIdOrKey === platformIdOrKey) || !platformIdOrKey

    switch (currentIntegration?.type) {
      case IntegrationType.GOOGLE_ANALYTIC:
        return (
          <div className={'form-tab ' + (selectedTab === 'settings' ? '' : ' d-none')}>
            <form
              onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                updateAuthentication()
              }}
            >
              <div className="form-row">
                <div className="form-cell">
                  <FormInput
                    name="platformIdOrKey"
                    label="Measurement ID"
                    type="text"
                    value={platformIdOrKey}
                    onChange={e => {
                      setPlatformIdOrKey(e.target.value)
                    }}
                    placeholder="eg. ABC-123-000"
                  />
                </div>
                <div className="form-cell right-side-hint">
                  <div className="form-text">
                    Find your Measurement ID
                    <a
                      className="form-link"
                      href="https://previewme.com/frequently-asked-questions?tab=integrations"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      &nbsp;here.
                    </a>
                  </div>
                </div>
              </div>
              <LoadingButton
                type="submit"
                isLoading={isLoading}
                disabled={disabled}
                className={'primary ' + (disabled ? ' disabled' : '')}
              >
                SAVE
              </LoadingButton>
            </form>
          </div>
        )

      case IntegrationType.META_PIXEL:
        return (
          <div className={'form-tab ' + (selectedTab === 'settings' ? '' : ' d-none')}>
            <form
              onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                updateAuthentication()
              }}
            >
              <div className="form-cell">
                <div className="form-text">
                  Start tracking events on your site by adding your Meta Pixel ID. Need to be&nbsp;
                  <a
                    href="https://previewme.com/frequently-asked-questions?tab=integrations"
                    className="form-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    GDPR compliant
                  </a>
                  ? Enable ‘Delay for cookie consent’ and add a consent form to your site.
                </div>
              </div>
              <div className="form-row mt-10px">
                <div className="form-cell">
                  <FormInput
                    name="platformIdOrKey"
                    label="Meta Pixel ID"
                    type="text"
                    value={platformIdOrKey}
                    placeholder="eg. 1234567890"
                    onChange={e => {
                      setPlatformIdOrKey(e.target.value)
                    }}
                  />
                </div>

                <div className="form-cell right-side-hint">
                  <div className="form-text">
                    Create a Meta Pixel and paste the ID here.
                    <br />
                    Need help? See&nbsp;
                    <a
                      href="https://previewme.com/frequently-asked-questions?tab=integrations"
                      className="form-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      our documentation.
                    </a>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-cell">
                  <div className="form-input-label">Delay for cookie consent</div>
                  <div className="switch-button-wrapper">
                    {settings
                          ?.find(setting => setting.fieldType === IntegrationSettingFieldType.META_PIXEL_GDPR)
                          ?.value?.toLowerCase() === 'true'}
                    <SwitchButton
                      checked={
                        settings
                          ?.find(setting => setting.fieldType === IntegrationSettingFieldType.META_PIXEL_GDPR)
                          ?.value?.toLowerCase() === 'true'
                      }
                      handleLocally={true}
                      onChange={handleMetaGDPRChange}
                    ></SwitchButton>
                    <div className="form-text">(Pixel will not load without consent)</div>
                  </div>
                </div>

                <div className="form-cell right-side-hint">
                  <div className="form-text">
                    Delay loading the Facebook pixel until after visitors have accepted your cookie policy. You must
                    turn this on to be GDPR compliant.
                    <br />
                    Learn how to create a{' '}
                    <a
                      href="https://previewme.com/frequently-asked-questions?tab=integrations"
                      className="form-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook pixel cookie consent form.
                    </a>
                  </div>
                </div>
              </div>

              <LoadingButton type="submit" isLoading={isLoading} className="primary">
                SAVE
              </LoadingButton>
            </form>
          </div>
        )

      case IntegrationType.HUBSPOT:
      default:
        return (
          <div className={'form-tab ' + (selectedTab === 'settings' ? '' : ' d-none')}>
            <form
              onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                updateAuthentication()
              }}
            >
              <div className="form-cell">
                <FormInput
                  name="platformIdOrKey"
                  label="Hubspot ID"
                  type="text"
                  value={platformIdOrKey}
                  onChange={e => {
                    setPlatformIdOrKey(e.target.value)
                  }}
                />
              </div>
              <LoadingButton
                type="submit"
                isLoading={isLoading}
                disabled={disabled}
                className={'primary ' + (disabled ? ' disabled' : '')}
              >
                SAVE
              </LoadingButton>
            </form>
          </div>
        )
    }
  }

  return (
    <AuthorizedPage UnauthorizedComponent={UnauthorizedPage}>
      <PageFrame
        className="integration-detail-page"
        header={
          integrationLoaded &&
          currentIntegration && (
            <div className="header-section">
              <div className="title-section">
                <div className="breadcrum">
                  <Link to="/organization/settings/integrations" className="breadcrum-link">
                    Integrations
                  </Link>
                  &nbsp;&gt;&nbsp;{currentIntegration.name || getDefaultIntegrationName(currentIntegration.type)}
                </div>
                <div className="title-row">
                  <div className="title-section">
                    <img src={getIntegrationIcon(currentIntegration.type)} alt="" className="platform-icon" />
                    <div className="integration-name" onClick={() => setEditTitleDialogShowing(true)}>
                      {currentIntegration.name || getDefaultIntegrationName(currentIntegration.type)} <PenIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-section">
                <div className="integration-id">Integration ID: {currentIntegration.id}</div>
                <div className="update-info">
                  Created: {convertToLongDateTimeFormat(currentIntegration.createdAt)}
                  &nbsp;&nbsp; Updated at: {convertToLongDateTimeFormat(currentIntegration.updatedAt)}
                </div>
                <a
                  className="documentation-link"
                  href="https://previewme.com/frequently-asked-questions?tab=integrations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Documentation <MoveUpRightArrowIcon />
                </a>
              </div>
              {renderTabs()}
            </div>
          )
        }
      >
        {integrationLoaded && currentIntegration && renderTabPanel()}
        {integrationLoaded && !currentIntegration && <div>Integration not found!</div>}
        {!integrationLoaded && <LoadingSpinner />}

        {currentIntegration && (
          <CloseableDialog
            className="edit-block-title-dialog"
            isShowing={isEditTitleDialogShowing}
            onClose={() => setEditTitleDialogShowing(false)}
          >
            <h2>Edit Integration Title</h2>
            <SingleFieldForm
              placeholder="Section title"
              validationErrorText="Please enter a section title"
              submitButtonText="Save"
              initialValue={currentIntegration?.name || getDefaultIntegrationName(currentIntegration?.type)}
              triggerReset={isEditTitleDialogShowing}
              onCancel={() => setEditTitleDialogShowing(false)}
              onSubmit={async name => {
                await updateName(name)
                setEditTitleDialogShowing(false)
                setCurrentIntegration({ ...currentIntegration, name })
              }}
            />
          </CloseableDialog>
        )}
      </PageFrame>
    </AuthorizedPage>
  )
}

export default IntegrationPageDetail
