import HandDrawnArrowIcon from 'assets/icons/HandDrawnArrowIcon'
import { getInvitationData, storeInvitationData } from 'helpers/paramStorage'
import React, { useEffect, useState } from 'react'
import { match, matchPath, useLocation } from 'react-router-dom'
import * as invitationService from 'services/invitationService'
import './AuthPage.scss'

const AuthPage: React.FC = ({ children }) => {
  const [isInvitationResponseMode, setInvitationResponseMode] = useState(false)
  const [invitationOwner, setInvitationOwner] = useState(getInvitationData()?.ownerName || '')
  const [isTeamMemberInvitationMode, setTeamMemberInvitationMode] = useState<boolean>(false)
  const isLongInvitationText = invitationOwner && invitationOwner.length > 15
  const location = useLocation()

  const isOnboardingPage = location.pathname === '/welcome'

  const handleTeamMemberInvitation = () => {
    setTeamMemberInvitationMode(true)
  }

  const handleInvitationResponse = (
    invitationResponseMatch: match<{
      id: string
    }> | null
  ) => {
    const invitationId = invitationResponseMatch?.params?.id
    const storedInvitationData = getInvitationData()
    const canUseStoredInvitationData =
      (!invitationId && storedInvitationData) || (invitationId && storedInvitationData?.id === invitationId)

    if (canUseStoredInvitationData) {
      setInvitationResponseMode(true)
    } else if (invitationId) {
      setInvitationResponseMode(true)

      const loadInvitationSummary = async () => {
        const { ok, data } = await invitationService.getPublicInvitationSummary(invitationId)
        if (ok && data) {
          setInvitationOwner(data.ownerName)
          storeInvitationData({ id: invitationId, ownerName: data.ownerName })
        } else {
          setInvitationOwner('')
          storeInvitationData({ id: invitationId, ownerName: '' })
        }
      }
      loadInvitationSummary()
    }
  }

  useEffect(() => {
    const teamMemberInvitationMatch = matchPath<{ organizationId: string }>(location?.state?.from, {
      path: '/o/:organizationName/i/:invitationCode',
      exact: true,
    })

    if (teamMemberInvitationMatch?.isExact) {
      handleTeamMemberInvitation()
    } else {
      setTeamMemberInvitationMode(false)
    }

    const invitationResponseMatch = matchPath<{ id: string }>(location?.state?.from, { path: '/i/:id', exact: true })

    if (invitationResponseMatch?.isExact) {
      handleInvitationResponse(invitationResponseMatch)
    } else {
      setInvitationResponseMode(false)
    }
  }, [location])

  return (
    <div className={'auth-page' + (isInvitationResponseMode || isTeamMemberInvitationMode ? ' invitation-mode' : '')}>
      <div className="auth-header">
        <div className="auth-header-content">
          <a href="https://previewme.com" aria-label="PreviewMe Home">
            <div className="logo" />
          </a>
          {isInvitationResponseMode && (
            <>
              <div className={'invitation-banner' + (isLongInvitationText ? ' long' : '')}>
                {!isOnboardingPage && invitationOwner ? (
                  <>
                    <span>You've been invited by </span>
                    <span className="owner-name">{invitationOwner}</span>
                    <span> to create a Preview! Log in to continue.</span>
                  </>
                ) : isOnboardingPage && invitationOwner ? (
                  <>
                    <span>Please complete this step before creating your Preview for </span>
                    <span className="owner-name">{invitationOwner}</span>
                    <span>.</span>
                  </>
                ) : !isOnboardingPage ? (
                  <span>You've been invited to create a Preview! Log in to continue.</span>
                ) : (
                  <span>Please complete this step before creating your Preview.</span>
                )}
                <span className="arrow-anchor">
                  <HandDrawnArrowIcon />
                </span>
              </div>
              <div className="ballast" />
            </>
          )}

          {isTeamMemberInvitationMode && (
            <>
              <div className={'invitation-banner' + (isLongInvitationText ? ' long' : '')}>
                <span>Log in to accept the invite</span>
                <span className="arrow-anchor">
                  <HandDrawnArrowIcon />
                </span>
              </div>
              <div className="ballast" />
            </>
          )}
        </div>
      </div>
      <div className="auth-page-content">{children}</div>
      <div className="auth-page-spacer" />
    </div>
  )
}

export default AuthPage
