  import useStore from './useStore'
  import { useState, useEffect, useCallback } from 'react'
  import * as callsActionService from '../services/videoSuiteService'
  import useWorkspace from './useWorkspace'

  interface VideoSuiteResponse {
    captions: string;
    createdAt: string;
    cta: string | any[];
    id: string;
    images: string;
    organizationId: string;
    updatedAt: string;
    videoId: string;
    videofx: string;
    watermark: string;
  }

  const useVideoSuite = (enabled:boolean) => {
    const [isMounted, setIsMounted] = useState(true);
    const { state, dispatch } = useStore();
    let videoSuiteData = [];
    const [isLoadingDelayed, setIsLoadingDelayed] = useState(true);

    const { currentWorkspace } = useWorkspace(true)
    const [isDataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
      setIsMounted(true);

      return () => {
        setIsMounted(false);
      };
    }, []);

    const getVideoSuiteByVideoID = async (
      videoId: string,
      previewId: string,
      rawVideoStorageId: string
    ): Promise<VideoSuiteResponse | null> => {

      if (!currentWorkspace?.id || !previewId || !videoId || !rawVideoStorageId || !isMounted) {
        return null; // Return null if conditions are not met
      }
      try {
        setDataLoaded(false);
        // setIsLoadingDelayed(false)
        const { data } = await callsActionService.getVideoSuiteByVideoId(
          currentWorkspace.id, videoId, previewId, rawVideoStorageId
        );

        if (!data) {
          return null; // Handle the case where data is not available
        }

        let ctaData: any[] | string = data.cta ?? [];

        if (typeof ctaData === 'string' && ctaData.trim() !== '') {
          try {
            ctaData = JSON.parse(ctaData);
          } catch (error) {
            console.error("Error parsing cta data:", error);
            ctaData = [];
          }
        } else if (!Array.isArray(ctaData)) {
          ctaData = [];
        }

        // Update the data object with the parsed ctaData
        const updatedData: VideoSuiteResponse = { ...data, cta: ctaData };
        if (isMounted) {
          setVideoSuite(ctaData);
        }
        return updatedData;

      } catch (error) {

        console.error("Error fetching video suite:", error);
        // Return null in case of an error
        return null;

      } finally {
        setIsLoadingDelayed(false)
      }

    };

    const getVideoSuiteByPreviewID = async (previewId:String) => {
      if (currentWorkspace?.id && previewId) {
        setDataLoaded(false)
        const {  data } = await callsActionService.getVideoSuiteByPreviewId(
          currentWorkspace.id, previewId
        )
        return data
      }
    }

    const getVideoSuiteByVideoIDPublic = async (videoId:String) => {
      if (currentWorkspace?.id && videoId) {
        setDataLoaded(false)
        const {  data } = await callsActionService.getVideoSuiteByVideoIdPublic(videoId)
        return data
      }
    }

    const removeVideoSuiteById = async (id:String) => {
      if (currentWorkspace?.id && id) {
        setDataLoaded(false)
        const {  data } = await callsActionService.removeVideoSuiteById(
          currentWorkspace.id, id
        )
        return data
      }
    }


    const updateVideoSuite = async (id: string, data: any) => {
      if (currentWorkspace?.id) {
        const { ok } = await callsActionService.updateVideoSuite(
          currentWorkspace.id,
          id,
          data
        )
        return ok
      }
    }

    const setVideoSuite = useCallback((data: any) => {
      if (enabled) {
        dispatch({ type: 'SET_CALLS_ACTION_DATA', value: data || [] });
      }
    }, [dispatch, enabled]);

    if (enabled) {
      videoSuiteData = state.callsAction;
    }

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toFixed(1).padStart(4, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    };
    const setInitialFadeIn = (initialTime: number) => {
      dispatch({ type: 'SET_INITIAL_FADE_IN', value: formatTime(initialTime) });
    };

    const initialFadeInData = state.initialFadeIn;

    const setInitialFadeOut = (initialTime: number) => {
      dispatch({ type: 'SET_INITIAL_FADE_OUT', value: formatTime(initialTime) });
    };

    const initialFadeOutData = state.initialFadeOut;

    return {
      setDataLoaded,
      getVideoSuiteByVideoID,
      setVideoSuite,
      videoSuiteData,
      isDataLoaded,
      updateVideoSuite,
      initialFadeInData,
      initialFadeOutData,
      setInitialFadeIn,
      setInitialFadeOut,
      isLoadingDelayed,
      setIsLoadingDelayed,
      getVideoSuiteByPreviewID,
      removeVideoSuiteById,
      getVideoSuiteByVideoIDPublic
    }
  }

  export default useVideoSuite
