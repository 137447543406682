import React from 'react'
import './Social.scss'

import ShareOnLinkedInIcon from '../../../assets/icons/ShareOnLinkedInIcon'
import ShareOnTwitterIcon from '../../../assets/icons/ShareOnTwitterIcon'
import ShareOnFacebookIcon from '../../../assets/icons/ShareOnFacebookIcon'
import { facebookBase, linkedinBase, twitterBase } from '../../shared/SharingLinks/SharingLinks'

interface Props {
  url: string
}

const Social = function ({ url }: Props) {
  const socials: { name: string; icon: JSX.Element; baseUrl: string }[] = [
    {
      name: 'Linkedin',
      icon: <ShareOnLinkedInIcon />,
      baseUrl: `${linkedinBase}&url=${url}`,
    },
    {
      name: 'Twitter',
      icon: <ShareOnTwitterIcon />,
      baseUrl: `${twitterBase}?url=${url}&hashtags=previewme`,
    },
    {
      name: 'Facebook',
      icon: <ShareOnFacebookIcon />,
      baseUrl: `${facebookBase}?u=${url}`,
    },
  ]

  return (
    <div className="share-social">
      {socials.map(social => (
        <a href={`${social.baseUrl}`} target="_blank" className="social-box">
          {social.icon}
          <span className="social-name">
            Share on <b>{social.name}</b>
          </span>
          <span className="share">Share</span>
        </a>
      ))}
    </div>
  )
}

export default Social
