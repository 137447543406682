import { WorkspaceType } from 'helpers/enum'
import { getAllWorkspaces, getWorkspaceOrganization, getWorkspaceOrganizationId } from 'helpers/organization'
import { getFullName } from 'helpers/user'
import { useEffect, useState } from 'react'
import * as imageService from 'services/imageService'
import * as organizationService from 'services/organizationService'
import * as userService from 'services/userService'
import { InvitedTeamMember, Organization, Preview, TeamMember, User, Workspace } from './types'
import useAuth from './useAuth'
import useStore from './useStore'

export const workspacesKey = 'workspaces'
export const currentWorkspaceKey = 'current-workspace'
export const hideSwitchToPersonalPreviewMeDialogKey = 'hide-switch-to-personal-preview-me-dialog'
export const hideSwitchToPersonalSettingsDialogKey = 'hide-switch-to-personal-settings-dialog'

const useWorkspace = (retrieveWorkspacesOnLoad: boolean) => {
  const { user, updateUser } = useAuth()
  const { state, dispatch } = useStore()
  const [organizationsLoaded, setOrganizationsLoaded] = useState<boolean>(false)

  useEffect(() => {
    const setupLocalStorage = async () => {
      storeWorkspaces()
    }

    if (retrieveWorkspacesOnLoad) {
      setupLocalStorage()
    }
  }, [])

  const getCurrentWorkspace = () => {
    if (state.currentWorkspace) {
      return state.currentWorkspace
    }

    const workspace = localStorage.getItem(currentWorkspaceKey)
    if (workspace) {
      const workspaceJSON = JSON.parse(workspace) as Workspace
      dispatch({ type: 'SET_CURRENT_WORKSPACE', value: workspaceJSON })
      return workspaceJSON
    }
  }

  const storeWorkspaces = async () => {
    if (user) {
      let workspaces: Workspace[] = []
      const userWorkspace: Workspace = {
        id: user.id,
        name: getFullName(user)!,
        profileImageStorageId: user.profileImageStorageId,
        type: WorkspaceType.User,
        freeTrial: false,
        industries: user.industries,
        country: user.country!,
      }
      workspaces.push(userWorkspace)
      workspaces = await assignOrganizationWorkspaces(workspaces)
      refreshWorkspaces(workspaces)
    }
    setOrganizationsLoaded(true)
  }

  const refreshWorkspaces = (workspaces: Workspace[]) => {
    setWorkspaces(workspaces)
    const initialWorkspace = getInitialWorkspace(workspaces)
    setCurrentWorkspace(initialWorkspace ? initialWorkspace : workspaces[0])
  }

  const getInitialWorkspace = (workspaces: Workspace[]) => {
    const currentWorkspace = localStorage.getItem(currentWorkspaceKey)
    let organizationWorkspace
    for (let i = 0; i < workspaces.length; i++) {
      const workspace = workspaces[i]
      if (workspace.id !== user?.id) {
        organizationWorkspace = workspace
      }
      if (currentWorkspace && workspace.id === (JSON.parse(currentWorkspace) as Workspace).id) {
        return workspace
      }
    }
    return organizationWorkspace
  }

  const setCurrentWorkspaceById = (id: string): boolean => {
    const allWorkspaces: Workspace[] | undefined = getAllWorkspaces()
    if (!allWorkspaces) {
      return false
    }

    const matchingWorkspaces: Workspace[] = allWorkspaces.filter(workspace => workspace.id === id)

    if (matchingWorkspaces.length === 0) {
      return false
    }

    setCurrentWorkspace(matchingWorkspaces[0])
    return true
  }

  const setCurrentWorkspace = (workspace: Workspace | undefined) => {
    if (workspace) {
      localStorage.setItem(currentWorkspaceKey, JSON.stringify(workspace))
      dispatch({ type: 'SET_CURRENT_WORKSPACE', value: workspace })
    }
  }

  const setHideSwitchToMyPreviewDialog = (value: boolean) => {
    localStorage.setItem(hideSwitchToPersonalPreviewMeDialogKey, String(value))
  }

  const getHideSwitchToMyPreviewMeDialog = (): boolean => {
    return localStorage.getItem(hideSwitchToPersonalPreviewMeDialogKey) === 'true'
  }

  const setHideSwitchToMySettingsDialog = (value: boolean) => {
    localStorage.setItem(hideSwitchToPersonalSettingsDialogKey, String(value))
  }

  const getHideSwitchToMySettingsDialog = (): boolean => {
    return localStorage.getItem(hideSwitchToPersonalSettingsDialogKey) === 'true'
  }

  const setWorkspaces = (workspaces: Workspace[] | undefined) => {
    if (workspaces) {
      localStorage.setItem(workspacesKey, JSON.stringify(workspaces))
    }
  }

  const switchToUserWorkspace = () => {
    setCurrentWorkspace(userToWorkspace(user!))
  }

  const assignOrganizationWorkspaces = async (workspaces: Workspace[]) => {
    const { ok, data: organizations } = await organizationService.getOrganizationsByUser()
    if (ok && organizations) {
      organizations.forEach(organization => {
        workspaces.push({
          id: organization.id,
          name: organization.name,
          profileImageStorageId: organization.profileImageStorageId,
          type: WorkspaceType.Organization,
          freeTrial: organization.freeTrial,
          industries: organization.industries,
          country: organization.country,
        })
      })
    }
    return workspaces
  }

  const trackEventsBasedOnWorkspace = (preview: Preview) => {
    if (user?.admin) {
      return false
    } else {
      const workspaceOrganizationId = getWorkspaceOrganizationId()
      if (workspaceOrganizationId !== undefined) {
        return workspaceOrganizationId !== preview.organizationId
      } else {
        return user?.id !== preview.userId
      }
    }
  }

  const sendInvitesToAllTeamMembers = (invitedTeamMembers: (InvitedTeamMember | undefined)[]) => {
    invitedTeamMembers.forEach(async teamMember => {
      if (teamMember !== undefined) {
        const workspaceOrganizationId = getWorkspaceOrganizationId()
        if (workspaceOrganizationId) {
          await organizationService.sendInviteToTeamMembers(workspaceOrganizationId, teamMember)
        }
      }
    })
  }

  const createOrganization = async (organization: Partial<Organization>) => {
    const { ok, data } = await organizationService.createOrganization(organization)
    if (ok && data) {
      localStorage.setItem(
        currentWorkspaceKey,
        JSON.stringify({
          id: data.id,
          name: data.name,
          profileImageStorageId: data.profileImageStorageId,
          freeTrial: data.freeTrial,
          type: WorkspaceType.Organization,
          industries: data.industries,
          country: data.country,
        })
      )
    }

    return { ok: ok, data: data }
  }

  const removeOrganizationProfileImage = async () => {
    const workspaceOrganization: Organization | undefined = getWorkspaceOrganization()
    if (workspaceOrganization) {
      workspaceOrganization.profileImageStorageId = undefined
      await updateOrganization(workspaceOrganization)
    }
  }

  const updateOrganizationProfileImage = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
    if (status === 'success' && !!storageId) {
      await updateOrganizationProfileImageStorageId(storageId)
    }
  }

  const updateOrganizationProfileImageStorageId = async function (profileImageStorageId: string) {
    const workspaceOrganization: Organization | undefined = getWorkspaceOrganization()
    if (workspaceOrganization) {
      workspaceOrganization.profileImageStorageId = profileImageStorageId
      await updateOrganization(workspaceOrganization)
    }
  }

  const updateUserProfileImage = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
    if (status === 'success' && !!storageId && user) {
      await updateUserProfileImageStorageId(storageId)
    }
  }

  const updateUserProfileImageStorageId = async (profileImageStorageId: string) => {
    if (user) {
      await updateUser({ profileImageStorageId: profileImageStorageId })
      user.profileImageStorageId = profileImageStorageId
      updateWorkspaces(userToWorkspace(user!))
    }
  }

  const removeUserProfileImage = () => {
    if (user) {
      dispatch({ type: 'UPDATE_USER', value: { profileImageStorageId: undefined } })
      userService.updateUser({ ...user, profileImageStorageId: undefined })
      user.profileImageStorageId = undefined
      updateWorkspaces(userToWorkspace(user!))
    }
  }

  const updateOrganization = async (organization: Organization): Promise<boolean> => {
    const { ok, data } = await organizationService.updateOrganization(organization)
    if (ok && data) {
      const workspace: Workspace = organizationToWorkspace(organization)
      updateWorkspaces(workspace)
    }
    return ok
  }

  const getCurrentOrganizationTeamMembers = async (): Promise<TeamMember[]> => {
    const currentWorkspace: Workspace | undefined = getCurrentWorkspace()

    if (currentWorkspace && currentWorkspace.type === WorkspaceType.Organization) {
      const { ok, data: teamMembers } = await organizationService.getTeamMembers(currentWorkspace.id)
      if (ok && teamMembers) {
        return teamMembers
      }
    }

    return []
  }

  const updateWorkspaces = (workspaceToUpdate: Workspace): void => {
    const allWorkspaces: Workspace[] | undefined = getAllWorkspaces()
    const currentWorkspace: Workspace | undefined = getCurrentWorkspace()
    if (allWorkspaces && currentWorkspace) {
      const workspaceToUpdateIndex: number = allWorkspaces.findIndex(workspace => workspace.id === workspaceToUpdate.id)
      allWorkspaces[workspaceToUpdateIndex] = workspaceToUpdate
      setWorkspaces(allWorkspaces)
      if (currentWorkspace.id === workspaceToUpdate.id) {
        setCurrentWorkspace(workspaceToUpdate)
      }
    }
  }

  const organizationToWorkspace = (organization: Organization): Workspace => {
    return {
      id: organization.id,
      name: organization.name,
      profileImageStorageId: organization.profileImageStorageId,
      industries: organization.industries,
      freeTrial: organization.freeTrial,
      type: WorkspaceType.Organization,
      country: organization.country,
    }
  }

  const userToWorkspace = (user: User): Workspace => {
    return {
      id: user.id,
      name: getFullName(user)!,
      profileImageStorageId: user.profileImageStorageId,
      industries: user.industries,
      freeTrial: false,
      type: WorkspaceType.User,
      country: user.country!,
    }
  }

  const addUserToOrganization = async (organizationId: string) => {
    const { ok, data } = await organizationService.addUserToOrganization(organizationId)
    return { ok: ok, data: data }
  }

  const addUserByInvitation = async (organizationId: string, invitationCode: string) => {
    const { ok, data } = await organizationService.addUserByInvitation(organizationId, invitationCode)
    return { ok: ok, data: data }
  }

  const addUserToOrganizationByInvitation = async (organizationId: string, invitationCode: string) => {
    const { ok } = await addUserByInvitation(organizationId, invitationCode)
    if (ok) {
      const { ok, data: workspaceOrganization } = await getOrganizationsById(organizationId)
      if (ok && workspaceOrganization) {
        setCurrentWorkspace({
          id: workspaceOrganization.id,
          name: workspaceOrganization.name,
          profileImageStorageId: workspaceOrganization.profileImageStorageId,
          type: WorkspaceType.Organization,
          freeTrial: workspaceOrganization.freeTrial,
          country: workspaceOrganization.country,
        })
      }
    }

    return ok
  }

  const deleteTeamMemberInvite = async (email: string): Promise<boolean | undefined> => {
    const organization = getWorkspaceOrganization()
    if (organization) {
      const { ok } = await organizationService.deleteTeamMemberInvite(organization.id, email)
      return ok
    }
  }

  const deleteTeamMemberFromCurrentOrganization = async (userId: string): Promise<boolean | undefined> => {
    const organization = getWorkspaceOrganization()
    if (organization) {
      const { ok } = await organizationService.deleteTeamMemberFromOrganization(organization.id, userId)
      return ok
    }
  }

  const isEmailCorrect = async (invitationCode: string) => {
    const { ok, data: organizationUserInvite } = await organizationService.getOrganizationsUserInviteByInviteCode(
      invitationCode
    )

    if (ok && organizationUserInvite) {
      return user?.email.toLowerCase() === organizationUserInvite.email.toLowerCase()
    }
    return true
  }

  const getOrganizationsUserInviteByInviteCode = async (invitationCode: string) => {
    const { ok, data } = await organizationService.getOrganizationsUserInviteByInviteCode(invitationCode)
    return { ok: ok, data: data }
  }

  const getOrganizationsById = async (organizationId: string) => {
    const { ok, data } = await organizationService.getOrganizationsById(organizationId)
    return { ok: ok, data: data }
  }

  return {
    organizationsLoaded,
    currentWorkspace: getCurrentWorkspace(),
    setCurrentWorkspace,
    createOrganization,
    getOrganizationsById,
    addUserToOrganization,
    trackEventsBasedOnWorkspace,
    sendInvitesToAllTeamMembers,
    addUserByInvitation,
    getOrganizationsUserInviteByInviteCode,
    addUserToOrganizationByInvitation,
    isEmailCorrect,
    updateOrganizationProfileImage,
    updateOrganizationProfileImageStorageId,
    removeOrganizationProfileImage,
    userToWorkspace,
    updateWorkspaces,
    updateUserProfileImage,
    updateUserProfileImageStorageId,
    removeUserProfileImage,
    updateOrganization,
    switchToUserWorkspace,
    getCurrentOrganizationTeamMembers,
    deleteTeamMemberInvite,
    deleteTeamMemberFromCurrentOrganization,
    setHideSwitchToMyPreviewDialog,
    getHideSwitchToMyPreviewMeDialog,
    setHideSwitchToMySettingsDialog,
    getHideSwitchToMySettingsDialog,
    setCurrentWorkspaceById,
  }
}

export default useWorkspace
