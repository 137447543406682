import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import FormSelect from '../../shared/FormSelect/FormSelect'
import FormInput from '../../shared/FormInput/FormInput'
import { isMobile } from '../../../helpers/browser'
import FormTextArea from '../../shared/FormTextArea/FormTextArea'
import { Button } from '@material-ui/core'
import { Option } from '../../../state/types'
import './CreateCustomVideoChapter.scss'

interface Props {
  options: Option[]
  initialOption?: string
  initialField?: string
  initialFormat?: string
  initialDescription?: string
  buttonText: string
  onFormSubmit: ({
    selectedFormat,
    selectedOption,
    formatDisplay,
    otherField,
    description,
  }: {
    selectedOption: string
    otherField: string
    selectedFormat: string
    formatDisplay: string
    description: string
  }) => void
}

const CreateCustomVideoChapter = function ({
  options,
  initialDescription,
  initialField,
  initialFormat,
  initialOption,
  buttonText,
  onFormSubmit,
}: Props) {
  const [fieldName, setFieldName] = useState(initialField || '')
  const [description, setDescription] = useState(initialDescription || '')
  const [selectedOption, setSelectedOption] = useState(initialOption || '')
  const [selectedFormat, setSelectedFormat] = useState(initialFormat || '')
  const [showErrors, setShowErrors] = useState(false)
  const optionValid = !options || !!selectedOption
  const inputValid = options ? (selectedOption === 'other' ? !!fieldName : true) : !!fieldName
  const descriptionValid = description && description !== ''
  const formatValid = selectedFormat && selectedFormat !== ''
  const optionError = showErrors && !optionValid ? '*Please select an option' : undefined
  const inputError = showErrors && !inputValid ? '*Please enter video type' : undefined
  const descriptionError = showErrors && !descriptionValid ? '*Please enter description/instructions' : undefined
  const formatError = showErrors && !formatValid ? '*Please enter content format' : undefined

  const formatOptions = [
    { value: 'video-audio', displayValue: 'Video or Audio' },
    { value: 'video-only', displayValue: 'Video only' },
    { value: 'audio-only', displayValue: 'Audio only' },
  ]

  const onFormClick = function () {
    if (!optionValid || !inputValid || !descriptionValid || !formatValid) {
      setShowErrors(true)
    } else {
      onFormSubmit({
        selectedOption,
        otherField: fieldName,
        selectedFormat,
        formatDisplay: formatOptions.filter(e => e.value === selectedFormat)[0].displayValue,
        description,
      })
    }
  }

  return (
    <div className="create-custom-video-chapter">
      <div className={'video-chapter-type-selector'}>
        {options && (
          <FormSelect
            label={'Chapter type'}
            value={selectedOption}
            options={options}
            error={optionError}
            onChange={e => setSelectedOption(e.target.value)}
          />
        )}
        {(!options || selectedOption === 'other') && (
          <FormInput
            name="fieldName"
            type="text"
            maxLength={60}
            autoFocus={!isMobile}
            autoComplete="off"
            value={fieldName}
            error={inputError}
            onChange={e => setFieldName(e.target.value)}
          />
        )}
      </div>
      <FormSelect
        label="Format"
        instructions="When you select ‘Video or Audio’ you are giving the applicant the choice to submit a response as either a video or audio recording"
        value={selectedFormat}
        error={formatError}
        options={formatOptions}
        onChange={e => setSelectedFormat(e.target.value)}
      />
      <div className={'description'}>
        <FormTextArea
          name="description"
          label="Description/instructions"
          value={description}
          error={descriptionError}
          onChange={e => setDescription(e.target.value)}
          textAreaRef={useForm()}
          placeholder="In one minute or less, outline what kind of work place culture you most enjoy working within."
          noLimit
        />
      </div>
      <div className={'video-chapter-bottom-button'}>
        <Button className={'primary edit-button'} onClick={onFormClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default CreateCustomVideoChapter
