import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { flattenChildren } from 'helpers/treeUtils'
import useContentBlock from 'state/useContentBlock'
import { ContentBlock, ContentBlockField, Preview } from 'state/types'
import './FormBlockEditor.scss'
import FormInput from 'components/shared/FormInput/FormInput'
import usePreview from 'state/usePreview'
import { getPreviewDetails } from '../../../services/previewService'

const getFormFields = (block: ContentBlock) => flattenChildren(block.contentBlockFields)

/**
 * Generate default value for content block field. Duplicated Preview that have empty or null `cbf` will cause error and Preview cannot be published.
 * Fix for [PM-1792](https://innovationcapital.atlassian.net/browse/PM-1792) bug.
 *
 * @param status
 * @param isDuplicate
 * @param contentBlock
 * @return { id: string, value: string }[]
 */
const dataContentBlockFields = (status: string | undefined, isDuplicate: boolean | undefined, contentBlock: ContentBlock | undefined) => {
  if (status && ["draft", "unpublished"].includes(status) && isDuplicate && contentBlock) {
    const cbf: ContentBlockField[] = JSON.parse(JSON.stringify(contentBlock.contentBlockFields)); // deep copy to prevent some nasty side effects.
    return cbf.map(field => {
      if (field.required) {
        const isValueEmpty = !field.value || field.value.trim().length === 0 || field.value === "";
        if (isValueEmpty) {
          let defaultValue = "";
          switch (field.controlType) {
            case "Dropdown":
              defaultValue = field.options ? Object.values(field.options)[0] : "";
              break;
            case "Text":
              defaultValue = field.formLabel;
              break;
            default:
              console.warn(`Unsupported control type: ${field.controlType}`);
              break;
          }
          return { id: field.id, value: defaultValue };
        }
      }
    }).filter(Boolean) as { id: string, value: string }[];
  }
  return [];
};

const TitleBlockEditor: React.FC<any> = ({ previewId, blockIndex, isPreviewPublished }) => {
  const [isSaveConfirmationDialogShowing, setSaveConfirmationDialogShowing] = useState(false)
  const {
    contentBlock,
    isValidationActive,
    updateValues,
    getValueUpdateStatusRecord,
    updateSessionValueUpdateStatusRecord,
    saveContentBlockValues
  } = useContentBlock(previewId, blockIndex)
  const block = contentBlock! // Can't be undefined inside this component.
  const { updateTitle, updateSubtitle, preview } = usePreview(previewId)

  const {
    register,
    handleSubmit,
    triggerValidation,
    reset,
    formState: { dirty },
  } = useForm({
    defaultValues: {
      title: preview ? preview.title : '',
      subtitle: preview ? preview.subtitle : '',
    },
  })

  const getValuesToSave = (formValues: FieldValues) => {
    // Only include values where the form value differs from the current store value.
    return getFormFields(block)
      ?.map(f => ({ id: f.id, value: f.value !== formValues[f.id] ? formValues[f.id] : undefined }))
      ?.filter(v => v.value !== undefined)
  }

  const updateValueUpdateStatusRecord = (valuesToUpdate: { id: string; value: any }[], status: boolean) => {
    const sessionRecord = getValueUpdateStatusRecord()
    let updatedRecord: Record<string, boolean> = sessionRecord ? sessionRecord : {}

    valuesToUpdate.forEach(valueToUpdate => {
      updatedRecord[valueToUpdate.id] = status
    })

    updateSessionValueUpdateStatusRecord(updatedRecord)
  }

  const [previewTitle, setPreviewTitle] = useState(preview ? preview.title : '')
  const [previewSubtitle, setPreviewSubtitle] = useState(preview ? preview.subtitle : '')

  // Note: debounce delay of 700ms here means we autosave after 700ms of inactivity.

  const onSubmit = handleSubmit(async formValues => {
    const valuesToSave = getValuesToSave(formValues)
    if (isPreviewPublished && dirty) {
      const ok = await updateValues(valuesToSave)
      if (ok) {
        reset(formValues)
        setSaveConfirmationDialogShowing(true)
        updateValueUpdateStatusRecord(valuesToSave, ok)
      }
    }
  })

  useEffect(() => {
    (async () => {
      const data = dataContentBlockFields(preview?.status, preview?.duplicate, contentBlock);
      if (data.length > 0) {
        await saveContentBlockValues(data);
      }
    })();
  }, [preview?.id]);

  useEffect(() => {
    if (isValidationActive) {
      triggerValidation()
    }
  }, [isValidationActive, triggerValidation])

  const [myTimeout, setMyTimeout] = useState<any>(null)

  const handleOnChange = (e: any) => {
    const value = e.target.value
    if (e.target.name === 'title') {
      //update title
      setPreviewTitle(value)
      if (myTimeout) {
        clearTimeout(myTimeout)
      }

      setMyTimeout(
        setTimeout(() => {
          //update title content block
          contentBlock?.contentBlockFields.forEach(async field => {
            if (field.formLabel === 'Title') {
              const ok = await updateValues([{ id: field.id, value: value }])
              if (ok !== undefined) {
                updateValueUpdateStatusRecord([{ id: field.id, value: value }], ok)
              }
            }
          })
          //update title on preview
          updateTitle(value)
        }, 700)
      )
      return
    }

    //update title
    setPreviewSubtitle(value)
    if (myTimeout) {
      clearTimeout(myTimeout)
    }

    setMyTimeout(
      setTimeout(() => {
        //update title content block
        contentBlock?.contentBlockFields.forEach(async field => {
          if (field.formLabel === 'Subtitle') {
            const ok = await updateValues([{ id: field.id, value: value }])
            if (ok !== undefined) {
              updateValueUpdateStatusRecord([{ id: field.id, value: value }], ok)
            }
          }
        })
        //update title on preview
        updateSubtitle(value)
      }, 700)
    )
    return
  }

  useEffect(() => {
    return () => {
      if (myTimeout) {
        clearTimeout(myTimeout)
      }
    }
  }, [])

  return (
    <form className="form-block-editor title-block" noValidate /*onSubmit={onSubmit}*/>
      <div className="form-fields">
        <FormInput
          name="title"
          label="Title*"
          inputRef={register}
          error={(isValidationActive || isPreviewPublished) && !previewTitle ? 'Please fill out this field' : undefined}
          onChange={handleOnChange}
        />

        <FormInput
          name="subtitle"
          label="Subtitle*"
          error={
            (isValidationActive || isPreviewPublished) && !previewSubtitle ? 'Please fill out this field' : undefined
          }
          inputRef={register}
          onChange={handleOnChange}
        />
      </div>
    </form>
  )
}

export default TitleBlockEditor
