import React, { useState } from 'react'
import MenuButton from 'components/shared/MenuButton/MenuButton'
import MoreOptionsIcon from 'assets/icons/MoreOptionsIcon'
import './OptionsMenuButton.scss'

type Props = {
  isShowing: boolean
  options: { option: string | React.ReactNode; className?: string; onClick?: () => void; hide?: boolean }[]
  setShowing: (showing: boolean) => void
}

const OptionsMenuButton: React.FC<Props> = ({ isShowing, options, setShowing }) => {
  const [noSpaceRight, setNoSpaceRight] = useState(false)

  const onAboutToShow = (rootElement: HTMLDivElement) => {
    const rootBounds = rootElement.getBoundingClientRect()
    const spaceRight = window.innerWidth - rootBounds.right
    setNoSpaceRight(spaceRight < 120)
  }

  if (options.findIndex(e => !e.hide) === -1) {
    return <></>
  }

  return (
    <MenuButton
      showing={isShowing}
      setShowing={setShowing}
      className={'options-menu-button' + (noSpaceRight ? ' no-space-right' : '')}
      buttonClassName="plain"
      buttonContent={
        <div className="icon-background">
          <MoreOptionsIcon />
        </div>
      }
      onAboutToShow={onAboutToShow}
    >
      {options
        .filter(o => !o.hide)
        .map(({ option, className, onClick }, i) => (
          <button
            key={i}
            className={'plain' + (className ? ' ' + className : '')}
            tabIndex={-1}
            onMouseDown={e => e.preventDefault()}
            onClick={() => {
              setShowing(false)
              onClick?.()
            }}
          >
            {option}
          </button>
        ))}
    </MenuButton>
  )
}

export default OptionsMenuButton
