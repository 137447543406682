import { getApi, getApiWithAuthorization } from './api'
import { OrganizationBrandSetting,BrandTheme } from 'state/types'

export const getPublicOrganizationSettingByOrganization = async (organizationId: string) => {
  const api = getApi()
  return api.get<OrganizationBrandSetting>(`/api/publicOrganizationSettings/organization/${organizationId}`)
}

export const getOrganizationBrandSettingByOrganization = async (organizationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<OrganizationBrandSetting>(`/api/organizationBrandSetting/organization/${organizationId}`)
}

export const updatePreviewReferenceDisabledByOrganization = async (organizationId: string, value: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<string>(
    `/api/organizationBrand/organization/${organizationId}/updatePreviewReferenceDisabled?value=${value}`
  )
}

export const updateCloudinaryStorageIdByOrganization = async (organizationId: string, cloudinaryStorageId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<string>(
    `/api/organizationBrand/organization/${organizationId}/updateCloudinaryStorageId?value=${cloudinaryStorageId}`
  )
}

export const updateFaviconCloudinaryStorageIdByOrganization = async (organizationId: string, faviconCloudinaryStorageId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<string>(
    `/api/organizationBrand/organization/${organizationId}/updateFaviconCloudinaryStorageId?value=${faviconCloudinaryStorageId}`
  )
}
export const updateBrandModeStorageIdByOrganization = async (organizationId: string, brandMode: any) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<string>(
    `/api/organizationBrand/organization/${organizationId}/brandMode/${brandMode}/updateBrandMode`
  )
}

export const updateThemeAllByOrganization = async (organizationId: string, theme: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<string>(
    `/api/preview/${organizationId}/updateAllTheme?theme=${theme}`
  )
}

export const updateBrandThemeByID = async (organizationId: string,id: string, brandMode: any) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<string>(
    `/api/brandTheme/${organizationId}/updateItem/${id}`,brandMode
  )
}
export const updateOrganizationBrandUrlByOrganization = async (organizationId: string, brandUrl: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<string>(`/api/organizationBrand/organization/${organizationId}/updateBrandUrl?value=${brandUrl}`)
}

export const getBrandThemeByID = async (organizationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<BrandTheme>(`/api/brandTheme/${organizationId}`)
}
