import React from 'react'
import { Link } from 'react-router-dom'
import './AnnouncementBanner.scss'

type Props = {
  isPreviewSuspended?: boolean
  isPaymentFailed?: boolean
}

const AnnouncementBanner: React.FC<Props> = ({ isPaymentFailed }) => {
  return (
    <div className={'announcement-banner ' + (isPaymentFailed ? ' payment-failure' : '')}>
      <div className="announement-text">
        <span>We weren't able to process your payment. To ensure your plan isn't downgraded please </span>
        <Link className="links" to={`/account/plan`}>
          update your payment details
        </Link>
      </div>
    </div>
  )
}

export default AnnouncementBanner
