import { FlatDocumentAssets, FlatVideoAssets, Invitation, Preview, PreviewSummary } from 'state/types'
import { compareDates } from 'helpers/date'
import { getDashboardTitles } from './titles'

export const sortingByKey = 'invitation-detail-page-sort-by'
export const sortingAscKey = 'invitation-detail-page-sort-asc'

export const getInitialSortingBy = () => 'firstPublishDate'
export const getInitialSortingAsc = () => {
  return false;
}

export const sortInvitationPreviews = (previews: PreviewSummary[] | undefined, sortBy: string, sortAsc: boolean) => {
  const sortFactor = sortAsc ? 1 : -1
  return previews?.sort((a, b) => {
    switch (sortBy) {
      case 'applicants':
        const personNameA = a.familyName && a.givenName ? a.familyName + ',' + a.givenName : a.familyName || a.givenName
        const personNameB = b.familyName && b.givenName ? b.familyName + ',' + b.givenName : b.familyName || b.givenName

        const nameA = a.name || personNameA
        const nameB = b.name || personNameB

        return nameA && nameB ? sortFactor * nameA.localeCompare(nameB) : 0
      case 'label':
        const labelA = a.label
        const labelB = b.label

        return labelA && labelB ? sortFactor * labelA.localeCompare(labelB) : 0
      case 'firstPublishDate':
        return a.firstPublishDate && b.firstPublishDate
          ? compareDates(a.firstPublishDate, b.firstPublishDate, sortAsc)
          : 0
      case 'starred':
        return !a.starred && b.starred ? sortFactor : !b.starred && a.starred ? -sortFactor : 0
      default:
        return 0
    }
  })
}

export const videoAssetsSortingByKey = 'video-aseets-page-sort-by'
export const videoAssetsSortingAscKey = 'video-aseets-page-sort-asc'

export const getVideoAssetsInitialSortingBy = () => localStorage.getItem(videoAssetsSortingByKey) || 'dateCreated'
export const getVideoAssetsInitialSortingAsc = () => {
  const stored = localStorage.getItem(videoAssetsSortingAscKey)
  return stored !== null ? stored === 'true' : false
}

export const sortFlatVideoAssets = (
  flatVideoAssets: FlatVideoAssets[] | undefined,
  sortBy: string,
  sortAsc: boolean
) => {
  const sortFactor = sortAsc ? 1 : -1
  return flatVideoAssets?.sort((a, b) => {
    switch (sortBy) {
      case 'title':
        return a.videoChapterName && b.videoChapterName
          ? sortFactor * a.videoChapterName.localeCompare(b.videoChapterName)
          : 0
      case 'previewName':
        return a.previewName && b.previewName ? sortFactor * a.previewName.localeCompare(b.previewName) : 0
      case 'dateCreated':
        return a.dateCreated && b.dateCreated ? compareDates(a.dateCreated, b.dateCreated, sortAsc) : 0
      default:
        return 0
    }
  })
}

export const documentAssetsSortingByKey = 'document-aseets-page-sort-by'
export const documentAssetsSortingAscKey = 'document-aseets-page-sort-asc'

export const getDocumentAssetsInitialSortingBy = () => localStorage.getItem(documentAssetsSortingByKey) || 'dateCreated'
export const getDocumentAssetsInitialSortingAsc = () => {
  const stored = localStorage.getItem(documentAssetsSortingAscKey)
  return stored !== null ? stored === 'true' : false
}

export const sortFlatDocumentAssets = (
  flatDocumentAssets: FlatDocumentAssets[] | undefined,
  sortBy: string,
  sortAsc: boolean
) => {
  const sortFactor = sortAsc ? 1 : -1
  return flatDocumentAssets?.sort((a, b) => {
    switch (sortBy) {
      case 'documentName':
        return a.documentName && b.documentName ? sortFactor * a.documentName.localeCompare(b.documentName) : 0
      case 'previewName':
        return a.previewName && b.previewName ? sortFactor * a.previewName.localeCompare(b.previewName) : 0
      case 'dateCreated':
        return a.dateCreated && b.dateCreated ? compareDates(a.dateCreated, b.dateCreated, sortAsc) : 0
      default:
        return 0
    }
  })
}

export const getPreviousAndNextPreviews = (
  previewId: string,
  previews: PreviewSummary[] | undefined,
  sortBy: string | undefined,
  sortAsc: boolean
) => {
  let previous: PreviewSummary | undefined
  let next: PreviewSummary | undefined

  if (previews && sortBy) {
    const sorted = sortInvitationPreviews(previews, sortBy, sortAsc)

    if (sorted) {
      const currentIndex = sorted.findIndex(p => p.id === previewId)
      if (currentIndex > 0) {
        previous = sorted[currentIndex - 1]
      }
      if (currentIndex < sorted.length - 1) {
        next = sorted[currentIndex + 1]
      }
    }
  }

  return { previous, next }
}

export const sortPreviewSummaries = (previewSummaries?: Preview[]) =>
  previewSummaries?.sort((a, b) => {
    if (a.archived !== b.archived) {
      return !a.archived ? -1 : 1
    } else if (a.status !== b.status && (a.status === 'published' || b.status === 'published')) {
      return a.status === 'published' ? -1 : 1
    }
    return 0
  })

export const sortInvitations = (invitations: Invitation[] | undefined, sortBy: string, sortAsc: boolean) => {
  const sortFactor = sortAsc ? 1 : -1
  return invitations?.sort((a, b) => {
    switch (sortBy) {
      case 'name':
        return a.name && b.name ? sortFactor * a.name.localeCompare(b.name) : 0
      case 'ownerName':
        return a.ownerName && b.ownerName ? sortFactor * a.ownerName.localeCompare(b.ownerName) : 0
      case 'endDate':
        if (a.endDate && !b.endDate) {
          return sortFactor * 1
        } else if (!a.endDate && b.endDate) {
          return sortFactor * -1
        } else if (a.endDate && b.endDate) {
          return compareDates(a.endDate, b.endDate, sortAsc)
        }
        return 0
      case 'status':
        const aClosed = a.closed ? a.closed : false
        const bClosed = b.closed ? b.closed : false
        if (sortAsc) {
          return aClosed === bClosed ? 0 : aClosed ? -1 : 1
        } else {
          return aClosed === bClosed ? 0 : aClosed ? 1 : -1
        }
      case 'previewCount':
        const aPreviewCount = a.previewCount ? a.previewCount : 0
        const bPreviewCount = b.previewCount ? b.previewCount : 0
        return sortAsc ? aPreviewCount - bPreviewCount : bPreviewCount - aPreviewCount
      default:
        return 0
    }
  })
}

export const sortPreviews = (previews: Preview[] | undefined, sortBy: string, sortAsc: boolean) => {
  const sortFactor = sortAsc ? 1 : -1
  const lastCharacter = '~'
  return previews?.sort((a, b) => {
    switch (sortBy) {
      case 'status':
        const statusA: string = a.status === 'published' ? 'published' + (a.firstPublishDate || '') : 'draft'
        const statusB: string = b.status === 'published' ? 'published' + (b.firstPublishDate || '') : 'draft'

        return statusA && statusB ? sortFactor * statusA.localeCompare(statusB) : 0
      case 'title_1': {
        const titleA: string | undefined = getDashboardTitles(a).title
        const titleB: string | undefined = getDashboardTitles(b).title

        return titleA && titleB ? sortFactor * titleA.localeCompare(titleB) : 0
      }
      case 'title_2': {
        const titleA: string = getDashboardTitles(a).subtitle || lastCharacter
        const titleB: string = getDashboardTitles(b).subtitle || lastCharacter

        return titleA && titleB ? sortFactor * titleA.localeCompare(titleB) : 0
      }
      case 'creator': {
        const creatorA: string = a.givenName || lastCharacter + a.familyName || lastCharacter
        const creatorB: string = b.givenName || lastCharacter + b.familyName || lastCharacter

        return creatorA && creatorB ? sortFactor * creatorA.localeCompare(creatorB) : 0
      }

      case 'views': {
        const viewsA = a.views || 0
        const viewsB = b.views || 0

        return sortFactor * (viewsA - viewsB)
      }
      default:
        return 0
    }
  })
}
