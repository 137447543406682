import React from 'react'

const MoreOptionsIcon = () => (
  <svg className="icon" width="21px" height="5px" viewBox="0 0 21 5">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g>
        <circle cx="18.5" cy="2.5" r="2.5"></circle>
        <circle cx="10.5" cy="2.5" r="2.5"></circle>
        <circle cx="2.5" cy="2.5" r="2.5"></circle>
      </g>
    </g>
  </svg>
)

export default MoreOptionsIcon
