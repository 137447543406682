import * as React from 'react'

const CopyIcon = () => (
  <svg className="icon" width="19px" height="22px" viewBox="0 0 19 22">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1054.000000, -332.000000)">
        <g transform="translate(1054.000000, 332.000000)" stroke="#01A3F8" strokeWidth="1.2">
          <rect fill="#FFFFFF" x="0.6" y="0.6" width="13.8416667" height="16.7259259" rx="1.152"></rect>
          <rect fill="#FFFFFF" x="4.55833333" y="4.67407407" width="13.8416667" height="16.7259259" rx="1.152"></rect>
        </g>
      </g>
    </g>
  </svg>
)

export default CopyIcon
