import React from 'react'
import PlayIcon from 'assets/icons/PlayIcon'
import './PlayPauseButtonOverlay.scss'

type Props = {
  isPlaying: boolean
  togglePlaying: () => void
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const PlayPauseButtonOverlay = ({ isPlaying, togglePlaying, className, ...rest }: Props) => (
  <button
    type="button"
    className={'play-pause-button-overlay' + (isPlaying ? ' playing' : '') + (className ? ' ' + className : '')}
    onClick={togglePlaying}
    {...rest}
  >
    <PlayIcon />
  </button>
)

export default PlayPauseButtonOverlay
