import React, { useEffect, useState } from 'react'
import { ContentBlockField } from 'state/types'
import useContentBlockField from 'state/useContentBlockField'
import FormFieldEditor from 'components/preview/FormFieldEditor/FormFieldEditor'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import './FormListEditor.scss'
import { fieldValueExists } from '../../../helpers/validation'
import HintDialog from './HintDialog'

type Props = {
  label: string
  field: ContentBlockField
  previewId: string
  blockIndex: number
  fieldIndex?: number
  register: any
  control: any
  inGroup?: boolean
  error?: string
  errors?: any
  setValue?: (name?: string, value?: string) => void
}

const FormListEditor: React.FC<Props> = ({
  label,
  field,
  previewId,
  blockIndex,
  fieldIndex,
  register,
  control,
  error,
  errors,
  setValue,
  inGroup,
}) => {
  const { isAddingToList, addToList, removeFromList } = useContentBlockField(
    previewId,
    blockIndex,
    fieldIndex,
    field?.id
  )
  const controlType = field.children?.[0]?.controlType
  const addButtonText =
    controlType === 'Website' ? 'Add another link' : controlType === 'TeamMember' ? 'Add team member' : 'Add another'
  const containsDetails: boolean = controlType === 'Details'
  const [showManualDetailsEntryList, setShowManualDetailsEntryList] = useState<(boolean | undefined)[]>([undefined])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [listChildren, setListChildren] = useState<ContentBlockField[] | undefined>([])
  const showAddButton = !listChildren || listChildren.length <= 30
  const [showingHint, setShowingHint] = useState<boolean>(false)

  useEffect(() => {
    const createInitialDetails = async () => {
      if (!containsDetails) {
        setListChildren(field.children)
        return
      }
      if (!field.children) return

      setLoading(true)
      field.children = field.children?.sort((a, b) => {
        if (a.children && b.children) {
          if (!a.children[0].value && !b.children[0].value) {
            return 0
          } else if (!a.children[0].value && b.children[0].value) {
            return 1
          } else {
            return -1
          }
        } else {
          return 0
        }
      })

      const updatedShowManualDetailsEntryList: (boolean | undefined)[] = [undefined]
      const updatedContentBlockFields: ContentBlockField[] = [field.children[0]]

      for (let index = 1; index < field.children.length; index++) {
        const child: ContentBlockField = field.children[index]
        if (child.children && child.children[1].value !== undefined) {
          updatedShowManualDetailsEntryList.push(false)
          updatedContentBlockFields.push(child)
        } else if (child.children && fieldValueExists(child.children[0])) {
          updatedShowManualDetailsEntryList.push(true)
          updatedContentBlockFields.push(child)
        } else {
          await removeFromList(child.id)
        }
      }

      setListChildren(updatedContentBlockFields)
      setShowManualDetailsEntryList(updatedShowManualDetailsEntryList)
      setLoading(false)
    }

    createInitialDetails()
  }, [])

  const handleAddClick = async (state?: boolean): Promise<void> => {
    if (containsDetails) {
      setShowManualDetailsEntryList([...showManualDetailsEntryList, state])
    }
    const addedChild: ContentBlockField | undefined = await addToList()
    if (addedChild && listChildren) {
      setListChildren([...listChildren, addedChild])
    }
  }

  const handleRemoveClick = async (child: ContentBlockField, index: number) => {
    if (listChildren) {
      setListChildren(listChildren.filter((item, i) => i !== index))
    }
    setShowManualDetailsEntryList(showManualDetailsEntryList.filter((item, i) => i !== index))
    await removeFromList(child.id)
  }

  return (
    <div className={'form-list-editor ' + (inGroup ? '' : 'bordered')}>
      {(label || error) && (
        <div className="form-input-label-row">
          {label && <div className="list-label">{label}</div>}
          {error && <div className="form-input-validation-error">{error}</div>}
        </div>
      )}
      {isLoading ? (
        <LoadingSpinner container />
      ) : (
        <>
          <div className="list-items">
            {listChildren &&
              listChildren.map((child, i) => {
                return (
                  <FormFieldEditor
                    key={child.id}
                    previewId={previewId}
                    blockIndex={blockIndex}
                    field={child}
                    register={register}
                    hideDetails={i === 0 && containsDetails ? true : i > 0 && containsDetails ? false : undefined}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    showManualDetailsEntry={showManualDetailsEntryList[i]}
                    removeFromList={
                      !containsDetails
                        ? listChildren.length > 1
                          ? () => handleRemoveClick(child, i)
                          : undefined
                        : () => handleRemoveClick(child, i)
                    }
                  />
                )
              })}
          </div>
          {!containsDetails && showAddButton && (
            <button type="button" className="add-list-item-button link" onClick={() => handleAddClick()}>
              {isAddingToList && <LoadingSpinner container />}
              {'+ ' + addButtonText}
            </button>
          )}
          {containsDetails && showAddButton && (
            <div className="add-details-item-buttons">
              <button type="button" className="add-preview-link-button link" onClick={() => handleAddClick(false)}>
                {isAddingToList && <LoadingSpinner container />}
                {'+ Add via preview link'}
              </button>
              <div
                className="info-icon"
                onClick={() => {
                  setShowingHint(!showingHint)
                }}
              >
                i
              </div>
              <HintDialog isShowing={showingHint} onClose={() => setShowingHint(false)} />
              <button type="button" className="add-details-manually link" onClick={() => handleAddClick(true)}>
                {isAddingToList && <LoadingSpinner container />}
                {'+ Add manually'}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FormListEditor
