import React from 'react'
import ConfirmationDialog from '../../shared/ConfirmationDialog/ConfirmationDialog'

interface Props {
  onClose: () => void
  onOk: () => void
}

const BulkArchiveConfirmationDialog = function ({ onClose, onOk }: Props) {
  return (
    <ConfirmationDialog
      isShowing={true}
      isWarning
      cancelButtonText="No, cancel that"
      okButtonText={`Yes, Archive Previews`}
      title={`Are you sure you want to Archive?`}
      onClose={onClose}
      onOk={onOk}
    >
      By archiving your Previews, your links will no longer be valid and your audience will lose access to your content.
    </ConfirmationDialog>
  )
}

export default BulkArchiveConfirmationDialog
