import React, { useEffect, useState } from 'react'
import './NumericalInput.scss'
import { Dimension, LengthUnits, UnitType, WeightUnits } from '../../../helpers/enum'
import {
  convertImperialInchLengthToMetricCentimetre,
  convertImperialLengthToMetric,
  convertImperialWeightToMetric,
  convertMetricCentimetreLengthToImperial,
  convertMetricLengthToImperial,
  convertMetricWeightToImperial,
} from '../../../helpers/units'
import Instructions from '../../shared/Instructions/Instructions'

type Props = {
  dimension: Dimension
  unitType: UnitType
  formLabel?: string
  instructions?: string | undefined
  value?: string
  onChange?: (value?: string) => void
}

const INCH_MAX: number = 12
const VALUE_MIN: number = 0

const NumericalInput: React.FC<Props> = ({ dimension, unitType, formLabel, instructions, value, onChange }) => {
  const [currentUnit, setCurrentUnit] = useState<string>()
  const [feetValue, setFeetValue] = useState<number | undefined>(NaN)
  const [inchValue, setInchValue] = useState<number | undefined>(NaN)
  const [numericalValue, setNumericalValue] = useState<number | undefined>(NaN)

  useEffect(() => {
    setUnit()
    setInitialValue()
  }, [dimension, unitType])

  const setUnit = (): void => {
    if (unitType === UnitType.Metric) {
      if (dimension === Dimension.Weight) {
        setCurrentUnit(WeightUnits.Kilogram)
      } else if (dimension === Dimension.Length) {
        setCurrentUnit(LengthUnits.Meter)
      } else if (dimension === Dimension.SmallLength) {
        setCurrentUnit(LengthUnits.Centimetre)
      }
    } else if (unitType === UnitType.Imperial) {
      if (dimension === Dimension.Weight) {
        setCurrentUnit(WeightUnits.Pound)
      } else if (dimension === Dimension.Length) {
        setCurrentUnit(LengthUnits.Feet)
      } else if (dimension === Dimension.SmallLength) {
        setCurrentUnit(LengthUnits.Inch)
      }
    }
  }

  const setInitialValue = (): void => {
    if (value) {
      const floatValue: number = parseFloat(value)
      if (unitType === UnitType.Metric) {
        const floatValue2Decimal: number = parseFloat(floatValue.toFixed(2))
        setNumericalValue(floatValue2Decimal)
      } else if (unitType === UnitType.Imperial) {
        if (dimension === Dimension.Weight) {
          const valueToPound = convertMetricWeightToImperial(floatValue)
          const valueToPound2Decimal: number = parseFloat(valueToPound.toFixed(2))
          setNumericalValue(valueToPound2Decimal)
        } else if (dimension === Dimension.Length) {
          const valueToFeetInch = convertMetricLengthToImperial(floatValue)
          setFeetValue(valueToFeetInch.feet)
          setInchValue(valueToFeetInch.inch)
        } else if (dimension === Dimension.SmallLength) {
          const valueCentimetreToInch = convertMetricCentimetreLengthToImperial(floatValue)
          setInchValue(valueCentimetreToInch.inch)
        }
      }
    }
  }

  const handleFeetValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const feet: number = parseInt(e.target.value)
    setFeetValue(feet)
    let valueToSave: number = NaN
    if (!(inchValue === undefined || isNaN(inchValue!)) && isNaN(feet)) {
      valueToSave = convertImperialLengthToMetric(VALUE_MIN, inchValue!)
    } else if (Number.isInteger(feet) && feet >= VALUE_MIN) {
      if (inchValue === undefined || isNaN(inchValue)) {
        valueToSave = convertImperialLengthToMetric(feet, VALUE_MIN)
      } else {
        valueToSave = convertImperialLengthToMetric(feet, inchValue)
      }
    }
    notifyChange(valueToSave)
  }

  const handleInchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inch: number = parseInt(e.target.value)
    setInchValue(inch)
    let valueToSave: number = NaN
    if (!(feetValue === undefined || isNaN(feetValue)) && isNaN(inch)) {
      valueToSave = convertImperialLengthToMetric(feetValue, VALUE_MIN)
    } else if (Number.isInteger(inch) && inch >= VALUE_MIN && inch < INCH_MAX) {
      if (feetValue === undefined || isNaN(feetValue)) {
        valueToSave = convertImperialLengthToMetric(VALUE_MIN, inch)
      } else {
        valueToSave = convertImperialLengthToMetric(feetValue, inch)
      }
    }
    notifyChange(valueToSave)
  }

  /**
   * We handle inch only fields by themselves as there are no restrictions on how
   * many inches can be inputted
   */
  const handleInchOnlyValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inch: number = parseInt(e.target.value)
    setInchValue(inch)
    notifyChange(convertImperialInchLengthToMetricCentimetre(inch))
  }

  const handleNumericalValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let numericalValue: number = parseFloat(e.target.value)
    setNumericalValue(numericalValue)

    if (unitType === UnitType.Imperial && dimension === Dimension.Weight) {
      numericalValue = convertImperialWeightToMetric(numericalValue)
    }

    notifyChange(numericalValue)
  }

  const unitField = (
    value: number | undefined,
    handler: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    unitLabel: string | undefined
  ) => {
    return (
      <div className="numerical-input">
        <input type="number" pattern="[0-9]*" value={String(value)} onChange={handler} />
        <label className="units">{unitLabel}</label>
      </div>
    )
  }

  /**
   * We handle the rendering of the fields in this function because it's easier to read the logic
   */
  const renderFields = () => {
    if (currentUnit === LengthUnits.Feet) {
      return (
        <>
          {unitField(feetValue, handleFeetValueChange, LengthUnits.Feet)}
          {unitField(inchValue, handleInchValueChange, LengthUnits.Inch)}
        </>
      )
    } else if (currentUnit === LengthUnits.Inch) {
      return unitField(inchValue, handleInchOnlyValueChange, LengthUnits.Inch)
    } else {
      return unitField(numericalValue, handleNumericalValueChange, currentUnit)
    }
  }

  const notifyChange = (v: number) => onChange?.(JSON.stringify(v))

  return (
    <div className="form-input-container">
      <div className="top-label-row">
        <label>{formLabel}</label>
        {instructions && <Instructions instructions={instructions} />}
      </div>
      <div className="inputs">{renderFields()}</div>
    </div>
  )
}

export default NumericalInput
