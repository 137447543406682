import React, { ReactNode } from 'react';
import './ModalConfirmation.scss'

interface ModalProps {
  show: boolean;
  children: ReactNode;
  onYes: () => void;
  onNo: () => void;
  onClose: () => void;

}

const ModalConfirmation: React.FC<ModalProps> = ({ show, children, onYes, onNo,onClose }) => {

    if (!show) {
    return null;
  }

  return (
    <>
    <div className="modal-overlay"></div>
    <div className="modal">
      <div className="modal-content">
        <span className="close-icon" onClick={onClose}>&times;</span>
        {children}
        <div className="modal-actions">
          <button onClick={onNo}>No</button>
          <button onClick={onYes}>Yes</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default ModalConfirmation;

