import ConfirmationDialog from '../../shared/ConfirmationDialog/ConfirmationDialog'
import React from 'react'

interface Props {
  onClose: () => void
  onOk: () => void
}

const BulkDeleteConfirmationDialog = function ({ onClose, onOk }: Props) {
  return (
    <ConfirmationDialog
      isShowing={true}
      isWarning
      cancelButtonText="No, thanks"
      okButtonText={`Yes, delete Previews`}
      title="Heads up!"
      onClose={onClose}
      onOk={onOk}
    >
      This will permanently delete all videos and other content within. Are you sure you wish to continue?
    </ConfirmationDialog>
  )
}

export default BulkDeleteConfirmationDialog
