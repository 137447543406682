import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useDocument from 'state/useDocument'
import FileSelectorButton from 'components/shared/FileSelectorButton/FileSelectorButton'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import ProgressBar from 'components/shared/ProgressBar/ProgressBar'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import SingleFieldForm from 'components/shared/SingleFieldForm/SingleFieldForm'
import UploadIcon from 'assets/icons/UploadIcon'
import CrossIcon from 'assets/icons/CrossIcon'
import PenIcon from 'assets/icons/PenIcon'
import AssetsIcon from 'assets/icons/AssetsIcon'
import './DocumentCard.scss'

const documentFileSizeLimitInMb = 50
const documentFileSizeLimitInBytes = documentFileSizeLimitInMb * 1e6

type Props = {
  previewId: string
  blockIndex: number
  fieldIndex: number
}

const DocumentCard: React.FC<Props> = ({ previewId, blockIndex, fieldIndex }) => {
  const {
    contentBlockField,
    contentBlock,
    isUploading,
    progress,
    isValidationActive,
    uploadDocument,
    deleteUploadedDocument,
    remove,
    rename,
  } = useDocument(previewId, blockIndex, fieldIndex)
  const baseLink = `/edit/${previewId}/document/${blockIndex}`
  const assetsLink = `${baseLink}/assets/${fieldIndex}`
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)
  const [isDeleteConfirmationDialogShowing, setDeleteConfirmationDialogShowing] = useState(false)
  const [isRenameDocumentDialogShowing, setRenameDocumentDialogShowing] = useState(false)
  const field = contentBlockField!
  const showValidationError = isValidationActive && field.required && !field.value

  const onSelectedFileChange = async (file: File | null) => {
    if (file) {
      if (file.size > documentFileSizeLimitInBytes) {
        setFileSizeErrorDialogShowing(true)
      } else {
        uploadDocument(file)
      }
    }
  }

  const onDeleteButtonClick = () => {
    if (field.value) {
      setDeleteConfirmationDialogShowing(true)
    } else {
      remove()
    }
  }

  const deleteDocumentAndRemoveField = async () => {
    await deleteUploadedDocument()
    remove()
  }

  return (
    <div
      className={
        'document-card' + (!field.required ? ' can-delete' : '') + (showValidationError ? ' show-validation-error' : '')
      }
    >
      <div className="document-card-content">
        {isUploading ? (
          <ProgressBar progress={progress} />
        ) : (
          <>
            <div className="left-column">
              <div className="document-label-container">
                <button
                  className="edit-document-label-button plain"
                  onClick={() => setRenameDocumentDialogShowing(true)}
                  disabled={field.required || !contentBlock?.editable}
                >
                  <div className="document-label">{field.formLabel}</div>
                  {!field.required && <PenIcon />}
                </button>
              </div>
              {field.value && (
                <a href={field.value} className="document-link" target="_blank" rel="noopener noreferrer">
                  <span>{decodeURIComponent(field.value.substring(field.value.lastIndexOf('/') + 1))}</span>
                </a>
              )}
            </div>
            <FileSelectorButton
              accept=".doc,.docx,.rtf,.rtx,.odt,.pdf"
              onSelectedFileChange={onSelectedFileChange}
              className="upload-document-button link"
            >
              <UploadIcon />
              <span>{field.value ? 'Replace' : 'Upload'}</span>
            </FileSelectorButton>
            <Link className="assets-link button" to={assetsLink}>
              <div className="assets-icon-container">
                <AssetsIcon />
              </div>
              <span>Add from Assets</span>
            </Link>
          </>
        )}
      </div>
      {!field.required && !!contentBlock?.editable && (
        <button className="delete-field-button" onClick={onDeleteButtonClick}>
          <div className="icon-container">
            <CrossIcon />
          </div>
        </button>
      )}
      <ConfirmationDialog
        isShowing={isDeleteConfirmationDialogShowing}
        cancelButtonText="No, cancel that"
        okButtonText="Yes, delete file"
        title="Heads up!"
        isWarning
        onClose={() => setDeleteConfirmationDialogShowing(false)}
        onOk={deleteDocumentAndRemoveField}
        className="delete-document-confirmation-dialog"
      >
        The file you have uploaded will also be deleted. Would you like to continue?
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Document too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
        className="document-file-size-error-dialog"
      >
        {`This document is too large. Please choose a file smaller than ${documentFileSizeLimitInMb} MB.`}
      </ConfirmationDialog>
      <CloseableDialog
        className="rename-document-dialog"
        isShowing={isRenameDocumentDialogShowing}
        onClose={() => setRenameDocumentDialogShowing(false)}
      >
        <h2>Rename Document</h2>
        <SingleFieldForm
          placeholder="Chapter title"
          validationErrorText="Please enter a title for your chapter"
          submitButtonText="Save"
          initialValue={contentBlockField?.formLabel!}
          triggerReset={isRenameDocumentDialogShowing}
          onCancel={() => setRenameDocumentDialogShowing(false)}
          onSubmit={async (name: string) => {
            const ok = await rename(name)
            if (ok) {
              setRenameDocumentDialogShowing(false)
            }
          }}
        />
      </CloseableDialog>
    </div>
  )
}

export default DocumentCard
