import React, { useState } from 'react'
import { Option } from 'state/types'
import DropdownBase from 'components/shared/DropdownBase/DropdownBase'
import './Dropdown.scss'

type Props = {
  id?: string
  className?: string
  selectedValue?: string
  placeholder?: string
  options?: Option[]
  onChange?: (value: string) => void
  renderOption?: (value?: string, displayValue?: string) => React.ReactNode | null
  renderSelectedOption?: (value?: string, displayValue?: string) => React.ReactNode | null
}

const Dropdown: React.FC<Props> = ({
  id,
  className,
  selectedValue,
  placeholder,
  options,
  onChange,
  renderOption,
  renderSelectedOption,
}) => {
  const [isPopupShowing, setPopupShowing] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const displayValue = options?.find(option => option.value === selectedValue)?.displayValue

  const onOptionSelected = (selectedValue: string) => {
    onChange?.(selectedValue)
    setPopupShowing(false)
  }

  const onPopupShowing = () => {
    const indexToSet = options?.findIndex(option => option.value === selectedValue)
    if (indexToSet !== undefined) {
      setSelectedIndex(indexToSet)
    }
  }

  return (
    <DropdownBase
      id={id}
      className={'dropdown' + (className ? ' ' + className : '')}
      isPopupShowing={isPopupShowing}
      selectedIndex={selectedIndex}
      options={options}
      rootElementProps={{ role: 'listbox', tabIndex: 0, onMouseDown: e => e.preventDefault() }}
      setPopupShowing={setPopupShowing}
      setSelectedIndex={setSelectedIndex}
      onOptionSelected={onOptionSelected}
      renderOption={renderOption}
      onPopupShowing={onPopupShowing}
    >
      <div className="toggle-popup-button" onClick={() => setPopupShowing(!isPopupShowing)}>
        {renderSelectedOption ? (
          renderSelectedOption(selectedValue, displayValue)
        ) : (
          <div className={'selected-option' + (!displayValue ? ' empty' : '')}>{displayValue || placeholder}</div>
        )}
      </div>
    </DropdownBase>
  )
}

export default Dropdown
