import React from 'react'

const CrossIcon = () => (
  <svg className="icon" width="13px" height="12px" viewBox="0 0 13 12">
    <g strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g transform="translate(-1249.000000, -436.000000)" strokeWidth="1.6">
        <g transform="translate(1255.500000, 442.000000) rotate(-45.000000) translate(-1255.500000, -442.000000) translate(1248.000000, 435.000000)">
          <path d="M7.5,4.54747351e-13 L7.5,14"></path>
          <path
            d="M7.5,0 L7.5,14"
            transform="translate(7.500000, 7.000000) rotate(-270.000000) translate(-7.500000, -7.000000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default CrossIcon
