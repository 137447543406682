import React from 'react'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import DropdownMenuOption from '../DropdownMenuOption/DropdownMenuOption'
import './Filter.scss'

type Props = {
  filterName: string
  filterOptions: string[]
  selectedFilterOption: string
  onSelect: (value: string) => void
}

const Filter: React.FC<Props> = ({ filterName, filterOptions, selectedFilterOption, onSelect }) => {
  const handleFilterOptionSelect = (selectedFilterOption: string) => {
    onSelect(selectedFilterOption)
  }

  return (
    <div className="filter">
      <DropdownMenu
        trigger={
          <button className="button">
            {filterName}: <b>{selectedFilterOption}</b>
          </button>
        }
        menuContent={
          <>
            {filterOptions.map((option, index) => {
              return (
                <DropdownMenuOption
                  key={index}
                  optionName={option}
                  onSelect={() => handleFilterOptionSelect(option)}
                  selectedOption={selectedFilterOption === option}
                  showCheckbox
                />
              )
            })}
          </>
        }
        horizontalAlignment="left"
      />
    </div>
  )
}

export default Filter
