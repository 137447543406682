import React, { useCallback, useEffect, useState } from 'react'
import FileSelector from '../Components/FileSelector/FileSelector'
import useVideoChapter from '../../../../state/useVideoChapter'
import { parseIndexParam } from '../../../../helpers/urlParams'
import { useParams } from 'react-router-dom'
import { getWatermarkImage } from '../../../../helpers/cloudinary'
import useStore from '../../../../state/useStore'
import {
  imageFileSizeLimitInBytes,
  imageFileSizeLimitInMb,
  watermarkImageFileSizeLimitInBytes,
  watermarkImageFileSizeLimitInKb
} from '../../../../helpers/media'
import * as imageService from '../../../../services/imageService'
import './AudioImage.scss'
import useWorkspace from '../../../../state/useWorkspace'
import LoadingSpinner from '../../../shared/LoadingSpinner/LoadingSpinner'
import ConfirmationDialog from '../../../shared/ConfirmationDialog/ConfirmationDialog'

const ProfileImage = () => {
  const { state, dispatch} = useStore()
  const [isUploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const { previewId, index, fieldIndex } = useParams<any>()
  const [isProfileImageChanging, setIsProfileImageChanging] = useState(false)
  const {  videoChapter, setProfileImageStorageId } = useVideoChapter(
    previewId,
    parseIndexParam(index),
    parseIndexParam(fieldIndex),
    true
  )
  const { currentWorkspace, updateOrganizationProfileImageStorageId, updateUserProfileImageStorageId } =
    useWorkspace(true)
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)



  useEffect(() => {
    if(videoChapter?.profileImageStorageId){
      setProfileImageStorageId(videoChapter?.profileImageStorageId)
    }
    else{
      setProfileImageStorageId("")
    }
  }, [])

  const onSelectedFileChange = async (file: any) => {
    if (file) {
      if (file.size > imageFileSizeLimitInBytes) {
        setFileSizeErrorDialogShowing(true)
      } else {
        setUploading(true)
        setProgress(0)
        try {
          const { status, storageId } = await imageService.uploadImage(file, setProgress)
          if(status === 'success' && !!storageId){
            setProfileImageStorageId(storageId)
          }
        } catch (error){
          console.error('Error uploading image:', error);
          setProfileImageStorageId("");
        }
        setUploading(false)
      }

    }
  }
  const onDeleteImageClick = () => {
    setProfileImageStorageId("")
  }

  const onUseProfileImageClick = () => {
    if(currentWorkspace && currentWorkspace.profileImageStorageId){
      setProfileImageStorageId(currentWorkspace.profileImageStorageId)
    }
  }

  const disableCondition1 = !(state.currentWorkspace?.profileImageStorageId && (state.profileImage !== (currentWorkspace?.type === "organization" ?
    state.currentWorkspace?.profileImageStorageId: state.user?.profileImageStorageId)))
  const disableCondition2 = !(state.profileImage && (state.profileImage !== (currentWorkspace?.type === "organization" ?
    state.currentWorkspace?.profileImageStorageId: state.user?.profileImageStorageId)))

  const onSaveAsProfileImage = async () => {
    setIsProfileImageChanging(true)
    if (!currentWorkspace || !state.profileImage) {
      setIsProfileImageChanging(false)
      return
    }
    if (currentWorkspace.type === 'organization') {
      await updateOrganizationProfileImageStorageId(state.profileImage)
    } else {
      await updateUserProfileImageStorageId(state.profileImage)
    }
    setIsProfileImageChanging(false)
  }


  return (
    <div className={'audio-image'}>
      <FileSelector
        onSelectedFileChange={onSelectedFileChange}
        onDeleteImageClick={onDeleteImageClick}
        value={state.profileImage}
        disable={false}
        isUploading={isUploading}
        uploadProgress={progress}
        imageFormat={"audio-image"}
        />

      <div className={'audio-image-buttons-container'}>
          <button
            disabled={disableCondition1}
            onClick={onUseProfileImageClick}
            className={`audio-image-button button ${disableCondition1 && 'disabled'}`}>
            Use Profile Image
          </button>
          <button
            disabled={disableCondition2}
            onClick={onSaveAsProfileImage}
            className={`audio-image-button button ${disableCondition2 && 'disabled'}`}>
            {isProfileImageChanging ? <LoadingSpinner/>:"Save as Profile Image"}
          </button>
      </div>
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Image too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
      >
        {`This image is too large. Please choose an image file smaller than ${imageFileSizeLimitInMb} MB.`}
      </ConfirmationDialog>
    </div>
  )
}

export default ProfileImage