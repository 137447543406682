import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import "./InputType.scss";
import useStore from '../../../../../state/useStore'

type Props = {
  unit:string;
  onOpacityChange: (value: string,unit: string) => void;
  opacity: string
  setOpacity: (data: number) => void;
  disabled: boolean
};

const InputType: React.FC<Props> = ({ unit,onOpacityChange, opacity, setOpacity, disabled }) => {
  const { state, dispatch } = useStore()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setOpacity(Number(value));
    onOpacityChange(value,unit)
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value, 10);
    value = isNaN(value) ? 100 : Math.max(0, Math.min(100, value));
    setOpacity(value);
    onOpacityChange(value.toString(),unit)

  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let value = parseInt(opacity, 10);

    if (e.key === 'ArrowUp' && (unit === 'px' || value < 100)) {
      setOpacity(value + 1);
      onOpacityChange(`${value + 1}`,unit)

    } else if (e.key === 'ArrowDown' && value > 0) {
      setOpacity(value - 1);
      onOpacityChange(`${value - 1}`,unit)

    }
  };

  return (
    <>
      <div className="percent-input-container">
        <input
          type="text"
          value={`${disabled? '100' : opacity}${unit === 'percent' ? '%' : ''}`}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default InputType;
