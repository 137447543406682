import React from 'react'
import { formatDate } from 'helpers/date'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'

type Props = {
  isShowing: boolean
  isClosed?: boolean
  endDate?: string
  isArchiving?: boolean
  invitationOwner?: string | null
  onClose: () => void
  onOk: () => void
}

const UnpublishConfirmationDialog: React.FC<Props> = ({
  isShowing,
  isClosed,
  endDate,
  isArchiving,
  invitationOwner,
  onClose,
  onOk,
}) => {
  const formattedEndDate = endDate && formatDate(endDate)

  return (
    <ConfirmationDialog
      className="unpublish-preview-confirmation-dialog"
      isShowing={isShowing}
      isWarning
      cancelButtonText="No, cancel that"
      okButtonText={`Yes, ${isArchiving ? 'archive' : 'unpublish'} Preview`}
      title={`Are you sure you want to ${isArchiving ? 'archive' : 'unpublish'}?`}
      onClose={onClose}
      onOk={onOk}
    >
      {isClosed ? (
        <>
          <p>
            Applications closed on {formattedEndDate}. By {isArchiving ? 'archiving' : 'unpublishing'} your Preview you
            are withdrawing your application.
          </p>
          <p>Do you want to withdraw your application?</p>
        </>
      ) : invitationOwner ? (
        <>
          <p>
            If you {isArchiving ? 'archive' : 'unpublish'} your Preview, {invitationOwner} will no longer be able to
            view it. Do you wish to proceed?
          </p>
        </>
      ) : (
        <>
          <p>
            By {isArchiving ? 'archiving' : 'unpublishing'} your Preview, your link will no longer be valid and your
            audience will lose access to your content.
          </p>
          {!isArchiving && (
            <p>If you are unpublishing to work on your Preview, remember to re-publish once you're done.</p>
          )}
        </>
      )}
    </ConfirmationDialog>
  )
}

export default UnpublishConfirmationDialog
