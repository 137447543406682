import React from 'react'
import SharingLinks from 'components/shared/SharingLinks/SharingLinks'

type Props = {
  previewId: string
}

const PreviewSharingLinks: React.FC<Props> = ({ previewId }) => {
  const origin = window.location.origin

  // Use test-app instead of localhost because localhost will be rejected:
  const baseUrl = origin?.includes('localhost') ? 'https://test-app.previewme.com' : origin
  const previewUrl = `${baseUrl}/p/${previewId}`

  const emailBody = encodeURIComponent(`Check out my awesome Preview:\n\n${previewUrl}`)

  return <SharingLinks url={previewUrl} emailBody={emailBody} />
}

export default PreviewSharingLinks
