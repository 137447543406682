import React from 'react'
import { formatVideoTime } from 'helpers/media'
import Slider from 'components/shared/Slider/Slider'
import FullscreenIcon from 'assets/icons/FullscreenIcon'
import './VideoControls.scss'

type Props = {
  duration: number
  currentTime: number
  onCurrentTimeChangeRequested: (time: number) => void
  enterFullscreen: () => void
}

const VideoControls: React.FC<Props> = ({ duration, currentTime, onCurrentTimeChangeRequested, enterFullscreen }) => (
  <div className="video-controls">
    <div className="video-time">
      <div className="current-time">{formatVideoTime(currentTime)}</div>
      <div className="duration">{` / ${formatVideoTime(duration)}`}</div>
    </div>
    <Slider value={currentTime} maxValue={duration} onChange={onCurrentTimeChangeRequested} />
    <button className="fullscreen-button" aria-label="Fullscreen mode" onClick={enterFullscreen}>
      <FullscreenIcon />
    </button>
  </div>
)

export default VideoControls
