import { formatDropdownFreeText } from 'helpers/formatting'
import { flattenChildren } from 'helpers/treeUtils'
import { ContentBlock, ContentBlockField, Preview } from 'state/types'
import * as yup from 'yup'
import { FIRST_BLOCK_INDEX } from './constant'
import { FieldName } from './enum'

export const buildValidationSchema = (block: ContentBlock) => {
  const fieldsToValidate = flattenChildren(block.contentBlockFields)?.filter(
    f => f.controlType !== 'ProfileImage' && f.controlType !== 'List'
  )
  const shape = fieldsToValidate.reduce(addFieldToValidationSchema, {})
  return shape ? yup.object().shape(shape) : undefined
}

export const addFieldToValidationSchema = (
  schema: Record<string, yup.StringSchema<string>>,
  field: ContentBlockField
) => {
  if (field.controlType === 'Email') {
    schema[field.id] = yup.string().email('Please enter a valid email address')
  }
  if (field.required) {
    if (!schema[field.id]) {
      schema[field.id] = yup.string()
    }
    if (field.controlType === 'MonthYear') {
      schema[field.id] = schema[field.id].test(
        'month-year-required',
        'Please fill out this field',
        validateMonthYearValue
      )
    } else if (field.controlType === 'Availability') {
      schema[field.id] = schema[field.id].test(
        'availability-required',
        'Please specify your availability or add a note',
        validateAvailability
      )
      // Skip list / team member here because their 'value' column is always empty:
    } else if (field.controlType !== 'List' && field.controlType !== 'TeamMember') {
      schema[field.id] = schema[field.id].trim().required('Please fill out this field')
    }
  }
  return schema
}

//hideFields: hide Subject, Recipient and Function on duplicate previews's first content block
export const validatePreview = (preview?: Preview, isDuplicate?: boolean) => {
  const isNameValid = !preview?.nameEditable || !!preview.name?.trim()
  const blocks =
    preview?.contentBlocks?.map((block, i) => ({
      isValid: isBlockValid(block, isDuplicate && i === FIRST_BLOCK_INDEX, !!preview.title && !!preview.subtitle),
      isVisible: isBlockVisible(block), // Block will not appear anywhere in the preview editor.
      isOptional: isBlockOptional(block), // No fields are required, will not be validated in stepper.
    })) || []
  const isValid = isNameValid && blocks.every(b => b.isValid)
  return { isValid, isNameValid, blocks }
}

export const isBlockValid = (block: ContentBlock, hideFields?: boolean, hasTitles?: boolean) => {
  return (
    block.contentBlockFields.every(field => {
      return isFieldValid(field, hideFields, hasTitles)
    }) && !isEmptyVideoBlock(block)
  )
}

export const isFieldValid: any = (field: ContentBlockField, hideFields?: boolean, hasTitles?: boolean) => {
  if (hideFields) {
    if (
      (field.formLabel === FieldName.Function ||
        field.formLabel === FieldName.Recipient ||
        field.formLabel === FieldName.Subject) &&
      hasTitles
    ) {
      return true
    } else {
      return false
    }
  }
  if (field.controlType === 'Email') {
    return !field.required
  } else if (field.controlType === 'List') {
    return !field.required || (field.children && field.children.length > 0 && field.children?.every(isFieldValid))
  } else if (field.controlType === 'TeamMember') {
    return !field.required || validateTeamMember(field)
  } else if (field.controlType === 'Group') {
    return !field.required || validateGroup(field)
  } else if (
    field.controlType === 'Weight' ||
    field.controlType === 'Length' ||
    field.controlType === 'TextWithLabel'
  ) {
    return fieldValueExists(field)
  } else if (field.controlType === 'TextArea') {
    return !field.required || field.value
  } else {
    return !field.required || fieldValueExists(field)
  }
}

export const fieldValueExists = (field: ContentBlockField) => {
  if (field.controlType === 'MonthYear') {
    return !!field.value && validateMonthYearValue(field.value)
  } else if (field.controlType === 'Availability') {
    return !!field.value && validateAvailability(field.value)
  } else if (field.controlType === 'List') {
    return field.children && field.children.some(fieldValueExists)
  } else if (field.controlType === 'People') {
    return field.children && field.children.some(fieldValueExists)
  } else if (field.controlType === 'Details') {
    return field.children && field.children[0].children && field.children[0].children.some(fieldValueExists)
  } else if (field.controlType === 'TeamMember') {
    return field.children && teamMemberExists(field)
  } else if (field.controlType === 'Group') {
    return field.children && groupExists(field)
  } else if (field.controlType === 'Link') {
    return !!field.value && validateLink(field.value)
  } else if (field.controlType === 'KeyValuePair') {
    return !!field.value && validateKeyValuePair(field.value)
  } else if (field.controlType === 'DropdownFreeText') {
    return !!field.value && validateDropdownFreeText(field.value)
  } else if (field.controlType === 'Video') {
    return !!field.videoChapter?.rawVideoStorageId
  } else if (field.controlType === 'Length' || field.controlType === 'Weight') {
    return !!field.value && validateNumericalField(field.value)
  } else {
    return !!field.value
  }
}

export const fieldValueExistsAndDisplayedInPublishedPreview = (field: ContentBlockField) => {
  if (field.hiddenFromPublishedPreview) {
    return false
  } else return fieldValueExists(field)
}

export const isBlockVisible = (block: ContentBlock) => {
  return block.editable || block.contentBlockFields.some(field => field.editable)
}

export const isBlockOptional = (block: ContentBlock) => {
  // E.g. for the documents block, if there are no required fields, we don't want to show a green tick in the
  // stepper as soon as you land in the editor. However, this method returns false if the block has valid fields
  // (for example you uploaded a document even though none were required), so that you see a green tick and get
  // a warm fuzzy feeling.
  return (
    block.contentBlockType.name !== 'Video' &&
    !block.contentBlockFields.some(f => f.required || (fieldValueExists(f) && isFieldValid(f)))
  )
}

export const isEmptyVideoBlock = (block: ContentBlock) =>
  block?.contentBlockType.name === 'Video' &&
  !block.contentBlockFields.some(fieldValueExists) &&
  !block.contentBlockFields.some(f => f.required)

export const validateMonthYearValue = (value: string) => {
  const { month, year } = parseMonthYearString(value)
  return !!month && !!year
}

export const parseMonthYearString = (value?: string) => {
  if (value) {
    try {
      const parsed = JSON.parse(value)
      return { month: parsed.month || '', year: parsed.year || '' }
    } catch {}
  }
  return { month: '', year: '' }
}

export const validateAvailability = (value: string) => {
  const { selection, date, notes } = parseAvailabilityString(value)
  return (selection === 'date' && !!date) || selection === 'immediately' || !!notes?.trim()
}

export const parseAvailabilityString = (value?: string) => {
  if (value) {
    try {
      const parsed = JSON.parse(value)
      return {
        selection: parsed.selection || '',
        date: parsed.date ? new Date(Date.parse(parsed.date)) : undefined,
        notes: parsed.notes || '',
      }
    } catch {}
  }
  return { selection: '', date: undefined, notes: '' }
}

export const parseMultiSelect = (value?: string) => {
  if (value) {
    try {
      const parsed = JSON.parse(value)
      if (Array.isArray(parsed) && parsed.length > 0 && typeof parsed[0] === 'string') {
        return parsed as string[]
      }
    } catch {}
  }
  return []
}

export const validateWebsiteLink = (value?: string) => !!parseWebsiteLink(value)?.url?.trim()

export const parseWebsiteLink = (value?: string) => {
  if (value) {
    try {
      const parsed = JSON.parse(value)
      return { icon: parsed.icon || '', url: parsed.url || '' }
    } catch {}
  }
  return { icon: '', url: '' }
}

export const validateLink = (value?: string) => {
  const { text, url } = parseLink(value)
  return !!text?.trim() && !!url?.trim()
}

export const validateKeyValuePair = (keyValuePair?: string) => {
  const { key, value } = parseKeyValuePair(keyValuePair)
  return !!key?.trim() && !!value?.trim()
}

export const validateDropdownFreeText = (keyValuePair?: string) => {
  const { freeText } = parseDropdownFreeText(keyValuePair)
  return !!freeText?.trim()
}

export const parseLink = (value?: string) => {
  if (value) {
    try {
      const parsed = JSON.parse(value)
      if (parsed.target === undefined) return { text: parsed.text || '', url: parsed.url || '', target: '_blank' }
      return { text: parsed.text || '', url: parsed.url || '', target: parsed.target }
    } catch {
      if (value) {
        return { text: '', url: value, target: 'popup' }
      }
    }
  }
  return { text: '', url: '', target: 'popup' }
}

export const parseKeyValuePair = (value?: string) => {
  if (value) {
    try {
      const parsed = JSON.parse(value)
      return { key: parsed.key || '', value: parsed.value || '' }
    } catch {
      if (value) {
        return { key: '', value: value }
      }
    }
  }
  return { key: '', value: '' }
}

export const parseDropdownFreeText = (value?: string) => {
  if (value) {
    try {
      const parsed = JSON.parse(value)
      return { dropdown: parsed.dropdown || '', freeText: parsed.freeText || '' }
    } catch {}
  }
  return { dropdown: '', freeText: '' }
}

export const checkPublishable = (preview: Preview): { isPublishable: boolean; reviewLink?: string } => {
  const { isValid, isNameValid, blocks } = validatePreview(preview)
  if (isValid) {
    return { isPublishable: true }
  } else if (!isNameValid) {
    return { isPublishable: false, reviewLink: `/edit/${preview.id}/title?validate=true` }
  } else {
    const indexOfFirstInvalidBlock = blocks.findIndex(b => !b.isValid && b.isVisible)
    return { isPublishable: false, reviewLink: `/edit/${preview.id}/block/${indexOfFirstInvalidBlock}?validate=true` }
  }
}

export const validateTeamMember = (field: ContentBlockField): boolean => {
  return !!field.children?.every(child => isFieldValid(child))
}

export const validatePeople = (field: ContentBlockField): boolean => {
  return !!field.children?.every(child => isFieldValid(child))
}

export const validateDetails = (field: ContentBlockField): boolean => {
  if (field.children) {
    return validatePeople(field.children?.[0])
  }
  return false
}

export const teamMemberExists = (field: ContentBlockField) => {
  const { name, role } = getValues(field)
  return !!name && !!role
}

export const getChildren = (field: ContentBlockField) => {
  const name = field.children?.find(f => f.formLabel === 'Name')
  const role = field.children?.find(f => f.formLabel === 'Role')
  const email = field.children?.find(f => f.formLabel === 'Email address')
  const phone = field.children?.find(f => f.formLabel === 'Phone')
  const location = field.children?.find(f => f.formLabel === 'Location')
  const links = field.children?.find(f => f.formLabel === 'Web and social media')
  const profileImage = field.children?.find(f => f.formLabel === 'Profile image')
  return { name, role, email, phone, location, links, profileImage }
}

export const getValues = (field: ContentBlockField) => {
  const { name, role, email, phone, location, links, profileImage } = getChildren(field)
  return {
    name: name?.value,
    role: role?.value,
    email: email?.value,
    phone: phone?.value,
    location: location ? formatDropdownFreeText(location) : undefined,
    links: links?.children,
    profileImage: profileImage?.value,
  }
}

export const validateGroup = (field: ContentBlockField): boolean => {
  return !!field.children?.some(child => isFieldValid(child))
}

export const validateNumericalField = (value: string): boolean => {
  return !isNaN(parseFloat(value))
}

export const validateDurationField = (value: { dropdown: string; freeText: string }): boolean => {
  return !(value.dropdown === '' || value.freeText === '')
}

export const groupExists = (field: ContentBlockField) => {
  return !!field.children?.some(fieldValueExists)
}
