import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from 'state/useAuth'
import { PreviewTheme } from 'state/types'
import './PublishedPreviewFooter.scss'
import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon'

type Props = {
  theme?: PreviewTheme,
  planType?:string
}



const PublishedPreviewFooter: React.FC<Props> = ({ theme,planType }) => {
  const { user } = useAuth()


  return (
    <footer className={'published-preview-footer ' + theme}>
      <div className="footer-content">
        <div className="text">Powered by PreviewMe.</div>
        {user ? (
          <Link className="home-link" to="/">
            <ChevronLeftIcon />
            <span>Home</span>
          </Link>
        ) : (
          <div className="links">
            <span>Want to create your own Preview? </span>
            {window.location.hostname === 'app.previewme.com' ? (
              <a className="login-link " href="https://previewme.com/auth/login">
                Log in
              </a>
            ) : (
              <Link className="login-link " to="/auth/login">
                Log in
              </Link>
            )}
            {' or '}
            {window.location.hostname === 'app.previewme.com' ? (
              <a className="register-link " href="https://previewme.com/auth/register?fromLogin=false">
                Sign up
              </a>
            ) : (
              <Link className="register-link " to="/auth/register?fromLogin=false">
                Sign up
              </Link>
            )}
            .
          </div>
        )}
      </div>
    </footer>
  )
}

export default PublishedPreviewFooter
