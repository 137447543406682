import React from 'react'

const LightBulbBlueIcon = () => (
  <svg width="19px" height="27px" viewBox="0 0 19 27" version="1.1">
    <title>096300F8-8D30-4B9A-8468-A099D6B6BAF2</title>
    <g id="All-icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="UI-Components" transform="translate(-577.000000, -1603.000000)" stroke="#01A3F8" stroke-width="1.3">
        <g id="Group-9" transform="translate(578.000000, 1604.000000)">
          <path
            d="M16.9394901,8.46941289 C16.9394901,3.46241289 12.5944901,-0.529587111 7.46949013,0.0574128891 C3.70049013,0.488412889 0.589490132,3.52641289 0.080490132,7.28641289 C-0.313509868,10.1904129 0.768490132,12.8514129 2.67949013,14.6404129 C4.03349013,15.9084129 4.69449013,17.7544129 4.69449013,19.6104129 L4.69449013,22.3094129 C4.69449013,22.7324129 5.03749013,23.0754129 5.45949013,23.0754129 L11.4794901,23.0754129 C11.9024901,23.0754129 12.2444901,22.7324129 12.2444901,22.3094129 L12.2444901,19.4064129 C12.2444901,17.5224129 13.1044901,15.7934129 14.4414901,14.4654129 C15.9834901,12.9344129 16.9394901,10.8154129 16.9394901,8.46941289 Z"
            id="Stroke-1"
          ></path>
          <line x1="4.79569013" y1="21.1320129" x2="12.1436901" y2="21.1320129" id="Stroke-3"></line>
          <line x1="4.79569013" y1="19.1199129" x2="12.1436901" y2="19.1199129" id="Stroke-5"></line>
          <path
            d="M5.93239013,23.2721129 C6.36339013,24.2481129 7.33439013,24.9311129 8.47139013,24.9311129 C9.60339013,24.9311129 10.5793901,24.2481129 11.0053901,23.2721129"
            id="Stroke-7"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default LightBulbBlueIcon
