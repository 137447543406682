import React from 'react'
import { Link } from 'react-router-dom'
import { Invitation } from 'state/types'
import { formatDate } from 'helpers/date'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import './InvitationsListMobile.scss'

type Props = {
  invitations: Invitation[]
}

const InvitationsListMobile: React.FC<Props> = ({ invitations }) => (
  <div className="invitations-list-mobile">
    {invitations.map(({ id, name, endDate, previewCount }, i) => (
      <div key={id} className="invitation-list-card">
        <div className="invitation-title">{name}</div>
        <div className="closing-date">{endDate ? `Closing date: ${formatDate(endDate)}` : 'No closing date'}</div>
        <Link className="details-link" to={`/invitation/${id}`}>
          <div className="preview-count">
            <span className="number">{previewCount}</span>
            <span>{` Preview${previewCount === 1 ? '' : 's'} received`}</span>
          </div>
          <ChevronRightIcon />
        </Link>
      </div>
    ))}
  </div>
)

export default InvitationsListMobile
