import React, { useEffect, useRef, useState } from 'react';
import { isVideoPlaying } from 'helpers/media';
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner';
import VideoLayoutContainer from 'components/video/VideoLayoutContainer/VideoLayoutContainer';
import VideoControls from '../VideoControls/VideoControls';
import './VideoPlayerWithTrimFeatures.scss';
import AudioOnlyProfileImage from '../Audio/AudioOnlyProfileImage/AudioOnlyProfileImage';
import useVideoSuite from '../../../state/useVideoSuite';
import WatermarkImage from '../VideoTrimEditorPage/Watermark/WatermarkImage'
import { createFullLinkConvert } from '../../../helpers/links'
import { SubscriptionPlan } from '../../../helpers/enum'
import useStore from '../../../state/useStore'

export type Props = {
  src?: string;
  nativeControls?: boolean;
  customControls?: boolean;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  hidePlayPauseButton?: boolean;
  noAutoLoad?: boolean;
  onEnded?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onDurationChange?: (duration: number) => void;
  onPlayClickAtEndOfVideo?: () => boolean;
  audioOnly?: boolean;
  profileImageStorageId?: string;
  responseData: {
    watermark: boolean;
    cta: boolean;
    videoFx: boolean;
    images: boolean;
    captions: boolean;
  };
  plan: string
};
interface DragOffsets {
  [key: number]: { x: number; y: number };
}

const VideoPlayerWithTrimFeatures: React.FC<Props> = ({ responseData, src, nativeControls, customControls, videoRef, noAutoLoad, onEnded, onPlay, onPause, onDurationChange, audioOnly, profileImageStorageId, plan }) => {
  const [canPlay, setCanPlay] = useState(false);
  const [revealed, setRevealed] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const mountedRef = useRef(true);
  const defaultRef = useRef<HTMLVideoElement | null>(null);
  const videoRefToUse = videoRef || defaultRef;
  // Calls Action Start
  const { setVideoSuite, videoSuiteData } = useVideoSuite(responseData?.cta);
  const [dragging, setDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [dragId, setDragId] = useState<number | null>(null);
  const [dragOffset, setDragOffset] = useState<DragOffsets>({});
  const [wasDragged, setWasDragged] = useState(false);
  const { state } = useStore()
  const isOnBasicPlan = plan === SubscriptionPlan.Basic
  const [fontSize, setFontSize] = useState<number>(28);
  const [buttonTextSize, setButtonTextSize] = useState(16);
  const [buttonMinWidth, setButtonMinWidth] = useState(186);
  const [buttonTextHeight, setButtonTextHeight] = useState(40);
  const [buttonMarginTop, setButtonMarginTop] = useState(5);
  const [textLineheight, setTextLineheight] = useState(24);

  const [positionX, setPositionX] = useState(videoSuiteData[0]?.positionX || 0);
  const [positionY, setPositionY] = useState(videoSuiteData[0]?.positionY || 0);

  const [previousVideoWidth] = useState( videoSuiteData[0]?.previousVideoWidth || 0);
  const [previousVideoHeight] = useState( videoSuiteData[0]?.previousVideoHeight || 0);

  const handleDragStart = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    thumbId: number
  ) => {
    setDragging(true);
    e.preventDefault();
    e.stopPropagation();
    setWasDragged(false);
    setDragId(thumbId);
    setDragStartX(e.clientX);
    setDragStartY(e.clientY);
    setDragOffset({
      ...dragOffset,
      [thumbId]: { x: 0, y: 0 },
    });
  };

  const handleDragStartMobile = (
    e: React.TouchEvent<HTMLDivElement>,
    thumbId: number
  ) => {
    // Retrieve the touch position
    const touch = e.touches[0];
    setWasDragged(false);
    setDragging(true);
    setDragId(thumbId);
    setDragStartX(touch.clientX);
    setDragStartY(touch.clientY);
    setDragOffset({
      ...dragOffset,
      [thumbId]: { x: 0, y: 0 },
    });
  };


  useEffect(() => {

    const handleDrag = (e: MouseEvent) => {
      if (dragging && dragId !== null) {
        const offsetX = e.clientX - dragStartX;
        const offsetY = e.clientY - dragStartY;
        if (Math.abs(e.clientX - dragStartX) > 10 || Math.abs(e.clientY - dragStartY) > 10) {
          setWasDragged(true);
        }
        setDragOffset((prevOffsets) => ({
          ...prevOffsets,
          [dragId]: { x: offsetX, y: offsetY },
        }));
      }
    };

    const handleDragMobile = (e: TouchEvent) => {
      if (dragging && dragId !== null) {
        // Retrieve the touch position
        const touch = e.touches[0];
        const offsetX = touch.clientX - dragStartX;
        const offsetY = touch.clientY - dragStartY;
        if (Math.abs(touch.clientX - dragStartX) > 10 || Math.abs(touch.clientY - dragStartY) > 10) {
          setWasDragged(true);
        }
        setDragOffset((prevOffsets) => ({
          ...prevOffsets,
          [dragId]: { x: offsetX, y: offsetY },
        }));
      }
    };

    const handleDragEnd = () => {
      // const videoElement = videoRefToUse.current;
      const videoElement = audioOnly ?  document.getElementById('mainAudio') : document.getElementById('mainVideo');
      if (dragging && dragId !== null) {

        setDragging(false);
        setDragId(null);

        const updatedPositionX = positionX + (dragOffset[dragId]?.x || 0);
        const updatedPositionY = positionY + (dragOffset[dragId]?.y || 0);
        setPositionX(updatedPositionX || 0);
        setPositionY(updatedPositionY || 0);

        const updatedCallsActionData = videoSuiteData.map((cta: any) =>
          cta.id === dragId
            ? {
              ...cta,
              previousVideoWidth: videoElement?.offsetWidth,
              previousVideoHeight: videoElement?.offsetHeight,
              positionX: updatedPositionX,
              positionY: updatedPositionY,
            }
            : cta
        );
        setVideoSuite(updatedCallsActionData);
        setDragOffset((prevOffsets) => ({
          ...prevOffsets,
          [dragId]: { x: 0, y: 0 },
        }));
      }
    };

    if (dragging) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', handleDragEnd);
      window.addEventListener('touchmove', handleDragMobile);
      window.addEventListener('touchend', handleDragEnd);
    } else {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchmove', handleDragMobile);
      window.removeEventListener('touchend', handleDragEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchmove', handleDragMobile);
      window.removeEventListener('touchend', handleDragEnd);
    };
  }, [dragging, dragId, dragStartX, dragStartY, dragOffset, videoSuiteData, setVideoSuite]);

// Calls Action End
  const handleDurationChange = () => {
    const duration = videoRefToUse.current?.duration || 0;
    setDuration(duration);
    onDurationChange && onDurationChange(Math.ceil(duration));
  };

  const onTimeUpdate = () => {
    const time = videoRefToUse.current?.currentTime;
    if (customControls && time !== undefined) {
      setCurrentTime(time);
    }
  };

  const onCurrentTimeChangeRequested = (newTime: number) => {
    const video = videoRefToUse.current;
    if (video) {
      if (isVideoPlaying(video)) {
        video.pause();
      }
      video.currentTime = newTime;
    }
  };

  const enterFullscreen = () => videoRefToUse.current?.requestFullscreen();

  useEffect(() => {
    const video = videoRefToUse.current as any;
    if (video) {
      video.disablePictureInPicture = true;
      if (!noAutoLoad) {
        video.load(); // Forces videos to load right away even on mobile.
      }
    }
  }, [src, videoRefToUse, noAutoLoad]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (mountedRef.current) {
        setRevealed(true);
      }
    }, 1);

    return () => {

      clearTimeout(timeoutId);
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (videoSuiteData.length === 0) {
      setPositionX(0);
      setPositionY(0);
    }
  }, [videoSuiteData]);


  const calculateResponsiveness = (initialSize: number, videoWidthParam: number, videoHeightParam: number, fullWidth: number, fullHeight: number) => {
    const isPortrait = videoHeightParam > videoWidthParam;
    const widthRatio = isPortrait ? fullHeight / videoHeightParam : fullWidth / videoWidthParam;
    const heightRatio = isPortrait ? fullWidth / videoWidthParam : fullHeight / videoHeightParam;
    return initialSize * Math.min(widthRatio, heightRatio);
  };

  useEffect(() => {
    const handleResize = () => {
      const videoElement = audioOnly ?  document.getElementById('mainAudio') : document.getElementById('mainVideo');

      let videoWidth = 0;
      let videoHeight = 0;

      if (videoElement) {
        videoWidth = videoElement.offsetWidth;
        videoHeight = videoElement.offsetHeight;
      }

      if (videoElement) {
        videoWidth = videoElement.offsetWidth;
        videoHeight = videoElement.offsetHeight;
      }

      let videoWidthInitial = 650;
      let videoHeightInitial = 488;
      const adjustedSize = calculateResponsiveness(28, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
      const adjustedLineHeight = calculateResponsiveness(24, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
      const adjustedButtonTextSize = calculateResponsiveness(16, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
      const adjustedButtonMinWidth = calculateResponsiveness(186, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
      const adjustedButtonHeight = calculateResponsiveness(40, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
      const adjustedTextLineheight = calculateResponsiveness(24, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
      const adjustedButtonMarginTop = calculateResponsiveness(5, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
      const adjustedPositionX = calculateResponsiveness(positionX, previousVideoWidth, previousVideoHeight, videoWidth, videoHeight);
      const adjustedPositionY = calculateResponsiveness(positionY, previousVideoWidth, previousVideoHeight, videoWidth, videoHeight);
      setFontSize(adjustedSize);
      setPositionX(adjustedPositionX);
      setPositionY(adjustedPositionY);
      setButtonTextSize(adjustedButtonTextSize);
      setTextLineheight(adjustedTextLineheight)
      setButtonMinWidth(adjustedButtonMinWidth);
      setButtonMarginTop(adjustedButtonMarginTop);
      setButtonTextHeight(adjustedButtonHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const parseTimeToFloat = (time: string) => {
    const [minutesPart, secondsPart] = time.split(':');
    const minutes = parseFloat(minutesPart) || 0;
    const seconds = parseFloat(secondsPart) || 0;
    return (minutes * 60) + seconds;
  };


  return (
    <>
    <VideoLayoutContainer className="video-player video-trim">
      {audioOnly && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} id="mainAudio">
          <div style={{ width: '54%', height: '74%' }}>
            <AudioOnlyProfileImage cloudinaryId={state.profileImage} />
          </div>
        </div>
      )}
      {responseData?.cta && !isOnBasicPlan &&(
        <div className="wrapper-drag">
          {videoSuiteData?.map((cta: any, index: number) => {
            const video = videoRefToUse.current;

            if (video) {
              const parsedFadeIn = cta.fadeIn ? parseTimeToFloat(cta.fadeIn) : 0
              const parsedFadeOut = cta.fadeOut ? parseTimeToFloat(cta.fadeOut) : 0
              const parsedNewStart = cta.newStart ? parseTimeToFloat(cta.newStart) : 0

              if (video.currentTime >= parsedFadeIn && video.currentTime <= parsedFadeOut) {
                return (
                  <div className="cta-draggable"
                      key={index}
                       style={{
                         transform: `translate(${positionX + (dragOffset[cta.id]?.x || 0)}px, ${positionY + (dragOffset[cta.id]?.y || 0)}px)`,
                       }}
                       onMouseDown={(e) => handleDragStart(e, cta.id)}
                       onTouchStart={(e) => handleDragStartMobile(e, cta.id)}>
                    <div className="trim-container-content" id="ctaContainer"  style={{ display:'inline' }}>
                      <div className="cta-container-item">
                      <div>
                        {cta.textContent && (cta.type === "text" || cta.type === "all") && (

                          <div>
                            {cta.targetLink === "open-new-tab" ? (
                              <button
                                className={`button__text ${cta.button ? 'link__cursor' : ''}`}
                                  onClick={() => {
                                  if (!wasDragged && cta.linkButton) {
                                  window.open(createFullLinkConvert(cta.linkButton), "_blank");
                                } else {
                                  setWasDragged(false);
                                }
                                }}>
                                <p
                                  style={{
                                    color: cta.textColor,
                                    fontSize:fontSize,
                                    lineHeight:textLineheight + 'px',
                                    ...(cta.textDropshadow
                                      ? {
                                        textShadow: `2px 2px 4px ${cta.textDropshadow}`,
                                      }
                                      : {}),
                                  }}
                                  className="text-content"
                                >  {cta.textContent}
                                </p>
                          </button>
                          ) : cta.targetLink === "open-as-popup" ? (
                          <button
                            className={`button__text ${cta.button ? 'link__cursor' : ''}`}
                            onClick={() => {
                              if (!wasDragged && cta.linkButton) {
                                window.open(createFullLinkConvert(cta.linkButton), "popup", "width=600,height=600");
                              } else {
                                setWasDragged(false);
                              }
                            }}>
                            <p
                              style={{
                                color: cta.textColor,
                                fontSize:fontSize,
                                lineHeight:textLineheight + 'px',
                                ...(cta.textDropshadow
                                  ? {
                                    textShadow: `2px 2px 4px ${cta.textDropshadow}`,
                                  }
                                  : {}),
                              }}
                              className="text-content"
                            >  {cta.textContent}
                            </p>
                          </button>
                          ) : (
                              <p
                                style={{
                                  color: cta.textColor,
                                  fontSize:fontSize,
                                  lineHeight:textLineheight + 'px',
                                  ...(cta.textDropshadow
                                    ? {
                                      textShadow: `2px 2px 4px ${cta.textDropshadow}`,
                                    }
                                    : {}),
                                }}
                                className="text-content"
                              >  {cta.textContent}
                              </p>
                          )}
                          </div>
                        )}
                      </div>


                      <div>
                        {cta.buttonContent && (cta.type === "button" || cta.type === "all") && (
                          <div>
                            {cta.targetLink === "open-new-tab" ? (
                              <button
                                onClick={() => {
                                  if (!wasDragged) {
                                    window.open(createFullLinkConvert(cta.linkButton), "_blank");
                                  } else {
                                    setWasDragged(false);
                                  }
                                }}
                                className="button-content"
                                style={{
                                  fontSize:buttonTextSize,
                                  height:buttonTextHeight,
                                  minWidth:buttonMinWidth,
                                  marginTop: buttonMarginTop + 'px',
                                  backgroundColor: cta.buttonColor,
                                  ...(cta.buttonDropshadow
                                    ? {
                                      boxShadow: `2px 2px 4px rgba(${parseInt(cta.buttonDropshadow.slice(1, 3), 16)}, ${parseInt(
                                        cta.buttonDropshadow.slice(3, 5),
                                        16
                                      )}, ${parseInt(cta.buttonDropshadow.slice(5, 7), 16)}, 0.5)`,
                                    }
                                    : {}),
                                  color: cta.buttonTextColor,
                                }}
                              >
                                {cta.buttonContent}
                              </button>
                            ) : cta.targetLink === "open-as-popup" ? (
                              <button
                                onClick={() => {
                                  if (!wasDragged) {
                                    window.open(createFullLinkConvert(cta.linkButton), "popup", "width=600,height=600");
                                  } else {
                                    setWasDragged(false);
                                  }
                                }}

                                className="button-content"
                                style={{
                                  fontSize:buttonTextSize,
                                  height:buttonTextHeight,
                                  minWidth:buttonMinWidth,
                                  marginTop: buttonMarginTop + 'px',
                                  backgroundColor: cta.buttonColor,
                                  ...(cta.buttonDropshadow
                                    ? {
                                      boxShadow: `2px 2px 4px rgba(${parseInt(cta.buttonDropshadow.slice(1, 3), 16)}, ${parseInt(
                                        cta.buttonDropshadow.slice(3, 5),
                                        16
                                      )}, ${parseInt(cta.buttonDropshadow.slice(5, 7), 16)}, 0.5)`,
                                    }
                                    : {}),
                                  color: cta.buttonTextColor,
                                }}
                              >
                                {cta.buttonContent}
                              </button>
                            ) : (
                              <button
                                className="button-content"
                                onClick={() => {
                                  if (!wasDragged) {
                                    window.location.href = createFullLinkConvert(cta.linkButton);
                                  } else {
                                    setWasDragged(false);
                                  }
                                }}
                                style={{
                                  fontSize:buttonTextSize,
                                  height:buttonTextHeight,
                                  minWidth:buttonMinWidth,
                                  marginTop: buttonMarginTop + 'px',
                                  backgroundColor: cta.buttonColor,
                                  ...(cta.buttonDropshadow
                                    ? {
                                      boxShadow: `2px 2px 4px rgba(${parseInt(cta.buttonDropshadow.slice(1, 3), 16)}, ${parseInt(
                                        cta.buttonDropshadow.slice(3, 5),
                                        16
                                      )}, ${parseInt(cta.buttonDropshadow.slice(5, 7), 16)}, 0.5)`,
                                    }
                                    : {}),
                                  color: cta.buttonTextColor,
                                }}
                              >
                                {cta.buttonContent}
                              </button>
                            )}
                          </div>
                        )}
                      </div>

                    </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }

            }

            return null;
          })}
      </div>
      )}
      <video
        id="mainVideo"
        src={src}
        hidden={audioOnly}
        controlsList="nodownload"
        playsInline
        ref={videoRefToUse}
        controls={nativeControls}
        onDurationChange={handleDurationChange}
        onEnded={() => {
          onEnded && onEnded();
        }}
        onPlay={() => {
          onPlay && onPlay();
        }}
        onPause={() => {
          onPause && onPause();
        }}
        onCanPlay={() => setCanPlay(true)}
        onTimeUpdate={onTimeUpdate}
        className={revealed ? 'revealed' : undefined}
      />

      <WatermarkImage videoRef={videoRefToUse.current} plan={plan}/>

      {!canPlay && !nativeControls && src ? (
        <LoadingSpinner container />
      ) : null }
      {canPlay && !nativeControls && customControls && (
        <VideoControls
          currentTime={currentTime}
          duration={duration}
          onCurrentTimeChangeRequested={onCurrentTimeChangeRequested}
          enterFullscreen={enterFullscreen}
        />
      )}
    </VideoLayoutContainer>
    </>
  );
};
export default VideoPlayerWithTrimFeatures;
