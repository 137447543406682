import React from 'react'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import '../VideoTipsDialog/VideoTipsDialog.scss'

type Props = {
  isShowing: boolean
  onClose: () => void
}

const VideoTipsDialog: React.FC<Props> = ({ isShowing, onClose }) => {
  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      <div className="video-tips-dialog">
        <h3 className="title">Tips & Tricks</h3>
        <ol>
          <li>Prepare your script and run through in advance.</li>
          <li>Dress 'audience appropriate'.</li>
          <li>Ensure the background is clean, tidy & free from distractions.</li>
          <li>Make sure your light source is projecting onto your face/towards you, not coming from behind you.</li>
        </ol>
      </div>
    </CloseableDialog>
  )
}

export default VideoTipsDialog
