import findIndex from 'lodash/findIndex'
import findLastIndex from 'lodash/findLastIndex'
import { Preview } from 'state/types'
import { isBlockVisible } from 'helpers/validation'
import { parseIndexParam } from 'helpers/urlParams'

export const getNavigationData = (
  preview?: Preview,
  blockIndex?: number,
  isTitleStep?: boolean,
  isInstructionsStep?: boolean,
  isPublishStep?: boolean
) => {
  if (!preview) {
    return { prevLink: undefined, nextLink: undefined, redirect: undefined }
  }

  const firstVisibleBlockIndex = findIndex(preview.contentBlocks, isBlockVisible)
  const lastVisibleBlockIndex = findLastIndex(preview.contentBlocks, isBlockVisible)

  let redirect: string | undefined
  if (!isTitleStep && !isInstructionsStep && !isPublishStep && blockIndex === undefined) {
    redirect = preview.nameEditable
      ? `/edit/${preview.id}/title`
      : preview.invitation?.instructions
      ? `/edit/${preview.id}/instructions`
      : `/edit/${preview.id}/block/${firstVisibleBlockIndex}`
  }

  let prevLink: string | undefined
  if (blockIndex !== undefined && blockIndex === firstVisibleBlockIndex && preview.nameEditable) {
    prevLink = `/edit/${preview.id}/title`
  } else if (blockIndex !== undefined && blockIndex === firstVisibleBlockIndex && preview.invitation?.instructions) {
    prevLink = `/edit/${preview.id}/instructions`
  } else if (blockIndex !== undefined && blockIndex > firstVisibleBlockIndex) {
    const prevIndex = findLastIndex(preview.contentBlocks, (b, i) => isBlockVisible(b) && i < blockIndex)
    prevLink = `/edit/${preview.id}/block/${prevIndex}`
  } else if (isPublishStep && lastVisibleBlockIndex >= 0) {
    prevLink = `/edit/${preview.id}/block/${lastVisibleBlockIndex}`
  }

  let nextLink: string | undefined
  if ((isTitleStep || isInstructionsStep) && firstVisibleBlockIndex >= 0) {
    nextLink = `/edit/${preview.id}/block/${firstVisibleBlockIndex}`
  } else if (blockIndex !== undefined && blockIndex < lastVisibleBlockIndex) {
    const nextIndex = findIndex(preview.contentBlocks, (b, i) => isBlockVisible(b) && i > blockIndex)
    nextLink = `/edit/${preview.id}/block/${nextIndex}`
  } else if (preview.status !== 'published' && blockIndex === lastVisibleBlockIndex) {
    nextLink = `/preview/${preview.id}?from=${blockIndex}`
  }

  return { prevLink, nextLink, redirect }
}

export const getPublishedPreviewNavigationLink = (
  previewId: string,
  fromParam?: number | 'publish' | 'editor' | 'analytics'
) => {
  if (fromParam !== undefined) {
    return `/preview/${previewId}?from=${fromParam}`
  } else {
    return `/preview/${previewId}`
  }
}

export const getPublishedPreviewBackLink = (previewId: string, from?: string) => {
  if (from === 'publish') {
    return `/edit/${previewId}/publish`
  } else if (from === 'analytics') {
    return `/analytics/p/${previewId}`
  } else {
    return `/`
  }
}

export const getEditPreviewLink = (previewId: string, from?: string) => {
  if (from) {
    const blockIndex = parseIndexParam(from)
    if (blockIndex !== undefined) {
      return `/edit/${previewId}/block/${blockIndex}`
    }
  }
  return `/edit/${previewId}`
}
