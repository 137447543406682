import React from 'react'
import '../InfoIcon/InfoIcon.scss'

type Props = {
  onClick?: () => void
}

const InfoIcon: React.FC<Props> = ({ onClick }) => (
  <div className={onClick ? 'info-icon clickable' : 'info-icon'} onClick={onClick}>
    i
  </div>
)

export default InfoIcon
