import React from 'react'
import { Redirect, RouteProps, Route } from 'react-router-dom'
import { clearAllStoredData } from 'helpers/paramStorage'
import useAuth from 'state/useAuth'

type Props = {
  component: any
  componentProps?: any
} & RouteProps

const OnboardedRoute = ({ component: Component, componentProps, location, ...rest }: Props) => {
  const { isLoggedIn, user } = useAuth()
  return (
    <Route
      render={() => {
        if (isLoggedIn && user?.onboarded) {
          clearAllStoredData()
          return <Component {...componentProps} />
        } else {
          return (
            <Redirect
              to={{
                pathname: '/welcome',
                search: location?.search,
                state: { from: location?.state?.from || location?.pathname },
              }}
            />
          )
        }
      }}
      {...rest}
      range="123"
    />
  )
}

export default OnboardedRoute
