import useAuth from 'state/useAuth'
import useContentBlockField from 'state/useContentBlockField'
import * as imageService from 'services/imageService'

const useProfileImage = (previewId?: string, blockIndex?: number, fieldIndex?: number) => {
  const { contentBlockField, isValidationActive, updateValue } = useContentBlockField(previewId, blockIndex, fieldIndex)
  const { user } = useAuth()
  const userProfileImageStorageId = user?.profileImageStorageId

  const uploadImage = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    const uploadResult = await imageService.uploadImage(blob, onProgressChange)
    if (uploadResult.status === 'success') {
      await updateValue(uploadResult.storageId, true)
    }
  }

  const deleteImage = () => {
    updateValue(undefined, true)
  }

  const copyFromProfile = () => {
    if (userProfileImageStorageId) {
      updateValue(userProfileImageStorageId, true)
    }
  }

  return {
    contentBlockField,
    isValidationActive,
    userProfileImageStorageId,
    uploadImage,
    deleteImage,
    copyFromProfile,
  }
}

export default useProfileImage
