import React from 'react'
import { Invitation } from 'state/types'
import { formatDate } from 'helpers/date'
import PreviewEditorBottomNav from 'components/preview/PreviewEditorBottomNav/PreviewEditorBottomNav'
import './InvitationInstructions.scss'
import { formatContentBlockFieldFormat } from '../../../helpers/formatting'

type Props = {
  previewId: string
  invitation: Invitation
  nextLink?: string
  chapters?: { previewLabel: string; format: string }[]
}

const InvitationInstructions: React.FC<Props> = ({ previewId, invitation, nextLink, chapters }) => {
  return (
    <div className="invitation-instructions">
      <div className="instructions-box">
        <div className="instructions-text">{invitation.instructions}</div>
        {invitation.endDate && (
          <div className="closing-date">
            <span>Closing date: </span>
            <span className="value">{formatDate(invitation.endDate)}</span>
          </div>
        )}
        <div className={'chapters'}>
          <h3>Video Submissions</h3>
          <p>
            We’ll also ask you to complete the following video/audio submissions. Details, instructions and the format
            requested are provided in the Media Suite.
          </p>
          <ul>
            {chapters?.map((chapter, index) => {
              return (
                <li>
                  <div className={'chapter'}>
                    <h4>{`Chapter ${index + 1}: ${chapter.previewLabel} (${formatContentBlockFieldFormat(
                      chapter.format
                    )})`}</h4>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <PreviewEditorBottomNav nextLink={nextLink} />
    </div>
  )
}

export default InvitationInstructions
