import { useState, useEffect, useCallback } from 'react'
import useStore from 'state/useStore'
import * as previewService from 'services/previewService'
import { getUrlParam, removeUrlParam } from 'helpers/urlParams'
import { getDashboardTitles } from 'helpers/titles'
import { PreviewTagDetail } from './types'

// Used for only loading the preview once in the case where multiple components are using this hook simultaneously.
let isLoadingGlobal = false

const usePreview = (previewId?: string) => {
  const { state, dispatch } = useStore()
  const [isInitializing, setInitializing] = useState(true)
  const [isPublishing, setPublishing] = useState(false)
  const [isUnpublishing, setUnpublishing] = useState(false)
  const [isUpdatingName, setUpdatingName] = useState(false)
  const [isUpdatingTitle, setUpdatingTitle] = useState(false)
  const [isUpdatingSubtitle, setUpdatingSubtitle] = useState(false)
  const [isUpdatingTags, setUpdatingTags] = useState(false)
  const previewCustomStyles = state.previewCustomStyles
  const preview = state.preview?.id === previewId ? state.preview : undefined
  const isLoading = state.isPreviewLoading

  const setPreviewCustomStyles = useCallback((data:object | undefined) => {
    dispatch({ type: 'SET_PREVIEW_CUSTOM_STYLES', value: data });
  }, [dispatch]);


  const setValidationActive = useCallback(
    (active: boolean) => {
      dispatch({ type: 'SET_PREVIEW_VALIDATION_ACTIVE', value: active })
    },
    [dispatch]
  )

  const updatePublished = async (published: boolean, setLoading: (loading: boolean) => void) => {
    if (previewId && preview) {
      setLoading(true)
      const { ok } = await previewService.updatePreviewPublished(previewId, published)
      setLoading(false)

      if (ok) {
        dispatch({ type: 'SET_PREVIEW', value: { ...preview, status: published ? 'published' : 'unpublished' } })
      }
    }
  }

  const publish = () => updatePublished(true, setPublishing)

  const unpublish = () => updatePublished(false, setUnpublishing)

  const updateName = async (name?: string, optimistic?: boolean) => {
    if (previewId && preview) {
      if (optimistic) {
        dispatch({ type: 'UPDATE_PREVIEW_NAME', value: name })
      }

      setUpdatingName(true)
      const { ok } = await previewService.updateName(previewId, name)
      setUpdatingName(false)

      if (ok && !optimistic) {
        dispatch({ type: 'UPDATE_PREVIEW_NAME', value: name })
      }

      return ok
    }
  }

  const updateTitle = async (title?: string) => {
    if (previewId && preview && title !== preview.title) {
      setUpdatingTitle(true)
      dispatch({ type: 'SET_PREVIEW', value: { ...preview, title: title } })
      const { ok } = await previewService.updateTitle(previewId, title)
      setUpdatingTitle(false)
      return ok
    }
  }

  const updateSubtitle = async (subtitle?: string) => {
    if (previewId && preview) {
      setUpdatingSubtitle(true)
      dispatch({ type: 'UPDATE_PREVIEW_SUBTITLE', value: subtitle })
      const { ok } = await previewService.updateSubtitle(previewId, subtitle)
      setUpdatingSubtitle(false)
      return ok
    }
  }

  const updatePreviewSelectedTags = async (tagIds: string[]) => {
    if (previewId && preview && preview.organizationId && preview.previewTagDetail) {
      const payload: PreviewTagDetail = { tagOptions: preview.previewTagDetail.tagOptions, values: tagIds }
      setUpdatingTags(true)
      dispatch({ type: 'UPDATE_PREVIEW_TAGS', value: payload })
      const { ok } = await previewService.updatePreviewTags(previewId, preview.organizationId, tagIds)
      setUpdatingTags(false)
      return ok
    }
  }

  const clear = useCallback(() => dispatch({ type: 'SET_PREVIEW', value: undefined }), [dispatch])

  const checkValidateParam = useCallback(() => {
    const validate = getUrlParam(window.location.search, 'validate')
    if (validate === 'true') {
      setValidationActive(true)
      removeUrlParam('validate', window.history, 'replace')
    }
  }, [setValidationActive])

  useEffect(() => {
    const loadPreview = async () => {
      setInitializing(false)
      if (previewId && preview?.id !== previewId && !isLoadingGlobal) {
        isLoadingGlobal = true
        dispatch({ type: 'SET_PREVIEW_LOADING', value: true })
        const response = await previewService.getPreviewDetails(previewId)
        if (response.ok && response.data) {
          dispatch({ type: 'SET_PREVIEW', value: response.data })
        }
        dispatch({ type: 'SET_PREVIEW_LOADING', value: false })
        isLoadingGlobal = false
      }
    }
    loadPreview()
  }, [previewId, preview, dispatch, checkValidateParam])

  return {
    isInitializing,
    isLoading,
    preview,
    isPublishing,
    isUnpublishing,
    isUpdatingName,
    isUpdatingTitle,
    isUpdatingSubtitle,
    setValidationActive,
    previewCustomStyles,
    setPreviewCustomStyles,
    publish,
    unpublish,
    updateName,
    updateTitle,
    updateSubtitle,
    clear,
    checkValidateParam,
    updatePreviewSelectedTags,
  }
}

export default usePreview
