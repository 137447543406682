import * as React from 'react'

const GoldShieldIcon = () => (
  <svg className="icon" width="33px" height="35px" viewBox="0 0 33 35">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M26.1323,27.0516 L16.2743,34.5516 L6.4193,27.0516 C2.6903,24.2136 0.5003,19.7976 0.5003,15.1146 L0.5003,0.000599999999 L32.0513,0.000599999999 L32.0513,15.1146 C32.0513,19.7976 29.8613,24.2136 26.1323,27.0516"
        fill="#FFE36D"
      ></path>
      <path
        d="M8.04196716,23.916036 C5.19940157,21.7415393 3.5,18.2985863 3.5,14.7091247 L3.5,3 L28.5,3 L28.5,14.7091247 C28.5,18.2985863 26.8044346,21.7415393 23.9580328,23.916036 L16.0019181,30"
        fill="#D9B000"
      ></path>
    </g>
  </svg>
)

export default GoldShieldIcon
