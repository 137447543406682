import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useUrlParams } from 'helpers/urlParams'
import AuthErrorMessage from 'components/auth/AuthErrorMessage/AuthErrorMessage'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import FormInput from 'components/shared/FormInput/FormInput'
import './ChangePasswordForm.scss'

const successMessage = 'Your password reset was successful!'

type Props = {
  submitNewPassword: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<{ success: boolean; errorMessage?: string }>
}

type FormData = {
  password: string
  confirmPassword: string
}

const validationSchema = yup.object().shape({
  password: yup.string().required('*Please enter a password'),
  confirmPassword: yup.string().required('*Please confirm your password'),
})

const ChangePasswordForm = ({ submitNewPassword }: Props) => {
  // Do NOT use the url param 'code' or AWS amplify will try to do something with it.
  const { email, accessCode } = useUrlParams('email', 'accessCode')
  const { handleSubmit, register, errors } = useForm<FormData>({ validationSchema })
  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit = handleSubmit(async ({ password, confirmPassword }) => {
    setSuccess(false)
    setErrorMessage('')
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match!')
    } else {
      setLoading(true)
      const { success, errorMessage } = await submitNewPassword(email || '', accessCode || '', password)
      if (success) {
        setSuccess(true)
      } else {
        setErrorMessage(errorMessage!)
      }
      setLoading(false)
    }
  })

  return (
    <form className="change-password-form" onSubmit={onSubmit} noValidate>
      {isSuccess ? (
        <Redirect
          to={`/auth/login?email=${encodeURIComponent(email || '')}&successMessage=${encodeURIComponent(
            successMessage
          )}`}
        />
      ) : (
        <>
          {errorMessage && <AuthErrorMessage text={errorMessage} />}
          <h1>New password</h1>
          <div className="text">Please enter your new password.</div>
          <FormInput name="password" type="password" placeholder="New password" errors={errors} inputRef={register} />
          <FormInput
            name="confirmPassword"
            type="password"
            placeholder="Confirm new password"
            errors={errors}
            inputRef={register}
          />
          <LoadingButton type="submit" className="primary" isLoading={isLoading} onMouseDown={e => e.preventDefault()}>
            Submit
          </LoadingButton>
        </>
      )}
    </form>
  )
}

export default ChangePasswordForm
