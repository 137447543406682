import React, { useState, useRef, useEffect } from 'react'
import { VideoUploadHandle } from 'state/types'
import {
  isMediaRecorderSupported,
  formatVideoTime,
  videoBitsPerSecond,
  getScreenCaptureStream,
  getMicrophoneStream,
  isVideoPlaying,
  checkCameraAndMicrophonePermissions,
  getCameraAndMicrophoneStream,
  aspectRatio,
} from 'helpers/media'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import ProgressBar from 'components/shared/ProgressBar/ProgressBar'
import VideoLayoutContainer from 'components/video/VideoLayoutContainer/VideoLayoutContainer'
import VideoSettingsMenuButton from 'components/video/VideoSettingsMenuButton/VideoSettingsMenuButton'
import PlayPauseButton from 'components/video/PlayPauseButton/PlayPauseButton'
import RecordButton from 'components/video/RecordButton/RecordButton'
import './ScreenRecorder.scss'
import VideoStreamMerger, { AddStreamOptions } from 'video-stream-merger'
import { Switch } from '@material-ui/core'
import { generateImage } from '../../../services/openGraphService'
import useStore from '../../../state/useStore'

let chunks: Array<BlobPart> | null = null
let recorder: MediaRecorder | null = null
let backupVolume: number | null

const recordingOptions = { videoBitsPerSecond }
let cameraRadius = 0
let screenHeight = 0
const cameraStreamOptions: Partial<AddStreamOptions> = {
  draw: (ctx, frame, done) => {
    ctx.save()
    ctx.beginPath()
    ctx.arc(cameraRadius, screenHeight - cameraRadius, cameraRadius, 0, 2 * Math.PI, false)
    ctx.clip()
    ctx.drawImage(
      frame,
      -cameraRadius / aspectRatio + cameraRadius,
      screenHeight - 2 * cameraRadius,
      (2 * cameraRadius) / aspectRatio,
      2 * cameraRadius
    )
    ctx.restore()
    done()
  },
}

type Props = {
  uploadVideo: (video: Blob, onProgressChange: (progress: number) => void) => VideoUploadHandle
  onUploadSuccess: (storageId: string) => void
}

const ScreenRecorder = ({ uploadVideo, onUploadSuccess }: Props) => {
  const { dispatch } = useStore()
  const [canRecord, setCanRecord] = useState(isMediaRecorderSupported)
  const [isCheckingPermissions, setCheckingPermissions] = useState(true)
  const [isRequestingPermissions, setRequestingPermissions] = useState(false)
  const [isRequestingScreenStream, setRequestingScreenStream] = useState(false)
  const [isPermissionGranted, setPermissionGranted] = useState(false)
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null)
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null)
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null)
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null)
  const [isPlaying, setPlaying] = useState(false)
  const [isRecording, setRecording] = useState(false)
  const [justFinishedRecording, setJustFinishedRecording] = useState(false)
  const [recordingTime, setRecordingTime] = useState(0)
  const [recordedVideoBlob, setRecordedVideoBlob] = useState<Blob | null>(null)
  const [recordedVideoUrl, setRecordedVideoUrl] = useState<string | null>(null)
  const [isUploadingVideo, setUploadingVideo] = useState(false)
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0)
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState<string | null>(null)
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState<string | null>(null)
  const [cameraVisible, setCameraVisible] = useState(true)
  const [merger, setMerger] = useState<VideoStreamMerger | undefined>()

  const videoRef = useRef<HTMLVideoElement>(null)
  const recordingTimerRef = useRef(0)
  const abortUploadRef = useRef<any>(null)
  const audioStreamRef = useRef<MediaStream | null>(null)
  const cameraStreamRef = useRef<MediaStream | null>(null)
  const screenStreamRef = useRef<MediaStream | null>(null)
  const videoStreamRef = useRef<MediaStream | null>(null)
  const mountedRef = useRef(true)
  const isCancelDisabled = isUploadingVideo && uploadVideoProgress >= 100

  audioStreamRef.current = audioStream // Used for cleanup when component unmounts.
  cameraStreamRef.current = cameraStream
  screenStreamRef.current = screenStream
  videoStreamRef.current = videoStream

  const checkPermissions = async () => {
    setCheckingPermissions(true)
    const hasPermissions = await checkCameraAndMicrophonePermissions()
    if (hasPermissions) {
      setPermissionGranted(true)
      if (selectedAudioDeviceId) {
        await getAudioStream(selectedAudioDeviceId)
      }
    }
    setCheckingPermissions(false)
  }

  const requestPermissions = async () => {
    setRequestingPermissions(true)

    // null if permissions blocked, audio/video input not found
    let permissionGranted = await getCameraAndMicrophoneStream(
      selectedVideoDeviceId || undefined,
      selectedAudioDeviceId || undefined
    )

    // if video input not found, ask for audio only
    if (!permissionGranted) {
      permissionGranted = await getMicrophoneStream(selectedAudioDeviceId || undefined)
    }

    if (permissionGranted) {
      setPermissionGranted(true)
    } else {
      setCanRecord(false)
    }
    setRequestingPermissions(false)
  }

  const getAudioStream = async (audioDeviceId?: string) => {
    const audioStream = await getMicrophoneStream(audioDeviceId)
    if (audioStream) {
      const audioStreamAny: any = audioStream
      audioStreamAny.oninactive = () => {
        if (mountedRef.current) {
          setAudioStream(null)
        }
      }
      setAudioStream(audioStream)
      setPlaying(true)

      return true
    } else {
      setCanRecord(false)
      return false
    }
  }

  const getCameraStream = async () => {
    const cameraStream = await getCameraAndMicrophoneStream(selectedVideoDeviceId || undefined, undefined)
    if (cameraStream) {
      const cameraStreamAny: any = cameraStream
      cameraStreamAny.oninactive = () => {
        if (mountedRef.current) {
          setRecording(false)
          setCameraStream(null)
        }
      }
      setCameraStream(cameraStream)
      return cameraStream
    }
  }

  const getVideoStream = async () => {
    setRequestingScreenStream(true)
    const screenStream = await getScreenCaptureStream()
    setRequestingScreenStream(false)
    if (screenStream) {
      const screenStreamAny: any = screenStream
      screenStreamAny.oninactive = () => {
        if (mountedRef.current) {
          setRecording(false)
          setScreenStream(null)
          setVideoStream(null)
        }
      }
      setScreenStream(screenStream)
      setPlaying(true)
    }

    if (screenStream === null) return
    const cameraStream = cameraVisible ? await getCameraStream() : null

    const merger = new VideoStreamMerger({
      width: screenStream.getVideoTracks()[0].getSettings().width,
      height: screenStream.getVideoTracks()[0].getSettings().height,
    })

    dispatch({type:"SET_VIDEO_DIMENSION",
      value: {
        width: screenStream.getVideoTracks()[0].getSettings().width,
        height: screenStream.getVideoTracks()[0].getSettings().height
    }})

    if (screenStream) {
      merger.addStream(screenStream, {
        mute: true,
      })
      setScreenStream(screenStream)
    }

    screenHeight = screenStream.getVideoTracks()[0].getSettings().height || 0
    cameraRadius = screenHeight / 5
    if (cameraStream) {
      merger.addStream(cameraStream, cameraStreamOptions)
    }
    merger.start()
    const videoStream = merger.result
    setMerger(merger)

    if (videoStream) {
      const videoStreamAny: any = videoStream
      videoStreamAny.oninactive = () => {
        if (mountedRef.current) {
          setRecording(false)
          setVideoStream(null)
          setScreenStream(null)
        }
      }
      setVideoStream(videoStream)
      setPlaying(true)
    }
  }

  const toggleRecording = () => {
    if (isRecording) {
      // Prevent some buttons etc from flashing visible briefly after recording ends.
      setJustFinishedRecording(true)
      setTimeout(() => setJustFinishedRecording(false), 500)
      cleanUpOutputStream(cameraStreamRef.current) // turn off camera when finished recording
    }

    setRecording(!isRecording)
  }

  const startRecording = () => {
    if (audioStream && videoStream) {
      setRecordingTime(0)
      const startTime = Date.now()
      recordingTimerRef.current = setInterval(() => setRecordingTime(Date.now() - startTime), 100) as any

      const streamToRecord = new MediaStream([...audioStream.getAudioTracks(), ...videoStream.getTracks()])

      chunks = []
      recorder = new MediaRecorder(streamToRecord, recordingOptions)
      recorder.addEventListener('dataavailable', onRecordingReady)
      recorder.addEventListener('stop', onRecordingFinished)
      recorder.start()
    }
  }

  const onRecordingReady = (e: any) => chunks && chunks.push(e.data)

  const onRecordingFinished = () => {
    clearInterval(recordingTimerRef.current)

    if (chunks) {
      const recordedVideoBlob = new Blob(chunks)
      const recordedVideoUrl = URL.createObjectURL(recordedVideoBlob)
      chunks = null
      setPlaying(false)
      setRecordedVideoBlob(recordedVideoBlob)
      setRecordedVideoUrl(recordedVideoUrl)
    }
  }

  const cancelUpload = () => {
    const abortUpload = abortUploadRef.current
    if (abortUpload) {
      abortUpload()
    }
  }

  const discardRecordedVideo = () => {
    const video = videoRef.current
    if (video && isVideoPlaying(video)) {
      video.pause()
    }
    if (recordedVideoUrl) {
      URL.revokeObjectURL(recordedVideoUrl)
    }
    setUploadingVideo(false)
    setRecordedVideoBlob(null)
    setRecordedVideoUrl(null)

    cleanUpVideoElement()
    cleanUpOutputStream(cameraStreamRef.current)
    cleanUpOutputStream(screenStreamRef.current)
    cleanUpOutputStream(videoStreamRef.current)
    setCameraStream(null)
    setScreenStream(null)
    setVideoStream(null)
  }

  const onSaveButtonClick = () => {
    if (!isUploadingVideo) {
      doUpload()
        .then(generateImage)
        .catch(console.error);
    } else {
      cancelUpload()
    }
  }

  const doUpload = async () => {
    if (recordedVideoBlob) {
      setUploadingVideo(true)
      try {
        const { response, abort } = uploadVideo(recordedVideoBlob, setUploadVideoProgress)

        abortUploadRef.current = abort
        const { status, storageId } = await response
        abortUploadRef.current = null

        if (status === 'success' && storageId) {
          onUploadSuccess(storageId)
        } else {
          setUploadVideoProgress(0)
          setUploadingVideo(false)
        }
      } catch (e) {}
    }
  }

  const onUnmount = () => {
    const recordingTimer = recordingTimerRef.current
    if (recordingTimer) {
      clearInterval(recordingTimer)
    }

    cleanUpVideoElement()
    cleanUpOutputStream(audioStreamRef.current)
    cleanUpOutputStream(cameraStreamRef.current)
    cleanUpOutputStream(screenStreamRef.current)
    cleanUpOutputStream(videoStreamRef.current)

    mountedRef.current = false
  }

  const cleanUpVideoElement = () => {
    const video = videoRef.current
    if (video && video.src) {
      if (isVideoPlaying(video)) {
        video.pause()
      }

      URL.revokeObjectURL(video.src)
    }
  }

  const cleanUpOutputStream = (streamToCleanUp: MediaStream | null) => {
    if (streamToCleanUp) {
      streamToCleanUp.getTracks().forEach(track => track.stop())
    }
  }

  const toggleCameraVisible = async () => {
    setCameraVisible(!cameraVisible)
    if (cameraVisible) {
      if (cameraStream) merger?.removeStream(cameraStream)
    } else {
      const theCameraStream = cameraStream ? cameraStream : await getCameraStream()
      if (theCameraStream) merger?.addStream(theCameraStream, cameraStreamOptions)
    }
  }

  if (isRecording) {
    document.body.classList.add('recording')
  } else {
    document.body.classList.remove('recording')
  }

  useEffect(() => {
    // Check permissions silently when component mounts and show video stream if permissions exist:
    checkPermissions()
    return onUnmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    cleanUpOutputStream(audioStreamRef.current)
    setAudioStream(null)

    // Update audio stream whenever selected device changes:
    if (isPermissionGranted && selectedAudioDeviceId) {
      getAudioStream(selectedAudioDeviceId)
    }
  }, [selectedAudioDeviceId, isPermissionGranted])

  async function changeCameraStream() {
    cleanUpOutputStream(cameraStream)
    merger?.removeStream(cameraStream || '')
    const newCameraStream = await getCameraStream()
    if (newCameraStream) merger?.addStream(newCameraStream, cameraStreamOptions)
  }

  useEffect(() => {
    if (isPermissionGranted && selectedVideoDeviceId && screenStream) {
      changeCameraStream()
    }
  }, [selectedVideoDeviceId, isPermissionGranted])

  // Some video attributes (e.g. srcObject, playing & pausing) have to be set imperatively:
  useEffect(() => {
    const video = videoRef.current
    if (video) {
      const isVideoElementPlaying = isVideoPlaying(video)
      if (!isPlaying && isVideoElementPlaying) {
        video.pause()
      }
      if (!recordedVideoBlob && videoStream && videoStream !== video.srcObject) {
        backupVolume = video.volume
        video.volume = 0 // Mute while streaming microphone or we'll get feedback.
        video.srcObject = videoStream
      } else if (video.srcObject && (recordedVideoBlob || !videoStream)) {
        video.srcObject = null
        video.volume = backupVolume || 0
      }

      if (isPlaying && !isVideoElementPlaying) {
        video.play()
      }

      if (isRecording && !recorder) {
        startRecording()
      } else if (!isRecording && recorder) {
        recorder.stop()
        recorder = null
      }
    }
  })

  useEffect(() => {
    const video = videoRef.current as any
    if (video) {
      // React doesn't recognize & strips this attribute so we have to set it in JS:
      video.disablePictureInPicture = true
    }
  }, [recordedVideoUrl])

  return (
    <div className="screen-recorder">
      {selectedVideoDeviceId && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Switch defaultChecked color="primary" onChange={() => toggleCameraVisible()} />
          <span>Camera {cameraVisible ? 'On' : 'Off'}</span>
        </div>
      )}
      <div className="video-with-toolbar">
        <VideoLayoutContainer>
          {!isCheckingPermissions && !isPermissionGranted && !recordedVideoBlob ? (
            canRecord ? (
              <div className="video-text-overlay">
                <div className="text">PreviewMe needs your permission to record audio and video.</div>
                <LoadingButton
                  type="button"
                  className="primary"
                  onClick={requestPermissions}
                  isLoading={isRequestingPermissions}
                >
                  Allow
                </LoadingButton>
              </div>
            ) : (
              <div className="device-error-message">
                {isMediaRecorderSupported
                  ? "Sorry, we can't find your camera and microphone.\n\n If you do have a camera and microphone " +
                    "installed, please check your browser's camera permissions."
                  : 'Sorry, your browser does not support video recording. We recommend Chrome or Firefox.'}
              </div>
            )
          ) : !isCheckingPermissions ? (
            <>
              {!screenStream && !recordedVideoUrl ? (
                <div className="video-text-overlay">
                  <div className="text">What part of your screen would you like to record?</div>
                  <LoadingButton
                    type="button"
                    className="primary"
                    onClick={getVideoStream}
                    isLoading={isRequestingScreenStream}
                  >
                    Select recording area
                  </LoadingButton>
                </div>
              ) : (
                <video
                  ref={videoRef}
                  controlsList="nodownload"
                  crossOrigin="anonymous"
                  src={recordedVideoUrl || undefined}
                  onEnded={() => setPlaying(false)}
                  onPause={() => setPlaying(false)}
                />
              )}
            </>
          ) : null}
        </VideoLayoutContainer>
        <div className="video-toolbar">
          <div className="left-buttons" />
          {!recordedVideoBlob && audioStream && videoStream ? (
            <RecordButton disabled={!isPermissionGranted} recording={isRecording} onClick={toggleRecording} />
          ) : recordedVideoBlob ? (
            <PlayPauseButton isPlaying={isPlaying} togglePlaying={() => setPlaying(!isPlaying)} />
          ) : null}
          <div className="right-buttons">
            <VideoSettingsMenuButton
              hasPermission={isPermissionGranted}
              selectedVideoDeviceId={selectedVideoDeviceId}
              selectedAudioDeviceId={selectedAudioDeviceId}
              onSelectedVideoDeviceChange={setSelectedVideoDeviceId}
              style={{ display: !recordedVideoBlob && !isRecording && !justFinishedRecording ? 'flex' : 'none' }}
              onSelectedAudioDeviceChange={setSelectedAudioDeviceId}
            />
            {(recordedVideoBlob || isRecording) && (
              <div className="video-duration">{formatVideoTime(recordingTime / 1000)}</div>
            )}
          </div>
        </div>
      </div>
      <div className="recorded-video-buttons" style={{ visibility: recordedVideoBlob ? 'visible' : 'hidden' }}>
        {isUploadingVideo ? (
          <ProgressBar progress={uploadVideoProgress} />
        ) : (
          <button type="button" className="link" onClick={discardRecordedVideo}>
            Discard video
          </button>
        )}
        <LoadingButton
          type="button"
          className={'save-button primary' + (isCancelDisabled ? ' disabled' : '')}
          disabled={isCancelDisabled}
          onClick={onSaveButtonClick}
        >
          {isUploadingVideo ? 'Cancel' : 'Save video'}
        </LoadingButton>
      </div>
    </div>
  )
}

export default ScreenRecorder
