import React, { useEffect, useState } from 'react'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import './InvitationLearnMoreDialog.scss'
import LearnMoreIndicator from '../../../assets/icons/LearnMoreIndicator'
import { Product, Subscription } from '../../../state/types'
import { ApiResponse } from 'apisauce'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import VideoContentFeatureImage from '../../../assets/video-content-feature.png'
import BrandingFeatureImage from '../../../assets/branding-feature.png'
import SuccessPageFeatureImage from '../../../assets/success-page-feature.png'
import UpgradePersonLadderImage from '../../../assets/dashboard-illustration.png'
import UpgradeSuccessImage from '../../../assets/person-celebrating.svg'
import UpgradeErrorImage from '../../../assets/error-illustration.png'
import useStore from '../../../state/useStore'

type Props = {
  isShowing: boolean
  onClose: () => void
  newSubscription?: Subscription
  newProduct?: Product
  changePlan?: (subscription: Subscription, billingType: string | undefined) => Promise<ApiResponse<Subscription, Subscription>>
  setShowEnterpriseFeatures?: (value: boolean) => void
}

export const InvitationLearnMoreDialog: React.FC<Props> = ({
                                                             isShowing,
                                                             onClose,
                                                             newProduct,
                                                             newSubscription,
                                                             changePlan,
                                                             setShowEnterpriseFeatures
                                                           }) => {
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [isUpgrading, setUpgrading] = useState(false)
  const [isUpgradeSuccessful, setUpgradeSuccessful] = useState<boolean | undefined>(undefined)
  const { state } = useStore()
  useEffect(() => {
    setUpgradeSuccessful(undefined)
    setPageIndex(0)
  }, [isShowing])

  const onDialogClose = () => {
    if (isUpgradeSuccessful && setShowEnterpriseFeatures) {
      setShowEnterpriseFeatures(true)
    }
    onClose()
  }

  const upgradeNow = async () => {
    if (changePlan && newSubscription && setShowEnterpriseFeatures) {
      setUpgrading(true)
      const { ok } = await changePlan(newSubscription, state.billingType)
      if (ok) {
        setUpgradeSuccessful(true)
      } else {
        setUpgradeSuccessful(false)
      }
      setUpgrading(false)
    }
  }

  return (
    <CloseableDialog className='invitation-learn-more-dialog' isShowing={isShowing} onClose={onDialogClose}>
      <div className={'invitation-learn-more'}>
        {isUpgradeSuccessful === undefined ?
          pageIndex === 0 ?
            <>
              <h2>Request specific video content</h2>
              <p>Request tailored video or audio chapters you would like to receive from applicants. </p>
              <div className={'action-buttons'}>
                <button className={'primary'} onClick={() => setPageIndex(3)}>upgrade now</button>
                <button className={'next-button'} onClick={() => setPageIndex(pageIndex + 1)}>Next feature></button>
              </div>
              <img src={VideoContentFeatureImage} alt={'Custom video chapters'} />
            </>
            : pageIndex === 1 ?
              <>
                <h2>Add your branding</h2>
                <p>Each Preview created in response to this Invitation will include your branding and is visible when a
                  Preview is shared. For example, when sharing applicant Previews with clients or stakeholders.
                </p>
                <div className={'action-buttons'}>
                  <button className={'primary'} onClick={() => setPageIndex(3)}>upgrade now</button>
                  <button className={'next-button'} onClick={() => setPageIndex(pageIndex + 1)}>Next feature></button>
                </div>
                <img src={BrandingFeatureImage} alt={'Custom preview branding'} />
              </> :
              pageIndex === 2 ?
                <>
                  <h2>Create a personalised success page</h2>
                  <p>Create a customised message to applicants who submit a Preview. It appears at the end of the
                    application process, heros your branding and guides the applicant back to your website or a website of
                    your choice.
                  </p>
                  <div className={'action-buttons'}>
                    <button className={'primary'} onClick={() => setPageIndex(3)}>upgrade now</button>
                    <button className={'next-button'} onClick={() => setPageIndex(pageIndex + 1)}>Next feature></button>
                  </div>
                  <img src={SuccessPageFeatureImage} alt={'Custom success page'} />
                </> :
                <>
                  <div className={'upgrade-page'}>
                    <img src={UpgradePersonLadderImage} alt={'Upgrade subscription'} />
                    <h2>Ready to upgrade?</h2>
                    {newProduct && newSubscription &&
                    <p>
                      <b>
                        A one-off payment of {'US$' + newSubscription.amountDue}
                      </b>{' '}
                      will be charged now for the upgrade to our {newProduct.name} plan for the current billing
                      period.
                      From the next billing period you will be charged {' '}
                      {`${'US$' + newProduct.prices[0].amount}/month`}.
                    </p>}
                    <LoadingButton className='primary upgrade-to-enterprise-button' isLoading={isUpgrading}
                                   onClick={upgradeNow}>
                      Upgrade to enterprise
                    </LoadingButton>
                  </div>
                </> :
          isUpgradeSuccessful ?
            <>
              <div className={'upgrade-page'}>
                <img src={UpgradeSuccessImage} alt={'Subscription upgrade successful'} />
                <h2>Upgrade successful!</h2>
                <p>
                  You now have access to all features in PreviewMe. Use them to create even more engaging and bespoke
                  Previews and Invitations!
                </p>
              </div>
            </> :
            <>
              <div className={'upgrade-page'}>
                <img src={UpgradeErrorImage} alt={'Subscription upgrade failed'} />
                <h2>Hmmm, looks there's an issue with your credit card details...</h2>
                <p>
                  We weren't able to process the payment with the card linked to your account.
                </p>
              </div>
            </>
        }
        {isUpgradeSuccessful === undefined && <div className={'learn-more-indicator'}>
          <LearnMoreIndicator index={pageIndex} setPageIndex={setPageIndex} />
        </div>}
      </div>
    </CloseableDialog>
  )
}
