import React from 'react'
import MicrophoneIcon from '../../../assets/icons/MicrophoneIcon'
import RecordIcon from '../../../assets/icons/RecordIcon'
import './VideoAudioIdentifierIcon.scss'
import { RecordingType } from '../../../state/types'
import { dynamicClasses } from '../../../helpers/classes'

/**
 * Displays Mic Icon if Audio videoChapter
 * Displays Record Icon if video videoChapter
 * Displays No Icon if type undefined
 */

interface Props {
  videoChapterType: RecordingType
  isCenter: boolean
}

const VideoAudioIdentifierIcon = function ({ videoChapterType, isCenter }: Props) {
  const classes = dynamicClasses({
    "video-audio-identifier-icon": true,
    "centered-icon": isCenter
  })
  let child
  if (videoChapterType === 'audio') {
    child = <MicrophoneIcon previewBlue />
  } else if (videoChapterType === 'video') {
    child = <RecordIcon />
  }
  return <div className={classes}>{child}</div>
}

export default VideoAudioIdentifierIcon
