import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CloudinaryUploadResult, RecordingType, TeleprompterFontSize } from 'state/types'
import useVideoChapter from 'state/useVideoChapter'
import { formatChapterLabel, videoFileSizeLimitInBytes, videoFileSizeLimitInMb } from 'helpers/media'
import { parseIndexParam } from 'helpers/urlParams'
import VideoRecorder from 'components/video/VideoRecorder/VideoRecorder'
import VideoRecorderPageHeader from 'components/video/VideoRecorderPageHeader/VideoRecorderPageHeader'
import Teleprompter from 'components/video/Teleprompter/Teleprompter'
import TeleprompterEditor from 'components/video/TeleprompterEditor/TeleprompterEditor'
import VideoSpeedSlider from 'components/video/VideoSpeedSlider/VideoSpeedSlider'
import Dialog from 'components/shared/Dialog/Dialog'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import './VideoRecorderPage.scss'
import AudioRecorder from '../Audio/AudioRecorder/AudioRecorder'
import VideoAudioSwitch from './VideoAudioSwitch'
import useWorkspace from '../../../state/useWorkspace'

const teleprompterHiddenKey = 'is-teleprompter-hidden'
const getInitialTeleprompterShowing = () => localStorage.getItem(teleprompterHiddenKey) !== 'true'

const VideoRecorderPage = () => {
  const [isEditing, setEditing] = useState(false)
  const [isTeleprompterFullscreen, setIsTeleprompterFullscreen] = useState(false)
  const [teleprompterFontSize, setTeleprompterFontSize] = useState<TeleprompterFontSize>('26px')
  const [isTeleprompterShowing, setTeleprompterShowing] = useState(getInitialTeleprompterShowing())
  const [isTeleprompterScrolling, setTeleprompterScrolling] = useState(false)
  const [teleprompterSpeed, setTeleprompterSpeed] = useState(16) // Pixels per second.
  const [isRecording, setRecording] = useState(false)
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)
  const { previewId, index, fieldIndex } = useParams<any>()
  const { currentWorkspace } = useWorkspace(true)
  const chapterIndex = parseIndexParam(fieldIndex) || 0
  const { contentBlockField, isLoading, videoChapter, uploadVideo, saveTeleprompterText, saveType } = useVideoChapter(
    previewId,
    parseIndexParam(index),
    chapterIndex
  )

  useEffect(() => {
    if (contentBlockField?.format === 'audio-only') setRecordingType('audio')
  })
  const [recordingType, setRecordingType] = useState<RecordingType>('video')
  const history = useHistory()
  const videoTrimEditorPath = `/edit/${previewId}/video/${index}/trim/${chapterIndex}/true`
  const teleprompterText = videoChapter?.teleprompterText || ''
  const videoBlockEditorPath = `/edit/${previewId}/block/${index}`

  const onRecordingChange = (recording: boolean) => {
    const shouldTeleprompterScroll = recording && isTeleprompterShowing && !!teleprompterText
    setTeleprompterScrolling(shouldTeleprompterScroll)
    setRecording(recording)
  }

  const handleUploadVideo = (blob: Blob, onProgressChange: (progress: number) => void) => {
    // We limit the recording time, but because different browsers record in different formats,
    // for safety we also do a file size check, because we'd rather show this dialog than have the
    // upload fail mysteriously.
    if (blob.size >= videoFileSizeLimitInBytes) {
      setFileSizeErrorDialogShowing(true)
      return { response: Promise.resolve({ status: 'error' } as CloudinaryUploadResult) }
    } else {
      return uploadVideo(blob, onProgressChange)
    }
  }

  const toggleTeleprompterShowing = () => {
    const newValue = !isTeleprompterShowing
    setTeleprompterShowing(newValue)
    localStorage.setItem(teleprompterHiddenKey, newValue ? 'false' : 'true')
  }

  const stopTeleprompter = () => {
    if (isTeleprompterScrolling) {
      setTeleprompterScrolling(false)
    }
  }

  return (
    <div className={'video-recorder-page' + (isEditing ? ' editing' : '')}>
      <VideoRecorderPageHeader
        showEditScriptButton={!!teleprompterText}
        isTeleprompterTextLoading={isLoading}
        isTeleprompterShowing={isTeleprompterShowing}
        isTeleprompterScrolling={isTeleprompterScrolling}
        teleprompterText={teleprompterText}
        teleprompterSpeed={teleprompterSpeed}
        setTeleprompterSpeed={setTeleprompterSpeed}
        stopTeleprompter={stopTeleprompter}
        toggleTeleprompterShowing={toggleTeleprompterShowing}
        toggleTeleprompterScrolling={() => setTeleprompterScrolling(!isTeleprompterScrolling)}
        editScript={() => setEditing(true)}
        isRecording={isRecording}
        backLink={videoBlockEditorPath}
        setIsTeleprompterFullscreen={setIsTeleprompterFullscreen}
        teleprompterFontSize={teleprompterFontSize}
        setTeleprompterFontSize={setTeleprompterFontSize}
      />
      {isTeleprompterShowing && (
        <>
          <Teleprompter
            isTextLoading={isLoading}
            text={teleprompterText}
            speed={teleprompterSpeed}
            isScrolling={isTeleprompterScrolling}
            isRecording={isRecording}
            editScript={() => setEditing(true)}
            isFullscreen={isTeleprompterFullscreen}
            setIsFullscreen={setIsTeleprompterFullscreen}
            teleprompterFontSize={teleprompterFontSize}
          />
          <VideoSpeedSlider
            className="mobile-speed-slider"
            isRecording={isRecording}
            isTeleprompterTextLoading={isLoading}
            isTeleprompterScrolling={isTeleprompterScrolling}
            isTeleprompterShowing={isTeleprompterShowing}
            teleprompterText={teleprompterText}
            teleprompterSpeed={teleprompterSpeed}
            toggleTeleprompterScrolling={() => setTeleprompterScrolling(!isTeleprompterScrolling)}
            setTeleprompterSpeed={setTeleprompterSpeed}
          />
        </>
      )}
      <div className={`recorder-container-panel ${isTeleprompterFullscreen ? 'fullscreen' : ''}`}>
        <div className="inner-panel">
          <VideoAudioSwitch
            activeRecordingType={recordingType}
            onChange={setRecordingType}
            audioOnly={contentBlockField?.format === 'audio-only'}
            videoOnly={contentBlockField?.format === 'video-only'}
          />
          <div className="chapter-name">
            {videoChapter && (
              <>
                <span className="label">{formatChapterLabel(chapterIndex) + ':'}</span>
                <span className="name">{`${videoChapter.name}`}</span>
              </>
            )}
          </div>
          {recordingType === 'video' ? (
            <VideoRecorder
              uploadVideo={handleUploadVideo}
              onUploadSuccess={() => {
                saveType('video')
              }}
              linkDirection={videoTrimEditorPath}
              onRecordingChange={onRecordingChange}
            />
          ) : (
            <AudioRecorder
              uploadVideo={handleUploadVideo}
              onUploadSuccess={() => {
                saveType('audio')
              }}
              onRecordingChange={onRecordingChange}
              profileImageStorageId={videoChapter?.profileImageStorageId || currentWorkspace?.profileImageStorageId}
              linkDirection={videoTrimEditorPath}
            />
          )}
        </div>
        <div className="spacer" />
      </div>
      <Dialog className="teleprompter-editor-dialog" isShowing={isEditing} onClose={() => setEditing(false)}>
        <TeleprompterEditor
          initialText={teleprompterText}
          save={saveTeleprompterText}
          close={() => setEditing(false)}
        />
      </Dialog>
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Video too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
        className="video-file-size-error-dialog"
      >
        {`This video is too large. The size limit is ${videoFileSizeLimitInMb} MB.`}
      </ConfirmationDialog>
    </div>
  )
}

export default VideoRecorderPage
