import ChangePlanFeedbackPage from 'components/account/ChangePlanFeedbackPage/ChangePlanFeedbackPage'
import ErrorPage from 'components/shared/ErrorPage/ErrorPage'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { formatDate } from 'helpers/date'
import { capitalize } from 'lodash'
import React from 'react'
import useSubscription from 'state/useSubscription'
import './ChangePlanSuccessPage.scss'
import { useUrlParams } from '../../../helpers/urlParams'
import useStore from '../../../state/useStore'
import { BillingType } from '../../../helpers/enum'

const ChangePlanSuccessPage = () => {
  const { state } = useStore()
  const { isSubscriptionLoading, subscription, subscriptionError, newProduct, editedSubscriptionName, editedNextSubscriptionName } = useSubscription(true)
  return isSubscriptionLoading ? (
    <LoadingSpinner fullScreen />
  ) : subscriptionError ? (
    <ErrorPage titleText="Unable to load subscription">
      Your subscription information is not currently available. Please try again later.
    </ErrorPage>
  ) : subscription ? (
    <ChangePlanFeedbackPage title="Success - your plan has been changed!" className="change-plan-success-page">
      {newProduct && subscription.nextBillingStartPeriod ? (
        <p className="cancel-text">
          Your {editedSubscriptionName()} Plan has been cancelled. You will be downgraded to the{' '}
          {editedNextSubscriptionName(0)} Plan on {formatDate(subscription.nextBillingStartPeriod)}.
        </p>
      ) : (
        <p>You have successfully changed to the {editedSubscriptionName()} Plan.</p>
      )}
    </ChangePlanFeedbackPage>
  ) : (
    <></>
  )
}

export default ChangePlanSuccessPage
