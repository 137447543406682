import ClicksIcon from 'assets/icons/ClicksIcon'
import DownloadIcon from 'assets/icons/DownloadIcon'
import ModalTickIcon from 'assets/icons/ModalTickIcon'
import PreviewsIcon from 'assets/icons/PreviewsIcon'
import RecordIcon from 'assets/icons/RecordIcon'
import AnalyticsRangeDropdown, { getDefaultRange } from 'components/analytics/AnalyticsRangeDropdown/AnalyticsRangeDropdown'
import AnalyticsSummaryCard from 'components/analytics/AnalyticsSummaryCard/AnalyticsSummaryCard'
import AnalyticsVideoCard from 'components/analytics/AnalyticsVideoCard/AnalyticsVideoCard'
import DocumentDownloadsListMobile from 'components/analytics/DocumentDownloadsListMobile/DocumentDownloadsListMobile'
import DocumentDownloadsTable from 'components/analytics/DocumentDownloadsTable/DocumentDownloadsTable'
import DonutChart from 'components/analytics/DonutChart/DonutChart'
import LinkClicksListMobile from 'components/analytics/LinkClicksListMobile/LinkClicksListMobile'
import LinkClicksTable from 'components/analytics/LinkClicksTable/LinkClicksTable'
import PreviewViewsChart from 'components/analytics/PreviewViewsChart/PreviewViewsChart'
import ViewPreviewLink from 'components/preview/ViewPreviewLink/ViewPreviewLink'
import BackLink from 'components/shared/BackLink/BackLink'
import ErrorWidget from 'components/shared/ErrorWidget/ErrorWidget'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import { viewsByReferrerMapper } from 'helpers/analytics'
import { PreviewCategory } from 'helpers/enum'
import {
  ENGAGEMENT_PERCENTAGE,
  PLAY_VIDEO,
  VIEW_DOCUMENT,
  VIEW_LINK,
  VIEW_PREVIEW,
  WATCHED_IN_FULL
} from 'helpers/events'
import { getConcatenatedTitles } from 'helpers/titles'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as analyticsService from 'services/analyticsService'
import {
  AnalyticsEvent,
  DocumentAnalytics,
  LinkAnalytics,
  PreviewAnalyticsRange,
  PreviewViewsByCategory,
  PreviewViewsOverTime
} from 'state/types'
import useAuth from 'state/useAuth'
import usePreview from 'state/usePreview'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import { isOnPaymentPlanOrAdmin } from '../../../helpers/subscription'
import AnalyticsCta from '../AnalyticsCta/AnalyticsCta'
import './AnalyticsPreviewPage.scss'
import useVideoSuite from '../../../state/useVideoSuite'


interface VideoSuite {
  id: string;
  captions: string;
  createdAt: string;
  cta: string;
  images: string;
  organizationId: string;
  previewId: string;
  rawVideoStorageId: string;
  updatedAt: string;
  videoId: string;
  videofx: string;
  watermark: string;
}

const AnalyticsPreviewPage: React.FC = () => {
  const { user } = useAuth()
  const { previewId } = useParams<any>()
  const { isLoading, isInitializing, preview } = usePreview(previewId)
  const [isViewedByInviterLoading, setViewedByInviterLoading] = useState(true)
  const [isViewedByInviter, setViewedByInviter] = useState(false)
  const [range, setRange] = useState<PreviewAnalyticsRange>(getDefaultRange())
  const [summary, setSummary] = useState<AnalyticsEvent[] | undefined>()
  const [previewViewsOverTime, setPreviewViewsOverTime] = useState<PreviewViewsOverTime[] | undefined>()
  const [chapterViews, setChapterViews] = useState<number[] | undefined>()
  const [engagementPercentage, setEngagementPercentage] = useState<number[] | undefined>()
  const [timesWatchedInFull, setTimesWatchedInFull] = useState<number[] | undefined>()
  const [viewsByLocation, setViewsByLocation] = useState<PreviewViewsByCategory[] | undefined>()
  const [viewsByPlatform, setViewsByPlatform] = useState<PreviewViewsByCategory[] | undefined>()
  const [viewsByReferrer, setViewsByReferrer] = useState<PreviewViewsByCategory[] | undefined>()
  const referrerChartData = viewsByReferrerMapper(viewsByReferrer)
  const [documentDownloads, setDocumentDownloads] = useState<DocumentAnalytics[] | undefined>()
  const [linkClicks, setLinkClicks] = useState<LinkAnalytics[] | undefined>()
  const { organizationsLoaded } = useWorkspace(true)
  const { isSubscriptionLoading, subscription } = useSubscription(organizationsLoaded)
  const responseData = {
    cta: true,
    watermark: true,
    videoFx: true,
    images: true,
    captions: true
  };
  const videoChapters = useMemo(
    () =>
      preview?.contentBlocks
        ?.flatMap(b => b.contentBlockFields)
        ?.filter(f => f.controlType === 'Video' && !!f.videoChapter)
        ?.map(f => f.videoChapter!),
    [preview]
  )
  const header = preview && getConcatenatedTitles(preview)
  const locationChartData = viewsByLocation?.map(v => ({ id: v.category, label: v.category, value: v.count }))
  const platformChartData = viewsByPlatform?.map(v => ({ id: v.category, label: v.category, value: v.count }))

  const anyChaptersWithViewData = chapterViews?.some(c => c >= 0)
  const anyViewsExist =
    previewViewsOverTime &&
    previewViewsOverTime.some(viewsOverTime => viewsOverTime.counts.some(viewsByDay => viewsByDay.count > 0))

  const documents = preview?.contentBlocks?.find(cb => cb.contentBlockType.name === 'Documents')?.contentBlockFields
  const { getVideoSuiteByPreviewID } = useVideoSuite(responseData?.cta);

  const lookupCount = (eventType: string) =>
    summary ? summary.find(e => e.eventType === eventType)?.count || 0 : undefined

  const lookupTrendPercentage = (eventType: string) =>
    summary ? summary?.find(e => e.eventType === eventType + '_TREND')?.count : undefined

  useEffect(() => {
    if (previewId) {
      const checkViewedByInviter = async () => {
        setViewedByInviterLoading(true)
        const { ok, data } = await analyticsService.isPreviewViewedByInviter(previewId)
        setViewedByInviterLoading(false)

        if (ok && data !== undefined) {
          setViewedByInviter(data)
        }
      }
      checkViewedByInviter()
    }
  }, [previewId])

  useEffect(() => {
    if (previewId) {
      const loadAnalytics = async () => {
        const { ok, data } = await analyticsService.getAnalyticsSummary(range, previewId)
        if (ok && data) {
          setSummary(data)
        }
      }
      loadAnalytics()

      const loadPreviewViewsOverTime = async () => {
        if (range !== 'last-24-hours') {
          const { ok, data } = await analyticsService.getPreviewViewsOverTime(range, previewId)
          if (ok && data) {
            setPreviewViewsOverTime([{ previewId, previewName: '', counts: data }])
          }
        }
      }
      loadPreviewViewsOverTime()

      const loadViewsByLocation = async () => {
        const { ok, data } = await analyticsService.getPreviewViewsByLocation(range, previewId)
        if (ok && data) {
          setViewsByLocation(data)
        }
      }
      loadViewsByLocation()

      const loadViewsByPlatform = async () => {
        const { ok, data } = await analyticsService.getPreviewViewsByPlatform(range, previewId)
        if (ok && data) {
          setViewsByPlatform(data)
        }
      }
      loadViewsByPlatform()

      const loadViewsByReferrer = async () => {
        const { ok, data } = await analyticsService.getPreviewViewsByReferrer(range, previewId)
        if (ok && data) {
          setViewsByReferrer(data)
        }
      }
      loadViewsByReferrer()

      const fetchVideoSuite = async () => {
        if (responseData?.cta || responseData?.watermark) {
          if (previewId) {
            const response = await getVideoSuiteByPreviewID(previewId);
            return response;
          }
        }
        return null;
      }

      const mergeCTAIntoLinkClicks = (clicksData: LinkAnalytics[], videoData: VideoSuite[]): LinkAnalytics[] => {
        return clicksData.map((clickItem: LinkAnalytics) => {
          const videoItem = videoData.find((video: VideoSuite) => video.videoId === clickItem.linkUrl);
          if (videoItem) {
            try {
              const ctaObject = JSON.parse(videoItem.cta);

              if (ctaObject.length > 0) {
                const cta = ctaObject[0];
                const linkUrl = cta.linkButton;
                const linkText = cta.buttonContent || cta.textContent;

                return { ...clickItem, linkUrl, linkText };
              }
            } catch (error) {
              console.error('Error parsing CTA JSON:', error);
            }
          }
          return clickItem;
        });
      };


      const loadLinkClicks = async () => {
        const videoSuiteResponse = await fetchVideoSuite();

        if (videoSuiteResponse) {
          const { ok, data } = await analyticsService.getPreviewLinkClicks(range, previewId);
          if (ok && data) {

            const videoDataArray = Array.isArray(videoSuiteResponse) ? videoSuiteResponse : [videoSuiteResponse];
            const mergedData = mergeCTAIntoLinkClicks(data, videoDataArray);

            setLinkClicks(mergedData);
          }
        } else {
          const { ok, data } = await analyticsService.getPreviewLinkClicks(range, previewId);
          if (ok && data) {
            setLinkClicks(data);
          }
        }
      };


      loadLinkClicks()

      if (documents && documents.length > 0) {
        const loadDocumentDownloads = async () => {
          const documentData = await Promise.all(
            documents.map(async document => {
              const { ok, data: analyticsData } = await analyticsService.getPreviewDocumentDownloads(range, document.id)
              if (ok && analyticsData) {
                const uniqueViews = analyticsData.find(e => e.eventType === 'UNIQUE_USER_COUNT')?.count || 0
                const totalViews = analyticsData.find(e => e.eventType === 'TOTAL_COUNT')?.count || 0
                return {
                  name: document.formLabel,
                  url: document.value,
                  uniqueViews,
                  totalViews
                }
              } else {
                return {}
              }
            })
          )
          setDocumentDownloads(documentData)
        }
        loadDocumentDownloads()
      }
    }
  }, [previewId, documents, range])

  useEffect(() => {
    if (videoChapters && videoChapters.length > 0) {
      const loadVideoAnalytics = async () => {
        const responses = await Promise.all(
          videoChapters.map(c => analyticsService.getVideoChapterAnalytics(range, c.id))
        )
        const chapterViews = responses.map(({ ok, data }) => {
          if (ok && data) {
            return data.find(e => e.eventType === PLAY_VIDEO)?.count || 0
          }
          return -1
        })
        setChapterViews(chapterViews)

        const engagementPercentage = responses.map(({ ok, data }) => {
          if (ok && data) {
            return data.find(e => e.eventType === ENGAGEMENT_PERCENTAGE)?.count || 0
          }
          return -1
        })
        setEngagementPercentage(engagementPercentage)

        const timesWatchedInFull = responses.map(({ ok, data }) => {
          if (ok && data) {
            return data.find(e => e.eventType === WATCHED_IN_FULL)?.count || 0
          }
          return -1
        })
        setTimesWatchedInFull(timesWatchedInFull)
      }
      loadVideoAnalytics()
    }
  }, [videoChapters, range])

  if (isSubscriptionLoading) {
    return  <LoadingSpinner container expand/>
  }
  else if (!isOnPaymentPlanOrAdmin(subscription, user!)) {
    return (
      <PageFrame
        className="analytics-page"
        header={
          <>
            <div className="top-row">
              <h1>Analytics</h1>
            </div>
          </>
        }
      >
        <AnalyticsCta
          title="Upgrade your subscription to access Analytics"
          body="We're collating data all the time to deliver insights on what really matters so you can make better decisions concerning your Previews and content."
        />
      </PageFrame>
    )
  } else {
    return (
      <PageFrame
        className="analytics-preview-page"
        header={
          preview &&
          !isViewedByInviterLoading && (
            <>
              <div className="link-row">
                <BackLink to="/analytics/breakdown">Back</BackLink>
                <ViewPreviewLink
                  previewId={preview.id}
                  isPublished={preview.status === 'published'}
                  fromParam="analytics"
                />
              </div>
              <h1>{header}</h1>
              {isViewedByInviter && (
                <div className="viewed-by-invitor-message">
                  <ModalTickIcon/>
                  <div className="viewed-by-invitor-text">
                    <span>Preview has been viewed by </span>
                    <span className="invitor-name">{preview.invitation?.ownerName}</span>
                  </div>
                </div>
              )}
              <div className="tags-and-range-dropdown">
                <div className="tags-row">
                  <div className="tag-group">
                    <div className="tags">
                      <div className="tag">
                        <span className="tag-category">{preview?.previewCategory}</span> > {preview.previewType}
                      </div>
                    </div>
                  </div>
                </div>
                <AnalyticsRangeDropdown className="analytics-range-dropdown" value={range} onChange={setRange}/>
              </div>
            </>
          )
        }
      >
        {isLoading || isInitializing || isViewedByInviterLoading ? (
          <LoadingSpinner container expand/>
        ) : preview ? (
          <div className="page-content">
            <h2 className="analytics-section-title">Summary</h2>
            <div className="analytics-cards analytics-summary-cards">
              <AnalyticsSummaryCard
                title="Preview views"
                className="preview-views"
                value={lookupCount(VIEW_PREVIEW)}
                trendPercentage={lookupTrendPercentage(VIEW_PREVIEW)}
                icon={<PreviewsIcon/>}
              />
              <AnalyticsSummaryCard
                title="Media clicks"
                className="video-views"
                value={lookupCount(PLAY_VIDEO)}
                trendPercentage={lookupTrendPercentage(PLAY_VIDEO)}
                icon={<RecordIcon/>}
              />
              <AnalyticsSummaryCard
                title="Document downloads"
                className="document-downloads"
                value={lookupCount(VIEW_DOCUMENT)}
                trendPercentage={lookupTrendPercentage(VIEW_DOCUMENT)}
                icon={<DownloadIcon/>}
              />
              <AnalyticsSummaryCard
                title="Links clicked"
                className="link-clicks"
                value={lookupCount(VIEW_LINK)}
                trendPercentage={lookupTrendPercentage(VIEW_LINK)}
                icon={<ClicksIcon/>}
              />
            </div>
            {range !== 'last-24-hours' && previewViewsOverTime && (
              <>
                <h2 className="analytics-section-title">Preview Views</h2>
                {anyViewsExist ? (
                  <PreviewViewsChart data={previewViewsOverTime!} hideLegend range={range}/>
                ) : (
                  <div className="no-data-message">You have no Preview views in this time period..</div>
                )}
              </>
            )}
            {anyChaptersWithViewData && (
              <>
                <h2 className="analytics-section-title">Media</h2>
                <div className="analytics-cards analytics-video-cards">
                  {videoChapters?.map((c, i) => {
                    const views = chapterViews ? chapterViews[i] : -1
                    const engagementPercentageNumber = engagementPercentage ? engagementPercentage[i] : -1
                    const timesWatchedInFullNumber = timesWatchedInFull ? timesWatchedInFull[i] : -1
                    return views >= 0 ? (
                      <AnalyticsVideoCard
                        key={c.id}
                        chapter={c}
                        chapterNumber={i + 1}
                        views={views}
                        engagementPercentage={engagementPercentageNumber}
                        timesWatchedInFull={timesWatchedInFullNumber}
                      />
                    ) : null
                  })}
                </div>
              </>
            )}
            {documentDownloads && (
              <>
                <h2 className="analytics-section-title">Document Downloads</h2>
                <DocumentDownloadsTable data={documentDownloads}/>
                <DocumentDownloadsListMobile data={documentDownloads}/>
              </>
            )}
            {linkClicks && linkClicks.length > 0 && (
              <>
                <h2 className="analytics-section-title">Link Clicks</h2>
                <LinkClicksTable data={linkClicks}/>
                <LinkClicksListMobile data={linkClicks}/>
              </>
            )}
            {locationChartData && locationChartData?.length > 0 && platformChartData && platformChartData?.length > 0  &&  referrerChartData &&
            referrerChartData?.length > 0 && (
              <>
                <h2 className="analytics-section-title"> { preview.previewCategory === PreviewCategory.Business  ? 'Location, Platform and Referrer' : 'Location and Platform'}</h2>
                <div className="donuts">
                  <DonutChart
                    title={
                      <>
                        <span className="light">Preview views by</span>
                        <span> location</span>
                      </>
                    }
                    data={locationChartData}
                  />
                  <DonutChart
                    title={
                      <>
                        <span className="light">Preview views by</span>
                        <span> platform</span>
                      </>
                    }
                    data={platformChartData}
                  />
                  { preview.previewCategory === PreviewCategory.Business  ? <DonutChart
                  title={
                    <>
                      <span className="light">Preview views by</span>
                      <span> referrer</span>
                    </>
                  }
                  data={referrerChartData}
                /> : ''}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="error-container">
            <ErrorWidget
              title="404 - Not Found"
              message="We couldn't find this Preview. It may have been deleted by its owner."
            />
          </div>
        )}
      </PageFrame>
    )
  }
}

export default AnalyticsPreviewPage
