import React from 'react'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import './SharingDialog.scss'
import { PreviewMeObject } from 'helpers/enum'
import SharingModal from '../SharingModal/SharingModal'
import { InvitationSuccessPageInfo } from '../../../state/types'

type Props = {
  id: string
  onPublish?: boolean
  invitationSuccessPage?: InvitationSuccessPageInfo
  invitationOrgName?: string
  isShowing: boolean
  onClose: () => void
  object: PreviewMeObject
}

const SharingDialog: React.FC<Props> = ({ id, isShowing, onClose, object, ...rest }) => (
  <CloseableDialog className="preview-sharing-dialog" isShowing={isShowing} onClose={onClose}>
    <SharingModal
      previewId={id}
      previewType={object === PreviewMeObject.Preview ? 'preview' : 'invitation'}
      {...rest}
    />
  </CloseableDialog>
)

export default SharingDialog
