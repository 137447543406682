import MoveUpRightArrowIcon from 'assets/icons/MoveUpRightArrowIcon'
import SwitchButton from 'components/shared/SwitchButton/SwitchButton'
import TableOptionButton from 'components/shared/TableOptionButton/TableOptionButton'
import { IntegrationStatus } from 'helpers/enum'
import { getDefaultIntegrationName, getIntegrationDocumentationLink, getIntegrationIcon } from 'helpers/integration'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Integration } from 'state/types'
import './IntegrationsListMobile.scss'

type Props = {
  integrations: Integration[]
  deleteIntegration: (integration: Integration) => void
  updateEnabledIntegration: (id: string, enabled: boolean) => void
}

const IntegrationsListMobile: React.FC<Props> = ({ integrations, deleteIntegration, updateEnabledIntegration }) => {
  const { push } = useHistory()
  const renderStatus = (type: IntegrationStatus) => {
    if (type)
      return (
        <div className={'status-item ' + type.toLowerCase()}>
          <div className="status-prefix"></div>
          <div className="status-label">{type[0].toUpperCase() + type.toLowerCase().substring(1)}</div>
        </div>
      )
    return null
  }
  return (
    <div className="integration-list-card-mobile">
      {integrations.map(({ id, name, type, status, platformIdOrKey, enabled }, index) => {
        return (
          <div key={index} className="integration-list-card">
            <div className="card-title-row">
              <div className="integration-title-container">
                <img className="integration-icon" src={getIntegrationIcon(type)} />
                <div
                  className="integration-title"
                  onClick={() => {
                    push('/organization/settings/integrations/' + id)
                  }}
                >
                  {name || getDefaultIntegrationName(type)}
                </div>
              </div>
              <TableOptionButton
                options={[
                  { option: 'Edit', onClick: () => push(`/organization/settings/integrations/${id}`) },
                  {
                    option: 'Delete',
                    onClick: () => {
                      const currentIntegration = integrations.find(integration => integration.id === id)
                      if (currentIntegration) deleteIntegration(currentIntegration)
                    },
                  },
                ]}
              />
            </div>
            <div className="card-row">
              <div className="id">ID: {platformIdOrKey}</div>
              <SwitchButton checked={enabled} onChange={(value: boolean) => updateEnabledIntegration(id, value)} />
            </div>
            <div className="card-row">
              {renderStatus(status)}

              <a className="documentation-link" href={getIntegrationDocumentationLink(type)}>
                Documentation
              </a>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default IntegrationsListMobile
