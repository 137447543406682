import React from 'react'

const ShareOnTwitterIcon = () => (
  <svg className="icon" width="50px" height="50px" viewBox="0 0 50 50">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-843.000000, -442.000000)">
        <ellipse cx="868.126638" cy="465" rx="20" ry="20" fill="#007AFF"></ellipse>
        <path
          d="M876.117904,460.115125 C875.528686,460.372393 874.896582,460.546048 874.233712,460.624148 C874.912431,460.224463 875.432659,459.5914 875.675058,458.837054 C875.042022,459.207336 874.34186,459.47563 873.592285,459.619884 C872.996541,458.992334 872.143481,458.6 871.199986,458.6 C869.389447,458.6 867.921996,460.047132 867.921996,461.831469 C867.921996,462.085062 867.949965,462.330385 868.006836,462.566521 C865.282636,462.431455 862.86703,461.146953 861.248545,459.190798 C860.966056,459.6695 860.804767,460.224463 860.804767,460.81618 C860.804767,461.936214 861.38373,462.925777 862.262895,463.505549 C861.725886,463.48901 861.220576,463.342919 860.77773,463.102189 L860.77773,463.141698 C860.77773,464.708277 861.907686,466.013911 863.407767,466.30977 C863.132737,466.386031 862.844654,466.423703 862.543519,466.423703 C862.332818,466.423703 862.12771,466.404407 861.927265,466.366736 C862.344938,467.650319 863.55414,468.584753 864.989892,468.609561 C863.867395,469.476922 862.453085,469.993296 860.917576,469.993296 C860.653734,469.993296 860.392688,469.978595 860.135371,469.948274 C861.586973,470.864331 863.310808,471.4 865.161436,471.4 C871.194393,471.4 874.491029,466.476994 874.491029,462.206346 C874.491029,462.065767 874.488232,461.926107 874.481706,461.788285 C875.123133,461.332553 875.67972,460.762889 876.117904,460.115125"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </svg>
)

export default ShareOnTwitterIcon
