import { useState } from 'react'
import useContentBlockField from 'state/useContentBlockField'
import * as documentService from 'services/documentService'

const useDocument = (previewId?: string, blockIndex?: number, fieldIndex?: number) => {
  const [isUploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const { isLoading, contentBlockField, contentBlock, isValidationActive, remove, rename, updateStoreValue } =
    useContentBlockField(previewId, blockIndex, fieldIndex)

  const uploadDocument = async (file: File) => {
    setUploading(true)
    const { ok, data } = await documentService.uploadFile(contentBlockField?.id!, file, setProgress)
    setUploading(false)
    if (ok) {
      updateStoreValue(data)
    }
  }

  const deleteUploadedDocument = async () => {
    const { ok } = await documentService.deleteUploadedFile(contentBlockField?.id!)
    if (ok) {
      updateStoreValue(undefined)
    }
  }

  return {
    isLoading,
    isUploading,
    contentBlockField,
    contentBlock,
    progress,
    isValidationActive,
    uploadDocument,
    deleteUploadedDocument,
    remove,
    rename,
  }
}

export default useDocument
