import { createMuiTheme } from '@material-ui/core'

export const customTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: 14,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#000000',
        fontSize: '14px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '14px',
      },
    },
    MuiFormControlLabel: {
      root: {
        height: 40,
      },
    },
  },
})
