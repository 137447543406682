import React from 'react'
import { Link } from 'react-router-dom'
import Tooltip from 'components/shared/Tooltip/Tooltip'
import TickIcon from 'assets/icons/TickIcon'
import CrossIcon from 'assets/icons/CrossIcon'
import './PreviewEditorStepper.scss'

type Props = {
  previewId: string
  steps: Array<{
    blockTitle: string
    link: string
    isActive: boolean
    isComplete: boolean
    isIncomplete: boolean
  }>
  isFirstBlockInCompleted?: boolean // use to trigger in complete block in case of duplicate preview has no title or subtitle
}

const PreviewEditorStepper: React.FC<Props> = ({ steps, isFirstBlockInCompleted }) => (
  <div className="preview-editor-stepper">
    {steps.map(({ blockTitle, link, isActive, isComplete, isIncomplete }, i) => {
      const greenLine = i > 0 && isComplete && steps[i - 1].isComplete
      const tooltipText = `${i + 1}. ${blockTitle}`
      const isCompleted = isComplete && !isActive && (!isFirstBlockInCompleted || i !== 0)
      const isIncompleted = isIncomplete || (!!isFirstBlockInCompleted && i === 0)

      return (
        <React.Fragment key={i}>
          {i > 0 && <div className={'step-line' + (greenLine ? ' green' : '')} />}
          <Tooltip content={tooltipText}>
            <Link to={link}>
              <div
                className={
                  'step' +
                  (isCompleted ? ' completed' : isIncompleted ? ' incomplete' : '') +
                  (isActive && !isIncompleted ? ' active' : '')
                }
              >
                {isCompleted && <TickIcon />}
                {isIncompleted && <CrossIcon />}
              </div>
            </Link>
          </Tooltip>
        </React.Fragment>
      )
    })}
  </div>
)

export default PreviewEditorStepper
