import React from 'react'
import PageFrame from '../../shared/PageFrame/PageFrame'
import AccountBillingForm from '../AccountBillingForm/AccountBillingForm'
import './OrganizationPlanBillingPage.scss'
import AuthorizedPage from '../../auth/AuthorizedPage/AuthorizedPage'
import UnauthorizedPage from '../../auth/UnauthorizedPage/UnauthorizedPage'

const OrganizationPlanBillingPage: React.FC = () => {
  return (
    <AuthorizedPage UnauthorizedComponent={UnauthorizedPage}>
      <PageFrame className='organization-plan-billing-page' header={<h1 className='header'>Plan/Billing</h1>}>
        <AccountBillingForm organizationSettings />
      </PageFrame>
    </AuthorizedPage>
  )
}

export default OrganizationPlanBillingPage
