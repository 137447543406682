import React from 'react'
import useContentBlock from 'state/useContentBlock'
import { BlockProps } from 'components/preview/PreviewEditorPage/PreviewEditorPage'
import DocumentCard from 'components/preview/DocumentCard/DocumentCard'
import PreviewEditorBottomNav from 'components/preview/PreviewEditorBottomNav/PreviewEditorBottomNav'
import AddFieldButton from 'components/shared/AddFieldButton/AddFieldButton'
import './DocumentBlockEditor.scss'

const maxDocuments = 10

const DocumentBlockEditor: React.FC<BlockProps> = ({ previewId, blockIndex, prevLink, nextLink }) => {
  const { contentBlock, addField } = useContentBlock(previewId, blockIndex)
  const block = contentBlock!

  return (
    <div className="document-block-editor">
      <div className="documents">
        {block.contentBlockFields.map((field, i) => (
          <DocumentCard key={field.id} previewId={previewId} blockIndex={blockIndex} fieldIndex={i} />
        ))}
        {block.editable && block.contentBlockFields?.length < maxDocuments && (
          <AddFieldButton
            buttonText="Add document"
            dialogTitle="Add Document"
            description="Start by giving your document a name."
            placeholder="e.g. CV"
            dialogAddButtonText="Add document"
            validationErrorText="Please enter a name for your document"
            onSubmit={name => addField(name, 'File')}
          />
        )}
      </div>
      <PreviewEditorBottomNav prevLink={prevLink} nextLink={nextLink} />
    </div>
  )
}

export default DocumentBlockEditor
