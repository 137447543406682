import ChangePlanFeedbackPage from 'components/account/ChangePlanFeedbackPage/ChangePlanFeedbackPage'
import React from 'react'
import './ChangePaymentMethodSuccessPage.scss'

const ChangePaymentMethodSuccessPage = () => (
  <ChangePlanFeedbackPage
    title="Success - your payment method has been updated!"
    className="change-payment-method-success-page"
  >
    <p>Your credit card details have been successfully changed.</p>
  </ChangePlanFeedbackPage>
)

export default ChangePaymentMethodSuccessPage
