import { isChrome, isFirefox, isIos, isMicrosoftEdge, isSafari } from './browser'
import { VideoChapter } from 'state/types'

type VideoQuality = 720 | 480 // Relevant for camera feed, not screen capture.

export const isMediaRecorderSupported =
  !!window.MediaRecorder && !isIos && (isChrome || isFirefox || isSafari || isMicrosoftEdge) && !!navigator.mediaDevices

export const videoFileSizeLimitInMb = 300
export const videoFileSizeLimitInBytes = videoFileSizeLimitInMb * 1e6
export const imageFileSizeLimitInMb = 5
export const imageFileSizeLimitInBytes = imageFileSizeLimitInMb * 1e6
export const businessLogoImageFileSizeLimitInMb = 10
export const businessLogoImageFileSizeLimitInBytes = businessLogoImageFileSizeLimitInMb * 1e6
export const watermarkImageFileSizeLimitInKb = 400
export const watermarkImageFileSizeLimitInBytes = watermarkImageFileSizeLimitInKb * 1024;

export const aspectRatio = 0.75 // 4:3
export const quality: VideoQuality = 480
export const videoBitsPerSecond = 2.5e6

// Specifies desired resolution, browser will try to honor this but not guaranteed.
const videoOptions = {
  width: quality / aspectRatio,
  height: quality,
}

// Silently checks permissions have been granted.
export const checkCameraAndMicrophonePermissions = async () => {
  let audioAllowed = false
  let videoAllowed = false
  if (isMediaRecorderSupported) {
    const devices = await navigator.mediaDevices.enumerateDevices()
    devices.forEach(mediaDeviceInfo => {
      if (mediaDeviceInfo.kind === 'audioinput' && !!mediaDeviceInfo.label) {
        audioAllowed = true
      }
      if (mediaDeviceInfo.kind === 'videoinput' && !!mediaDeviceInfo.label) {
        videoAllowed = true
      }
    })
  }
  return audioAllowed && videoAllowed
}

export const checkMicrophonePermissions = async () => {
  let audioAllowed = false
  if (isMediaRecorderSupported) {
    const devices = await navigator.mediaDevices.enumerateDevices()
    devices.forEach(mediaDeviceInfo => {
      if (mediaDeviceInfo.kind === 'audioinput' && !!mediaDeviceInfo.label) {
        audioAllowed = true
      }
    })
  }
  return audioAllowed
}

// Asks user for permissions if necessary and returns the camera & microphone stream object.
export const getCameraAndMicrophoneStream: (
  videoDeviceId?: string,
  audioDeviceId?: string
) => Promise<MediaStream | null> = async (videoDeviceId, audioDeviceId) =>
  new Promise(resolve => {
    navigator.mediaDevices
      .getUserMedia({
        audio: audioDeviceId ? { deviceId: audioDeviceId } : true,
        video: videoDeviceId ? { ...videoOptions, deviceId: videoDeviceId } : videoOptions,
      })
      .then(resolve, () => resolve(null))
  })

export const getMicrophoneStream: (audioDeviceId?: string) => Promise<MediaStream | null> = async audioDeviceId =>
  new Promise(resolve => {
    navigator.mediaDevices
      .getUserMedia({
        audio: audioDeviceId ? { deviceId: audioDeviceId } : true,
      })
      .then(resolve, () => resolve(null))
  })

// Asks user for permissions if necessary and returns the screen capture stream object.
export const getScreenCaptureStream: () => Promise<MediaStream | null> = async () =>
  new Promise(resolve => {
    const mediaDevices: any = navigator.mediaDevices
    if (mediaDevices.getDisplayMedia) {
      mediaDevices.getDisplayMedia().then(resolve, () => resolve(null))
    } else {
      resolve(null)
    }
  })

export const getRecordingDevices: () => Promise<MediaDeviceInfo[]> = async (audioOnly?: boolean) => {
  if (isMediaRecorderSupported) {
    const allDevices = await navigator.mediaDevices.enumerateDevices()
    return allDevices.filter(device => device.kind === 'audioinput' || (device.kind === 'videoinput' && !audioOnly))
  } else {
    return []
  }
}

export const formatVideoTime = (timeInSeconds: number) => {
  const roundedTimeInSeconds = Math.round(timeInSeconds)
  const minuteCount = Math.floor(roundedTimeInSeconds / 60)
  const remainingSeconds = roundedTimeInSeconds - 60 * minuteCount
  let minuteString = minuteCount.toString()
  let secondString = remainingSeconds.toString()
  if (minuteString.length === 1) {
    minuteString = '0' + minuteString
  }
  if (secondString.length === 1) {
    secondString = '0' + secondString
  }
  return minuteString + ':' + secondString
}

export const formatChapterLabel = (index: number) => {
  return `Chapter ${(index + 1).toString().padStart(2, '0')}`
}

// Stops all videos playing other than the given video element.
export const stopAllOtherVideos = (videoElement: HTMLVideoElement) => {
  const videos = document.querySelectorAll('video')
  videos.forEach(video => {
    if (video !== videoElement && !video.paused && !video.ended && video.readyState > 2) {
      video.pause()
    }
  })
}

export const isVideoPlaying = (video: HTMLVideoElement) => {
  // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState.
  return !video.paused && !video.ended && video.readyState > 2
}

export const getVideoDuration = (chapter: VideoChapter) =>
  chapter.duration !== undefined
    ? chapter.duration - (chapter.startOffsetTime || 0) - (chapter.endOffsetTime || 0)
    : undefined
