import { useEffect, useState } from 'react'
import { Preview, ViewingSelection } from './types'

/**
 * custom hook to manage selected previews, for bulk action (e.g. archive).
 */

interface Props {
  viewingSelection: ViewingSelection
}

const useSelectPreviews = ({ viewingSelection }: Props) => {
  useEffect(() => {
    setPreviewsSelected([])
  }, [viewingSelection])

  const [previewsSelected, setPreviewsSelected] = useState<Preview[]>([])

  const selectPreview = function (preview: Preview) {
    setPreviewsSelected([...previewsSelected, preview])
  }

  const bulkSelectPreviews = function (previews: Preview[]) {
    setPreviewsSelected([...previews])
  }

  const unselectPreview = function (id: string) {
    setPreviewsSelected(previewsSelected.filter(e => e.id !== id))
  }

  const clear = function () {
    setPreviewsSelected([])
  }

  return {
    previewsSelected,
    selectPreview,
    bulkSelectPreviews,
    unselectPreview,
    clear,
  }
}

export default useSelectPreviews
