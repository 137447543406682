import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { ContentBlockField } from 'state/types'
import useContentBlock from 'state/useContentBlock'
import { getChildren } from 'helpers/validation'
import * as imageService from 'services/imageService'
import FormListEditor from 'components/preview/FormListEditor/FormListEditor'
import FormInput from 'components/shared/FormInput/FormInput'
import ProfileImageSelector from 'components/shared/ProfileImageSelector/ProfileImageSelector'
import DropdownFreeText from 'components/shared/DropdownFreeText/DropdownFreeText'
import CrossIcon from 'assets/icons/CrossIcon'
import './TeamMemberInput.scss'

type Props = {
  previewId: string
  blockIndex: number
  field: ContentBlockField
  register: any
  control: any
  errors?: any
  removeFromList?: () => void
}

const TeamMemberInput: React.FC<Props> = ({
  previewId,
  blockIndex,
  field,
  register,
  control,
  errors,
  removeFromList,
}) => {
  const { updateValues } = useContentBlock(previewId, blockIndex)
  const { name, role, email, phone, location, links, profileImage } = getChildren(field)
  const isEmailInvalid = email && errors[email.id]?.type === 'email'
  const emailError = isEmailInvalid ? 'Invalid email' : undefined
  const locationOptions = location?.options
    ? Object.entries(location.options).map(o => ({ value: o[0], displayValue: o[1] }))
    : []
  const [profileImageStorageId, setProfileImageStorageId] = useState<string | undefined>(
    profileImage ? profileImage.value : undefined
  )

  const uploadImage = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
    if (status === 'success' && storageId && profileImage) {
      await updateValues([{ id: profileImage.id, value: storageId }])
      setProfileImageStorageId(storageId)
    }
  }

  const deleteImage = async () => {
    if (profileImage) {
      await updateValues([{ id: profileImage.id, value: '' }], true)
      setProfileImageStorageId(undefined)
    }
  }

  return (
    <div className="team-member-input">
      <div className="team-member-title-row">
        <div className="team-member-title">Team Member</div>
        {removeFromList && (
          <button type="button" className="remove-button plain" onClick={removeFromList}>
            <CrossIcon />
            <span>Remove</span>
          </button>
        )}
      </div>
      <FormInput name={name?.id} label="Name*" inputRef={register} errors={errors} />
      <FormInput name={role?.id} label="Role*" inputRef={register} errors={errors} />
      <div className="team-member-input-row">
        <FormInput
          name={email?.id}
          label="Email"
          type="email"
          spellCheck={false}
          inputRef={register}
          error={emailError}
        />
        <FormInput name={phone?.id} label="Phone" type="tel" inputRef={register} />
      </div>
      {location && (
        <Controller
          name={location.id}
          control={control}
          as={
            <DropdownFreeText
              label="Location"
              options={locationOptions}
              placeholder={location.placeholder}
              inputProps={{ spellCheck: false }}
            />
          }
        />
      )}
      {links && (
        <FormListEditor
          label={links.formLabel || ''}
          field={links}
          previewId={previewId}
          blockIndex={blockIndex}
          register={register}
          control={control}
        />
      )}
      <div className="team-member-profile-image-label">Profile image</div>
      <ProfileImageSelector
        value={profileImageStorageId}
        canDelete
        warnBeforeDeleting={false}
        uploadImage={uploadImage}
        deleteImage={deleteImage}
      />
    </div>
  )
}

export default TeamMemberInput
