import React from 'react'
import introImage from '../../../assets/johnny-farquhar-ceo.png'
import previewsImage from '../../../assets/unpublished-preview-illustration.png'
import invitationsImage from '../../../assets/dashboard-illustration-small.png'
import organisationSettingsImage from '../../../assets/woman_blue_overalls_small.png'
import previewmeSettingsImage from '../../../assets/video-illustration-small.png'
import { ModalHighlight } from '../../../state/types'

interface OnboardingModalPage {
  content: JSX.Element
  id: ModalHighlight
}

const ModalImage = function ({ src }: { src: string }) {
  const images: string[] = [
    introImage,
    previewsImage,
    invitationsImage,
    organisationSettingsImage,
    previewmeSettingsImage,
  ]
  const loadedImages = images.map(image => (
    <img src={image} className={`modal-image ${image === src && 'visible'}`} alt="" />
  ))
  return <>{loadedImages}</>
}

const BusinessIntro = function (givenName: string): OnboardingModalPage {
  return {
    content: (
      <div className="modal-content">
        <ModalImage src={introImage} />
        <span className="modal-title">
          <b>Hi {givenName}</b>, welcome to PreviewMe Business!
        </span>
        <p>
          <span className="modal-line">
            You just joined a community of thousands of businesses and professionals using video to make faster, more
            informed hiring decisions and super-charge workplace comms. 👏
          </span>
          <span className="modal-line helper-message">
            To help you get started we’ve highlighted a few key features that are available as part of your PreviewMe
            toolbox.
          </span>
          <span className="modal-line">Enjoy!</span>
          <span className="modal-line">Johnny Farquhar, CEO</span>
        </p>
      </div>
    ),
    id: 'N/A',
  }
}

const BusinessPreviews: OnboardingModalPage = {
  content: (
    <div className="modal-content">
      <ModalImage src={previewsImage} />
      <span className="modal-title">
        <b>Previews</b>
      </span>
      <p>
        Create and manage all of your Preview content. <br /> <br /> A Preview packages up videos and documents into a
        private shareable link or QR code. Previews can be used to onboard employees, promote jobs or services, pitch
        for work or investment or simply send quick video comms.
      </p>
    </div>
  ),
  id: 'previews',
}

const BusinessInvitations: OnboardingModalPage = {
  content: (
    <div className="modal-content">
      <ModalImage src={invitationsImage} />
      <span className="modal-title">
        <b>Invitations</b>
      </span>
      <p>
        Request, screen and manage videos you want to receive from others. <br /> <br /> PreviewMe Invitations allow you
        to request and receive video as part of an application process. E.g. when hiring new employees and using video
        as part of your screening process.
      </p>
    </div>
  ),
  id: 'invitations',
}

const BusinessOrganisationSettings: OnboardingModalPage = {
  content: (
    <div className="modal-content">
      <ModalImage src={organisationSettingsImage} />
      <span className="modal-title">
        <b>Workspace Settings</b>
      </span>
      <p>
        View and change your Business Plan and billing details, add and remove team members and update basic information
        about your organisation's account.
      </p>
    </div>
  ),
  id: 'Organisation Settings',
}

const BusinessPreviewMeSettings: OnboardingModalPage = {
  content: (
    <div className="modal-content">
      <ModalImage src={previewmeSettingsImage} />
      <span className="modal-title">
        <b>My PreviewMe</b>
      </span>
      <p>
        Update and manage personal information and navigate to PreviewMe Personal. Access FAQs and get in touch with us
        via our contact & support page.
      </p>
    </div>
  ),
  id: 'previewme-settings',
}

const PersonalIntro = function (givenName: string): OnboardingModalPage {
  return {
    content: (
      <div className="modal-content">
        <ModalImage src={introImage} />
        <span className="modal-title">
          <b>Hi {givenName}</b>, welcome to PreviewMe Personal!
        </span>
        <p>
          <span className="modal-line">
            You just joined a community of thousands of people using video to tell their story and communicate more
            effectively both personally and professionally. 👏
          </span>
          <span className="modal-line helper-message">
            To help you get started we’ve highlighted a few key features that are available as part of your PreviewMe
            toolbox.
          </span>
          <span className="modal-line">Enjoy!</span>
          <span className="modal-line">Johnny Farquhar, CEO</span>
        </p>
      </div>
    ),
    id: 'N/A',
  }
}

const PersonalPreviews: OnboardingModalPage = {
  content: (
    <div className="modal-content">
      <ModalImage src={previewsImage} />
      <span className="modal-title">
        <b>Previews</b>
      </span>
      <p>
        Create and manage all of your Preview content. <br /> <br /> A Preview packages up your videos and documents
        into a private shareable link or QR code. Use a PreviewMe to apply for jobs, promote your services as a
        contractor, or add another dimension to your college application.
      </p>
    </div>
  ),
  id: 'previews',
}

const PersonalPreviewMeSettings: OnboardingModalPage = {
  content: (
    <div className="modal-content">
      <ModalImage src={previewmeSettingsImage} />
      <span className="modal-title">
        <b>My PreviewMe</b>
      </span>
      <p>
        Update and manage your personal information, access our FAQs and get in touch with us via our contact & support
        page.
      </p>
    </div>
  ),
  id: 'previewme-settings',
}

export const BusinessModalPages = function (givenName: string): OnboardingModalPage[] {
  return [
    BusinessIntro(givenName),
    BusinessPreviews,
    BusinessInvitations,
    BusinessOrganisationSettings,
    BusinessPreviewMeSettings,
  ]
}

export const PersonalModalPages = function (givenName: string): OnboardingModalPage[] {
  return [PersonalIntro(givenName), PersonalPreviews, PersonalPreviewMeSettings]
}
