import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon'
import './BackLink.scss'

// Styled link with a back arrow icon.
const BackLink: React.FC<LinkProps> = ({ children, className, ...rest }) => (
  <Link className={'back-link' + (className ? ' ' + className : '')} {...rest}>
    <ChevronLeftIcon />
    <span>{children}</span>
  </Link>
)

export default BackLink
