import { InvitationAlgolia, PreviewAlgolia } from 'state/types'
import { ALGOLIA_PREVIEW_INDEX } from './constant'

export const MAX_HIT_PER_PAGE = 600

export const getPreviewSortingIndex = (sortingBy: string, sortingAsc: boolean) => {
  return ALGOLIA_PREVIEW_INDEX + '_' + sortingBy + '_' + (!sortingAsc ? 'desc' : 'asc')
}

export const getInvitationFromAlgoliaPreview = (preview: PreviewAlgolia): InvitationAlgolia | null => {
  if (!preview.invitation) return null
  const { invitation } = preview
  const startDate = invitation.split('; ')[1].split('startDate: ')[1]
  const endDate = invitation.split('; ')[2].split('endDate: ')[1]
  let ownerName = invitation.split('; ')[3].split('ownerName: ')[1]
  ownerName = ownerName.substring(0, ownerName.length - 1)

  return {
    id: invitation.split('; ')[0].split(': ')[1],
    // startDate: !!startDate && startDate !== 'null' ? startDate : null,
    endDate: !!endDate && endDate !== 'null' ? endDate : null,
    ownerName: !!ownerName ? ownerName : null,
  }
}

export const getDisplayTags = (tags: string) => {
  if (tags === '-') return '-'
  if (!!tags)
    return tags
      .substring(1, tags.length - 1)
      .split(',')
      .map(tag => (tag.startsWith('"') && tag.endsWith('"') ? tag.substring(1, tag.length - 1) : tag))
      .join(', ')
}

export const getDisplayTagsOnMobile = (tags: string) => {
  if (tags === '-' || !tags) return null
  if (!tags.startsWith('{')) return tags.split(',')

  if (!!tags)
    return tags
      .substring(1, tags.length - 1)
      .split(',')
      .map(tag => (tag.startsWith('"') && tag.endsWith('"') ? tag.substring(1, tag.length - 1) : tag))
}
