import React from 'react'
import { TeamMember } from 'state/types'
import Table from 'components/shared/Table/Table'
import './TeamMembersTable.scss'
import ProfileCell from '../../shared/ProfileCell/ProfileCell'
import SettingsIcon from '../../../assets/icons/SettingsIcon'
import DropdownMenu from '../../shared/DropdownMenu/DropdownMenu'
import DropdownMenuOption from '../../shared/DropdownMenuOption/DropdownMenuOption'
import { TeamMemberStatus } from '../../../helpers/enum'
import useAuth from '../../../state/useAuth'
import { useHistory, useLocation } from 'react-router-dom'

type Props = {
  teamMembers: TeamMember[]
  deleteInvite: (email: string) => Promise<void>
  deleteTeamMember: (userId: string) => Promise<void>
}

const TeamMembersTable: React.FC<Props> = ({ teamMembers, deleteInvite, deleteTeamMember }) => {
  const { user } = useAuth()
  const { push } = useHistory()
  const { pathname } = useLocation()

  const formatRole = (type: string): string | undefined => {
    return type ? type.replace('team ', '').replace(/^\w/, c => c.toUpperCase()): undefined
  }

  const formatAccount = (status: string): string | undefined => {
    return status ? status[0] + status.substring(1).toLowerCase() : undefined
  }

  const handleManagePermissions = (teamMember: TeamMember) => {
    push('/organization/settings/teamMembers/permissions', {
      from: pathname,
      profileRowData: teamMember,
    })
  }

  return (
    <>
      <Table
        className="team-members-table"
        columns={[
          {
            name: 'teamMember',
            label: 'Team Members',
            className: 'team-member',
            render: ({ profileImageStorageId, givenName, familyName, email }) => (
              <ProfileCell
                givenName={givenName}
                familyName={familyName}
                email={email}
                profileImageId={profileImageStorageId}
              />
            ),
          },
          {
            name: 'type',
            label: 'Role',
            className: 'role',
            render: ({ type }) => formatRole(type),
          },
          {
            name: 'status',
            label: 'Status',
            className: 'status',
            render: ({ status }) => formatAccount(status),
          },
          {
            name: 'settings',
            className: 'settings',
            render: (teamMember: TeamMember) => (
              <div className={'settings-button'}>
                {user && user.id !== teamMember.userId && (
                  <DropdownMenu
                    trigger={<SettingsIcon color={'black'} />}
                    menuContent={
                      <>
                        {teamMember.status === TeamMemberStatus.Pending ? (
                          <DropdownMenuOption
                            optionName="Cancel invitation"
                            onSelect={async () => {
                              await deleteInvite(teamMember.email)
                            }}
                          />
                        ) : (
                          <>
                            <DropdownMenuOption
                              optionName="Manage Permissions"
                              onSelect={() => handleManagePermissions(teamMember)} />
                            <DropdownMenuOption
                              optionName="Remove from Organization"
                              onSelect={async () => {await deleteTeamMember(teamMember.userId)}} />
                          </>
                        )}
                      </>
                    }
                    horizontalAlignment="right"
                  />
                )}
              </div>
            ),
          },
        ]}
        rows={teamMembers}
      />
    </>
  )
}

export default TeamMembersTable
