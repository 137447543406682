import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
import { event } from 'helpers/analytics'
import { parseIndexParam } from 'helpers/urlParams'
import useVideoChapter from 'state/useVideoChapter'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import VideoTrimEditor from 'components/video/VideoTrimEditor/VideoTrimEditor'
import ModalConfirmation from './Components/ModalConfirmation/ModalConfirmation';
import './VideoTrimEditorPage.scss'
import VideoTrimSideBar from "./VideoTrimSideBar/VideoTrimSideBar";
import useVideoSuite from '../../../state/useVideoSuite'
import useWatermark from '../../../state/useWatermark'
import useStore from '../../../state/useStore'
import { getCurrentWorkspace, getWorkspaceOrganizationId } from 'helpers/organization'
import MenuIcon from '@material-ui/icons/Menu'
import useWorkspace from '../../../state/useWorkspace'
import useSubscription from '../../../state/useSubscription'
import * as watermarkService from '../../../services/watermarkService'
import { VideoSuite } from '../../../state/types'

type CallsActionData = {
  id: number;
  type: string;
  previewDisableData: any;
  duration: string;
  fadeIn: string;
  fadeOut: string;
  textContent: string;
  textColor: string;
  textDropshadow: string;
  buttonContent: string;
  buttonColor: string;
  buttonDropshadow: string;
  buttonTextColor: string;
  linkButton: string;
  targetLink: string;
  newStart: number
  newEnd: number
};
const VideoTrimEditorPage = () => {
  const { dispatch } = useStore()
  const [showModal, setShowModal] = useState(false);
  const history = useHistory()
  const [startOffsetTime, setStartOffsetTime] = useState(0);
  const [endOffsetTime, setEndOffsetTime] = useState(0);
  const { updateWatermarkData,
    setWatermarkImage,
    setWatermarkOpacity,
    setWatermarkCoords,
    defaultWatermarkData
  } = useWatermark()
  const responseData = {
    cta: true,
    watermark: true,
    videoFx: true,
    images: true,
    captions: true
  };
  const { state } = useStore()
  const { previewId, index, fieldIndex } = useParams<any>()
  const {  isTrimming, isChapterUpdated, videoChapter, videoBlob, trimVideo, saveProfileImageStorageId } = useVideoChapter(
    previewId,
    parseIndexParam(index),
    parseIndexParam(fieldIndex),
    true
  )
  const [startEndOffsetTime, setStartEndOffsetTime] = useState<{ start: number; end: number; }[]>([]);
  const videoBlockEditorPath = `/edit/${previewId}/block/${index}`
  const {videoSuiteData,setVideoSuite,updateVideoSuite,getVideoSuiteByVideoID,setDataLoaded, video,isLoadingDelayed } = useVideoSuite(responseData?.cta)
  const currentWorkspace = getCurrentWorkspace()
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [drawerClass, setDrawerClass] = useState("MuiDrawer-paper");
  const { organizationsLoaded } = useWorkspace(true)
  const { subscription} = useSubscription(organizationsLoaded)
  const { saveOnExit } = useParams()
  const organizationId =getWorkspaceOrganizationId()
  const plan =  state.subscription?.plan.name
  const [videoSuiteId, setVideoSuiteId] = useState()

  useEffect(() => {
    dispatch({ type: 'SET_VIDEO_SUITE_FEATURE_FLAG', value: responseData })
  }, [])

  useEffect(() => {
    let isMounted = true;
    const getVideoSuiteData = async () => {
      let watermarkData
      if (videoChapter && videoChapter.rawVideoStorageId) {
        try {
          const response = await getVideoSuiteByVideoID(videoChapter.id, previewId, videoChapter?.rawVideoStorageId);
          setVideoSuiteId(response?.id)
          if(response?.watermark){
            watermarkData = JSON.parse(response?.watermark as string);
            dispatch({
              type: 'SET_WATERMARK_DATA',
              value: {
                ...watermarkData,
                opacity: watermarkData.watermarkOpacity,
                cloudinaryId: watermarkData.watermarkCloudinaryId,
                x: watermarkData.x,
                y: watermarkData.y,
              },
            });
          }else{
            setWatermarkOpacity(100);
            setWatermarkImage("");
            setWatermarkCoords(0,0);
          }
          if (isMounted) {
            setVideoSuite(response?.cta || []);
          }
        } catch (error) {
          console.log("Error fetching video suite:", error);
        } finally {
          if (isMounted) {
            setDataLoaded(true);
          }
        }
      }
    };

    getVideoSuiteData();

    return () => {
      isMounted = false;
    };
  }, [videoChapter, previewId]);


  const onSave = async () => {
    if(state.profileImage !== videoChapter?.profileImageStorageId){
      await saveProfileImageStorageId(state.profileImage)
    }
    const watermarkUpdateSuccess = await updateWatermarkData();
    const start = startOffsetTime || startEndOffsetTime[0]?.start ||
      (startOffsetTime === 0 && startEndOffsetTime[0]?.start === 0 ? 0 : videoChapter?.startOffsetTime);
    const end = endOffsetTime < 0 ? 0 : endOffsetTime;
    const success = await trimVideo(start || 0, end || 0);
    if (success && watermarkUpdateSuccess) {
      ReactGA.event({
        category: event.videoTrimmer.category,
        action: event.videoTrimmer.actions.trim,
        label: previewId,
      })
      history.push(videoBlockEditorPath)
    }
    const updatedCallsActionData = videoSuiteData.map((cta: CallsActionData) => {
      let updatedCtaData = { ...cta, newStart: state.videoStartData || 0, newEnd: state.videoEndData };
      if (cta.type === 'button') {
        delete updatedCtaData.textContent;
        delete updatedCtaData.textColor;
        delete updatedCtaData.textDropshadow;
      } else if (cta.type === 'text') {
        delete updatedCtaData.buttonContent;
        delete updatedCtaData.buttonColor;
        delete updatedCtaData.buttonDropshadow;
        delete updatedCtaData.buttonTextColor
      }
      return updatedCtaData;
    });
    const finalData = {
      type: "cta",
      data: JSON.stringify(updatedCallsActionData)
    };
    if (videoChapter && videoChapter.rawVideoStorageId) {
      const response = await getVideoSuiteByVideoID(videoChapter.id, previewId, videoChapter?.rawVideoStorageId);
      if (response) {
        await updateVideoSuite(response.id || '', finalData);
        setVideoSuite([])
      }
    }
    sessionStorage.removeItem("getAllVideoSuiteData");

  };

  const handleExit = () => {
    setShowModal(true);
  };
  const handleNo = async () => {
    setShowModal(false);
    if(saveOnExit) {
      let watermark = defaultWatermarkData.watermarkCloudinaryId
      const { data, ok } = await watermarkService.getWatermarkData(organizationId, videoChapter?.id);
      const response = data as VideoSuite;
      if(response.watermark){
       const parsedWatermark = JSON.parse(response.watermark)
        watermark =  parsedWatermark.watermarkCloudinaryId || defaultWatermarkData.watermarkCloudinaryId
      }

      if(!watermark){
        const finalData = {
          type: "watermark",
          data: JSON.stringify(defaultWatermarkData)
        };
        if (videoChapter && videoChapter.rawVideoStorageId) {
          const response = await getVideoSuiteByVideoID(videoChapter.id, previewId, videoChapter?.rawVideoStorageId);
          if (response) {
            await updateVideoSuite(response.id || '', finalData);
            setVideoSuite([])
          }
        }
      }

    }
    history.push(videoBlockEditorPath);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleYes = async () => {
    setShowModal(false);
    await onSave();
    history.push(videoBlockEditorPath);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setDrawerClass(drawerOpen ? "MuiDrawer-paper show-sidebar" : "MuiDrawer-paper");
  };


  return plan ? (
    <div className="video-trim-editor-page">
      {(currentWorkspace?.type === 'organization') || (currentWorkspace?.type === 'user' && videoChapter?.type === 'audio') ? (
      <VideoTrimSideBar responseData={responseData} drawerClass={drawerClass} plan={plan} currentWorkspaceType={currentWorkspace?.type}/>
        ) : null}
      <ModalConfirmation
        show={showModal}
        onYes={handleYes}
        onNo={handleNo}
        onClose={handleClose}
      >
        <p>Do you want to save your changes before exiting?</p>
      </ModalConfirmation>
      <div className="header">
        <div className="header-content">
          <div className="burger-menu" onClick={toggleDrawer}>
            <MenuIcon />
          </div>
          <div className="btn-container">
            <button className='btn-cancel' onClick={handleExit}>Exit</button>
            <button className='btn-save' onClick={onSave}>Save</button>
          </div>
        </div>
      </div>
      {isLoadingDelayed  ? (
        <LoadingSpinner fullScreen>Downloading your video for editing. This may take a few moments...</LoadingSpinner>
      ) : isTrimming ? (
        <LoadingSpinner fullScreen>Saving your trimmed video. Please wait...</LoadingSpinner>
      ) : videoBlob && !isChapterUpdated ? (
        <div className="content">
          <VideoTrimEditor
            responseData={responseData}
            initialStartOffsetTime={videoChapter?.startOffsetTime || 0}
            initialEndOffsetTime={videoChapter?.endOffsetTime || 0}
            videoBlob={videoBlob}
            onSave={onSave}
            onStartEndTimeParentChange={setStartEndOffsetTime}
            onStartOffsetChange={setStartOffsetTime}
            onEndOffsetChange={setEndOffsetTime}
            plan={plan}
          />
          <div className="bottom-spacer"/>
        </div>
      ) :  <LoadingSpinner fullScreen>Downloading your video for editing. This may take a few moments...</LoadingSpinner>}
    </div>
  ) : <LoadingSpinner fullScreen/>
}

export default VideoTrimEditorPage
