import React from 'react'
import { useHistory } from 'react-router-dom'
import './DuplicateSuccess.scss'

interface Props {
  invitationId?: string
  organisationId?: string
  onClose: () => void
}

const DuplicateSuccess = function ({ invitationId, organisationId, onClose }: Props) {
  const { push } = useHistory()

  const onEditNow = function () {
    if (!invitationId || !organisationId) return
    push(`/invitation/${organisationId}/${invitationId}/edit`)
  }

  return (
    <div className="duplicate-success">
      <h2>Your invitation has been duplicated!</h2>
      <div className="subtitle">Would you like to edit your new invitation now?</div>
      <div className="button-container">
        <button onClick={onClose}>Edit later</button>
        <button className="edit-now secondary" onClick={onEditNow}>
          Edit now
        </button>
      </div>
    </div>
  )
}

export default DuplicateSuccess
