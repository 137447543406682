import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import React, { useEffect, useMemo, useState } from 'react'
import {  PreviewAlgolia } from '../../../state/types'
import { duplicateOrganizationPreview, getPreviewDetails } from '../../../services/previewService'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import DuplicateSuccess from './DuplicateSuccess'
import './PreviewDuplicateModal.scss'
import * as yup from 'yup'
import FormInput from 'components/shared/FormInput/FormInput'
import { useForm } from 'react-hook-form'
import useVideoSuite from '../../../state/useVideoSuite'
import useWatermark from '../../../state/useWatermark'

interface Props {
  isShowing: boolean
  hideModal: () => void
  preview: PreviewAlgolia
  onDuplicateSuccess: (preview?: PreviewAlgolia) => void
}

type FormData = {
  newTitle: string
  newSubTitle: string
}

const validationSchema = yup.object().shape({
  newTitle: yup.string().required('Please enter new primary title'),
})

const PreviewDuplicateModal = function ({ preview, isShowing, hideModal, onDuplicateSuccess }: Props) {
  const { title, subtitle } = useMemo(() => {
    return { title: preview.title, subtitle: preview.subtitle }
  }, [preview])
  const { defaultWatermarkData } = useWatermark()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [newTitle, setNewTitle] = useState<string>('CLONE: ' + title)
  const [newSubtitle, setNewSubtitle] = useState<string | undefined>(subtitle)
  const [newPreview, setNewPreview] = useState<PreviewAlgolia | null>(null)
  const {getVideoSuiteByVideoID,updateVideoSuite,setVideoSuite } = useVideoSuite(true)

  const { handleSubmit, register, errors } = useForm<FormData>({ validationSchema })

  useEffect(() => {
    if (title?.startsWith('CLONE')) setNewTitle(title)
    else setNewTitle('CLONE: ' + title)
    setNewSubtitle(subtitle)
  }, [isShowing])

  const onContinue = handleSubmit(async function () {

    if (!preview) return
    setIsLoading(true)
    const { data, ok } = await duplicateOrganizationPreview(preview.id, newTitle, newSubtitle)

    if (data) {
      // Fetching preview details
      const previewDetailsNew = await getPreviewDetails(data.id);
      const previewDetailsExist = await getPreviewDetails(preview.id);

      if (previewDetailsNew && previewDetailsExist) {
        // Extracting existing video chapters
        const contentBlocksExist = previewDetailsExist?.data?.contentBlocks || [];
        const videoBlockExist = contentBlocksExist.find(cb => cb.contentBlockType?.name === 'Video');
        const videoChaptersExist = videoBlockExist?.contentBlockFields.map(f => ({
          ...f.videoChapter!,
          contentBlockFieldId: f.id,
        })) || [];

        // Extracting new video chapters
        const contentBlocksNew = previewDetailsNew?.data?.contentBlocks || [];
        const videoBlockNew = contentBlocksNew.find(cb => cb.contentBlockType?.name === 'Video');
        const videoChaptersNew = videoBlockNew?.contentBlockFields.map(f => ({
          ...f.videoChapter!,
          contentBlockFieldId: f.id,
        })) || [];

        // Ensure both arrays have the same length to match row-to-row
        if (videoChaptersExist.length === videoChaptersNew.length) {
          for (let i = 0; i < videoChaptersNew.length; i++) {
            const newChapter = videoChaptersNew[i];
            const existChapter = videoChaptersExist[i];

            // Fetch cta data from the existing chapter
            const responseExisting = await getVideoSuiteByVideoID(existChapter?.id, preview.id, existChapter?.rawVideoStorageId ?? '');
            const responseNew = await getVideoSuiteByVideoID(newChapter?.id, data.id, newChapter?.rawVideoStorageId ?? '');

            // Check if responseExisting has valid data and then proceed with the update
            if (responseExisting && responseExisting.cta && responseNew) {
              const finalData = {
                type: "cta",
                data: JSON.stringify(responseExisting.cta)
              };

              let watermarkData = {
                ...defaultWatermarkData
              }

              if(responseExisting?.watermark){
                const parsedWatermark = JSON.parse(responseExisting?.watermark as string)
                watermarkData = {
                  watermarkCloudinaryId: parsedWatermark.watermarkCloudinaryId,
                  watermarkOpacity: parsedWatermark.watermarkOpacity,
                  x: parsedWatermark.x,
                  y: parsedWatermark.y,
                  imageHeight: parsedWatermark.imageHeight,
                  imageWidth: parsedWatermark.imageWidth
                }
              }

              const finalDataWatermark = {
                type: "watermark",
                data: JSON.stringify(watermarkData)
              }

              // Update the corresponding new video chapter with the cta data
              await updateVideoSuite(responseNew?.id, finalData);
              await updateVideoSuite(responseNew?.id || '', finalDataWatermark)
            } else {
              console.log(`No CTA data for Existing Chapter ${i + 1} at position ${i}. Corresponding New Chapter will not be updated.`);
            }
          }
        } else {
          console.error(`Mismatch in the length of video chapters arrays. Existing chapters: ${videoChaptersExist.length}, New chapters: ${videoChaptersNew.length}`);
        }

        setVideoSuite([]);
      }
    }
    setIsLoading(false)
    if (data && ok) {
      const newPreview = {
        ...preview,
        id: data.id,
        title: newTitle,
        subtitle: newSubtitle,
      }
      setNewPreview(newPreview)
      onDuplicateSuccess(newPreview)
    }
    setIsSuccess(true)
  })

  const onClose = function () {
    hideModal()
    setIsSuccess(false)
  }

  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      {isSuccess && newPreview ? (
        <DuplicateSuccess previewId={newPreview.id} organisationId={preview.organizationId} onClose={onClose} />
      ) : (
        <div className="duplicate-preview-modal">
          <h2>Duplicate Preview: {title}</h2>
          <form>
            <span>Rename your Preview: </span>
            <FormInput
              name="newTitle"
              label="Primary title:"
              inputRef={register}
              type="text"
              errors={errors}
              defaultValue={newTitle}
              onChange={e => {
                setNewTitle(e.target.value)
              }}
            />
            <FormInput
              name="newSubTitle"
              label="Subtitle:"
              inputRef={register}
              type="text"
              errors={errors}
              defaultValue={newSubtitle}
              onChange={e => {
                setNewSubtitle(e.target.value)
              }}
            />
            <LoadingButton isLoading={isLoading} className="primary" onClick={onContinue}>
              Create
            </LoadingButton>
          </form>
        </div>
      )}
    </CloseableDialog>
  )
}

export default PreviewDuplicateModal
