import React, { useState, useRef, useEffect } from 'react'
import { VideoUploadHandle } from 'state/types'
import {
  checkCameraAndMicrophonePermissions,
  getCameraAndMicrophoneStream,
  isMediaRecorderSupported,
  formatVideoTime,
  videoBitsPerSecond,
} from 'helpers/media'
import { event } from 'helpers/analytics'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import VideoLayoutContainer from 'components/video/VideoLayoutContainer/VideoLayoutContainer'
import VideoSettingsMenuButton from 'components/video/VideoSettingsMenuButton/VideoSettingsMenuButton'
import PlayPauseButton from 'components/video/PlayPauseButton/PlayPauseButton'
import RecordButton from 'components/video/RecordButton/RecordButton'
import './VideoRecorder.scss'
import { generateImage } from '../../../services/openGraphService'
import CircularProgressBar from './../../shared/CircularProgressBar/CircularProgressBar'
import { useHistory, useParams } from 'react-router-dom'
import useStore from '../../../state/useStore'

let chunks: Array<BlobPart> | null = null
let recorder: MediaRecorder | null = null
let backupVolume: number | null

const recordingOptions = { videoBitsPerSecond }

type Props = {
  uploadVideo: (video: Blob, onProgressChange: (progress: number) => void) => VideoUploadHandle
  onUploadSuccess: (storageId: string) => void
  onRecordingChange: (recording: boolean) => void
  triggerGAEvent?: (category: string, action: string) => void
  linkDirection:string
}


const VideoRecorder = ({ uploadVideo, onUploadSuccess, onRecordingChange, triggerGAEvent,linkDirection }: Props) => {
  const history = useHistory();
  const { dispatch } = useStore()
  const [canRecord, setCanRecord] = useState(isMediaRecorderSupported)
  const [isCheckingPermissions, setCheckingPermissions] = useState(true)
  const [isRequestingPermissions, setRequestingPermissions] = useState(false)
  const [isPermissionGranted, setPermissionGranted] = useState(false)
  const [stream, setStream] = useState<MediaStream | null>(null)
  const [isPlaying, setPlaying] = useState(false)
  const [isRecording, setRecording] = useState(false)
  const [justFinishedRecording, setJustFinishedRecording] = useState(false)
  const [recordingTime, setRecordingTime] = useState(0)
  const [recordedVideoBlob, setRecordedVideoBlob] = useState<Blob | null>(null)
  const [recordedVideoUrl, setRecordedVideoUrl] = useState<string | null>(null)
  const [isUploadingVideo, setUploadingVideo] = useState(false)
  const [isUploadingVideoCircular, setUploadingVideoCircular] = useState(false)
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0)
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState<string | null>(null)
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState<string | null>(null)
  const [showSavedText, setShowSavedText] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null)
  const recordingTimerRef = useRef(0)
  const abortUploadRef = useRef<any>(null)
  const streamRef = useRef<any>(null)
  const mountedRef = useRef(true)

  streamRef.current = stream // Used for cleanup when component unmounts.

  const checkIfVideoPlaying = () => {
    const video = videoRef.current
    return video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2
  }

  const checkPermissions = async () => {
    setCheckingPermissions(true)
    const hasPermissions = await checkCameraAndMicrophonePermissions()
    if (hasPermissions) {
      setPermissionGranted(true)
      if (selectedVideoDeviceId && selectedAudioDeviceId) {
        await getRecordingStream(selectedVideoDeviceId, selectedAudioDeviceId)
      }
    }
    setCheckingPermissions(false)
  }

  const requestPermissions = async () => {
    setRequestingPermissions(true)
    const permissionGranted = await getRecordingStream(
      selectedVideoDeviceId || undefined,
      selectedAudioDeviceId || undefined
    )
    if (permissionGranted) {
      setPermissionGranted(true)
    }
    setRequestingPermissions(false)
  }

  const getRecordingStream = async (videoDeviceId?: string, audioDeviceId?: string) => {
    const stream = await getCameraAndMicrophoneStream(videoDeviceId, audioDeviceId)
    if (stream) {
      const streamAny: any = stream
      streamAny.oninactive = () => {
        if (mountedRef.current) {
          // It can happen that the stream is cleaned up while the component is still mounted, e.g. if the
          // user spends some time browsing in other tabs. In this case we reset everything.
          setStream(null)
          setRecording(false)
        }
      }

      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        dispatch({type:"SET_VIDEO_DIMENSION",
          value:
            {width: videoTrack.getSettings().width,
            height: videoTrack.getSettings().height }})
      }

      setStream(stream)
      setPlaying(true)
      return true
    } else {
      setCanRecord(false)
      return false
    }
  }

  const toggleRecording = () => {
    if (isRecording) {
      // Prevent some buttons etc from flashing visible briefly after recording ends.
      setJustFinishedRecording(true)
      setTimeout(() => setJustFinishedRecording(false), 500)
    }

    setRecording(!isRecording)
    onRecordingChange(!isRecording)
  }

  const startRecording = () => {
    if (stream) {
      setRecordingTime(0)
      const startTime = Date.now()
      recordingTimerRef.current = setInterval(() => setRecordingTime(Date.now() - startTime), 100) as any

      chunks = []
      recorder = new MediaRecorder(stream, recordingOptions)
      recorder.addEventListener('dataavailable', onRecordingReady)
      recorder.addEventListener('stop', onRecordingFinished)
      recorder.start()
    }
  }

  const onRecordingReady = (e: any) => chunks && chunks.push(e.data)

  const onRecordingFinished = () => {
    clearInterval(recordingTimerRef.current)

    const video = videoRef.current
    if (video && chunks) {
      const recordedVideoBlob = new Blob(chunks)
      const recordedVideoUrl = URL.createObjectURL(recordedVideoBlob)
      chunks = null
      setPlaying(false)
      setRecordedVideoBlob(recordedVideoBlob)
      setRecordedVideoUrl(recordedVideoUrl)
    }
  }

  const cancelUpload = () => {
    const abortUpload = abortUploadRef.current
    if (abortUpload) {
      abortUpload()
      triggerGAEvent && triggerGAEvent(event.videoRecorder.category, event.videoRecorder.actions.cancelSave)
    }
  }

  const discardRecordedVideo = () => {
    const video = videoRef.current
    if (video && checkIfVideoPlaying()) {
      video.pause()
    }
    if (recordedVideoUrl) {
      URL.revokeObjectURL(recordedVideoUrl)
    }
    setUploadingVideo(false)
    setRecordedVideoBlob(null)
    setRecordedVideoUrl(null)
    setPlaying(true)
    setShowSavedText(false);
    triggerGAEvent && triggerGAEvent(event.videoRecorder.category, event.videoRecorder.actions.discard)
  }

  const onSaveButtonClick = () => {
    if (!isUploadingVideo) {
      doUpload()
        .then(generateImage)
        .then(() => {
          setShowSavedText(true);
        })
        .catch(console.error);
    } else {
      cancelUpload();
    }
  };

  const toggleVideoPlay = () => {
    setPlaying(!isPlaying)
    if (!isPlaying) {
      triggerGAEvent && triggerGAEvent(event.videoRecorder.category, event.videoRecorder.actions.playRecordedVideo)
    }
  }

  const doUpload = async () => {
    if (recordedVideoBlob) {

      if (showSavedText === false) {
        setUploadingVideo(true);
      }

      try {
        const { response, abort } = uploadVideo(recordedVideoBlob, setUploadVideoProgress);

        abortUploadRef.current = abort;
        const { status, storageId } = await response;
        abortUploadRef.current = null;
        setUploadingVideo(true);
        setUploadingVideoCircular(true);
        setShowSavedText(true);

        if (status === 'success' && storageId) {
          setTimeout(() => {
            onUploadSuccess(storageId);
            history.push(linkDirection);
          }, 700);
        } else {
          setUploadVideoProgress(0);
          setUploadingVideo(false);
          setShowSavedText(false);
        }
      } catch (e) {}
    }
  }


  const onUnmount = () => {
    const recordingTimer = recordingTimerRef.current
    if (recordingTimer) {
      clearInterval(recordingTimer)
    }

    cleanUpStream()

    mountedRef.current = false
  }

  const cleanUpStream = () => {
    const video = videoRef.current;
    if (video && video.src) {
      if (checkIfVideoPlaying()) {
        video.pause();
      }

      URL.revokeObjectURL(video.src);
    }

    const streamToCleanUp = streamRef.current as MediaStream;
    if (streamToCleanUp) {
      streamToCleanUp.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    }
  }

  const isCancelDisabled = isUploadingVideo && uploadVideoProgress >= 100

  if (isRecording) {
    document.body.classList.add('recording')
  } else {
    document.body.classList.remove('recording')
  }

  useEffect(() => {
    // Check permissions silently when component mounts and show video stream if permissions exist:
    checkPermissions()
    return onUnmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    cleanUpStream()
    setPlaying(false)
    setStream(null)

    // Update recording stream whenever selected devices change:
    if (isPermissionGranted && selectedVideoDeviceId && selectedAudioDeviceId) {
      getRecordingStream(selectedVideoDeviceId, selectedAudioDeviceId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoDeviceId, selectedAudioDeviceId, isPermissionGranted])

  // Some video attributes (e.g. srcObject, playing & pausing) have to be set imperatively:
  useEffect(() => {
    const video = videoRef.current
    if (video) {
      const isVideoElementPlaying = checkIfVideoPlaying()
      if (!isPlaying && isVideoElementPlaying) {
        video.pause()
      }

      if (!recordedVideoBlob && stream && stream !== video.srcObject) {
        backupVolume = video.volume
        video.volume = 0 // Mute while streaming microphone or we'll get feedback.
        video.srcObject = stream
      } else if (video.srcObject && (recordedVideoBlob || !stream)) {
        video.srcObject = null
        video.volume = backupVolume || 0
      }

      if (isPlaying && !isVideoElementPlaying) {
        video.play()
      }

      if (isRecording && !recorder) {
        startRecording()
      } else if (!isRecording && recorder) {
        recorder.stop()
        recorder = null
      }
    }
  })

  useEffect(() => {
    const video = videoRef.current as any
    if (video) {

      video.disablePictureInPicture = true
    }
  }, [recordedVideoUrl])

  return (
    <>

    <div className="video-recorder">
      <div className="video-with-toolbar">
        <VideoLayoutContainer>
          {!isCheckingPermissions && !isPermissionGranted && !recordedVideoBlob ? (
            canRecord ? (
              <div className="request-permissions-container">
                <div className="text">PreviewMe needs your permission to record audio and video.</div>
                <LoadingButton
                  type="button"
                  className="request-permissions-button primary"
                  onClick={requestPermissions}
                  isLoading={isRequestingPermissions}
                >
                  Allow
                </LoadingButton>
              </div>
            ) : (
              <div className="device-error-message">
                {isMediaRecorderSupported
                  ? "Sorry, we can't find your camera and microphone.\n\n If you do have a camera and microphone " +
                    "installed, please check your browser's camera permissions."
                  : 'Sorry, your browser does not support video recording. We recommend Chrome or Firefox.'}
              </div>
            )
          ) : !isCheckingPermissions ? (
            <video
              ref={videoRef}
              controlsList="nodownload"
              src={recordedVideoUrl || undefined}
              onEnded={() => setPlaying(false)}
              onPause={() => setPlaying(false)}
            />
          ) : null}
        </VideoLayoutContainer>
        <div className="video-toolbar">
          <div className="left-buttons" />
          {!recordedVideoBlob && stream ? (
            <RecordButton disabled={!isPermissionGranted} recording={isRecording} onClick={toggleRecording} />
          ) : recordedVideoBlob ? (
            <PlayPauseButton isPlaying={isPlaying} togglePlaying={toggleVideoPlay} />
          ) : null}
          <div className="right-buttons">
            <VideoSettingsMenuButton
              hasPermission={isPermissionGranted}
              selectedVideoDeviceId={selectedVideoDeviceId}
              selectedAudioDeviceId={selectedAudioDeviceId}
              onSelectedVideoDeviceChange={setSelectedVideoDeviceId}
              onSelectedAudioDeviceChange={setSelectedAudioDeviceId}
              style={{ display: !recordedVideoBlob && !isRecording && !justFinishedRecording ? 'flex' : 'none' }}
            />

            {(recordedVideoBlob || isRecording) && (
              <div className="video-duration">{formatVideoTime(recordingTime / 1000)}</div>
            )}
          </div>
        </div>
      </div>
      <div className="recorded-video-buttons" style={{ visibility: recordedVideoBlob ? 'visible' : 'hidden' }}>
        {isUploadingVideo && !isUploadingVideoCircular ? (
          <>
            <CircularProgressBar progress={uploadVideoProgress} />
          </>
        ) : null}

        {showSavedText ? (
          <>
            <div className="saved-message">Saved</div>
          </>
        ) : null}

        {!isUploadingVideo && (
          <>
            <button type="button" className="link" onClick={discardRecordedVideo}>
              Discard video
            </button>
            <LoadingButton
              type="button"
              className={'save-button primary' + (isCancelDisabled ? ' disabled' : '')}
              disabled={isCancelDisabled}
              onClick={onSaveButtonClick}
            >
              {isUploadingVideo ? 'Cancel' : 'Next'}
            </LoadingButton>
          </>
        )}
      </div>
    </div>
      </>
  )
}

export default VideoRecorder
