import { useState } from 'react'
import { Rect } from 'state/types'

const cardMargin = 15
const animationDelay = 100
const animationDuration = 500

export const totalCardAnimationDuration = animationDelay + animationDuration

// This assumes the cards are all the same width and height, so don't activate it on mobile screen sizes.
export const useCardAnimation = () => {
  const [isAnimating, setAnimating] = useState(false)
  const [cardPositions, setCardPositions] = useState<Record<string, Rect> | undefined>()
  const [buttonPosition, setButtonPosition] = useState<Rect | undefined>()

  const animateReorder = (fieldIds: string[], newFieldIds: string[]) => {
    const parentBounds = document.getElementById('video-chapter-card-0')?.parentElement?.getBoundingClientRect()
    const currentPositions = fieldIds.reduce((result, fieldId, i) => {
      const cardBounds = document.getElementById('video-chapter-card-' + i)?.getBoundingClientRect()
      if (cardBounds && parentBounds) {
        result[fieldId] = {
          x: cardBounds.left - parentBounds.left - cardMargin,
          y: cardBounds.top - parentBounds.top - cardMargin,
          width: cardBounds.width,
          height: cardBounds.height,
        }
      }
      return result
    }, {} as Record<string, Rect>)

    const buttonBounds = document.getElementById('add-field-button')?.getBoundingClientRect()
    if (buttonBounds && parentBounds) {
      setButtonPosition({
        x: buttonBounds.left - parentBounds.left - cardMargin,
        y: buttonBounds.top - parentBounds.top - cardMargin,
        width: buttonBounds.width,
        height: buttonBounds.height,
      })
    }

    const newPositions = newFieldIds.reduce((result, fieldId, i) => {
      result[fieldId] = currentPositions[fieldIds[i]]
      return result
    }, {} as Record<string, Rect>)

    setCardPositions(currentPositions)
    setTimeout(() => {
      setAnimating(true)
      setCardPositions(newPositions)
      setTimeout(() => {
        setAnimating(false)
        setButtonPosition(undefined)
        setCardPositions(undefined)
      }, animationDuration)
    }, animationDelay)
  }

  return { isAnimating, cardPositions, buttonPosition, animateReorder }
}

export const getAbsoluteStyle = (absolutePosition?: Rect): React.CSSProperties | undefined =>
  absolutePosition
    ? {
        position: 'absolute',
        transform: `translate(${absolutePosition.x}px, ${absolutePosition.y}px)`,
        width: absolutePosition.width + 'px',
        height: absolutePosition.height + 'px',
      }
    : undefined
