import React from 'react'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import './EditInvitationVideoChapterDialog.scss'
import { Option, VideoCategoryTemplate } from '../../../state/types'
import CreateCustomVideoChapter from '../CreateCustomVideoChapter/CreateCustomVideoChapter'

type Props = {
  index: number
  isShowing: boolean
  onClose: () => void
  options: Option[]
  addedVideoChapter: VideoCategoryTemplate
  addedVideoChapters: VideoCategoryTemplate[]
  setAddedVideoChapters: (value: VideoCategoryTemplate[]) => void
}

export const EditInvitationVideoChapterDialog: React.FC<Props> = ({
  index,
  isShowing,
  onClose,
  options,
  addedVideoChapters,
  setAddedVideoChapters,
}) => {
  const onFormSubmit = async ({
    selectedFormat,
    selectedOption,
    formatDisplay,
    otherField,
    description,
  }: {
    selectedOption: string
    otherField: string
    selectedFormat: string
    formatDisplay: string
    description: string
  }) => {
    const tempAddedVideoChapters = addedVideoChapters.slice()
    tempAddedVideoChapters[index].videoCategoryId = selectedOption
    tempAddedVideoChapters[index].videoCategoryIdTitle =
      selectedOption === 'other'
        ? otherField
        : options.filter(option => option.value === selectedOption)[0].displayValue
    tempAddedVideoChapters[index].helperText = description
    tempAddedVideoChapters[index].format = selectedFormat
    tempAddedVideoChapters[index].formatDisplay = formatDisplay
    setAddedVideoChapters(tempAddedVideoChapters)
    onClose()
  }

  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      <div className="edit-invitation-video-chapter">
        <h2>Edit Video Chapter</h2>
        <CreateCustomVideoChapter
          options={options}
          buttonText="Edit"
          onFormSubmit={onFormSubmit}
          initialOption={addedVideoChapters[index].videoCategoryId}
          initialField={
            addedVideoChapters[index].videoCategoryId === 'other' ? addedVideoChapters[index].videoCategoryIdTitle : ''
          }
          initialDescription={addedVideoChapters[index].helperText}
          initialFormat={addedVideoChapters[index].format}
        />
      </div>
    </CloseableDialog>
  )
}
