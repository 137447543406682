import React from 'react'
import './DateSelector.scss'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import { customTheme } from './MuiTheme'
import { formatISO } from 'date-fns'
import { IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import Instructions from '../../shared/Instructions/Instructions'

type Props = {
  value?: string
  label?: string
  instructions?: string | undefined
  disableFuture?: boolean
  onChange?: (value?: string) => void
}

const DateSelector: React.FC<Props> = ({ value, label, instructions, disableFuture, onChange }) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(value ? new Date(value) : null)

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
    onChange?.(date ? formatISO(date) : '')
  }

  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation()
    handleDateChange(null)
  }

  return (
    <div className="form-input-container">
      {label && (
        <div className="top-label-row">
          {label && <label>{label}</label>}
          {instructions && <Instructions instructions={instructions} />}
        </div>
      )}
      <div className="input-container ">
        <ThemeProvider theme={customTheme}>
          <div className="date-selector">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className="picker"
                format="dd/MM/yyyy"
                value={selectedDate}
                disableFuture={disableFuture}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
            <IconButton className="clear-icon" onClick={e => handleClear(e)}>
              <ClearIcon />
            </IconButton>
          </div>
        </ThemeProvider>
      </div>
    </div>
  )
}

export default DateSelector
