import React, { useState } from 'react'
import InfoIcon from '@material-ui/icons/Info'
import InstructionDialog from '../InstructionDialog/InstructionDialog'
import './Instructions.scss'

type Props = {
  instructions: string
}

const Instructions: React.FC<Props> = ({ instructions }) => {
  const [isInstructionDialogShowing, setInstructionDialogShowing] = useState(false)

  return (
    <>
      <InfoIcon className="instruction-info-icon" onClick={() => setInstructionDialogShowing(true)} />
      <InstructionDialog
        instructions={instructions}
        isShowing={isInstructionDialogShowing}
        onClose={() => setInstructionDialogShowing(false)}
      />
    </>
  )
}

export default Instructions
