import React from 'react'
import './SuccessInformation.scss'
import CopyableLink from '../../shared/CopyableLink/CopyableLink'
import CopyableQR from '../../shared/CopyableQR/CopyableQR'
import PreviewSharingLinks from '../../preview/PreviewSharingLinks/PreviewSharingLinks'
import BackLink from '../../shared/BackLink/BackLink'
import { InvitationSuccessPageInfo, Preview } from '../../../state/types'
import { getBusinessLogoImageUrl } from '../../../helpers/cloudinary'

type Props = {
  invitationSuccessPage?: InvitationSuccessPageInfo
  invitationOrgName?: string
  previewId: string
  preview?: Preview
  hideBackToPreviews?: boolean
}

export const SuccessInformation: React.FC<Props> = ({
  invitationSuccessPage,
  invitationOrgName,
  previewId,
  preview,
  hideBackToPreviews,
}) => {
  const onReturnLinkClick = () => {
    if (!(invitationSuccessPage && invitationSuccessPage.returnLink)) {
      return
    }

    if (
      invitationSuccessPage.returnLink.startsWith('https://') ||
      invitationSuccessPage.returnLink.startsWith('http://')
    ) {
      window.open(invitationSuccessPage.returnLink, '_blank')
    } else {
      window.open('https://' + invitationSuccessPage.returnLink, '_blank')
    }
  }

  return (
    <>
      <div className={'title-and-logo'}>
        {preview?.invitationSuccessPage?.logo && (
          <div className={'logo'}>
            <img className={'business-logo-image'} src={getBusinessLogoImageUrl(preview.invitationSuccessPage.logo)} />
          </div>
        )}
        <h1>{preview?.invitationSuccessPage?.title ? preview?.invitationSuccessPage?.title : 'Success!'}</h1>
      </div>
      {invitationSuccessPage && invitationSuccessPage.title ? (
        <div className={'invitation-success-page'}>
          <div className={'message'}>{invitationSuccessPage.message}</div>
          {!invitationSuccessPage.returnLink ? (
            <b>{invitationSuccessPage.returnLinkTitle}</b>
          ) : (
            <div className={'return-link'} onClick={onReturnLinkClick}>
              {invitationSuccessPage.returnLinkTitle
                ? invitationSuccessPage.returnLinkTitle
                : invitationSuccessPage.returnLink}
            </div>
          )}
          <p>A copy of your Preview is also available from the link below:</p>
        </div>
      ) : invitationOrgName ? (
        <>
          <p>You're all done. {invitationOrgName} has received your Preview.</p>
          <p>A copy of your Preview is also available from the link below:</p>
        </>
      ) : (
        <p>Your Preview is published. Share it with this link:</p>
      )}
      <CopyableLink to={`/p/${previewId}`} />
      <p>Or</p>
      <CopyableQR to={`/p/${previewId}`} />
      <p>Or share via:</p>
      <PreviewSharingLinks previewId={previewId} />
      {!hideBackToPreviews && (
        <BackLink className="home-link" to="/">
          Back to Previews
        </BackLink>
      )}
    </>
  )
}
