import { getWorkspaceOrganizationId } from 'helpers/organization'
import { forceRefreshSession } from 'services/authService'
import { User } from 'state/types'
import { getApiWithAuthorization } from './api'

export const matchUser = async () => {
  const apiWithAuth = await getApiWithAuthorization()
  const { ok, data } = await apiWithAuth.get<{ tokenUpdated: boolean; socialAuth: boolean; user: User }>(
    '/api/user/match'
  )
  if (ok && data?.tokenUpdated) {
    // This ensures we get a new ID token with the latest user attributes in it.
    await forceRefreshSession()
  }
  if (ok && data?.user) {
    return { ...data.user, socialAuth: data.socialAuth }
  }
}

export const completeUserOnboarding = async (user: Partial<User>) => {
  const apiWithAuth = await getApiWithAuthorization()
  const { ok } = await apiWithAuth.patch('/api/user/onboard', user)
  return ok
}

export const acknowledgeDowngrade = async () => {
  const apiWithAuth = await getApiWithAuthorization()
  const { ok } = await apiWithAuth.patch('/api/user/downgradeAcknowledged')
  return ok
}

export const updateUser = async (user: User) => {
  const apiWithAuth = await getApiWithAuthorization()
  const { ok } = await apiWithAuth.patch('/api/user/update', user)
  return ok
}

// Change password for logged-in user.
export const changePassword = async (oldPassword: string, newPassword: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const { status } = await apiWithAuth.patch('/api/user/changePassword', { oldPassword, newPassword })
  return status
}

export const deleteAccount = async (userId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.delete(
    `/api/user/deleteAccount/${userId}` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

// Just used to refresh user state, e.g. after after some updating user in DB via Stripe webhooks.
export const loadUserData = async () => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<{ user: User }>('/api/user/match')
}
