import React, { useRef, useEffect, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useEscapeKeyPress } from 'helpers/useDocumentKeyPress'
import DialogPortal from 'components/shared/DialogPortal/DialogPortal'
import './Dialog.scss'

type Props = {
  isShowing: boolean
  className: string
  closeOnEscapePress?: boolean
  scrollTopOnOpen?: boolean
  onClose: () => void
  disableExitOnBackgroundClick?: boolean
}

const Dialog: React.FC<Props> = ({ isShowing, className, closeOnEscapePress, scrollTopOnOpen, children, onClose, disableExitOnBackgroundClick }) => {
  const scrollYRef = useRef(0)
  const fadingInRef = useRef(false)
  const mountedRef = useRef(true)

  const preventBodyScroll = useCallback(() => {
    scrollYRef.current = scrollTopOnOpen ? 0 : window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollYRef.current}px`
    document.body.classList.add('dialog-showing')
  }, [scrollTopOnOpen])

  const releaseBodyScroll = () => {
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.classList.remove('dialog-showing')
  }

  const onBackgroundClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(disableExitOnBackgroundClick) return
    if (!fadingInRef.current) {
      e.preventDefault()
      e.stopPropagation()
      onClose()
    }
  }

  useEscapeKeyPress(
    useCallback(() => {
      if (!fadingInRef.current && closeOnEscapePress) {
        onClose()
      }
    }, [closeOnEscapePress, onClose])
  )

  useEffect(() => {
    if (isShowing) {
      fadingInRef.current = true
      setTimeout(() => (fadingInRef.current = false), 250)
      preventBodyScroll()
    } else if (mountedRef.current) {
      releaseBodyScroll()
      window.scrollTo(0, scrollYRef.current)
    }
  }, [isShowing, preventBodyScroll])

  useEffect(
    () => () => {
      mountedRef.current = false
      releaseBodyScroll()
    },
    []
  )

  return (
    <CSSTransition timeout={250} in={isShowing} unmountOnExit>
      <DialogPortal>
        <div className={'dialog-container' + (className ? ' ' + className : '')}>
          <div className="dialog-background" onClick={onBackgroundClick} />
          <div className="dialog">
            <div className="dialog-scrollable-content">{children}</div>
          </div>
        </div>
      </DialogPortal>
    </CSSTransition>
  )
}

export default Dialog
