import React from 'react'
import { useHistory } from 'react-router-dom'
import ExamplePage from 'components/preview/ExamplePage/ExamplePage'

const InvitationExamplePage = () => {
  const { push } = useHistory()

  const createPreview = async (id: string) => {
    push(`/invitations/template/${id}`)
  }

  return (
    <ExamplePage
      cancelLink="/invitations"
      prevLink="/invitations/create"
      createPreviewPage={createPreview}
      isInvitation={true}
      titleText="This is an example of what you can receive"
    />
  )
}

export default InvitationExamplePage
