import { cloudinaryFolder, cloudinaryImageUploadPreset, cloudinaryVideoUploadPreset } from 'helpers/config'
import { CloudinaryUploadResult, VideoChapter, VideoUploadHandle } from 'state/types'

const cloudName = 'innovation-capital'

const uploadChunk = function (
  chunk: Blob,
  url: string,
  xhr: XMLHttpRequest,
  xUniqueUploadId: string,
  start: number,
  end: number,
  size: number,
  uploadPreset: string
) {
  return new Promise<CloudinaryUploadResult>(resolve => {
    xhr.open('POST', url, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.setRequestHeader('X-Unique-Upload-Id', xUniqueUploadId)
    xhr.setRequestHeader('Content-Range', 'bytes ' + start + '-' + end + '/' + size)
    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText)
        if (response.hasOwnProperty('done') && !response.done) {
          resolve({ status: 'success' })
        } else {
          const storageId = response.public_id.replace(cloudinaryFolder + '/', '')
          resolve({ status: 'success', storageId })
        }
      } else {
        resolve({ status: 'error' })
      }
    })
    xhr.addEventListener('error', () => resolve({ status: 'error' }))
    xhr.addEventListener('abort', () => resolve({ status: 'aborted' }))

    const formData = new FormData()
    formData.append('upload_preset', uploadPreset)
    formData.append('file', chunk)
    xhr.send(formData)
  })
}

export const upload = (
  file: Blob,
  isImage: boolean,
  onProgressChange?: (progress: number) => void
): VideoUploadHandle => {
  const chunkSize = 20000000
  const numberOfChunks = Math.ceil(file.size / chunkSize)

  const xhrArray: XMLHttpRequest[] = []
  for (let i = 0; i < numberOfChunks; i++) {
    xhrArray.push(new XMLHttpRequest())
  }

  if (onProgressChange) {
    onProgressChange(0)
    for (let i = 0; i < numberOfChunks; i++) {
      const gradient = i === numberOfChunks - 1 ? file.size % chunkSize : chunkSize
      const constant = i * chunkSize
      xhrArray[i].upload.addEventListener('progress', e =>
        onProgressChange(Math.round((((e.loaded * gradient) / e.total + constant) * 100) / file.size))
      )
    }
  }

  const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
  const xUniqueUploadId = (+new Date()).toString()
  const uploadPreset = isImage ? cloudinaryImageUploadPreset : cloudinaryVideoUploadPreset
  let start = -chunkSize
  let end = 0
  let resultPromise: Promise<CloudinaryUploadResult> = Promise.resolve({ status: 'success' })
  for (let i = 0; i < numberOfChunks; i++) {
    resultPromise = resultPromise.then(status => {
      if (status.status !== 'success') return Promise.resolve({ status: status.status })
      start += chunkSize
      end = Math.min(end + chunkSize, file.size)
      const chunk = file.slice(start, end)
      return uploadChunk(chunk, url, xhrArray[i], xUniqueUploadId, start, end - 1, file.size, uploadPreset)
    })
  }

  return {
    response: resultPromise,
    abort: () =>
      xhrArray.forEach(xhr => {
        xhr.abort()
      }),
  }
}

export const fetchVideoBlob = async (videoChapter: VideoChapter) => {
  try {
    const response: any = await fetch(videoChapter.originalMp4Url!)
    if (response.status === 200) {
      return (await response.blob()) as Blob
    }
  } catch (e) {
    return null
  }
}

export const getProfileImageUrl = (id: string) => {
  // Uses some Cloudinary smarts to fill to face. Request at 240x240 to make images look sharper on retina.
  return `https://res.cloudinary.com/${cloudName}/image/upload/c_fill,g_face,h_240,q_60,r_4,w_240/${cloudinaryFolder}/${id}.png`
}

export const getBusinessLogoImageUrl = (id: string) => {
  // Uses some Cloudinary smarts to get a Thumbnail picture
  return `https://res.cloudinary.com/${cloudName}/image/upload/c_scale,fl_progressive.progressive:semi,w_155/${cloudinaryFolder}/${id}.png`
}

export const getWatermarkImage = (id:string) => {
  return `https://res.cloudinary.com/${cloudName}/image/upload/${cloudinaryFolder}/${id}.png`
}

export const getBusinessLogoFaviconImageUrl = (id: string) => {
  if(!id) {
    return `/favicon.png`
  } else {
    return `https://res.cloudinary.com/${cloudName}/image/upload/c_scale,fl_progressive.progressive:semi,w_155/${cloudinaryFolder}/${id}.png`
  }
  // Uses some Cloudinary smarts to get a Thumbnail picture
}