import React from 'react'

const TickIcon = () => (
  <svg className="icon" width="24px" height="24px" viewBox="0 0 24 24">
    <g strokeWidth="1" fill="none" fillRule="evenodd">
      <g strokeWidth="1.824">
        <polyline
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="8.30769231 12.8798077 11.7007212 16.0384615 16.1736779 7.38461538"
        ></polyline>
      </g>
    </g>
  </svg>
)

export default TickIcon
