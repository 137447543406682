import { getSearch, getUrlParam, useUrlParams } from 'helpers/urlParams'
import React from 'react'
import { useHistory } from 'react-router-dom'
import './ChangePlanFeedbackPage.scss'
import * as previewService from 'services/previewService'
import { PreviewMeAction } from 'helpers/enum'

type Props = {
  title: string
  className?: string
}

const ChangePlanFeedbackPage: React.FC<Props> = ({ title, className, children }) => {
  const { from } = useUrlParams('from')
  const { push } = useHistory()

  const returnUser = async () => {
    const search = getSearch(from)
    const templateId = getUrlParam(search, 'templateId')
    const previewId = getUrlParam(search, 'previewId')
    const action = getUrlParam(search, 'action')

    if (from.includes('limitReached')) {
      if (action === PreviewMeAction.Create) {
        const { ok, data } = await previewService.createPreview(templateId!)
        if (ok && data) {
          push(`/edit/${data}`)
        }
      } else if (action === PreviewMeAction.Publish) {
        push(`/edit/${previewId}/publish`)
      }
    } else {
      push(from)
    }
  }

  return (
    <div className={'change-plan-feedback-page' + (className ? ' ' + className : '')}>
      <header>
        <div className="header-content">
          <h1>{title}</h1>
          <div className="illustration" />
        </div>
      </header>
      <div className="page-content">
        <div className="body-text">{children}</div>
        <button className="home-link button primary" onClick={returnUser}>
          Return
        </button>
      </div>
    </div>
  )
}

export default ChangePlanFeedbackPage
