import React from 'react';
import { Helmet } from 'react-helmet';
import './G2ReviewComponent.scss'

const G2ReviewComponent = () => {

    return (
      <div>
        <Helmet>
          <script type="application/javascript">
            {`(function(w,d,s,u,o,e,f) { w['G2WriteAReview'] = o;w[o] = w[o] || function() {(w[o].q = w[o].q || []).push(arguments)}; e = d.createElement(s); f = d.getElementsByTagName(s)[0]; e.async = 1;e.src = u; e.defer = 1;f.parentNode.insertBefore(e, f) })(window,document,'script','https://www.g2.com/assets/write_a_review_entry.js','g2'); g2('write-a-review', { productSlug: 'previewme', host: "https://www.g2.com", element: document.getElementById('g2-write-a-review-container'), buttonText: "", buttonColor: "#3898ec", buttonHoverColor: "#3b2569", buttonTextColor: "#fff" });
            `}
          </script>
        </Helmet>
        <div id='g2-write-a-review-container'></div>
      </div>
    );
}

export default G2ReviewComponent;
