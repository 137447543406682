import MultiSelect from 'components/shared/MultiSelect/MultiSelect'
import { isMobile } from 'helpers/browser'
import { parseMultiSelect } from 'helpers/validation'
import React, { useEffect, useState } from 'react'
import { Option } from 'state/types'
import Instructions from '../Instructions/Instructions'

type Props = {
  name?: string
  label?: string
  instructions?: string | undefined
  placeholder?: string
  error?: string
  errors?: any
  defaultValue?: string
  value?: string
  options?: Option[]
  onChange?: (value: string) => void
}

const FormMultiSelect: React.FC<Props> = ({
  name,
  label,
  instructions,
  placeholder,
  error,
  errors,
  value,
  defaultValue,
  options,
  onChange,
}) => {
  const inputId = 'input' + (name ? '-' + name : '')
  const errorToShow = error || (name && errors?.[name]?.message)

  const selectedValues = parseMultiSelect(value)

  useEffect(() => {
    if (defaultValue && !value) {
      onChange?.(defaultValue)
    }
  })

  const handleChange = (newSelectedValues: string[]) => onChange?.(JSON.stringify(newSelectedValues))

  return (
    <div className="form-input-container">
      <div className="form-input-label-row">
        {label && (
          <label className="form-input-label" htmlFor={inputId}>
            {label}
          </label>
        )}
        {instructions && <Instructions instructions={instructions} />}
        {errorToShow && <div className="form-input-validation-error">{errorToShow}</div>}
      </div>
      <MultiSelect
        placeholder={placeholder}
        selectedValues={selectedValues}
        options={options}
        searchable={!isMobile && options && options.length > 10}
        onChange={handleChange}
      />
    </div>
  )
}

export default FormMultiSelect
