import React from 'react'
import './WorkspaceInfo.scss'
import ProfileImage from '../ProfileImage/ProfileImage'
import { Tooltip } from '@material-ui/core'
import { Workspace } from '../../../state/types'
import { getProfileImageUrl } from '../../../helpers/cloudinary'

type Props = {
  showProfileImage?: boolean
  workspace: Workspace
}

const WorkspaceInfo: React.FC<Props> = ({ showProfileImage, workspace }) => {
  return (
    <div className="workspace-info">
      {showProfileImage && (
        <div className="workspace-image">
          <ProfileImage
            imageUrl={workspace.profileImageStorageId ? getProfileImageUrl(workspace.profileImageStorageId) : undefined}
            cssClassName={'display-image'}
            name={workspace.name}
          />
        </div>
      )}
      <Tooltip title={workspace.name}>
        <div className="workspace-name">
          <label>{workspace.name}</label>
        </div>
      </Tooltip>
    </div>
  )
}

export default WorkspaceInfo
