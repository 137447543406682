import React, { useState } from 'react'
import { isSafari } from 'helpers/browser'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import './SocialAuth.scss'

type Props = {
  logInWithGoogle: () => Promise<{ success: boolean; errorMessage?: string }>
  logInWithFacebook: () => Promise<{ success: boolean; errorMessage?: string }>
  clearSuccess: () => void
  setErrorMessage: (message: string) => void
  signUpText?: boolean
}

const SocialAuth: React.FC<Props> = ({
  logInWithGoogle,
  logInWithFacebook,
  clearSuccess,
  setErrorMessage,
  signUpText,
}) => {
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false)
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false)

  const onGoogleLoginClick = async () => {
    clearSuccess()
    setErrorMessage('')
    setGoogleAuthLoading(true)
    const { success, errorMessage } = await logInWithGoogle()
    handleSocialAuthResponse(success, errorMessage)
  }

  const onFacebookLoginClick = async () => {
    clearSuccess()
    setErrorMessage('')
    setFacebookAuthLoading(true)
    const { success, errorMessage } = await logInWithFacebook()
    handleSocialAuthResponse(success, errorMessage)
  }

  const handleSocialAuthResponse = (success: boolean, errorMessage?: string) => {
    if (!success) {
      setErrorMessage(errorMessage!)
    }
    if (!success || isSafari) {
      // Stop showing spinner if call fails, or on Safari otherwise the spinner will still be showing if
      // we go back from social auth page. Otherwise keep showing spinner until redirect takes place.
      setFacebookAuthLoading(false)
      setGoogleAuthLoading(false)
    }
  }

  return (
    <>
      <LoadingButton
        className="primary social-auth-button google-auth-button"
        type="button"
        isLoading={isGoogleAuthLoading}
        onClick={onGoogleLoginClick}
      >
        <div className="icon-container">
          <div className="social-icon google-icon" />
        </div>
        <div className="text">{(signUpText ? 'Sign up' : 'Continue') + ' with Google'}</div>
      </LoadingButton>
      <LoadingButton
        className="primary social-auth-button facebook-auth-button"
        type="button"
        isLoading={isFacebookAuthLoading}
        onClick={onFacebookLoginClick}
      >
        <div className="icon-container">
          <div className="social-icon facebook-icon" />
        </div>
        <div className="text">{(signUpText ? 'Sign up' : 'Continue') + ' with Facebook'}</div>
      </LoadingButton>
      <div className="social-auth-separator">
        <div className="separator-line" />
        <div className="separator-text">Or</div>
        <div className="separator-line" />
      </div>
    </>
  )
}

export default SocialAuth
