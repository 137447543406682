import React from 'react'
import './MenuIcon.scss'

type Props = {
  open: boolean
}

const MenuIcon: React.FC<Props> = ({ open }) => (
  <div className={'menu-icon' + (open ? ' open' : '')}>
    <span />
    <span />
    <span />
  </div>
)

export default MenuIcon
