import { useCallback, useEffect, useState } from 'react'
import useStore from 'state/useStore'
import useContentBlockField from 'state/useContentBlockField'
import { RecordingType, VideoChapter } from 'state/types'
import * as videoService from 'services/videoService'
import useVideoSuite from './useVideoSuite'

const tolerance = 0.05 // Dont store edited video if offsets are less than 0.05s.

const useVideoChapter = (previewId?: string, blockIndex?: number, fieldIndex?: number, download?: boolean) => {
  const { state, dispatch } = useStore()
  const { isLoading, contentBlockField, contentBlock, isValidationActive, remove, rename } = useContentBlockField(
    previewId,
    blockIndex,
    fieldIndex
  )
  const responseData = {
    cta: true,
    watermark: true,
    videoFx: true,
    images: true,
    captions: true
  };

  const [isDownloading, setDownloading] = useState(false)
  const [isTrimming, setTrimming] = useState(false)
  const [videoBlob, setVideoBlob] = useState<Blob | undefined>()
  const [isChapterUpdated, setChapterUpdated] = useState(false)
  const videoChapter = contentBlockField?.videoChapter
  const {removeVideoSuiteById,getVideoSuiteByVideoIDPublic} = useVideoSuite(responseData?.cta)

  const updateRawVideoStorageId = (storageId?: string) => {
    if (blockIndex !== undefined && fieldIndex !== undefined) {
      dispatch({ type: 'UPDATE_RAW_VIDEO_STORAGE_ID', blockIndex, fieldIndex, storageId })
    }
  }

  const saveProfileImageStorageId = async (storageId?: string) => {
    await videoService.updateVideoChapterProfileImage(videoChapter?.id!, storageId)
    if (blockIndex !== undefined && fieldIndex !== undefined) {
      dispatch({ type: 'UPDATE_VIDEO_CHAPTER_PROFILE_IMAGE_STORAGE_ID', blockIndex, fieldIndex, storageId })
    }
  }

  const saveType = async (type?: RecordingType) => {
    await videoService.updateVideoChapterType(videoChapter?.id!, type)
    if (blockIndex !== undefined && fieldIndex !== undefined) {
      dispatch({ type: 'UPDATE_VIDEO_CHAPTER_TYPE', blockIndex, fieldIndex, videoType: type })
    }
  }

  const updateVideoTrimData = (startOffset: number, endOffset: number) => {
    if (blockIndex !== undefined && fieldIndex !== undefined) {
      dispatch({
        type: 'UPDATE_VIDEO_TRIM_DATA',
        blockIndex,
        fieldIndex,
        startOffset,
        endOffset,
      })
    }
  }

  const setProfileImageStorageId = useCallback((data: string | undefined) => {
    dispatch({ type: 'SET_PROFILE_IMAGE_STORAGE_ID', value: data });
  }, [dispatch]);

  const updateTeleprompterText = (text: string) => {
    if (blockIndex !== undefined && fieldIndex !== undefined) {
      dispatch({ type: 'UPDATE_TELEPROMPTER_TEXT', blockIndex, fieldIndex, text })
    }
  }

  const uploadVideo = (blob: Blob, onProgressChange: (progress: number) => void) => {
    return videoService.uploadVideo(videoChapter?.id!, blob, onProgressChange, updateVideoChapter)
  }

  const updateVideoChapter = async (newVideoChapter: VideoChapter) => {
    if (blockIndex !== undefined && fieldIndex !== undefined) {
      dispatch({ type: 'UPDATE_VIDEO_CHAPTER', blockIndex, fieldIndex, chapter: newVideoChapter })
    }
  }

  const deleteVideo = async () => {
    // Need to erase teleprompter text after deleting video. Otherwise, the teleprompter is not successfully erased
    await saveProfileImageStorageId(undefined)
    await saveType(undefined)
    videoService.deleteVideo(videoChapter?.id!).then(() => saveTeleprompterText(''))
    updateRawVideoStorageId()
    updateVideoTrimData(0, 0)
    await deleteVideoSuite()
  }

  const deleteVideoSuite = async () => {
    if(responseData?.cta || responseData?.watermark) {
      if (videoChapter && videoChapter.id) {
        const response = await getVideoSuiteByVideoIDPublic(videoChapter.id);
        if (response) {
          await removeVideoSuiteById(response.id);
        }
      }
    }
  }

  const trimVideo = async (startOffset: number, endOffset: number) => {
    setTrimming(true)
    let apiPromise
    // if offsets are less than 0.05s, send a request to the backend to clean up the old edited video and set offests to null
    if (Math.abs(startOffset) < tolerance && Math.abs(endOffset) < tolerance) {
      apiPromise = videoService.removeTrimmedVideo(videoChapter?.id!)
    } else {
      apiPromise = videoService.trimVideo(videoChapter?.id!, startOffset, endOffset)
    }
    const { ok, data } = await apiPromise
    if (ok) {
      data && updateVideoChapter(data)
      setChapterUpdated(true)
      setTrimming(false)
      return true
    } else {
      setTrimming(false)
      return false
    }
  }

  const saveTeleprompterText = async (text: string) => {
    await videoService.updateTeleprompterText(videoChapter?.id!, text)
    updateTeleprompterText(text)
  }

  const checkPermanentlyDeleted = async () => {
    const { ok, data } = await videoService.checkPermanentlyDeleted(videoChapter?.id!)
    return ok && data
  }

  useEffect(() => {
    const storageId = videoChapter?.rawVideoStorageId
    const originalMp4Url = videoChapter?.originalMp4Url
    if (videoChapter && download && storageId && originalMp4Url) {
      const downloadBlob = async () => {
        setDownloading(true)
        const blob = await videoService.downloadVideoBlob(videoChapter)
        if (blob) {
          setVideoBlob(blob)
        }
        setDownloading(false)
      }
      downloadBlob()
    }
  }, [download, videoChapter])

  return {
    isLoading: isLoading || isDownloading,
    isTrimming,
    isChapterUpdated,
    videoChapter,
    videoBlob,
    contentBlockField,
    contentBlock,
    isValidationActive,
    uploadVideo,
    deleteVideo,
    trimVideo,
    saveTeleprompterText,
    remove,
    rename,
    checkPermanentlyDeleted,
    saveProfileImageStorageId,
    saveType,
    deleteVideoSuite,
    updateVideoChapter,
    setProfileImageStorageId
  }
}

export default useVideoChapter
