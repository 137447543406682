import React, { useEffect, useMemo, useState } from 'react'
import { Invitation, PreviewSummary } from 'state/types'
import { formatDate } from 'helpers/date'
import Table from 'components/shared/Table/Table'
import StarIcon from 'assets/icons/StarIcon'
import './InvitationPreviewsTable.scss'
import CopyIcon from '../../../assets/icons/CopyIcon'
import { Tooltip } from '@material-ui/core'
import ProfileCell from '../../shared/ProfileCell/ProfileCell'
import SharingDialog from '../../preview/SharingDialog/SharingDialog'
import { PreviewMeObject } from '../../../helpers/enum'
import ShareIconSmall from '../../../assets/icons/ShareIconSmall'
import Pagination from '../../shared/Table/Pagination'
import { MAX_HIT_PER_PAGE } from '../../../helpers/algolia'
import { DEFAULT_TABLE_VIEW_ITEMS_PER_PAGE, MAX_TOTAL_ITEMS_PER_PAGE_INVITATIONS } from '../../../helpers/constant'
import * as invitationService from 'services/invitationService'
import { number } from 'yup'
import useStore from '../../../state/useStore'

type Props = {
  invitationId: string
  previews: (invitationPreviews: PreviewSummary[] | undefined) => PreviewSummary[] | undefined
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  toggleStarred: (previewId: string) => void
  selectedLabelFilterOption?: string
  tableViewCurrentPage: number
  onPageChange: (page: number) => void
  previewsCount: number | undefined
  searchQuery: string
}


const InvitationPreviewsTable: React.FC<Props> = ({
  invitationId,
  previews,
  sortingBy,
  sortingAsc,
  onSortingByChange,
  onSortingAscChange,
  toggleStarred,
  selectedLabelFilterOption,
  tableViewCurrentPage,
  onPageChange,
  previewsCount,
  searchQuery
}) => {
  const getPreviewLink = (previewId: string, invitationId: string, path = 'preview') => `/${path}/${previewId}?i=${invitationId}&p=${tableViewCurrentPage}
  &f=${selectedLabelFilterOption}&sq=${searchQuery}`
  const [showSharingDialog, setShowSharingDialog] = useState(false)
  const [sharingId, setSharingId] = useState()
  const { dispatch } = useStore()
  const previewsToShow = previews as unknown as PreviewSummary[]

  const [totalItemPerPage, setTotalItemPerPage] = useState(MAX_TOTAL_ITEMS_PER_PAGE_INVITATIONS)
  const [itemsToShow, setItemsToShow] = useState<PreviewSummary[] | undefined>(previewsToShow)

  const range = (start: number, end: number) => {
    let length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
  }

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil((previewsCount || 0)/ totalItemPerPage)
    const totalPageNumbers = 7

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(tableViewCurrentPage - 2, 1)
    const rightSiblingIndex = Math.min(tableViewCurrentPage + 2, totalPageCount)

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    const DOTS = '...'

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 7
      let leftRange = range(1, leftItemCount)

      return [...leftRange, DOTS, totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 7
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
      return [firstPageIndex, DOTS, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }

    return []
  }, [previewsCount, totalItemPerPage, tableViewCurrentPage])

  const handleChangePage = (page: number) => {
    dispatch({ type: 'SET_INVITATION_TABLE_CURRENT_PAGE', value:page })
    onPageChange(page)
  }
  return (
    <>
      <SharingDialog
        id={sharingId}
        onPublish
        isShowing={showSharingDialog}
        onClose={() => setShowSharingDialog(false)}
        object={PreviewMeObject.Preview}
      />
      <Table
        className="invitation-previews-table"
        columns={[
          {
            name: 'applicants',
            className: 'applicants',
            label: `Applicants (${previewsCount})`,
            render: ({ givenName, familyName, name, email, viewed, profileImageId }) => (
              <ProfileCell
                name={name}
                givenName={givenName}
                familyName={familyName}
                email={email}
                notViewed={!viewed}
                profileImageId={profileImageId}
              />
            ),

          },
          {
            name: 'firstPublishDate',
            className: 'first-publish-date',
            label: 'Date received',
            render: ({ firstPublishDate }) => (firstPublishDate ? formatDate(firstPublishDate) : '-'),
          },
          {
            name: 'starred',
            className: 'starred',
            label: 'Starred',
            render: ({ id, starred }) => (
              <div className={'star-button'}>
                <button
                  className={'button plain' + (starred ? ' active' : '')}
                  onClick={e => {
                    e.preventDefault()
                    toggleStarred(id)
                  }}
                >
                  <StarIcon />
                </button>
              </div>
            ),
          },
          {
            name: 'label',
            className: 'label',
            label: 'Label',
            render: ({ label }) => <div>{label}</div>,
          },
          {
            name: 'share',
            className: 'share',
            render: ({ id }) => (
              <div className="share-button-container">
                <button
                  className={'share-button plain'}
                  onClick={e => {
                    e.preventDefault()
                    setShowSharingDialog(true)
                    setSharingId(id)
                  }}
                >
                  <ShareIconSmall/>
                </button>
              </div>
            ),
          },
        ]}
        rows={itemsToShow!}
        sortable
        sortingBy={sortingBy}
        sortingAsc={sortingAsc}
        rowLink={row => getPreviewLink(row.id, invitationId)}
        onSortingByChange={onSortingByChange}
        onSortingAscChange={onSortingAscChange}
      />
      <Pagination
        className={"invitation-pagination"}
        currentPage={tableViewCurrentPage}
        totalCount={totalItemPerPage}
        pageSize={50}
        paginationRange={paginationRange}
        onPageChange={(page) => handleChangePage(page)}
        onChangeTotalItemsPerPage={(itemsPerPage: number) => setTotalItemPerPage(itemsPerPage)}
        disableItemsPerPageSettings={true}
      />
    </>

  )
}

export default InvitationPreviewsTable