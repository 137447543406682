import React from 'react'
import { isValid } from 'helpers/date'
import DatePicker from 'components/shared/DatePicker/DatePicker'
import './FormDatePicker.scss'
import Instructions from '../Instructions/Instructions'

type Props = {
  value?: Date | string
  name?: string
  label?: string
  instructions?: string | undefined
  placeholder?: string
  fromMonth?: Date
  error?: string
  errors?: any
  setValue?: (name?: string, value?: string) => void
  stringify?: boolean
  onChange?: (value?: Date | string) => void
}

const FormDatePicker: React.FC<Props> = ({
  value,
  name,
  label,
  instructions,
  placeholder,
  fromMonth,
  error,
  errors,
  setValue,
  stringify,
  onChange,
}) => {
  const inputId = 'input' + (name ? '-' + name : '')
  const errorToShow = error || (name && errors?.[name]?.message)
  const dateValue = typeof value === 'string' ? new Date(value) : value
  const valueToUse = dateValue && isValid(dateValue) ? dateValue : undefined

  const onDateChange = (date?: Date) => {
    if (stringify && date) {
      onChange?.(date.toISOString())
    } else {
      onChange?.(date)
    }
  }

  return (
    <div className="form-input-container form-date-picker-input-container">
      <div className="form-input-label-row">
        <div>
          {label && (
            <label className="form-input-label" htmlFor={inputId}>
              {label}
            </label>
          )}
          {instructions && <Instructions instructions={instructions} />}
        </div>
        {errorToShow && <div className="form-input-validation-error">{errorToShow}</div>}
      </div>
      <DatePicker
        id={inputId}
        placeholder={placeholder}
        fromMonth={fromMonth}
        value={valueToUse}
        onChange={onDateChange}
      />
      <button
        className="plain"
        onClick={e => {
          e.preventDefault()
          setValue && setValue(name, undefined)
        }}
      >
        clear
      </button>
    </div>
  )
}

export default FormDatePicker
