import React from 'react'

const DialogCloseIcon = () => (
  <svg className="icon" width="29px" height="29px" viewBox="0 0 29 29">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <circle fill="#ececec" cx="14.5" cy="14.5" r="14.5"></circle>
        <g
          transform="translate(14.353553, 14.646447) rotate(-45.000000) translate(-14.353553, -14.646447) translate(6.853553, 7.646447)"
          stroke="#858585"
          strokeLinecap="round"
          strokeWidth="1.6"
        >
          <path d="M7.5,4.54747351e-13 L7.5,14"></path>
          <path
            d="M7.5,0 L7.5,14"
            transform="translate(7.500000, 7.000000) rotate(-270.000000) translate(-7.500000, -7.000000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default DialogCloseIcon
