import { useEffect, useState } from 'react'
import * as integrationService from 'services/integrationService'
import { Integration, IntegrationSetting, Preview, ViewingSelection } from './types'
import { IntegrationSettingFieldType, IntegrationType } from 'helpers/enum'
import { useHistory } from 'react-router-dom'
import useWorkspace from './useWorkspace'
import { getDefaultIntegrationName } from 'helpers/integration'
import ReactGA from 'react-ga4'
import Cookies from 'js-cookie'

export const useIntegrations = (organizationId: string | null) => {
  const [integrations, setIntegrations] = useState<Integration[]>([])
  const [activeIntegrationCount, setActiveIntegrationCount] = useState<number>(0)
  const [archivedIntegrationCount, setArchivedIntegrationCount] = useState<number>(0)

  const [integrationsToShow, setIntegrationsToShow] = useState<Integration[]>([])
  const [viewingSelection, setViewingSelection] = useState<ViewingSelection>('active')
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [sortingOption, setSortingOption] = useState<string | null>('None')
  const [searchKey, setSearchKey] = useState<string>('')
  const { push } = useHistory()
  const [isListLoading, setIsListLoading] = useState<boolean>(false)

  useEffect(() => {
    getAllIntegrationsByOrganization(true)
  }, [organizationId, viewingSelection])

  const getAllIntegrationsByOrganization = (applyLoading?: boolean) => {
    if (organizationId !== null) {
      const getAllIntegrationsByOrganizationFunc = async () => {
        applyLoading && setIsListLoading(true)
        const { ok, data } = await integrationService.getAllIntegrationsByOrganization(
          organizationId,
          viewingSelection === 'archived'
        )

        if (ok && data) {
          setIntegrations(data.integrations)
          setIntegrationsToShow(data.integrations)
          setArchivedIntegrationCount(data.archivedIntegrationCount)
          setActiveIntegrationCount(data.activeIntegrationCount)
        }

        applyLoading && setIsListLoading(false)
      }
      getAllIntegrationsByOrganizationFunc()
    }
  }

  const deleteIntegration = async (integrationId: string) => {
    if (organizationId) {
      const { ok } = await integrationService.deleteIntegrationById(organizationId, integrationId)
      if (ok) {
        const { data } = await integrationService.getAllIntegrationsByOrganization(
          organizationId,
          viewingSelection === 'archived'
        )

        if (data) {
          setIntegrations(data.integrations)
          setIntegrationsToShow(
            integrationsToShow.filter((integration: Integration) => integration.id !== integrationId)
          )
        }
      }
    }
  }

  const updateEnabled = (integrationId: string, enabled: boolean) => {
    if (organizationId) {
      integrationService.updateIntegrationEnabled(organizationId, integrationId, enabled)
      setIntegrations(
        integrations.map((integration: Integration) => {
          if (integration.id !== integrationId) return integration
          return { ...integration, enabled: enabled }
        })
      )
      setIntegrationsToShow(
        integrationsToShow.map((integration: Integration) => {
          if (integration.id !== integrationId) return integration
          return { ...integration, enabled: enabled }
        })
      )
    }
  }

  const updateArchived = (integrationId: string, archived: boolean) => {
    if (organizationId) {
      const updateIntegrationArchivedFunc = async () => {
        await integrationService.updateIntegrationArchived(organizationId, integrationId, archived)
        getAllIntegrationsByOrganization()
      }

      updateIntegrationArchivedFunc()
    }
  }

  const createIntegration = async (integrationType: IntegrationType) => {
    if (organizationId) {
      setModalLoading(true)
      const { ok, data } = await integrationService.createIntegration(
        organizationId,
        integrationType,
        getDefaultIntegrationName(integrationType)
      )
      if (data) {
        setModalLoading(false)
        push('/organization/settings/integrations/' + data.id)
      }
    }
  }

  const onFiltersChange = (selectedSortingOption?: string | null, typingSearchKey?: string | null) => {
    if (selectedSortingOption) setSortingOption(selectedSortingOption)
    if (typingSearchKey || typingSearchKey === '') setSearchKey(typingSearchKey)

    let currentSortingOption = selectedSortingOption || sortingOption
    let currentSearchKey = typingSearchKey || typingSearchKey === '' ? typingSearchKey : searchKey

    let showingIntegrations: Integration[] = JSON.parse(JSON.stringify(integrations))

    if (currentSearchKey || currentSearchKey === '') {
      showingIntegrations = showingIntegrations.filter((integration: Integration) => {
        const nameToSearch = integration.name || getDefaultIntegrationName(integration.type)
        return nameToSearch.search(currentSearchKey) !== -1
      })
    }

    switch (currentSortingOption) {
      case 'A-Z':
        setIntegrationsToShow(
          showingIntegrations.sort((integrationA: Integration, integrationB: Integration) => {
            const nameA = integrationA.name || getDefaultIntegrationName(integrationA.type)
            const nameB = integrationB.name || getDefaultIntegrationName(integrationB.type)
            return nameA.localeCompare(nameB)
          })
        )

        break
      case 'Z-A':
        setIntegrationsToShow(
          showingIntegrations.sort((integrationA: Integration, integrationB: Integration) => {
            const nameA = integrationA.name || getDefaultIntegrationName(integrationA.type)
            const nameB = integrationB.name || getDefaultIntegrationName(integrationB.type)
            return nameB.localeCompare(nameA)
          })
        )
        break
      case 'None':
      default:
        setIntegrationsToShow(showingIntegrations)
    }
  }

  //handle on viewing selection change
  useEffect(() => {
    onFiltersChange(null, null)
  }, [integrations])

  return {
    integrations,
    viewingSelection,
    modalLoading,
    integrationsToShow,
    sortingOption,
    activeIntegrationCount,
    searchKey,
    archivedIntegrationCount,
    isListLoading,
    setSortingOption,
    setIntegrations,
    deleteIntegration,
    updateEnabled,
    createIntegration,
    setIntegrationsToShow,
    onFiltersChange,
    setViewingSelection,
    setActiveIntegrationCount,
    setArchivedIntegrationCount,
    updateArchived,
    setIsListLoading,
    setSearchKey,
  }
}

export const useIntegration = (integrationId: string) => {
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [currentIntegration, setCurrentIntegration] = useState<null | Integration>(null)
  const [platformIdOrKey, setPlatformIdOrKey] = useState<string>('')
  const [integrationLoaded, setIntegrationLoaded] = useState<boolean>(false)
  const [settings, setSettings] = useState<IntegrationSetting[]>([])

  const { currentWorkspace, organizationsLoaded } = useWorkspace(true)

  useEffect(() => {
    if (currentWorkspace?.id && integrationId) {
      const getIntegrationByIdFunc = async () => {
        setIntegrationLoaded(false)
        const { ok, data } = await integrationService.getIntegrationById(currentWorkspace?.id, integrationId)
        if (ok && data) {
          setCurrentIntegration(data)
          setPlatformIdOrKey(data.platformIdOrKey)
        } else {
          setIntegrationLoaded(true)
          setCurrentIntegration(null)
        }
        getSettingsByIntegrationId()
      }
      getIntegrationByIdFunc()
    }
  }, [integrationId])

  const updateEnabled = (enabled: boolean) => {
    if (currentWorkspace?.id && currentIntegration) {
      const updateIntegrationEnabledFunc = async () => {
        const { ok } = await integrationService.updateIntegrationEnabled(
          currentWorkspace?.id,
          currentIntegration.id,
          enabled
        )

        if (ok) {
          setCurrentIntegration({ ...currentIntegration, enabled: enabled })
        }
      }
      updateIntegrationEnabledFunc()
    }
  }

  const updateAuthentication = () => {
    if (currentWorkspace?.id && currentIntegration) {
      const updateIntegrationPlatformIdOrKeyFunc = async () => {
        const { ok, data } = await integrationService.updateIntegrationPlatformIdOrKey(
          currentWorkspace?.id,
          integrationId,
          platformIdOrKey
        )

        if (ok && data) {
          setCurrentIntegration({ ...currentIntegration, platformIdOrKey: platformIdOrKey })
        }
      }
      updateIntegrationPlatformIdOrKeyFunc()
      saveSettings()
    }
  }

  const updateName = async (name: string) => {
    if (currentWorkspace?.id && currentIntegration) {
      await integrationService.updateIntegrationName(currentWorkspace?.id, currentIntegration.id, name)
    }
  }

  const getSettingsByIntegrationId = () => {
    const getIntegrationSettingsByIntegrationId = async () => {
      if (currentWorkspace?.id && integrationId) {
        const { data } = await integrationService.getIntegrationSettingsByIntegrationId(
          currentWorkspace?.id,
          integrationId
        )

        if (data) {
          setSettings(data)
        }

        setIntegrationLoaded(true)
      }
    }

    getIntegrationSettingsByIntegrationId()
  }

  const updateSettings = (currentSetting: IntegrationSetting) => {
    //add new settings
    if (
      !settings.find((setting: IntegrationSetting) => {
        return setting.fieldType === currentSetting?.fieldType
      })
    )
      setSettings([...settings, currentSetting])
    //update existing settings
    else
      setSettings(
        settings.map(setting => (setting.integrationId !== currentSetting.integrationId ? setting : currentSetting))
      )
  }

  const saveSettings = () => {
    if (settings.length === 0) return

    const saveIntegrationSettings = async () => {
      if (currentWorkspace?.id && currentIntegration) {
        const { data } = await integrationService.saveIntegrationSettings(
          currentWorkspace?.id,
          currentIntegration.id,
          settings
        )
        if (data) setSettings(data)
      }
    }

    saveIntegrationSettings()
  }

  return {
    modalLoading,
    currentIntegration,
    integrationLoaded,
    platformIdOrKey,
    settings,
    updateEnabled,
    setPlatformIdOrKey,
    updateAuthentication,
    updateName,
    setCurrentIntegration,
    updateSettings,
  }
}

export const useAttachIntegrations = (preview?: Preview, attachedCondition?: boolean) => {
  let hubspotChatInterval = 0

  useEffect(() => {
    if (preview?.status !== 'published' || !preview.organizationId || !attachedCondition) return

    const getEnabledIntegrationsByOrganizationFunc = async (organizationId: string) => {
      const { ok, data } = await integrationService.getEnabledIntegrationsByOrganization(organizationId)
      if (!data || data.length === 0) return

      //Integrate Hubspot
      const hubSpotIntegrations = data.filter(
        (integration: Integration) =>
          integration.type === IntegrationType.HUBSPOT && integration.enabled && integration.platformIdOrKey
      )

      if (hubSpotIntegrations.length > 0) {
        hubSpotIntegrations.forEach(integration => {
          const script = document.createElement('script')
          script.id = 'hs-script-loader'
          script.type = 'text/javascript'
          script.src = '//js.hs-scripts.com/' + integration.platformIdOrKey + '.js'
          script.async = true
          script.defer = true
          document.body.appendChild(script)
        })
      }

      let counter = 0
      const hubspotChatInterval = setInterval(() => {
        const hubSpotMessageScriptElements = document.querySelectorAll('#hubspot-messages-iframe-container')
        hubSpotMessageScriptElements.forEach(element => {
          element.classList.add('shown-chat-frame')
        })
        counter = counter + 1
        if (hubSpotMessageScriptElements.length || counter === 10) {
          clearInterval(hubspotChatInterval)
        }
      }, 400)

      //Integrate GA4
      const ga4Integrations = data.filter(
        (integration: Integration) =>
          integration.type === IntegrationType.GOOGLE_ANALYTIC && integration.enabled && integration.platformIdOrKey
      )

      if (ga4Integrations.length > 0) {
        const integrationGAMeasurementIds = ga4Integrations.map(integration => {
          return { trackingId: integration.platformIdOrKey }
        })

        ReactGA.initialize(integrationGAMeasurementIds)
        ReactGA.gtag('event', 'page_view', {
          page_path: window.location.pathname + window.location.search,
          page_location: window.location.href,
        })
      }

      //Intergrate Meta Pixel
      const metaPixelIntegrations = data.filter(
        (integration: Integration) =>
          integration.type === IntegrationType.META_PIXEL && integration.enabled && integration.platformIdOrKey
      )

      if (metaPixelIntegrations.length > 0) {
        var rootElement = document.getElementById('root')
        if (rootElement) {
          metaPixelIntegrations.forEach((metaIntegration: Integration) => {
            const fbq = window?.fbq || null

            if (fbq && metaIntegration.settings) {
              const requireConsent =
                metaIntegration.settings.find(
                  setting => setting.fieldType === IntegrationSettingFieldType.META_PIXEL_GDPR
                )?.value === 'true'

              //check received data and cookie
              const showCookiePopup = requireConsent && !Cookies.get('meta_pixel_' + metaIntegration.id)

              if (requireConsent) {
                fbq('consent', 'revoke')
                if (Cookies.get('meta_pixel_' + metaIntegration.id) === 'accept') fbq('consent', 'grant')
              }

              fbq('init', metaIntegration.platformIdOrKey)
              if (showCookiePopup) {
                const modalString = `<div id = "meta-pixel-modal" class="meta-pixel-modal">
                      <div id="my-meta-pixel-modal" class="modal">
                        <div class="modal-content">
                        <div class="close-icon-container">
                          <span id = "cookie-close-icon" class="close-icon">&times;</span>
                          </div>
                          <p>This Preview uses pixel tracking technologies to improve your browsing experience and personalize content and ads. By continuing to use this site, you consent to the use of these technologies.
                          <br/>
                          We may also use these technologies to collect information about your browsing behavior on this site, including information about the pages you visit and how long you stay on each page. This data may be used to improve our website and provide a better user experience, as well as to create targeted ads on social media platforms that allow advertising</p>
                          <div class = "cookie-button-group">
                            <button id = "cookie-decline-btn" class="cookie-decline-btn">Decline</button>
                            <button id = "cookie-accept-btn" class="cookie-accept-btn">Accept</button>
                          </div>
                          </div>
                      </div>
                    </div>`

                if (!document.getElementById('meta-pixel-modal')) {
                  document.body.insertAdjacentHTML('beforeend', modalString)
                }

                const modal = document.getElementById('my-meta-pixel-modal')
                const closeIcon = document.getElementById('cookie-close-icon')
                const declineButton = document.getElementById('cookie-close-icon')
                const acceptButton = document.getElementById('cookie-accept-btn')

                const onDeclineCookie = () => {
                  if (modal) modal.style.display = 'none'
                  Cookies.set('meta_pixel_' + metaIntegration.id, 'decline')
                  fbq('consent', 'revoke')
                }

                const onCloseModal = () => {
                  if (modal) modal.style.display = 'none'
                  //revoke for current time, not update cookie
                  fbq('consent', 'revoke')
                }

                const onAcceptCookie = () => {
                  if (modal) modal.style.display = 'none'
                  fbq('consent', 'grant')
                  Cookies.set('meta_pixel_' + metaIntegration.id, 'accept')
                }

                if (closeIcon) closeIcon.onclick = () => onCloseModal()
                if (declineButton) declineButton.onclick = () => onDeclineCookie()
                if (acceptButton) acceptButton.onclick = () => onAcceptCookie()
              }
            }

            if (fbq) fbq('track', 'PageView')
          })
        }
      }
    }

    getEnabledIntegrationsByOrganizationFunc(preview?.organizationId)

    return () => {
      //clear chat on changing page
      clearInterval(hubspotChatInterval)
      const hubSpotScriptElement = document.querySelectorAll('#hs-script-loader')
      hubSpotScriptElement.forEach(element => {
        document.body.removeChild(element)
      })
      const hubSpotMessageScriptElements = document.querySelectorAll('#hubspot-messages-iframe-container')
      hubSpotMessageScriptElements.forEach(element => {
        element.classList.remove('shown-chat-frame')
      })
    }
  }, [preview])

  return {}
}
