import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { emailNotFoundError } from 'services/authService'
import AuthErrorMessage from 'components/auth/AuthErrorMessage/AuthErrorMessage'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import FormInput from 'components/shared/FormInput/FormInput'
import BackLink from 'components/shared/BackLink/BackLink'
import './ForgotPasswordForm.scss'

type Props = {
  initiatePasswordReset: (email: string) => Promise<{ success: boolean; errorMessage?: string }>
}

type FormData = {
  email: string
}

const validationSchema = yup.object().shape({
  email: yup.string().required('*Please enter an email address').email('*Please enter a valid email address'),
})

const ForgotPasswordForm = ({ initiatePasswordReset }: Props) => {
  const { handleSubmit, register, errors, setError } = useForm<FormData>({ validationSchema })
  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [submittedEmail, setSubmittedEmail] = useState('')

  const onSubmit = handleSubmit(async ({ email }) => {
    setSubmittedEmail('')
    setSuccess(false)
    setErrorMessage('')
    setLoading(true)
    const { success, errorMessage } = await initiatePasswordReset(email)
    if (success) {
      setSuccess(true)
      setSubmittedEmail(email)
    } else if (errorMessage === emailNotFoundError) {
      setError('email', 'server', errorMessage)
    } else {
      setErrorMessage(errorMessage!)
    }
    setLoading(false)
  })

  return (
    <form className="forgot-password-form" onSubmit={onSubmit} noValidate>
      <BackLink to="/auth/login">Back to log in</BackLink>
      {errorMessage && <AuthErrorMessage text={errorMessage} />}
      <h1>Password reset</h1>
      {isSuccess ? (
        <div className="text">
          <span>Success! we've sent a password reset link to </span>
          <span className="email">{submittedEmail}</span>
          <span>.</span>
        </div>
      ) : (
        <>
          <div className="text">Let us know your email address and we'll send you a link to reset your password.</div>
          <FormInput
            name="email"
            type="email"
            placeholder="Email address"
            spellCheck={false}
            errors={errors}
            inputRef={register}
          />
          <LoadingButton type="submit" className="primary" isLoading={isLoading} onMouseDown={e => e.preventDefault()}>
            Submit
          </LoadingButton>
        </>
      )}
    </form>
  )
}

export default ForgotPasswordForm
