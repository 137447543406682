import React, { useEffect, useRef } from 'react'
import './Slider.scss'

interface Props {
  value: number
  minValue?: number
  maxValue: number
  disabled?: boolean
  onChange?: (value: number) => void
  onChangeCommitted?: () => void
}

const isDisabled = (maxValue: number, disabled?: boolean) =>
  disabled || !maxValue || maxValue < 0 || !isFinite(maxValue)

const Slider = ({ value, minValue, maxValue, disabled, onChange, onChangeCommitted }: Props) => {
  const min = minValue || 0
  const sliderRef = useRef<HTMLDivElement>(null)
  const trackRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)
  const minValueRef = useRef(min)
  const maxValueRef = useRef(maxValue)
  const onChangeRef = useRef(onChange)
  const disabledRef = useRef(disabled)
  minValueRef.current = min
  maxValueRef.current = maxValue
  onChangeRef.current = onChange
  disabledRef.current = disabled

  const right = (1 - (value - min) / (maxValue - min)) * 100 + '%'

  useEffect(() => {
    const slider = sliderRef.current
    if (slider) {
      let isDragging = false

      const updateSliderValue = (clientX: number) => {
        if (maxValueRef.current && !isDisabled(maxValueRef.current, disabledRef.current)) {
          const bounds = slider.getBoundingClientRect()
          const newFractionClamped = Math.max(0, Math.min(1, (clientX - bounds.left) / bounds.width))

          const track = trackRef.current
          const thumb = thumbRef.current
          if (track && thumb) {
            track.style.right = (1 - newFractionClamped) * 100 + '%'
            thumb.style.right = (1 - newFractionClamped) * 100 + '%'
          }

          if (onChangeRef.current) {
            onChangeRef.current(newFractionClamped * (maxValueRef.current - minValueRef.current) + minValueRef.current)
          }
        }
      }

      const onMouseDown = (e: MouseEvent) => {
        if (e.button === 0) {
          isDragging = true
          slider.focus()
          e.preventDefault()
          updateSliderValue(e.clientX)
        }
      }

      const onTouchStart = (e: TouchEvent) => {
        if (e.touches.length === 1) {
          isDragging = true
          slider.focus()
          e.preventDefault()
          updateSliderValue(e.touches[0].clientX)
        }
      }

      const onMouseMove = (e: MouseEvent) => {
        if (isDragging) {
          updateSliderValue(e.clientX)
        }
      }

      const onTouchMove = (e: TouchEvent) => {
        if (isDragging && e.touches.length === 1) {
          updateSliderValue(e.touches[0].clientX)
        }
      }

      const endDrag = () => {
        isDragging = false
        slider.blur()
        if (onChangeCommitted) {
          onChangeCommitted()
        }
      }

      slider.addEventListener('mousedown', onMouseDown)
      slider.addEventListener('touchstart', onTouchStart)
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('touchmove', onTouchMove)
      window.addEventListener('mouseup', endDrag)
      window.addEventListener('touchend', endDrag)

      return () => {
        slider.removeEventListener('mousedown', onMouseDown)
        slider.removeEventListener('touchstart', onTouchStart)
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('touchmove', onTouchMove)
        window.removeEventListener('mouseup', endDrag)
        window.removeEventListener('touchend', endDrag)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'slider' + (isDisabled(maxValue, disabled) ? ' disabled' : '')} ref={sliderRef} tabIndex={0}>
      <div></div>
      <div className="track-container">
        <div className="track-background">
          {maxValue > 0 && <div className="track" style={{ right }} ref={trackRef} />}
        </div>
        {maxValue > 0 && (
          <div className="thumb-container" style={{ right }} ref={thumbRef}>
            <div className="thumb" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Slider
