import React from 'react'
import { ContentBlockField } from 'state/types'
import { getProfileImageUrl } from 'helpers/cloudinary'
import PublishedPreviewWebsiteLinks from 'components/preview/PublishedPreviewWebsiteLinks/PublishedPreviewWebsiteLinks'
import './PublishedPreviewPeople.scss'
import { formatDropdownFreeText, formatMultiSelectDropdown, formatPreviewValue } from '../../../helpers/formatting'

type Props = {
  fields: ContentBlockField[]
  trackLinkClick: (fieldId: string) => void
}

const PublishedPreviewPeople: React.FC<Props> = ({ fields, trackLinkClick }) => {
  return (
    <>
      <div className="people">
        {fields.map(field => {
          let valueFieldChildren: ContentBlockField[] = []
          let profileImageValue: string | undefined
          field.children?.forEach(child => {
            if (child.controlType === 'ProfileImage' && child.value) {
              profileImageValue = child.value
            } else {
              valueFieldChildren.push(child)
            }
          })
          return (
            <div key={field.id} className="person">
              <div className="person-values">
                {valueFieldChildren.map((child, index) => {
                  switch (child.controlType) {
                    case 'List':
                      return (
                        child.children && (
                          <PublishedPreviewWebsiteLinks
                            key={index}
                            fields={child.children}
                            hasLabel={false}
                            trackLinkClick={trackLinkClick}
                          />
                        )
                      )
                    case 'Email':
                      return (
                        <a key={index} className="value" href={`mailto:${child.value}`}>
                          {child.value}
                        </a>
                      )
                    case 'DropdownFreeText':
                      return formatDropdownFreeText(child)
                    case 'MultiSelectDropdown':
                      return formatMultiSelectDropdown(child)
                    default:
                      return (
                        <div className={'value ' + child.cssClasses} key={index}>
                          {formatPreviewValue(child, child.highlightPosition !== undefined, trackLinkClick)}
                        </div>
                      )
                  }
                })}
              </div>
              <div className="person-profile-image">
                {profileImageValue && (
                  <div className="person-profile-image">
                    <img src={getProfileImageUrl(profileImageValue)} alt="" />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default PublishedPreviewPeople
