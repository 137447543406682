import React from 'react'
import { useHistory } from 'react-router-dom'
import './DuplicateSuccess.scss'

interface Props {
  previewId?: string
  organisationId?: string
  onClose: () => void
}

const DuplicateSuccess = function ({ previewId, organisationId, onClose }: Props) {
  const { push } = useHistory()

  const onEditNow = function () {
    if (!previewId) return
    push(`/edit/${previewId}/block/0/`)
  }

  return (
    <div className="duplicate-success">
      <h2>Your preview has been duplicated!</h2>
      <div className="subtitle">Would you like to edit your new preview now?</div>
      <div className="button-container">
        <button onClick={onClose}>Edit later</button>
        <button className="edit-now secondary" onClick={onEditNow}>
          Edit now
        </button>
      </div>
    </div>
  )
}

export default DuplicateSuccess
