import React from 'react'
import './VideoSpeedSlider.scss'
import { Slider } from '@material-ui/core'

type Props = {
  className?: string
  isTeleprompterTextLoading: boolean
  isTeleprompterShowing: boolean
  isTeleprompterScrolling: boolean
  teleprompterText: string
  teleprompterSpeed: number
  isRecording: boolean
  toggleTeleprompterScrolling: () => void
  setTeleprompterSpeed: (speed: number) => void
}

const VideoSpeedSlider: React.FC<Props> = ({
  className,
  isTeleprompterTextLoading,
  isTeleprompterShowing,
  isTeleprompterScrolling,
  teleprompterText,
  teleprompterSpeed,
  isRecording,
  toggleTeleprompterScrolling,
  setTeleprompterSpeed,
}) => {
  const testDisabled = !teleprompterText || !isTeleprompterShowing || isRecording || isTeleprompterTextLoading
  const sliderDisabled =
    !teleprompterText || !isTeleprompterShowing || isTeleprompterScrolling || isRecording || isTeleprompterTextLoading
  const speed = `Speed ${teleprompterSpeed / 16}x`

  return (
    <div className={'video-speed-slider' + (className ? ' ' + className : '')}>
      <div className={'speed-label' + (sliderDisabled ? ' disabled' : '')}>{speed}</div>
      <Slider
        defaultValue={teleprompterSpeed}
        step={4}
        min={4}
        max={40}
        onChange={(e, value) => setTeleprompterSpeed(value as number)}
        disabled={sliderDisabled}
        marks
      />
      <button className="test-button primary outline" onClick={toggleTeleprompterScrolling} disabled={testDisabled}>
        {isTeleprompterScrolling ? 'Stop' : 'Test'}
      </button>
    </div>
  )
}

export default VideoSpeedSlider
