import React from 'react';
import './CircularProgressBar.scss';

interface CircularProgressBarProps {
  progress: number;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({ progress }) => {

  const progressAngle = (progress / 100) * 360;

  return (
    <div className="circular-progress-container">
      <svg className="circular-progress">
        <circle
          className="circular-progress-background"
          r="20"
          cx="25"
          cy="25"
        />
        <circle
          className="circular-progress-indicator"
          r="20"
          cx="25"
          cy="25"
          strokeDasharray="126"
          strokeDashoffset={126 - (progressAngle / 360) * 126}
        />
      </svg>
    </div>
  );
};

export default CircularProgressBar;
