import React from 'react'
import AnnouncementBanner from 'components/preview/AnnouncementBanner/AnnouncementBanner'
import { isSubscriptionStatusActiveOrOnFree } from 'helpers/user'
import useAuth from 'state/useAuth'
import './PageFrame.scss'

type Props = {
  className?: string
  header?: React.ReactNode
  hideHeader?: boolean
  sidebar?: React.ReactNode
  hideSidebar?: boolean
}

const PageFrame: React.FC<Props> = ({ className, header, children,sidebar, hideHeader,hideSidebar }) => {
  const { user } = useAuth()
  return (
    <main className={'page-frame' + (className ? ' ' + className : '')}>
      {!isSubscriptionStatusActiveOrOnFree(user!) ? <AnnouncementBanner isPaymentFailed={true} /> : ''}
      {!hideHeader && <div className="header">{header}</div>}
      <div className="page-frame-body">{children}</div>
      {!hideSidebar && <div className="page-frame-side">{sidebar}</div>}
    </main>
  )
}

export default PageFrame
