import React, { useState, useEffect, ButtonHTMLAttributes } from 'react'
import { getRecordingDevices } from 'helpers/media'
import MenuButton from 'components/shared/MenuButton/MenuButton'
import SelectionRing from 'components/shared/SelectionRing/SelectionRing'
import CogIcon from 'assets/icons/CogIcon'
import './VideoSettingsMenuButton.scss'

type Props = {
  selectedVideoDeviceId: string | null
  selectedAudioDeviceId: string | null
  hasPermission?: boolean
  onlyAudio?: boolean
  onSelectedVideoDeviceChange?: (deviceId: string) => void
  onSelectedAudioDeviceChange?: (deviceId: string) => void
} & ButtonHTMLAttributes<HTMLButtonElement>

const VideoSettingsMenuButton: React.FC<Props> = ({
  selectedVideoDeviceId,
  selectedAudioDeviceId,
  hasPermission,
  style,
  onlyAudio,
  onSelectedVideoDeviceChange,
  onSelectedAudioDeviceChange,
}) => {
  const [showing, setShowing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([])
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([])

  useEffect(() => {
    const loadDevices = async () => {
      setLoading(true)
      const devices = await getRecordingDevices()
      const audioDevices = devices.filter(d => d.kind === 'audioinput')
      const videoDevices = devices.filter(d => d.kind === 'videoinput')
      setAudioDevices(audioDevices)
      setVideoDevices(videoDevices)
      onSelectedAudioDeviceChange?.(audioDevices?.[0]?.deviceId)
      onSelectedVideoDeviceChange?.(videoDevices?.[0]?.deviceId)
      setLoading(false)
    }
    loadDevices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission])

  return (
    <MenuButton
      className="video-settings-menu-button"
      showing={showing}
      setShowing={setShowing}
      disabled={loading || !hasPermission}
      buttonContent={<CogIcon />}
      style={style}
    >
      {!onlyAudio && <div className="menu-section-label">Camera</div>}
      {(!videoDevices || videoDevices.length === 0) && !onlyAudio && (
        <div className="device-menu-item">No video devices found</div>
      )}
      {!onlyAudio &&
        videoDevices?.map(d => (
          <button
            type="button"
            key={d.deviceId}
            className="device-menu-item"
            onClick={() => {
              onSelectedVideoDeviceChange?.(d.deviceId)
              setShowing(false)
            }}
          >
            <SelectionRing selected={d.deviceId === selectedVideoDeviceId} />
            <div className="text">{d.label}</div>
          </button>
        ))}
      <div className="menu-section-label">Microphone</div>
      {(!audioDevices || audioDevices.length === 0) && <div className="device-menu-item">No audio devices found</div>}
      {audioDevices?.map(d => (
        <button
          type="button"
          key={d.deviceId}
          className="device-menu-item"
          onClick={() => {
            onSelectedAudioDeviceChange?.(d.deviceId)
            setShowing(false)
          }}
        >
          <SelectionRing selected={d.deviceId === selectedAudioDeviceId} />
          <div className="text">{d.label}</div>
        </button>
      ))}
    </MenuButton>
  )
}

export default VideoSettingsMenuButton
