import format from 'date-fns/format'
import compareAsc from 'date-fns/compareAsc'
import isDatePast from 'date-fns/isPast'
import isDateFuture from 'date-fns/isFuture'
import isDateValid from 'date-fns/isValid'
import { isAndroid, isIos } from 'helpers/browser'

export const formatDate = (unixDate: string) => {
  const date: Date = new Date(unixDate)
  return format(date, 'd MMMM yyyy')
}

export const formatShortDate = (unixDate: string) => {
  const date: Date = new Date(unixDate)
  return format(date, 'd.MM.yy')
}

export const formatDateFromNumber = (unixDate: number) => {
  const date: Date = new Date(unixDate * 1000)
  return format(date, 'd MMMM yyyy')
}

export const formatToDateWithSlashes = (unixDate: string) => {
  const date: Date = new Date(unixDate)
  return format(date, 'd/MM/yyyy')
}

export const compareDates = (unixDate1: string, unixDate2: string, asc: boolean = true) => {
  return (asc ? 1 : -1) * compareAsc(new Date(unixDate1), new Date(unixDate2))
}

export const isPast = (unixDate: string) => isDatePast(new Date(unixDate))

export const isFuture = (unixDate: string) => isDateFuture(new Date(unixDate))

export const isValid = (date: Date) => isDateValid(date)

export const transformStartDate = (datePickerDate: Date | undefined) => {
  if (!datePickerDate) return undefined;

  const transformedDate = new Date(datePickerDate);

  if (isIos || isAndroid) {
    transformedDate.setMinutes(transformedDate.getMinutes() + 1);
  } else {
    transformedDate.setHours(0);
    transformedDate.setMinutes(1);
  }

  return transformedDate.toISOString();
}

export const transformEndDate = (datePickerDate: Date | undefined) => {
  if (!datePickerDate) return undefined;

  const transformedDate = new Date(datePickerDate);

  if (isIos || isAndroid) {
    transformedDate.setHours(23);
    transformedDate.setMinutes(59);
  } else { 
    transformedDate.setHours(23);
    transformedDate.setMinutes(59);
  }

  return transformedDate.toISOString();
}


export const addOffset = (date: Date | undefined, hours: number, minutes: number) => {
  const offset = (hours * 60 + minutes) * 60 * 1000
  return date ? new Date(date?.getTime() + offset) : undefined
}

export const subtractOffset = (date: Date | undefined, hours: number, minutes: number) => {
  const offset = (hours * 60 + minutes) * 60 * 1000
  return date ? new Date(date?.getTime() - offset) : undefined
}

export const reverseTransformStartDate = (savedDate?: Date) => {
  return isIos || isAndroid ? subtractOffset(savedDate, 0, 1) : addOffset(savedDate, 11, 59)
}

export const reverseTransformEndDate = (savedDate?: Date) => {
  return isIos || isAndroid ? subtractOffset(savedDate, 23, 59) : subtractOffset(savedDate, 11, 59)
}

export const today = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return now
}

export const tomorrow = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return new Date(now.getTime() + 24 * 60 * 60 * 1000)
}

export const yesterdayMidday = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return new Date(now.getTime() - 12 * 60 * 60 * 1000)
}

export const endOfYesterday = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return new Date(now.getTime() - 60 * 1000)
}

export const dateWithDaysAdjustment = (dayAdjustment: number) => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return new Date(now.getTime() + dayAdjustment * 24 * 60 * 60 * 1000)
}

export const compareWithCurrentTimeUTC = (dateTime: string) => {
  let now = new Date(Date.now()).toISOString().split('T').join(' ')
  now = now.substring(0, now.length - 2)
  if (!!dateTime) return dateTime.localeCompare(now)
  return -1
}

export const convertToLongDateTimeFormat = (dateTimeString: string) => {
  if (dateTimeString) {
    const time24h = new Date(dateTimeString)
    let time24hStr = time24h.toString()
    const H = +time24hStr.substring(0, 2)
    const hourNum = H % 12 || 12
    const hourStr = hourNum < 10 ? '0' + hourNum : hourNum
    const ampm = H < 12 ? 'am' : 'pm'
    time24hStr = hourStr + '.' + time24h.getMinutes().toString() + ampm

    const month = time24h.toLocaleString('en-US', {
      month: 'long',
    })

    return time24hStr + ', ' + time24h.getDate() + ' ' + month + ' ' + time24h.getFullYear()
  }
  return null
}
