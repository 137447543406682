import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  sortFlatVideoAssets,
  getVideoAssetsInitialSortingBy,
  getVideoAssetsInitialSortingAsc,
  videoAssetsSortingByKey,
  videoAssetsSortingAscKey,
} from 'helpers/sort'
import { formatDate } from 'helpers/date'
import { formatVideoTime, getVideoDuration } from 'helpers/media'
import { parseIndexParam } from 'helpers/urlParams'
import useVideoAssets from 'state/useVideoAssets'
import useVideoChapter from 'state/useVideoChapter'
import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import VideoAssetsTable from 'components/assets/VideoAssetsTable/VideoAssetsTable'
import VideoAssetsListMobile from 'components/assets/VideoAssetsListMobile/VideoAssetsListMobile'
import { getConcatenatedTitlesForPreviewLite } from 'helpers/titles'
import './VideoAssetsPage.scss'

const VideoAssetsPage = () => {
  const { push } = useHistory()
  const { previewId, index, fieldIndex } = useParams<any>()
  const { isLoading, videoAssets ,copyVideoAssetModify} = useVideoAssets()
  const { videoChapter: destinationChapter, contentBlockField } = useVideoChapter(
    previewId,
    parseIndexParam(index),
    parseIndexParam(fieldIndex)
  )
  const [sortingBy, setSortingBy] = useState(getVideoAssetsInitialSortingBy())
  const [sortingAsc, setSortingAsc] = useState(getVideoAssetsInitialSortingAsc())

  const onSortingByChange = (sortingBy: string) => {
    setSortingBy(sortingBy)
    localStorage.setItem(videoAssetsSortingByKey, sortingBy)
  }

  const onSortingAscChange = (sortingAsc: boolean) => {
    setSortingAsc(sortingAsc)
    localStorage.setItem(videoAssetsSortingAscKey, sortingAsc.toString())
  }

  let flatVideoAssets = videoAssets?.flatMap(previewAssets =>
    previewAssets.videoChapters.map(videoChapter => {
      const storageId = videoChapter.rawVideoStorageId
      const duration = getVideoDuration(videoChapter)
      const createdAt = videoChapter.createdAt

      return {
        videoChapterId: videoChapter.id,
        videoChapterName: videoChapter.name,
        previewName: getConcatenatedTitlesForPreviewLite(previewAssets.previewLite),
        dateCreated: createdAt ? formatDate(createdAt) : undefined,
        videoTime: duration ? formatVideoTime(duration) : undefined,
        videoUrl: storageId ? videoChapter.trimmedMp4Url : undefined,
        audioOnly: videoChapter.type === 'audio',
        profileImageStorageId: videoChapter.profileImageStorageId,
      }
    })
  )

  let filteredFlatVideoAssets = flatVideoAssets?.filter(e => {
    if (contentBlockField?.format === 'audio-only') return e.audioOnly
    if (contentBlockField?.format === 'video-only') return !e.audioOnly
    return true
  })

  const sortedFlatVideoAssets = sortFlatVideoAssets(filteredFlatVideoAssets, sortingBy, sortingAsc)

  const onAddClick = async (videoChapterId: string) => {
    if (destinationChapter) {
      const filteredVideoAsset = videoAssets?.flatMap(videoAsset => videoAsset.videoChapters)
        .find(chapter => chapter.id === videoChapterId);
      await copyVideoAssetModify(false, videoChapterId, destinationChapter.id,filteredVideoAsset?.id,filteredVideoAsset?.rawVideoStorageId)
      push(`/edit/${previewId}/block/${index}`)

    }

  }

  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top of page when component mounts.
  }, [])

  return (
    <FullScreenPageFrame
      className="video-assets-page"
      cancelLink={`/edit/${previewId}/block/${index}`}
      title="Video Content Assets"
      subtitle="These are videos you've uploaded previously. You can select any of them to re-use, and edit the titles once you've added them."
      whiteBackground
    >
      {!isLoading && flatVideoAssets && flatVideoAssets.length === 0 ? (
        <div className="no-assets-message">You have no video assets yet.</div>
      ) : (
        <>
          {sortedFlatVideoAssets && (
            <VideoAssetsTable
              rows={sortedFlatVideoAssets!}
              sortingBy={sortingBy}
              sortingAsc={sortingAsc}
              onSortingByChange={onSortingByChange}
              onSortingAscChange={onSortingAscChange}
              onAddClick={onAddClick}
            />
          )}
          <div className="video-assets-page-mobile-content">
            {sortedFlatVideoAssets && (
              <VideoAssetsListMobile
                rows={sortedFlatVideoAssets!}
                sortingBy={sortingBy}
                sortingAsc={sortingAsc}
                onSortingByChange={onSortingByChange}
                onSortingAscChange={onSortingAscChange}
                onAddClick={onAddClick}
              />
            )}
          </div>
        </>
      )}
    </FullScreenPageFrame>
  )
}

export default VideoAssetsPage
