import React from 'react'

const FullscreenIcon = () => (
  <svg className="icon" width="20" height="20" viewBox="0 0 20 20">
    <path d="M9,3A1,1,0,0,0,8,2H3A1,1,0,0,0,2,3V8A1,1,0,0,0,4,8V5.41L7.24,8.66A1,1,0,0,0,8.66,7.24L5.41,4H8A1,1,0,0,0,9,3Z"></path>
    <path d="M17,11a1,1,0,0,0-1,1v2.59l-3.24-3.24a1,1,0,0,0-1.41,1.41L14.59,16H12a1,1,0,0,0,0,2h5a1,1,0,0,0,1-1V12A1,1,0,0,0,17,11Z"></path>
  </svg>
)

export default FullscreenIcon
