import React from 'react'
import './PageIndicator.scss'

interface Props {
  pageCount: number
  pageIndex: number
  setPageIndex: (value: number) => void
}

const PageIndicator = function ({ pageCount, pageIndex, setPageIndex }: Props) {
  const load = function () {
    const result: JSX.Element[] = []
    for (let i = 0; i < pageCount; i++) {
      result.push(<div key={i} className={`dot ${i === pageIndex ? ' filled' : ''}`} onClick={() => setPageIndex(i)} />)
    }
    return result
  }

  return <div className="dot-container">{load()}</div>
}

export default PageIndicator
