import * as React from 'react'

interface Props {
  white?: boolean
  grey?: boolean
  previewBlue?: boolean
  width?: string
  height?: string
}

const MicrophoneIcon = ({ white, grey, previewBlue, width, height }: Props) => {
  const fill = grey ? 'b7b6b6' : white ? 'FFFFFF' : previewBlue ? '01a3f8' : '000000'
  const renderWidth = width || '24px'
  const renderHeight = height || '24px'
  return (
    <svg className="icon microphone-icon" width={renderWidth} height={renderHeight} viewBox="0 0 24 24">
      <defs>
        <style>{`.cls-1{fill:#${fill};}`}</style>
      </defs>
      <title>{'microphone'}</title>
      <g id="microphone-Regular">
        <path
          id="microphone-Regular-2"
          data-name="microphone-Regular"
          className="cls-1"
          d="M12,15.75A4.756,4.756,0,0,0,16.75,11V7a4.75,4.75,0,0,0-9.5,0v4A4.756,4.756,0,0,0,12,15.75ZM8.75,7a3.25,3.25,0,0,1,6.5,0v4a3.25,3.25,0,0,1-6.5,0Zm4,11.712V20.25H16a.75.75,0,0,1,0,1.5H8a.75.75,0,0,1,0-1.5h3.25V18.712A7.76,7.76,0,0,1,4.25,11a.75.75,0,0,1,1.5,0,6.25,6.25,0,0,0,12.5,0,.75.75,0,0,1,1.5,0A7.76,7.76,0,0,1,12.75,18.712Z"
        />
      </g>
    </svg>
  )
}

export default MicrophoneIcon
