import React, { useRef, useEffect, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import useOutsideClick from 'helpers/useOutsideClick'
import useEscapeKeyPress from 'helpers/useDocumentKeyPress'
import './MenuButton.scss'

type Props = {
  showing: boolean
  className?: string
  buttonClassName?: string
  buttonContent?: React.ReactNode | React.ReactNodeArray
  disabled?: boolean
  setShowing: (showing: boolean) => void
  onAboutToShow?: (rootElement: HTMLDivElement) => void
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const MenuButton: React.FC<Props> = ({
  showing,
  className,
  buttonClassName,
  buttonContent,
  disabled,
  setShowing,
  onAboutToShow,
  children,
  ...rest
}) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const showingChangeRef = useRef(false)

  useOutsideClick(rootRef, () => !showingChangeRef.current && setShowing(false))

  useEscapeKeyPress(
    useCallback(() => {
      if (!showingChangeRef.current) {
        setShowing(false)
      }
    }, [setShowing])
  )

  useEffect(() => {
    showingChangeRef.current = true
    setTimeout(() => (showingChangeRef.current = false), 200)
    if (rootRef.current) {
      onAboutToShow?.(rootRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showing])

  return (
    <div className={'menu-button-container' + (className ? ' ' + className : '')} ref={rootRef}>
      <button
        {...rest}
        type="button"
        className={'menu-button' + (buttonClassName ? ' ' + buttonClassName : '')}
        onClick={() => !showingChangeRef.current && setShowing(!showing)}
        disabled={disabled}
      >
        {buttonContent}
      </button>
      <CSSTransition in={showing} timeout={200} unmountOnExit>
        <div className="popup-anchor">
          <div className="popup">{children}</div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default MenuButton
