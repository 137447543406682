import { checkPublishable } from 'helpers/validation'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as previewService from 'services/previewService'
import { Preview, PreviewStatus, ViewingSelection } from 'state/types'
import { bulkPreviewArchived, bulkPreviewPublish } from 'services/previewService'
import { getPreviewsViewAnalytic } from 'services/analyticsService'
import { getWorkspaceOrganizationId } from 'helpers/organization'
import useAuth from 'state/useAuth'
import { PreviewsCreatorFilterOption, PreviewViewMode } from 'helpers/enum'
import { DEFAULT_TABLE_VIEW_ITEMS_PER_PAGE, INITIAL_VIEWS, TILE_VIEW_ITEMS_PER_PAGE } from 'helpers/constant'

const usePreviews = (retrievePreviewsOnLoad?: boolean, retrievePreviewCountOnLoad?: boolean) => {
  const isOrganization = !!getWorkspaceOrganizationId()
  const [viewMode, setViewMode] = useState(PreviewViewMode.TABLE_VIEW)

  const [isTableViewPreviewsLoading, setTableViewPreviewsLoading] = useState(false)
  const [isTileViewPreviewsLoading, setTileViewPreviewsLoading] = useState(false)

  const [isPreviewCategoryCountLoading, setPreviewCategoryCountLoading] = useState(true)

  const [tableViewPreviews, setTableViewPreviews] = useState<Preview[]>([])
  const [tileViewPreviews, setTileViewPreviews] = useState<Preview[]>([])

  const [previewCount, setPreviewCount] = useState<number>(0)
  const [archivedPreviewCount, setArchivedPreviewCount] = useState<number>(0)
  const [suspendedPreviewsCount, setSuspendedPreviewsCount] = useState<number>()
  const [viewingSelection, setViewingSelection] = useState<ViewingSelection>('active')
  const [creatingId, setCreatingId] = useState<string | undefined>()
  const [loadingId, setLoadingId] = useState<string | undefined>()
  const [removingId, setRemovingId] = useState<string | undefined>()
  const [individualCount, setIndividualCount] = useState<number>()
  const [businessCount, setBusinessCount] = useState<number>()
  const [isIndividualCountAtMaxForFree, setisIndividualCountAtMaxForFree] = useState(false)
  const [isBusinessCountAtMaxForFree, setisBusinessCountAtMaxForFree] = useState(false)
  const history = useHistory()
  const mountedRef = useRef(true)
  const { user } = useAuth()

  const [tableViewCurrentPage, setTableViewCurrentPage] = useState(1)
  const [tileViewCurrentPage, setTileViewCurrentPage] = useState(1)

  const [status, setStatus] = useState('all')
  const [archived, setArchived] = useState(false)
  const [sortingBy, setSortingBy] = useState('status')
  const [sortingAsc, setSortingAsc] = useState(false)
  const [creator, setCreator] = useState<string>(PreviewsCreatorFilterOption.Everyone)
  const [totalPage, setTotalPage] = useState(1)
  const [itemCount, setItemCount] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(DEFAULT_TABLE_VIEW_ITEMS_PER_PAGE)
  const createPreview = async (templateId: string) => {
    setCreatingId(templateId)
    const { ok, data } = await previewService.createPreview(templateId)
    setCreatingId(undefined)

    if (ok && data) {
      const newPreviewId = data
      history.push(`/edit/${newPreviewId}`)
    }
  }

  const updateArchived = async (
    previewId: string,
    archived: boolean,
    suspended: boolean,
    viewMode?: PreviewViewMode
  ) => {
    setLoadingId(previewId)
    const { ok } = await previewService.updatePreviewArchived(previewId, archived)
    setLoadingId(undefined)
    if (ok) {
      if (viewMode === PreviewViewMode.TABLE_VIEW) {
        loadTableViewPreviews(tableViewCurrentPage)
      } else {
        setRemovingId(previewId)
        setTimeout(() => {
          if (mountedRef.current) {
            if (archived && suspended && suspendedPreviewsCount && suspendedPreviewsCount > 0) {
              setSuspendedPreviewsCount(suspendedPreviewsCount - 1)
            }
            setTileViewPreviews(tileViewPreviews.filter(p => p.id !== previewId))
            setPreviewCount(previewCount + (archived ? -1 : 1))
            setArchivedPreviewCount(archivedPreviewCount + (archived ? 1 : -1))
            setRemovingId(undefined)
          }
        }, 400) // Actually remove preview after 400ms to allow animation time to finish.
      }
    }
  }

  const publishBulkPreviews = async function (previewIds: string[], published: boolean) {
    const { ok } = await bulkPreviewPublish(previewIds, published)
    if (ok) {
      if (viewMode === PreviewViewMode.TABLE_VIEW) {
        const status = published ? 'published' : 'unpublished'
        setTableViewPreviews(tableViewPreviews.map(p => (previewIds.includes(p.id) ? { ...p, status } : p)))
      } else {
        const status = published ? 'published' : 'unpublished'
        setTileViewPreviews(tileViewPreviews.map(p => (previewIds.includes(p.id) ? { ...p, status } : p)))
      }
    }
  }

  const archiveBulkPreviews = async function (previewIds: string[], archived: boolean) {
    const { ok } = await bulkPreviewArchived(previewIds, archived)
    if (ok && viewMode === PreviewViewMode.TABLE_VIEW) {
      loadTableViewPreviews(tableViewCurrentPage)
    } else if (ok) {
      setTileViewPreviews(tileViewPreviews.filter(p => !previewIds.includes(p.id)))
      setPreviewCount(previewCount + (archived ? -1 * previewIds.length : previewIds.length))
      setArchivedPreviewCount(archivedPreviewCount + (archived ? previewIds.length : -1 * previewIds.length))
    }
  }

  const archivePreview = (previewId: string, suspended: boolean, viewMode?: PreviewViewMode) =>
    updateArchived(previewId, true, suspended, viewMode)

  const restorePreview = (previewId: string, suspended: boolean, viewMode?: PreviewViewMode) =>
    updateArchived(previewId, false, suspended, viewMode)

  const checkCanPublish = async (previewId: string) => {
    setLoadingId(previewId)
    const { ok, data } = await previewService.getPreviewDetails(previewId)
    setLoadingId(undefined)
    if (ok && data) {
      return checkPublishable(data)
    }
    return {}
  }

  const updatePublished = async (previewId: string, published: boolean, isSuspended: boolean): Promise<boolean> => {
    setLoadingId(previewId)
    const { ok, data } = await previewService.updatePreviewPublished(previewId, published)
    if (ok) {
      const status: PreviewStatus = published ? 'published' : 'unpublished'
      let suspended: boolean = isSuspended
      if (published && isSuspended) {
        suspended = false
      }
      if (data)
        if (viewMode === PreviewViewMode.TABLE_VIEW) {
          setTableViewPreviews(
            tableViewPreviews.map(p => (p.id === previewId ? { ...p, status, suspended, lastPublishDate: data } : p))
          )
        } else
          setTileViewPreviews(
            tileViewPreviews.map(p => (p.id === previewId ? { ...p, status, suspended, lastPublishDate: data } : p))
          )
      else if (viewMode === PreviewViewMode.TABLE_VIEW) {
        setTableViewPreviews(tableViewPreviews.map(p => (p.id === previewId ? { ...p, status, suspended } : p)))
      } else setTileViewPreviews(tileViewPreviews.map(p => (p.id === previewId ? { ...p, status, suspended } : p)))

      if (isSuspended && suspendedPreviewsCount && suspendedPreviewsCount > 0) {
        setSuspendedPreviewsCount(suspendedPreviewsCount - 1)
      }
      setLoadingId(undefined)
      return true
    } else {
      setLoadingId(undefined)
      return false
    }
  }

  const publishPreview = async (previewId: string, suspended: boolean): Promise<boolean> =>
    await updatePublished(previewId, true, suspended)

  const unpublishPreview = async (previewId: string, suspended: boolean): Promise<boolean> =>
    await updatePublished(previewId, false, suspended)

  const deletePreview = async (previewId: string, viewMode?: PreviewViewMode) => {
    setLoadingId(previewId)
    const { ok } = await previewService.deletePreview(previewId)
    setLoadingId(undefined)

    if (ok && viewMode === PreviewViewMode.TABLE_VIEW) {
      loadTableViewPreviews(tableViewCurrentPage)
    } else if (ok) {
      setRemovingId(previewId)
      setTimeout(() => {
        if (mountedRef.current) {
          setTileViewPreviews(tileViewPreviews.filter(p => p.id !== previewId))
          setArchivedPreviewCount(archivedPreviewCount - 1)
          setRemovingId(undefined)
        }
      }, 400) // Remove preview after 400ms to allow animation time to finish.
    }
  }

  const deleteBulkPreviews = async (previewIds: string[]) => {
    const { ok } = await previewService.deleteBulkPreviews(previewIds)

    if (ok && viewMode === PreviewViewMode.TABLE_VIEW) {
      loadTableViewPreviews(tableViewCurrentPage)
    } else if (ok) {
      setTileViewPreviews(tileViewPreviews.filter(p => !previewIds.includes(p.id)))
      setArchivedPreviewCount(archivedPreviewCount - previewIds.length)
    }
  }

  const duplicatePreview = async (newPreview: Preview, duplicatedPreviewId?: string, viewMode?: PreviewViewMode) => {
    setLoadingId(duplicatedPreviewId)
    setLoadingId(undefined)
    setViewingSelection('active')

    if (viewMode === PreviewViewMode.TABLE_VIEW) {
      let oldIndex: number = tableViewPreviews.findIndex(preview => preview.id === duplicatedPreviewId)
      if (user) {
        tableViewPreviews.splice(oldIndex + 1, 0, {
          ...newPreview,
          views: INITIAL_VIEWS,
          givenName: user.givenName,
          familyName: user.familyName,
        })
        setTableViewPreviews(tableViewPreviews)
      } else {
        tableViewPreviews.splice(oldIndex + 1, 0, {
          ...newPreview,
          views: INITIAL_VIEWS,
        })
        setTableViewPreviews(tableViewPreviews)
      }
    } else {
      let oldIndex: number = tileViewPreviews.findIndex(preview => preview.id === duplicatedPreviewId)
      if (user) {
        tileViewPreviews.splice(oldIndex + 1, 0, {
          ...newPreview,
          views: INITIAL_VIEWS,
          givenName: user.givenName,
          familyName: user.familyName,
        })
        setTileViewPreviews(tileViewPreviews)
      } else {
        tileViewPreviews.splice(oldIndex + 1, 0, {
          ...newPreview,
          views: INITIAL_VIEWS,
        })
        setTileViewPreviews(tileViewPreviews)
      }
    }
  }

  const loadTableViewPreviews = async (page?: number) => {
    setTableViewPreviewsLoading(true)

    const rowsPerPage = totalItemPerPage
    const queryCreator = creator === PreviewsCreatorFilterOption.JustMe ? 'JUST_ME' : 'EVERYONE'
    const queryParams = `page=${page ? page : tableViewCurrentPage}&rowsPerPage=${rowsPerPage}&status=${status}${
      !!sortingBy ? '&sortingBy=' + sortingBy : ''
    }${sortingAsc ? '&asc=true' : ''}${isOrganization && creator ? '&creator=' + queryCreator : ''}&viewMode=TABLE_VIEW`
    const { ok, data } = await previewService.getMyPreviews(viewingSelection === 'archived', queryParams)

    if (ok && data) {
        let viewsMap = new Map()
        const ids = data.previewSummaries.map(preview => preview.id).join(',')
        if (ids) {
          const { ok, data } = await getPreviewsViewAnalytic(ids)
          if (ok && data) {
            data.forEach(item => {
              viewsMap.set(item.previewId, item.views)
            })
          }
        }

        data.previewSummaries.forEach(preview => {
          preview.views = viewsMap.get(preview.id) || 0
        })

      setTableViewPreviewsLoading(false)
      setTotalPage(data.itemCount / totalItemPerPage)
      setTableViewPreviews(data.previewSummaries || [])
      setPreviewCount(data.previewsCount || 0)

      if (Math.floor((data.itemCount - 1) / totalItemPerPage + 1) < tableViewCurrentPage)
        setTableViewCurrentPage(Math.floor((data.itemCount - 1) / totalItemPerPage + 1))

      setSuspendedPreviewsCount(data.suspendedPreviewsCount)
      setArchivedPreviewCount(data.archivedPreviewsCount || 0)
      setItemCount(data.itemCount || 0)
    }

    setTableViewPreviewsLoading(false)
  }

  const loadTileViewPreviews = async (reset?: boolean) => {
    if (reset) setTileViewPreviewsLoading(true)

    const rowsPerPage = TILE_VIEW_ITEMS_PER_PAGE
    const currentItemCount = reset ? 0 : itemCount
    const currentTileViewDataLoaded = reset ? false : isTileViewPreviewsLoading
    const currentTileViewPage = reset ? 1 : tileViewCurrentPage

    if (currentTileViewPage > currentItemCount / rowsPerPage + 1 && currentTileViewDataLoaded) return

    const queryCreator = creator === PreviewsCreatorFilterOption.JustMe ? 'JUST_ME' : 'EVERYONE'
    const queryParams = `page=${currentTileViewPage}&rowsPerPage=${rowsPerPage}&status=${status}${
      !!sortingBy ? '&sortingBy=' + sortingBy : ''
    }${sortingAsc ? '&asc=true' : ''}${isOrganization && creator ? '&creator=' + queryCreator : ''}&viewMode=TILE_VIEW`

    const { ok, data } = await previewService.getMyPreviews(viewingSelection === 'archived', queryParams)

    if (ok && data) {
      if (isOrganization) {
        let viewsMap = new Map()
        const ids = data.previewSummaries.map(preview => preview.id).join(',')
        if (ids) {
          const { ok, data } = await getPreviewsViewAnalytic(ids)
          if (ok && data) {
            data.forEach(item => {
              viewsMap.set(item.previewId, item.views)
            })
          }
        }
        data.previewSummaries.forEach(preview => {
          preview.views = viewsMap.get(preview.id) || 0
        })
      }

      if (reset) {
        setTileViewPreviews(data.previewSummaries || [])
      } else {
        setTileViewPreviews(tileViewPreviews.concat(data.previewSummaries) || [])
      }

      setTileViewPreviewsLoading(false)
      setTileViewCurrentPage(currentTileViewPage + 1)
      setPreviewCount(data.previewsCount || 0)
      setSuspendedPreviewsCount(data.suspendedPreviewsCount)
      setArchivedPreviewCount(data.archivedPreviewsCount || 0)
      setItemCount(data.itemCount || 0)
    }
  }

  const onTotalItemPerPageChange = (itemPerPage: number) => {
    setTotalItemPerPage(itemPerPage)
  }

  // Load previews
  useEffect(() => {
    //handle tileview
    setTileViewCurrentPage(1)
    if (viewMode === PreviewViewMode.TILE_VIEW) {
      loadTileViewPreviews(true)
    } else {
      loadTableViewPreviews(tableViewCurrentPage)
    }
  }, [viewingSelection, creator, viewMode, status, sortingBy, sortingAsc, tableViewCurrentPage, totalItemPerPage])

  // Load individual & business previews counts and booleans
  useEffect(() => {
    const loadPreviewCategoryCount = async () => {
      setPreviewCategoryCountLoading(true)
      const { ok, data } = await previewService.getPreviewCategoryCount()
      if (ok && data) {
        setisIndividualCountAtMaxForFree(data.overLimitForFreeIndividualPreviews)
        setisBusinessCountAtMaxForFree(data.overLimitForFreeBusinessPreviews)
        setIndividualCount(data.individualPreviewsCount)
        setBusinessCount(data.businessPreviewsCount)
      }
      setPreviewCategoryCountLoading(false)
    }

    if (retrievePreviewCountOnLoad) {
      loadPreviewCategoryCount()
    }
  }, [retrievePreviewCountOnLoad])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const range = (start: number, end: number) => {
    let length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
  }

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(itemCount / totalItemPerPage)
    const totalPageNumbers = 7

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(tableViewCurrentPage - 2, 1)
    const rightSiblingIndex = Math.min(tableViewCurrentPage + 2, totalPageCount)

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    const DOTS = '...'

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 7
      let leftRange = range(1, leftItemCount)

      return [...leftRange, DOTS, totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 7
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
      return [firstPageIndex, DOTS, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }

    return []
  }, [itemCount, totalItemPerPage, tableViewCurrentPage])

  return {
    isTableViewPreviewsLoading,
    isTileViewPreviewsLoading,
    setTileViewPreviewsLoading,
    setTableViewPreviewsLoading,
    isPreviewCategoryCountLoading,
    tableViewPreviews,
    tileViewPreviews,
    creatingId,
    loadingId,
    removingId,
    viewingSelection,
    previewCount,
    archivedPreviewCount,
    individualCount,
    businessCount,
    suspendedPreviewsCount,
    isBusinessCountAtMaxForFree,
    isIndividualCountAtMaxForFree,
    viewMode,
    tableViewCurrentPage,
    tileViewCurrentPage,
    creator,
    sortingBy,
    sortingAsc,
    status,
    archived,
    totalPage,
    itemCount,
    totalItemPerPage,
    paginationRange,
    createPreview,
    archivePreview,
    restorePreview,
    checkCanPublish,
    publishPreview,
    unpublishPreview,
    deletePreview,
    duplicatePreview,
    setViewingSelection,
    archiveBulkPreviews,
    deleteBulkPreviews,
    publishBulkPreviews,
    setTableViewCurrentPage,
    setCreator,
    setSortingBy,
    setSortingAsc,
    setStatus,
    setArchived,
    loadTableViewPreviews,
    setViewMode,
    loadTileViewPreviews,
    onTotalItemPerPageChange,
    setTotalItemPerPage,
  }
}

export default usePreviews
