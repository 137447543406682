import AuthPage from 'components/auth/AuthPage/AuthPage'
import FormInput from 'components/shared/FormInput/FormInput'
import FormMultiSelect from 'components/shared/FormMultiSelect/FormMultiSelect'
import FormSelect from 'components/shared/FormSelect/FormSelect'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import { AccountType } from 'helpers/enum'
import { getClientQueryParam, getLocationFrom } from 'helpers/paramStorage'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import * as configService from 'services/configService'
import { Option } from 'state/types'
import useAuth from 'state/useAuth'
import useWorkspace from 'state/useWorkspace'
import * as yup from 'yup'
import AccountTypeSelector from '../AccountTypeSelector/AccountTypeSelector'
import AgreementSelector from '../AgreementSelector/AgreementSelector'
import './OnboardingPage.scss'

type FormData = {
  givenName: string
  familyName: string
  industries: string
  country: string
  accountType: AccountType
  privacyPolicy: boolean
  marketing: boolean
}

type Props = {
  teamMemberInvitation?: boolean
}

const OnboardingPage: React.FC<Props> = ({ teamMemberInvitation }) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [industryOptions, setIndustryOptions] = useState<Option[]>([])
  const [countryOptions, setCountryOptions] = useState<Option[]>([])
  const [isOrganizationAccount, setOrganizationAccount] = useState<boolean>(false)
  const [isPersonalAccount, setPersonalAccount] = useState<boolean>(false)
  const { user, completeUserOnboarding, onboardUserInSessionStorage } = useAuth()
  const { addUserToOrganizationByInvitation } = useWorkspace(false)
  const { push } = useHistory()
  const { organizationId, invitationCode } = useParams<any>()

  const validationSchema = !teamMemberInvitation
    ? yup.object().shape({
        givenName: yup.string().required(),
        familyName: yup.string().required(),
        country: yup.string().required('Please select your country'),
        accountType: yup
          .mixed<AccountType>()
          .oneOf(Object.values(AccountType), 'Please select an account type')
          .required('Please select an account type'),
        privacyPolicy: yup.bool().oneOf([true], 'Please agree to continue'),
        marketing: yup.bool(),
      })
    : yup.object().shape({
        givenName: yup.string().required(),
        familyName: yup.string().required(),
        country: yup.string().required('Please select your country'),
        privacyPolicy: yup.bool().oneOf([true], 'Please agree to continue'),
        marketing: yup.bool(),
      })

  const { handleSubmit, register, control, errors } = useForm<FormData>({
    validationSchema,
    defaultValues: {
      givenName: user?.givenName,
      familyName: user?.familyName,
      industries: user?.industries,
      country: user?.country,
      accountType: user?.accountType,
    },
  })

  const nameError = errors.givenName || errors.familyName ? 'Please complete your name' : undefined

  const onSubmit = handleSubmit(
    async ({ givenName, familyName, industries, country, accountType, privacyPolicy, marketing }) => {
      localStorage.setItem('onboarding', 'true')
      setSubmitting(true)
      const user = { givenName, familyName, industries, country, accountType, privacyPolicy, marketing }
      if (teamMemberInvitation) {
        const ok = await addUserToOrganizationByInvitation(organizationId, invitationCode)
        if (!ok) {
          setSubmitting(false)
        } else if (user) {
          const ok = await completeUserOnboarding(user)
          if (ok) {
            push('/')
          } else {
            setSubmitting(false)
          }
        }
      } else {
        if (accountType === AccountType.Organization || accountType === AccountType.Personal) {
          if (isPersonalAccount) {
            const ok = await completeUserOnboarding(user)
            if (!ok) {
              setSubmitting(false)
            }
          } else if (isOrganizationAccount) {
            onboardUserInSessionStorage(user)
            push('/welcome/organization')
          }
        }
      }
    }
  )

  const getRedirectUrl = () => {
    const storedFrom = getLocationFrom()
    const storedClient = getClientQueryParam()
    if (teamMemberInvitation) {
      return '/'
    }
    return (storedFrom || '/') + (storedClient ? `?client=${storedClient}` : '')
  }

  useEffect(() => {
    if (!user?.onboarded) {
      const loadIndustryOptions = async () => setIndustryOptions(await configService.getFieldSetOptions('industry'))
      const loadCountryOptions = async () => setCountryOptions(await configService.getFieldSetOptions('country'))
      loadIndustryOptions()
      loadCountryOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return user?.onboarded ? (
    <Redirect to={getRedirectUrl()} />
  ) : (
    <AuthPage>
      <div className="onboarding-page">
        <form onSubmit={onSubmit} noValidate>
          <h1>Welcome to PreviewMe</h1>
          <div className="subtitle">
            To complete your account setup, please confirm some final details so we can tailor your experience to you.
          </div>
          <div className="name-row">
            <FormInput name="givenName" placeholder="First name" label="Your name*" inputRef={register} />
            <FormInput name="familyName" placeholder="Last name" error={nameError} inputRef={register} />
          </div>
          <Controller
            name="industries"
            errors={errors}
            control={control}
            as={
              <FormMultiSelect
                label="Which industries are you interested in?"
                placeholder="Select industries"
                options={industryOptions}
              />
            }
          />
          <Controller
            name="country"
            errors={errors}
            control={control}
            as={<FormSelect label="Country*" placeholder="Select country" options={countryOptions} />}
          />
          {!teamMemberInvitation && (
            <AccountTypeSelector
              isOrganizationAccount={isOrganizationAccount}
              isPersonalAccount={isPersonalAccount}
              setOrganizationAccount={setOrganizationAccount}
              setPersonalAccount={setPersonalAccount}
              inputRef={register}
              formErrors={errors}
              name="accountType"
              label={'Account Type*'}
            />
          )}
          <AgreementSelector inputRef={register} formErrors={errors} />
          <div className="buttons">
            <LoadingButton className="finish-button primary" isLoading={isSubmitting}>
              {isOrganizationAccount ? 'Next' : 'Finish'}
            </LoadingButton>
          </div>
        </form>
      </div>
    </AuthPage>
  )
}

export default OnboardingPage
