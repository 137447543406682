import React from 'react'

const MoveUpArrowIcon = () => (
  <svg className="icon" width="30px" height="30px" viewBox="0 0 30 30">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-312.500000, -30.00000)">
        <g transform="translate(310.000000, 25.000000)">
          <g>
            <g transform="translate(13.000000, 14.000000)" stroke="#25262A" strokeLinecap="round">
              <polyline
                strokeLinejoin="round"
                transform="translate(5.500000, 5.500000) rotate(-45.000000) translate(-5.500000, -5.500000) "
                points="2 2 9 2 9 9"
              ></polyline>
              <path
                d="M0.550252532,5.5 L13.5068359,5.5"
                transform="rotate(-90.000000) translate(-15.00000, 0.00000)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default MoveUpArrowIcon
