import ConfirmationDialog from '../../shared/ConfirmationDialog/ConfirmationDialog'
import React from 'react'
import './BulkUnpublishConfirmationDialog.scss'

interface Props {
  onClose: () => void
  onOk: () => void
}

const BulkUnpublishConfirmationDialog = function ({ onClose, onOk }: Props) {
  return (
    <ConfirmationDialog
      className="bulk-unpublish-confirmation-dialog"
      isShowing={true}
      isWarning
      cancelButtonText="No, cancel that"
      okButtonText={`Yes, Unpublish Previews`}
      title="Are you sure you want to Unpublish?"
      onClose={onClose}
      onOk={onOk}
    >
      By unpublishing your Previews, your links will no longer be valid and your audience will lose access to your
      content.
    </ConfirmationDialog>
  )
}

export default BulkUnpublishConfirmationDialog
