import React from 'react'

const ShareOnFacebookIcon = () => (
  <svg className="icon" width="50px" height="50px" viewBox="0 0 50 50">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-893.000000, -442.000000)">
        <ellipse cx="917.978166" cy="465" rx="20" ry="20" fill="#3B5998"></ellipse>
        <path
          d="M914.781659,461.762198 L916.322522,461.762198 L916.322522,460.315646 C916.322522,459.678054 916.339043,458.693525 916.819041,458.084494 C917.324256,457.439342 918.018166,457 919.211204,457 C921.155542,457 921.973799,457.267973 921.973799,457.267973 L921.588584,459.473924 C921.588584,459.473924 920.946847,459.294155 920.34685,459.294155 C919.747723,459.294155 919.211204,459.501645 919.211204,460.080434 L919.211204,461.762198 L921.669453,461.762198 L921.498149,463.916066 L919.211204,463.916066 L919.211204,471.4 L916.322522,471.4 L916.322522,463.916066 L914.781659,463.916066 L914.781659,461.762198 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </svg>
)

export default ShareOnFacebookIcon
