import { dateWithDaysAdjustment } from 'helpers/date'
import { PreviewStatus } from '../../state/types'

export const mockPreviewsOverTime = [
  {
    previewId: 'id',
    previewName: 'Example Preview 1',
    counts: [
      {
        day: dateWithDaysAdjustment(-4),
        count: 1,
      },
      {
        day: dateWithDaysAdjustment(-3),
        count: 4,
      },
      {
        day: dateWithDaysAdjustment(-2),
        count: 10,
      },
      {
        day: dateWithDaysAdjustment(-1),
        count: 6,
      },
      {
        day: new Date(),
        count: 4,
      },
    ],
  },
  {
    previewId: 'id',
    previewName: 'Example Preview2',
    counts: [
      {
        day: dateWithDaysAdjustment(-4),
        count: 8,
      },
      {
        day: dateWithDaysAdjustment(-3),
        count: 9,
      },
      {
        day: dateWithDaysAdjustment(-2),
        count: 12,
      },
      {
        day: dateWithDaysAdjustment(-1),
        count: 13,
      },
      {
        day: new Date(),
        count: 15,
      },
    ],
  },
]

export const mockTopVideos = [
  {
    previewName: 'Example Preview 1',
    videoChapter: {
      id: '1',
      name: 'Example Video 1',
      videoCategoryId: 'example',
      rawVideoStorageId: undefined,
    },
    viewCount: 12,
    engagementPercentage: 100,
    timesWatchedInFull: 6,
  },
  {
    previewName: 'Example Preview 2',
    videoChapter: {
      id: '2',
      name: 'Example Video 2',
      videoCategoryId: 'example',
      rawVideoStorageId: undefined,
    },
    viewCount: 5,
    engagementPercentage: 85,
    timesWatchedInFull: 1,
  },
  {
    previewName: 'Example Preview 3',
    videoChapter: {
      id: '3',
      name: 'Example Video 3',
      videoCategoryId: 'example',
      rawVideoStorageId: undefined,
    },
    viewCount: 1,
    engagementPercentage: 56,
    timesWatchedInFull: 1,
  },
]

export const mockLocationChartData = [
  {
    id: 'Auckland, New Zealand',
    label: 'Auckland, New Zealand',
    value: 25,
  },
  {
    id: 'Wellington, New Zealand',
    label: 'Wellington, New Zealand',
    value: 18,
  },
  {
    id: 'London, United Kingdom',
    label: 'London, United Kingdom',
    value: 14,
  },
  {
    id: 'Madrid, Spain',
    label: 'Madrid, Spain',
    value: 8,
  },
  {
    id: 'Sydney, Australia',
    label: 'Sydney, Australia',
    value: 6,
  },
]

export const mockPlatformChartData = [
  {
    id: 'desktop',
    label: 'desktop',
    value: 25,
  },
  {
    id: 'mobile',
    label: 'mobile',
    value: 19,
  },
  {
    id: 'tablet',
    label: 'tablet',
    value: 5,
  },
]

export const mockReferrerChartData = [
  {
    id: 'facebook',
    label: 'facebook',
    value: 25,
  },
  {
    id: 'linkedin',
    label: 'linkedin',
    value: 13,
  },
  {
    id: 'instagram',
    label: 'instagram',
    value: 5,
  },
  {
    id: 'university.com',
    label: 'university.com',
    value: 15,
  },
  {
    id: 'job-board.com',
    label: 'job-board.com',
    value: 15,
  },
]

const publishedStatus: PreviewStatus = 'published'
const archivedStatus: PreviewStatus = 'archived'
export const mockPreviewAnalytics = [
  {
    previewId: '1',
    title: 'Example Preview 1',
    status: publishedStatus,
    archived: false,
    firstPublishDate: dateWithDaysAdjustment(-3).toString(),
    loadingAnalytics: false,
    previewViews: 25,
    videoPlays: 12,
    documentDownloads: 3,
    linkClicks: 5,
  },
  {
    previewId: '2',
    title: 'Example Preview 2',
    status: publishedStatus,
    archived: false,
    firstPublishDate: dateWithDaysAdjustment(-8).toString(),
    loadingAnalytics: false,
    previewViews: 12,
    videoPlays: 5,
    documentDownloads: 3,
    linkClicks: 8,
  },
  {
    previewId: '3',
    title: 'Example Preview 3',
    status: archivedStatus,
    archived: false,
    firstPublishDate: dateWithDaysAdjustment(-12).toString(),
    loadingAnalytics: false,
    previewViews: 24,
    videoPlays: 4,
    documentDownloads: 3,
    linkClicks: 5,
  },
]
