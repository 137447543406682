import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { formatDate, isPast } from 'helpers/date'
import useInvitation from 'state/useInvitation'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import './InvitationDateInfo.scss'
import { useUrlParams } from '../../../helpers/urlParams'

type Props = {
  organizationId: string | undefined
  invitationId: string | undefined
  startDate?: string
  endDate?: string
  open?: boolean
  closed?: boolean
  isInvitationLimitReached: boolean
  setShowUpgradeDialog: (value: boolean) => void
}

const InvitationDateInfo: React.FC<Props> = ({
  organizationId,
  invitationId,
  startDate,
  endDate,
  open,
  closed,
  isInvitationLimitReached,
  setShowUpgradeDialog,
}) => {
  const [isDialogShowing, setDialogShowing] = useState(false)
  const { push } = useHistory()
  const { openNow, closeNow } = useInvitation(invitationId)
  const isEndDatePast = endDate && isPast(endDate)
  const isPending = startDate && !open && Date.now() < Date.parse(startDate)
  const status = isEndDatePast || closed ? 'closed' : isPending ? 'pending' : 'open'

  const openDialog = () => setDialogShowing(true)

  const onReopenClick = () => {
    if (isInvitationLimitReached) {
      setShowUpgradeDialog(true)
    } else {
      push(`/invitation/${organizationId}/${invitationId}/edit`)
    }
  }

  const onDialogOk = () => {
    if (isPending) {
      openNow()
    } else {
      closeNow()
    }
  }

  return (
    <div className="invitation-date-info">
      <div className="top-row">
        <div className={'status ' + status}>{status}</div>
        {!isPending && !endDate && (
          <button className="plain" onClick={openDialog}>
            Close now
          </button>
        )}
      </div>
      {isPending && (
        <div className="status-text-row">
          <div className="status-text">{'Opening: ' + formatDate(startDate!)}</div>
          <button className="plain" onClick={openDialog}>
            Open now
          </button>
        </div>
      )}
      {endDate && (
        <div className="status-text-row">
          <div className="status-text">{(isEndDatePast ? 'Closed on: ' : 'Closing: ') + formatDate(endDate)}</div>
          {!isPending && (
            <button className="plain" onClick={isEndDatePast ? onReopenClick : openDialog}>
              {isEndDatePast ? 'Reopen' : 'Close now'}
            </button>
          )}
        </div>
      )}
      <ConfirmationDialog
        className="invitation-date-confirmation-dialog"
        isShowing={isDialogShowing}
        cancelButtonText="Cancel"
        okButtonText={isPending ? 'Yes, open this invitation' : 'Yes, close this invitation'}
        title={isPending ? 'Open this invitation now?' : 'Close this invitation now?'}
        onClose={() => setDialogShowing(false)}
        onOk={onDialogOk}
      >
        {isPending
          ? 'This will take effect immediately and applicants will be able to respond to the invitation.'
          : 'This will take effect immediately and applicants will no longer be able to respond to the invitation.'}
      </ConfirmationDialog>
    </div>
  )
}

export default InvitationDateInfo
