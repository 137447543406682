import React from 'react'
import { lookupIcon } from 'helpers/lookupIcon'
import { parseWebsiteLink } from 'helpers/validation'
import Dropdown from 'components/shared/Dropdown/Dropdown'
import CrossIcon from 'assets/icons/CrossIcon'
import './WebsiteLinkInput.scss'

type Props = {
  name?: string
  value?: string
  onChange?: (value: string) => void
  removeFromList?: () => void
  label?: string
}

const linkTypes = [
  {
    value: 'homepage',
    displayValue: 'Home',
  },
  {
    value: 'previewme',
    displayValue: 'PreviewMe',
    placeholder: 'app.previewme.com/p/example',
  },
  {
    value: 'linkedin',
    displayValue: 'LinkedIn',
    placeholder: 'linkedin.com/in/example',
  },
  {
    value: 'twitter',
    displayValue: 'Twitter',
    placeholder: 'twitter.com/example',
  },
  {
    value: 'facebook',
    displayValue: 'Facebook',
    placeholder: 'facebook.com/example',
  },
  {
    value: 'instagram',
    displayValue: 'Instagram',
    placeholder: 'instagram.com/example',
  },
  {
    value: 'youtube',
    displayValue: 'YouTube',
    placeholder: 'youtube.com/example',
  },
  {
    value: 'github',
    displayValue: 'GitHub',
    placeholder: 'github.com/example',
  },
  {
    value: 'dribbble',
    displayValue: 'Dribbble',
    placeholder: 'dribbble.com/example',
  },
  {
    value: 'medium',
    displayValue: 'Medium',
    placeholder: 'medium.com/example',
  },
  {
    value: 'pinterest',
    displayValue: 'Pinterest',
    placeholder: 'pinterest.com/example',
  },
  {
    value: 'none',
    displayValue: 'No icon',
  },
]

const renderDropdownValue = (value?: string, displayValue?: string) =>
  value && displayValue ? (
    <div className="value-with-icon">
      {lookupIcon(value) || <div className="icon-placeholder" />}
      <span>{displayValue}</span>
    </div>
  ) : null

const WebsiteLinkInput: React.FC<Props> = ({ name, value, onChange, removeFromList, label }) => {
  const { icon, url } = parseWebsiteLink(value)
  const dropdownId = name + '-icon-dropdown'
  const inputId = name + '-url-input'

  const notifyChange = (i: string, u: string) => onChange?.(JSON.stringify({ icon: i, url: u }))

  return (
    <div>
      {label && (
        <div className="form-input-label-row">
          <label className="form-input-label">{label}</label>
        </div>
      )}
      <div className="website-link-input">
        <div className="icon-with-label">
          <div className="label-row">
            <label htmlFor={dropdownId}>Icon</label>
            {removeFromList && (
              <button type="button" className="remove-button plain phone" onClick={removeFromList}>
                <CrossIcon />
                <span>Remove</span>
              </button>
            )}
          </div>
          <Dropdown
            id={dropdownId}
            placeholder="Icon"
            selectedValue={icon}
            options={linkTypes}
            onChange={newValue => notifyChange(newValue, url)}
            renderOption={renderDropdownValue}
            renderSelectedOption={renderDropdownValue}
          />
        </div>
        <div className="url-with-label">
          <div className="label-row">
            <label htmlFor={inputId}>Link</label>
            {removeFromList && (
              <button type="button" className="remove-button plain" onClick={removeFromList}>
                <CrossIcon />
                <span>Remove</span>
              </button>
            )}
          </div>
          <input
            id={inputId}
            spellCheck={false}
            placeholder={(icon && linkTypes.find(l => l.value === icon)?.placeholder) || 'www.example.com'}
            value={url}
            onChange={e => notifyChange(icon, e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default WebsiteLinkInput
