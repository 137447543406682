import React, { useState } from 'react'
import { ContentBlockField } from 'state/types'
import useContentBlock from 'state/useContentBlock'
import { getChildren } from 'helpers/validation'
import * as imageService from 'services/imageService'
import FormListEditor from 'components/preview/FormListEditor/FormListEditor'
import ProfileImageSelector from 'components/shared/ProfileImageSelector/ProfileImageSelector'
import CrossIcon from 'assets/icons/CrossIcon'
import './PeopleInput.scss'
import FormFieldEditor from '../FormFieldEditor/FormFieldEditor'

type Props = {
  previewId: string
  blockIndex: number
  field: ContentBlockField
  register: any
  control: any
  errors?: any
  removeFromList?: () => void
}

const PeopleInput: React.FC<Props> = ({ previewId, blockIndex, field, register, control, errors, removeFromList }) => {
  const { updateValues } = useContentBlock(previewId, blockIndex)
  const { profileImage } = getChildren(field)
  const [profileImageStorageId, setProfileImageStorageId] = useState<string | undefined>(
    profileImage ? profileImage.value : undefined
  )
  const uploadImage = async (blob: Blob, onProgressChange: (progress: number) => void) => {
    const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
    if (status === 'success' && storageId && profileImage) {
      await updateValues([{ id: profileImage.id, value: storageId }])
      setProfileImageStorageId(storageId)
    }
  }

  const deleteImage = async () => {
    if (profileImage) {
      await updateValues([{ id: profileImage.id, value: '' }], true)
      setProfileImageStorageId(undefined)
    }
  }

  return (
    <div className="people-input">
      <div className="person-title-row">
        <div className="person-title">{field.formLabel}</div>
        {removeFromList && (
          <button type="button" className="remove-button plain" onClick={removeFromList}>
            <CrossIcon />
            <span>Remove</span>
          </button>
        )}
      </div>
      {field.children?.map((child, index) => {
        switch (child.controlType) {
          case 'List':
            return (
              <FormListEditor
                key={child.id}
                label={child.formLabel || ''}
                field={child}
                previewId={previewId}
                blockIndex={blockIndex}
                register={register}
                control={control}
              />
            )
          case 'ProfileImage':
            return (
              <div key={child.id}>
                <div className="person-profile-image-label">Profile image</div>
                <ProfileImageSelector
                  value={profileImageStorageId}
                  canDelete
                  warnBeforeDeleting={false}
                  uploadImage={uploadImage}
                  deleteImage={deleteImage}
                />
              </div>
            )
          default:
            return (
              <FormFieldEditor
                key={child.id}
                previewId={previewId}
                blockIndex={blockIndex}
                field={child}
                register={register}
                control={control}
                errors={errors}
              />
            )
        }
      })}
    </div>
  )
}

export default PeopleInput
