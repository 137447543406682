import React, { useEffect, useState } from 'react'
import { Preview, TagOption } from 'state/types'
import MultiSelect from './MultiSelect'
import './TagFieldEditor.scss'
import { isMobile } from 'helpers/browser'
import usePreview from 'state/usePreview'

type Props = {
  preview: Preview
  organizationId?: string
  updatePreviewSelectedTags: any
}

const TagFieldEditor: React.FC<Props> = ({ preview, organizationId, updatePreviewSelectedTags }) => {
  const [selectedValues, setSelectedValues] = useState(preview?.previewTagDetail?.values || [])
  const handleChange = async (newSelectedValues: string[]) => {
    setSelectedValues(newSelectedValues)
    if (organizationId) {
      const res = await updatePreviewSelectedTags(newSelectedValues)
    }
  }
  const tagOptions = preview?.previewTagDetail.tagOptions || []

  return (
    <form className="tag-block-editor" noValidate>
      <div className="form-fields">
        <div className="form-input-container">
          <div className="form-input-label-row">
            <label className="form-input-label">Tags:</label>
          </div>
          <MultiSelect
            selectedValues={selectedValues}
            options={tagOptions}
            searchable={!isMobile && tagOptions && tagOptions.length > 10}
            onChange={handleChange}
          />
        </div>
      </div>
    </form>
  )
}

export default TagFieldEditor
