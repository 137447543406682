import React from 'react'
import ChangePlanFeedbackPage from 'components/account/ChangePlanFeedbackPage/ChangePlanFeedbackPage'
import './ChangePlanErrorPage.scss'

const ChangePlanErrorPage = () => (
  <ChangePlanFeedbackPage title="Error - unable to change plan" className="change-plan-error-page">
    We were unable to charge your card and complete your plan change request. Please try again. If you continue to see
    this error, please <a href="https://previewme.com/contact">contact us</a>.
  </ChangePlanFeedbackPage>
)

export default ChangePlanErrorPage
