import React from 'react'
import { parseKeyValuePair } from 'helpers/validation'
import FormInput from 'components/shared/FormInput/FormInput'
import './KeyValuePairInput.scss'
import CrossIcon from '../../../assets/icons/CrossIcon'

type Props = {
  name?: string
  value?: string
  onChange?: (value: string) => void
  removeFromList?: () => void
}

const KeyValuePairInput: React.FC<Props> = ({ name, value, onChange, removeFromList }) => {
  const { key: keyInput, value: valueInput } = parseKeyValuePair(value)

  const notifyChange = (k: string, v: string) => onChange?.(JSON.stringify({ key: k, value: v }))

  return (
    <div className="key-value-pair-input">
      <div className="key-value-pair-key">
        <div className="label-row">
          <label>Subject</label>
          {removeFromList && (
            <button type="button" className="remove-button plain phone" onClick={removeFromList}>
              <CrossIcon />
              <span>Remove</span>
            </button>
          )}
        </div>
        <FormInput value={keyInput} onChange={e => notifyChange(e.target.value, valueInput)} />
      </div>
      <div className="key-value-pair-value">
        <div className="label-row">
          <label>Label</label>
          {removeFromList && (
            <button type="button" className="remove-button plain" onClick={removeFromList}>
              <CrossIcon />
              <span>Remove</span>
            </button>
          )}
        </div>
        <FormInput value={valueInput} onChange={e => notifyChange(keyInput, e.target.value)} />
      </div>
    </div>
  )
}

export default KeyValuePairInput
