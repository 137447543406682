import React from 'react'

const CogIcon = () => (
  <svg className="icon" width="27px" height="27px" viewBox="0 0 27 27">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g stroke="#01a3f8" strokeWidth="1.3">
        <path d="M18.3097,13.6293 C18.3097,16.2143 16.2137,18.3093 13.6297,18.3093 C11.0447,18.3093 8.9487,16.2143 8.9487,13.6293 C8.9487,11.0443 11.0447,8.9493 13.6297,8.9493 C16.2137,8.9493 18.3097,11.0443 18.3097,13.6293 Z"></path>
        <path d="M26.6085,15.4594 L26.6085,11.7994 L23.9835,11.5964 C23.7095,10.1924 23.1605,8.8874 22.3905,7.7434 L24.1015,5.7454 L21.5135,3.1574 L19.5155,4.8684 C18.3705,4.0974 17.0665,3.5494 15.6625,3.2744 L15.4595,0.6494 L11.7995,0.6494 L11.5965,3.2744 C10.1925,3.5494 8.8875,4.0974 7.7435,4.8684 L5.7455,3.1574 L3.1575,5.7454 L4.8685,7.7434 C4.0975,8.8874 3.5485,10.1924 3.2755,11.5964 L0.6505,11.7994 L0.6505,15.4594 L3.2755,15.6624 C3.5485,17.0654 4.0975,18.3704 4.8685,19.5154 L3.1575,21.5134 L5.7455,24.1014 L7.7435,22.3904 C8.8875,23.1604 10.1925,23.7094 11.5965,23.9834 L11.7995,26.6084 L15.4595,26.6084 L15.6625,23.9834 C17.0665,23.7094 18.3705,23.1614 19.5155,22.3904 L21.5135,24.1014 L24.1015,21.5134 L22.3905,19.5154 C23.1605,18.3704 23.7095,17.0654 23.9835,15.6624 L26.6085,15.4594 Z"></path>
      </g>
    </g>
  </svg>
)

export default CogIcon
