import React from 'react'
import { Link } from 'react-router-dom'
import { VideoChapter } from 'state/types'
import { formatVideoTime, getVideoDuration } from 'helpers/media'
import { formatDate } from 'helpers/date'
import VideoPlayer from 'components/video/VideoPlayer/VideoPlayer'
import InfoPopup from 'components/shared/InfoPopup/InfoPopup'
import GoldShieldIcon from 'assets/icons/GoldShieldIcon'
import './AnalyticsVideoCard.scss'

type Props = {
  chapter: VideoChapter
  chapterNumber?: number
  rank?: number
  previewId?: string
  previewTitle?: string
  firstPublishDate?: string
  views?: number
  engagementPercentage?: number
  timesWatchedInFull?: number
}

const AnalyticsVideoCard: React.FC<Props> = ({
  chapter,
  chapterNumber,
  rank,
  previewId,
  previewTitle,
  firstPublishDate,
  views,
  engagementPercentage,
  timesWatchedInFull,
}) => {
  const duration = getVideoDuration(chapter)
  const date = firstPublishDate ? formatDate(firstPublishDate) : undefined
  const storageId = chapter.rawVideoStorageId
  // trimmedMp4Url should be the same as originalMp4Url if the video is untrimmed
  const videoUrl = storageId ? chapter.trimmedMp4Url : undefined

  let engagementPercentageClass = ''
  if (engagementPercentage) {
    if (engagementPercentage > 90) {
      engagementPercentageClass = 'engagement-level-1'
    } else if (engagementPercentage > 70) {
      engagementPercentageClass = 'engagement-level-2'
    } else if (engagementPercentage > 25) {
      engagementPercentageClass = 'engagement-level-3'
    } else {
      engagementPercentageClass = 'engagement-level-4'
    }
  }

  return (
    <div className="analytics-video-card">
      {rank !== undefined && <div className="rank-marker">{rank}</div>}
      {chapterNumber !== undefined && <div className="chapter-number">{`Chapter ${chapterNumber}`}</div>}
      <div className="title-row">
        <span className="chapter-name">{chapter.name}</span>
        <span className="video-duration">{duration ? '[' + formatVideoTime(duration) + ']' : undefined}</span>
      </div>
      {previewTitle && (
        <Link className="preview-title" to={`/analytics/p/${previewId}`}>
          {previewTitle}
        </Link>
      )}

      {previewTitle && <div className="date">{date}</div>}
      <div className="spacer-above-video" />
      <VideoPlayer src={videoUrl} />
      <div className="video-stats">
        <div className="video-views">
          <div className="label">Clicks</div>
          <div className="value">
            <span>{views}</span>
            {views! > 0 && timesWatchedInFull! > 0 && (
              <InfoPopup
                customIcon={
                  <div className="complete-views-badge">
                    <GoldShieldIcon />
                    <div className="complete-views-value">{timesWatchedInFull}</div>
                  </div>
                }
              >
                Number of visits where this video or audio recording was watched to the end.
              </InfoPopup>
            )}
          </div>
        </div>
        {views! > 0 && engagementPercentage! > 0 && (
          <div className="video-engagement">
            <div className="label">Engagement</div>
            <div className={`value ${engagementPercentageClass}`}>{engagementPercentage}%</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalyticsVideoCard
