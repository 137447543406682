import React, { useState } from 'react'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import './TeleprompterScriptDialog.scss'
import CopyIcon from '../../../assets/icons/CopyIcon'
import { Tooltip } from '@material-ui/core'

type Props = {
  isShowing: boolean
  teleprompterScript: string
  onClose: () => void
}

const TeleprompterScriptDialog: React.FC<Props> = ({
  isShowing,

  teleprompterScript,
  onClose,
}) => {
  const [copyText, setCopyText] = useState<string>('')
  return (
    <CloseableDialog className="teleprompter-script-dialog" isShowing={isShowing} onClose={onClose}>
      <div className={'teleprompter-script'}>
        <div className={'title'}>
          <h2>Teleprompter script</h2>
          <Tooltip title={copyText} placement={'top'} onOpen={() => setCopyText('Copy Script')}>
            <button
              className={'copy-button plain'}
              onClick={e => {
                setCopyText('Script Copied!')
                e.preventDefault()
                navigator?.clipboard?.writeText(teleprompterScript)
              }}
            >
              <CopyIcon />
            </button>
          </Tooltip>
        </div>
        <p>{teleprompterScript}</p>
      </div>
    </CloseableDialog>
  )
}

export default TeleprompterScriptDialog
