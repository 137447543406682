import InvitationsListMobile from 'components/invitation/InvitationsListMobile/InvitationsListMobile'
import InvitationsTable from 'components/invitation/InvitationsTable/InvitationsTable'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import { InvitationsCreatorFilterOption, InvitationsStatusFilterOption, SubscriptionPlan } from 'helpers/enum'
import { filterInvitations } from 'helpers/filter'
import { getWorkspaceOrganizationId } from 'helpers/organization'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as invitationService from 'services/invitationService'
import { Invitation, Price, ViewingSelection } from 'state/types'
import useAuth from 'state/useAuth'
import useInvitation from 'state/useInvitation'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import { getCurrentPlan, isOnProfessionalOrEnterprisePlanOrAdmin } from '../../../helpers/subscription'
import AnalyticsCta from '../../analytics/AnalyticsCta/AnalyticsCta'
import InvitationsFilters from '../InvitationsFilters/InvitationsFilters'
import './InvitationsPage.scss'
import { InvitationUpgradeDialog } from '../InvitationUpgradeDialog/InvitationUpgradeDialog'
import useStore from '../../../state/useStore'
import { sortInvitations } from '../../../helpers/sort'
import ActiveArchivedHeaderDropdown from '../../shared/ActiveArchivedHeaderDropdown/ActiveArchivedHeaderDropdown'
import ArchivedInvitationsTable from '../ArchivedInvitationsTable/ArchivedInvitationsTable'
import useProduct from '../../../state/useProduct'

const InvitationsPage: React.FC = () => {
  const { user } = useAuth()
  const { clear } = useInvitation()
  const { push } = useHistory()
  const { state, dispatch } = useStore()
  const { organizationsLoaded } = useWorkspace(true)
  const { isSubscriptionLoading, subscription } = useSubscription(organizationsLoaded)
  const [isLoading, setLoading] = useState(true)
  const defaultStatusFilterOption: string = InvitationsStatusFilterOption.All
  const [selectedStatusFilterOption, setSelectedStatusFilterOption] = useState<string>(
    state.invitationsStatusFilter ? state.invitationsStatusFilter : defaultStatusFilterOption
  )
  const defaultCreatorFilter: string = InvitationsCreatorFilterOption.Everyone
  const [selectedCreatorFilterOption, setSelectedCreatorFilterOption] = useState<string>(
    state.invitationsCreatorFilter ? state.invitationsCreatorFilter : defaultCreatorFilter
  )
  const { editedSubscriptionName } = useSubscription(organizationsLoaded)
  const [viewingSelection, setViewingSelection] = useState<ViewingSelection>('active')
  const [allInvitations, setAllInvitations] = useState<Invitation[]>([])
  const [invitationsToShow, setInvitationsToShow] = useState<Invitation[]>([])
  const [showUpgradeDialog, setShowUpgradeDialog] = useState<boolean>(false)
  const [sortingBy, setSortingBy] = useState()
  const [sortingAsc, setSortingAsc] = useState(true)

  const [activeInvitationCount, setActiveInvitationCount] = useState(0)
  const [archivedInvitationCount, setArchivedInvitationCount] = useState(0)

  const loadInvitations = async (duplicate?: boolean) => {
    const workspaceOrganizationId = getWorkspaceOrganizationId()
    if (workspaceOrganizationId) {
      if (!duplicate) {
        // if duplicate, just need to fetch one invitation
        setLoading(true)
      }
      const { ok, data } = await invitationService.getInvitationSummaries2(
        workspaceOrganizationId,
        viewingSelection === 'archived'
      )
      if (ok && data) {
        setAllInvitations(data.invitationSummaries)
        setActiveInvitationCount(data.activeInvitationCount)
        setArchivedInvitationCount(data.archivedInvitationCount)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    loadInvitations()
    clear()
  }, [clear, viewingSelection])

  useEffect(() => {
    sortInvitations(allInvitations, sortingBy, sortingAsc)
    const filterAllInvitations = async () => {
      setLoading(true)
      if (user && allInvitations.length > 0) {
        const statusFilterOption: InvitationsStatusFilterOption =
          selectedStatusFilterOption as InvitationsStatusFilterOption
        const creatorFilterOption: InvitationsCreatorFilterOption =
          selectedCreatorFilterOption as InvitationsCreatorFilterOption
        setInvitationsToShow(filterInvitations(statusFilterOption, creatorFilterOption, allInvitations, user))
        dispatch({ type: 'SET_STATUS_FILTER', value: statusFilterOption })
        dispatch({ type: 'SET_CREATOR_FILTER', value: creatorFilterOption })
      }
      setLoading(false)
    }
    filterAllInvitations()
  }, [sortingBy, sortingAsc, allInvitations, selectedStatusFilterOption, selectedCreatorFilterOption])

  const handleClearAllFiltersButton = (): void => {
    setSelectedStatusFilterOption(defaultStatusFilterOption)
    setSelectedCreatorFilterOption(defaultCreatorFilter)
  }

  const navigateToCreate = () => {
    const openInvitations = allInvitations.filter(invitation => !invitation.closed).length
    if ((getCurrentPlan(subscription) === SubscriptionPlan.Professional.toString() ||
        getCurrentPlan(subscription) === SubscriptionPlan.Business.toString()) && openInvitations > 1) {
      setShowUpgradeDialog(true)
    } else {
      push('/invitations/create')
    }
  }

  const onSortingByChange = (sortingBy: string) => {
    setSortingBy(sortingBy)
  }

  const onSortingAscChange = (sortingAsc: boolean) => {
    setSortingAsc(sortingAsc)
  }

  const onArchiveChange = (invitationId: string, nowArchived: boolean) => {
    setAllInvitations(allInvitations.filter(i => i.id !== invitationId))
    setActiveInvitationCount(activeInvitationCount + (nowArchived ? -1 : 1))
    setArchivedInvitationCount(archivedInvitationCount + (nowArchived ? 1 : -1))
  }

  const subPlanName = editedSubscriptionName()

  return (
    <>
      {showUpgradeDialog && (
        <InvitationUpgradeDialog isShowing={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} />
      )}
      <PageFrame
        className="invitations-page"
        header={
          <>
            {!isSubscriptionLoading && isOnProfessionalOrEnterprisePlanOrAdmin(subscription, user!) ? (
              <>
                <ActiveArchivedHeaderDropdown
                  selection={viewingSelection}
                  activeCount={activeInvitationCount}
                  archivedCount={archivedInvitationCount}
                  onSelectionChange={setViewingSelection}
                  pageType="invitation"
                />
                <div className="create-invitation-link button primary" onClick={navigateToCreate}>
                  <div className="add-icon">+</div>
                  <span>Create Invitation</span>
                </div>
              </>
            ) : (
              ''
            )}
          </>
        }
      >
        {isSubscriptionLoading || !subPlanName ? (
          <LoadingSpinner container expand />
        ) : (
          <>
            {!isOnProfessionalOrEnterprisePlanOrAdmin(subscription, user!) ? (
              <AnalyticsCta
                title={`You're currently subscribed to the ${subPlanName} Plan`}
                body="'Invitations' is a feature of the Business or Professional Plan. You can upgrade to this plan anytime."
              />
            ) : allInvitations.length > 0 ? (
              <>
                <InvitationsFilters
                  selectedStatus={selectedStatusFilterOption}
                  selectedCreator={selectedCreatorFilterOption}
                  onStatusSelect={setSelectedStatusFilterOption}
                  onCreatorSelect={setSelectedCreatorFilterOption}
                  onClearAllSelect={handleClearAllFiltersButton}
                />
                {!isLoading ? (
                  <>
                    {viewingSelection === 'active' ? (
                      <InvitationsTable
                        invitations={invitationsToShow}
                        sortingBy={sortingBy}
                        sortingAsc={sortingAsc}
                        onSortingByChange={onSortingByChange}
                        onSortingAscChange={onSortingAscChange}
                        loadInvitations={loadInvitations}
                        onArchiveChange={onArchiveChange}
                      />
                    ) : (
                      <ArchivedInvitationsTable
                        invitations={invitationsToShow}
                        sortingBy={sortingBy}
                        sortingAsc={sortingAsc}
                        onSortingByChange={onSortingByChange}
                        onSortingAscChange={onSortingAscChange}
                        onArchiveChange={onArchiveChange}
                      />
                    )}

                    <div className="invitations-page-mobile-content">
                      <div className="create-invitation-link mobile button primary" onClick={navigateToCreate}>
                        <div className="add-icon">+</div>
                        <span>Create Invitation</span>
                      </div>
                      <InvitationsListMobile invitations={invitationsToShow} />
                    </div>
                  </>
                ) : (
                  <LoadingSpinner container expand />
                )}
              </>
            ) : !isLoading ? (
              <div className=" no-invitations-container">
                {viewingSelection === 'archived' ? (
                  <div className="archived">You have no archived Invitations</div>
                ) : (
                  <>
                    <div className=" no-invitations-inner-container">
                      <div className=" no-invitations-text">
                        You don't have any Invitations yet. Want people to create Previews and send them to you?
                      </div>
                      <Link className=" caps" to="/invitations/create">
                        Create an Invitation
                      </Link>
                    </div>
                    <div className=" no-invitations-illustration" />
                  </>
                )}
              </div>
            ) : (
              <LoadingSpinner container expand />
            )}
          </>
        )}
      </PageFrame>
    </>
  )
}

export default InvitationsPage
