import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@material-ui/core'
import './OverflowTooltip.scss'

const OverflowTooltip: React.FC = ({ children }) => {
  const [isOverflowed, setIsOverflow] = useState<boolean>(false)
  const textElementRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (textElementRef && textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth)
    }
  }, [])

  return (
    <>
      {typeof children === 'string' ? (
        <Tooltip title={children} disableHoverListener={!isOverflowed}>
          <div ref={textElementRef} className={'content'}>
            {children}
          </div>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default OverflowTooltip
