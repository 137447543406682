import Filter from 'components/shared/Filter/Filter'
import { InvitationResponsesLabelFilterOption } from 'helpers/enum'
import React from 'react'
import './InvitationsResponseFilters.scss'

type Props = {
  selectedLabel: string
  onLabelSelect: (value: string) => void
}

const InvitationsResponseFilters: React.FC<Props> = ({ selectedLabel, onLabelSelect }) => {
  return (
    <div className="invitations-response-filters">
      <Filter
        filterName="Label"
        selectedFilterOption={selectedLabel}
        filterOptions={Object.values(InvitationResponsesLabelFilterOption)}
        onSelect={onLabelSelect}
      />
    </div>
  )
}

export default InvitationsResponseFilters
