import { mockPreviewAnalytics } from 'components/analytics/AnalyticsMockData'
import AnalyticsPreviewCard from 'components/analytics/AnalyticsPreviewCard/AnalyticsPreviewCard'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { PLAY_VIDEO, VIEW_DOCUMENT, VIEW_LINK, VIEW_PREVIEW } from 'helpers/events'
import { sortPreviewSummaries } from 'helpers/sort'
import { isOnPaymentPlanOrAdmin } from 'helpers/subscription'
import { getDashboardTitles } from 'helpers/titles'
import React, { useEffect, useState } from 'react'
import * as analyticsService from 'services/analyticsService'
import * as previewService from 'services/previewService'
import { PreviewAnalytics } from 'state/types'
import useAuth from 'state/useAuth'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import './AnalyticsBreakdown.scss'

const AnalyticsBreakdown: React.FC = () => {
  const { user } = useAuth()
  const { isSubscriptionLoading, subscription } = useSubscription(true)
  const [isLoading, setLoading] = useState(true)
  const [previewAnalytics, setPreviewAnalytics] = useState<PreviewAnalytics[]>([])

  useEffect(() => {
    const load = async () => {
      setLoading(true)
      if (!isOnPaymentPlanOrAdmin(subscription, user!)) {
        setPreviewAnalytics(mockPreviewAnalytics)
        setLoading(false)
        return
      }

      const { ok, data } = await previewService.getMyPreviews()
      setLoading(false)

      if (ok && data) {
        const previews = sortPreviewSummaries(data.previewSummaries)!.map(p => ({
          previewId: p.id,
          ...getDashboardTitles(p),
          status: p.status,
          archived: p.archived,
          firstPublishDate: p.firstPublishDate,
          loadingAnalytics: true,
        }))

        setPreviewAnalytics(previews)

        const updates = await Promise.all(
          previews.map(async p => {
            const { ok, data: analyticsData } = await analyticsService.getAnalyticsSummary('all-time', p.previewId)
            if (ok && analyticsData) {
              const lookup = (eventType: string) => analyticsData.find(e => e.eventType === eventType)?.count || 0

              const previewViews = lookup(VIEW_PREVIEW)
              const videoPlays = lookup(PLAY_VIDEO)
              const documentDownloads = lookup(VIEW_DOCUMENT)
              const linkClicks = lookup(VIEW_LINK)

              return { loadingAnalytics: false, previewViews, videoPlays, documentDownloads, linkClicks }
            } else {
              return {}
            }
          })
        )

        setPreviewAnalytics(previews.map((p, i) => ({ ...p, ...updates[i] })))
      }
    }
    load()
  }, [user, subscription])

  return isSubscriptionLoading || isLoading ? (
    <LoadingSpinner container expand />
  ) : (
    <div className={'analytics-breakdown ' + (!isOnPaymentPlanOrAdmin(subscription, user!) ? 'obfuscated' : '')}>
      {previewAnalytics.length > 0 ? (
        <div className="analytics-preview-cards">
          {previewAnalytics.map(p => (
            <AnalyticsPreviewCard key={p.previewId} {...p} obfuscated={!isOnPaymentPlanOrAdmin(subscription, user!)} />
          ))}
        </div>
      ) : (
        <div className="no-previews-message">You have no Previews yet.</div>
      )}
    </div>
  )
}

export default AnalyticsBreakdown
