import PublishedPreviewDocuments from 'components/preview/PublishedPreviewDocuments/PublishedPreviewDocuments'
import PublishedPreviewFormBlock from 'components/preview/PublishedPreviewFormBlock/PublishedPreviewFormBlock'
import PublishedPreviewHighlights from 'components/preview/PublishedPreviewHighlights/PublishedPreviewHighlights'
import PublishedPreviewVideo from 'components/preview/PublishedPreviewVideo/PublishedPreviewVideo'
import { getProfileImageUrl } from 'helpers/cloudinary'
import { getPublishedPreviewTitles } from 'helpers/titles'
import { useBodyColor } from 'helpers/useBodyColor'
import { fieldValueExistsAndDisplayedInPublishedPreview } from 'helpers/validation'
import React, { useState, useEffect } from 'react';
import { ContentBlock, Preview } from 'state/types'
import useWorkspace from 'state/useWorkspace'
import './PublishedPreview.scss'
import '../PublishedPreview/PublishedPreview.scss'
import ArrowBottom from 'assets/icons/arrow-bottom.png'
import { useWindowScroll  } from 'react-use';

type Props = {
  preview: Preview,
  classData:any,
  planType:string
}

const hgmNotesLabel = 'If there is anything that affected your grades please explain here'

const renderBlock = (
  block: ContentBlock,
  i: number,
  columnBlocks: ContentBlock[],
  previewId: string,
  trackEvents: boolean
) => {
  const isAfterDocuments = i > 0 && columnBlocks[i - 1].contentBlockType?.name === 'Documents'
  return block.contentBlockType?.name === 'Documents' ? (
    <PublishedPreviewDocuments key={i} block={block} previewId={previewId} trackEvents={trackEvents} />
  ) : block.formLabel === 'Application requirements' ? (
    <PublishedPreviewFormBlock
      key={i}
      block={block}
      fieldsToSuppress={[hgmNotesLabel]}
      noBorder={isAfterDocuments}
      previewId={previewId}
      trackEvents={trackEvents}
    />
  ) : (
    <PublishedPreviewFormBlock
      key={i}
      block={block}
      noBorder={isAfterDocuments}
      previewId={previewId}
      trackEvents={trackEvents}
    />
  )
}

const PublishedPreview: React.FC<Props> = ({ preview,classData,planType }) => {
  const { trackEventsBasedOnWorkspace } = useWorkspace(true)
  const trackEvents = trackEventsBasedOnWorkspace(preview)
  const [showArrow, setShowArrow] = useState(false);
  const { y } = useWindowScroll();
  const contentBlocks = preview.contentBlocks || []
  const allFields = contentBlocks.flatMap(block => block.contentBlockFields)
  const videoBlock = contentBlocks.find(cb => cb.contentBlockType?.name === 'Video')
  const videoChapters =
    videoBlock?.contentBlockFields.map(f => ({
      ...f.videoChapter!,
      contentBlockFieldId: f.id,
    })) || []

  // Only show content blocks that have at least one valid field.
  const otherBlocks = contentBlocks.filter(
    cb =>
      cb.contentBlockType.name !== 'Video' && cb.contentBlockFields.some(fieldValueExistsAndDisplayedInPublishedPreview)
  )

  const profileImage = allFields.find(f => f.controlType === 'ProfileImage')?.value
  const { title, subtitle } = getPublishedPreviewTitles(preview)

  const firstColumnBlocks: ContentBlock[] = []
  const secondColumnBlocks: ContentBlock[] = []

  // Put HGM notes field in a separate block at the end.
  const hgmNotesValue = allFields.find(field => field.formLabel === hgmNotesLabel)?.value
  if (hgmNotesValue) {
    otherBlocks.push({ formLabel: 'Notes', contentBlockFields: [{ value: hgmNotesValue }] } as any)
  }

  const isHgmPromo = preview?.templateId === '90258adc-710b-4510-a721-2a38979527f7'
  if (isHgmPromo) {
    otherBlocks.splice(1, 0, {
      formLabel: 'Apply here',
      contentBlockFields: [{ value: 'https://www.hgmlegal.com/careers' }],
    } as any)
  }

  const hackAdjustmentForTalent =
    otherBlocks.length === 4 &&
    preview.name?.includes('Talent') &&
    !otherBlocks.some(b => b.contentBlockType.name === 'Documents')
      ? 1
      : 0

  // Split the other blocks into two columns.
  const firstColumnBlockCount = Math.ceil(otherBlocks.length / 2.0) + hackAdjustmentForTalent
  for (let i = 0; i < Math.min(firstColumnBlockCount, otherBlocks.length); i++) {
    firstColumnBlocks.push(otherBlocks[i])
  }
  for (let i = firstColumnBlockCount; i < otherBlocks.length; i++) {
    secondColumnBlocks.push(otherBlocks[i])
  }

  let bodyColor: 'darkblue' | 'cream' | 'lightCream' | 'white' |  'light' | 'dark'
    | 'contrast' | 'forest' |  'sky' | 'business-blue' |'custom-workspace-theme'
  if (preview.theme === 'dark') {
    bodyColor = 'darkblue'
  } else if (preview.theme === 'forest') {
    bodyColor = 'lightCream'
  } else {
    bodyColor = 'white'
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    (async () => {
      const scrollOffset = 150;

      if (y > scrollOffset) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    })();
  }, [y]);


  useBodyColor(bodyColor)

  return (
    <div className={`published-preview ${classData.root} ` + preview.theme}>
      <div className="section video-section">
        <div className="section-content">
          <div className="preview-title">{title}</div>
          <div className="preview-subtitle">{subtitle}</div>
        </div>
        <PublishedPreviewHighlights allFields={allFields} />
        <div className="section-content">
          <PublishedPreviewVideo
            videoChapters={videoChapters}
            previewId={preview.id}
            contentBlockId={videoBlock?.id}
            trackEvents={trackEvents}
          />
        </div>

      </div>

      <div className="section details-section">
        <div className={`scroll-container ${showArrow ? 'visible' : 'hidden'}`}
             onClick={scrollToBottom}>
          <img className="arrow-scroll" src={ArrowBottom} />

        </div>
        <div className="section-content">
          {profileImage && (
            <div className="published-profile-image">
              <img src={getProfileImageUrl(profileImage)} alt="" />
            </div>
          )}
          <div className="column">
            {firstColumnBlocks.map((b, i) => renderBlock(b, i, firstColumnBlocks, preview.id, trackEvents))}
          </div>
          <div className="column">
            {secondColumnBlocks.map((b, i) => renderBlock(b, i, secondColumnBlocks, preview.id, trackEvents))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishedPreview
