import ChangePaymentMethodSuccessPage from 'components/account/ChangePaymentMethodSuccessPage/ChangePaymentMethodSuccessPage'
import ChangePlanErrorPage from 'components/account/ChangePlanErrorPage/ChangePlanErrorPage'
import ChangePlanPage from 'components/account/ChangePlanPage/ChangePlanPage'
import ChangePlanSuccessPage from 'components/account/ChangePlanSuccessPage/ChangePlanSuccessPage'
import OnboardingFromInvitationPage from 'components/account/OnboardingFromInvitationPage/OnboardingFromInvitationPage'
import OnboardingPage from 'components/account/OnboardingPage/OnboardingPage'
import OrganizationOnboardingPage from 'components/account/OrganizationOnboardingPage/OrganizationOnboardingPage'
import PlanSelectionPage from 'components/account/PlanSelectionPage/PlanSelectionPage'
import BrowserCheck from 'components/app/BrowserCheck/BrowserCheck'
import NavRoutes, { paths as navPaths } from 'components/app/NavRoutes/NavRoutes'
import NotFoundPage from 'components/app/NotFoundPage/NotFoundPage'
import OnboardedRoute from 'components/app/OnboardedRoute/OnboardedRoute'
import RouteTracker, { initGA } from 'components/app/RouteTracker/RouteTracker'
import SecuredRoute from 'components/app/SecuredRoute/SecuredRoute'
import DocumentAssetsPage from 'components/assets/DocumentAssetsPage/DocumentAssetsPage'
import VideoAssetsPage from 'components/assets/VideoAssetsPage/VideoAssetsPage'
import AuthRoutes from 'components/auth/AuthRoutes/AuthRoutes'
import CreateInvitationPage from 'components/invitation/CreateInvitationPage/CreateInvitationPage'
import CreateInvitationSuccessPage from 'components/invitation/CreateInvitationSuccessPage/CreateInvitationSuccessPage'
import InvitationExamplePage from 'components/invitation/InvitationExamplePage/InvitationExamplePage'
import InvitationSplashPage from 'components/invitation/InvitationSplashPage/InvitationSplashPage'
import InvitationTemplatePage from 'components/invitation/InvitationTemplatePage/InvitationTemplatePage'
import CreatePreviewPage from 'components/preview/CreatePreviewPage/CreatePreviewPage'
import LimitReachedPage from 'components/preview/LimitReachedPage/LimitReachedPage'
import PublishedPreviewPage from 'components/preview/PublishedPreviewPage/PublishedPreviewPage'
import TemplateExamplePage from 'components/preview/TemplateExamplePage/TemplateExamplePage'
import ScreenRecorderPage from 'components/video/ScreenRecorderPage/ScreenRecorderPage'
import VideoRecorderPage from 'components/video/VideoRecorderPage/VideoRecorderPage'
import VideoTrimEditorPage from 'components/video/VideoTrimEditorPage/VideoTrimEditorPage'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

type Props = {
  userId?: string
}

const Routes: React.FC<Props> = ({ userId }) => (
  <Router>
    <BrowserCheck>
      {initGA({ userId }) && <RouteTracker />}
      <Switch>
        <Route path="/auth" component={AuthRoutes} />
        <Route exact path={navPaths} component={NavRoutes} />
        <Route exact path={'/preview/:previewId'} component={PublishedPreviewPage} />
        <SecuredRoute exact path="/welcome" component={OnboardingPage} />
        <SecuredRoute exact path="/o/:organizationId/i/:invitationCode" component={OnboardingFromInvitationPage} />
        <SecuredRoute exact path="/welcome/organization" component={OrganizationOnboardingPage} />
        <OnboardedRoute exact path="/create" component={CreatePreviewPage} />
        <OnboardedRoute exact path="/create/template/:templateId" component={TemplateExamplePage} />
        <OnboardedRoute exact path="/edit/:previewId/video/:index/record/:fieldIndex" component={VideoRecorderPage} />
        <OnboardedRoute exact path="/edit/:previewId/video/:index/screen/:fieldIndex" component={ScreenRecorderPage} />
        <OnboardedRoute exact path="/edit/:previewId/video/:index/trim/:fieldIndex/:saveOnExit" component={VideoTrimEditorPage} />
        <OnboardedRoute exact path="/edit/:previewId/video/:index/trim/:fieldIndex/" component={VideoTrimEditorPage} />
        <OnboardedRoute exact path="/edit/:previewId/video/:index/assets/:fieldIndex" component={VideoAssetsPage} />
        <OnboardedRoute exact path="/limitReached" component={LimitReachedPage} />
        <OnboardedRoute
          exact
          path="/edit/:previewId/document/:index/assets/:fieldIndex"
          component={DocumentAssetsPage}
        />
        <OnboardedRoute exact path="/invitations/create" component={InvitationTemplatePage} />
        <OnboardedRoute exact path="/invitations/example/:templateId" component={InvitationExamplePage} />
        <OnboardedRoute exact path="/invitations/template/:templateId" component={CreateInvitationPage} />
        <OnboardedRoute exact path="/invitations/created/:invitationId" component={CreateInvitationSuccessPage} />
        <OnboardedRoute exact path="/i/:invitationId" component={InvitationSplashPage} />
        <OnboardedRoute exact path="/plan/select" component={PlanSelectionPage} />
        <OnboardedRoute exact path="/plan/change" component={ChangePlanPage} />
        <OnboardedRoute exact path="/plan/success" component={ChangePlanSuccessPage} />
        <OnboardedRoute exact path="/plan/error" component={ChangePlanErrorPage} />
        <OnboardedRoute exact path="/payment/success" component={ChangePaymentMethodSuccessPage} />
        <Route path="/" component={NotFoundPage} />
      </Switch>
    </BrowserCheck>
  </Router>
)

export default Routes
