import ErrorPage from 'components/shared/ErrorPage/ErrorPage'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { Country, SubscriptionPlan } from 'helpers/enum'
import { useUrlParams } from 'helpers/urlParams'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import ChangePlanPageLayout from '../ChangePlanPageLayout/ChangePlanPageLayout'
import useProduct from '../../../state/useProduct'
import { Price } from '../../../state/types'
import useStore from '../../../state/useStore'
import useAuth from '../../../state/useAuth'
import { getCurrentPlan } from '../../../helpers/subscription'
import { getCurrentWorkspace } from '../../../helpers/organization'

const ChangePlanConfirmationPage = () => {
  const { to } = useUrlParams('to')
  const { from } = useUrlParams('from')
  const { toggle } = useUrlParams("toggle")
  const { push } = useHistory()
  const currentWorkspace = getCurrentWorkspace()
  const {
    subscriptionError,
    isSubscriptionLoading,
    subscriptionLoaded,
    isPreviewSubscriptionLoading,
    previewSubscriptionError,
    previewSubscriptionLoaded,
    newSubscription,
    newProduct,
    subscription,
    changePlan,
    editedSubscriptionName,
    editedNextSubscriptionName,
  } = useSubscription(true, true, to, false)
  const index =  0
  const VAT = currentWorkspace?.country === 'GBR' ? ' + VAT' : ''
  const { state } = useStore()
  const isUpgrading =
    subscription && newProduct ? subscription.plan.index < newProduct.index : false
  const isDowngrading =
    subscription && newProduct ? subscription.plan.index > newProduct.index  : false
  const isReactivating = !isUpgrading && !isDowngrading
  const isLosingInvitations =
    (subscription?.plan.name === SubscriptionPlan.Enterprise || subscription?.plan.name === SubscriptionPlan.Professional || subscription?.plan.name === SubscriptionPlan.Business) &&
    (newProduct?.name === SubscriptionPlan.Plus || newProduct?.name === SubscriptionPlan.Basic || newProduct?.name === SubscriptionPlan.Solo)
  const modifyPlan = async () => {
    if (newSubscription) {
      const { ok } = await changePlan(newSubscription, state.billingType)
      if (ok) {
        push(`/plan/success?from=${from}`)
      } else {
        push(`/plan/error?from=${from}`)
      }
    }
  }


  const paymentCadence = (
    (newProduct?.name === SubscriptionPlan.Business ||
      newProduct?.name === SubscriptionPlan.Professional ||
      newProduct?.name === SubscriptionPlan.Enterprise )) ? 'Quarter' : 'Monthly'

  return isSubscriptionLoading && isPreviewSubscriptionLoading ? (
    <LoadingSpinner fullScreen />
  ) : subscriptionError || previewSubscriptionError ? (
    <ErrorPage titleText="Unable to load subscription">
      Your subscription information is not currently available. Please try again later.
    </ErrorPage>
  ) : (
    <ChangePlanPageLayout
      title="Let's complete your plan change!"
      backLink={from}
      cancelLink={from}
      plan={newProduct}
      price={`${newProduct ? 'US$' +  newProduct.prices[index].amount : ''}`}
      contentLoading={!(subscriptionLoaded && previewSubscriptionLoaded)}
      onConfirm={async () => modifyPlan()}
      editedNewPlanName={editedNextSubscriptionName(index)}
      toggle={toggle}
      currentWorkspace={currentWorkspace}
    >
      {newSubscription &&
        newProduct &&
        (isUpgrading ? (
          <>
            <p>
              <span className="bold">
                A one-off payment of {'US$' + newSubscription.amountDue}
              </span>{' '}
              will be charged immediately for the upgrade to our {editedNextSubscriptionName(index)} Plan for the current billing period.
              Your subscription automatically renews until cancelled.
            </p>
          </>
        ) : isDowngrading ? (
          <>
            <p>
              You're downgrading from the {editedSubscriptionName()} Plan to the {editedNextSubscriptionName(index)} Plan. The downgrade
              will occur at the end of your current billing period.
            </p>
            {isLosingInvitations && (
              <p>
                By downgrading you will lose access to the Invitations feature and any associated applicant Previews.
                Not to worry, your data will be saved and is accessible when you re-subscribe to either our Enterprise, Professional
                or Business Plan.
              </p>
            )}
          </>
        ) : isReactivating ? (
          <>
            <p>
              Your {(editedSubscriptionName() !== SubscriptionPlan.Professional) ? editedSubscriptionName() : "Business"} Plan will be reactivated. Your subscription automatically renews until cancelled.
            </p>
          </>
        ) : (
          ''
        ))}
    </ChangePlanPageLayout>
  )
}

export default ChangePlanConfirmationPage
