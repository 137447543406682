import { Box } from '@material-ui/core'
import React from 'react'
import MenuOption from '../MenuOption/MenuOption'
import './DropdownMenuOption.scss'

type Props = {
  optionName: string
  showBottomBorder?: boolean
  showCheckbox?: boolean
  selectedOption?: boolean
  onSelect: (value: any) => void
}

const DropdownMenuOption: React.FC<Props> = ({
  optionName,
  showBottomBorder,
  showCheckbox,
  selectedOption,
  onSelect,
  children
}) => {
  return (
    <div className="dropdown-menu-option">
      {showBottomBorder ? (
        <Box borderBottom={1}>
          <MenuOption
            optionName={optionName}
            onSelect={onSelect}
            showCheckbox={showCheckbox}
            selectedOption={selectedOption}
          >
            {children}
          </MenuOption>
        </Box>
      ) : (
        <MenuOption
          optionName={optionName}
          onSelect={onSelect}
          showCheckbox={showCheckbox}
          selectedOption={selectedOption}
        >
          {children}
        </MenuOption>
      )}
    </div>
  )
}

export default DropdownMenuOption
