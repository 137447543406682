import React from 'react'
import { ContentBlockField, ImperialLength, ImperialLengthSmall } from 'state/types'
import {
  fieldValueExists,
  parseDropdownFreeText,
  parseLink,
  parseMonthYearString,
  parseMultiSelect,
  validateAvailability,
} from 'helpers/validation'
import { getPluralisedTitle } from 'helpers/titles'
import { createFullLink, formatDisplayedLink } from 'helpers/links'
import { formatDate } from 'helpers/date'
import PublishedPreviewAvailability from 'components/preview/PublishedPreviewAvailability/PublishedPreviewAvailability'
import {
  convertMetricCentimetreLengthToImperial,
  convertMetricLengthToImperial,
  convertMetricWeightToImperial,
} from './units'
import { LengthUnits, WeightUnits } from './enum'
import { parseISO } from 'date-fns'
import PublishedPreviewWebsiteLinks from '../components/preview/PublishedPreviewWebsiteLinks/PublishedPreviewWebsiteLinks'
import PublishedPreviewKeyValuePair from '../components/preview/PublishedPreviewKeyValuePair/PublishedPreviewKeyValuePair'
import PublishedPreviewDropdownFreeText from '../components/preview/PublishedPreviewDropdownFreeText/PublishedPreviewDropdownFreeText'

export const formatDropdownFreeText = (field: ContentBlockField, highlight?: boolean) => {
  let { dropdown, freeText } = parseDropdownFreeText(field.value)
  const dropdownValue = dropdown && field.options && field.options[dropdown]
  freeText = freeText.trim()

  const isLocation = field.formLabel?.toLowerCase().includes('location')
  const canSeparateWithComma = isLocation && freeText && freeText.split(' ').length < 5 && !freeText.includes(',')

  if (field.previewLabel === 'Location') {
    return dropdownValue + (freeText ? ', ' + freeText : '')
  }

  if (isLocation && freeText && highlight) {
    return freeText // Just return freeText part for location highlight, too much useless information otherwise.
  } else if (canSeparateWithComma) {
    return freeText + (dropdownValue ? ', ' + dropdownValue : '')
  } else {
    return dropdownValue + (dropdownValue && freeText && ': ') + freeText
  }
}

export const formatDuration = (field: ContentBlockField, highlight?: boolean) => {
  let { dropdown, freeText } = parseDropdownFreeText(field.value)
  freeText = freeText.trim()

  if (dropdown && freeText) {
    return freeText + ' ' + dropdown
  }
}

export const formatDropdownWithOtherFreeText = (field: ContentBlockField, highlight?: boolean) => {
  let { dropdown, freeText } = parseDropdownFreeText(field.value)
  const dropdownValue = dropdown && field.options && field.options[dropdown]
  freeText = freeText.trim()

  if (dropdownValue?.toLowerCase() === 'other' && freeText) {
    return freeText
  } else {
    return dropdownValue
  }
}

export const formatMonthYear = (value: string) => {
  const { month, year } = parseMonthYearString(value)
  return month && year ? `${month} / ${year}` : null
}

export const formatMultiSelectDropdown = (field: ContentBlockField) => {
  let formattedValue = null
  if (field.value) {
    const selectedOptions = parseMultiSelect(field.value)
    if (selectedOptions.length > 0) {
      formattedValue = selectedOptions
        .map(value => field.options?.[value])
        .filter(displayValue => !!displayValue)
        .join(', ')
      if (field.previewLabel && selectedOptions.length > 1) {
        field.previewLabel = getPluralisedTitle(field.previewLabel)
      }
    }
  }
  return formattedValue
}

export const formatPreviewLabel = (field: ContentBlockField) => {
  if (field.controlType === 'List' && fieldValueExists(field)) {
    return <div className="label">{field.previewLabel}</div>
  } else if (field.previewLabel && field.value) {
    if ((field.controlType === 'Length' || field.controlType === 'Weight') && isNaN(parseFloat(field.value))) {
      return
    } else if (field.controlType === 'TextWithLabel') {
      return <div className="text-with-label label">{`${field.previewLabel}`}</div>
    }
    return <div className="label">{field.previewLabel}</div>
  }
}

// Any fields handled here can be displayed at the top level and also inside a group.
export const formatPreviewValue = (
  field: ContentBlockField,
  highlight: boolean,
  trackLinkClick?: (fieldId: string) => void
) => {
  let formattedValue: any = field.value
  if (field.value) {
    if (field.controlType === 'DateOfBirth') {
      const dob: Date = parseISO(field.value)
      const currentDate: Date = new Date()

      let age = currentDate.getFullYear() - dob.getFullYear()
      const month = currentDate.getMonth() - dob.getMonth()

      if (month < 0 || (month === 0 && currentDate.getDate() < dob.getDate())) {
        age--
      }
      formattedValue =
        age +
        'yrs, ' +
        dob.getDate() +
        ' ' +
        dob.toLocaleString('default', { month: 'long' }).substring(0, 3) +
        ' ' +
        dob.getFullYear()
    } else if (field.controlType === 'Date') {
      const dob: Date = parseISO(field.value)
      formattedValue =
        dob.getDate() + ' ' + dob.toLocaleString('default', { month: 'long' }).substring(0, 3) + ' ' + dob.getFullYear()
    } else if (field.controlType === 'Length') {
      const metricValue: number = parseFloat(field.value)
      const metricValueToDisplay: string = metricValue.toFixed(2) + LengthUnits.Meter
      const imperialValue: ImperialLength = convertMetricLengthToImperial(metricValue)
      const imperialValueToDisplay: string = imperialValue.feet + "'" + imperialValue.inch + "''"
      if (!isNaN(metricValue)) {
        formattedValue = metricValueToDisplay + ' / ' + imperialValueToDisplay
      } else {
        formattedValue = undefined
      }
    } else if (field.controlType === 'LengthSmall') {
      const metricValue: number = parseFloat(field.value)
      const metricValueToDisplay: string = metricValue.toFixed(2) + LengthUnits.Centimetre
      const imperialValue: ImperialLengthSmall = convertMetricCentimetreLengthToImperial(metricValue)
      const imperialValueToDisplay: string = imperialValue.inch + "''"
      if (!isNaN(metricValue)) {
        formattedValue = metricValueToDisplay + ' / ' + imperialValueToDisplay
      } else {
        formattedValue = undefined
      }
    } else if (field.controlType === 'Weight') {
      const metricValue: number = parseFloat(field.value)
      const metricValueToDisplay: string = metricValue.toFixed(2) + WeightUnits.Kilogram
      const imperialValue: number = convertMetricWeightToImperial(metricValue)
      const imperialValueToDisplay: string = imperialValue.toFixed(2) + WeightUnits.Pound
      if (!isNaN(metricValue)) {
        formattedValue = metricValueToDisplay + ' / ' + imperialValueToDisplay
      } else {
        formattedValue = undefined
      }
    } else if (field.controlType === 'MultiSelectDropdown') {
      formattedValue = formatMultiSelectDropdown(field)
    } else if (field.controlType === 'Dropdown' && field.options) {
      formattedValue = field.options[field.value!]
    } else if (field.controlType === 'Email') {
      formattedValue = <a href={`mailto:${field.value}`}>{field.value}</a>
    } else if (field.controlType === 'TextArea') {
      formattedValue = <div className="text-area">{field.value}</div>
    } else if (field.controlType === 'InvitationLink') {
      const { text, url } = parseLink(field.value)
      formattedValue = (
        <a
          className="apply-button button primary"
          href={createFullLink(url)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackLinkClick?.(field.id)}
        >
          {formatDisplayedLink(text)}
        </a>
      )
    } else if (field.controlType === 'Website' || field.value?.startsWith('http')) {
      formattedValue = (
        <PublishedPreviewWebsiteLinks
          fields={[field]}
          hasLabel={!!field.previewLabel}
          trackLinkClick={trackLinkClick!}
        />
      )
    } else if (field.controlType === 'Link') {
      const { text, url } = parseLink(field.value)
      formattedValue = (
        <a
          href={createFullLink(url)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackLinkClick?.(field.id)}
        >
          {formatDisplayedLink(text)}
        </a>
      )
    } else if (field.controlType === 'MonthYear') {
      formattedValue = formatMonthYear(field.value!)
    } else if (field.controlType === 'Availability') {
      formattedValue = validateAvailability(field.value!) && (
        <PublishedPreviewAvailability value={field.value} highlight={highlight} />
      )
    } else if (field.controlType === 'DatePicker') {
      formattedValue = formatDate(field.value!)
    } else if (field.controlType === 'Duration') {
      formattedValue = formatDuration(field, highlight)
    } else if (field.controlType === 'DropdownFreeText') {
      formattedValue = formatDropdownFreeText(field, highlight)
    } else if (field.controlType === 'DropdownWithOtherFreeText') {
      formattedValue = formatDropdownWithOtherFreeText(field, highlight)
    }
  } else if (field.controlType === 'List' && trackLinkClick) {
    if (field.children && field.children[0].controlType === 'Website') {
      formattedValue = (
        <PublishedPreviewWebsiteLinks fields={field.children} hasLabel={false} trackLinkClick={trackLinkClick} />
      )
    } else if (field.children && field.children[0].controlType === 'KeyValuePair') {
      formattedValue = <PublishedPreviewKeyValuePair fields={field.children} />
    } else if (field.children && field.children[0].controlType === 'DropdownFreeText') {
      formattedValue = <PublishedPreviewDropdownFreeText fields={field.children} />
    }
  }

  return formattedValue
}

export const formatContentBlockFieldFormat = (format?: string) => {
  if (format === undefined || format === 'video-audio') return 'Video/Audio'
  if (format === 'audio-only') return 'Audio'
  if (format === 'video-only') return 'Video'
}
