import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { emailAlreadyRegisteredError } from 'services/authService'
import AuthErrorMessage from 'components/auth/AuthErrorMessage/AuthErrorMessage'
import SocialAuth from 'components/auth/SocialAuth/SocialAuth'
import AuthTermsText from 'components/auth/AuthTermsText/AuthTermsText'
import BackLink from 'components/shared/BackLink/BackLink'
import FormInput from 'components/shared/FormInput/FormInput'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import './RegistrationForm.scss'
import { useUrlParams } from 'helpers/urlParams'

type Props = {
  submitRegistration: (email: string, password: string) => Promise<{ success: boolean; errorMessage?: string }>
  logInWithGoogle: () => Promise<{ success: boolean; errorMessage?: string }>
  logInWithFacebook: () => Promise<{ success: boolean; errorMessage?: string }>
}

type FormData = {
  email: string
  password: string
  confirmPassword: string
}

const validationSchema = yup.object().shape({
  email: yup.string().required('*Please enter an email address').email('*Please enter a valid email address'),
  password: yup.string().required('*Please enter a password'),
  confirmPassword: yup.string().required('*Please confirm your password'),
})

const RegistrationForm: React.FC<Props> = ({ submitRegistration, logInWithGoogle, logInWithFacebook }) => {
  const { handleSubmit, register, errors, setError } = useForm<FormData>({ validationSchema })
  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [submittedEmail, setSubmittedEmail] = useState('')
  const { fromLogin } = useUrlParams('fromLogin')

  // In cases where we did not arrive here from the login page (e.g. link in Preview footer),
  //  we don't show a 'Back to log in' link because it's a bit confusing.
  const notFromLoginPage = fromLogin === 'false'

  const onSubmit = handleSubmit(async ({ email, password, confirmPassword }) => {
    setSubmittedEmail('')
    setErrorMessage('')
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.')
    } else {
      setLoading(true)
      const response = await submitRegistration(email, password)
      if (response.success) {
        setSuccess(true)
        setSubmittedEmail(email)
      } else if (response.errorMessage === emailAlreadyRegisteredError) {
        setError('email', 'server', response.errorMessage)
      } else {
        setErrorMessage(response.errorMessage!)
      }
      setLoading(false)
    }
  })

  return (
    <form className="registration-form" onSubmit={onSubmit} noValidate>
      <BackLink to="/auth/login">{notFromLoginPage ? 'Log in' : 'Back to log in'}</BackLink>
      {errorMessage && <AuthErrorMessage text={errorMessage} />}
      <h1>Create an account</h1>
      {isSuccess ? (
        <>
          <div className="registration-success-message">
            <span>Success! We've sent an email to </span>
            <span className="email">{submittedEmail}</span>
            <span>. Please open it up to activate your account.</span>
          </div>
        </>
      ) : (
        <>
          <SocialAuth
            logInWithGoogle={logInWithGoogle}
            logInWithFacebook={logInWithFacebook}
            clearSuccess={() => setSuccess(false)}
            setErrorMessage={setErrorMessage}
            signUpText
          />
          <FormInput
            name="email"
            type="email"
            placeholder="Email address"
            spellCheck={false}
            errors={errors}
            inputRef={register}
          />
          <FormInput name="password" type="password" placeholder="Password" errors={errors} inputRef={register} />
          <FormInput
            name="confirmPassword"
            type="password"
            placeholder="Confirm password"
            errors={errors}
            inputRef={register}
          />
          <div className="password-message">
            Use at least 8 characters, with at least one number and a mixture of uppercase and lowercase letters.
          </div>
          <LoadingButton type="submit" className="register-button primary" isLoading={isLoading}>
            Create account
          </LoadingButton>

          <AuthTermsText />
        </>
      )}
    </form>
  )
}

export default RegistrationForm
