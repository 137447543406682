import React, { useState } from 'react'
import './TeamMembersActions.scss'
import InviteTeamMembersDialog from '../../organization/InviteMemberDialog/InviteMemberDialog'
import Filter from '../../shared/Filter/Filter'
import { TeamMembersStatusFilterOption } from '../../../helpers/enum'

type Props = {
  onInvitesSent: () => Promise<void>
  selectedStatusFilterOption: string
  onStatusSelect: (value: string) => void
}

const TeamMembersActions: React.FC<Props> = ({ onInvitesSent, selectedStatusFilterOption, onStatusSelect }) => {
  const [openInviteMemberDialog, setOpenInviteMemberDialog] = useState<boolean>(false)

  return (
    <div className="team-members-actions">
      <InviteTeamMembersDialog
        onInvitesSent={onInvitesSent}
        openInviteMemberDialog={openInviteMemberDialog}
        setOpenInviteMemberDialog={setOpenInviteMemberDialog}
      />
      <Filter
        filterName={'Status'}
        filterOptions={Object.values(TeamMembersStatusFilterOption)}
        selectedFilterOption={selectedStatusFilterOption}
        onSelect={onStatusSelect}
      />
      <button
        className="primary add-team-members-button"
        onClick={() => {
          setOpenInviteMemberDialog(true)
        }}
      >
        + Add member(s)
      </button>
    </div>
  )
}

export default TeamMembersActions
