import React, { useState } from 'react'
import './SharingModal.scss'
import Social from './Social'
import QrCode from './QrCode'
import CopyLink from '../../shared/CopyableLink/CopyLink'
import { InvitationSuccessPageInfo } from '../../../state/types'
import Title from './Title'
import Embed from './Embed'

interface Props {
  previewId: string
  previewType: 'preview' | 'invitation'
  onPublish?: boolean // true if user just published a preview or an invitation
  invitationSuccessPage?: InvitationSuccessPageInfo
  invitationOrgName?: string
}

const SharingModal = function ({ previewId, previewType, onPublish, invitationOrgName, invitationSuccessPage }: Props) {
  const tabs = ['Social', 'QR Code']
  if (previewType === 'preview') {
    tabs.unshift('Embed')
  }
  const [activeTab, setActiveTab] = useState<string>(tabs[0])

  const urlPath = previewType.charAt(0) // 'p' or 'i'
  const origin = window.location.origin
  let shareText = `${origin}/${urlPath}/${previewId}`
  if (previewType === 'invitation' && invitationOrgName) {
    shareText += `?orgName=${invitationOrgName}`
  }

  const renderContent = function () {
    switch (activeTab) {
      case 'Social':
        // Use test-app instead of localhost because localhost will be rejected:
        const baseUrl = origin?.includes('localhost') ? 'https://app.development.previewme.com' : origin
        return <Social url={`${baseUrl}/${urlPath}/${previewId}`} />
      case 'QR Code':
        return <QrCode to={`/${urlPath}/${previewId}`} />
      case 'Embed':
        return (
          <Embed previewId={previewId} previewLink={`${origin}/${urlPath}/${previewId}`} previewType={previewType} />
        )
    }
  }

  return (
    <div className="sharing-modal">
      <div className="top">
        <Title
          invitationOrgName={invitationOrgName}
          invitationSuccessPage={invitationSuccessPage}
          previewType={previewType}
          onPublish={onPublish}
        />
        <div className="tab-container">
          {tabs.map(tab => (
            <div className={`tab ${activeTab === tab ? 'active' : ''}`} onClick={() => setActiveTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        {renderContent()}
      </div>
      <div className="bottom">
        <span className="content">
          Everyone with link can <b>view</b>
        </span>
        <CopyLink textToCopy={shareText} />
      </div>
    </div>
  )
}

export default SharingModal
