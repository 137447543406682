import React, { useState, useEffect, useRef } from 'react'
import "./CallsAction.scss";
import ColorPicker from '../Components/ColorPicker/ColorPicker'
import useVideoSuite from '../../../../state/useVideoSuite'
import { Link } from 'react-router-dom'
import { SubscriptionPlan } from '../../../../helpers/enum'
import useStore from '../../../../state/useStore'

type CallsActionProps = {
  enabled: boolean;
};
const CallsAction: React.FC<CallsActionProps> = ({ enabled }) => {
  const { state } = useStore()
  const {setVideoSuite,videoSuiteData,initialFadeInData,initialFadeOutData } = useVideoSuite(enabled)
  const nextIdRef = useRef(1);
  const [type, setType] = useState('all');
  const fadeIn = '00:00.0';
  const fadeOut = '00:00.5';
  const duration = '00:00';
  const textContent = '';
  const textColor = '';
  const textDropshadow = '';
  const buttonContent = '';
  const buttonColor = '';
  const buttonDropshadow = '';
  const buttonTextColor = '';
  const linkButton = '';
  const targetLink = '';
  const isOnBasicPlan = state.subscription?.plan.name === SubscriptionPlan.Basic
  const subscriptionPlanDisable = false;
  const previewDisableData = true;
  const [ctas, setCtas] = useState([createNewCta(0)]);

  useEffect(() => {
    if (videoSuiteData && Array.isArray(videoSuiteData)) {
      const updatedCtas = videoSuiteData.map((ctaData) => {
        const parseTime = (timeString:string) => {
          if (timeString) {
            const [minutes, fullSeconds] = timeString.split(':');
            const [seconds, fraction] = fullSeconds.split('.');
            return parseInt(minutes, 10) * 60 + parseInt(seconds, 10) + (fraction ? parseInt(fraction, 10) / 10 : 0);
          } else {
            return 0;
          }
        };


        const fadeInTime = parseTime(ctaData.fadeIn);
        const fadeOutTime = parseTime(ctaData.fadeOut);

        const totalDurationSeconds = fadeOutTime - fadeInTime;

        const formatTime = (time:number) => {
          const minutes = Math.floor(time / 60);
          const seconds = time % 60;
          const formattedMinutes = minutes.toString().padStart(2, '0');
          const formattedSeconds = seconds.toFixed(1).padStart(4, '0');
          return `${formattedMinutes}:${formattedSeconds}`;
        };

        return {
          ...ctaData,
          duration: formatTime(totalDurationSeconds),
          fadeIn: formatTime(fadeInTime),
          fadeOut: formatTime(fadeOutTime),
        };
      });

      setCtas(updatedCtas);
    }
  }, [videoSuiteData]);

  function createNewCta(index: number) {
    nextIdRef.current++;
    return {
      id: index + 1,
      type: type,
      previewDisableData: previewDisableData,
      duration: duration,
      fadeIn: initialFadeInData || fadeIn ,
      fadeOut: initialFadeOutData || fadeOut ,
      textContent: textContent || '',
      textColor: textColor || '#000',
      textDropshadow: textDropshadow || '',
      buttonContent: buttonContent || '',
      buttonColor: buttonColor || '#5284FF',
      buttonDropshadow: buttonDropshadow || '',
      buttonTextColor: buttonTextColor || '#FFF',
      linkButton: linkButton || '',
      targetLink: targetLink || 'open-as-popup',
      positionX: 0,
      positionY: 0,
    };
  }

  const updateCtaData = (index: number, newData: any) => {
    const updatedCtas = [...ctas];
    const updatedCta = { ...updatedCtas[index], ...newData };

    const fadeInParts = updatedCta.fadeIn.split(':').map((part:any) => parseInt(part, 10));
    const fadeOutParts = updatedCta.fadeOut.split(':').map((part:any) => parseInt(part, 10));

    if (!isNaN(fadeInParts[0]) && !isNaN(fadeInParts[1]) && !isNaN(fadeOutParts[0]) && !isNaN(fadeOutParts[1])) {
      const fadeInSeconds = fadeInParts[0] * 60 + fadeInParts[1];
      const fadeOutSeconds = fadeOutParts[0] * 60 + fadeOutParts[1];

      const totalDuration = fadeOutSeconds - fadeInSeconds ;

      const totalDurationFormatted = `${String(Math.floor(totalDuration / 60)).padStart(2, '0')}:${String(Math.floor(totalDuration % 60)).padStart(2, '0')}`;

      updatedCta.duration = totalDurationFormatted;
    }

    updatedCtas[index] = updatedCta;
    setCtas(updatedCtas);
    setVideoSuite(updatedCtas);
  };


  const addCta = () => {
    setCtas([...ctas, createNewCta(ctas.length)]);
    setVideoSuite([...ctas, createNewCta(ctas.length)]);
  };

  const deleteCta = (index:number) => {
    const newCtas = [...ctas];
    newCtas.splice(index, 1);
    setCtas(newCtas);
    setVideoSuite(newCtas);
  };

  const handleBackgroundColor = (color: string, type: string, ctaIndex: number) => {
    const updatedCtas = [...ctas];
    const ctaToUpdate = updatedCtas[ctaIndex];

    if (type === 'text_colour') {
      ctaToUpdate.textColor = color;
    } else if (type === 'text_dropshadow') {
      ctaToUpdate.textDropshadow = color;
    } else if (type === 'button_colour') {
      ctaToUpdate.buttonColor = color;
    } else if (type === 'button_dropshadow') {
      ctaToUpdate.buttonDropshadow = color;
    } else if (type === 'button_text') {
      ctaToUpdate.buttonTextColor = color;
    }

    setCtas(updatedCtas);
    setVideoSuite(updatedCtas);

  };



  return (
    <>

      <div className="calls-action-container">
        {isOnBasicPlan &&
          <div className='upgrade-container'>
            Upgrade to use premium features
            <Link className='upgrade-button button' to={`/plan/select?from=${encodeURIComponent(window.location.pathname + window.location.search)}`}>
              VIEW UPGRADE OPTIONS
            </Link>
          </div>
        }
        <div className={isOnBasicPlan ? "d-none" : ""}>
          <div className={previewDisableData ? "" : "disabled-item"}>
          {ctas.map((cta, index) => (
            <div className="cta-container" key={index}>
            <div className="first-container">
              <div className="flex-between">
                <p className="text-title">CTA {index + 1}</p>
                <p className="text-delete" onClick={() => deleteCta(index)}>Delete</p>
                <input type="hidden" value={index + 1} onChange={() => setType('all')}/>
              </div>

              <div className="custom-radio-buttons">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name={`type-${index}`}
                    value="all"
                    checked={cta.type === 'all'}
                    onChange={() => updateCtaData(index, { type: 'all' })}
                  />
                  <span className="radio-control"></span>
                  <span className="text-radio">All</span>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name={`type-${index}`}
                    value="button"
                    checked={cta.type === 'button'}
                    onChange={() => updateCtaData(index, { type: 'button' })}
                  />
                  <span className="radio-control"></span>
                  <span className="text-radio">Button</span>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name={`type-${index}`}
                    value="text"
                    checked={cta.type === 'text'}
                    onChange={() => updateCtaData(index, { type: 'text' })}
                  />
                  <span className="radio-control"></span>
                  <span className="text-radio">Text</span>
                </label>
              </div>
                <div className="duration-container">
                  <div className="duration-form-group">
                    <label htmlFor="durationInput">Duration</label>
                    <input
                      disabled
                      id={`durationInput-${index}`}
                      type="text"
                      className="txt-duration"
                      value={cta.duration}
                      onChange={(e) => updateCtaData(index, { duration: e.target.value })}
                    />
                  </div>
                  <div className="duration-form-group">
                    <label htmlFor={`fadeInInput-${index}`}>Fade in</label>
                    <input
                      disabled
                      type="text"
                      value={cta.fadeIn}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d{1,2}:\d{1,2}$/.test(inputValue)) {
                          updateCtaData(index, { fadeIn: inputValue });
                        } else {
                          console.log("Invalid format");
                        }
                      }}
                      id={`fadeInInput-${index}`}
                      className="txt-fadein"
                    />
                  </div>
                  <div className="duration-form-group">
                    <label htmlFor={`fadeOutInput-${index}`}>Fade out</label>
                    <input
                      disabled
                      type="text"
                      value={cta.fadeOut}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d{0,2}:\d{0,2}$/.test(inputValue)) {
                          updateCtaData(index, { fadeOut: inputValue });
                        } else {
                          console.log("Invalid format");
                        }
                      }}
                      id={`fadeOutInput-${index}`}
                      className="txt-fadeout"
                    />
                  </div>
                </div>
            </div>

            {(cta.type === 'text' || cta.type === 'all') && (
            <div className="text-container">
              <p className="text-title">Text</p>
              <input type="text" value={cta.textContent} onChange={(e) => updateCtaData(index, { textContent: e.target.value })} className="text-field" />
              <ColorPicker
                plan={subscriptionPlanDisable}
                existingValue={cta.textColor}
                label="Text Colour"
                type="text_colour"
                onColorBgChange={(color: string) => handleBackgroundColor(color, 'text_colour', index)}
              />
              <ColorPicker
                plan={subscriptionPlanDisable}
                existingValue={cta.textDropshadow}
                label="Text Dropshadow"
                type="text_dropshadow"
                onColorBgChange={(color: string) => handleBackgroundColor(color, 'text_dropshadow', index)}
              />
            </div>
            )}

            {/* Text Settings */}
            <div className="button-container">
              {(cta.type === 'button' || cta.type === 'all') && (
              <>
                <p className="text-title">Button</p>
                <input type="text" value={cta.buttonContent} onChange={(e) => updateCtaData(index, { buttonContent: e.target.value })} className="button-field"/>
                <ColorPicker
                  plan={subscriptionPlanDisable}
                  existingValue={cta.buttonColor}
                  label="Button Colour"
                  type="button_colour"
                  onColorBgChange={(color: string) => handleBackgroundColor(color, 'button_colour', index)}
                />
                <ColorPicker
                  plan={subscriptionPlanDisable}
                  existingValue={cta.buttonTextColor}
                  label="Button Text Color"
                  type="button_dropshadow"
                  onColorBgChange={(color: string) => handleBackgroundColor(color, 'button_text', index)}
                />
                <ColorPicker
                  plan={subscriptionPlanDisable}
                  existingValue={cta.buttonDropshadow}
                  label="Button Dropshadow"
                  type="button_dropshadow"
                  onColorBgChange={(color: string) => handleBackgroundColor(color, 'button_dropshadow', index)}
                />

              </>
              )}
              <p className="text-title">URL Link</p>
              <input type="text" value={cta.linkButton} onChange={(e) => updateCtaData(index, { linkButton: e.target.value })} className="button-field"/>
              <p className="text-title">Target</p>
              <select className="select-fields" value={cta.targetLink  || 'open-as-popup'} onChange={(e) => updateCtaData(index, { targetLink: e.target.value || 'open-as-popup' })}>
                <option value="open-as-popup">Open as popup</option>
                <option value="open-new-tab">Open in new tab</option>
              </select>

            </div>



          </div>
          ))}
            <>
            {(videoSuiteData && videoSuiteData.length === 0) &&
            <button className="addCta-button" onClick={addCta}>
              Add CTA
            </button>
            }
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallsAction;
