import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAuth from 'state/useAuth'
import useWorkspace from 'state/useWorkspace'
import OnboardingPage from '../OnboardingPage/OnboardingPage'
import { globalAuthAppRedirect, globalAuthSignOutUrl } from '../../../helpers/config'

const OnboardingFromInvitationPage = () => {
  const { organizationId, invitationCode } = useParams<any>()
  const { user } = useAuth()
  const { addUserToOrganizationByInvitation, isEmailCorrect } = useWorkspace(false)
  const [isNewOrganizationLoading, setNewOrganizationLoading] = useState<boolean>(false)

  useEffect(() => {
    const handleOnboardedUsers = async () => {
      setNewOrganizationLoading(true)

      if (await isEmailCorrect(invitationCode)) {
        if (user?.onboarded && organizationId && invitationCode) {
          await addUserToOrganizationByInvitation(organizationId, invitationCode)
          window.location.reload()
        }
      } else {
        window.location.assign(
          `${globalAuthSignOutUrl}${globalAuthAppRedirect}/o/${organizationId}/i/${invitationCode}`
        )
      }
      setNewOrganizationLoading(false)
    }
    handleOnboardedUsers()
  }, [organizationId, invitationCode])

  return isNewOrganizationLoading ? <LoadingSpinner fullScreen /> : <OnboardingPage teamMemberInvitation={true} />
}

export default OnboardingFromInvitationPage
