import FormMultiSelect from 'components/shared/FormMultiSelect/FormMultiSelect'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import ProfileImageSelector from 'components/shared/ProfileImageSelector/ProfileImageSelector'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as configService from 'services/configService'
import { Option, Organization } from 'state/types'
import useWorkspace from 'state/useWorkspace'
import './OrganizationProfilePage.scss'
import FormInput from '../../shared/FormInput/FormInput'
import FormSelect from '../../shared/FormSelect/FormSelect'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import SuccessDialog from '../../shared/SuccessDialog/SuccessDialog'
import * as yup from 'yup'
import { getWorkspaceOrganization } from '../../../helpers/organization'
import AuthorizedPage from '../../auth/AuthorizedPage/AuthorizedPage'
import UnauthorizedPage from '../../auth/UnauthorizedPage/UnauthorizedPage'

type FormData = {
  organizationIndustries: string
}

const validationSchema = yup.object().shape({
  organizationIndustries: yup.array().required('Please select the industries')
})

const OrganizationProfilePage: React.FC = () => {
  const { currentWorkspace, updateOrganizationProfileImage, removeOrganizationProfileImage, updateOrganization } =
    useWorkspace(true)
  const [industryOptions, setIndustryOptions] = useState<Option[]>([])
  const [countryOptions, setCountryOptions] = useState<Option[]>([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSuccessDialogShowing, setSuccessDialogShowing] = useState(false)

  const { handleSubmit, control, errors } = useForm<FormData>({ validationSchema })

  const onSubmit = handleSubmit(async ({ organizationIndustries }) => {
    const currentWorkspaceOrganization: Organization | undefined = getWorkspaceOrganization()
    if (currentWorkspaceOrganization) {
      setSubmitting(true)
      currentWorkspaceOrganization.industries = JSON.stringify(organizationIndustries)
      const ok = await updateOrganization(currentWorkspaceOrganization)
      if (ok) {
        setSuccessDialogShowing(true)
      }
      setSubmitting(false)
    }
  })
  useEffect(() => {
    const loadIndustryOptions = async () => setIndustryOptions(await configService.getFieldSetOptions('industry'))
    const loadCountryOptions = async () => setCountryOptions(await configService.getFieldSetOptions('country'))
    loadIndustryOptions()
    loadCountryOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteImageConfirmationText =
    'If you remove this image from your profile it will be permanently deleted. Would you like to continue?'
  return (
    <AuthorizedPage UnauthorizedComponent={UnauthorizedPage}>
      <PageFrame className='organization-profile-page' header={<h1 className='header'>Profile</h1>}>
        <form className='profile-form' onSubmit={onSubmit}>
          <ProfileImageSelector
            value={currentWorkspace?.profileImageStorageId}
            name={currentWorkspace?.name}
            canDelete
            warnBeforeDeleting
            deleteConfirmationText={deleteImageConfirmationText}
            uploadButtonText=' workspace profile image'
            showUploadingLabel
            uploadImage={updateOrganizationProfileImage}
            deleteImage={removeOrganizationProfileImage}
          />
          <FormInput
            name='organizationName'
            label='Workspace name'
            errors={errors}
            spellCheck={false}
            defaultValue={currentWorkspace?.name}
            disabled
          />
          <Controller
            name='country'
            errors={errors}
            control={control}
            as={
              <FormSelect
                label='Country'
                placeholder='Select country'
                options={countryOptions}
                defaultValue={currentWorkspace?.country}
                disabled
              />
            }
          />
          <Controller
            name='organizationIndustries'
            errors={errors}
            control={control}
            as={
              <FormMultiSelect
                label='Industries*'
                placeholder='Select industries'
                options={industryOptions}
                defaultValue={currentWorkspace?.industries}
              />
            }
          />
          <LoadingButton className='save-button primary' isLoading={isSubmitting}>
            Save changes
          </LoadingButton>
          <SuccessDialog isShowing={isSuccessDialogShowing} text='Profile updated'
                         setShowing={setSuccessDialogShowing} />
        </form>
      </PageFrame>
    </AuthorizedPage>
  )
}

export default OrganizationProfilePage
