import unpublishedPreviewIllustration from 'assets/unpublished-preview-illustration.png'
import PublishedPreview from 'components/preview/PublishedPreview/PublishedPreview'
import PublishedPreviewHeader from 'components/preview/PublishedPreviewHeader/PublishedPreviewHeader'
import PublishedPreviewToolbar from 'components/preview/PublishedPreviewToolbar/PublishedPreviewToolbar'
import ErrorPage from 'components/shared/ErrorPage/ErrorPage'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAttachIntegrations } from 'state/useIntegrations'
import usePublishedPreview from 'state/usePublishedPreview'
import PublishedPreviewFooter from '../PublishedPreviewFooter/PublishedPreviewFooter'
import './PublishedPreviewPage.scss'
import { usePublicOrganizationBrandSetting } from 'state/useOrganizationBrandSetting'
import previewmeLogo from 'assets/previewme-logo.svg'
import { makeStyles } from '@material-ui/core/styles'
import WebFont from 'webfontloader';
import useBrandTheme from '../../../state/useBrandTheme'
import usePreview from '../../../state/usePreview'
import useStore from '../../../state/useStore'
import { useHistory, useParams } from 'react-router-dom'
import { useUrlParams } from '../../../helpers/urlParams'

interface TextProps {
  color?: string;
  font?: string;
  variant?:number;
}

interface BrandModeDataFont {
  heading1?: { font?: string,variant?: number };
  heading2?: { font?: string,variant?: number };
  heading3?: { font?: string,variant?: number };
  body?: { font?: string,variant?: number };
  link?: { font?: string,variant?: number };
  button02?: { font?: string,variant?: number };
}

interface BrandDataProps {
  headerColor?: string;
  upperColor?: string;
  lowerColor?: string;
  buttonColor?: string;
  heading1?: TextProps;
  heading2?: TextProps;
  heading3?: TextProps;
  body?: TextProps;
  link?: TextProps;
  button02?: TextProps;
  borderRadius?: number;
}

const getErrorTitleText = (status?: number) => {
  switch (status) {
    case 403:
      return 'This preview is no longer available'
    case 404:
      return 'Preview not found'
    default:
      return 'Network error'
  }
}

const getErrorBodyText = (status?: number) => {
  switch (status) {
    case 403:
      return 'It looks like this Preview has been unpublished. Please contact the creator directly to get access to it.'
    case 404:
      return 'It may have been deleted by its owner.'
    default:
      const statusText = status !== undefined && status !== null ? `${status})` : 'unknown'
      return `A network error occurred (status code ${statusText}).`
  }
}

const PublishedPreviewPage: React.FC = () => {
  const {
    isLoading,
    preview,
    error,
    status,
    isOwnPreview,
    isUserInOrganization,
    isInvitationMode,
    publish,
    unpublish,
    updateTheme,
    updateBusinessLogoImage,
    removeBusinessLogoImage,
  } = usePublishedPreview()
  const {
    brandModeData,
    planType
  } = useBrandTheme('main');
  const [showInvitationClosedError, setShowInvitationClosedError] = useState(false)
  const currentURL = window.location.href
  const { state, dispatch } = useStore()
  const history = useHistory()
  console.log("params: ", currentURL)
  useEffect(() => {
    (() => {
      if (preview?.invitation?.closed) {
        setShowInvitationClosedError(true);
      }
    })();
  }, [preview?.invitation?.closed]);

  const { previewCustomStyles, setPreviewCustomStyles } = usePreview()

  const showToolbar = (isOwnPreview || isUserInOrganization) && !isInvitationMode
  const orgIdOrInvitationOwnerId = !preview?.invitation
    ? preview?.organizationId
    : preview?.invitation?.organizationId
    
  useAttachIntegrations(preview, !(isOwnPreview || isUserInOrganization))
  const { isBrandLoading, organizationBrandSetting } = usePublicOrganizationBrandSetting(orgIdOrInvitationOwnerId)
  const [showHoverPopup, setShowHoverPopup] = useState<boolean>(false)

  const renderHoverLogo = () => {
    //render for personal account previews
    if (!preview?.organizationId && showHoverPopup) {
      return (
        <div
          className="pinned-previewme-reference"
          onClick={() => {
            window.open('https://www.previewme.com')
          }}
        >
          <span>Powered by</span> <img className="pinned-logo" src={previewmeLogo} />
        </div>
      )
    }

    const handlePreviewClick = () => {
      if (typeof window !== 'undefined') {
        window.open('https://www.previewme.com');
      }
    };

    return (
      preview &&
      !isBrandLoading &&
      (organizationBrandSetting?.previewReferenceDisabled
        ? null
        : showHoverPopup && (
            <div
              className="pinned-previewme-reference"
              onClick={handlePreviewClick}
            >
              <span>Powered by</span> <img className="pinned-logo" src={previewmeLogo} />
            </div>
          ))
    )
  }

  const checkElementInViewport = (element: HTMLElement) => {
    if (typeof window === 'undefined') {
      // Window object is not available
      return false;
    }
    if (element) {
      var top = element.offsetTop
      var left = element.offsetLeft
      var width = element.offsetWidth
      var height = element.offsetHeight

      while (element.offsetParent) {
        element = element.offsetParent as HTMLElement
        top += element.offsetTop
        left += element.offsetLeft
      }

      return (
        top < window.pageYOffset + window.innerHeight &&
        left < window.pageXOffset + window.innerWidth &&
        top + height > window.pageYOffset &&
        left + width > window.pageXOffset
      )
    }
  }

  useEffect(() => {
    const checkElementsInViewport = () => {
      // Only run if document is defined (e.g., not in server-side rendering)
      if (typeof document !== 'undefined') {
        let footerElement: HTMLElement = document.querySelector('.published-preview-footer') as HTMLElement;

        if (organizationBrandSetting?.cloudinaryStorageId) {
          setShowHoverPopup(!checkElementInViewport(footerElement));
        } else {
          let headerElement: HTMLElement = document.querySelector('.published-preview-header') as HTMLElement;
          setShowHoverPopup(!checkElementInViewport(footerElement) && !checkElementInViewport(headerElement));
        }
      }
    };

    // Only add event listeners if window and document are defined
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      checkElementsInViewport();
      window.addEventListener('scroll', checkElementsInViewport);

      return () => {
        window.removeEventListener('scroll', checkElementsInViewport);
      };
    }
  }, [organizationBrandSetting]);


  useEffect(() => {

    if (preview?.theme !== 'custom-workspace-theme') {
      return;
    }

    if (brandModeData) {
      setPreviewCustomStyles(brandModeData);
      loadFontsFromBrandData(brandModeData);
    }

  }, [brandModeData,preview?.theme]);

  const loadFontsFromBrandData = (data:BrandModeDataFont) => {
    const defaultFont = 'DM Sans';

    const fontFamilies = [
      data.heading1?.font ?? defaultFont,
      data.heading2?.font ?? defaultFont,
      data.heading3?.font ?? defaultFont,
      data.body?.font ?? defaultFont,
      data.link?.font ?? defaultFont,
      data.button02?.font ?? defaultFont
    ].filter(Boolean);

    if (fontFamilies.length === 0) {
      console.error("No valid font families found in brandModeData.");
      return;
    }

    try {
      WebFont.load({
        google: {
          families: fontFamilies
        }
      });
    } catch (error) {
      console.error('Error loading fonts:', error);
    }
  };

  const useStyles = makeStyles({
    root: {
      '--custom-bg-header': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.headerColor ?? '') : '#FFFFFF',
      '--custom-bg-upper': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.upperColor ?? '') : '#FFFFFF',
      '--custom-bg-lower' : (props: BrandDataProps) => !currentURL.includes("i=") ? (props.lowerColor ?? '') : '#FFFFFF',
      '--custom-bg-button': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.buttonColor ?? '#01a3f8') : '#01a3f8',
      '--custom-text-color-heading1': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading1?.color || '#000') : '#000',
      '--custom-text-color-heading2': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading2?.color || '#000') : '#000',
      '--custom-text-color-heading3': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading3?.color || '#000') : '#000',
      '--custom-text-color-body': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.body?.color || '#000') : '#000',
      '--custom-text-color-link': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.link?.color || '#000') : '#000',
      '--custom-text-color-button02': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.button02?.color || '#000') : '#000',
      '--custom-text-font-heading1': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading1?.font || 'DM Sans') : 'DM Sans',
      '--custom-text-font-heading2': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading2?.font || 'DM Sans') : 'DM Sans',
      '--custom-text-font-heading3': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading3?.font || 'DM Sans') : 'DM Sans',
      '--custom-text-font-body': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.body?.font || 'DM Sans') : 'DM Sans',
      '--custom-text-font-link': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.link?.font || 'DM Sans') : 'DM Sans',
      '--custom-text-font-button02': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.button02?.font || 'DM Sans') : 'DM Sans',
      '--custom-text-variant-heading1': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading1?.variant || '600') : '600',
      '--custom-text-variant-heading2': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading2?.variant || '500') : '500',
      '--custom-text-variant-heading3': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.heading3?.variant || '600') : '600',
      '--custom-text-variant-body': (props: BrandDataProps) =>!currentURL.includes("i=")  ? (props.body?.variant ||  '600') : '600',
      '--custom-text-variant-body2': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.body?.variant || '400') : '400',
      '--custom-text-variant-link': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.link?.variant || '600') : '400',
      '--custom-text-variant-button02': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.button02?.variant || '600') : '600',
      '--custom-border-radius': (props: BrandDataProps) => !currentURL.includes("i=") ? (props.borderRadius || Number(props?.borderRadius) === 0? props.borderRadius+ 'px' : '4px') : '4px',
    },
  });

  const classes = useStyles(previewCustomStyles as BrandDataProps); // Use state with useStyles
  return isLoading || isBrandLoading ? (
    <LoadingSpinner fullScreen />
  ) : preview ? (
    <div className={'published-preview-page' + (showToolbar ? ' toolbar-showing' : '')}>
      {showToolbar && (
        <PublishedPreviewToolbar
          planType={planType ?? ''}
          preview={preview}
          publish={publish}
          unpublish={unpublish}
          updateTheme={updateTheme}
          updateBusinessLogoImage={updateBusinessLogoImage}
          removeBusinessLogoImage={removeBusinessLogoImage}
        />
      )}
      <PublishedPreviewHeader
        classData={classes}
        preview={preview}
        isBrandLoading={isBrandLoading}
        organizationBrandSetting={organizationBrandSetting}
        isOwnPreview={isOwnPreview}
        planType={planType ?? ''}
      />
      <PublishedPreview planType={planType ?? ''} preview={preview} classData={classes} />

      {!organizationBrandSetting?.previewReferenceDisabled && <PublishedPreviewFooter planType={planType ?? ''} theme={preview.theme} />}
      {renderHoverLogo()}
    </div>
  ) : error ? (
    <ErrorPage
      className="unpublished-preview-page"
      titleText={getErrorTitleText(status)}
      illustration={unpublishedPreviewIllustration}
    >
      {getErrorBodyText(status)}
    </ErrorPage>
  ) : null
}

export default PublishedPreviewPage
