import { addDays, isSameDay } from 'date-fns'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { PreviewViewsOverTime, PreviewViewsByDay } from 'state/types'

export const padDataWithZeros = (
  previewViewsOverTime: PreviewViewsOverTime,
  minDay: Date,
  maxDay: Date
): PreviewViewsOverTime => {
  const countsWithViews = previewViewsOverTime.counts
  const arrayLength = differenceInCalendarDays(maxDay, minDay) + 1
  const counts: PreviewViewsByDay[] = []

  for (let i = 0; i < arrayLength; i++) {
    const day = addDays(minDay, i)

    const count = countsWithViews?.find(count => isSameDay(count.day, day))?.count || 0
    counts.push({ day, count })
  }

  return { ...previewViewsOverTime, counts }
}
