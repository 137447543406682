import CopyIcon from '../../../assets/icons/CopyIcon'
import React, { useRef, useState } from 'react'
import './CopyLink.scss'
import { Tooltip } from '@material-ui/core'

interface Props {
  textToCopy: string
}

const CopyLink = function ({ textToCopy }: Props) {
  const [tooltip, setTooltip] = useState('')
  const [buttonText, setButtonText] = useState('Copy link')
  const timeoutRef = useRef<NodeJS.Timeout>()

  const onClick = function () {
    navigator?.clipboard?.writeText(textToCopy)
    setButtonText('Copied!')
    setTooltip('Copied')
    timeoutRef.current && clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => clear(), 1500)
  }

  const clear = function () {
    setTooltip('')
    setButtonText('Copy link')
  }

  return (
    <div>
      <Tooltip title={tooltip} placement="top">
        <button className="copy-button" onClick={onClick}>
          <span>{buttonText}</span>
          <CopyIcon />
        </button>
      </Tooltip>
    </div>
  )
}

export default CopyLink
