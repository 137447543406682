import React from 'react'

const ClicksIcon = () => (
  <svg className="icon" width="34px" height="34px" viewBox="0 0 34 34">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1297.000000, -82.000000)">
        <g transform="translate(592.000000, -77.000000)">
          <g transform="translate(707.000000, 160.000000)" stroke="#25262A" strokeWidth="1.17">
            <path
              d="M18.6666667,9.34932325 L24.8367922,21.856757 L20.2455314,21.8559204 L20.2458937,30.6312162 L17.0873581,30.6312162 L17.0864982,21.8559204 L12.4965412,21.8567569 L18.6666667,9.34932325 Z"
              transform="translate(18.666667, 19.621622) rotate(-45.000000) translate(-18.666667, -19.621622) "
            ></path>
            <g
              transform="translate(8.000000, 8.472973) rotate(-45.000000) translate(-8.000000, -8.472973) translate(0.000000, 5.351351)"
              strokeLinecap="square"
            >
              <line x1="8" y1="0" x2="8" y2="4.16216216"></line>
              <line
                x1="13.5677615"
                y1="2.4168757"
                x2="13.5626733"
                y2="6.58785102"
                transform="translate(13.565217, 4.502363) rotate(-300.000000) translate(-13.565217, -4.502363) "
              ></line>
              <line
                x1="2.43732675"
                y1="2.4168757"
                x2="2.43223847"
                y2="6.58785102"
                transform="translate(2.434783, 4.502363) scale(-1, 1) rotate(-300.000000) translate(-2.434783, -4.502363) "
              ></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ClicksIcon
