import React, { useEffect, useState } from 'react'
import PageFrame from '../../shared/PageFrame/PageFrame'
import './TeamMembersPage.scss'
import useWorkspace from '../../../state/useWorkspace'
import { TeamMember } from '../../../state/types'
import TeamMembersTable from '../TeamMembersTable/TeamMembersTable'
import TeamMembersListMobile from '../TeamMembersListMobile/TeamMembersListMobile'
import TeamMembersActions from '../TeamMembersActions/TeamMembersActions'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'
import { getEnumKey, TeamMembersStatusFilterOption, TeamMemberStatus } from '../../../helpers/enum'
import AuthorizedPage from '../../auth/AuthorizedPage/AuthorizedPage'
import UnauthorizedPage from '../../auth/UnauthorizedPage/UnauthorizedPage'

const TeamMembersPage: React.FC = () => {
  const { getCurrentOrganizationTeamMembers, deleteTeamMemberInvite, deleteTeamMemberFromCurrentOrganization } =
    useWorkspace(false)
  const [allTeamMembers, setAllTeamMembers] = useState<TeamMember[]>([])
  const [teamMembersToShow, setTeamMembersToShow] = useState<TeamMember[]>([])
  const [selectedStatusFilterOption, setSelectedStatusFilterOption] = useState<string>(
    TeamMembersStatusFilterOption.ALL
  )
  const [isLoading, setLoading] = useState<boolean>(false)

  const doesTeamMemberMatchStatusFilter = (teamMember: TeamMember): boolean => {
    if (selectedStatusFilterOption === TeamMembersStatusFilterOption.ALL) {
      return true
    }

    const status: TeamMemberStatus = teamMember.status
    if (!status) {
      return false
    }
    return status === getEnumKey(TeamMembersStatusFilterOption, selectedStatusFilterOption)
  }

  const loadTeamMembers = async () => {
    setAllTeamMembers(await getCurrentOrganizationTeamMembers())
  }

  const filterAllTeamMembers = () => {
    setLoading(true)
    setTeamMembersToShow(allTeamMembers.filter(teamMember => doesTeamMemberMatchStatusFilter(teamMember)))
    setLoading(false)
  }

  const deleteInvite = async (email: string) => {
    const ok = await deleteTeamMemberInvite(email)
    if (ok) {
      await loadTeamMembers()
    }
  }

  const deleteTeamMember = async (userId: string) => {
    const ok = await deleteTeamMemberFromCurrentOrganization(userId)
    if (ok) {
      await loadTeamMembers()
    }
  }

  useEffect(() => {
    loadTeamMembers()
  }, [])

  useEffect(() => {
    filterAllTeamMembers()
  }, [allTeamMembers, selectedStatusFilterOption])

  return (
    <AuthorizedPage UnauthorizedComponent={UnauthorizedPage}>
      <PageFrame className='team-members-page' header={<h1>Team Members</h1>}>
        <TeamMembersActions
          onInvitesSent={loadTeamMembers}
          selectedStatusFilterOption={selectedStatusFilterOption}
          onStatusSelect={setSelectedStatusFilterOption}
        />
        {isLoading ? (
          <LoadingSpinner container />
        ) : allTeamMembers ? (
          <>
            <TeamMembersTable
              teamMembers={teamMembersToShow}
              deleteInvite={deleteInvite}
              deleteTeamMember={deleteTeamMember}
            />
            <TeamMembersListMobile
              teamMembers={teamMembersToShow}
              deleteInvite={deleteInvite}
              deleteTeamMember={deleteTeamMember}
            />
          </>
        ) : (
          ''
        )}
      </PageFrame>
    </AuthorizedPage>
  )
}

export default TeamMembersPage
