import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import React, { useEffect, useState } from 'react'
import { Invitation } from '../../../state/types'
import { duplicateInvitation } from '../../../services/invitationService'
import useInvitation from '../../../state/useInvitation'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import DuplicateSuccess from './DuplicateSuccess'
import './InvitationDuplicateModal.scss'

interface Props {
  isShowing: boolean
  hideModal: () => void
  invitation: Invitation
  loadInvitations: (duplicate?: boolean) => void
}

const InvitationDuplicateModal = function ({ invitation, loadInvitations, isShowing, hideModal }: Props) {
  const { invitation: invitationWithExtraFields } = useInvitation(invitation.id)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [newTitle, setNewTitle] = useState<string>('CLONE: ' + invitation.name)
  const [newId, setNewId] = useState<string>()

  useEffect(() => {
    setNewTitle('CLONE - ' + invitation.name)
  }, [isShowing])

  const onContinue = async function () {
    if (!invitationWithExtraFields) return

    setIsLoading(true)
    await new Promise(r => setTimeout(r, 1000))
    const response = await duplicateInvitation(invitation.templateId, newTitle, invitationWithExtraFields)
    setIsLoading(false)
    setNewId(response.data)
    setIsSuccess(true)
  }

  const onClose = function () {
    if (isSuccess) loadInvitations(true)
    hideModal()
    setIsSuccess(false)
    setNewTitle('')
  }

  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      {isSuccess ? (
        <DuplicateSuccess invitationId={newId} organisationId={invitation.organizationId} onClose={onClose} />
      ) : (
        <div className="duplicate-modal">
          <h2>Duplicate Invitation: {invitation.name}</h2>
          Duplicating copies all of your Invitation content (not the Previews received). The duplicated invitation will
          initially be closed so make sure to edit the starting and closing dates.
          <div>
            <span>Rename your new invitation:</span>
            <input value={newTitle} onChange={e => setNewTitle(e.target.value)} />
          </div>
          <LoadingButton isLoading={isLoading} onClick={onContinue} className="primary">
            create
          </LoadingButton>
        </div>
      )}
    </CloseableDialog>
  )
}

export default InvitationDuplicateModal
