import { useState, useEffect, useCallback } from 'react'
import useStore from 'state/useStore'
import { getTagsByOrganization } from 'services/tagService'
import { TagOption } from './types'

// Used for only loading the preview once in the case where multiple components are using this hook simultaneously.

const useTag = (organizationId: string) => {
  const { state, dispatch } = useStore()
  const [fetchingOrganizationTags, setFetchingOrganizationTags] = useState(false)
  const [organizationTags, setOrganizationTags] = useState<TagOption[] | undefined>([])

  useEffect(() => {
    getTagsByOganization(organizationId)
  }, [organizationId])

  const getTagsByOganization = async (organizationId: string) => {
    if (organizationId) {
      setFetchingOrganizationTags(true)
      const { data, ok } = await getTagsByOrganization(organizationId)
      dispatch({ type: 'SET_ORGANIZATION_TAGS', value: data })
      setOrganizationTags(data)
      setFetchingOrganizationTags(false)
      return ok
    }
  }

  return { fetchingOrganizationTags, organizationTags, getTagsByOganization }
}

export default useTag
