import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { parseIndexParam } from 'helpers/urlParams'
import { formatChapterLabel } from 'helpers/media'
import * as videoService from 'services/videoService'
import useStore from 'state/useStore'
import useContentBlock from 'state/useContentBlock'
import useVideoSuite from './useVideoSuite'
import useWatermark from './useWatermark'

// Used for only loading the video assets once in the case where multiple components are using this hook simultaneously.
let isLoadingGlobal = false

const useVideoAssets = () => {
  const { state, dispatch } = useStore()
  const { defaultWatermarkData } = useWatermark()
  const { previewId, index } = useParams<any>()
  const blockIndex = parseIndexParam(index)
  const { isLoading: isBlockLoading, contentBlock } = useContentBlock(previewId, blockIndex)
  const hasEmptyChapters =
    contentBlock &&
    contentBlock.contentBlockFields.length > 0 &&
    contentBlock.contentBlockFields.some(f => !!f.videoChapter?.id && !f.videoChapter.rawVideoStorageId)
  const responseData = {
    cta: true,
    watermark: true,
    videoFx: true,
    images: true,
    captions: true
  };
  const {setVideoSuite,updateVideoSuite,getVideoSuiteByVideoID } = useVideoSuite(responseData?.cta)
  // Only show video assets if (a) there are assets to copy, and (b) there are empty chapters to copy to or new chapters can be created
  const showVideoAssets = state.hasVideoAssets && (hasEmptyChapters || contentBlock?.editable)

  const chapterOptions =
    contentBlock?.contentBlockFields
      .map(f => f.videoChapter)
      .map((c, i) => ({
        value: c?.id!,
        displayValue: `${formatChapterLabel(i)} - ${c?.name}`,
        include: !!c && !c.rawVideoStorageId,
      }))
      .filter(o => !!o.include) || []

  const copyChapter = async (destinationChapterId: string, sourceChapterId: string) => {
    const { ok, data } = await videoService.copyVideo(destinationChapterId, sourceChapterId)
    if (ok && data) {
      // always copy script. If the video doesn't have a script, we ignore this call and use the original data value
      const { ok: ok2, data: data2 } = await videoService.copyScript(destinationChapterId, sourceChapterId)
      const fieldIndex = contentBlock!.contentBlockFields.findIndex(f => f.videoChapter?.id === destinationChapterId)
      dispatch({ type: 'UPDATE_VIDEO_CHAPTER', blockIndex: blockIndex!, fieldIndex, chapter: ok2 ? data2 : data })
      return true
    }
    return false
  }

  const copyChapterModify = async (destinationChapterId: string, sourceChapterId: string,videoChapterId?:string,rawVideoStorageId?:string) => {
    const { ok, data } = await videoService.copyVideo(destinationChapterId, sourceChapterId)
    if (ok && data) {
      // always copy script. If the video doesn't have a script, we ignore this call and use the original data value
      if(responseData?.cta && videoChapterId && rawVideoStorageId) {
        const responseExisting = await getVideoSuiteByVideoID(videoChapterId, previewId, rawVideoStorageId || '');
        const responseNew = await getVideoSuiteByVideoID(data?.id, previewId, data?.rawVideoStorageId  || '');
        let watermarkData = {
          ...defaultWatermarkData
        }

        if(responseExisting?.watermark && responseData.watermark){
          const parsedWatermark = JSON.parse(responseExisting?.watermark as string)
          watermarkData = {
            watermarkCloudinaryId: parsedWatermark.watermarkCloudinaryId,
            watermarkOpacity: parsedWatermark.watermarkOpacity,
            x: parsedWatermark.x,
            y: parsedWatermark.y,
            imageHeight: parsedWatermark.imageHeight,
            imageWidth: parsedWatermark.imageWidth
          }
        }

        const finalDataWatermark = {
          type: "watermark",
          data: JSON.stringify(watermarkData)
        }

        const finalData = {
          type: "cta",
          data: JSON.stringify(responseExisting?.cta)
        };

        if (responseNew) {
          await updateVideoSuite(responseNew?.id || '', finalData);
          if(responseData.watermark){
            await updateVideoSuite(responseNew?.id || '', finalDataWatermark)
          }
          setVideoSuite([])
        }
      }
      const { ok: ok2, data: data2 } = await videoService.copyScript(destinationChapterId, sourceChapterId)
      const fieldIndex = contentBlock!.contentBlockFields.findIndex(f => f.videoChapter?.id === destinationChapterId)
      dispatch({ type: 'UPDATE_VIDEO_CHAPTER', blockIndex: blockIndex!, fieldIndex, chapter: ok2 ? data2 : data })
      return true
    }
    return false
  }

  const copyToNewChapter = async (sourceChapterId: string, script: boolean) => {
    const apiCall = script ? videoService.copyScriptToNewChapter : videoService.copyVideoToNewChapter
    const { ok, data } = await apiCall(contentBlock!.id, sourceChapterId)
    if (ok && data) {
      dispatch({ type: 'ADD_CONTENT_BLOCK_FIELD', blockIndex: blockIndex!, field: data })
      return true
    }
    return false
  }

  const copyVideoAsset = async (script: boolean, sourceChapterId: string, destinationChapterId?: string) => {
    if (destinationChapterId) {
      return copyChapter(destinationChapterId, sourceChapterId)
    } else {
      return copyToNewChapter(sourceChapterId, script)
    }
  }

  const copyVideoAssetModify = async (script: boolean, sourceChapterId: string, destinationChapterId?: string, videoChapterId?:string,rawVideoStorageId?:string) => {
    if (destinationChapterId) {
      return copyChapterModify(destinationChapterId, sourceChapterId,videoChapterId,rawVideoStorageId)
    } else {
      return copyToNewChapter(sourceChapterId, script)
    }
  }

  useEffect(() => {
    const loadAssets = async () => {
      if (contentBlock) {
        if (contentBlock.contentBlockType.name === 'Video') {
          if (!isLoadingGlobal) {
            isLoadingGlobal = true
            dispatch({ type: 'SET_VIDEO_ASSETS_LOADING', value: true })
            const { ok, data } = await videoService.getAllVideoChapters()
            if (ok && data) {
              const videoAssets = data.filter(
                previewVideoChapters =>
                  previewVideoChapters.previewLite.id !== previewId &&
                  previewVideoChapters.videoChapters.some(
                    chapter => !!chapter.rawVideoStorageId || !!chapter.teleprompterText
                  )
              )
              dispatch({ type: 'SET_HAS_VIDEO_ASSETS', value: videoAssets.length > 0 })
              dispatch({ type: 'SET_VIDEO_ASSETS', value: videoAssets })
            } else {
              dispatch({ type: 'SET_HAS_VIDEO_ASSETS', value: false })
            }
            dispatch({ type: 'SET_VIDEO_ASSETS_LOADING', value: false })
            isLoadingGlobal = false
          }
        } else {
          dispatch({ type: 'SET_HAS_VIDEO_ASSETS', value: false })
        }
      }
    }
    loadAssets()
  }, [contentBlock, previewId, dispatch])

  useEffect(() => {
    if (!previewId) {
      dispatch({ type: 'SET_HAS_VIDEO_ASSETS', value: false })
    }
  }, [previewId, dispatch])

  return {
    isLoading: isBlockLoading || state.areVideoAssetsLoading,
    showVideoAssets,
    videoAssets: state.videoAssets,
    videoBlock: contentBlock,
    chapterOptions,
    copyVideoAsset,
    copyVideoAssetModify
  }
}

export default useVideoAssets
