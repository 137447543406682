import React from 'react'
import { ContentBlock } from 'state/types'
import { VIEW_DOCUMENT } from 'helpers/events'
import DownloadIcon from 'assets/icons/DownloadIcon'
import { trackEvent } from 'services/eventTrackingService'
import './PublishedPreviewDocuments.scss'

type Props = {
  block: ContentBlock
  previewId: string
  trackEvents: boolean
}

const PublishedPreviewDocuments: React.FC<Props> = ({ block, previewId, trackEvents }) => {
  const trackDocumentLinkClick = (contentBlockFieldId: string) => {
    if (trackEvents) {
      trackEvent({
        eventType: VIEW_DOCUMENT,
        previewId: previewId,
        contentBlockId: block.id,
        contentBlockFieldId: contentBlockFieldId,
      })
    }
  }
  return (
    <div className="published-preview-documents block">
      <div className="block-title">{block.previewLabel || block.formLabel}</div>
      {block.contentBlockFields
        ?.filter(field => field.controlType === 'File' && !!field.value)
        ?.map(field => (
          <a
            key={field.id}
            href={field.value}
            className="document"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackDocumentLinkClick(field.id)}
          >
            <div className="label">{field.formLabel}</div>
            <DownloadIcon />
          </a>
        ))}
    </div>
  )
}

export default PublishedPreviewDocuments
