import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PreviewSummary } from 'state/types'
import { getProfileImageUrl } from 'helpers/cloudinary'
import { getFullName } from 'helpers/names'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import ProfilePlaceholder from 'assets/profile-placeholder-grey.svg'
import './NextPreviewLink.scss'

type Props = {
  preview: PreviewSummary
  to: string
  isPrevious?: boolean
}

const NextPreviewLink: React.FC<Props> = ({ preview, to, isPrevious }) => {
  const [collapsibleContentWidth, setCollapsibleContentWidth] = useState(0)
  const [isCollapsing, setCollapsing] = useState(false)
  const collapsibleContentRef = useRef<HTMLDivElement>(null)
  const fullName = getFullName(preview.givenName, preview.familyName)
  const hoverRef = useRef(false)

  const onMouseEnter = () => {
    hoverRef.current = true
    setCollapsibleContentWidth(collapsibleContentRef.current?.scrollWidth || 0)
  }

  const onMouseLeave = () => {
    hoverRef.current = false
    setCollapsibleContentWidth(0)
    setCollapsing(true)
    setTimeout(() => setCollapsing(false), 100)
  }

  useEffect(() => {
    if (preview.id && hoverRef.current && collapsibleContentRef.current) {
      // Update width of hovered link when preview changes.
      const currentWidth = collapsibleContentRef.current.scrollWidth
      collapsibleContentRef.current.style.width = 'auto'
      const desiredWidth = collapsibleContentRef.current.scrollWidth
      collapsibleContentRef.current.style.width = currentWidth + 'px'
      setCollapsibleContentWidth(desiredWidth)
    }
  }, [preview.id])

  return (
    <Link
      className={
        'next-preview-link' +
        (isPrevious ? ' previous' : '') +
        (collapsibleContentWidth > 0 ? ' hover' : '') +
        (isCollapsing ? ' collapsing' : '')
      }
      to={to}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="link-content">
        <div className="user-summary">
          <div className="label">{isPrevious ? 'Prev' : 'Next'}</div>
          <div
            className="collapsible-content"
            ref={collapsibleContentRef}
            style={{ width: collapsibleContentWidth + 'px' }}
          >
            <div className="colon">:</div>
            <div className="name">{fullName}</div>
            <div className="image-container">
              <img
                alt=""
                src={preview.profileImageId ? getProfileImageUrl(preview.profileImageId) : ProfilePlaceholder}
              />
            </div>
          </div>
        </div>
        <ChevronRightIcon />
      </div>
    </Link>
  )
}

export default NextPreviewLink
