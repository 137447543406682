import { useCallback, useEffect } from 'react'
import useStore from 'state/useStore'
import useAuth from 'state/useAuth'
import * as invitationService from 'services/invitationService'
import { isPast } from 'helpers/date'
import { endOfYesterday } from 'date-fns'
import { InvitationSuccessPageInfo, VideoCategoryTemplate } from './types'

// Used for only loading the invitation once in the case where multiple components are using this hook simultaneously.
let isLoadingGlobal = false

const useInvitation = (
  invitationId?: string,
  page?: number,
  filterOption?: string,
  searchQuery?: string,
  repeatCall?: boolean,
  sortingBy?: string,
  sortingAsc?: boolean) => {
  const { state, dispatch } = useStore()
  const { user } = useAuth()
  const invitation = state.invitation
  const isLoading = state.isInvitationLoading

  const setStarred = async (previewId: string, value: boolean) => {
    if (invitationId) {
      dispatch({ type: 'SET_PREVIEW_STARRED', previewId, value })
      const { ok } = await invitationService.updateStarred(invitationId, previewId, value)
      if (!ok) {
        window.location.reload()
      }
    }
  }

  const setLabel = async (previewId: string, value: string) => {
    if (invitationId) {
      dispatch({ type: 'SET_PREVIEW_LABEL', previewId, value })
      const { ok } = await invitationService.updateLabel(invitationId, previewId, value)
      if (!ok) {
        window.location.reload()
      }
    }
  }

  const toggleStarred = (previewId: string) => {
    const starred = state.invitation?.previews?.find(p => p.id === previewId)?.starred
    if (starred !== undefined) {
      setStarred(previewId, !starred)
    }
    return !starred;
  }

  const setViewed = (previewId: string) => {
    if (invitationId) {
      dispatch({ type: 'SET_PREVIEW_VIEWED', previewId })
    }
  }

  const update = async (
    name: string,
    ownerName: string,
    industries?: string,
    startDate?: string,
    endDate?: string,
    instructions?: string,
    sendEmailToInviter?: boolean,
    businessLogoImageStorageId?: string,
    invitationSuccessPage?: InvitationSuccessPageInfo,
    videoCategoryTemplates?: VideoCategoryTemplate[]
  ) => {
    if (invitation && invitationId) {
      const { ok } = await invitationService.updateInvitation(
        invitationId,
        name,
        ownerName,
        industries,
        startDate,
        endDate,
        instructions,
        sendEmailToInviter,
        businessLogoImageStorageId,
        invitationSuccessPage,
        videoCategoryTemplates
      )

      if (ok) {
        dispatch({
          type: 'SET_INVITATION',
          value: {
            ...invitation,
            name,
            ownerName,
            industries,
            startDate,
            endDate,
            instructions,
            sendEmailToInviter,
            closed: !!endDate && isPast(endDate),
            open: !!!startDate || (!!startDate && isPast(startDate)),
            businessLogoImageStorageId: businessLogoImageStorageId,
            invitationSuccessPage: invitationSuccessPage,
            videoCategoryTemplates: videoCategoryTemplates ? videoCategoryTemplates : [],
          },
        })
      } else {
        window.location.reload()
      }
      return ok
    }

    return false
  }

  const openNow = async () => {
    if (invitation) {
      return update(
        invitation.name,
        invitation.ownerName,
        invitation.industries,
        undefined,
        invitation.endDate,
        invitation.instructions,
        invitation.sendEmailToInviter,
        invitation.businessLogoImageStorageId,
        invitation.invitationSuccessPage,
        invitation.videoCategoryTemplates
      )
    }
    return false
  }

  const closeNow = async () => {
    if (invitation) {
      return update(
        invitation.name,
        invitation.ownerName,
        invitation.industries,
        invitation.startDate,
        endOfYesterday().toISOString(),
        invitation.instructions,
        invitation.sendEmailToInviter,
        invitation.businessLogoImageStorageId,
        invitation.invitationSuccessPage,
        invitation.videoCategoryTemplates
      )
    }
    return false
  }

  const sampleData = {
    archived: false,
    closed: false,
    endDate: "2024-08-16T15:59:00Z",
    id: "re4rmq",
    industries: "[\"advertising-arts-media\"]",
    instructions: "New Instructions",
    invitationSuccessPage:{
      logo: "mhmgoje5zqwd2lcvenyr",
      message: "Nice",
      returnLink: "https://chatgpt.com/",
      returnLinkTitle: "Return to websiote",
      title: "Done creating",
      name: "NEw Invitation testing ",
      open: false,
      organizationId: "02d6b857-f568-4b09-ad62-a2afe8dabb7f",
      ownerName: "Test Test Inv",
      previewCount: 102,
      previews:[
        {

          email: "rirey59387@xitudy.com",
          familyName
            :
            "User Org",
          firstPublishDate
            :
            "2024-08-01T05:35:39.909786Z",
          givenName
            :
            "Newest",
          id
            :
            "6pd0v1",
          phoneNumber
            :
            "",
          starred
            :
            false,
          viewed
            :
            true
        }
      ]
    }
  }

  const clear = useCallback(() => dispatch({ type: 'SET_INVITATION', value: undefined }), [dispatch])
  useEffect(() => {
    const loadInvitation = async () => {
      if (invitationId && (invitation?.id !== invitationId || repeatCall) && !isLoadingGlobal && user) {
        isLoadingGlobal = true
        dispatch({ type: 'SET_INVITATION_LOADING', value: true })
        dispatch({ type: 'SET_INVITATION', value: undefined })
        const { ok, data } = await invitationService.getInvitationDetails(invitationId, page, filterOption, searchQuery, sortingBy, sortingAsc)
        if (ok && data) {
          // Ensure that data.videoCategoryTemplates is not null or undefined
          if (data.videoCategoryTemplates) {
            // fix PM-1668: this will ensure position display will always in correct sequence.
            data.videoCategoryTemplates.forEach((vct, i) => {
              vct.position = i + 1;
            });
          }
          dispatch({ type: 'SET_INVITATION', value:  data  })
        }
        dispatch({ type: 'SET_INVITATION_LOADING', value: false })
        isLoadingGlobal = false
      }
    }
    loadInvitation()
  }, [invitationId, invitation, user, page, filterOption, searchQuery, sortingBy, sortingAsc])

  return {
    isLoading,
    invitation,
    setLabel,
    toggleStarred,
    update,
    clear,
    setViewed,
    openNow,
    closeNow,
  }
}

export default useInvitation
