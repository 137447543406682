import React from 'react'
import { PreviewAnalyticsRange } from 'state/types'
import Dropdown from 'components/shared/Dropdown/Dropdown'
import './AnalyticsRangeDropdown.scss'

type Props = {
  className?: string
  value?: PreviewAnalyticsRange
  onChange?: (value: PreviewAnalyticsRange) => void
}

const storageKey = 'analytics-time-range'

export const getDefaultRange = (): PreviewAnalyticsRange =>
  (localStorage.getItem(storageKey) as PreviewAnalyticsRange) || 'all-time'

const options: { value: PreviewAnalyticsRange; displayValue: string }[] = [
  { value: 'last-24-hours', displayValue: 'Last 24 hours' },
  { value: 'last-7-days', displayValue: 'Last 7 days' },
  { value: 'last-30-days', displayValue: 'Last 30 days' },
  { value: 'last-90-days', displayValue: 'Last 90 days' },
  { value: 'all-time', displayValue: 'All time' },
]

const AnalyticsRangeDropdown: React.FC<Props> = ({ className, value, onChange }) => {
  const onValueChange = (value: any) => {
    localStorage.setItem(storageKey, value)
    onChange?.(value)
  }
  return (
    <div className={'analytics-range-dropdown' + (className ? ' ' + className : '')}>
      <div className="analytics-range-dropdown-label">Period:</div>
      <Dropdown selectedValue={value} onChange={onValueChange} options={options} />
    </div>
  )
}

export default AnalyticsRangeDropdown
