import DialogCloseIcon from 'assets/icons/DialogCloseIcon'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import FileSelectorButton from 'components/shared/FileSelectorButton/FileSelectorButton'
import ProgressBar from 'components/shared/ProgressBar/ProgressBar'
import { imageFileSizeLimitInBytes, imageFileSizeLimitInMb } from 'helpers/media'
import React, { useState } from 'react'
import './LogoSelector.scss'
import { getBusinessLogoImageUrl } from '../../../../helpers/cloudinary'

type Props = {
  value?: string
  name?: string
  canDelete: boolean
  type:string
  warnBeforeDeleting: boolean
  deleteConfirmationText?: string
  deleteForbiddenText?: string
  showUploadingLabel?: boolean
  showUseLogoImageButton?: boolean
  disabled?: boolean
  uploadImage?: (blob: Blob, onProgressChange: (progress: number) => void) => Promise<void>
  uploadImageLocally?: (blob: Blob) => void
  deleteImage: () => void
  useLogoImage?: () => void
}

const LogoImageSelector: React.FC<Props> = ({
  value,
  name,
  canDelete,
  type,
  warnBeforeDeleting,
  deleteConfirmationText,
  deleteForbiddenText,
  showUploadingLabel,
  showUseLogoImageButton,
  uploadImage,
  uploadImageLocally,
  deleteImage,
  useLogoImage,
  disabled = true,
}) => {
  const [isUploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isDeleteDialogShowing, setDeleteDialogShowing] = useState(false)
  const [isDeleteErrorDialogShowing, setDeleteErrorDialogShowing] = useState(false)
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)

  const onSelectedFileChange = async (file: File | null) => {
    if (file) {
      if (file.size > imageFileSizeLimitInBytes) {
        setFileSizeErrorDialogShowing(true)
      } else if (uploadImage) {
        setUploading(true)
        setProgress(0)
        await uploadImage(file, setProgress)
        setUploading(false)
      } else if (uploadImageLocally) {
        setUploading(true)
        await uploadImageLocally(file)
        setUploading(false)
      }
    }
  }

  const onDeleteImageClick = () => {
    if (!canDelete) {
      setDeleteErrorDialogShowing(true)
    } else if (warnBeforeDeleting) {
      setDeleteDialogShowing(true)
    } else {
      deleteImage()
    }
  }
  return (
    <>
      <div className={'logo-image-selector' + (disabled ? ' disabled' : '')}>
        {value && <img className={type === 'favicon' ? 'logo-image favicon' : 'logo-image'} src={getBusinessLogoImageUrl(value)} />}

        {value && (
          <button type="button" className="close-button plain" onClick={onDeleteImageClick}>
            <DialogCloseIcon />
          </button>
        )}

        {!isUploading ? (
          <div className={'upload-text-block' + (!!value ? ' has-value' : '')}>
            <FileSelectorButton
              accept=".svg,.png,.jpeg"
              disabled={disabled}
              onSelectedFileChange={onSelectedFileChange}
              className="upload-image-button link"
            >
              {`${value ? 'Change' : 'Upload your'}`} {type === 'favicon' ? 'favicon file' : 'logo file'}
            </FileSelectorButton>
            <span className="upload-text-small">Max file size is 10 MB</span>
            {showUseLogoImageButton && (
              <>
                <div className="separator">
                  <div className="separator-line" />
                  <div className="separator-text">Or</div>
                  <div className="separator-line" />
                </div>
                <button className="use-logo-image-button link" onClick={useLogoImage}>
                  Use logo image
                </button>
              </>
            )}
          </div>
        ) : (
          <div className="uploading-buttons">
            {showUploadingLabel && <div className="uploading-label">Uploading...</div>}
            <div className="progress-bar-container">
              <ProgressBar progress={progress} />
            </div>
          </div>
        )}
      </div>
      <ConfirmationDialog
        isShowing={isDeleteDialogShowing}
        cancelButtonText="No, cancel that"
        okButtonText="Yes, delete this image"
        title="Heads up!"
        isWarning
        onClose={() => setDeleteDialogShowing(false)}
        onOk={deleteImage}
      >
        {deleteConfirmationText}
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isDeleteErrorDialogShowing}
        okButtonText="Ok"
        title="Logo image is required"
        onClose={() => setDeleteErrorDialogShowing(false)}
      >
        {deleteForbiddenText}
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Image too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
      >
        {`This image is too large. Please choose an image file smaller than ${imageFileSizeLimitInMb} MB.`}
      </ConfirmationDialog>
    </>
  )
}

export default LogoImageSelector
