import React from 'react'

const TrendArrowLevel = () => (
  <svg className="icon" width="22" height="22">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1140.000000, -537.000000)" stroke="#4A4567" strokeWidth="1.5444">
        <g transform="translate(1149.000000, 542.000000) rotate(-315.000000) translate(-1149.000000, -542.000000) translate(1142.000000, 535.000000)">
          <path
            d="M5.5,15 L5.5,2"
            transform="translate(5.500000, 8.500000) rotate(-135.000000) translate(-5.500000, -8.500000) "
          ></path>
          <polyline
            transform="translate(9.389087, 4.610913) rotate(-135.000000) translate(-9.389087, -4.610913) "
            points="13.3890873 2.6109127 9.3890873 6.6109127 5.3890873 2.6109127"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
)

export default TrendArrowLevel
