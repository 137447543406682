import { Menu } from '@material-ui/core'
import React, { useState } from 'react'
import './DropdownMenu.scss'

type Props = {
  menuContent: React.ReactNode
  trigger: React.ReactNode
  horizontalAlignment: number | 'center' | 'left' | 'right'
}

const DropdownMenu: React.FC<Props> = ({ trigger, menuContent, horizontalAlignment }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="dropdown-menu">
      <div onClick={handleMenu} className="trigger">
        {trigger}
      </div>
      <Menu
        className="menu"
        anchorEl={anchorEl}
        open={open}
        autoFocus={false}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: horizontalAlignment }}
        transformOrigin={{ vertical: 'top', horizontal: horizontalAlignment }}
        disablePortal
      >
        <div className="content" onClick={handleMenuClose}>
          {menuContent}
        </div>
      </Menu>
    </div>
  )
}

export default DropdownMenu
