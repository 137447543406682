import BackArrowIcon from 'assets/icons/BackArrowIcon'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { capitalize } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Product, Workspace } from 'state/types'
import './ChangePlanPageLayout.scss'
import { Country, SubscriptionPlan } from '../../../helpers/enum'

type Props = {
  title: string
  backLink: string
  cancelLink: string
  plan?: Product
  price: string
  contentLoading?: boolean
  onConfirm: () => Promise<void>
  editedNewPlanName: string | undefined
  toggle: string
  currentWorkspace?: Workspace
}

const ChangePlanPageLayout: React.FC<Props> = ({
  title,
  backLink,
  cancelLink,
  plan,
  price,
  contentLoading,
  children,
  onConfirm,
  editedNewPlanName,
  toggle,
  currentWorkspace
}) => {
  const mountedRef = useRef(true)
  const [submitting, setSubmitting] = useState(false)

  const onConfirmClick = async () => {
    setSubmitting(true)
    await onConfirm()
    if (mountedRef.current) {
      setSubmitting(false)
    }
  }

  const getMonthlyPrice = (title: string | undefined, price: number | undefined) => {
    if(price) {
      switch (title) {
        case 'Solo'  :
          return Number(toggle) === 2 ? price / 12 : price
        case 'Business' :
          return Number(toggle) === 2 ? price / 12 : price / 3
        case 'Enterprise' :
          return Number(toggle) === 2 ? price / 12 : price / 3
        default:
          return price
      }
    }
  }

  useEffect(
    () => () => {
      mountedRef.current = false
    },
    []
  )

  return (
    <div className="change-plan-page">
      <header>
        <div className="header-content">
          <Link className="cancel-arrow-link" to={backLink}>
            <BackArrowIcon />
          </Link>
          <h1>{title}</h1>
          <div className="illustration-container">
            <div className="illustration" />
          </div>
        </div>
      </header>
      <div className="page-content">
        <div className="new-plan">New Plan</div>
        {contentLoading ? (
          <LoadingSpinner container />
        ) : (
          <>
            <div className="plan-row">
              <div className="plan-name">
                <div className={'circle' + (plan?.name ? ' ' + plan.name : '')} />
                <div className="text">{plan ? editedNewPlanName : 'Free'}</div>
              </div>
              <div className="pricing-section">
                <div className='price'>{price}</div>
                {((plan?.name !== SubscriptionPlan.Basic)) &&
                  <div className={'price-breakdown'}>
                    <i>(US${getMonthlyPrice(plan?.name, plan?.prices[0].amount)}/month
                      {((plan?.name === SubscriptionPlan.Business || plan?.name === SubscriptionPlan.Enterprise)) ?
                        <span> billed quarterly</span> : <span> billed monthly</span>})</i>
                  </div>
                }`
              </div>

            </div>
            <>
              <div className="body-text">{children}</div>
              <div className="separator" />
              <div className="spacer" />
              <div className="buttons">
                <Link className="cancel-link" to={cancelLink}>
                  Cancel
                </Link>
                <LoadingButton className="confirm-button primary" isLoading={submitting} onClick={onConfirmClick}>
                  Confirm
                </LoadingButton>
              </div>
            </>
          </>
        )}
      </div>
    </div>
  )
}

export default ChangePlanPageLayout
