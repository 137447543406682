import React from 'react'
import { CSSTransition } from 'react-transition-group'
import './LoadingSpinner.scss'

type Props = {
  fullScreen?: boolean
  className?: string
  container?: boolean
  expand?: boolean
}

const LoadingSpinner: React.FC<Props> = ({ fullScreen, className, container, expand, children }) =>
  fullScreen || children || container ? (
    <CSSTransition in appear timeout={1000}>
      <div
        className={
          'loading-spinner-container' +
          (className ? ' ' + className : '') +
          (fullScreen ? ' fullscreen' : '') +
          (expand ? ' expand' : '')
        }
      >
        <div className="loading-spinner" />
        {children && <div className="text">{children}</div>}
      </div>
    </CSSTransition>
  ) : (
    <div className={'loading-spinner' + (className ? ' ' + className : '')} />
  )

export default LoadingSpinner
