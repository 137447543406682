import Bowser from 'bowser'

export const browserInfo = Bowser.parse(window.navigator.userAgent)

export const isIos = browserInfo.os.name === 'iOS'
export const isAndroid = browserInfo.os.name === 'Android'
export const isFirefox = browserInfo.browser.name === 'Firefox'
export const isChrome = browserInfo.browser.name === 'Chrome'
export const isIE = browserInfo.browser.name === 'Internet Explorer'
export const isSafari = browserInfo.browser.name === 'Safari'
export const isMicrosoftEdge = browserInfo.browser.name === 'Microsoft Edge'
export const isMobile = browserInfo.platform.type === 'mobile'
export const isTablet = browserInfo.platform.type === 'tablet'
