import React, { useState, useRef, useEffect } from 'react'
import throttle from 'lodash/throttle'
import { formatVideoTime, isVideoPlaying } from 'helpers/media'
import VideoPlayerWithTrimFeatures from 'components/video/VideoPlayerWithTrimFeatures/VideoPlayerWithTrimFeatures'
import VideoTrimSlider from 'components/video/VideoTrimSlider/VideoTrimSlider'
import PlayPauseButton from 'components/video/PlayPauseButton/PlayPauseButton'
import './VideoTrimEditor.scss'
import { useParams } from 'react-router-dom'
import useVideoChapter from '../../../state/useVideoChapter'
import { parseIndexParam } from '../../../helpers/urlParams'

const throttleDelayMillis = 10

type Props = {
  initialStartOffsetTime: number
  initialEndOffsetTime: number
  videoBlob: Blob
  onSave: (startTime: number, endTime: number) => void
  onStartOffsetChange: (time: number) => void
  onEndOffsetChange: (time: number) => void
  onStartEndTimeParentChange: (items: { start: number; end: number; }[]) => void;
  responseData: {
    watermark: boolean;
    cta: boolean;
    videoFx: boolean;
    images: boolean;
    captions: boolean;
  };
  plan: string
}

const VideoTrimEditor: React.FC<Props> = ({ onStartEndTimeParentChange,responseData,initialStartOffsetTime, initialEndOffsetTime, videoBlob,onStartOffsetChange, onEndOffsetChange,plan  }) => {
  const [isPlaying, setPlaying] = useState(false)
  const [startOffsetTime, setStartOffsetTime] = useState(initialStartOffsetTime)
  const [endOffsetTime, setEndOffsetTime] = useState(initialEndOffsetTime)
  const [currentTime, setCurrentTime] = useState(startOffsetTime)
  const [duration, setDuration] = useState(0)
  const [src, setSrc] = useState<string | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const [fontSize, setFontSize] = useState<string>('');
  const [startEndOffsetTime, setStartEndOffsetTime] = useState<{ start: number; end: number; }[]>([]);
  const allowCustomControls = true
  const { previewId, index, fieldIndex } = useParams<any>()
  const {  videoChapter } = useVideoChapter(previewId, parseIndexParam(index), parseIndexParam(fieldIndex), true)
  const togglePlaying = () => {
    const video = videoRef.current
    if (video) {
      if (!isVideoPlaying(video)) {
        // E.g. if user is at end of video and presses play, play from the start again.
        if (video.currentTime >= duration - endOffsetTime - 0.01 || video.currentTime < startOffsetTime) {
          video.currentTime = startOffsetTime
        }
        video.play()
        setPlaying(true)
        intervalRef.current = setInterval(updatePlayTime, 30)
      } else {
        stopIfPlaying()
      }
    }
  }

  useEffect(() => {
    if (startOffsetTime !== initialStartOffsetTime) {
      onStartOffsetChange(startOffsetTime);
    }
  }, [startOffsetTime, initialStartOffsetTime, onStartOffsetChange]);

  useEffect(() => {
    onEndOffsetChange(endOffsetTime);
  }, [endOffsetTime, startOffsetTime]);




  useEffect(() => {
    const fetchStartEndTime = async () => {
      try {
        const formattedStartEndOffsetTime = startEndOffsetTime.map(item => ({
          start: item.start,
          end: item.end
        }));
        onStartEndTimeParentChange(formattedStartEndOffsetTime);
      } catch (error) {
        console.error("Error in fetching and processing", error);
      }
    };
    fetchStartEndTime();
  }, [startEndOffsetTime]);


  const updatePlayTime = () => {
    const video = videoRef.current
    if (video) {
      setCurrentTime(Math.max(startOffsetTime, Math.min(duration - endOffsetTime, video.currentTime)))
      if (video.currentTime >= duration - endOffsetTime) {
        stopIfPlaying()
      }
    }
  }

  const updateVideoTimeThrottled = throttle((time: number) => {
    const video = videoRef.current
    if (video) {
      stopIfPlaying()
      video.currentTime = time
    }
  }, throttleDelayMillis)

  const stopIfPlaying = () => {
    const video = videoRef.current
    if (video && isVideoPlaying(video)) {
      video.pause()
      setPlaying(false)
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }

  const onSliderTimeChange = (time: number) => {
    setCurrentTime(time)
    updateVideoTimeThrottled(time)
  }

  const onSliderMonitoringChange = (time: number) => {
    updateVideoTimeThrottled(time)
  }


  useEffect(() => {
    updateVideoTimeThrottled(duration - endOffsetTime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endOffsetTime])

  useEffect(() => {
    updateVideoTimeThrottled(startOffsetTime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOffsetTime])

  useEffect(() => {
    const url = URL.createObjectURL(videoBlob)
    setSrc(url)
    return () => {
      URL.revokeObjectURL(url)
      setSrc(null)
    }
  }, [videoBlob])

  const revertToOriginal = () => {
    setStartOffsetTime(initialStartOffsetTime);
    setEndOffsetTime(initialEndOffsetTime);
    setCurrentTime(initialStartOffsetTime);
    stopIfPlaying();
    onStartOffsetChange(initialStartOffsetTime);
    onEndOffsetChange(initialEndOffsetTime);
  };

  return (
    <>
    <div className="video-trim-editor">
      <VideoPlayerWithTrimFeatures
        responseData={responseData}
        key={src}
        hidePlayPauseButton={false}
        src={src || undefined}
        customControls={allowCustomControls}
        nativeControls={!allowCustomControls}
        videoRef={videoRef}
        onDurationChange={setDuration}
        profileImageStorageId={videoChapter?.profileImageStorageId}
        audioOnly={videoChapter?.type === 'audio'}
        plan={plan}
      />


      <div className="video-trim-controls">
        {duration > 0 && isFinite(duration) && (
          <>
            <PlayPauseButton isPlaying={isPlaying} togglePlaying={togglePlaying} />
            <div className="video-time">
              <span>{formatVideoTime(currentTime)}</span>
              <span className="separator">/</span>
              <span>{formatVideoTime(duration)}</span>
            </div>
            <VideoTrimSlider
              responseData={responseData}
              currentTime={currentTime}
              startTime={startOffsetTime}
              endTime={endOffsetTime}
              duration={duration}
              onCurrentMonitoringTimeChange={onSliderMonitoringChange}
              onCurrentTimeChange={onSliderTimeChange}
              onStartTimeChange={setStartOffsetTime}
              onStartEndTimeChange={setStartEndOffsetTime}
              onEndTimeChange={setEndOffsetTime}
              plan={plan}
            />
          </>
        )}
      </div>
    </div>
   </>
  )
}

export default VideoTrimEditor
