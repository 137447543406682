import { Collapse, List, ListItem } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import AnalyticsIcon from 'assets/icons/AnalyticsIcon'
import InvitationsIcon from 'assets/icons/InvitationsIcon'
import PreviewsIcon from 'assets/icons/PreviewsIcon'
import SettingsIcon from 'assets/icons/SettingsIcon'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ModalHighlight, Nav } from 'state/types'
import './NavItems.scss'

type NavItemProps = {
  navLink: string
  className?: string
  onClick?: () => void
  isActive?: (pathname: string) => boolean
}

const NavItem: React.FC<NavItemProps> = ({ navLink, className, children, onClick, isActive }) => {
  const { pathname } = useLocation()
  const active = isActive ? isActive(pathname) : pathname === navLink

  return (
    <>
      <Link
        to={navLink}
        className={'nav-item' + (className ? ' ' + className : '') + (active ? ' active' : '')}
        onClick={onClick}
      >
        <div className="selection-bar" />
        {children}
      </Link>
    </>
  )
}

type ParentNavItemProps = {
  navLink: string
  isOpen: boolean
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  isModalHighlight: boolean
}

const ParentNavItem: React.FC<ParentNavItemProps> = ({ navLink, isOpen, children, onClick, isModalHighlight }) => {
  return (
    <Link to={navLink} className={`nav-item parent ${isModalHighlight && 'modal-highlight'}`} onClick={onClick}>
      {children}
      {isOpen ? <ExpandLess className="expand-icon" /> : <ExpandMore className="expand-icon" />}
    </Link>
  )
}

type CildNavItemProps = {
  navLink: string
  onClick?: () => void
}

const ChildNavItem: React.FC<CildNavItemProps> = ({ navLink, children, onClick }) => {
  const { pathname } = useLocation()
  const active: boolean = pathname === navLink

  return (
    <Link to={navLink} className={'nav-item child' + (active ? ' child-nav-active' : '')} onClick={onClick}>
      {children}
    </Link>
  )
}

type NestedListItemProps = {
  parentNav: Nav
  childNavs: Nav[]
  parentNavIcon: JSX.Element
  showMobileView?: boolean
  onClick?: () => void
  modalHighlight?: ModalHighlight
}

const NestedListItem: React.FC<NestedListItemProps> = ({
  parentNav,
  childNavs,
  parentNavIcon,
  showMobileView,
  onClick,
  modalHighlight,
}) => {
  const { pathname } = useLocation()
  const isPathnameChild = childNavs.some(nav => nav.link === pathname)
  const [isOpen, setOpen] = useState<boolean>(isPathnameChild)

  useEffect(() => {
    setOpen(isPathnameChild)
  }, [isPathnameChild])

  const handleParentNavItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (showMobileView) {
      event.preventDefault()
      if (!isPathnameChild) {
        setOpen(!isOpen)
      }
    } else if (!showMobileView) {
      if (isOpen) {
        event.preventDefault()
      } else {
        setOpen(true)
      }
    }
  }

  return (
    <>
      <ListItem button>
        <ParentNavItem
          navLink={parentNav.link}
          isOpen={isOpen}
          onClick={handleParentNavItemClick}
          isModalHighlight={modalHighlight === 'Organisation Settings'}
        >
          {parentNavIcon}
          <span>{parentNav.name}</span>
        </ParentNavItem>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childNavs.map((nav: Nav, index: number) => {
            return (
              <ListItem button key={index}>
                <ChildNavItem navLink={nav.link} onClick={onClick}>
                  <span>{nav.name}</span>
                </ChildNavItem>
              </ListItem>
            )
          })}
        </List>
      </Collapse>
    </>
  )
}

type NavItemsProps = {
  showMobileView?: boolean
  isWorkspaceTypeOrganization?: boolean
  onLinkClick?: () => void
  modalHighlight?: ModalHighlight
}

const NavItems: React.FC<NavItemsProps> = ({
  isWorkspaceTypeOrganization,
  showMobileView,
  onLinkClick,
  modalHighlight,
}) => {
  return (
    <div className="nav-items">
      <List component="nav">
        <ListItem button>
          <NavItem
            navLink="/"
            className={`previews ${modalHighlight === 'previews' && 'modal-highlight'}`}
            onClick={onLinkClick}
            isActive={p => p === '/' || p.startsWith('/edit')}
          >
            <PreviewsIcon />
            <span>Previews</span>
          </NavItem>
        </ListItem>
        <ListItem button>
          {isWorkspaceTypeOrganization && (
            <NavItem
              navLink="/invitations"
              className={`invitations ${modalHighlight === 'invitations' && 'modal-highlight'}`}
              onClick={onLinkClick}
              isActive={p => p.startsWith('/invitation')}
            >
              <InvitationsIcon />
              <span>Invitations</span>
            </NavItem>
          )}
        </ListItem>
        <ListItem button>
          <NavItem
            navLink="/analytics"
            className="analytics"
            onClick={onLinkClick}
            isActive={p => p.startsWith('/analytics')}
          >
            <AnalyticsIcon />
            <span>Analytics</span>
          </NavItem>
        </ListItem>
        {isWorkspaceTypeOrganization && (
          <NestedListItem
            onClick={onLinkClick}
            parentNav={{ name: 'Workspace Settings', link: '/organization/settings/profile' }}
            childNavs={[
              { name: 'Profile', link: '/organization/settings/profile' },
              { name: 'Brand', link: '/organization/settings/brand' },
              { name: 'Team Members', link: '/organization/settings/teamMembers' },
              { name: 'Plan / Billing', link: '/organization/settings/planBilling' },
              { name: 'Integrations', link: '/organization/settings/integrations' },
            ]}
            parentNavIcon={<SettingsIcon />}
            showMobileView={showMobileView}
            modalHighlight={modalHighlight}
          />
        )}
      </List>
    </div>
  )
}

export default NavItems
