import React from 'react'

const CancelIcon = () => (
  <svg className="icon" width="40px" height="40px" viewBox="0 0 40 40">
    <g strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1458.000000, -25.000000)">
        <g transform="translate(1458.000000, 25.000000)">
          <circle cx="20" cy="20" r="20"></circle>
          <g transform="translate(12.000000, 13.000000)" strokeLinecap="round">
            <path
              d="M7.5,-2 L7.5,16"
              transform="translate(7.500000, 7.000000) rotate(-45.000000) translate(-7.500000, -7.000000) "
            ></path>
            <path
              d="M7.5,-2 L7.5,16"
              transform="translate(7.500000, 7.000000) rotate(-315.000000) translate(-7.500000, -7.000000) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CancelIcon
