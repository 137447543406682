import React from 'react'

const PreviewMeIcon = () => (
  <svg className="icon" width="20" height="20" viewBox="0 -2 20 20">
    <path
      className="cls-1"
      d="M14.54,2.12H5.11A4.88,4.88,0,0,0,.24,7v5.65a.68.68,0,0,0,.68.69H14.54a4.87,4.87,0,0,0,4.87-4.88V7A4.88,4.88,0,0,0,14.54,2.12Zm-3.82,8.42H9.52V7.41c0-.68-.26-1.19-1-1.19s-1.18.47-1.18,1.32v3H6.16V7.41c0-.69-.27-1.19-1-1.19A1.14,1.14,0,0,0,4,7.5v3H2.81V5.31H3.92V6a1.68,1.68,0,0,1,1.56-.82A1.73,1.73,0,0,1,7.05,6a2.14,2.14,0,0,1,1.77-.83,1.85,1.85,0,0,1,1.9,2.07Zm6-2.27h-4a1.39,1.39,0,0,0,1.45,1.36,1.27,1.27,0,0,0,1.22-.74h1.21a2.49,2.49,0,0,1-2.46,1.77,2.56,2.56,0,0,1-2.6-2.74,2.58,2.58,0,0,1,2.59-2.73A2.54,2.54,0,0,1,16.75,7.9C16.75,8.09,16.74,8.27,16.74,8.27Z"
    />
    <path className="cls-1" d="M14.18,6.17a1.36,1.36,0,0,0-1.39,1.22h2.77A1.32,1.32,0,0,0,14.18,6.17Z" />
  </svg>
)

export default PreviewMeIcon
