import React, { useState } from 'react'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import '../VideoSuiteDialog/VideoSuiteDialog.scss'
import { Checkbox } from '@material-ui/core'
import InfoIcon from '../../shared/InfoIcon/InfoIcon'
import MoreOptionsIcon from '../../../assets/icons/MoreOptionsIcon'

type Props = {
  isShowing: boolean
  onClose: () => void
  hideActions?: boolean
  isInvitationResponse?: boolean
  areCustomInstructionsPresent?: boolean
}

export const adhocDontShowAgainMessageKey = 'ad-hoc-dont-show-again-message-key'
export const nonCustomVideoChaptersInvitationResponseDontShowAgainMessageKey =
  'invitation-response-dont-show-again-message-key'
export const customVideoChaptersInvitationResponseDontShowAgainMessageKey =
  'custom-video-chapters-invitation-response-dont-show-again-message-key'

const VideoSuiteDialog: React.FC<Props> = ({
  isShowing,
  onClose,
  hideActions,
  isInvitationResponse,
  areCustomInstructionsPresent,
}) => {
  const [isChecked, setChecked] = useState(false)
  const handleCheckBox = () => {
    localStorage.setItem(
      isInvitationResponse && areCustomInstructionsPresent
        ? customVideoChaptersInvitationResponseDontShowAgainMessageKey
        : isInvitationResponse && !areCustomInstructionsPresent
        ? nonCustomVideoChaptersInvitationResponseDontShowAgainMessageKey
        : adhocDontShowAgainMessageKey,
      `${!isChecked}`
    )
    setChecked(!isChecked)
  }

  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      <div className="video-suite-dialog">
        <h3 className="title">Welcome to PreviewMe's Media suite. </h3>
        {isInvitationResponse && areCustomInstructionsPresent ? (
          <>
            <p>
              You've been asked to record or upload video(s) and/or make an audio recording as part of this application.
            </p>
            <p>
              The title for each required chapter has been pre-built for you and what is being asked can be found by
              clicking ‘Instructions’ on each chapter.
            </p>
            <p>
              If you've created videos in PreviewMe before that could be relevant to this application (e.g. ‘About Me’)
              you can reuse them by clicking 'Add from Assets'.
            </p>
            <p>
              Add additional video chapters as required. Our teleprompter is also available on Android, PC, Apple Mac
              (not IOS) and we recommend using Google Chrome for the best experience.
            </p>
          </>
        ) : isInvitationResponse && !areCustomInstructionsPresent ? (
          <>
            <p>
              You can find instructions on the videos you have been asked to record in the first step of this
              application. Additional video chapters can be added as needed.
            </p>
            <p>
              If you you’ve created videos in PreviewMe before that are relevant to this application (e.g. ‘About Me’)
              you can upload them by clicking ‘Add from Assets.’
            </p>
            <p>Our teleprompter is available on Android, PC and Apple Mac (not IOS).</p>
            <p>We recommend using Google Chrome for the best experience.</p>
          </>
        ) : (
          <>
            <p>This is where you can create/upload videos or audio recordings into your Preview.</p>
            <p>
              Chapter title/ content suggestions are based on the template you've selected. Add more Chapters as you
              need to.
            </p>
            <p>
              If you've created videos or audio recordings in PreviewMe before that are relevant to this application you
              can upload them by clicking 'Add from Assets'.
            </p>
            <p>
              Our teleprompter is available on Android, PC, Apple Mac (not IOS) and we recommend using Google Chrome for
              the best experience.
            </p>
          </>
        )}

        {!hideActions && (
          <>
            <div className="info-popup-text">
              <span>You can view this pop up again by clicking the</span>
              <InfoIcon />
              <span>next to 'Media Content'.</span>
            </div>
            <div className={'dont-show-again'} onClick={handleCheckBox}>
              <Checkbox className={'checkbox'} color={'primary'} checked={isChecked} />
              <p className={'message'}>Don’t show me again</p>
            </div>
            <button className="primary button" onClick={onClose}>
              Got it!
            </button>
          </>
        )}
      </div>
    </CloseableDialog>
  )
}

export default VideoSuiteDialog
