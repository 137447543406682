import React from 'react'
import { Option } from 'state/types'
import { parseDropdownFreeText } from 'helpers/validation'
import './DropdownFreeText.scss'
import CrossIcon from '../../../assets/icons/CrossIcon'
import Instructions from '../Instructions/Instructions'

type Props = {
  name?: string
  label?: string
  placeholder?: string
  value?: string
  instructions?: string | undefined
  options: Array<Option>
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  onChange?: (value?: string) => void
  otherFreeText?: boolean
  removeFromList?: () => void
}

const DropdownFreeText: React.FC<Props> = ({
  name,
  placeholder,
  value,
  instructions,
  label,
  options,
  inputProps,
  onChange,
  otherFreeText,
  removeFromList,
}) => {
  const { dropdown, freeText } = parseDropdownFreeText(value)
  const isDropdownOther = dropdown.toLowerCase() === 'other'
  let controlLabel: string | undefined
  const labels = label?.split('|')
  if (labels) {
    controlLabel = labels[0]
  }

  return (
    <div className="form-input-container">
      <div className="form-input-label-row">
        <div>
          {controlLabel !== 'undefined' ? <label className="form-input-label">{controlLabel}</label> : null}
          {instructions && <Instructions instructions={instructions} />}
        </div>
        {removeFromList && (
          <button type="button" className="remove-button plain phone" onClick={removeFromList}>
            <CrossIcon />
            <span>Remove</span>
          </button>
        )}
      </div>

      <div className="form-dropdown-free-text-controls-row">
        <select
          id={'select' + (name ? '-' + name : '')}
          value={dropdown}
          onChange={e => onChange?.(JSON.stringify({ dropdown: e.target.value, freeText }))}
        >
          <option key="no-selection" value="">
            {''}
          </option>
          {options.map(({ value, displayValue }) => (
            <option key={value} value={value}>
              {displayValue}
            </option>
          ))}
        </select>
        {(isDropdownOther || !otherFreeText) && (
          <input
            id={'input-' + name}
            placeholder={placeholder}
            value={freeText}
            onChange={e => onChange?.(JSON.stringify({ dropdown, freeText: e.target.value }))}
            {...inputProps}
          />
        )}
      </div>
    </div>
  )
}

export default DropdownFreeText
