import { Checkbox, FormControlLabel } from '@material-ui/core'
import React, { useState } from 'react'
import './AgreementSelector.scss'
type Props = {
  inputRef?: any
  label?: string
  otherErrorMessages?: string
  name?: string
  formErrors?: any
}

const AgreementSelector: React.FC<Props> = ({ inputRef, formErrors }) => {
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false)
  const [marketing, setMarketing] = useState<boolean>(false)

  const handlePrivacyPolicy = () => {
    privacyPolicy ? setPrivacyPolicy(false) : setPrivacyPolicy(true)
  }

  const handleMarketingCheck = () => {
    marketing ? setMarketing(false) : setMarketing(true)
  }

  return (
    <div className="agreement">
      <div className="form-input-label-row">
        {formErrors && formErrors['privacyPolicy']?.message && (
          <div className="form-input-validation-error">{formErrors['privacyPolicy'].message}</div>
        )}
      </div>
      <FormControlLabel
        control={
          <Checkbox className="checkbox" color="default" checked={privacyPolicy} onClick={handlePrivacyPolicy} />
        }
        label={
          <div className="agreement-checkbox bordered" onClick={handlePrivacyPolicy}>
            <label>
              I agree to PreviewMe's{' '}
              <a
                onClick={e => {
                  e.stopPropagation()
                }}
                href="https://previewme.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              {' and '}
              <a
                onClick={e => {
                  e.stopPropagation()
                }}
                href="https://previewme.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              *
            </label>
          </div>
        }
        name={'privacyPolicy'}
        inputRef={inputRef}
      />

      <FormControlLabel
        control={<Checkbox className="checkbox" color="default" checked={marketing} onClick={handleMarketingCheck} />}
        label={
          <div className="agreement-checkbox bordered" onClick={handleMarketingCheck}>
            <label onClick={handleMarketingCheck}>Keep me updated with news, events and offers</label>
          </div>
        }
        name={'marketing'}
        inputRef={inputRef}
      />
    </div>
  )
}

export default AgreementSelector
