import React from 'react'
import './FormInput.scss'
import Instructions from '../Instructions/Instructions'

type Props = {
  name?: string
  disabled?: boolean
  defaultValue?: string | number | readonly string[] | undefined
  label?: string
  instructions?: string | undefined
  inputRef?: any
  error?: string
  errors?: any
} & React.InputHTMLAttributes<HTMLInputElement>

const FormInput: React.FC<Props> = ({
  name,
  disabled,
  defaultValue,
  label,
  instructions,
  inputRef,
  error,
  errors,
  ...rest
}) => {
  const inputId = 'input' + (name ? '-' + name : '')
  const errorToShow = error || (name && errors?.[name]?.message)

  return (
    <div className="form-input-container">
      <div className="form-input-label-row">
        <div>
          {label && (
            <label className="form-input-label" htmlFor={inputId}>
              {label}
            </label>
          )}
          {instructions && <Instructions instructions={instructions} />}
        </div>
        {errorToShow && <div className="form-input-validation-error">{errorToShow}</div>}
      </div>
      <div className={disabled ? ' disabled' : ''}>
        <input
          id={inputId}
          name={name}
          className={'form-input' + (errorToShow ? ' has-error' : '')}
          ref={inputRef}
          {...rest}
          autoComplete="off"
          disabled={disabled}
          value={defaultValue ? defaultValue : rest.value}
        />
      </div>
    </div>
  )
}

export default FormInput
