import {
  InvitedTeamMember,
  Organization,
  OrganizationUserInvite,
  OrganizationUserPermission,
  TeamMember
} from 'state/types'
import { getApiWithAuthorization } from './api'

export const createOrganization = async (organization: Partial<Organization>) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.post<Organization>(`/api/organization`, organization)
}

export const addUserToOrganization = async (organizationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.post<string>(`/api/organization/${organizationId}/user`)
}

export const addUserByInvitation = async (organizationId: string, invitationCode: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.post<Organization>(`/api/organization/${organizationId}/invite/${invitationCode}`)
}

export const deleteTeamMemberInvite = async (organizationId: string, email: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.delete<void>(`/api/organization/${organizationId}/invite/${email}`)
}

export const deleteTeamMemberFromOrganization = async (organizationId: string, userId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.delete<void>(`/api/organization/${organizationId}/user/${userId}`)
}

export const getOrganizationsByUser = async () => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<Organization[]>(`/api/organization`)
}

export const getOrganizationsById = async (organizationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<Organization>(`/api/organization/${organizationId}`)
}

export const sendInviteToTeamMembers = async (organizationId: string, invitedTeamMember: InvitedTeamMember) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.put<OrganizationUserInvite>(
    `/api/organization/${organizationId}/invite/${invitedTeamMember.email}`,
    { email: invitedTeamMember.email, type: invitedTeamMember.role }
  )
}

export const getOrganizationsUserInviteByInviteCode = async (inviteCode: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<OrganizationUserInvite>(`/api/organization/invite/${inviteCode}`)
}

export const updateOrganization = async (organization: Organization) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.put<Organization>(`/api/organization/${organization.id}`, organization)
}

export const getTeamMembers = async (organizationId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<TeamMember[]>(`/api/organization/${organizationId}/user`)
}

export const updateOrgUserPermission = async (organizationId: string, userId: string, payload: OrganizationUserPermission) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.put<void>(`/api/organization/${organizationId}/user/${userId}/permission`, payload)
}
