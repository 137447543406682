import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { confirmRegistration, alreadyVerifiedMessage } from 'services/authService'
import { useUrlParams } from 'helpers/urlParams'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'

const successMessage = 'Your email has been verified!'

const ConfirmRegistrationPage = () => {
  // Do NOT use the url param 'code' or AWS amplify will try to do something with it.
  const { email, accessCode } = useUrlParams('email', 'accessCode')
  const [redirect, setRedirect] = useState('')

  useEffect(() => {
    const doConfirmRegistration = async () => {
      const { success, errorMessage } = await confirmRegistration(email || '', accessCode || '')
      const alreadyConfirmed = errorMessage === alreadyVerifiedMessage
      if (success || alreadyConfirmed) {
        setRedirect(
          `/auth/login?email=${email}&successMessage=${encodeURIComponent(
            alreadyConfirmed ? alreadyVerifiedMessage : successMessage
          )}`
        )
      } else {
        setRedirect(`/auth/login?errorMessage=${encodeURIComponent(errorMessage!)}`)
      }
    }
    doConfirmRegistration()
  }, [email, accessCode])

  return redirect ? <Redirect to={redirect} /> : <LoadingSpinner fullScreen />
}

export default ConfirmRegistrationPage
