import React from 'react'
import { Invitation } from 'state/types'
import { updateArchived } from '../../../services/invitationService'
import GeneralInvitationsTable from '../../GeneralInvitationsTable/GeneralInvitationsTable'

type Props = {
  invitations: Invitation[]
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  onArchiveChange: (invitationId: string, nowArchived: boolean) => void
}

const ArchivedInvitationsTable: React.FC<Props> = ({
  invitations,
  sortingBy,
  sortingAsc,
  onSortingByChange,
  onSortingAscChange,
  onArchiveChange,
}) => {
  const onRestore = async function (invitation: Invitation) {
    const { ok } = await updateArchived(invitation.id, false)
    if (ok) {
      onArchiveChange(invitation.id, false)
    }
  }

  const dropdownMenuContent = [{ name: 'Restore', onSelect: onRestore }]

  return (
    <GeneralInvitationsTable
      invitations={invitations}
      sortingBy={sortingBy}
      sortingAsc={sortingAsc}
      onSortingByChange={onSortingByChange}
      onSortingAscChange={onSortingAscChange}
      dropdownMenuContent={dropdownMenuContent}
    />
  )
}

export default ArchivedInvitationsTable
