import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'
import './QrCode.scss'

interface Props {
  to: string
}

const QrCode = function ({ to }: Props) {
  const fullLink = window.location.origin + to
  const [QRCodeImg, setQRCodeImg] = useState<string>()

  useEffect(() => {
    const generateQRCode = async () => {
      const result = await QRCode.toDataURL(fullLink)
      setQRCodeImg(result)
    }
    generateQRCode()
  })

  return (
    <div className="qr-code">
      <a href={QRCodeImg} download="QRCode.jpg" className="button download-button">
        <span>Download QR code</span>
      </a>
      <img src={QRCodeImg} alt="qr-code" />
    </div>
  )
}

export default QrCode
