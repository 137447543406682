import React, { useEffect, useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { Invitation } from 'state/types'
import { formatDate } from 'helpers/date'
import * as invitationService from 'services/invitationService'
import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import BackLink from 'components/shared/BackLink/BackLink'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import './InvitationSplashPage.scss'

const InvitationSplashPage = () => {
  const [invitation, setInvitation] = useState<Invitation | undefined>()
  const [previewId, setPreviewId] = useState('')
  const [isLoading, setLoading] = useState(true)
  const [isCreating, setCreating] = useState(false)
  const { invitationId } = useParams<any>()

  const onCreateClick = async () => {
    if (invitationId) {
      setCreating(true)
      const { ok, data } = await invitationService.createPreview(invitationId)
      if (ok && data) {
        setPreviewId(data)
      }
      setCreating(false)
    }
  }

  useEffect(() => {
    const loadInvitation = async () => {
      if (invitationId) {
        setLoading(true)
        const [invitationResponse, previewResponse] = await Promise.all([
          invitationService.getInvitationSummary(invitationId),
          invitationService.findPreview(invitationId),
        ])
        setLoading(false)
        if (previewResponse.ok && previewResponse.data) {
          // User already has a preview for this invitation, set ID to redirect to editor page.
          setPreviewId(previewResponse.data)
        } else if (invitationResponse.ok && invitationResponse.data) {
          // Use doesn't have a preview yet, show them the page with a create button.
          setInvitation(invitationResponse.data)
        }
      }
    }
    loadInvitation()
  }, [invitationId])

  return previewId ? (
    <Redirect to={`/edit/${previewId}`} />
  ) : (
    <FullScreenPageFrame className="invitation-splash-page">
      {isLoading ? (
        <LoadingSpinner container />
      ) : (
        <>
          <BackLink to="/">PreviewMe Home</BackLink>
          <div className="card">
            <div className="invitation-name">
              {!invitation
                ? 'Invitation not found'
                : invitation.startDate !== undefined && !invitation.open
                ? 'Invitation is not yet open'
                : invitation.name}
            </div>
            <div className="description">
              {!invitation ? (
                'This invitation does not appear to exist. It may have been deleted by its owner.'
              ) : invitation.closed ? (
                <>
                  <span>The invitation by </span>
                  <span className="highlight">{invitation.ownerName}</span>
                  <span> has now closed.</span>
                </>
              ) : invitation.startDate !== undefined && !invitation.open ? (
                <>
                  <span>The invitation </span>
                  <span className="highlight">{invitation.name}</span>
                  <span> is not yet open. Please come back on {formatDate(invitation.startDate)}.</span>
                </>
              ) : (
                <>
                  <span>You've been invited by </span>
                  <span>{invitation.ownerName}</span>
                  <span> to create a Preview! Let's begin.</span>
                </>
              )}
            </div>
            {invitation && !invitation.closed && (invitation.open || invitation.startDate === undefined) && (
              <LoadingButton isLoading={isCreating} className="get-started-button primary" onClick={onCreateClick}>
                Get started
              </LoadingButton>
            )}
          </div>
        </>
      )}
    </FullScreenPageFrame>
  )
}

export default InvitationSplashPage
