import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ContentBlock, Preview } from 'state/types'
import usePreview from 'state/usePreview'
import useContentBlock from 'state/useContentBlock'
import { isEmptyVideoBlock } from 'helpers/validation'
import { getPublishedPreviewNavigationLink } from 'helpers/navigation'
import { getConcatenatedTitles } from 'helpers/titles'
import PreviewEditorStepper from 'components/preview/PreviewEditorStepper/PreviewEditorStepper'
import ViewPreviewLink from 'components/preview/ViewPreviewLink/ViewPreviewLink'
import UnpublishConfirmationDialog from 'components/preview/UnpublishConfirmationDialog/UnpublishConfirmationDialog'
import OptionsMenuButton from 'components/shared/OptionsMenuButton/OptionsMenuButton'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import SingleFieldForm from 'components/shared/SingleFieldForm/SingleFieldForm'
import PenIcon from 'assets/icons/PenIcon'
import './PreviewEditorHeader.scss'
import VideoSuiteDialog from '../../video/VideoSuiteDialog/VideoSuiteDialog'
import InfoIcon from '../../shared/InfoIcon/InfoIcon'
import VideoChaptersInformationDialog from '../../video/VideoChaptersInformationDialog/VideoChaptersInformationDialog'
import LightBulbBlueIcon from '../../../assets/icons/LightBulbBlueIcon'
import VideoTipsDialog from '../../video/VideoTipsDialog/VideoTipsDialog'
import { FIRST_BLOCK_INDEX } from 'helpers/constant'

const getDescription = (block?: ContentBlock) => {
  switch (block?.formLabel) {
    case 'Additional Video':
      return 'Additional video can include entire games, extended highlight reels which are linked through third party platforms eg: YouTube'
  }

  switch (block?.contentBlockType?.name) {
    case 'Additional Video':
      return 'Additional video include entire games, extended highlight reels which are linked through third party platforms eg: YouTube'
    case 'Video':
      return 'Keep it short! Files must be 300MB or less.'
    case 'Documents':
      return "Upload any documents you'd like viewers to have access to."
    default:
      return null
  }
}

const checkForEmptyVideoBlock = (preview?: Preview, block?: ContentBlock) => {
  return preview?.isValidationActive && block && isEmptyVideoBlock(block)
    ? 'Please add at least one video before publishing!'
    : ''
}

type Props = {
  preview?: Preview
  blockIndex?: number
  isTitlePage: boolean
  isInstructionsPage: boolean
  isPublishPage: boolean
  steps: Array<{
    blockTitle: string
    link: string
    isActive: boolean
    isComplete: boolean
    isIncomplete: boolean
  }>
}

const PreviewEditorHeader: React.FC<Props> = ({
  preview,
  blockIndex,
  isTitlePage,
  isInstructionsPage,
  isPublishPage,
  steps,
}) => {
  const { unpublish } = usePreview(preview?.id)
  const { updatePreviewLabel } = useContentBlock(preview?.id, blockIndex)
  const { push } = useHistory()
  const [areOptionsShowing, setOptionsShowing] = useState(false)
  const [isUnpublishDialogShowing, setUnpublishDialogShowing] = useState(false)
  const [isEditTitleDialogShowing, setEditTitleDialogShowing] = useState(false)
  const [showVideoContentDialog, setShowVideoContentDialog] = useState(false)
  const [isVideoChaptersInformationDialogShowing, setVideoChaptersInformationDialogShowing] = useState(false)
  const [isVideoTipsDialogShowing, setVideoTipsDialogShowing] = useState(false)
  const isPublished = preview?.status === 'published'
  const block = blockIndex !== undefined ? preview?.contentBlocks?.[blockIndex] : undefined
  const isPublishSuccess = isPublishPage && preview?.status === 'published'
  const title = isPublishSuccess
    ? 'Success!'
    : isPublishPage
    ? 'Publish'
    : isTitlePage
    ? 'Preview Title'
    : isInstructionsPage
    ? `Instructions from ${preview?.invitation?.ownerName}`
    : block?.previewLabel || block?.formLabel

  const displayName = preview && getConcatenatedTitles(preview)
  const videoError = checkForEmptyVideoBlock(preview, block)
  const description = videoError || getDescription(block)
  const fromParam = isPublishPage ? 'publish' : blockIndex !== undefined ? blockIndex : 'editor'
  const allFieldsHidden = block?.contentBlockFields?.every(b => b.hiddenFromPublishedPreview)
  const isVideoContentBlock = block?.contentBlockType.name === 'Video'
  const canEditTitle =
    preview && !preview.invitation && block && block.contentBlockType.name !== 'Video' && !allFieldsHidden

  const getVideoChapters = () => {
    const chapters: string[] = []
    preview?.contentBlocks
      ?.filter(contentBlock => contentBlock.contentBlockType.name === 'Video')[0]
      .contentBlockFields.forEach(videoChapter => {
        if (videoChapter.previewLabel) {
          chapters.push(videoChapter.previewLabel)
        }
      })
    return chapters
  }

  const onPublishClick = async (previewId: string) => push(getPublishedPreviewNavigationLink(previewId, fromParam))

  return preview && !isPublishSuccess ? (
    <div className="preview-editor-header">
      <div className="top-row">
        <div className="left-column">
          <div className="mobile-options-container">
            {displayName && <div className="preview-name">{displayName}</div>}
            {!isPublishPage && (
              <>
                <OptionsMenuButton
                  isShowing={areOptionsShowing}
                  options={[
                    {
                      option: 'Video Tips',
                      onClick: () => setVideoTipsDialogShowing(true),
                    },
                    {
                      option: isPublished ? 'View Preview' : 'View Draft',
                      onClick: () => push(getPublishedPreviewNavigationLink(preview.id, fromParam)),
                    },
                    {
                      option: isPublished ? 'Unpublish' : 'Publish',
                      onClick: () => (isPublished ? setUnpublishDialogShowing(true) : onPublishClick(preview.id)),
                    },
                  ]}
                  setShowing={setOptionsShowing}
                />
              </>
            )}
          </div>
          <PreviewEditorStepper
            previewId={preview.id}
            steps={steps}
            isFirstBlockInCompleted={
              !!(!preview.title || !preview.subtitle) &&
              preview.duplicate &&
              (!!preview?.isValidationActive || preview.status === 'published')
            }
          />
        </div>
        <VideoTipsDialog isShowing={isVideoTipsDialogShowing} onClose={() => setVideoTipsDialogShowing(false)} />
        <div className="left-buttons">
          <ViewPreviewLink previewId={preview.id} isPublished={preview?.status === 'published'} fromParam={fromParam} />
          {block?.contentBlockType?.name === 'Video' && (
            <div className="video-tips-button" onClick={() => setVideoTipsDialogShowing(true)}>
              <p className="video-tips-text">Video tips</p>
              <LightBulbBlueIcon />
            </div>
          )}
        </div>
      </div>
      <div className="title-row">
        {isVideoContentBlock ? (
          <>
            <VideoSuiteDialog
              isShowing={showVideoContentDialog}
              onClose={() => setShowVideoContentDialog(false)}
              isInvitationResponse={preview.invitation !== undefined}
              areCustomInstructionsPresent={getVideoChapters().length > 0}
              hideActions
            />
            <h1 className="video-content-title">{title}</h1>
            <InfoIcon onClick={() => setShowVideoContentDialog(true)} />
          </>
        ) : (
          <>
            {canEditTitle ? (
              <button className="edit-title-button plain" onClick={() => setEditTitleDialogShowing(true)}>
                <h1>{title}</h1>
                <PenIcon />
              </button>
            ) : (
              <>
                <h1>{title}</h1>
              </>
            )}
          </>
        )}
      </div>
      <VideoChaptersInformationDialog
        isShowing={isVideoChaptersInformationDialogShowing}
        onClose={() => setVideoChaptersInformationDialogShowing(false)}
      />
      {!isPublishPage && description && (
        <div className={'description' + (videoError ? ' error' : '')}>
          {description}{' '}
          {isVideoContentBlock ? (
            <b onClick={() => setVideoChaptersInformationDialogShowing(true)} className="video-information-button">
              Learn why.
            </b>
          ) : (
            ''
          )}
        </div>
      )}
      <UnpublishConfirmationDialog
        isShowing={isUnpublishDialogShowing}
        isClosed={preview.closedTemplate}
        endDate={preview.endDate}
        invitationOwner={preview?.invitation?.ownerName}
        onClose={() => setUnpublishDialogShowing(false)}
        onOk={unpublish}
      />
      {canEditTitle && (
        <CloseableDialog
          className="edit-block-title-dialog"
          isShowing={isEditTitleDialogShowing}
          onClose={() => setEditTitleDialogShowing(false)}
        >
          <h2>Edit Section Title</h2>
          <SingleFieldForm
            placeholder="Section title"
            validationErrorText="Please enter a section title"
            submitButtonText="Save"
            initialValue={block?.previewLabel || block?.formLabel}
            triggerReset={isEditTitleDialogShowing}
            onCancel={() => setEditTitleDialogShowing(false)}
            onSubmit={async previewLabel => {
              const ok = await updatePreviewLabel(previewLabel)
              if (ok) {
                setEditTitleDialogShowing(false)
              }
            }}
          />
        </CloseableDialog>
      )}
    </div>
  ) : null
}

export default PreviewEditorHeader
