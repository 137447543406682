import React from 'react'
import { ContentBlockField } from 'state/types'
import './PublishedPreviewDetails.scss'
import { fieldValueExists, validatePeople } from '../../../helpers/validation'
import PublishedPreviewPeople from '../PublishedPreviewPeople/PublishedPreviewPeople'

type Props = {
  fields: ContentBlockField[]
  trackLinkClick: (fieldId: string) => void
}

const PublishedPreviewDetails: React.FC<Props> = ({ fields, trackLinkClick }) => {
  const handleClick = (contentBlockField: ContentBlockField | undefined) => {
    if (contentBlockField) {
      trackLinkClick(contentBlockField.id)
      window.open(contentBlockField.value, '_blank')
    }
  }
  return (
    <>
      {fields.map(field => {
        const doesPeopleHaveAnyValue = fieldValueExists(field)
        return (
          <div key={field.id} className={doesPeopleHaveAnyValue ? 'detail' : ''}>
            {doesPeopleHaveAnyValue && (
              <PublishedPreviewPeople
                fields={field.children ? field.children.filter(validatePeople) : []}
                trackLinkClick={trackLinkClick}
              />
            )}
            {doesPeopleHaveAnyValue && field.children && field.children[1].value && (
              <button
                className={'primary button'}
                onClick={() => handleClick(field.children ? field.children[1] : undefined)}
              >
                See more
              </button>
            )}
          </div>
        )
      })}
    </>
  )
}

export default PublishedPreviewDetails
