import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Invitation, OrganizationBrandSetting, Preview } from 'state/types'
import useInvitation from 'state/useInvitation'
import { useUrlParams } from 'helpers/urlParams'
import { getBusinessLogoImageUrl } from 'helpers/cloudinary'
import { getInitialSortingAsc, getInitialSortingBy, getPreviousAndNextPreviews } from 'helpers/sort'
import NextPreviewLink from 'components/preview/NextPreviewLink/NextPreviewLink'
import NextPreviewLinksMobile from 'components/preview/NextPreviewLinksMobile/NextPreviewLinksMobile'
import BackLink from 'components/shared/BackLink/BackLink'
import StarIcon from 'assets/icons/StarIcon'
import './PublishedPreviewHeader.scss'
import InvitationsLabelSelector from '../../invitation/InvitationsLabelSelector/InvitationsLabelSelector'
import SharingDialog from '../SharingDialog/SharingDialog'
import { PreviewMeObject } from '../../../helpers/enum'
import ShareIcon from '../../../assets/icons/ShareIcon'
import useStore from '../../../state/useStore'
import { MAX_TOTAL_ITEMS_PER_PAGE_INVITATIONS } from '../../../helpers/constant'

type Props = {
  preview: Preview
  isOwnPreview: boolean
  isBrandLoading?: boolean
  organizationBrandSetting?: OrganizationBrandSetting
  classData:any
  planType:string
}

const PublishedPreviewHeader: React.FC<Props> = ({ preview, isBrandLoading, organizationBrandSetting,classData, planType, }) => {
  const { i: invitationId, p: page, f: filter, sq: searchQuery } = useUrlParams('i', 'p', 'f', 'sq')
  const { state } = useStore()
  const [repeatCall, setRepeatCall] = useState(true)
  const { isLoading, invitation, toggleStarred, setViewed } = useInvitation(invitationId, Number(page), filter,searchQuery, repeatCall)
  const showInvitationHeader = invitationId && !isLoading && invitation
  const isStarred = invitation?.previews?.find(p => p.id === preview.id)?.starred
  const label = invitation?.previews?.find(p => p.id === preview.id)?.label
    || state.defaultInvitation?.previews?.find(p => p.id === preview.id)?.label
  const [showSharingDialog, setShowSharingDialog] = useState(false)
  const maxPage = ((invitation?.previewCount || state.defaultInvitation?.previewCount || 0)/MAX_TOTAL_ITEMS_PER_PAGE_INVITATIONS)
  const { previous, next } = getPreviousAndNextPreviews(
    preview.id,
    invitation?.previews,
    getInitialSortingBy(),
    getInitialSortingAsc(),
  )
  const previousPage = invitation?.previews?.findIndex(p => p.id === preview.id) === 0? Number(page) - 1 : Number(page)
  const nextPage = invitation?.previews?.findIndex(p => p.id === preview.id) === MAX_TOTAL_ITEMS_PER_PAGE_INVITATIONS? Number(page) + 1 : Number(page)
  const previousLink = invitationId && previous && `/preview/${previous.id}?i=${invitationId}&p=${previousPage}&f=${filter}&sq=${searchQuery}`
  const nextLink = invitationId && next && `/preview/${next.id}?i=${invitationId}&p=${nextPage}&f=${filter}&sq=${searchQuery}`

  useEffect(() => {
    setRepeatCall(false)
  }, [])

  const renderInvitationOption = (logosGroup: object | null) => {
    return (
      <div className="header-content">
        {showInvitationHeader ? (
          <>
            <div className="back-button">
              <BackLink to={`/invitation/${invitationId}?page=${page}`}><p>Back to all applicant</p></BackLink>
            </div>
            <div className="responses">
              <InvitationsLabelSelector previewId={preview.id} invitationId={invitationId} label={label ? label : 'Add Label'} />
              <button
                className={'star-button plain' + (isStarred ? ' active' : '')}
                onClick={() => toggleStarred(preview.id)}
              >
                <StarIcon />
              </button>
              <button className={'share-button plain'}
                      onClick={() => {
                        setShowSharingDialog(true)
                      }}>
                <ShareIcon/>
              </button>
            </div>
          </>
        ) : !isLoading ? (
          <>{preview && logosGroup}</>
        ) : null}
      </div>
    )
  }

  useEffect(() => {
    setViewed(preview.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview.id])

  const renderHeaderLogos = () => {
    let logosGroup = null
    // in case of no RHS logo and disabled preview reference => don't render header
    if (
      !isBrandLoading &&
      !organizationBrandSetting?.cloudinaryStorageId &&
      organizationBrandSetting?.previewReferenceDisabled &&
      !preview.businessLogoImageStorageId
    ) {
      return renderInvitationOption(logosGroup)
    }


    // render personal - non invitation logo
    if ((!isBrandLoading && !organizationBrandSetting) || (!preview.organizationId && !preview.invitation)) {
      logosGroup = (
        <>
          {!organizationBrandSetting?.previewReferenceDisabled ? (
            <a href="https://previewme.com" aria-label="PreviewMe Home" target="_blank" rel="noreferrer noopener">
              <div className="previewme-logo" />
            </a>
          ) : (
            <div></div>
          )}
          {preview.businessLogoImageStorageId && (
            <img
              className="business-logo-image"
              alt=""
              src={getBusinessLogoImageUrl(preview.businessLogoImageStorageId)}
            />
          )}
        </>
      )
    }

    //render organization or invitation logo
    if (!isBrandLoading && !!organizationBrandSetting) {
      if (!!organizationBrandSetting.cloudinaryStorageId)
        logosGroup = (
          <>
            <a
              href={
                organizationBrandSetting.brandUrl?.startsWith('http')
                  ? organizationBrandSetting.brandUrl
                  : 'https://' + organizationBrandSetting.brandUrl
              }
              aria-label="PreviewMe Home"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="custom-logo"
                src={getBusinessLogoImageUrl(organizationBrandSetting.cloudinaryStorageId)}
              />
            </a>
            {preview.businessLogoImageStorageId && (
              <img
                className="business-logo-image"
                alt=""
                src={getBusinessLogoImageUrl(preview.businessLogoImageStorageId)}
              />
            )}
          </>
        )
      else
        logosGroup = (
          <>
            {!organizationBrandSetting?.previewReferenceDisabled ? (
              <a href="https://previewme.com" aria-label="PreviewMe Home" target="_blank" rel="noreferrer noopener">
                <div className="previewme-logo" />
              </a>
            ) : (
              //placeholder to occupy the LHS logo
              <div></div>
            )}
            {preview.businessLogoImageStorageId && (
              <img
                className="business-logo-image"
                alt=""
                src={getBusinessLogoImageUrl(preview.businessLogoImageStorageId)}
              />
            )}
          </>
        )
    }
    return renderInvitationOption(logosGroup)
  }

  return (

    <>
      <SharingDialog
        id={preview.id}
        onPublish
        isShowing={showSharingDialog}
        onClose={() => setShowSharingDialog(false)}
        object={PreviewMeObject.Preview}
      />
      <header
        className={
          `published-preview-header ${classData.root} ` + preview.theme +
          (showInvitationHeader ? ' invitation-mode' : '') +
          (previousLink || nextLink ? ' has-navigation-links' : '')
        }

      >
        {renderHeaderLogos()}
        {showInvitationHeader && previous && <NextPreviewLink preview={previous} to={previousLink!} isPrevious />}
        {showInvitationHeader && next && <NextPreviewLink preview={next} to={nextLink!} />}
        {showInvitationHeader && (previous || next) && (
          <NextPreviewLinksMobile
            previousLink={previousLink}
            previousPreview={previous}
            nextLink={nextLink}
            nextPreview={next}
          />
        )}
      </header>
    </>

  )
}

export default PublishedPreviewHeader
