import React, { useState } from 'react'
import { businessLogoImageFileSizeLimitInBytes, businessLogoImageFileSizeLimitInMb } from 'helpers/media'
import { getBusinessLogoImageUrl } from 'helpers/cloudinary'
import FileSelectorButton from 'components/shared/FileSelectorButton/FileSelectorButton'
import ProgressBar from 'components/shared/ProgressBar/ProgressBar'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import DialogCloseIcon from 'assets/icons/DialogCloseIcon'
import './BusinessLogoImageSelector.scss'

type Props = {
  previewBusinessLogoImageStorageId?: string
  canDelete: boolean
  warnBeforeDeleting: boolean
  deleteConfirmationText?: string
  deleteForbiddenText?: string
  uploadButtonText?: string
  showUploadingLabel?: boolean
  updateBusinessLogoImage: (blob: Blob, onProgressChange: (progress: number) => void) => Promise<void>
  removeBusinessLogoImage: () => void
}

const BusinessLogoImageSelector: React.FC<Props> = ({
  previewBusinessLogoImageStorageId,
  canDelete,
  warnBeforeDeleting,
  deleteConfirmationText,
  deleteForbiddenText,
  uploadButtonText,
  showUploadingLabel,
  updateBusinessLogoImage,
  removeBusinessLogoImage,
}) => {
  const [isUploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isDeleteDialogShowing, setDeleteDialogShowing] = useState(false)
  const [isDeleteErrorDialogShowing, setDeleteErrorDialogShowing] = useState(false)
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)

  const onSelectedFileChange = async (file: File | null) => {
    if (file) {
      if (file.size > businessLogoImageFileSizeLimitInBytes) {
        setFileSizeErrorDialogShowing(true)
      } else {
        setUploading(true)
        setProgress(0)
        await updateBusinessLogoImage(file, setProgress)
        setUploading(false)
      }
    }
  }

  const onDeleteImageClick = () => {
    if (!canDelete) {
      setDeleteErrorDialogShowing(true)
    } else if (warnBeforeDeleting) {
      setDeleteDialogShowing(true)
    } else {
      removeBusinessLogoImage()
    }
  }

  return (
    <>
      <div className="business-logo-image-selector">
        <div className="image-preview-block">
          {previewBusinessLogoImageStorageId && (
            <>
              <img
                className="business-logo-image"
                alt=""
                src={getBusinessLogoImageUrl(previewBusinessLogoImageStorageId!)}
              />
              <button type="button" className="close-button plain" onClick={onDeleteImageClick}>
                <DialogCloseIcon />
              </button>
            </>
          )}
        </div>
        <div className="upload-image-block">
          {!isUploading ? (
            <>
              <FileSelectorButton
                accept="image/*"
                onSelectedFileChange={onSelectedFileChange}
                className="upload-image-button link"
              >
                {`${previewBusinessLogoImageStorageId ? 'Replace' : 'Upload'} ${uploadButtonText || 'image'}`}
              </FileSelectorButton>
              <span className="upload-text-small">Max image size is {businessLogoImageFileSizeLimitInMb} MB</span>
              <span className="upload-text-small">Future Premium ($) feature.</span>
            </>
          ) : (
            <div className="uploading-buttons">
              {showUploadingLabel && <div className="uploading-label">Uploading...</div>}
              <ProgressBar progress={progress} />
            </div>
          )}
        </div>
      </div>
      <ConfirmationDialog
        isShowing={isDeleteDialogShowing}
        cancelButtonText="No, cancel that"
        okButtonText="Yes, delete this image"
        title="Heads up!"
        isWarning
        onClose={() => setDeleteDialogShowing(false)}
        onOk={removeBusinessLogoImage}
      >
        {deleteConfirmationText}
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isDeleteErrorDialogShowing}
        okButtonText="Ok"
        title="Logo image is required"
        onClose={() => setDeleteErrorDialogShowing(false)}
      >
        {deleteForbiddenText}
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Image too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
      >
        {`This image is too large. Please choose an image file smaller than ${businessLogoImageFileSizeLimitInMb} MB.`}
      </ConfirmationDialog>
    </>
  )
}

export default BusinessLogoImageSelector
