import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { line } from 'd3-shape'
import { select } from 'd3-selection'
import { scaleLinear, scaleTime } from 'd3-scale'
import { axisBottom, axisLeft } from 'd3-axis'
import min from 'date-fns/min'
import format from 'date-fns/format'
import subHours from 'date-fns/subHours'
import subDays from 'date-fns/subDays'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { PreviewAnalyticsRange, PreviewViewsOverTime } from 'state/types'
import { useResizeObserver } from 'helpers/resizeObserver'
import { padDataWithZeros } from 'helpers/charts'
import { today } from 'helpers/date'
import './PreviewViewsChart.scss'

const margin = { top: 15, bottom: 50, right: 20 }

type Props = {
  data: PreviewViewsOverTime[]
  hideLegend?: boolean
  range: PreviewAnalyticsRange
  obfuscated?: boolean
}

const PreviewViewsChart: React.FC<Props> = ({ data, hideLegend, range, obfuscated }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)
  const tooltipAnchorRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const { width, height } = useResizeObserver(containerRef)
  const numberOfDays =
    range === 'last-7-days' ? 7 : range === 'last-30-days' ? 30 : range === 'last-90-days' ? 90 : undefined

  useEffect(() => {
    if (width > 0 && height > 0) {
      select(svgRef.current).select('*').remove()

      const allData = data.flatMap(d => d.counts)
      const maxCount = Math.max(...allData.map(v => v.count))
      const minDateWithViews = min(allData.map(v => v.day))
      const minDay = numberOfDays ? subDays(today(), numberOfDays) : subDays(minDateWithViews, 2)
      const maxDay = new Date()
      const paddedData = data.map(d => padDataWithZeros(d, minDay, maxDay))
      const minDateOnAxis = subHours(minDay, 23)

      // Don't show dots on the zero counts if the time axis is showing more than 30 days,
      // because there isnt enough space.
      const hideDotsForZeroes = differenceInCalendarDays(maxDay, minDay) > 60

      const marginLeft = maxCount < 100 ? 30 : maxCount < 1000 ? 40 : 50
      const chartWidth = width - marginLeft - margin.right
      const chartHeight = height - margin.top - margin.bottom

      const xScale = scaleTime().domain([minDateOnAxis, maxDay]).range([0, chartWidth])
      const yScale = scaleLinear().domain([0, maxCount]).range([chartHeight, 0]).nice()

      const xAxis = axisBottom(xScale)
        .tickFormat(date => format(date as any, 'd'))
        .tickSize(0)
        .tickPadding(12)

      if (numberOfDays === 7) {
        xAxis.ticks(8)
      } else if (!numberOfDays) {
        const range = differenceInCalendarDays(today(), minDay)
        if (range < 10) {
          xAxis.ticks(range + 1)
        }
      }

      const yAxis = axisLeft(yScale).ticks(Math.min(maxCount, 10)).tickSize(-chartWidth).tickPadding(12)

      const svg = select(svgRef.current).append('g').attr('transform', `translate(${marginLeft}, ${margin.top})`)

      let monthLabelsUsed: string[] = []
      svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0,${chartHeight})`)
        .call(xAxis)
        .selectAll<Element, Date>('.tick')
        .filter((date, i) => {
          // This is a bit of a hack to only show the month labels (Jun, Jul, etc.) on the first tick of the month.
          if (i === 0) {
            monthLabelsUsed = []
          }
          const monthLabel = format(date, 'MMM')
          if (!monthLabelsUsed.includes(monthLabel)) {
            monthLabelsUsed.push(monthLabel)
            return true
          } else {
            return false
          }
        })
        .append('text')
        .attr('class', 'month-label')
        .text(date => format(date, 'MMM'))

      svg.append('g').attr('class', 'y-axis').call(yAxis)

      const l = line<any>()
        .x(d => xScale(d.day))
        .y(d => yScale(d.count))

      for (let i = 0; i < paddedData.length; i++) {
        const previewGroup = svg.append('g').attr('class', 'preview-' + (i + 1))
        const nonZeroCounts = paddedData[i].counts.filter(c => c.count > 0)
        const countsToShowDotsFor = hideDotsForZeroes ? nonZeroCounts : paddedData[i].counts

        previewGroup.append('path').datum(paddedData[i].counts).attr('class', 'line').attr('d', l)
        previewGroup
          .selectAll('.dot')
          .data(countsToShowDotsFor)
          .enter()
          .append('circle')
          .attr('class', 'dot')
          .attr('cx', d => xScale(d.day))
          .attr('cy', d => yScale(d.count))
          .attr('r', 4)
          .exit()
          .remove()

        previewGroup
          .selectAll('.hover-target')
          .data(nonZeroCounts)
          .enter()
          .append('circle')
          .attr('class', 'hover-target')
          .attr('cx', d => xScale(d.day))
          .attr('cy', d => yScale(d.count))
          .attr('r', 8)
          .on('mouseover', d => {
            const tooltipAnchor = tooltipAnchorRef.current
            if (tooltipAnchor) {
              tooltipAnchor.style.left = marginLeft + xScale(d.day) + 'px'
              tooltipAnchor.style.top = yScale(d.count) + 'px'
              tooltipAnchor.style.opacity = '1'
            }
            const tooltip = tooltipRef.current
            if (tooltip) {
              tooltip.innerHTML = d.count + ' view' + (d.count !== 1 ? 's' : '') + ' on ' + format(d.day, 'd MMMM')
            }
          })
          .on('mouseout', d => {
            const tooltipAnchor = tooltipAnchorRef.current
            if (tooltipAnchor) {
              tooltipAnchor.style.opacity = '0'
            }
          })
          .exit()
          .remove()
      }
    }
  }, [data, width, height, numberOfDays])

  return (
    <div className={'preview-views-chart' + (hideLegend ? ' hide-legend' : '')}>
      <div className="scroll-container">
        <div className="scroll-content">
          {!hideLegend && (
            <div className="legend">
              {data.map(({ previewId, previewName }, i) =>
                obfuscated ? (
                  <div key={i} className={'legend-item item-' + (i + 1)}>
                    <div className="legend-color-marker" />
                    <div className="preview-name">{previewName}</div>
                  </div>
                ) : (
                  <Link key={i} className={'legend-item item-' + (i + 1)} to={`/analytics/p/${previewId}`}>
                    <div className="legend-color-marker" />
                    <div className="preview-name">{previewName}</div>
                  </Link>
                )
              )}
            </div>
          )}
          <div className="chart-container" ref={containerRef}>
            <svg className="line-chart-svg" ref={svgRef} />
            <div className="tooltip-layer">
              <div className="tooltip-anchor" ref={tooltipAnchorRef}>
                <div className="tooltip" ref={tooltipRef} aria-hidden></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewViewsChart
