import { useState } from 'react'
import * as contentBlockService from 'services/contentBlockService'
import useStore from 'state/useStore'
import useContentBlock from 'state/useContentBlock'
import { findFieldInTree } from 'helpers/treeUtils'
import { ContentBlockField } from './types'
import { saveContentBlockValues } from 'services/contentBlockService'

// Here you can pass fieldIndex for top-level fields OR fieldId for fields deeper inside the tree.
const useContentBlockField = (previewId?: string, blockIndex?: number, fieldIndex?: number, fieldId?: string) => {
  const { dispatch } = useStore()
  const { isLoading, contentBlock, isValidationActive, updateValues } = useContentBlock(previewId, blockIndex)
  const [isAddingToList, setAddingToList] = useState(false)

  const contentBlockField =
    fieldIndex !== undefined
      ? contentBlock?.contentBlockFields?.[fieldIndex]
      : fieldId
      ? findFieldInTree(contentBlock, fieldId)
      : undefined

  const updateStoreValue = (value?: string) => {
    if (blockIndex !== undefined && contentBlockField?.id) {
      dispatch({ type: 'UPDATE_CONTENT_BLOCK_FIELD_VALUE', blockIndex, fieldId: contentBlockField?.id, value })
    }
  }

  const updateValue = (value?: string, optimistic?: boolean) => {
    if (contentBlockField) {
      return updateValues([{ id: contentBlockField.id, value: value || '' }], optimistic)
    }
  }

  const remove = async () => {
    const { ok } = await contentBlockService.deleteContentBlockField(contentBlockField?.id!)
    if (ok && blockIndex !== undefined && fieldIndex !== undefined) {
      dispatch({ type: 'REMOVE_CONTENT_BLOCK_FIELD', blockIndex, fieldIndex })
    }
    return ok
  }

  const rename = async (name: string) => {
    const { ok } = await contentBlockService.renameContentBlockField(contentBlockField?.id!, name)
    if (ok && blockIndex !== undefined && fieldIndex !== undefined) {
      dispatch({ type: 'RENAME_CONTENT_BLOCK_FIELD', blockIndex, fieldIndex, name })
      if (!!contentBlockField?.videoChapter) {
        dispatch({ type: 'RENAME_VIDEO_CHAPTER', blockIndex, fieldIndex, name })
      }
    }
    return ok
  }

  const addToList = async () => {
    if (contentBlockField?.controlType === 'List' && contentBlockField.children && blockIndex !== undefined) {
      setAddingToList(true)
      const { ok, data } = await contentBlockService.addContentBlockFieldToList(contentBlockField.id)
      setAddingToList(false)
      if (ok && data) {
        dispatch({ type: 'ADD_CONTENT_BLOCK_FIELD_TO_LIST', blockIndex, fieldIndex, fieldId, child: data })
        return data
      }
    }
  }

  const removeFromList = async (childContentBlockFieldId: string) => {
    if (contentBlockField?.controlType === 'List' && contentBlockField.children && blockIndex !== undefined) {
      const childIndex = contentBlockField.children.findIndex(c => c.id === childContentBlockFieldId)
      const { ok } = await contentBlockService.deleteContentBlockFieldFromList(childContentBlockFieldId)
      if (ok) {
        dispatch({ type: 'DELETE_CONTENT_BLOCK_FIELD_FROM_LIST', blockIndex, fieldIndex, fieldId, childIndex })
      }
      return ok
    }
  }

  const deleteContentBlockFieldFromList = async (childContentBlockFieldId: string) => {
    await contentBlockService.deleteContentBlockFieldFromList(childContentBlockFieldId)
  }

  const clearAllChildValues = async (contentBlockField: ContentBlockField) => {
    await contentBlockField.children?.forEach(
      async child => await saveContentBlockValues([{ id: child.id, value: '' }], previewId)
    )
  }

  return {
    isLoading,
    contentBlockField,
    contentBlock,
    isValidationActive,
    isAddingToList,
    updateStoreValue,
    updateValue,
    remove,
    rename,
    addToList,
    removeFromList,
    clearAllChildValues,
    deleteContentBlockFieldFromList,
  }
}

export default useContentBlockField
