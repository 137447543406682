import React from 'react'

const AddIcon = () => (
  <svg className="icon" width="40px" height="40px" viewBox="0 0 40 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1411.000000, -447.000000)">
        <g transform="translate(1411.000000, 447.000000)">
          <circle fill="#DBDBDB" cx="20" cy="20" r="20"></circle>
          <g
            transform="translate(20.000000, 21.000000) rotate(-360.000000) translate(-20.000000, -21.000000) translate(13.000000, 14.000000)"
            stroke="#858585"
            strokeLinecap="round"
            strokeWidth="1.536"
          >
            <path d="M7,4.54747351e-13 L7,13.0666667"></path>
            <path
              d="M7,-1.77635684e-15 L7,13.0666667"
              transform="translate(7.000000, 6.533333) rotate(-270.000000) translate(-7.000000, -6.533333) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default AddIcon
