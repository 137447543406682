const apiSubdomain = process.env.REACT_APP_API_SUBDOMAIN || 'api.development'
const authSubdomain = process.env.REACT_APP_AUTH_SUBDOMAIN || 'auth.development'
const userPoolId = process.env.REACT_APP_USER_POOL_ID || 'us-east-1_BQiFUBlRy'
const userPoolWebClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID || '18p30cig80uti5m9o06ons7eo4'
const userPoolRegion = process.env.REACT_APP_USER_POOL_REGION || 'us-east-1'
const workspace = process.env.REACT_APP_WORKSPACE || 'localhost' // 'development' | 'production'

const local = false

export const baseUrl = local
  ? 'http://localhost:8080/previewme-meta-service'
  : `https://${apiSubdomain}.previewme.co/previewme-meta-service`

export const globalAuthUrl =
  process.env.REACT_APP_WORKSPACE === 'production'
    ? 'https://previewme.com/auth/login'
    : process.env.REACT_APP_WORKSPACE === 'development'
    ? 'https://development.previewme.com/auth/login'
    : 'http://localhost:3000/auth/login'

export const globalAuthAppRedirect =
  process.env.REACT_APP_WORKSPACE === 'production'
    ? '?redirect_uri=https://app.previewme.com'
    : process.env.REACT_APP_WORKSPACE === 'development'
    ? '?redirect_uri=https://app.development.previewme.com'
    : '?redirect_uri=http://localhost:3000'

export const cognitoSignOutRedirect =
  process.env.REACT_APP_WORKSPACE === 'production'
    ? 'https://previewme.com/auth/login'
    : process.env.REACT_APP_WORKSPACE === 'development'
    ? 'https://development.previewme.com/auth/login'
    : 'http://localhost:3000/auth/login'

export const globalAuthSignOutUrl =
  process.env.REACT_APP_WORKSPACE === 'production'
    ? 'https://previewme.com/auth/logout'
    : process.env.REACT_APP_WORKSPACE === 'development'
    ? 'https://development.previewme.com/auth/logout'
    : 'http://localhost:3000/auth/logout'

export const algoliaApplicationId = process.env.REACT_APP_ALGOLIA_APPICATION_ID || 'B3AMIXVLWM'

export const cognitoConfig = {
  userPoolRegion,
  userPoolId,
  userPoolWebClientId,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: `${authSubdomain}.previewme.com`,
    redirectSignIn: window.location.origin + '/auth/loginSuccess',
    redirectSignOut: cognitoSignOutRedirect,
    responseType: 'code',
  },
  cookieStorage: {
    domain:
      workspace === 'production'
        ? 'previewme.com'
        : workspace === 'development'
        ? 'development.previewme.com'
        : 'localhost',
    path: '/',
    expires: 365,
    secure: !local,
  },
}

export const stripePublicApiKey =
  process.env.REACT_APP_STRIPE_PUBLIC_API_KEY || 'pk_test_AeQfNZTErUxaJh8M2vR0g17t002NQhWBb1'

export const apiTimeout = 30000

export const cloudinaryFolder = process.env.REACT_APP_CLOUDINARY_FOLDER || 'PreviewMe/Test'
export const cloudinaryImageUploadPreset =
  process.env.REACT_APP_CLOUDINARY_IMAGE_UPLOAD_PRESET || 'test-previewme-image'
export const cloudinaryVideoUploadPreset =
  process.env.REACT_APP_CLOUDINARY_VIDEO_UPLOAD_PRESET || 'test-previewme-video'

export const gtmId = process.env.REACT_APP_GTM_ID || 'GTM-5CWSM3V'

export const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'UA-160183341-1'

export const environment = apiSubdomain.replace('api', '').replace('-', '') || 'production'
