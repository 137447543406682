import React from 'react'
import { ContentBlockField } from 'state/types'
import { formatPreviewValue } from 'helpers/formatting'
import './PublishedPreviewHighlights.scss'

type Props = {
  allFields: ContentBlockField[]
}

const PublishedPreviewHighlights: React.FC<Props> = ({ allFields }) => {
  let allHighlightFields: ContentBlockField[] = []
  allFields.forEach(field => {
    if (field.highlightPosition != undefined) {
      allHighlightFields.push(field)
    }

    const highlightChildren: ContentBlockField[] = field.children
      ? field.children.filter(child => child.highlightPosition != undefined)
      : []
    if (highlightChildren.length > 0) {
      allHighlightFields = allHighlightFields.concat(highlightChildren)
    }
  })

  const sortedHighlightFields = allHighlightFields.sort((a, b) => a.highlightPosition! - b.highlightPosition!)

  const fieldsWithFormattedValues = sortedHighlightFields.map(f => ({ field: f, value: formatPreviewValue(f, true) }))
  const fieldsToShow = fieldsWithFormattedValues.filter(({ field, value }) => field.required || !!value)

  const outputValues = (field: ContentBlockField, value: any) => {
    return (
      <>
        <div className="label">{field.previewLabel}</div>
        <div className="value">{value}</div>
      </>
    )
  }

  return fieldsToShow.length > 0 ? (
    <div className="published-preview-highlights">
      <div className="content">
        {fieldsToShow.map(({ field, value }, i) => (
          <div key={i} className={'highlight ' + (field.cssClasses ? field.cssClasses : '')}>
            {outputValues(field, value)}
          </div>
        ))}
      </div>
    </div>
  ) : null
}

export default PublishedPreviewHighlights
