import React, { useEffect, useRef, useState } from 'react'
import './Embed.scss'
import { getApiWithAuthorization } from '../../../services/api'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'
import Instructions from '../../shared/Instructions/Instructions'

interface Props {
  previewId: string
  previewLink: string
  previewType: 'preview' | 'invitation'
}

const Embed = function ({ previewId, previewLink, previewType }: Props) {
  const [buttonText, setButtonText] = useState<'Copy GIF Thumbnail' | 'Loading...' | 'Copied!'>('Copy GIF Thumbnail')
  const [copyEmbedCodeText, setCopyEmbedCodeText] = useState<'Copy Embed Code' | 'Loading...' | 'Copied!'>(
    'Copy Embed Code'
  )
  const [cachedGifUrl, setCachedGifUrl] = useState<string>('')
  const [isImageLoading, setImageLoading] = useState(true)
  const gifThumbnailTimeoutRef = useRef<NodeJS.Timeout>()
  const embedCodeTimeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    retrieveGifUrl()
  }, [])

  const retrieveGifUrl = async function () {
    const apiWithAuth = await getApiWithAuthorization()
    const response = await apiWithAuth.get<string>(`/api/preview/${previewId}/gif`)
    if (response.data) {
      setCachedGifUrl(response.data)
      if (buttonText === 'Loading...') {
        onCopy()
      }
    }
  }

  const onCopy = async function () {
    if (cachedGifUrl === '') {
      setButtonText('Loading...')
      return
    }
    const text = previewType === 'preview' ? 'View Preview' : 'View Invitation'
    const embeddedText = `<a href="${previewLink}" style="margin: 0px; box-sizing: border-box; padding: 0px; outline-color: var(--lns-color-focusRing); color: inherit; text-decoration: none; -webkit-font-smoothing: antialiased; letter-spacing: -0.1px; font-family: circular, -apple-system, &quot;system-ui&quot;, &quot;Segoe UI&quot;, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255);"><p style="margin: 0px; box-sizing: border-box; padding: 0px; outline-color: var(--lns-color-focusRing); -webkit-font-smoothing: antialiased; letter-spacing: calc(0.6px - 0.05em);">${text}</p><img src="${cachedGifUrl}" style="margin: 0px; box-sizing: border-box; padding: 0px; outline-color: var(--lns-color-focusRing); max-width: 300px; -webkit-font-smoothing: antialiased; letter-spacing: calc(0.6px - 0.05em);"></a>`
    const plainBlob = new Blob([text], { type: 'text/plain' })
    const htmlBlob = new Blob([embeddedText], { type: 'text/html' })
    // @ts-ignore
    await navigator.clipboard.write([
      // @ts-ignore
      new ClipboardItem({
        ['text/plain']: plainBlob,
        ['text/html']: htmlBlob,
      }),
    ])
    setButtonText('Copied!')
    gifThumbnailTimeoutRef.current && clearTimeout(gifThumbnailTimeoutRef.current)
    gifThumbnailTimeoutRef.current = setTimeout(() => setButtonText('Copy GIF Thumbnail'), 1500)
  }

  const onCopyEmbedCode = async function () {
    if (cachedGifUrl === '') {
      setCopyEmbedCodeText('Loading...')
      return
    }
    await navigator.clipboard.writeText(
      `<meta charset="utf-8"><a href="${previewLink}" style="margin: 0px; box-sizing: border-box; padding: 0px; outline-color: var(--lns-color-focusRing); color: inherit; text-decoration: none; -webkit-font-smoothing: antialiased; letter-spacing: -0.1px; font-family: circular, -apple-system, &quot;system-ui&quot;, &quot;Segoe UI&quot;, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255);"><p style="margin: 0px; box-sizing: border-box; padding: 0px; outline-color: var(--lns-color-focusRing); -webkit-font-smoothing: antialiased; letter-spacing: calc(0.6px - 0.05em);">View Preview</p><img src="${cachedGifUrl}" style="margin: 0px; box-sizing: border-box; padding: 0px; outline-color: var(--lns-color-focusRing); max-width: 300px; -webkit-font-smoothing: antialiased; letter-spacing: calc(0.6px - 0.05em);"></a>`
    )
    setCopyEmbedCodeText('Copied!')
    embedCodeTimeoutRef.current && clearTimeout(embedCodeTimeoutRef.current)
    embedCodeTimeoutRef.current = setTimeout(() => setCopyEmbedCodeText('Copy Embed Code'), 1500)
  }

  return (
    <div className="embed">
      <div className="left">
        <div className="button-container">
          <button onClick={onCopy} className="button">
            {buttonText}
          </button>
          <Instructions instructions="Allow a moment for the GIF to render when you copy it into emails you are drafting and sending. When clicked, the GIF and 'view' text will direct the recipient to your Preview." />
        </div>
        <p>Note: Gif Thumbnail plays in email only.</p>
        <button className="button copy-embed-code" onClick={onCopyEmbedCode}>
          {copyEmbedCodeText}
        </button>
      </div>
      <div className="img-container">
        <img alt="" src={cachedGifUrl} onLoad={() => setImageLoading(false)} hidden={isImageLoading} />
        {isImageLoading && <LoadingSpinner />}
      </div>
    </div>
  )
}

export default Embed
