// In-memory cache for downloaded videos.

const maxCacheItems = 2

type CacheItem = {
  storageId: string
  blob: Blob
}

let cache: CacheItem[] = []

export const get = (storageId: string) => {
  return cache.find(cacheItem => cacheItem.storageId === storageId)?.blob
}

export const put = (storageId: string, blob: Blob) => {
  if (cache.length >= maxCacheItems) {
    cache = cache.slice(cache.length - maxCacheItems)
  }
  cache.push({ storageId, blob })
}
