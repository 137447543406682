import { ContentBlockField, ContentBlock } from 'state/types'

export const flattenChildren = (fields?: ContentBlockField[]) => {
  const flattenedFields: ContentBlockField[] = []

  const addField = (field: ContentBlockField) => {
    flattenedFields.push(field)
    if (field.children) {
      field.children.forEach(addField)
    }
  }

  fields?.forEach(addField)
  return flattenedFields
}

export const isSelfOrDescendant = (fieldId: string, field: ContentBlockField): boolean => {
  return fieldId === field.id || !!field.children?.some(f => isSelfOrDescendant(fieldId, f))
}

export const findRootField = (fieldId: string, fields?: ContentBlockField[]) => {
  return fields?.find(f => isSelfOrDescendant(fieldId, f))
}

export const findFieldInTree = (block?: ContentBlock, fieldId?: string) => {
  return flattenChildren(block?.contentBlockFields)?.find(f => f.id === fieldId)
}
