import Tooltip from 'components/shared/Tooltip/Tooltip'
import React from 'react'
import './InfoPopup.scss'

type Props = {
  customIcon?: React.ReactNode
  label?: string
}

const InfoPopup: React.FC<Props> = ({ customIcon, children, label }) => (
  <Tooltip className="info-popup" content={children}>
    <div className={'info-popup-content'}>
      {customIcon || <div className="question-icon">?</div>} {true ? <label>{label}</label> : ''}
    </div>
  </Tooltip>
)

export default InfoPopup
