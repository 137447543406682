import React, { ChangeEventHandler, useCallback } from 'react'
import { parseLink } from 'helpers/validation'
import FormInput from 'components/shared/FormInput/FormInput'
import './InvitationLinkInput.scss'
import CrossIcon from '../../../assets/icons/CrossIcon'
import Instructions from '../../shared/Instructions/Instructions'
import Dropdown from 'components/shared/Dropdown/Dropdown'

type Props = {
  name?: string
  value?: string
  onChange?: (value: string) => void
  removeFromList?: () => void
  instructions?: string
}

const targetTypes = [
  {
    value: '_blank',
    displayValue: 'Open in new tab',
    placeholder: 'Open in new tab',
  },
  {
    value: 'popup',
    displayValue: 'Open as popup',
    placeholder: 'Open as popup',
  },
]

const InvitationLinkInput: React.FC<Props> = ({ name, value, onChange, removeFromList, instructions }) => {
  const { text, url, target } = parseLink(value)
  const linkId = name + '-link'
  const targetDropdownId = name + '-target-dropdown-id'

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    notifyChange(e.target.value, url, target)
  }

  const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    notifyChange(text, e.target.value, target)
  }

  const handleTargetChange = (value: string) => {
    notifyChange(text, url, value)
  }

  const notifyChange = (t: string, u: string, tg: string) => onChange?.(JSON.stringify({ text: t, url: u, target: tg }))

  const renderTargetTypeDropdownValue = useCallback(
    (value?: string, displayValue?: string) =>
      value && displayValue ? (
        <div className="dropdown-item">
          <span>{displayValue}</span>
        </div>
      ) : null,
    []
  )

  return (
    <div className="invitation-link-input">
      <div className="invitation-link-title">
        <div className="label-row">
          <div>
            <label htmlFor={linkId}>Button Text</label>
            {instructions && <Instructions instructions={instructions} />}
          </div>
          {removeFromList && (
            <button type="button" className="remove-button plain phone" onClick={removeFromList}>
              <CrossIcon />
              <span>Remove</span>
            </button>
          )}
        </div>
        <FormInput
          name={name + '-text'}
          value={text}
          onChange={handleTextChange}
          maxLength={24}
        />
      </div>
      <div className="invitation-link-url">
        <div className="label-row">
          <label htmlFor={linkId}>Link</label>
        </div>
        <FormInput
          id={linkId}
          name={name + '-url'}
          value={url}
          onChange={handleURLChange}
          placeholder="https://www.example.com"
        />
      </div>
      <div className="invitation-link-target">
        <div className="label-row">
          <label htmlFor={targetDropdownId}>Target</label>
          {removeFromList && (
            <button type="button" className="remove-button plain" onClick={removeFromList}>
              <CrossIcon />
              <span>Remove</span>
            </button>
          )}
        </div>
        <Dropdown
          id={targetDropdownId}
          className="target-type"
          placeholder="Target"
          selectedValue={target}
          options={targetTypes}
          onChange={handleTargetChange}
          renderOption={renderTargetTypeDropdownValue}
          renderSelectedOption={renderTargetTypeDropdownValue}
        />
      </div>
    </div>
  )
}

export default InvitationLinkInput
