import React from 'react'
import { Link } from 'react-router-dom'
import { PreviewSummary } from 'state/types'
import Dropdown from 'components/shared/Dropdown/Dropdown'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import StarIcon from 'assets/icons/StarIcon'
import './InvitationPreviewsListMobile.scss'
import ProfileCell from '../../shared/ProfileCell/ProfileCell'

type Props = {
  invitationId: string
  previews: PreviewSummary[]
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  toggleStarred: (previewId: string) => void
}

const sortOptions = [
  { value: 'name-true', displayValue: 'Name A-Z' },
  { value: 'name-false', displayValue: 'Name Z-A' },
  { value: 'firstPublishDate-false', displayValue: 'Newest first' },
  { value: 'firstPublishDate-true', displayValue: 'Oldest first' },
  { value: 'starred-true', displayValue: 'Starred first' },
  { value: 'starred-false', displayValue: 'Unstarred first' },
]

const InvitationPreviewsListMobile: React.FC<Props> = ({
  invitationId,
  previews,
  sortingBy,
  sortingAsc,
  onSortingByChange,
  onSortingAscChange,
  toggleStarred,
}) => {
  const selectedValue = sortingBy + '-' + sortingAsc

  const onDropdownChange = (newSelection: string) => {
    const s = newSelection.split('-')
    onSortingByChange?.(s[0])
    onSortingAscChange?.(s[1] === 'true')
  }

  return (
    <div className="invitation-previews-list-mobile">
      <Dropdown
        className="sort-dropdown"
        selectedValue={selectedValue}
        options={sortOptions}
        onChange={onDropdownChange}
        renderSelectedOption={(_, displayValue) => (
          <div className="sort-dropdown-selected-option">
            <span className="sort-by-label">Sort by:</span>
            <span>{displayValue}</span>
          </div>
        )}
      />
      {previews.map(({ id, givenName, familyName, profileImageId, starred, viewed }) => {
        return (
          <div key={id} className="invitation-previews-list-card">
            <button className={'star-button plain' + (starred ? ' active' : '')} onClick={() => toggleStarred(id)}>
              <StarIcon />
            </button>
            <Link className="preview-link" to={`/preview/${id}?i=${invitationId}`}>
              <ProfileCell
                givenName={givenName}
                familyName={familyName}
                profileImageId={profileImageId}
                notViewed={!viewed}
              />
              <ChevronRightIcon />
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default InvitationPreviewsListMobile
