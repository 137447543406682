import { getApi } from './api'
import { Option } from 'state/types'

const API_PATH = '/api/config'

export const getFieldSetOptions = async (fieldSetId: string) => {
  const { ok, data } = await getApi().get<Record<string, string>>(`${API_PATH}/${fieldSetId}`)
  if (ok && data) {
    return Object.entries(data).map(([key, value]) => ({ value: key, displayValue: value } as Option))
  }
  return []
}

export const getVideoCategoryOptions = async (templateId: string) => {
  const { ok, data } = await getApi().get<{ id: string; description: string }[]>(
    `${API_PATH}/videoCategories/${templateId}`
  )
  if (ok && data) {
    return data.map(({ id, description }) => ({ value: id, displayValue: description } as Option))
  }
  return []
}
