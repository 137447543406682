import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Preview } from 'state/types'
import * as previewService from 'services/previewService'
import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import './ExamplePage.scss'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'

type Props = {
  createPreviewPage: (id: string, isIndividualCountAtMaxForFree: boolean, isBusinessCountAtMaxForFree: boolean) => void
  cancelLink: string
  prevLink: string
  titleText: string
  isInvitation: boolean
}

const ExamplePage: React.FC<Props> = ({ createPreviewPage, cancelLink, prevLink, titleText, isInvitation }) => {
  const { templateId } = useParams<any>()
  const [template, setTemplate] = useState<Preview | undefined>()
  const [isLoading, setLoading] = useState(true)
  const [isCountByCategoryLoading, setCountByCategoryLoading] = useState(false)
  useEffect(() => {
    const loadTemplate = async () => {
      if (templateId) {
        setLoading(true)
        const { ok, data } = await previewService.getTemplateSummary(templateId)
        setLoading(false)
        if (ok && data) {
          setTemplate(data)
        }
      }
    }
    loadTemplate()
  }, [templateId])

  return (
    <FullScreenPageFrame
      className="example-page"
      cancelLink={cancelLink}
      prevLink={prevLink}
      title={isLoading ? undefined : template ? template?.name : 'Template Not Found'}
      subtitle={isLoading ? undefined : titleText}
    >
      <>
        {isLoading ? (
          <LoadingSpinner container expand />
        ) : (
          <>
            <img
              className="desktop-preview-example"
              alt=""
              src={!isInvitation ? template?.templateDesktopUrl : template?.invitationTemplateDesktopUrl}
            />
            <img
              className="mobile-preview-example"
              alt=""
              src={!isInvitation ? template?.templateMobileUrl : template?.invitationTemplateMobileUrl}
            />
          </>
        )}

        <div className="example-page-footer">
          <LoadingButton
            isLoading={isCountByCategoryLoading}
            className="edit-preview-link button primary"
            onClick={async () => {
              if (templateId) {
                setCountByCategoryLoading(true)
                const { ok, data } = await previewService.getPreviewCategoryCount()
                setCountByCategoryLoading(false)
                if (ok && data) {
                  createPreviewPage(
                    templateId,
                    data.overLimitForFreeBusinessPreviews,
                    data.overLimitForFreeIndividualPreviews
                  )
                }
              }
            }}
          >
            <span>Let's Go!</span>
          </LoadingButton>
        </div>
      </>
    </FullScreenPageFrame>
  )
}

export default ExamplePage
