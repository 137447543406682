import React from 'react'
import { Link } from 'react-router-dom'
import { isIE } from 'helpers/browser'
import errorIllustration from 'assets/error-illustration.png'
import './ErrorPage.scss'

type Props = {
  titleText: string
  illustration?: string
  className?: string
}

const ErrorPage: React.FC<Props> = ({ titleText, illustration, className, children }) => (
  <div className={'error-page' + (className ? ' ' + className : '') + (isIE ? ' internet-explorer' : '')}>
    <div className="header">
      <div className="header-content">
        <Link className="logo" to="/" />
      </div>
    </div>
    <div className="page-content">
      <div className="text">
        <h1>{titleText}</h1>
        <div className="body-text">{children}</div>
      </div>
      <div className="illustration" style={{ backgroundImage: `url('${illustration || errorIllustration}')` }} />
    </div>
    <div className="spacer" />
  </div>
)

export default ErrorPage
