import { Organization, Workspace } from 'state/types'
import { currentWorkspaceKey, workspacesKey } from 'state/useWorkspace'
import { WorkspaceType } from './enum'

export const isAccountOrganizationType = () => {
  const localStorageOrganizationsString = localStorage.getItem('organizations')
  if (localStorageOrganizationsString == null) {
    return false
  } else {
    const localStorageOrganizationsObject = JSON.parse(localStorageOrganizationsString)
    return localStorageOrganizationsObject[0] !== undefined
  }
}

export const getworkspaceOrganizationId = () => {
  const allOrganizations = localStorage.getItem('organizations')
  if (allOrganizations && JSON.parse(allOrganizations)[0]) {
    return JSON.parse(allOrganizations)[0].id
  }
  return undefined
}

export const getFirstOrganization = () => {
  const allOrganizations = localStorage.getItem('organizations')
  if (allOrganizations && JSON.parse(allOrganizations)[0]) {
    return JSON.parse(allOrganizations)[0]
  }
  return undefined
}

export const getAllOrganizations = () => {
  const allOrganizations = localStorage.getItem('organizations')
  if (allOrganizations && JSON.parse(allOrganizations)) {
    return JSON.parse(allOrganizations) as Organization[]
  }
  return undefined
}

export const getAllWorkspaces = () => {
  const allWorkspaces = localStorage.getItem(workspacesKey)
  if (allWorkspaces) {
    return JSON.parse(allWorkspaces) as Workspace[]
  }
  return undefined
}

export const getAllOrganizationWorkspaces = () => {
  const allWorkspaces = getAllWorkspaces()
  if (allWorkspaces) {
    return allWorkspaces.filter(workspace => workspace.type !== WorkspaceType.User)
  }
  return undefined
}

export const getCurrentWorkspace = () => {
  const currentWorkspace = localStorage.getItem(currentWorkspaceKey)
  if (currentWorkspace) {
    return JSON.parse(currentWorkspace) as Workspace
  }
}

export const getWorkspaceOrganization = () => {
  const currentWorkspace = localStorage.getItem(currentWorkspaceKey)
  if (currentWorkspace && JSON.parse(currentWorkspace).type === WorkspaceType.Organization) {
    return JSON.parse(currentWorkspace) as Organization
  }
}

export const getWorkspaceType = () => {
  const currentWorkspace: Workspace | undefined = getCurrentWorkspace()
  if (currentWorkspace) {
    return currentWorkspace.type
  }
}

export const getWorkspaceOrganizationId = () => {
  const workspaceOrganization = getWorkspaceOrganization()
  return workspaceOrganization?.id
}

export const getWorkspaceOrganizationFreeTrial = () => {
  const workspaceOrganization = getWorkspaceOrganization()
  return workspaceOrganization?.freeTrial
}

export const getFirstOrganizationFreeTrial = () => {
  const allOrganizations = localStorage.getItem('organizations')
  if (allOrganizations && JSON.parse(allOrganizations)[0]) {
    return JSON.parse(allOrganizations)[0].freeTrial
  }
  return undefined
}
