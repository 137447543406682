import { loadStripe } from '@stripe/stripe-js'
import { stripePublicApiKey } from 'helpers/config'
import { getWorkspaceOrganizationId } from 'helpers/organization'
import { ChargePreview, PaymentDetails, Plan, Product, StripeSession, Subscription } from 'state/types'
import { getApiWithAuthorization } from './api'
import useWorkspace from '../state/useWorkspace'
import useSubscription from '../state/useSubscription'
import { BillingType } from '../helpers/enum'

export const redirectToStripeCheckout = async (productId: string, priceId: string, search: string, backTo?: string, editedPlanName?: string, togglePage?:number, newProduct?: Product) => {
  const authApi = await getApiWithAuthorization()
  const organizationId = getWorkspaceOrganizationId()
  const editedNewPlanName = togglePage === 2 ? newProduct?.name + " Annual " : newProduct?.name
  const { ok, data } = await authApi.post<StripeSession>(
    `/api/stripe/session`,
    createStripeSessionObject(false, search, backTo, productId, priceId, organizationId, editedNewPlanName)
  )

  if (ok && data && data.sessionId) {
    const stripe = await loadStripe(stripePublicApiKey, { stripeAccount: data.stripeAccountId })

    const result = await stripe?.redirectToCheckout({ sessionId: data.sessionId })
    return !!result && !result.error
  } else {
    return false
  }
}

export const getSubscription = async () => {
  const authApi = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return authApi.get<Subscription>(
    '/api/subscription' + (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getSubscriptionByOrganizationId = async (organizationId: string | undefined) => {
  const authApi = await getApiWithAuthorization()
  return authApi.get<Subscription>(
    '/api/subscription' + (organizationId !== undefined ? `?organizationId=${organizationId}` : '')
  )
}

export const getPreviewSubscription = async (subscription: Subscription, newProductId: string) => {
  const authApi = await getApiWithAuthorization()
  subscription.preview = true
  subscription.productId = newProductId
  return authApi.patch<Subscription>(`/api/subscription/${subscription.id}/${BillingType.Monthly}`, subscription)
}

export const changePlan = async (subscription: Subscription) => {
  const authApi = await getApiWithAuthorization()
  subscription.preview = false
  return authApi.patch<Subscription>(`/api/subscription/${subscription.id}/${BillingType.Monthly}`, subscription)
}

export const getPaymentDetails = async (subscription: Subscription) => {
  const authApi = await getApiWithAuthorization()
  return authApi.get<PaymentDetails>(`/api/subscription/${subscription.id}/payment`)
}

export const getChargePreview = async (plan: Plan) => {
  const authApi = await getApiWithAuthorization()
  return authApi.get<ChargePreview>('/api/payment/chargePreview?plan=' + plan)
}

export const updatePaymentMethod = async (backTo: string) => {
  const authApi = await getApiWithAuthorization()
  const organizationId = getWorkspaceOrganizationId()

  const { ok, data } = await authApi.post<StripeSession>(
    `/api/stripe/session`,
    createStripeSessionObject(true, backTo, backTo, undefined, undefined, organizationId)
  )

  if (ok && data && data.sessionId) {
    const stripe = await loadStripe(stripePublicApiKey, { stripeAccount: data.stripeAccountId })

    const result = await stripe?.redirectToCheckout({ sessionId: data.sessionId })
    return !!result && !result.error
  } else {
    return false
  }
}

const createStripeSessionObject = (
  updatePaymentDetails: boolean,
  search?: string,
  backTo?: string,
  productId?: string,
  priceId?: string,
  organizationId?: string,
  editedNewPlanName?: string | undefined
) => {
  const successUrl = updatePaymentDetails
    ? window.location.origin + '/payment/success' + (search ? '?from=' + search : '')
    : window.location.origin + '/plan/success' + (search ? '?' + search : '') + (!search ? '?' : '&' + `&editedNewPlanName=${editedNewPlanName}`)
  const cancelUrl = window.location.origin + (backTo || '')

  return {
    successUrl,
    cancelUrl,
    updatePaymentDetails: updatePaymentDetails,
    productId: productId,
    priceId: priceId,
    organizationId: organizationId,
  }
}
