import { Checkbox, MenuItem } from '@material-ui/core'
import React from 'react'
import './MenuOption.scss'

type Props = {
  optionName: string
  selectedOption?: boolean
  showCheckbox?: boolean
  onSelect: (value: any) => any
}

const MenuOption: React.FC<Props> = ({ optionName, selectedOption, showCheckbox, onSelect, children }) => {
  return (
    <MenuItem onClick={onSelect}>
      <div className="menu-option">
        {showCheckbox ? <Checkbox className="checkbox" color="primary" checked={selectedOption} /> : ''}
        <label className="name">{optionName}</label>
        {!optionName && children}
      </div>
    </MenuItem>
  )
}

export default MenuOption
