import React, { useState } from 'react'
import './SearchBox.scss'

type Props = {
  onChange?: any
  searchKey: string
}

const SearchBox: React.FC<Props> = ({ searchKey, onChange }) => {
  const [myTimeout, setMyTimeout] = useState<any>(0)

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (onChange) onChange(null, value)

    if (myTimeout) {
      clearTimeout(myTimeout)
    }

    const tmpTO = setTimeout(() => {}, 400)
    setMyTimeout(tmpTO)
  }

  const clearSearchKey = () => {
    if (onChange) onChange(null, '')

    if (myTimeout) {
      clearTimeout(myTimeout)
    }

    const tmpTO = setTimeout(() => {}, 400)
    setMyTimeout(tmpTO)
  }

  return (
    <div>
      <input
        type="input"
        className="search-box"
        placeholder="Search ..."
        value={searchKey}
        onChange={handleSearchQueryChange}
      />
      <div className="search-wrapper">
        <div className="search-icon">
          <div className="search">
            <div className="search__circle"></div>
            <div className="search__rectangle"></div>
          </div>
        </div>
      </div>
      <div className="delete-icon-wrapper" onClick={clearSearchKey}>
        <div className="delete-icon">
          <div className="oval"></div>
          <div className="line-7-1"></div>
          <div className="line-7"></div>
        </div>
      </div>
    </div>
  )
}

export default SearchBox
