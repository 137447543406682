import React, { useEffect } from 'react'
import { Hub } from '@aws-amplify/core'
import { Redirect } from 'react-router-dom'
import { getLocationFrom, getClientQueryParam } from 'helpers/paramStorage'
import useAuth from 'state/useAuth'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'

const getRedirectUrl = () => {
  const storedFrom = getLocationFrom()
  const storedClient = getClientQueryParam()
  return (storedFrom || '/') + (storedClient ? `?client=${storedClient}` : '')
}

// This page is where we are redirected back to after login via social auth.
const LoginSuccessPage = () => {
  const { isLoggedIn, loadSession } = useAuth()
  useEffect(() => {
    const hubCallback = async ({ payload: { event } }: any) => {
      if (event === 'signIn') {
        loadSession()
      }
    }
    Hub.listen('auth', hubCallback)
    return () => {
      Hub.remove('auth', hubCallback)
    }
  }, [loadSession])

  return isLoggedIn ? <Redirect to={getRedirectUrl()} /> : <LoadingSpinner fullScreen />
}

export default LoginSuccessPage
