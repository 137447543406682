import React from 'react'

const ShareOnLinkedInIcon = () => (
  <svg className="icon" width="50px" height="50px" viewBox="0 0 50 50">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-793.000000, -442.000000)">
        <ellipse cx="817.978166" cy="465" rx="20" ry="20" fill="#4384F4"></ellipse>
        <path
          d="M825.170306,471.4 L821.814226,471.4 L821.814226,466.618028 C821.814226,465.367067 821.289866,464.513236 820.134857,464.513236 C819.252657,464.513236 818.762841,465.089075 818.533434,465.645921 C818.445745,465.844487 818.460803,466.121615 818.460803,466.399606 L818.460803,471.4 L815.135723,471.4 C815.135723,471.4 815.180011,462.930756 815.135723,462.160668 L818.460803,462.160668 L818.460803,463.610195 C818.656552,462.976512 819.719443,462.071745 821.414755,462.071745 C823.517509,462.071745 825.170306,463.400406 825.170306,466.262337 L825.170306,471.4 Z M811.774329,461.004672 L811.752185,461.004672 C810.682208,461.004672 809.9869,460.297607 809.9869,459.403199 C809.9869,458.491525 810.701694,457.8 811.794701,457.8 C812.885936,457.8 813.55733,458.490662 813.579473,459.401473 C813.579473,460.29588 812.885936,461.004672 811.774329,461.004672 L811.774329,461.004672 Z M810.368655,471.4 L813.330579,471.4 L813.330579,462.160668 L810.368655,462.160668 L810.368655,471.4 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </svg>
)

export default ShareOnLinkedInIcon
