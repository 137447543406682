import React from 'react'
import { Controller } from 'react-hook-form'
import { ContentBlockField, Option } from 'state/types'
import { yesterdayMidday } from 'helpers/date'
import TeamMemberInput from 'components/preview/TeamMemberInput/TeamMemberInput'
import WebsiteLinkInput from 'components/preview/WebsiteLinkInput/WebsiteLinkInput'
import LinkInput from 'components/preview/LinkInput/LinkInput'
import GroupInput from 'components/preview/GroupInput/GroupInput'
import FormInput from 'components/shared/FormInput/FormInput'
import FormSelect from 'components/shared/FormSelect/FormSelect'
import FormTextArea from 'components/shared/FormTextArea/FormTextArea'
import FormMultiSelect from 'components/shared/FormMultiSelect/FormMultiSelect'
import DropdownFreeText from 'components/shared//DropdownFreeText/DropdownFreeText'
import FormDatePicker from 'components/shared/FormDatePicker/FormDatePicker'
import InvitationLinkInput from '../InvitationLinkInput/InvitationLinkInput'
import FormMonthYear from '../../shared/FormMonthYear/FormMonthYear'
import { Dimension, UnitType } from '../../../helpers/enum'
import NumericalInput from '../NumericalInput/NumericalInput'
import DateSelector from '../DateSelector/DateSelector'
import PeopleInput from '../PeopleInput/PeopleInput'
import DetailsInput from '../DetailsInput/DetailsInput'
import AvailabilitySelector from '../AvailabilitySelector/AvailabilitySelector'
import FormListEditor from '../FormListEditor/FormListEditor'
import KeyValuePairInput from '../KeyValuePairInput/KeyValuePairInput'
import PercentageInput from '../../shared/PercentageInput/PercentageInput'
import FormConditionalGroupSelect from '../../shared/FormConditionalGroupSelect/FormConditionalGroupSelect'

type Props = {
  previewId: string
  blockIndex: number
  field: ContentBlockField
  register: any
  control: any
  error?: string
  errors?: any
  selectedUnitType?: UnitType
  showManualDetailsEntry?: boolean
  hideDetails?: boolean
  setValue?: (name?: string, value?: string) => void
  removeFromList?: () => void
  tagFilter?: Array<string>
  tags?: Array<string>
}

const getInputProps = (field: ContentBlockField): React.InputHTMLAttributes<HTMLInputElement> => {
  if (field.formLabel === 'First name' || field.formLabel === 'Last name') {
    return { spellCheck: false }
  } else if (field.controlType === 'Email') {
    return { type: 'email', spellCheck: false }
  } else if (field.controlType === 'Phone') {
    return { type: 'tel' }
  }
  return {}
}

const FormFieldEditor: React.FC<Props> = ({
  previewId,
  blockIndex,
  field,
  register,
  control,
  error,
  errors,
  showManualDetailsEntry,
  hideDetails,
  selectedUnitType,
  setValue,
  removeFromList,
  tagFilter,
}) => {
  // If there ia filter applied to the fields only show the fields that have the correct tag
  if (field.tags && tagFilter && !tagFilter.includes('other')) {
    const includesFilter = tagFilter.some(tag => {
      return field.tags.includes(tag)
    })
    if (!includesFilter) {
      return null
    }
  }

  const props = {
    key: field.id,
    name: field.id,
    error,
  }

  const label = field.formLabel + (field.required ? '*' : '')
  const options = field.options ? Object.entries(field.options).map(o => ({ value: o[0], displayValue: o[1] })) : []

  switch (field.controlType) {
    case 'Duration':
      const durationOptions: Array<Option> = [
        { value: 'Seconds', displayValue: 'Seconds' },
        { value: 'Minutes', displayValue: 'Minutes' },
        { value: 'Hours', displayValue: 'Hours' },
      ]
      const durationDropdownFreeText = (
        <DropdownFreeText
          label={label}
          placeholder={field.placeholder}
          options={durationOptions}
          removeFromList={removeFromList}
          instructions={field.instructions}
        />
      )
      return <Controller {...props} control={control} as={durationDropdownFreeText} />
    case 'Percentage':
      const numericalPercentageInput = <PercentageInput formLabel={label} instructions={field.instructions} />
      return <Controller {...props} control={control} as={numericalPercentageInput} />
    case 'Length':
      const numericalLengthInput = (
        <NumericalInput
          formLabel={field.formLabel}
          dimension={Dimension.Length}
          unitType={selectedUnitType ? selectedUnitType : UnitType.Metric}
          instructions={field.instructions}
        />
      )
      return <Controller {...props} control={control} as={numericalLengthInput} />
    case 'LengthSmall':
      const numericalLengthSmallInput = (
        <NumericalInput
          formLabel={field.formLabel}
          dimension={Dimension.SmallLength}
          unitType={selectedUnitType ? selectedUnitType : UnitType.Metric}
          instructions={field.instructions}
        />
      )
      return <Controller {...props} control={control} as={numericalLengthSmallInput} />
    case 'Weight':
      const numericalWeightInput = (
        <NumericalInput
          formLabel={field.formLabel}
          dimension={Dimension.Weight}
          unitType={selectedUnitType ? selectedUnitType : UnitType.Metric}
          instructions={field.instructions}
        />
      )
      return <Controller {...props} control={control} as={numericalWeightInput} />
    case 'Dropdown':
      const select = <FormSelect label={label} options={options} instructions={field.instructions} />
      return <Controller {...props} control={control} as={select} />
    case 'MultiSelectDropdown':
      const multiSelect = (
        <FormMultiSelect
          label={label}
          placeholder={field.placeholder}
          options={options}
          instructions={field.instructions}
        />
      )
      return <Controller {...props} control={control} as={multiSelect} />
    case 'DropdownFreeText':
      const dropdownFreeText = (
        <DropdownFreeText
          label={label}
          placeholder={field.placeholder}
          options={options}
          removeFromList={removeFromList}
          instructions={field.instructions}
        />
      )
      return <Controller {...props} control={control} as={dropdownFreeText} />
    case 'DropdownWithOtherFreeText':
      const dropdownWithOtherFreeText = (
        <DropdownFreeText
          label={label}
          placeholder={field.placeholder}
          options={options}
          otherFreeText
          instructions={field.instructions}
        />
      )
      return <Controller {...props} control={control} as={dropdownWithOtherFreeText} />
    case 'Website':
      const websiteLinkInput = <WebsiteLinkInput label={field.formLabel} removeFromList={removeFromList} />
      return <Controller {...props} control={control} as={websiteLinkInput} />
    case 'Link':
      const linkInput = <LinkInput />
      return <Controller {...props} control={control} as={linkInput} />
    case 'KeyValuePair':
      const keyValuePair = <KeyValuePairInput removeFromList={removeFromList} />
      return <Controller {...props} control={control} as={keyValuePair} />
    case 'Date':
      const dateSelector = <DateSelector label={field.formLabel} instructions={field.instructions} />
      return <Controller {...props} control={control} as={dateSelector} />
    case 'MonthYear':
      const monthYear = <FormMonthYear label={label} instructions={field.instructions} />
      return <Controller {...props} control={control} as={monthYear} />
    case 'DatePicker':
      const datePicker = (
        <FormDatePicker
          stringify
          label={label}
          fromMonth={yesterdayMidday()}
          setValue={setValue}
          instructions={field.instructions}
        />
      )
      return <Controller {...props} control={control} as={datePicker} />
    case 'Availability':
      const availabilitySelector = (
        <AvailabilitySelector label={label} placeholder={field.placeholder} instructions={field.instructions} />
      )
      return <Controller {...props} control={control} as={availabilitySelector} />
    case 'Group':
      return (
        <GroupInput
          {...props}
          previewId={previewId}
          blockIndex={blockIndex}
          field={field}
          register={register}
          control={control}
          errors={errors}
          removeFromList={removeFromList}
          tagFilter={tagFilter}
        />
      )
    case 'TeamMember':
      return (
        <TeamMemberInput
          {...props}
          previewId={previewId}
          blockIndex={blockIndex}
          field={field}
          register={register}
          control={control}
          errors={errors}
          removeFromList={removeFromList}
        />
      )
    case 'People':
      return (
        <PeopleInput
          {...props}
          previewId={previewId}
          blockIndex={blockIndex}
          field={field}
          register={register}
          control={control}
          errors={errors}
          removeFromList={removeFromList}
        />
      )
    case 'Details':
      return (
        <DetailsInput
          {...props}
          previewId={previewId}
          blockIndex={blockIndex}
          field={field}
          register={register}
          control={control}
          errors={errors}
          removeFromList={removeFromList}
          showManualEntry={showManualDetailsEntry}
        />
      )
    case 'TextArea':
      return <FormTextArea {...props} label={label} textAreaRef={register} instructions={field.instructions} isResizable largeTextArea />
    case 'InvitationLink':
      const invitationLinkInput = (
        <InvitationLinkInput removeFromList={removeFromList} instructions={field.instructions} />
      )
      return <Controller {...props} control={control} as={invitationLinkInput} />
    case 'Text':
    case 'TextWithLabel':
    case 'Email':
    case 'Phone':
    case 'Address':
      return (
        <FormInput
          {...props}
          label={label}
          placeholder={field.placeholder}
          inputRef={register}
          {...getInputProps(field)}
          instructions={field.instructions}
        />
      )
    case 'List':
      return (
        <FormListEditor
          {...props}
          label={label}
          field={field}
          previewId={previewId}
          blockIndex={blockIndex}
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          inGroup
        />
      )
    case 'ConditionalGroup':
      const conditionalGroupSelect = (
        <FormConditionalGroupSelect
          label={label}
          options={options}
          field={field}
          previewId={previewId}
          blockIndex={blockIndex}
          register={register}
          control={control}
          errors={errors}
          removeFromList={removeFromList}
        />
      )
      return <Controller {...props} control={control} as={conditionalGroupSelect} />
    default:
      return null
  }
}

export default FormFieldEditor
