import React from 'react'

const PlayButtonPlain = () => (


<svg width="48px" height="48px" viewBox="0 0 48 48">
<g id="Brand-Settings-21.04.23" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
  <g id="Brand-Settings-New-1" transform="translate(-648.000000, -416.572500)" fill="#FFFFFF">
    <g id="Group-2" transform="translate(323.000000, 214.000000)">
      <g id="Play-Button-Copy-2" transform="translate(325.000000, 202.572500)">
        <path d="M24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 Z M19.7078125,16.5588542 L19.7078125,31.4921875 L31.4921875,24.0255208 L19.7078125,16.5588542 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </g>
</g>
</svg>
)

export default PlayButtonPlain
