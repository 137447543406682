import React from 'react'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'

type Props = {
  isShowing: boolean
  onClose: () => void
  onOk: () => void
}

const PublishReviewDialog: React.FC<Props> = ({ isShowing, onClose, onOk }) => (
  <ConfirmationDialog
    className="publish-review-dialog"
    isShowing={isShowing}
    cancelButtonText="No, thanks"
    okButtonText={`Yes, let's review`}
    title="You're almost ready to publish!"
    onClose={onClose}
    onOk={onOk}
  >
    You just have to fill in any mandatory fields you missed. Want us to highlight the missing information?
  </ConfirmationDialog>
)

export default PublishReviewDialog
