import React from 'react'
import { ContentBlockField } from 'state/types'
import { formatPreviewLabel, formatPreviewValue } from 'helpers/formatting'
import './PublishedPreviewGroups.scss'
import { validateGroup } from '../../../helpers/validation'

type Props = {
  fields: ContentBlockField[]
  trackLinkClick: (id: string) => void
}

const PublishedPreviewGroups: React.FC<Props> = ({ fields, trackLinkClick }) => (
  <div className="published-preview-groups">
    {fields.map(
      field =>
        validateGroup(field) && (
          <div className={'published-preview-group' + (field.cssClasses ? ' ' + field.cssClasses : '')} key={field.id}>
            {field.previewLabel && <div className={`label ${field.cssClasses}`}>{field.previewLabel}</div>}
            {field.children?.map(child => (
              <React.Fragment key={child.id}>
                <div className={child.cssClasses ? ' ' + child.cssClasses : ''}>
                  {formatPreviewValue(child, false, trackLinkClick) && formatPreviewLabel(child)}
                  <div className="value">{formatPreviewValue(child, false, trackLinkClick)}</div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )
    )}
  </div>
)

export default PublishedPreviewGroups
