import React from 'react'
import { ContentBlockField } from 'state/types'
import { formatPreviewLabel, formatPreviewValue } from 'helpers/formatting'
import './PublishedPreviewConditionalGroups.scss'
import { parseDropdownFreeText, validateDurationField, validateGroup } from '../../../helpers/validation'

type Props = {
  field: ContentBlockField
  trackLinkClick: (id: string) => void
}

const PublishedPreviewConditionalGroups: React.FC<Props> = ({ field, trackLinkClick }) => {
  const renderField = (child: ContentBlockField, rowDataLayout: boolean | undefined) => {
    const classes =
      'value' + (child.cssClasses ? ` ${child.cssClasses}` : '') + (rowDataLayout ? ' row-data-layout-item' : '')
    return (
      <React.Fragment key={child.id}>
        <div className={classes}>
          {formatPreviewValue(child, false, trackLinkClick) && formatPreviewLabel(child)}
          <div className="value">{formatPreviewValue(child, false, trackLinkClick)}</div>
        </div>
      </React.Fragment>
    )
  }
  /**
   * Loop over the groups children and output the fields.
   * @param childGroup The conditional fields children which are of the controlType Group
   * @param fieldFilter Not used yet but can be used to filter out the fields that are not required to be rendered
   * @param rowDataLayout
   * @param key
   */
  const outputGroup = (
    childGroup: ContentBlockField,
    fieldFilter: string | null,
    rowDataLayout: boolean | undefined,
    key: number
  ) => {
    if (childGroup.controlType === 'Group' && validateGroup(childGroup)) {
      // Child array used to group the fields
      let children: ContentBlockField[] = []

      childGroup.children?.map((child, i) => {
        // Validate that duration fields have both values else skip the field
        if (child.controlType === 'Duration' && child.value) {
          if (!validateDurationField(JSON.parse(child.value as string))) {
            return false
          }
        }

        // Populate the child array
        if (child.value !== 'null' && child.value) {
          if (fieldFilter) {
            if (child.tags && child.tags.includes(fieldFilter)) {
              children.push(child)
            }
          } else {
            children.push(child)
          }
        }
      })

      // Only output fields if there are any withing the child array. this
      // prevents us from outputting fields that don't match the filter or don't
      // have a value
      if (children.length) {
        const childOutput = children.map((child, i) => {
          return <div key={i}>{renderField(child, rowDataLayout)}</div>
        })

        return (
          <div key={key}>
            <h4 className="published-preview-group__group-title">{childGroup.previewLabel}</h4>
            {childOutput}
          </div>
        )
      }
    }
  }

  const parsedFieldValue = parseDropdownFreeText(field.value)
  const fieldFilter = parsedFieldValue.dropdown == 'other' ? parsedFieldValue.freeText : parsedFieldValue.dropdown;

  return (
    <div className="published-preview-groups">
      {validateGroup(field) && (
        <div className={'published-preview-group' + (field.cssClasses ? ' ' + field.cssClasses : '')} key={field.id}>
          <div className={field.rowDataLayout ? 'row-data-layout-item' : ''}>
            {field.previewLabel && <div className="label">{field.previewLabel}</div>}
            {field.value && <div className="value">{fieldFilter}</div>}
          </div>
          {field.children?.map((childGroup, i) => outputGroup(childGroup, fieldFilter, field.rowDataLayout, i))}
        </div>
      )}
    </div>
  )
}

export default PublishedPreviewConditionalGroups
