import AuthPage from 'components/auth/AuthPage/AuthPage'
import BackLink from 'components/shared/BackLink/BackLink'
import FormInput from 'components/shared/FormInput/FormInput'
import FormMultiSelect from 'components/shared/FormMultiSelect/FormMultiSelect'
import FormSelect from 'components/shared/FormSelect/FormSelect'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import { useUrlParams } from 'helpers/urlParams'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'
import * as configService from 'services/configService'
import { Option } from 'state/types'
import useAuth from 'state/useAuth'
import useWorkspace from 'state/useWorkspace'
import * as yup from 'yup'
import './OrganizationOnboardingPage.scss'

type FormData = {
  organizationName: string
  organizationIndustries: string[]
  organizationCountry: string
  organizationEmail: string
}

const validationSchema = yup.object().shape({
  organizationName: yup.string().required('Please provide the name'),
  organizationCountry: yup.string().required('Please select the country'),
  organizationIndustries: yup.array().required('Please select the industries'),
  organizationEmail: yup
    .string()
    .email('Must provide a valid email address')
    .required('Please provide the email address'),
})

const OrganizationOnboardingPage = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [industryOptions, setIndustryOptions] = useState<Option[]>([])
  const [countryOptions, setCountryOptions] = useState<Option[]>([])
  const { createOrganization } = useWorkspace(false)
  const { user, completeUserOnboarding } = useAuth()
  const { push } = useHistory()
  const { from, onboardingAdditionalOrganization } = useUrlParams('from', 'onboardingAdditionalOrganization')

  const { handleSubmit, register, control, errors, setError } = useForm<FormData>({
    validationSchema,
  })

  const onSubmit = handleSubmit(
    async ({ organizationName, organizationIndustries, organizationCountry, organizationEmail }) => {
      setSubmitting(true)

      const { ok } = await createOrganization({
        name: organizationName,
        industries: JSON.stringify(organizationIndustries),
        country: organizationCountry,
        billingEmailAddress: organizationEmail,
      })

      if (!ok) {
        setError('organizationName', 'required', 'This name is taken')
        setSubmitting(false)
      } else if (user && !onboardingAdditionalOrganization) {
        const ok = await completeUserOnboarding(user)
        if (ok) {
          push('/plan/select?from=/')
        } else {
          setSubmitting(false)
        }
      } else {
        push('/plan/select?from=/')
      }
    }
  )

  useEffect(() => {
    const loadIndustryOptions = async () => setIndustryOptions(await configService.getFieldSetOptions('industry'))
    const loadCountryOptions = async () => setCountryOptions(await configService.getFieldSetOptions('country'))
    loadIndustryOptions()
    loadCountryOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRedirectUrl = () => {
    return '/'
  }

  return !onboardingAdditionalOrganization && (!user?.onboardedUser || user?.onboarded) ? (
    <Redirect to={getRedirectUrl()} />
  ) : (
    <AuthPage>
      <div className="organization-onboarding-page">
        <form onSubmit={onSubmit} noValidate>
          <div className="back-button">
            <BackLink to={from ? from : '/'}>Back</BackLink>
          </div>
          <h1>About your Workspace</h1>
          <div className="subtitle">
            To complete your account setup, please confirm some final details so we can tailor your experience to you.
          </div>
          <div className="name-row">
            <FormInput
              errors={errors}
              name="organizationName"
              placeholder="e.g. Acme Co."
              label="Name your workspace*"
              inputRef={register}
            />
          </div>
          <Controller
            name="organizationIndustries"
            errors={errors}
            control={control}
            as={
              <FormMultiSelect
                label="Industries the workspace operates in?*"
                placeholder="Select industries"
                options={industryOptions}
              />
            }
          />
          <Controller
            name="organizationCountry"
            errors={errors}
            control={control}
            as={
              <FormSelect
                label="Country where the workspace is based?*"
                placeholder="Select country"
                options={countryOptions}
              />
            }
          />
          <div className="email-row">
            <FormInput
              name="organizationEmail"
              type="email"
              label="Email address for billing purposes*"
              placeholder="e.g. accounts@example.com"
              spellCheck={false}
              errors={errors}
              inputRef={register}
            />
          </div>

          <div className="buttons">
            <LoadingButton className="finish-button primary" isLoading={isSubmitting}>
              {'Finish'}
            </LoadingButton>
          </div>
        </form>
      </div>
    </AuthPage>
  )
}

export default OrganizationOnboardingPage
