import React from 'react'
import './ClearAllFiltersButton.scss'

type Props = {
  onSelect: () => void
}

const ClearAllFiltersButton: React.FC<Props> = ({ onSelect }) => {
  return (
    <div className="clear-all-filter-button" onClick={() => onSelect()}>
      Clear All
    </div>
  )
}

export default ClearAllFiltersButton
