import React from 'react'
import './ProgressBar.scss'

export type Props = {
  progress: number
}

const ProgressBar: React.FC<Props> = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div className="progress-label">{progress}%</div>
      <div className="progress-track">
        <div className="progress-thumb" style={{ width: progress + '%' }} />
      </div>
    </div>
  )
}

export default ProgressBar
