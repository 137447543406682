import React from 'react'

const ShareIcon = () => (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
        <g id="Share-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect id="Rectangle" fill="#01A3F8" x="0" y="0" width="40" height="40" rx="4"></rect>
            <g id="share-svgrepo-com" transform="translate(10, 9)" fill="#FFFFFF" fill-rule="nonzero">
                <path d="M15.1276688,13.0757036 C13.9306711,13.0757036 12.8590741,13.6343111 12.148049,14.5099311 L7.52286236,11.8235504 C7.66669361,11.4094932 7.74574659,10.9642893 7.74574659,10.5003235 C7.74574659,10.0364501 7.66669361,9.59124618 7.52286236,9.17718901 L12.1476876,6.4901613 C12.8586224,7.36605858 13.9304001,7.92485091 15.1274881,7.92485091 C17.2627317,7.92485091 19,6.14708536 19,3.9618247 C19,1.77721101 17.2629124,0 15.1276688,0 C12.9921541,0 11.2547955,1.77721101 11.2547955,3.96173228 C11.2547955,4.42569813 11.3337581,4.87099443 11.4776797,5.28514403 L6.85258343,7.97226416 C6.14164868,7.09682899 5.07014199,6.53840636 3.87341537,6.53840636 C1.73762969,6.53840636 0,8.31561737 0,10.5002311 C0,12.6847523 1.73762969,14.4619633 3.87341537,14.4619633 C5.07014199,14.4619633 6.14155833,13.9035407 6.85249308,13.028198 L11.4776797,15.7146711 C11.3337581,16.1288207 11.2547051,16.5742094 11.2547051,17.0382677 C11.2547051,19.222789 12.9920638,21 15.1275784,21 C17.262822,21 19,19.2226966 19,17.0382677 C19,14.8533768 17.2629124,13.0757036 15.1276688,13.0757036 Z M15.1276688,1.38635213 C16.5156584,1.38635213 17.6448964,2.54164558 17.6448964,3.96173228 C17.6448964,5.38255837 16.5156584,6.53840636 15.1276688,6.53840636 C13.7394081,6.53840636 12.6099894,5.38255837 12.6099894,3.96173228 C12.6099894,2.54164558 13.7394081,1.38635213 15.1276688,1.38635213 Z M3.87350572,13.0757036 C2.48497399,13.0757036 1.35528431,11.9203178 1.35528431,10.5003235 C1.35528431,9.08014436 2.48497399,7.92485091 3.87350572,7.92485091 C5.26149537,7.92485091 6.39064298,9.08014436 6.39064298,10.5003235 C6.39064298,11.9203178 5.26140503,13.0757036 3.87350572,13.0757036 Z M15.1276688,19.6137403 C13.7394081,19.6137403 12.6099894,18.4583544 12.6099894,17.0383601 C12.6099894,15.6178113 13.7394081,14.4620558 15.1276688,14.4620558 C16.5156584,14.4620558 17.6448964,15.6178113 17.6448964,17.0383601 C17.6448964,18.4583544 16.5156584,19.6137403 15.1276688,19.6137403 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
)

export default ShareIcon
