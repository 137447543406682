import { bool } from 'aws-sdk/clients/signer'
import { MaxPreviewsByCategoryForFree, PreviewCategory } from 'helpers/enum'
import { isOnPaymentPlanOrAdmin } from 'helpers/subscription'
import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from 'state/useAuth'
import './PreviewsAvailablityByCategory.scss'

type Props = {
  category: string
  isCategoryAtMaxForFree: bool
}

const PreviewsAvailablityByCategory: React.FC<Props> = ({ category, isCategoryAtMaxForFree }) => {
  const maxNumberOfPreviews =
    category === PreviewCategory.Business
      ? MaxPreviewsByCategoryForFree.Business
      : category === PreviewCategory.Individual
      ? MaxPreviewsByCategoryForFree.Individual
      : 0
  const puralise = category === PreviewCategory.Business ? '' : category === PreviewCategory.Individual ? 's' : ''
  const { user, subscription } = useAuth()
  const captalizedCategory = category.charAt(0).toUpperCase() + category.slice(1)
  return (
    <div className="previews-available-by-category">
      {!isOnPaymentPlanOrAdmin(subscription, user!) && (
        <div className="warning-text">
          <div>
            {isCategoryAtMaxForFree ? (
              <span>
                You've <b>maxed out</b> your Published {captalizedCategory} Preview allocation.
                <br></br>
                <br></br>
                Upgrade your account to get unlimited Previews or unpublish an existing {captalizedCategory} Preview to
                create space.
              </span>
            ) : (
              <span>
                Your <b>Free Plan</b> lets you have up to{' '}
                <b>
                  {maxNumberOfPreviews} {captalizedCategory}
                </b>{' '}
                Preview{puralise} 'PUBLISHED' at any one time before you need to upgrade.
              </span>
            )}
            <br></br>
            <br></br>
            <Link
              className="view-plan-options button primary"
              to={`/plan/select?from=${encodeURIComponent(window.location.pathname + window.location.search)}`}
            >
              <span>View Upgrade Options</span>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default PreviewsAvailablityByCategory
