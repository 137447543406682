import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import './IntegrationsPage.scss'
import useWorkspace from 'state/useWorkspace'
import IntegrationsTable from './IntegrationsTable/IntegrationsTable'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { SubscriptionPlan, WorkspaceType } from 'helpers/enum'
import { useIntegrations } from 'state/useIntegrations'
import useSubscription from 'state/useSubscription'
import { getCurrentPlan } from 'helpers/subscription'
import AnalyticsCta from 'components/analytics/AnalyticsCta/AnalyticsCta'
import CreateIntegrationDialog from './CreateIntegrationDialog/CreateIntegrationDialog'
import Filter from 'components/shared/Filter/Filter'
import unpublished_preview_illustration from 'assets/unpublished-preview-illustration.png'
import HeaderDropdown from './HeaderDropdown/HeaderDropdown'
import SearchBox from './SearchBox/SearchBox'
import { ViewingSelection } from 'state/types'
import UnauthorizedPage from '../../auth/UnauthorizedPage/UnauthorizedPage'
import AuthorizedPage from '../../auth/AuthorizedPage/AuthorizedPage'

const IntegrationsPage: React.FC = () => {
  const { currentWorkspace, organizationsLoaded } = useWorkspace(true)
  const { subscription, isPaymentDetailsLoading, editedSubscriptionName } = useSubscription(organizationsLoaded)

  const {
    integrations,
    viewingSelection,
    integrationsToShow,
    modalLoading,
    sortingOption,
    activeIntegrationCount,
    archivedIntegrationCount,
    isListLoading,
    searchKey,
    updateEnabled,
    deleteIntegration,
    onFiltersChange,
    createIntegration,
    setViewingSelection,
    updateArchived,
    setSearchKey,
  } = useIntegrations(currentWorkspace?.id || null)

  const isWorkspaceTypeUser = currentWorkspace?.type === WorkspaceType.User
  const [openInviteMemberDialog, setOpenInviteMemberDialog] = useState<boolean>(false)

  const applyIntegration = useMemo(() => {
    return (
      (getCurrentPlan(subscription) === SubscriptionPlan.Enterprise ||
        getCurrentPlan(subscription) === SubscriptionPlan.Professional) ||
        getCurrentPlan(subscription) === SubscriptionPlan.Business &&
      !isWorkspaceTypeUser
    )
  }, [subscription, isWorkspaceTypeUser, organizationsLoaded, isPaymentDetailsLoading])

  const renderNoDataView = useCallback(() => {
    const isViewingArchived = viewingSelection === 'archived'
    return (
      <div className={'no-integration-container' + (isViewingArchived ? ' archived' : '')}>
        <img src={unpublished_preview_illustration}></img>
        <div className="no-integration-message">
          Looks like you haven’t added any integrations yet. Ready to get started?
        </div>
        <button
          className="primary add-integration-button"
          onClick={() => {
            setOpenInviteMemberDialog(true)
          }}
        >
          + Add Integration
        </button>
      </div>
    )
  }, [viewingSelection])

  return (
    <AuthorizedPage UnauthorizedComponent={UnauthorizedPage}>
      <PageFrame
        className="integration-page"
        header={
          <HeaderDropdown
            selection={viewingSelection}
            activeCount={activeIntegrationCount}
            archivedCount={archivedIntegrationCount}
            onSelectionChange={setViewingSelection}
          />
        }
      >
        <CreateIntegrationDialog
          openCreateIntegrationDialog={openInviteMemberDialog}
          onOpenCreateIntegrationDialog={setOpenInviteMemberDialog}
          createIntegration={createIntegration}
          modalLoading={modalLoading}
        />
        {subscription && organizationsLoaded && isPaymentDetailsLoading ? (
          !applyIntegration ? (
            //not professional or enterprise plan
            <AnalyticsCta
              title={`You're currently subscribed to the ${editedSubscriptionName()} Plan`}
              body="'Integrations' is a feature of the Professional Plan, Business Plan or Enterprise Plan. You can upgrade to this plan anytime."
            />
          ) : (
            <>
              <div className="integrations-actions">
                <div className="left-container">
                  <Filter
                    filterName={'Sort by'}
                    filterOptions={['None', 'A-Z', 'Z-A']}
                    selectedFilterOption={sortingOption || 'None'}
                    onSelect={onFiltersChange}
                  />
                  <SearchBox searchKey={searchKey} onChange={onFiltersChange} />
                </div>
                <button
                  className="primary add-integrations-button"
                  onClick={() => {
                    setOpenInviteMemberDialog(true)
                  }}
                >
                  + Add Integration
                </button>
              </div>
              {!isListLoading ? (
                integrations.length > 0 ? (
                  <IntegrationsTable
                    integrations={integrationsToShow}
                    deleteIntegration={deleteIntegration}
                    updateEnabledIntegration={updateEnabled}
                    updateArchivedIntegration={updateArchived}
                    modalLoading={modalLoading}
                    viewingSelection={viewingSelection}
                  />
                ) : (
                  renderNoDataView()
                )
              ) : (
                <div className="table-loading">
                  <LoadingSpinner expand />
                </div>
              )}
            </>
          )
        ) : (
          <LoadingSpinner />
        )}
      </PageFrame>
    </AuthorizedPage>
  )
}

export default IntegrationsPage
