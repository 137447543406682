import React, { useState } from 'react'
import { PreviewAlgolia, TagOption } from 'state/types'
import MultiSelect from './MultiSelect'
import './TagFieldEditor.scss'
import { isMobile } from 'helpers/browser'
import usePreview from 'state/usePreview'
type Props = {
  preview: PreviewAlgolia
  tagOptions: TagOption[]
  selectedOptions: string[]
  setSelectedOptions: any
}

const TagFieldEditorAlgolia: React.FC<Props> = ({ tagOptions, preview, selectedOptions, setSelectedOptions }) => {
  const handleChange = async (newSelectedOptions: string[]) => {
    setSelectedOptions(newSelectedOptions)
  }

  return (
    <form className="tag-block-editor">
      <div className="form-fields">
        <div className="form-input-container">
          <MultiSelect
            selectedValues={selectedOptions}
            options={tagOptions}
            searchable={!isMobile && tagOptions && tagOptions.length > 10}
            onChange={handleChange}
          />
        </div>
      </div>
    </form>
  )
}

export default TagFieldEditorAlgolia
