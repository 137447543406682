import React from 'react'
import './FormTextArea.scss'
import Instructions from '../Instructions/Instructions'

type Props = {
  name: string
  label?: string
  instructions?: string | undefined
  textAreaRef: any
  error?: string
  noLimit?: boolean
  isResizable?: boolean
  largeTextArea?: boolean
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const textAreaSizeLimit = 750

const FormTextArea: React.FC<Props> = ({ name, label, instructions, textAreaRef, error, noLimit, isResizable, largeTextArea, ...rest }) => {
  const inputId = 'input-' + name
  return (
    <div className="form-input-container">
      <div className="form-input-label-row">
        <div>
          {label && (
            <label className="form-input-label" htmlFor={inputId}>
              {label}
            </label>
          )}
          {instructions && <Instructions instructions={instructions} />}
        </div>
        {error && <div className="form-input-validation-error">{error}</div>}
      </div>
      <textarea
        id={inputId}
        name={name}
        className={'form-text-area' + (isResizable ? ' resizable': '') + (error ? ' has-error' : '') + (largeTextArea ? ' large-text-area': '') }
        ref={textAreaRef}
        maxLength={!noLimit ? textAreaSizeLimit : undefined}
        {...rest}
      />
      {!noLimit && <div className="form-text-area-message">Limited to {textAreaSizeLimit} characters</div>}
    </div>
  )
}

export default FormTextArea
