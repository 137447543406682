import { useState, useEffect, useCallback, useMemo } from 'react'
import useStore from 'state/useStore'
import { BrandTheme, OrganizationBrandSetting } from './types'
import * as organizationBrandSettingService from 'services/organizationBrandSettingService'
import { getCurrentPlan } from 'helpers/subscription'
import { SubscriptionPlan, WorkspaceType } from 'helpers/enum'
import useWorkspace from './useWorkspace'
import useSubscription from './useSubscription'
import * as imageService from 'services/imageService'
import { useHistory } from 'react-router-dom'

const useBranding = () => {
  //hooks for brand settings
  const { state, dispatch } = useStore();
  const { currentWorkspace, organizationsLoaded } = useWorkspace(true)
  const { subscription, isPaymentDetailsLoading } = useSubscription(organizationsLoaded)
  const isWorkspaceTypeUser = currentWorkspace?.type === WorkspaceType.User
  const [isDataLoaded, setDataLoaded] = useState(false)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [isUpdatingBrandUrl, setIsUpdatingBrandUrl] = useState(false)
  const [isUpdatingPageTitle, setIsUpdatingPageTitle] = useState(false)
  const [organizationBrandSetting, setOrganizationBrandSetting] = useState<OrganizationBrandSetting | undefined>()
  const [brandUrl, setBrandUrl] = useState<string | undefined>(undefined)
  const [previewReferenceDisabled, setPreviewReferenceDisabled] = useState<boolean | undefined>(false)
  const [showUpdateURLPopup, setShowUpdateURLPopup] = useState<boolean>(false)
  const [brandMode, setBrandMode] = useState<any | undefined>()
  const [brandModeDataGet, setBrandModeDataGet] = useState<any | undefined>()
  const previewDisableData = state.previewDisableData
  const cloudinaryStorageIdData = state.cloudinaryStorageId
  const faviconCloudinaryStorageIdData = state.faviconCloudinaryStorageId
  const subscriptionData = state.subscription
  const planType = subscriptionData?.plan.name

  const setHidePreviewMeBranding = useCallback((data: boolean | undefined) => {
    dispatch({ type: 'SET_HIDE_PREVIEW_ME_BRANDING', value: data });
  }, [dispatch]);

  const setCloudinaryStorageIdData = useCallback((data: string | undefined) => {
    dispatch({ type: 'SET_CLOUDINARY_STORAGE_ID', value: data });
  }, [dispatch]);

  const setFaviconCloudinaryStorageIdData = useCallback((data: string | undefined) => {
    dispatch({ type: 'SET_FAVICON_CLOUDINARY_STORAGE_ID', value: data });
  }, [dispatch]);


  useEffect(() => {
    (async () => {
      try {
        if (currentWorkspace?.id && (planType === SubscriptionPlan.Professional || planType === SubscriptionPlan.Business || planType === SubscriptionPlan.Enterprise)) {
          await getOrganizationBrandSettingByOrganization();
        }

        if(planType === SubscriptionPlan.Basic || planType === SubscriptionPlan.Solo) {
          setHidePreviewMeBranding(false);
          setCloudinaryStorageIdData('');
          setFaviconCloudinaryStorageIdData('');
        }
      } catch (error) {
        console.log("Error fetching organization brand setting:", error);
      } finally {
        setDataLoaded(true)
      }
    })();

  }, []);


  const getOrganizationBrandSettingByOrganization = async () => {
    if (currentWorkspace?.id) {
      const { data, ok } = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(
        currentWorkspace.id
      )
      setOrganizationBrandSetting(data)
      setBrandUrl(data?.brandUrl)
      setPreviewReferenceDisabled(data?.previewReferenceDisabled)
      setHidePreviewMeBranding(data?.previewReferenceDisabled)
      setCloudinaryStorageIdData(data?.cloudinaryStorageId)
      setFaviconCloudinaryStorageIdData(data?.faviconCloudinaryStorageId)
      setBrandMode(data?.brandMode)
      setBrandModeDataGet(data?.brandMode)
      return ok
    }
  }

  const updateFaviconCloudinaryStorageIdByOrganization = async (faviconCloudinaryStorageId: string) => {
    if (currentWorkspace?.id) {
      setIsUploadingImage(true)
      const { ok } = await organizationBrandSettingService.updateFaviconCloudinaryStorageIdByOrganization(
        currentWorkspace.id,
        faviconCloudinaryStorageId
      )
      setIsUploadingImage(false)
      return ok
    }
  }


  const updatePreviewReferenceDisabledByOrganization = async (enabled: boolean) => {
    if (currentWorkspace?.id) {
      const { data, ok } = await organizationBrandSettingService.updatePreviewReferenceDisabledByOrganization(
        currentWorkspace.id,
        enabled
      )
      setHidePreviewMeBranding(enabled)
      return ok
    }
  }

  const updateCloudinaryStorageIdByOrganization = async (cloudinaryStorageId: string) => {
    if (currentWorkspace?.id) {
      setIsUploadingImage(true)
      const { ok } = await organizationBrandSettingService.updateCloudinaryStorageIdByOrganization(
        currentWorkspace.id,
        cloudinaryStorageId
      )
      setIsUploadingImage(false)

      return ok
    }
  }

  const updateOrganizationBrandUrlByOrganization = async (brandUrl: string) => {
    if (currentWorkspace?.id) {
      setIsUpdatingBrandUrl(true)
      const { ok } = await organizationBrandSettingService.updateOrganizationBrandUrlByOrganization(
        currentWorkspace.id,
        brandUrl
      )
      if (organizationBrandSetting) setOrganizationBrandSetting({ ...organizationBrandSetting, brandUrl: brandUrl })
      setShowUpdateURLPopup(true)
      setIsUpdatingBrandUrl(false)

      return ok
    }
  }

  //New placeholder function for page title - subject for refactoring
  const updateOrganizationPageTitleByOrganization = (e:any) => {
    if(currentWorkspace?.id){
      setIsUpdatingPageTitle(true)
      setShowUpdateURLPopup(true)
      setIsUpdatingPageTitle(false)
    }
  }

  const updateOrganizationBrandLogo = async (blob: Blob, onProgressChange: (progress: number) => void) => {
      const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
      if (status === 'success' && !!storageId) {
        await updateOrganizationLogoImageStorageId(storageId)
        setCloudinaryStorageIdData(storageId)
      }

  }

  const updateOrganizationLogoImageStorageId = async (profileImageStorageId: string) => {
    if (currentWorkspace?.id ) {
      await organizationBrandSettingService.updateCloudinaryStorageIdByOrganization(
        currentWorkspace.id,
        profileImageStorageId
      )
    }
  }

  const updateOrganizationBrandLogoFavicon = async (blob: Blob, onProgressChange: (progress: number) => void) => {
      const { status, storageId } = await imageService.uploadImage(blob, onProgressChange)
      if (status === 'success' && !!storageId) {
        await updateOrganizationLogoImageStorageIdFavicon(storageId)
        setFaviconCloudinaryStorageIdData(storageId)

    }
  }

  const updateOrganizationLogoImageStorageIdFavicon = async (profileImageStorageId: string) => {
    if (currentWorkspace?.id ) {
      await organizationBrandSettingService.updateFaviconCloudinaryStorageIdByOrganization(
        currentWorkspace.id,
        profileImageStorageId
      )
    }
  }

  const removeOrganizationLogoImage = () => {
    if (currentWorkspace?.id ) {
      updateOrganizationLogoImageStorageId('')
      setCloudinaryStorageIdData(undefined)
    }
  }

  const removeOrganizationLogoFaviconImage = async () => {
    if (currentWorkspace?.id ) {
      updateOrganizationLogoImageStorageIdFavicon('')
      setFaviconCloudinaryStorageIdData(undefined)
    }
  }


  return {
    previewDisableData,
    brandUrl,
    isDataLoaded,
    organizationBrandSetting,
    isUploadingImage,
    isUpdatingBrandUrl,
    previewReferenceDisabled,
    cloudinaryStorageIdData,
    setCloudinaryStorageIdData,
    faviconCloudinaryStorageIdData,
    setFaviconCloudinaryStorageIdData,
    showUpdateURLPopup,
    brandMode,
    brandModeDataGet,
    isUpdatingPageTitle,
    setIsUpdatingPageTitle,
    setBrandUrl,
    getOrganizationBrandSettingByOrganization,
    updatePreviewReferenceDisabledByOrganization,
    updateCloudinaryStorageIdByOrganization,
    updateFaviconCloudinaryStorageIdByOrganization,
    updateOrganizationBrandUrlByOrganization,
    updateOrganizationPageTitleByOrganization,
    setPreviewReferenceDisabled,
    updateOrganizationBrandLogo,
    updateOrganizationBrandLogoFavicon,
    removeOrganizationLogoImage,
    removeOrganizationLogoFaviconImage,
    setShowUpdateURLPopup,
  }
}

export default useBranding
