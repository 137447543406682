import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils, NavbarElementProps } from 'react-day-picker'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import { isIos, isAndroid } from 'helpers/browser'
import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import 'react-day-picker/lib/style.css'
import './DatePicker.scss'

const htmlDateFormat = 'yyyy-MM-dd' // Not the format we use, the format the native HTML widget wants.

type Props = {
  id?: string
  placeholder?: string
  value?: Date
  onChange?: (value?: Date) => void
  fromMonth?: Date
}

const CustomNavBar: React.FC<NavbarElementProps> = ({
  onPreviousClick,
  onNextClick,
  showPreviousButton,
  showNextButton,
}) => (
  <div className="date-picker-custom-nav-bar">
    <button
      type="button"
      className="plain"
      onClick={() => onPreviousClick()}
      disabled={!showPreviousButton}
      tabIndex={-1}
    >
      <ChevronLeftIcon />
    </button>
    <button type="button" className="plain" onClick={() => onNextClick()} disabled={!showNextButton} tabIndex={-1}>
      <ChevronRightIcon />
    </button>
  </div>
)

const DatePicker: React.FC<Props> = ({ placeholder, value, onChange, fromMonth }) => {
  const onDateChange = (date?: Date) => {
    if (!date) {
      onChange?.()
    } else if (fromMonth) {
      onChange?.(date >= fromMonth ? date : fromMonth)
    } else {
      onChange?.(date)
    }
  }

  return isIos || isAndroid ? (
    <input
      type="date"
      className="native-mobile-date-picker"
      placeholder={placeholder}
      value={value ? dateFnsFormat(value, htmlDateFormat) : ''}
      onChange={e => {
        onDateChange(e.target.value ? dateFnsParse(e.target.value, htmlDateFormat, new Date()) : undefined)
      }}
      min={dateFnsFormat(new Date(), htmlDateFormat)}
      onInput={e => {
        const target = e.nativeEvent.target as any
        if (isIos && target.value === target.defaultValue) {
          // Fix for https://github.com/facebook/react/issues/8938.
          onDateChange()
          setTimeout(() => {
            target.defaultValue = ''
            target.value = ''
          }, 0)
        }
      }}
    />
  ) : (
    <DayPickerInput
      inputProps={{ readOnly: true }}
      placeholder={placeholder || ''}
      format="dd/MM/yyyy"
      formatDate={(date, format) => dateFnsFormat(date, format)}
      parseDate={(stringValue, format) => {
        const parsed = dateFnsParse(stringValue, format, new Date())

        // Check year string is 4 characters or greater so if the user is typing 2 for the year and
        // meaning to type 2020, it doesn't immediately convert it to year 0002.
        const yearString = stringValue?.split('/')?.[2]
        const entryComplete = yearString && yearString.length >= 4

        if (DateUtils.isDate(parsed) && entryComplete) {
          return parsed
        }
        return undefined
      }}
      clickUnselectsDay
      value={value}
      onDayChange={onDateChange}
      dayPickerProps={{
        navbarElement: CustomNavBar,
        initialMonth: value,
        disabledDays: fromMonth ? { before: fromMonth } : undefined,
      }}
    />
  )
}

export default DatePicker
