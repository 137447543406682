import React from 'react'
import { Link } from 'react-router-dom'
import { getFullName } from 'helpers/names'
import { PreviewSummary } from 'state/types'
import './NextPreviewLinksMobile.scss'

type Props = {
  nextLink?: string
  nextPreview?: PreviewSummary
  previousLink?: string
  previousPreview?: PreviewSummary
}

const NextPreviewLinksMobile: React.FC<Props> = ({ nextLink, nextPreview, previousLink, previousPreview }) => {
  const nextName = getFullName(nextPreview?.givenName, nextPreview?.familyName)
  const previousName = getFullName(previousPreview?.givenName, previousPreview?.familyName)
  return (
    <div className="next-preview-links-mobile">
      {previousName && previousLink && (
        <Link className="previous-preview-link-mobile" to={previousLink}>
          <div className="label">Prev</div>
          <div className="name">{previousName}</div>
        </Link>
      )}
      {nextName && nextLink && (
        <Link className="next-preview-link-mobile" to={nextLink}>
          <div className="label">Next</div>
          <div className="name">{nextName}</div>
        </Link>
      )}
    </div>
  )
}

export default NextPreviewLinksMobile
