import React, { useState, useEffect, useRef } from 'react'
import Dialog from 'components/shared/Dialog/Dialog'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import './ConfirmationDialog.scss'

type Props = {
  className?: string
  isShowing: boolean
  isWarning?: boolean
  okButtonText?: string
  cancelButtonText?: string
  isOkButtonLinkStyle?: boolean
  title?: string
  onClose: () => void
  onOk?: () => void | Promise<void>
  onCancel?: () => void
}

const ConfirmationDialog: React.FC<Props> = ({
  className,
  isShowing,
  isWarning,
  okButtonText,
  cancelButtonText,
  isOkButtonLinkStyle,
  title,
  onClose,
  onOk,
  onCancel,
  children,
}) => {
  const [isLoading, setLoading] = useState(false)
  const isMountedRef = useRef(true)

  const handleCancel = () => {
    onClose()
    if (onCancel) {
      onCancel()
    }
  }

  // If onOk returns a promise, the dialog will wait for it, otherwise it will close immediately.
  const handleOk = async () => {
    if (onOk) {
      setLoading(true)
      await Promise.resolve(onOk())
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
    if (isMountedRef.current) {
      onClose()
    }
  }

  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    []
  )

  return (
    <Dialog
      className={'confirmation-dialog' + (className ? ' ' + className : '') + (isWarning ? ' warning' : '')}
      isShowing={isShowing}
      onClose={onClose}
      closeOnEscapePress
      disableExitOnBackgroundClick={isLoading}
    >
      {title && <h2>{title}</h2>}
      <div className="dialog-content">{children}</div>
      <div className="buttons">
        {cancelButtonText && (
          <button type="button" className="cancel-button link" onClick={handleCancel}>
            {cancelButtonText}
          </button>
        )}
        <LoadingButton
          isLoading={isLoading}
          type="button"
          className={'ok-button' + (isOkButtonLinkStyle ? ' link' : ' primary')}
          autoFocus={!isWarning}
          onClick={handleOk}
        >
          {okButtonText || 'Ok'}
        </LoadingButton>
      </div>
    </Dialog>
  )
}

export default ConfirmationDialog
