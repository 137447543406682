import { createMuiTheme } from '@material-ui/core'
import { AutocompleteClassKey } from '@material-ui/lab'

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAutocomplete: AutocompleteClassKey
  }
}

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#01a3f8',
    },
  },
  typography: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: 14,
  },
  overrides: {
    MuiInputLabel: {
      shrink: {
        transform: 'none',
      },
    },

    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: '2px 5px',
        },
      },
      popupIndicator: {
        height: '26px',
        color: '#000000',
      },
      clearIndicator: {
        height: '26px',

        color: '#000000',
      },
    },
  },
})
