import React from 'react'
import './FormCheckbox.scss'

type Props = {
  name?: string
  label?: string
  value?: boolean
  inputRef?: any
}

const FormCheckbox: React.FC<Props> = ({ name, value, label, inputRef }) => {
  const inputId = 'checkbox-' + name
  return (
    <div className="form-checkbox bordered">
      <input type="checkbox" id={inputId} name={name} checked={value} ref={inputRef} />
      <label htmlFor={inputId}>{label}</label>
    </div>
  )
}

export default FormCheckbox
