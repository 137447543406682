import { Subscription, User } from 'state/types'
import { SubscriptionPlan } from './enum'
import { getWorkspaceOrganizationId } from './organization'

export const getCurrentPlan = (subscription: Subscription | undefined) => {
  if (subscription) {
    return subscription.plan.name
  } else {
    return SubscriptionPlan.Basic
  }
}

export const getCurrentPlanDescription = (subscription: Subscription | undefined) => {
  if (subscription) {
    return subscription.plan.description
  }
}

export const canUpgrade = (subscription: Subscription | undefined) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  if (
    workspaceOrganizationId !== undefined &&
    subscription &&
    (subscription.plan.name === SubscriptionPlan.Professional.toString() || subscription.plan.name === SubscriptionPlan.Business.toString())
  ) {
    return false
  } else if (
    workspaceOrganizationId === undefined &&
    subscription &&
    subscription.plan.name === SubscriptionPlan.Plus.toString()
  ) {
    return false
  } else {
    return true
  }
}

export const isOnPaymentPlanOrAdmin = (subscription: Subscription | undefined, user: User) => {
  return getCurrentPlan(subscription) !== SubscriptionPlan.Basic.toString() || user.admin
}

export const isOnProfessionalOrEnterprisePlanOrAdmin = (subscription: Subscription | undefined, user: User) => {
  const currentPlan = getCurrentPlan(subscription)
  return (
    currentPlan === SubscriptionPlan.Professional.toString() ||
    currentPlan === SubscriptionPlan.Enterprise.toString() ||
    currentPlan === SubscriptionPlan.Business.toString() ||
    user.admin
  )
}

export const isOnProfessionalPlanOrAdminOnLoad = (
  subscription: Subscription | undefined,
  user: User,
  isSubscriptionLoading: boolean
) => {
  if (!isSubscriptionLoading) {
    return isOnProfessionalOrEnterprisePlanOrAdmin(subscription, user)
  } else {
    return false
  }
}
