import React, { useRef, useEffect, useState, useMemo } from 'react';
import './VideoTrimSlider.scss'
import MegaphoneIcon from '../../../assets/ico_megaphone.svg'
import useVideoSuite from '../../../state/useVideoSuite'
import { SubscriptionPlan } from '../../../helpers/enum'
import { updateVideoSuite } from '../../../services/videoSuiteService'
import useStore from '../../../state/useStore'

const minSeparationInSeconds = 1.5

interface CTA {
  id: number;
  duration: string;
  fadeIn: string;
  fadeOut: string;
  newStart: string | undefined;
  newEnd: string | undefined;
}

type Props = {
  currentTime: number
  startTime: number
  endTime: number
  duration: number
  onCurrentTimeChange: (currentTime: number) => void
  onCurrentMonitoringTimeChange: (currentTime: number) => void
  onStartTimeChange: (startTime: number) => void
  onEndTimeChange: (endTime: number) => void
  onStartEndTimeChange: (items: { start: number; end: number; }[]) => void;
  responseData: {
    watermark: boolean;
    cta: boolean;
    videoFx: boolean;
    images: boolean;
    captions: boolean;
  };
  plan: string
  videoSuiteId: string
}

const VideoTrimSlider: React.FC<Props> = ({
                                            responseData,
                                            currentTime,
                                            startTime,
                                            endTime,
                                            duration,
                                            onCurrentTimeChange,
                                            onCurrentMonitoringTimeChange,
                                            onStartTimeChange,
                                            onEndTimeChange,
                                            onStartEndTimeChange,
                                            plan,
                                            videoSuiteId
                                          }) => {
  const currentPercent = useMemo(() => duration && (currentTime / duration) * 100, [duration, currentTime]);
  const startPercent = useMemo(() => duration && (startTime / duration) * 100, [duration, startTime]);
  const endPercent = useMemo(() => duration && (1 - endTime / duration) * 100, [duration, endTime]);
  const { state, dispatch } = useStore()
  const rootRef = useRef<HTMLDivElement>(null)
  const currentTimeElementRef = useRef<HTMLDivElement>(null)
  const leftThumbRef = useRef<HTMLDivElement>(null)
  const rightThumbRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const startTimeRef = useRef(startTime)
  const endTimeRef = useRef(endTime)
  const durationRef = useRef(duration)
  const isOnBasicPlan = plan === SubscriptionPlan.Basic
  const [middleThumbs, setMiddleThumbs] = useState<
    {
      id: number;
      fadeIn: number;
      fadeOut: number;
      duration: number;
      newStart: string | undefined;
      newEnd: string | undefined;
    }[]
  >([]);

  startTimeRef.current = startTime
  endTimeRef.current = endTime
  durationRef.current = duration
  const middleThumbClass = "middle-thumb thumb-transition";

  const timeToPercent = (fadeIn: number) => (fadeIn / duration) * 100;
  const percentToTime = (percent: number) => percent * duration / 100;
  const {setVideoSuite, videoSuiteData, setInitialFadeIn, setInitialFadeOut, updateVideoSuite } = useVideoSuite(responseData?.cta)
  useEffect(() => {
    if (videoSuiteData && responseData.cta) {
      const initialThumbs = videoSuiteData.map((cta: CTA) => {
        const [minutesStr, secondsStr] = cta.fadeOut.split(':');
        const minutes = parseInt(minutesStr, 10);
        const secondsParts = secondsStr.split('.');
        const seconds = parseInt(secondsParts[0], 10);
        const fractions = parseInt(secondsParts[1], 10) / 10; // Convert fractions to seconds

        const totalDuration = minutes * 60 + seconds + fractions;
        return {
          id: cta.id,
          fadeIn: parseFloat(cta.fadeIn.split(':')[1]),
          fadeOut: totalDuration,
          duration: duration,
          newStart: cta.newStart,
          newEnd: cta.newEnd,
        };
      });
      const currentDuration = durationRef.current;

      setMiddleThumbs(initialThumbs);
      setInitialFadeIn(startTimeRef.current);
      setInitialFadeOut(currentDuration - endTimeRef.current);
    }
  }, [videoSuiteData, responseData.cta]);

  const setNewStartTime = (data: string) => {
    dispatch({
      type: 'SET_VIDEO_NEW_START',
      value: data
    });
  }

  const setNewEndTime = (data: string) => {
    dispatch({
      type: 'SET_VIDEO_NEW_END',
      value: data
    });
  }

  useEffect(() => {
    const startTime = formatTime((startPercent/100) * duration)
    const endTime =  formatTime((endPercent/100) * duration)
    setNewStartTime(startTime)
    setNewEndTime(endTime)
  }, [])

  const handleThumbDrag = (thumbId: number, clientX: number) => {
    if (!rootRef.current) {
      return;
    }

    const bounds = rootRef.current.getBoundingClientRect();
    let newTime = ((clientX - bounds.left) / bounds.width) * duration;

    const draggedThumb = middleThumbs.find((thumb) => thumb.id === thumbId);
    if (!draggedThumb) {
      return;
    }

    const maxFadeOutTime = draggedThumb.fadeOut - 1.0;
    newTime = Math.min(newTime, maxFadeOutTime);

    const maxRightThumbPosition = (startTimeRef.current / duration) * 100;
    newTime = Math.max(newTime, maxRightThumbPosition * (duration / 100));
    onCurrentMonitoringTimeChange(newTime);
    requestAnimationFrame(() => {
      setMiddleThumbs(prevThumbs =>
        prevThumbs.map(thumb =>
          thumb.id === thumbId ? { ...thumb, fadeIn: newTime,fadeOut:draggedThumb.fadeOut } : thumb
        )
      );

      const updatedCallsActionData = videoSuiteData.map((cta: CTA) =>
        cta.id === thumbId ? { ...cta, fadeIn: formatTime(newTime),fadeOut:formatTime(draggedThumb.fadeOut), duration: formatTime(maxFadeOutTime - newTime), videoDuration: formatTime(duration) } : cta
      );

      setVideoSuite(updatedCallsActionData);
    });
  };


  let initialClientX = 0

  const onThumbMouseDown = (thumbId: number, e: React.MouseEvent<HTMLDivElement>) => {
    initialClientX = e.clientX;
    const onMouseMove = (moveEvent: MouseEvent) => handleThumbDrag(thumbId, moveEvent.clientX);
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    e.preventDefault();
  };

  const onThumbTouchStart = (thumbId: number, e: React.TouchEvent<HTMLDivElement>) => {
    const onTouchMove = (moveEvent: TouchEvent) => handleThumbDrag(thumbId, moveEvent.touches[0].clientX);
    const onTouchEnd = () => {
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    };

    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);
    e.preventDefault();
  };

  const handleFadeOutThumbDrag = (thumbId: number, clientX: number) => {
    if (!rootRef.current) {
      return;
    }
    if (Math.abs(clientX - initialClientX) < 1) {
      return;
    }
    const bounds = rootRef.current.getBoundingClientRect();
    let newTime = ((clientX - bounds.left) / bounds.width) * duration;

    const thumb = middleThumbs.find((thumb) => thumb.id === thumbId);
    if (!thumb) {
      return;
    }

    const minFadeInTime = thumb.fadeIn + 1.0;
    newTime = Math.max(newTime, minFadeInTime);

    const maxRightThumbPosition = (1 - endTimeRef.current / duration) * 100;
    newTime = Math.min(newTime, maxRightThumbPosition * (duration / 100));
    onCurrentMonitoringTimeChange(newTime);
    requestAnimationFrame(() => {
      setMiddleThumbs(prevThumbs =>
        prevThumbs.map(thumb =>
          thumb.id === thumbId ? { ...thumb, fadeOut: newTime, fadeIn:thumb.fadeIn} : thumb
        )
      );

      const updatedCallsActionData = videoSuiteData.map((cta: CTA) =>
        cta.id === thumbId ? { ...cta, fadeOut: formatTime(newTime),fadeIn:formatTime(thumb.fadeIn) } : cta
      );

      setVideoSuite(updatedCallsActionData);
    });
  };



  const onFadeOutThumbMouseDown = (thumbId: number, e: React.MouseEvent<HTMLDivElement>) => {

    const onMouseMove = (moveEvent: MouseEvent) => handleFadeOutThumbDrag(thumbId, moveEvent.clientX);
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    e.preventDefault();
  };

  const onFadeOutThumbTouchStart = (thumbId: number, e: React.TouchEvent<HTMLDivElement>) => {

    const onTouchMove = (moveEvent: TouchEvent) => handleFadeOutThumbDrag(thumbId, moveEvent.touches[0].clientX);
    const onTouchEnd = () => {
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    };

    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);
    e.preventDefault();
  };

  useEffect(() => {
    if (responseData.cta) {
      setMiddleThumbs(prevThumbs => {
        const gap = 1 * duration;
        return prevThumbs.map(thumb => {
          const minTime = percentToTime(startTime / duration * 100);
          const maxTime = percentToTime((1 - endTime / duration) * 100);

          let constrainedFadeIn = Math.max(minTime, Math.min(maxTime, thumb.fadeIn));
          let constrainedFadeOut = Math.max(minTime, Math.min(maxTime, thumb.fadeOut));

          if (constrainedFadeIn === constrainedFadeOut) {
            constrainedFadeOut += gap;

            if (constrainedFadeOut > maxTime) {
              constrainedFadeOut = maxTime;
              constrainedFadeIn = constrainedFadeOut - gap;
            }
          }

          return {
            ...thumb,
            fadeIn: constrainedFadeIn,
            fadeOut: constrainedFadeOut,
            videoDuration: duration
          };
        });
      });
    }
  }, [startTime, endTime, duration, responseData.cta]);



  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toFixed(1).padStart(4, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    const root = rootRef.current
    const currentTimeElement = currentTimeElementRef.current
    const leftThumb = leftThumbRef.current
    const rightThumb = rightThumbRef.current
    const overlay = overlayRef.current
    if (root && currentTimeElement && leftThumb && rightThumb && overlay) {
      let leftThumbDragged = false
      let rightThumbDragged = false
      let currentTimeDragged = false
      let clientXOnDragStart = 0
      let thumbXOnDragStart = 0

      const updateDraggedThumbValue = (clientX: number) => {
        const deltaX = clientX - clientXOnDragStart;
        const newX = thumbXOnDragStart + deltaX;
        const currentDuration = durationRef.current;
        const bounds = root.getBoundingClientRect();
        const minSeparation = minSeparationInSeconds / currentDuration;

        let newFractionClamped = (newX - bounds.left) / bounds.width;

        if (leftThumbDragged) {
          const maxLeftThumbPosition = (1 - endTimeRef.current / currentDuration) - minSeparation;
          newFractionClamped = Math.max(newFractionClamped, 0);
          newFractionClamped = Math.min(newFractionClamped, maxLeftThumbPosition);

          const newPositionPercent = (newFractionClamped * 100).toFixed(2) + '%';
          leftThumb.style.left = newPositionPercent;
          currentTimeElement.style.left = newPositionPercent;
          overlay.style.left = newPositionPercent;

          const newStartTime = newFractionClamped * currentDuration;

          onStartTimeChange(newStartTime);
          onCurrentTimeChange(newStartTime);
          setNewStartTime(formatTime(newStartTime))

        } else {
          const minRightThumbPosition = (startTimeRef.current / currentDuration) + minSeparation;

          newFractionClamped = Math.max(newFractionClamped, minRightThumbPosition);
          newFractionClamped = Math.min(newFractionClamped, 1);

          const newPositionPercent = (newFractionClamped * 100).toFixed(2) + '%';
          rightThumb.style.left = newPositionPercent;
          currentTimeElement.style.left = newPositionPercent;
          overlay.style.right = (100 - newFractionClamped * 100).toFixed(2) + '%';

          onEndTimeChange((1 - newFractionClamped) * currentDuration);
          onStartTimeChange(startTimeRef.current)
          onCurrentTimeChange(newFractionClamped * currentDuration);
          onStartEndTimeChange([{
          start:startTimeRef.current,
          end:currentDuration - endTimeRef.current
          }])
          setNewEndTime(formatTime(newFractionClamped * currentDuration))
        }

        setInitialFadeIn(startTimeRef.current)
        setInitialFadeOut(currentDuration - endTimeRef.current)
      };

      const updateCurrentTime = (clientX: number) => {
        const bounds = root.getBoundingClientRect()
        const min = startTimeRef.current / durationRef.current
        const max = 1 - endTimeRef.current / durationRef.current
        const newFractionClamped = Math.max(min, Math.min(max, (clientX - bounds.left) / bounds.width))
        currentTimeElement.style.left = newFractionClamped * 100 + '%'
        onCurrentTimeChange(newFractionClamped * duration)
      }

      const onLeftThumbMouseDown = (e: MouseEvent) => {
        if (e.button === 0) {
          leftThumbDragged = true
          clientXOnDragStart = e.clientX
          thumbXOnDragStart = leftThumb.getBoundingClientRect().left
          e.preventDefault()
        }
      }

      const onLeftThumbTouchStart = (e: TouchEvent) => {
        if (e.touches.length === 1) {
          leftThumbDragged = true
          clientXOnDragStart = e.touches[0].clientX
          thumbXOnDragStart = leftThumb.getBoundingClientRect().left
          e.preventDefault()
        }
      }

      const onRightThumbMouseDown = (e: MouseEvent) => {
        if (e.button === 0) {
          rightThumbDragged = true
          clientXOnDragStart = e.clientX
          thumbXOnDragStart = rightThumb.getBoundingClientRect().left
          e.preventDefault()
        }
      }

      const onRightThumbTouchStart = (e: TouchEvent) => {
        if (e.touches.length === 1) {
          rightThumbDragged = true
          clientXOnDragStart = e.touches[0].clientX
          thumbXOnDragStart = rightThumb.getBoundingClientRect().left
          e.preventDefault()
        }
      }

      const onCenterOverlayMouseDown = (e: MouseEvent) => {
        if (e.button === 0) {
          currentTimeDragged = true
          updateCurrentTime(e.clientX)
        }
      }

      const onCenterOverlayTouchStart = (e: TouchEvent) => {
        if (e.touches.length === 1) {
          currentTimeDragged = true
          updateCurrentTime(e.touches[0].clientX)
        }
      }

      const onMouseMove = (e: MouseEvent) => {
        if (leftThumbDragged || rightThumbDragged) {
          updateDraggedThumbValue(e.clientX)
        } else if (currentTimeDragged) {
          updateCurrentTime(e.clientX)
        }
      }

      const onTouchMove = (e: TouchEvent) => {
        if ((leftThumbDragged || rightThumbDragged) && e.touches.length === 1) {
          updateDraggedThumbValue(e.touches[0].clientX)
        } else if (currentTimeDragged && e.touches.length === 1) {
          updateCurrentTime(e.touches[0].clientX)
        }
      }

      const endDrag = () => {
        leftThumbDragged = false
        rightThumbDragged = false
        currentTimeDragged = false
      }

      leftThumb.addEventListener('mousedown', onLeftThumbMouseDown)
      leftThumb.addEventListener('touchstart', onLeftThumbTouchStart)
      rightThumb.addEventListener('mousedown', onRightThumbMouseDown)
      rightThumb.addEventListener('touchstart', onRightThumbTouchStart)
      overlay.addEventListener('mousedown', onCenterOverlayMouseDown)
      overlay.addEventListener('touchstart', onCenterOverlayTouchStart)
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('touchmove', onTouchMove)
      window.addEventListener('mouseup', endDrag)
      window.addEventListener('touchend', endDrag)

      return () => {
        leftThumb.removeEventListener('mousedown', onLeftThumbMouseDown)
        leftThumb.removeEventListener('touchstart', onLeftThumbTouchStart)
        rightThumb.removeEventListener('mousedown', onRightThumbMouseDown)
        rightThumb.removeEventListener('touchstart', onRightThumbTouchStart)
        overlay.removeEventListener('mousedown', onCenterOverlayMouseDown)
        overlay.removeEventListener('touchstart', onCenterOverlayTouchStart)
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('touchmove', onTouchMove)
        window.removeEventListener('mouseup', endDrag)
        window.removeEventListener('touchend', endDrag)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="video-trim-slider" ref={rootRef}>
      {duration > 0 && (
        <>
          <div className="current-time" style={{ left: currentPercent + '%' }} ref={currentTimeElementRef} />
          <div
            className="overlay"
            style={{ left: startPercent + '%', right: 100 - endPercent + '%' }}
            ref={overlayRef}
          >
            <div className="overlay-border"></div>
          </div>
          <div className="video-trim-border"></div>
          <div className="left-thumb left-drag-indicator" style={{ left: startPercent + '%' }} ref={leftThumbRef} role="button" />
          {!isOnBasicPlan && middleThumbs.map(thumb => (
            <div key={thumb.id} className="middle-thumb-container">
              <div
                key={`fadein-${thumb.id}`}
                className={`middle-thumb-wrapper fade-in ${middleThumbClass}`}
                style={{ left: `${timeToPercent(thumb.fadeIn)}%` }}
              >
                <div
                  className="middle-thumb"
                  role="button"
                  onMouseDown={(e) => onThumbMouseDown(thumb.id, e)}
                  onTouchStart={(e) => onThumbTouchStart(thumb.id, e)}
                >
                  <div className="icon-container">
                    <img src={MegaphoneIcon} alt={`CTA ${thumb.id}`} />
                  </div>
                </div>
                <div className="thumb-label">
                  {thumb.id}
                </div>
              </div>
              <div className="middle-thumb-overlay" style={{ left: `${timeToPercent(thumb.fadeIn)}%`, width: `${timeToPercent(thumb.fadeOut - thumb.fadeIn)}%` }}/>
              <div
                key={`fadeout-${thumb.id}`}
                className={`middle-thumb-wrapper fade-out ${middleThumbClass}`}
                style={{ left: `${timeToPercent(thumb.fadeOut)}%` }}
              >
                <div
                  className="middle-thumb fade-out"
                  role="button"
                  onMouseDown={(e) => onFadeOutThumbMouseDown(thumb.id, e)}
                  onTouchStart={(e) => onFadeOutThumbTouchStart(thumb.id, e)}
                >
                  <div className="icon-container">
                    <img src={MegaphoneIcon} alt={`CTA ${thumb.id}`} />
                  </div>
                </div>
                <div className="thumb-label">
                  {thumb.id}
                </div>
              </div>
            </div>
          ))}
          <div className="right-thumb right-drag-indicator" style={{ left: endPercent + '%' }} ref={rightThumbRef} role="button" />
        </>
      )}
    </div>

  )
}

export default VideoTrimSlider
