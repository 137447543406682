import React from 'react'
import { ContentBlock, ContentBlockField } from 'state/types'
import { getFullName } from 'helpers/names'
import { VIEW_LINK } from 'helpers/events'
import {
  fieldValueExists,
  validateGroup,
  validatePeople,
  validateTeamMember,
  validateWebsiteLink,
} from 'helpers/validation'
import { trackEvent } from 'services/eventTrackingService'
import { formatPreviewValue } from 'helpers/formatting'
import PublishedPreviewWebsiteLinks from 'components/preview/PublishedPreviewWebsiteLinks/PublishedPreviewWebsiteLinks'
import PublishedPreviewTeamMembers from 'components/preview/PublishedPreviewTeamMembers/PublishedPreviewTeamMembers'
import PublishedPreviewGroups from 'components/preview/PublishedPreviewGroups/PublishedPreviewGroups'
import './PublishedPreviewFormBlock.scss'
import PublishedPreviewInvitationLinks from '../PublishedPreviewInvitationLinks/PublishedPreviewInvitationLinks'
import PublishedPreviewPeople from '../PublishedPreviewPeople/PublishedPreviewPeople'
import PublishedPreviewDetails from '../PublishedPreviewDetails/PublishedPreviewDetails'
import PublishedPreviewConditionalGroups from '../PublishedPreviewConditionalGroups/PublishedPreviewConditionalGroups'

type Props = {
  block: ContentBlock
  order?: string[]
  labelsToHide?: string[]
  fieldsToSuppress?: string[]
  separatorsAfter?: number[]
  noBorder?: boolean
  tableLayout?: boolean
  previewId?: string
  trackEvents: boolean
}

const controlTypesToSuppress = ['ProfileImage']

const getField = (contentBlock: ContentBlock, id: string | undefined) => {
  return contentBlock.contentBlockFields.find(f => f.id === id)
}

const PublishedPreviewFormBlock: React.FC<Props> = ({
  block,
  order,
  fieldsToSuppress,
  separatorsAfter,
  noBorder,
  tableLayout,
  previewId,
  trackEvents,
}) => {
  const firstName = block.contentBlockFields?.find(f => f.formLabel === 'First name')?.value
  const lastName = block.contentBlockFields?.find(f => f.formLabel === 'Last name')?.value
  const fullName = getFullName(firstName, lastName) || ''

  /**
   * This is a bit hackie, but we need to distinguish the Physical Profile from the rest of the content blocks
   */
  if (block.formLabel === 'Physical Profile') {
    block.contentBlockFields.map(block => {
      block['rowDataLayout'] = true
    })
  }

  const trackLinkClick = (contentBlockFieldId: string) => {
    if (trackEvents) {
      trackEvent({
        eventType: VIEW_LINK,
        previewId: previewId,
        contentBlockId: block.id,
        contentBlockFieldId: contentBlockFieldId,
      })
    }
  }

  // Always show formatted name first without label, if it exists.
  const displayFields: Array<Partial<ContentBlockField>> = []
  if (fullName) {
    displayFields.push({ value: fullName })
  }

  const fieldIdsOrdered = order ? [...order] : []
  block.contentBlockFields
    ?.filter(
      f => f.formLabel !== 'First name' && f.formLabel !== 'Last name' && !order?.some(label => label === f.formLabel)
    )
    .filter(f => !controlTypesToSuppress.includes(f.controlType))
    ?.forEach(f => fieldIdsOrdered.push(f.id))

  fieldIdsOrdered.forEach(fieldId => {
    let displayFieldLabel: boolean = true
    const field: ContentBlockField | undefined = getField(block, fieldId)
    const suppress = field?.hiddenFromPublishedPreview || fieldsToSuppress?.some(field => field === fieldId)

    let formattedValue: any
    if (field?.controlType === 'List') {
      if (field?.children?.[0]?.controlType === 'Website') {
        const hasAnyValue = field.children.some(child => validateWebsiteLink(child.value))
        formattedValue = hasAnyValue && (
          <PublishedPreviewWebsiteLinks
            fields={field.children}
            hasLabel={!!field.previewLabel}
            trackLinkClick={trackLinkClick}
          />
        )
      } else if (field?.children?.[0]?.controlType === 'TeamMember') {
        const hasAnyValue = field.children.some(child => validateTeamMember(child))
        formattedValue = hasAnyValue && (
          <PublishedPreviewTeamMembers
            fields={field.children.filter(validateTeamMember)}
            trackLinkClick={trackLinkClick}
          />
        )
      } else if (field?.children?.[0]?.controlType === 'People') {
        const hasAnyValue = field.children.some(child => validatePeople(child))
        formattedValue = hasAnyValue && (
          <PublishedPreviewPeople fields={field.children.filter(validatePeople)} trackLinkClick={trackLinkClick} />
        )
      } else if (field?.children?.[0]?.controlType === 'Details' && field.children[0].children?.[0].children) {
        const hasAnyValue = field.children.some(child => validatePeople(child))
        formattedValue = hasAnyValue && (
          <PublishedPreviewDetails fields={field.children.filter(validatePeople)} trackLinkClick={trackLinkClick} />
        )
      } else if (field?.children?.[0]?.controlType === 'Group') {
        const hasAnyValue = field.children.some(validateGroup)
        displayFieldLabel = false
        formattedValue = hasAnyValue && (
          <PublishedPreviewGroups fields={field.children.filter(validateGroup)} trackLinkClick={trackLinkClick} />
        )
      } else if (field?.children?.[0]?.controlType === 'InvitationLink') {
        formattedValue = (
          <PublishedPreviewInvitationLinks
            fields={field.children}
            hasLabel={!!field.previewLabel}
            trackLinkClick={trackLinkClick}
          />
        )
      } else {
        formattedValue = null // Still to be implemented for other controls.
      }
    } else if (field?.controlType === 'Group') {
      const hasAnyValue = [field].some(validateGroup)
      displayFieldLabel = false
      formattedValue = hasAnyValue && <PublishedPreviewGroups fields={[field]} trackLinkClick={trackLinkClick} />
    } else if (field?.controlType === 'ConditionalGroup') {
      const hasAnyValue = [field].some(validateGroup)
      displayFieldLabel = false
      formattedValue = hasAnyValue && (
        <PublishedPreviewConditionalGroups field={field} trackLinkClick={trackLinkClick} />
      )
    } else if (field?.controlType === 'People') {
      const hasAnyValue = [field].some(validatePeople)
      displayFieldLabel = false
      formattedValue = hasAnyValue && <PublishedPreviewPeople fields={[field]} trackLinkClick={trackLinkClick} />
    } else if (field) {
      formattedValue = formatPreviewValue(field, false, trackLinkClick)
      if (field.formLabel == 'Height (without shoes)') {
      }
    }

    if (!suppress && field) {
      displayFields.push({
        ...field,
        previewLabel: displayFieldLabel ? field?.previewLabel : undefined,
        value: formattedValue,
      })
    }
  })

  return (
    <div className={'published-preview-form-block block' + (noBorder ? ' no-border' : '')}>
      <div className="block-title">{block.previewLabel}</div>
      {tableLayout ? (
        <table>
          <tbody>
            {displayFields
              .filter(f => !!f.value)
              .map((f, i) => (
                <React.Fragment key={i}>
                  {f.previewLabel ? (
                    <tr className="value-with-label-row">
                      <td className="value">{f.value}</td>
                    </tr>
                  ) : (
                    <tr className="value-row">
                      <td colSpan={2}>{f.value}</td>
                    </tr>
                  )}
                  {separatorsAfter?.includes(i) && (
                    <tr>
                      <td colSpan={2}>
                        <div className="separator" />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="single-column-layout">
          {displayFields
            .filter(f => !!f.value)
            .map((f, i) => (
              <React.Fragment key={i}>
                {f.previewLabel && f.value && <div className="label">{f.previewLabel}</div>}
                <div className="value">{f.value}</div>
                {separatorsAfter?.includes(i) && <div className="separator" />}
              </React.Fragment>
            ))}
        </div>
      )}
    </div>
  )
}

export default PublishedPreviewFormBlock
