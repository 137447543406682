import React from 'react'

const CircleRecordIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill="#DBDBDB" />
    <circle cx="9" cy="9.00001" r="6.42857" fill="#FF6347" />
  </svg>

)

export default CircleRecordIcon