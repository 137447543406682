import { checkPublishable } from 'helpers/validation'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as previewService from 'services/previewService'
import { PreviewStatus, PreviewAlgolia, ViewingSelection, TagOption } from 'state/types'
import { bulkPreviewArchived, bulkPreviewPublish } from 'services/previewService'
import useAuth from 'state/useAuth'
import { AlgoliaResource, PreviewsCreatorFilterOption, PreviewViewMode, WorkspaceType } from 'helpers/enum'
import {
  DEFAULT_DASHBOARD_ALGOLIA_PREVIEW_INDEX,
  DEFAULT_TABLE_VIEW_ITEMS_PER_PAGE,
  INITIAL_VIEWS,
} from 'helpers/constant'
import useWorkspace from './useWorkspace'
import algoliasearch, { SearchClient } from 'algoliasearch'
import { algoliaApplicationId } from 'helpers/config'

const usePreviewsAlgolia = (retrievePreviewCountOnLoad?: boolean, algoliaSearchApiKey?: string) => {
  const { currentWorkspace } = useWorkspace(true)
  const { user } = useAuth()
  const isWorkspaceTypeUser = currentWorkspace?.type === WorkspaceType.User

  //algolia index for sorting
  const [algoliaSearchClient, setAlgoliaSearchClient] = useState<SearchClient | null>(null) //use to force recall algolia search

  const [sortingIndex, setSortingIndex] = useState<string>(DEFAULT_DASHBOARD_ALGOLIA_PREVIEW_INDEX)
  const [filters, setFilters] = useState<string>('userId:' + user?.id)
  const [activePreviewsCountFilter, setActivePreviewsCountFilter] = useState<string | null>(null)
  const [archivedPreviewsCountFilter, setArchivedPreviewsCountFilter] = useState<string | null>(null)

  const [isTableViewPreviewsLoading, setTableViewPreviewsLoading] = useState(false)
  const [isPreviewCategoryCountLoading, setPreviewCategoryCountLoading] = useState(true)
  const [searchResults, setSearchResults] = useState<any>([])
  const [previews, setPreviews] = useState<PreviewAlgolia[]>([])
  const [searchKey, setSearchKey] = useState<string>('')
  const [previewCount, setPreviewCount] = useState<number>(0)
  const [archivedPreviewsCount, setArchivedPreviewsCount] = useState<number>(0)
  const [activePreviewsCount, setActivePreviewsCount] = useState<number>(0)
  const [suspendedPreviewsCount, setSuspendedPreviewsCount] = useState<number>()
  const [viewingSelection, setViewingSelection] = useState<ViewingSelection>('active')
  const [creatingId, setCreatingId] = useState<string | undefined>()
  const [loadingId, setLoadingId] = useState<string | undefined>()
  const [removingId, setRemovingId] = useState<string | undefined>()
  const [individualCount, setIndividualCount] = useState<number>()
  const [businessCount, setBusinessCount] = useState<number>()
  const [isIndividualCountAtMaxForFree, setisIndividualCountAtMaxForFree] = useState(false)
  const [isBusinessCountAtMaxForFree, setisBusinessCountAtMaxForFree] = useState(false)
  const [cachedUpdatedPreviews, setCachedUpdatedPreviews] = useState<Map<string, PreviewAlgolia>>(new Map())
  const [cachedHiddenPreviews, setCachedHiddenPreviews] = useState<Set<string>>(new Set())

  const history = useHistory()
  const mountedRef = useRef(true)
  const [currentPage, setCurrentPage] = useState(1)

  const [reversedStatusPreviewCountFilter, setReversedStatusPreviewCountFilter] = useState<string>('')

  const [status, setStatus] = useState('all')
  const [archived, setArchived] = useState(false)
  const [sortingBy, setSortingBy] = useState('status')
  const [sortingAsc, setSortingAsc] = useState(false)
  const [creator, setCreator] = useState<string>(PreviewsCreatorFilterOption.Everyone)
  const [tagFilter, setTagFilter] = useState<string>('all')
  const [totalPage, setTotalPage] = useState(1)
  const [itemCount, setItemCount] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(DEFAULT_TABLE_VIEW_ITEMS_PER_PAGE)

  const createPreview = async (templateId: string) => {
    setCreatingId(templateId)
    const { ok, data } = await previewService.createPreview(templateId)
    setCreatingId(undefined)

    if (ok && data) {
      const newPreviewId = data
      history.push(`/edit/${newPreviewId}`)
    }
  }

  const updateArchived = async (previewId: string, archived: boolean, suspended: boolean) => {
    setLoadingId(previewId)
    const { ok } = await previewService.updatePreviewArchived(previewId, archived)
    setLoadingId(undefined)

    if (ok) {
      setCachedHiddenPreviews(cachedHiddenPreviews.add(previewId))
      setPreviews(previews.filter(preview => !cachedHiddenPreviews.has(preview.id)))
    }
  }

  const publishBulkPreviews = async function (previewIds: string[], published: boolean) {
    const { ok } = await bulkPreviewPublish(previewIds, published)
    if (ok) {
      setPreviews(
        previews.map(p =>
          previewIds.includes(p.id)
            ? {
                ...p,
                status: published ? 'Published ' + new Date().toISOString() : 'Draft ' + new Date().toISOString(),
              }
            : p
        )
      )
    }
  }

  const archiveBulkPreviews = async function (previewIds: string[], archived: boolean) {
    setLoadingId(previewIds[0])
    const { ok } = await bulkPreviewArchived(previewIds, archived)
    setLoadingId(undefined)

    if (ok) {
      previewIds.forEach(previewId => cachedHiddenPreviews.add(previewId))
      setCachedHiddenPreviews(cachedHiddenPreviews)
      setPreviews(previews.filter(preview => !cachedHiddenPreviews.has(preview.id)))
    }
  }

  const archivePreview = (previewId: string, suspended: boolean) => updateArchived(previewId, true, suspended)

  const restorePreview = (previewId: string, suspended: boolean) => updateArchived(previewId, false, suspended)

  const checkCanPublish = async (previewId: string) => {
    setLoadingId(previewId)
    const { ok, data } = await previewService.getPreviewDetails(previewId)
    setLoadingId(undefined)
    if (ok && data) {
      return checkPublishable(data)
    }
    return {}
  }

  const updatePublished = async (
    preview: PreviewAlgolia,
    published: boolean,
    isSuspended: boolean
  ): Promise<boolean> => {
    const previewId = preview.id
    setLoadingId(previewId)
    const { ok, data } = await previewService.updatePreviewPublished(previewId, published)
    if (ok) {
      let suspended: boolean = isSuspended
      if (published && isSuspended) {
        suspended = false
      }

      let updatedPreview: PreviewAlgolia

      if (published && data) {
        updatedPreview = {
          ...previews.filter(p => p.id === previewId)[0],
          status: 'Published ' + data,
          suspended,
        }
      } else {
        updatedPreview = {
          ...previews.filter(p => p.id === previewId)[0],
          status: 'Draft',
          suspended,
        }
      }

      cachedUpdatedPreviews.set(updatedPreview.id, updatedPreview)
      setCachedUpdatedPreviews(cachedUpdatedPreviews)
      setPreviews(previews.map(p => (p.id === previewId ? updatedPreview : p)))

      if (isSuspended && suspendedPreviewsCount && suspendedPreviewsCount > 0) {
        setSuspendedPreviewsCount(suspendedPreviewsCount - 1)
      }
      setLoadingId(undefined)
      return true
    } else {
      setLoadingId(undefined)
      return false
    }
  }

  const publishPreview = async (preview: PreviewAlgolia, suspended: boolean): Promise<boolean> =>
    await updatePublished(preview, true, suspended)

  const unpublishPreview = async (preview: PreviewAlgolia, suspended: boolean): Promise<boolean> =>
    await updatePublished(preview, false, suspended)

  const deletePreview = async (previewId: string) => {
    setLoadingId(previewId)
    const { ok } = await previewService.deletePreview(previewId)
    setLoadingId(undefined)

    setRemovingId(previewId)
    setTimeout(() => {
      if (mountedRef.current) {
        setPreviews(previews.filter(p => p.id !== previewId))
        setArchivedPreviewsCount(archivedPreviewsCount - 1)
        setRemovingId(undefined)
      }
    }, 400) // Remove preview after 400ms to allow animation time to finish.
  }

  const deleteBulkPreviews = async (previewIds: string[]) => {
    setLoadingId(previewIds[0])
    const { ok } = await previewService.deleteBulkPreviews(previewIds)
    setLoadingId(undefined)
  }

  const duplicatePreview = async (newPreview: PreviewAlgolia, duplicatedPreviewId?: string) => {
    setLoadingId(undefined)

    let oldIndex: number = previews.findIndex(preview => preview.id === duplicatedPreviewId)
    if (user) {
      previews.splice(oldIndex + 1, 0, {
        ...newPreview,
        views: INITIAL_VIEWS,
        status: 'Draft',
        creator: (user.givenName || '') + user.familyName,
      })
      setPreviews(previews)
    }
  }

  const onUpdateTags = (previewId: string, newSelectedTags: TagOption[]) => {
    let newTagNames: string[] = []
    let newTagIds: string[] = []

    newSelectedTags.forEach((tag: TagOption) => {
      newTagNames.push(tag.name)
      newTagIds.push(tag.id)
    })

    const newDisplayTagNames = newTagNames.join(', ')
    const updatedPreview = {
      ...previews.filter(p => p.id === previewId)[0],
      tags: newDisplayTagNames,
      tagIds: newTagIds,
    }
    cachedUpdatedPreviews.set(updatedPreview.id, updatedPreview)
    setCachedUpdatedPreviews(cachedUpdatedPreviews)
    setPreviews(previews.map(p => (p.id === previewId ? updatedPreview : p)))
  }

  const loadTableViewPreviews = async (refresh?: boolean) => {
    let previewQueryString = `${viewingSelection === 'active' ? 'archived:false' : 'archived:true'}${
      creator === PreviewsCreatorFilterOption.JustMe && user?.id ? ' AND userId:' + user.id : ''
    }`

    let archivedItemsFilter = 'organization:not_found'
    let activeItemsFilter = 'organization:not_found'

    if (!isWorkspaceTypeUser && currentWorkspace) {
      previewQueryString =
        previewQueryString + (!!previewQueryString ? ' AND ' : '') + 'organizationId:' + currentWorkspace.id

      archivedItemsFilter = 'organizationId:' + currentWorkspace.id + ' AND archived:true'
      activeItemsFilter = 'organizationId:' + currentWorkspace.id + ' AND archived:false'
    }

    if (refresh) {
      if (algoliaSearchApiKey) {
        setAlgoliaSearchClient(algoliasearch(algoliaApplicationId, algoliaSearchApiKey))
      }
    } else {
      setFilters(previewQueryString)
      setActivePreviewsCountFilter(activeItemsFilter)
      setArchivedPreviewsCountFilter(archivedItemsFilter)
    }
  }

  const onTotalItemPerPageChange = (itemPerPage: number) => {
    setTotalItemPerPage(itemPerPage)
  }

  // Load individual & business previews counts and booleans
  useEffect(() => {
    const loadPreviewCategoryCount = async () => {
      setPreviewCategoryCountLoading(true)
      const { ok, data } = await previewService.getPreviewCategoryCount()
      if (ok && data) {
        setisIndividualCountAtMaxForFree(data.overLimitForFreeIndividualPreviews)
        setisBusinessCountAtMaxForFree(data.overLimitForFreeBusinessPreviews)
        setIndividualCount(data.individualPreviewsCount)
        setBusinessCount(data.businessPreviewsCount)
      }
      setPreviewCategoryCountLoading(false)
    }

    if (retrievePreviewCountOnLoad) {
      loadPreviewCategoryCount()
    }
  }, [retrievePreviewCountOnLoad])

  //get secure api key
  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    let previewQueryString = `${viewingSelection === 'active' ? 'archived:false' : 'archived:true'}`

    //check change viewingSelection
    if (
      (filters.includes('archived:false') && viewingSelection === 'archived') ||
      (filters.includes('archived:true') && viewingSelection === 'active')
    ) {
      setCurrentPage(1)
      setCachedHiddenPreviews(new Set<string>())
      loadTableViewPreviews(true)
    }

    if (creator === PreviewsCreatorFilterOption.JustMe && user?.id) previewQueryString += ' AND userId:' + user.id
    if (tagFilter != 'all') previewQueryString += ' AND tagIds:' + tagFilter

    if (!isWorkspaceTypeUser && currentWorkspace) {
      previewQueryString =
        previewQueryString + (!!previewQueryString ? ' AND ' : '') + 'organizationId:' + currentWorkspace.id

      const archivedItemsFilter = 'organizationId:' + currentWorkspace.id + ' AND archived:true'
      const activeItemsFilter = 'organizationId:' + currentWorkspace.id + ' AND archived:false'
      setActivePreviewsCountFilter(activeItemsFilter)
      setArchivedPreviewsCountFilter(archivedItemsFilter)
    }

    setFilters(previewQueryString)
  }, [viewingSelection, creator, sortingBy, sortingAsc, totalItemPerPage, tagFilter])

  //for rendering pagiantion
  const range = (start: number, end: number) => {
    let length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
  }

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(itemCount / totalItemPerPage)
    const totalPageNumbers = 7

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(currentPage - 2, 1)
    const rightSiblingIndex = Math.min(currentPage + 2, totalPageCount)

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    const DOTS = '...'

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 7
      let leftRange = range(1, leftItemCount)

      return [...leftRange, DOTS, totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 7
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
      return [firstPageIndex, DOTS, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }

    return []
  }, [itemCount, totalItemPerPage, currentPage])

  return {
    isTableViewPreviewsLoading,
    isPreviewCategoryCountLoading,
    previews,
    creatingId,
    loadingId,
    removingId,
    viewingSelection,
    previewCount,
    archivedPreviewsCount,
    activePreviewsCount,
    individualCount,
    businessCount,
    suspendedPreviewsCount,
    isBusinessCountAtMaxForFree,
    isIndividualCountAtMaxForFree,
    currentPage,
    creator,
    sortingBy,
    sortingAsc,
    status,
    archived,
    totalPage,
    itemCount,
    totalItemPerPage,
    paginationRange,
    searchKey,
    filters,
    reversedStatusPreviewCountFilter,
    searchResults,
    sortingIndex,
    algoliaSearchClient,
    activePreviewsCountFilter,
    archivedPreviewsCountFilter,
    algoliaSearchApiKey,
    tagFilter,
    cachedUpdatedPreviews,
    cachedHiddenPreviews,
    setTableViewPreviewsLoading,
    createPreview,
    archivePreview,
    restorePreview,
    checkCanPublish,
    publishPreview,
    unpublishPreview,
    deletePreview,
    duplicatePreview,
    setViewingSelection,
    archiveBulkPreviews,
    deleteBulkPreviews,
    publishBulkPreviews,
    setCurrentPage,
    setCreator,
    setSortingBy,
    setSortingAsc,
    setStatus,
    setArchived,
    loadTableViewPreviews,
    onTotalItemPerPageChange,
    setTotalItemPerPage,
    setSearchKey,
    setPreviews,
    setFilters,
    setReversedStatusPreviewCountFilter,
    setSearchResults,
    setArchivedPreviewsCount,
    setActivePreviewsCount,
    setPreviewCount,
    setItemCount,
    setSortingIndex,
    setAlgoliaSearchClient,
    setActivePreviewsCountFilter,
    setArchivedPreviewsCountFilter,
    setTagFilter,
    onUpdateTags,
  }
}

export default usePreviewsAlgolia
