import React from 'react'
import { useHistory } from 'react-router-dom'
import GetInTouchMessage from 'components/preview/GetInTouchMessage/GetInTouchMessage'
import TemplateCards from 'components/preview/TemplateCards/TemplateCards'
import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import './InvitationTemplatePage.scss'

const InvitationTemplatePage = () => {
  const { push } = useHistory()
  const onTemplateSelected = async (id: string) => push(`/invitations/example/${id}`)

  return (
    <FullScreenPageFrame
      className="invitation-template-page"
      cancelLink="/invitations"
      title="Create an Invitation"
      subtitle="What kind of Preview would you like to receive?"
    >
      <div className="template-selection-content">
        <GetInTouchMessage />
        <TemplateCards isInvitational={true} templateUsageTypesToDisplay={['any', 'invitation']} onTemplateSelected={onTemplateSelected} />
      </div>
    </FullScreenPageFrame>
  )
}

export default InvitationTemplatePage
