import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import React, { useEffect, useMemo, useState } from 'react'
import { Preview } from '../../../state/types'
import { duplicateOrganizationPreview } from '../../../services/previewService'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import DuplicateSuccess from './DuplicateSuccess'
import './PreviewDuplicateModal.scss'
import { getDashboardTitles } from 'helpers/titles'
import * as yup from 'yup'
import FormInput from 'components/shared/FormInput/FormInput'
import { useForm } from 'react-hook-form'
import { getCurrentWorkspace } from '../../../helpers/organization'
import { WorkspaceType } from '../../../helpers/enum'

interface Props {
  isShowing: boolean
  hideModal: () => void
  preview: Preview
  onDuplicateSuccess: (preview?: Preview) => void
}

type FormData = {
  newTitle: string
  newSubTitle: string
}

const validationSchema = yup.object().shape({
  newTitle: yup.string().required('Please enter new primary title'),
})

const PreviewDuplicateModal = function ({ preview, isShowing, hideModal, onDuplicateSuccess }: Props) {
  const { title, subtitle } = useMemo(() => {
    return getDashboardTitles(preview)
  }, [preview])
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [newTitle, setNewTitle] = useState<string>('CLONE: ' + title)
  const [newSubtitle, setNewSubtitle] = useState<string | undefined>(subtitle)
  const [newPreview, setNewPreview] = useState<Preview | null>(null)
  const templateWithoutSubtitle = ['Seeking Opportunities', 'Athletic Biography', 'Sports Biography', 'General Introduction', 'Bare Essentials']
  const disableSubtitleForm = templateWithoutSubtitle.some(template => template === preview.templateName)
  const { handleSubmit, register, errors } = useForm<FormData>({ validationSchema })
  const currentWorkspace = getCurrentWorkspace()
  useEffect(() => {
    if (title?.startsWith('CLONE')) setNewTitle(title)
    else setNewTitle('CLONE: ' + title)
    setNewSubtitle(subtitle)
  }, [isShowing])

  const onContinue = handleSubmit(async function () {
    if (!preview) return
    setIsLoading(true)
    const { data, ok } = await duplicateOrganizationPreview(preview.id, newTitle, newSubtitle)
    setIsLoading(false)
    if (data && ok) {
      setNewPreview(data)
      onDuplicateSuccess(data)
    }
    setIsSuccess(true)
  })

  const onClose = function () {
    hideModal()
    setIsSuccess(false)
  }

  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      {isSuccess && newPreview ? (
        <DuplicateSuccess previewId={newPreview.id} organisationId={preview.organizationId} onClose={onClose} />
      ) : (
        <div className="duplicate-preview-modal">
          <h2>Duplicate Preview: {title}</h2>
          <form>
            <span>Rename your Preview: </span>
            <FormInput
              name="newTitle"
              label="Primary title:"
              inputRef={register}
              type="text"
              errors={errors}
              defaultValue={newTitle}
              onChange={e => {
                setNewTitle(e.target.value)
              }}
            />
            {
              !disableSubtitleForm &&
              <FormInput
                name="newSubTitle"
                label="Subtitle:"
                inputRef={register}
                type="text"
                errors={errors}
                defaultValue={newSubtitle}
                onChange={e => {
                  setNewSubtitle(e.target.value)
                }}
              />
            }

            <LoadingButton isLoading={isLoading} className="primary" onClick={onContinue}>
              Create
            </LoadingButton>
          </form>
        </div>
      )}
    </CloseableDialog>
  )
}

export default PreviewDuplicateModal
