import React, { useState } from 'react'
import { isMobile } from 'helpers/browser'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import './TeleprompterEditor.scss'

type Props = {
  initialText?: string
  save: (text: string) => Promise<void>
  close: () => void
}

const TeleprompterEditor: React.FC<Props> = ({ initialText, save, close }) => {
  const [text, setText] = useState(initialText || '')
  const [isSaving, setSaving] = useState(false)

  const onSaveButtonClick = async () => {
    setSaving(true)
    await save(text)
    close()
  }

  return (
    <div className="teleprompter-editor">
      <div className="teleprompter-editor-content">
        <textarea
          placeholder="Add your script here"
          value={text}
          autoFocus={!isMobile}
          onChange={e => setText(e.currentTarget.value)}
        />
      </div>
      <div className="button-row">
        <div className="button-row-content">
          <button className="cancel-button link" onClick={close}>
            Cancel
          </button>
          <LoadingButton isLoading={isSaving} className="save-button primary" onClick={onSaveButtonClick}>
            {'Save & close'}
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default TeleprompterEditor
