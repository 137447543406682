export const getEnumKey = (e: any, value: string) => {
  return Object.keys(e).filter(key => e[key] === value)[0]
}

export enum PreviewCategory {
  Individual = 'individual',
  Business = 'business',
}

export enum BillingType {
  Monthly = "monthly",
  Annual = "annual"
}

export enum AccountType {
  Personal = 'personal',
  Organization = 'organization',
}

export enum Dimension {
  Length = 'Length',
  SmallLength = 'SmallLength',
  Weight = 'Weight',
}

export enum InvitationResponseLabel {
  No = 'No',
  Shortlist = 'Shortlist',
  PhoneScreen = 'Phone Screen',
  Interview = 'Interview',
  Successful = 'Successful',
  Favorite = 'Favorite',
}

export enum WeightUnits {
  Kilogram = 'kg',
  Pound = 'lb',
}

export enum LengthUnits {
  Meter = 'm',
  Feet = 'ft',
  Inch = 'in',
  Centimetre = 'cm',
}

export enum UnitType {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export enum SubscriptionPlan {
  Basic = 'Basic',
  Plus = 'Plus',
  Solo = 'Solo',
  Professional = 'Professional',
  Enterprise = 'Enterprise',
  Business = 'Business'
}

export enum PriceType {
  Plan = 'plan',
  AdditionalUser = 'additional user',
  UnlimitedInvites = 'unlimited invites',
}

export enum TeamMemberType {
  Owner = 'owner',
  Administrator = 'administrator',
  TeamMember = 'team member',
}

export enum TeamMemberStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export enum WorkspaceType {
  User = 'user',
  Organization = 'organization',
}

export enum SubscriptionStatus {
  Trail = 'trail',
  Pending = 'pending',
  Active = 'active',
  Cancelled = 'cancelled',
  PastDue = 'past due',
}

export enum Status {
  Active = 'active',
  Archived = 'archived',
}

export enum MaxPreviewsByCategoryForFree {
  Individual = '4',
  Business = '5',
}

export enum PreviewPlan {
  Free = 'free',
  Basic = 'basic',
  Professional = 'professional',
}

export enum PreviewMeObject {
  Preview = 'preview',
  Invitation = 'invitation',
}

export enum PreviewMeAction {
  Create = 'create',
  Publish = 'publish',
}

export enum ChangePlanResponse {
  UnableToChangePlan = 'unableToChangePlan',
  PlanChanged = 'planChanged',
  PlanWillChange = 'planWillChange',
}

export enum InvitationsStatusFilterOption {
  All = 'All',
  Open = 'Open',
  Closed = 'Closed',
}

export enum TeamMembersStatusFilterOption {
  ALL = 'All',
  ACTIVE = 'Active',
  PENDING = 'Pending',
}

export enum IntegrationsStatusFilterOption {
  ALL = 'All',
  ACTIVE = 'Active',
  PENDING = 'Pending',
}

export enum InvitationResponsesLabelFilterOption {
  All = 'All',
  No = 'No',
  Shortlist = 'Shortlist',
  PhoneScreen = 'Phone Screen',
  Interview = 'Interview',
  Successful = 'Successful',
  Favorite = 'Favorite',
}

export enum InvitationsCreatorFilterOption {
  Everyone = 'Everyone',
  JustMe = 'Just Me',
}

export enum PreviewsCreatorFilterOption {
  Everyone = 'Everyone',
  JustMe = 'Just Me',
}

export enum PreviewViewMode {
  TILE_VIEW = 'TILE_VIEW',
  TABLE_VIEW = 'TABLE_VIEW',
}

export enum NavItemType {
  Nav = 'nav',
  Parent = 'parent',
  Child = 'child',
}

export enum FieldName {
  Title = 'Title',
  Subtitle = 'Subtitle',
  Subject = 'Subject',
  Recipient = 'Recipient',
  Function = 'Function',
}

export enum AlgoliaResource {
  Preview = 'preview',
  InvitationResponse = 'invitationResponse',
}

export enum IntegrationType {
  HUBSPOT = 'HUBSPOT',
  GOOGLE_ANALYTIC = 'GOOGLE_ANALYTIC',
  GMAIL = 'GMAIL',
  META_PIXEL = 'META_PIXEL',
  LINKEDIN = 'LINKEDIN',
  OUTLOOK = 'OUTLOOK',
  SALESLOFT = 'SALESLOFT',
}

export enum IntegrationStatus {
  FUNCTIONAL = 'FUNCTIONAL',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  ARCHIVED = 'ARCHIVED',
}

export enum IntegrationSettingFieldType {
  META_PIXEL_GDPR = 'META_PIXEL_GDPR',
}

export enum Country {
  NEW_ZEALAND = 'NZL'
}