import { useRef, useState, useEffect } from 'react'

export const useIntersectionObserver = (elementRef: React.RefObject<Element>) => {
  const [intersects, setIntersects] = useState(false)

  const observerRef = useRef(new IntersectionObserver(entries => setIntersects(entries[0].isIntersecting)))

  useEffect(() => {
    const element = elementRef.current
    const observer = observerRef.current
    if (element && observer) {
      observer.observe(element)
    }
    return () => {
      if (element && observer) {
        observer.unobserve(element)
      }
    }
  }, [observerRef, elementRef])

  return { intersects }
}
