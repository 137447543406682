import React from 'react'

const BackArrowSmallIcon = () => (
  <svg className="icon" width="18" height="15" viewBox="0 0 18 15">
    <g strokeWidth="1.3" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g transform="translate(2.000000, 2.000000)">
        <polyline
          strokeLinejoin="round"
          transform="translate(5.500000, 5.500000) rotate(-135.000000) translate(-5.500000, -5.500000) "
          points="2 2 9 2 9 9"
        ></polyline>
        <line x1="0.550252532" y1="5.5" x2="13.5068359" y2="5.5"></line>
      </g>
    </g>
  </svg>
)

export default BackArrowSmallIcon
