import ClicksIcon from 'assets/icons/ClicksIcon'
import DownloadIcon from 'assets/icons/DownloadIcon'
import PreviewsIcon from 'assets/icons/PreviewsIcon'
import RecordIcon from 'assets/icons/RecordIcon'
import {
  mockLocationChartData,
  mockPlatformChartData,
  mockPreviewsOverTime,
  mockReferrerChartData,
  mockTopVideos,
} from 'components/analytics/AnalyticsMockData'
import AnalyticsPreviewTypeDropdown, {
  getDefaultPreviewStatus,
} from 'components/analytics/AnalyticsPreviewStatusDropdown/AnalyticsPreviewStatusDropdown'
import AnalyticsRangeDropdown, {
  getDefaultRange,
} from 'components/analytics/AnalyticsRangeDropdown/AnalyticsRangeDropdown'
import AnalyticsSummaryCard from 'components/analytics/AnalyticsSummaryCard/AnalyticsSummaryCard'
import AnalyticsVideoCard from 'components/analytics/AnalyticsVideoCard/AnalyticsVideoCard'
import DonutChart from 'components/analytics/DonutChart/DonutChart'
import PreviewViewsChart from 'components/analytics/PreviewViewsChart/PreviewViewsChart'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { viewsByReferrerMapper } from 'helpers/analytics'
import { PLAY_VIDEO, VIEW_DOCUMENT, VIEW_LINK, VIEW_PREVIEW } from 'helpers/events'
import React, { useEffect, useState } from 'react'
import * as analyticsService from 'services/analyticsService'
import * as previewService from 'services/previewService'
import {
  AnalyticsEvent,
  AnalyticsPreviewStatus,
  PreviewAnalyticsRange,
  PreviewViewsByCategory,
  PreviewViewsOverTime,
  VideoAnalytics,
} from 'state/types'
import useAuth from 'state/useAuth'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import { isOnPaymentPlanOrAdmin } from '../../../helpers/subscription'
import './AnalyticsOverview.scss'

const AnalyticsOverview = () => {
  const { user } = useAuth()
  const [loadingSummary, setLoadingSummary] = useState(true)
  const [loadingTopPreviewViewsOverTime, setLoadingTopPreviewViewsOverTime] = useState(true)
  const [loadingTopVideos, setLoadingTopVideos] = useState(true)
  const [loadingViewsByLocation, setLoadingViewsByLocation] = useState(true)
  const [summary, setSummary] = useState<AnalyticsEvent[] | undefined>()
  const [topPreviewViewsOverTime, setTopPreviewViewsOverTime] = useState<PreviewViewsOverTime[] | undefined>()
  const [topVideos, setTopVideos] = useState<VideoAnalytics[] | undefined>()
  const [viewsByLocation, setViewsByLocation] = useState<PreviewViewsByCategory[] | undefined>()
  const [viewsByPlatform, setViewsByPlatform] = useState<PreviewViewsByCategory[] | undefined>()
  const [viewsByReferrer, setViewsByReferrer] = useState<PreviewViewsByCategory[] | undefined>()
  const referrerChartData = viewsByReferrerMapper(viewsByReferrer)
  const locationChartData = viewsByLocation?.map(v => ({ id: v.category, label: v.category, value: v.count }))
  const platformChartData = viewsByPlatform?.map(v => ({ id: v.category, label: v.category, value: v.count }))
  const [previewStatus, setPreviewStatus] = useState<AnalyticsPreviewStatus>(getDefaultPreviewStatus())
  const [range, setRange] = useState<PreviewAnalyticsRange>(getDefaultRange())
  const anyLoading = loadingSummary || loadingTopVideos || loadingTopPreviewViewsOverTime || loadingViewsByLocation
  const { isSubscriptionLoading, subscription } = useSubscription(true)

  const lookupCount = (eventType: string) =>
    summary ? summary?.find(e => e.eventType === eventType)?.count || 0 : undefined

  const lookupTrendPercentage = (eventType: string) =>
    summary ? summary?.find(e => e.eventType === eventType + '_TREND')?.count : undefined

  useEffect(() => {
    const loadSummary = async () => {
      setLoadingSummary(true)
      const { ok, data } = await analyticsService.getAnalyticsSummary(range, undefined, previewStatus)
      if (ok && data) {
        setSummary(data)
      }
      setLoadingSummary(false)
    }
    loadSummary()

    if (!isOnPaymentPlanOrAdmin(subscription, user!)) {
      return
    }

    const loadTopPreviewViewsOverTime = async () => {
      if (range !== 'last-24-hours') {
        setLoadingTopPreviewViewsOverTime(true)
        const { ok, data } = await analyticsService.getTopPreviewViewsOverTime(range, previewStatus)
        if (ok && data) {
          setTopPreviewViewsOverTime(data)
        }
      }
      setLoadingTopPreviewViewsOverTime(false)
    }

    const loadTopVideos = async () => {
      setLoadingTopVideos(true)
      const { ok, data } = await analyticsService.getTopVideos(range, previewStatus)
      if (ok && data) {
        setTopVideos(data)
      }
      setLoadingTopVideos(false)
    }

    const loadViewsByLocation = async () => {
      setLoadingViewsByLocation(true)
      const { ok, data } = await analyticsService.getPreviewViewsByLocation(range, undefined, previewStatus)
      if (ok && data) {
        setViewsByLocation(data)
      }
      setLoadingViewsByLocation(false)
    }

    const loadViewsByPlatform = async () => {
      const { ok, data } = await analyticsService.getPreviewViewsByPlatform(range, undefined, previewStatus)
      if (ok && data) {
        setViewsByPlatform(data)
      }
    }

    const loadViewsByBusinessReferrer = async () => {
      const { ok, data } = await previewService.getMyPreviews()
      if (ok && data) {
        const previewSummaries = data.previewSummaries
        let businessRefferer: PreviewViewsByCategory[] = []
        for (let index = 0; index < previewSummaries.length; index++) {
          const preview = previewSummaries[index]
          if (preview.previewCategory === 'business') {
            const { ok, data } = await analyticsService.getPreviewViewsByReferrer(range, preview.id)
            if (ok && data) {
              data.forEach(referrer => {
                businessRefferer.push({ category: referrer.category, count: referrer.count })
              })
            }
          }
        }
        setViewsByReferrer(businessRefferer)
      }
    }

    loadViewsByBusinessReferrer()
    loadSummary()
    loadTopPreviewViewsOverTime()
    loadTopVideos()
    loadViewsByLocation()
    loadViewsByPlatform()
  }, [range, previewStatus, user, subscription])

  return (
    <>
      {isSubscriptionLoading ? (
        <LoadingSpinner container expand />
      ) : (
        <div className={'analytics-overview ' + (!isOnPaymentPlanOrAdmin(subscription, user!) ? 'obfuscated' : '')}>
          <div className="analytics-dropdowns-section">
            <AnalyticsPreviewTypeDropdown
              className="analytics-preview-status-dropdown"
              value={previewStatus}
              onChange={setPreviewStatus}
            />
            <AnalyticsRangeDropdown value={range} onChange={setRange} />
          </div>
          <h2 className="analytics-section-title">Summary</h2>
          <div className="analytics-cards analytics-summary-cards">
            <AnalyticsSummaryCard
              title="Preview views"
              className="preview-views"
              value={lookupCount(VIEW_PREVIEW)}
              trendPercentage={lookupTrendPercentage(VIEW_PREVIEW)}
              icon={<PreviewsIcon />}
            />
            <AnalyticsSummaryCard
              title="Media clicks"
              className="video-views"
              value={lookupCount(PLAY_VIDEO)}
              trendPercentage={lookupTrendPercentage(PLAY_VIDEO)}
              icon={<RecordIcon />}
            />
            <AnalyticsSummaryCard
              title="Document downloads"
              className="document-downloads"
              value={lookupCount(VIEW_DOCUMENT)}
              trendPercentage={lookupTrendPercentage(VIEW_DOCUMENT)}
              icon={<DownloadIcon />}
            />
            <AnalyticsSummaryCard
              title="Links clicked"
              className="link-clicks"
              value={lookupCount(VIEW_LINK)}
              trendPercentage={lookupTrendPercentage(VIEW_LINK)}
              icon={<ClicksIcon />}
            />
          </div>
          {true ? (
            !anyLoading && (
              <>
                {range !== 'last-24-hours' && (
                  <>
                    <h2 className="analytics-section-title">Preview Views</h2>
                    {topPreviewViewsOverTime && topPreviewViewsOverTime.length > 0 ? (
                      <PreviewViewsChart data={topPreviewViewsOverTime} range={range} />
                    ) : (
                      <div className="no-data-message">You have no Preview views in this time period.</div>
                    )}
                  </>
                )}
                {topVideos && topVideos.length > 0 && (
                  <>
                    <h2 className="analytics-section-title">Highest Performing Media Assets</h2>
                    <div className="analytics-cards analytics-video-cards">
                      {topVideos?.map((videoAnalytics, i) => (
                        <AnalyticsVideoCard
                          key={videoAnalytics.videoChapter.id}
                          rank={i + 1}
                          previewId={videoAnalytics.previewId}
                          chapter={videoAnalytics.videoChapter}
                          previewTitle={videoAnalytics.previewName}
                          firstPublishDate={videoAnalytics.firstPublishDate}
                          views={videoAnalytics.viewCount}
                          engagementPercentage={videoAnalytics.engagementPercentage}
                          timesWatchedInFull={videoAnalytics.timesWatchedInFull}
                        />
                      ))}
                    </div>
                  </>
                )}
                {locationChartData &&
                  locationChartData?.length > 0 &&
                  platformChartData &&
                  platformChartData?.length > 0 && (
                    <>
                      <h2 className="analytics-section-title">
                        {referrerChartData && referrerChartData?.length > 0
                          ? 'Location, Platform and Referrer'
                          : 'Location and Platform '}
                      </h2>
                      <div className="donuts">
                        <DonutChart
                          title={
                            <>
                              <span className="light">Preview views by</span>
                              <span> location</span>
                            </>
                          }
                          data={locationChartData}
                        />
                        <DonutChart
                          title={
                            <>
                              <span className="light">Preview views by</span>
                              <span> platform</span>
                            </>
                          }
                          data={platformChartData}
                        />
                        {referrerChartData && referrerChartData?.length > 0 ? (
                          <DonutChart
                            title={
                              <>
                                <span className="light">Preview views by</span>
                                <span> Referrer (Business Previews only)</span>
                              </>
                            }
                            data={referrerChartData}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  )}
              </>
            )
          ) : (
            <>
              <h2 className="analytics-section-title">Preview Views</h2>
              <PreviewViewsChart data={mockPreviewsOverTime} range={range} obfuscated={true} />

              <h2 className="analytics-section-title">Highest Performing Media Assets</h2>
              <div className="analytics-cards analytics-video-cards">
                {mockTopVideos?.map((videoAnalytics, i) => (
                  <AnalyticsVideoCard
                    key={videoAnalytics.videoChapter.id}
                    rank={i + 1}
                    chapter={videoAnalytics.videoChapter}
                    previewTitle={videoAnalytics.previewName}
                    views={videoAnalytics.viewCount}
                    engagementPercentage={videoAnalytics.engagementPercentage}
                    timesWatchedInFull={videoAnalytics.timesWatchedInFull}
                  />
                ))}
              </div>
              <h2 className="analytics-section-title">Location, Platform and Referrer</h2>
              <div className="donuts">
                <DonutChart
                  title={
                    <>
                      <span className="light">Preview views by</span>
                      <span> location</span>
                    </>
                  }
                  data={mockLocationChartData}
                />
                <DonutChart
                  title={
                    <>
                      <span className="light">Preview views by</span>
                      <span> platform</span>
                    </>
                  }
                  data={mockPlatformChartData}
                />
                <DonutChart
                  title={
                    <>
                      <span className="light">Preview views by</span>
                      <span>Referrer (Business Previews only)</span>
                    </>
                  }
                  data={mockReferrerChartData}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default AnalyticsOverview
