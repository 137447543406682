import AccountBillingForm from 'components/account/AccountBillingForm/AccountBillingForm'
import AccountChangePasswordForm from 'components/account/AccountChangePasswordForm/AccountChangePasswordForm'
import UserProfileForm from 'components/account/UserProfileForm/UserProfileForm'
import OnboardedRoute from 'components/app/OnboardedRoute/OnboardedRoute'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import TabBar from 'components/shared/TabBar/TabBar'
import React from 'react'
import { Switch } from 'react-router-dom'
import useAuth from 'state/useAuth'
import './AccountSettingsPage.scss'

const getTabs = (isSocialAuth: boolean) => [
  { to: '/account', label: 'Profile' },
  { to: '/account/password', label: 'Password', disabled: isSocialAuth },
  { to: '/account/plan', label: 'Plan & Billing' },
]

const AccountSettingsPage: React.FC = () => {
  const { user } = useAuth()
  return (
    <PageFrame
      className="account-settings-page"
      header={
        <>
          <h1>My Profile</h1>
          <TabBar tabs={getTabs(!!user?.socialAuth)} />
        </>
      }
    >
      <Switch>
        <OnboardedRoute exact path="/account" component={UserProfileForm} />
        <OnboardedRoute exact path="/account/password" component={AccountChangePasswordForm} />
        <OnboardedRoute exact path="/account/plan" component={AccountBillingForm} />
      </Switch>
    </PageFrame>
  )
}

export default AccountSettingsPage
