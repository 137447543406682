import React from 'react'

const PlayIcon = () => (
  <svg className="icon" width="70px" height="70px" viewBox="0 0 70 70" style={{ overflow: 'visible' }}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#FFFFFF">
        <circle cx="35" cy="35" r="34" fill="black" stroke="none" />
        <path d="M35,70 C15.6700338,70 0,54.3299662 0,35 C0,15.6700338 15.6700338,0 35,0 C54.3299662,0 70,15.6700338 70,35 C70,54.3299662 54.3299662,70 35,70 Z M45.9261068,35.0372179 L28.7405599,24.148329 L28.7405599,45.9261068 L45.9261068,35.0372179 Z"></path>
      </g>
    </g>
  </svg>
)

export default PlayIcon
