import React from 'react'
import './TickCheckBox.scss'
import { PreviewAlgolia } from 'state/types'

interface Props {
  preview: PreviewAlgolia
  isSelected: boolean
  onSelect: (preview: PreviewAlgolia) => void
  onUnselect: (id: string) => void
}

interface MasterProps {
  isSelected: boolean
  onSelect: () => void
  onUnselect: () => void
}

export const TickCheckBox = function ({ preview, isSelected, onSelect, onUnselect }: Props) {
  const onClick = function () {
    if (!isSelected) {
      onSelect(preview)
    } else {
      onUnselect(preview.id)
    }
  }

  return (
    <div
      className={`tick-check-box ${isSelected ? 'checked' : 'unchecked'}`}
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
    />
  )
}

export const MasterTickCheckBox = function ({ isSelected, onSelect, onUnselect }: MasterProps) {
  const onClick = function () {
    if (!isSelected) {
      onSelect()
    } else {
      onUnselect()
    }
  }

  return (
    <div
      className={`tick-check-box ${isSelected ? 'checked' : 'unchecked'}`}
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
    />
  )
}
