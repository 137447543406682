import BackLink from 'components/shared/BackLink/BackLink'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import SuccessDialog from 'components/shared/SuccessDialog/SuccessDialog'
import { isOnProfessionalOrEnterprisePlanOrAdmin } from 'helpers/subscription'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useAuth from 'state/useAuth'
import useInvitation from 'state/useInvitation'
import './EditInvitationPage.scss'
import useWorkspace from '../../../state/useWorkspace'
import useSubscription from '../../../state/useSubscription'
import InvitationEditor from '../InvitationEditor/InvitationEditor'

const EditInvitationPage = () => {
  const { invitationId, organizationId } = useParams<any>()
  const { organizationsLoaded, setCurrentWorkspaceById } = useWorkspace(true)
  const { isSubscriptionLoading, subscription } = useSubscription(organizationsLoaded)
  const { isLoading, invitation } = useInvitation(invitationId)
  const { push } = useHistory()
  const { user } = useAuth()
  const [isSaveConfirmationDialogShowing, setSaveConfirmationDialogShowing] = useState(false)
  const editingDefaultInvitationDetailsValues = invitation
    ? {
        name: invitation?.name,
        ownerName: invitation?.ownerName,
        industries: invitation?.industries,
        startDate: invitation?.startDate ? new Date(invitation?.startDate) : undefined,
        endDate: invitation?.endDate ? new Date(invitation?.endDate) : undefined,
        instructions: invitation?.instructions,
        sendEmailToInviter: invitation?.sendEmailToInviter,
      }
    : undefined

  const editingDefaultInvitationSuccessPageValues =
    invitation && invitation.invitationSuccessPage
      ? {
          title: invitation.invitationSuccessPage.title,
          message: invitation.invitationSuccessPage.message,
          returnLink: invitation.invitationSuccessPage.returnLink,
          returnLinkTitle: invitation.invitationSuccessPage.returnLinkTitle,
          logo: invitation.invitationSuccessPage.logo,
        }
      : undefined

  useEffect(() => {
    if (isSubscriptionLoading) {
      return
    }

    if (!organizationId) {
      return
    }

    const hasWorkspaceChanged: boolean = setCurrentWorkspaceById(organizationId)
    if (!hasWorkspaceChanged) {
      push('/notFound')
      return
    }

    if (!isOnProfessionalOrEnterprisePlanOrAdmin(subscription, user!)) {
      push('/invitations')
    }
  }, [isSubscriptionLoading, subscription, user])

  return (
    <PageFrame
      className="edit-invitation-page"
      header={
        !isLoading &&
        invitation && (
          <>
            <BackLink to={`/invitation/${invitationId}`}>Back to Invitation</BackLink>
            <h1>
              <span className="edit-label">Edit: </span>
              <span>{invitation?.name}</span>
            </h1>
          </>
        )
      }
    >
      {isLoading ? (
        <LoadingSpinner container expand />
      ) : invitation ? (
        <InvitationEditor
          editingDefaultInvitationDetailsValues={editingDefaultInvitationDetailsValues}
          invitationId={invitationId}
          editingBrandingImageStorageId={invitation.businessLogoImageStorageId}
          editingDefaultInvitationSuccessPageValues={editingDefaultInvitationSuccessPageValues}
          editingVideoCategoryTemplates={invitation.videoCategoryTemplates}
        />
      ) : null}
      <SuccessDialog
        isShowing={isSaveConfirmationDialogShowing}
        text="Changes saved"
        setShowing={setSaveConfirmationDialogShowing}
      />
    </PageFrame>
  )
}

export default EditInvitationPage
