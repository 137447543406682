import React, { useState } from 'react'
import { ContentBlockField } from 'state/types'
import './DetailsInput.scss'
import PeopleInput from '../PeopleInput/PeopleInput'
import { Controller } from 'react-hook-form'
import CrossIcon from '../../../assets/icons/CrossIcon'
import PreviewURLInput from '../../shared/PreviewURLInput/PreviewURLInput'
import useContentBlock from '../../../state/useContentBlock'

type Props = {
  previewId: string
  name: string
  blockIndex: number
  field: ContentBlockField
  register: any
  control: any
  errors?: any
  removeFromList?: () => void
  showManualEntry?: boolean
}

const DetailsInput: React.FC<Props> = ({
  previewId,
  blockIndex,
  field,
  register,
  control,
  errors,
  removeFromList,
  showManualEntry,
}) => {
  const { getValueUpdateStatusRecord } = useContentBlock()
  const valueUpdateStatusRecord = getValueUpdateStatusRecord()

  return (
    <>
      {showManualEntry !== undefined && (
        <div className="details-input">
          <div className="details-title-row">
            {removeFromList && (
              <button type="button" className="remove-button plain" onClick={removeFromList}>
                <CrossIcon />
                <span>Remove</span>
              </button>
            )}
          </div>
          {showManualEntry ? (
            <PeopleInput
              previewId={previewId}
              blockIndex={blockIndex}
              field={field}
              register={register}
              control={control}
              errors={errors}
            />
          ) : (
            <>
              {field.children && (
                <Controller
                  key={field.children[1].id}
                  name={field.children[1].id}
                  as={
                    <PreviewURLInput
                      previewId={previewId}
                      valueUpdateStatus={
                        valueUpdateStatusRecord ? valueUpdateStatusRecord[field.children[1].id] : undefined
                      }
                      defaultValue={field.children[1].value}
                    />
                  }
                  control={control}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default DetailsInput
