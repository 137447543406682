import React, { useEffect, useState } from 'react'
import './CopyableQR.scss'
import QRCode from 'qrcode'
type Props = {
  to: string
}

const CopyableQR: React.FC<Props> = ({ to }) => {
  const fullLink = window.location.origin + to
  const [QRCodeImg, setQRCodeImg] = useState<string>()

  useEffect(() => {
    const generateQRCode = async () => {
      const result = await QRCode.toDataURL(fullLink)
      setQRCodeImg(result)
    }
    generateQRCode()
  })
  return (
    <div className="copyable-qr">
      <img src={QRCodeImg} alt="qr-code" className="qr-code" />

      <b>
        <u>
          <a href={QRCodeImg} download="QRCode.jpg">
            Download this QR code
          </a>
        </u>
      </b>
    </div>
  )
}

export default CopyableQR
