import React from 'react'
import PlayPauseIcon from 'assets/icons/PlayPauseIcon'
import './PlayPauseButton.scss'

type Props = {
  isPlaying: boolean
  togglePlaying: () => void
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const PlayPauseButton = ({ isPlaying, togglePlaying, className, ...rest }: Props) => (
  <button type="button" className="play-pause-button" onClick={togglePlaying} {...rest}>
    <div className="ring">
      <PlayPauseIcon isPlaying={isPlaying} />
    </div>
  </button>
)

export default PlayPauseButton
