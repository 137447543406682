import React from 'react'
import { ContentBlockField } from 'state/types'
import { parseLink, validateLink } from 'helpers/validation'
import { createFullLink } from 'helpers/links'
import './PublishedPreviewInvitationLinks.scss'

type Props = {
  hasLabel: boolean
  fields: ContentBlockField[]
  trackLinkClick: (fieldId: string) => void
}

const PublishedPreviewInvitationLinks: React.FC<Props> = ({ hasLabel, fields, trackLinkClick }) => {
  const handleInvitationLinkClick = (e: any, url: string, target: string, fieldId: string) => {
    trackLinkClick(fieldId)
    if (target === '_blank') return

    e.preventDefault()

    const clientWidth = document.documentElement.clientWidth
    const width = window.innerWidth ? window.innerWidth : clientWidth ? clientWidth : window.screen.width

    if (width < 600) {
      const newWindowConfig = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no`
      if (url.startsWith('https://')) {
        window.open(url, '_blank', newWindowConfig)
      } else window.open('https://' + url, '_blank', newWindowConfig)
      return
    }

    const clientHeight = document.documentElement.clientHeight
    const height = window.innerHeight ? window.innerHeight : clientHeight ? clientHeight : window.screen.height
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY
    const systemZoom = width / window.screen.availWidth
    const windowWidth = clientWidth * 0.5 > 600 ? clientWidth * 0.5 : 600
    const windowHeight = clientHeight * 0.7 > 400 ? clientHeight * 0.7 : 400
    const left = (width - windowWidth) / 2 / systemZoom + dualScreenLeft
    const top = (height - windowHeight) / 2 / systemZoom + dualScreenTop

    const newWindowConfig = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
    if (url.startsWith('https://')) window.open(url, '_blank', newWindowConfig)
    else window.open('https://' + url, '_blank', newWindowConfig)
  }

  return (
    <div className={'website-links' + (hasLabel ? ' has-label' : '')}>
      {fields.map((field, i) => {
        const { text, url, target } = parseLink(field.value)

        const valid = validateLink(field.value)

        const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
          handleInvitationLinkClick(e, url, target, field.id)
        }

        return valid ? (
          <div className="invitation-links" key={i}>
            <a
              className="apply-button button primary"
              href={createFullLink(url)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleOnClick}
            >
              {text}
            </a>
          </div>
        ) : null
      })}
    </div>
  )
}

export default PublishedPreviewInvitationLinks
