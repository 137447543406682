import Dropdown from 'components/shared/Dropdown/Dropdown'
import React, { useEffect, useState } from 'react'
import { InvitedTeamMember } from 'state/types'
import './InviteTeamMembersInput.scss'

type Props = {
  errors?: { message: string; index: number }[]
  index: number
  teamMembers: (InvitedTeamMember | undefined)[]
  errorMessage: string | undefined
  setTeamMembers: (teamMembers: (InvitedTeamMember | undefined)[]) => void
}

const teamMemberTypes = [
  {
    value: 'administrator',
    displayValue: 'Admin',
    placeholder: 'Permissions',
  },
  {
    value: 'owner',
    displayValue: 'Owner',
    placeholder: 'Permissions',
  },
  {
    value: 'team_member',
    displayValue: 'Member',
    placeholder: 'Permissions',
  },
]

const InviteTeamMembersInput: React.FC<Props> = ({ index, setTeamMembers, teamMembers, errorMessage }) => {
  const [role, setRole] = useState<string | undefined>('')
  const [email, setEmail] = useState<string | undefined>('')

  useEffect(() => {
    const currentTeamMember = teamMembers[index]
    if (currentTeamMember !== undefined) {
      setRole(currentTeamMember.role)
      setEmail(currentTeamMember.email)
    } else {
      setRole('')
      setEmail('')
    }
  }, [teamMembers])

  const handleRoleChange = (role: string) => {
    setRole(role)
    teamMembers[index] = { email: teamMembers[index]?.email, role: role }
    setTeamMembers(teamMembers)
  }

  const handleEmailChange = (email: string) => {
    setEmail(email)
    teamMembers[index] = { email: email, role: teamMembers[index]?.role }
    setTeamMembers(teamMembers)
  }

  return (
    <div className="invite-team-members-input">
      {errorMessage && (
        <div className="invite-team-members-input-error-message">
          <label>{errorMessage}</label>
        </div>
      )}
      <div className="invite-team-members-input-fields">
        <div className="email-with-label">
          <div className="label-row">
            <label>Email</label>
          </div>
          <input
            className="email-input"
            value={email}
            spellCheck={false}
            onChange={newValue => handleEmailChange(newValue.target.value)}
          />
        </div>
        <div className="role-with-label">
          <div className="label-row">
            <label>Role</label>
          </div>
          <Dropdown
            className="role-selector"
            selectedValue={role}
            options={teamMemberTypes}
            onChange={newValue => handleRoleChange(newValue)}
          />
        </div>
      </div>
    </div>
  )
}

export default InviteTeamMembersInput
