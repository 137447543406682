import React from 'react'
import InfoPopup from 'components/shared/InfoPopup/InfoPopup'
import { LinkAnalytics } from 'state/types'
import { createFullLink } from 'helpers/links'
import './LinkClicksListMobile.scss'

type Props = {
  data: LinkAnalytics[]
}

const LinkClicksListMobile: React.FC<Props> = ({ data }) => (
  <div className="link-clicks-list-mobile">
    {data.map(({ linkUrl, uniqueUserCount, totalCount }, i) => (
      <div key={i} className="link-clicks-card">
        <a className="url-row" href={createFullLink(linkUrl!)} target="_blank" rel="noopener noreferrer">
          {linkUrl}
        </a>
        <div className="data-row">
          <div className="label">
            <span>By unique user</span>
            <InfoPopup>
              The number of link clicks by distinct individuals, regardless of how often they clicked.
            </InfoPopup>
          </div>
          <div className="value">{uniqueUserCount}</div>
        </div>
        <div className="data-row">
          <div className="label">Total</div>
          <div className="value">{totalCount}</div>
        </div>
      </div>
    ))}
  </div>
)

export default LinkClicksListMobile
