import React, { useEffect, useState } from 'react'
import './PreviewURLInput.scss'
import usePreview from '../../../state/usePreview'
import { PreviewStatus } from '../../../state/types'
import HintDialog from '../../preview/FormListEditor/HintDialog'

type Props = {
  previewId: string
  formLabel?: string
  value?: string
  defaultValue?: string
  onChange?: (value?: string) => void
  valueUpdateStatus?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const PreviewURLInput: React.FC<Props> = ({
  previewId,
  formLabel,
  defaultValue,
  value,
  onChange,
  valueUpdateStatus,
}) => {
  const [inputValue, setInputValue] = useState<string | (string & readonly string[]) | undefined>('')
  const [errorToShow, setErrorToShow] = useState<string | undefined>(undefined)
  const { preview } = usePreview(previewId)
  useEffect(() => {
    if (defaultValue) {
      if (!defaultValue.startsWith(window.location.origin + '/preview/')) {
        defaultValue = window.location.origin + '/preview/' + defaultValue
      }
      setInputValue(defaultValue)
      return
    } else if (value) {
      setInputValue(value)
    }
  }, [])

  useEffect(() => {
    if (valueUpdateStatus === false && preview && preview.status !== 'published') {
      setErrorToShow('Invalid preview link')
    } else {
      setErrorToShow(undefined)
    }
  }, [valueUpdateStatus])

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)
  const onInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.startsWith(window.location.origin + '/preview/')) notifyChange(value)
    else setErrorToShow('Invalid preview link')
  }
  const notifyChange = (v: string) => onChange?.(v)

  return (
    <div className="form-input-container">
      <div className="form-input-label-row">
        {formLabel && <label className="form-input-label">{formLabel}</label>}
        {errorToShow && <div className="form-input-validation-error">{errorToShow}</div>}
      </div>
      <div>
        <input value={inputValue} onChange={onValueChange} onBlur={onInputBlur} className={'form-input'} />
      </div>
    </div>
  )
}

export default PreviewURLInput
