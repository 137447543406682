import React, { useEffect, useState } from 'react'
import './LogoUploader.scss'
import SwitchButton from 'components/shared/SwitchButton/SwitchButton'
import FormInput from 'components/shared/FormInput/FormInput'
import LogoSelector from './../LogoSelector/LogoSelector'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import { Link } from 'react-router-dom'
import useOrganizationBrandSetting from 'state/useOrganizationBrandSetting'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import SuccessDialog from 'components/shared/SuccessDialog/SuccessDialog'
import useBranding from '../../../../state/useBranding'
import * as organizationBrandSettingService from 'services/organizationBrandSettingService'
import useWorkspace from '../../../../state/useWorkspace'
import useBrandTheme from '../../../../state/useBrandTheme'


type Props = {
  planSwitchDisable:boolean;
  plan: boolean;
  onSubmit: any;
  type?:String
};
const IntegrationPageDetail: React.FC<Props> = ({ plan,planSwitchDisable , onSubmit, type}) => {
  const {
    organizationBrandSetting,
    brandUrl,
    isDataLoaded,
    isUpdatingBrandUrl,
    isUpdatingPageTitle,
    previewReferenceDisabled,
    cloudinaryStorageIdData,
    showUpdateURLPopup,
    faviconCloudinaryStorageIdData,
    setBrandUrl,
    updatePreviewReferenceDisabledByOrganization,
    updateOrganizationPageTitleByOrganization,
    updateOrganizationBrandUrlByOrganization,
    updateOrganizationBrandLogo,
    updateOrganizationBrandLogoFavicon,
    removeOrganizationLogoImage,
    removeOrganizationLogoFaviconImage,
    setShowUpdateURLPopup,
    previewDisableData
  } = useBranding()

  const { currentWorkspace, organizationsLoaded } = useWorkspace(true)
  const [popupMessage, setPopupMessage] = useState()
  const { brandModeData, setBrandModeData } = useBrandTheme('main')
  const updateBrandUrl = (e: any) => {
    setPopupMessage("URL updated successfully!")
    e.preventDefault()
    if (brandUrl) updateOrganizationBrandUrlByOrganization(brandUrl)
  }

  const updatePageTitle = (e: any) => {
    const { value } = e.target
    e.preventDefault()
    onSubmit(value)
  }

  useEffect(() => {
    const getOrganizationBrandSettingByOrganization = async () => {
      if (currentWorkspace?.id) {
        const { data, ok } = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(
          currentWorkspace.id
        );
        setBrandUrl(data?.brandUrl);
      }
    };

    getOrganizationBrandSettingByOrganization();
  }, [currentWorkspace]);


  return (
    <div className="logo-uploader-settings">
      {isDataLoaded ? (
        <>
          <div className="toggle-container">
            <div className="bold-label">
              Hide PreviewMe branding
            </div>
            <div className="hide-brand-settings">
              <div
                className={
                  'hint-popup-container hint-popup-trigger' + (planSwitchDisable ? ' ' : ' disabled-popup')
                }
              >
                <SwitchButton
                  checked={previewDisableData}
                  onChange={updatePreviewReferenceDisabledByOrganization}
                  disabled={planSwitchDisable}
                  handleLocally={true}
                ></SwitchButton>
                <div className="previewme-branding-hint-popup">
                  <div className="hint-popup">
                    <div className="left-arrow-bg"></div>
                    <div className="left-arrow"></div>
                    <div className="hint-title">Plan upgrade required</div>
                    <p className="hint-text">
                      If you’d like to use this feature you need to upgrade your current plan.
                      <Link className="hint-link" to="/plan/select?from=/organization/settings/brand">
                        Upgrade now.
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bold-label">
            Logo File
            <div className="personalised-logo info-icon hint-popup-trigger">
              i
              <div className="hint-popup-container">
                <div className="hint-popup">
                  <div className="left-arrow-bg"></div>
                  <div className="left-arrow"></div>
                  <div className="hint-title">Your Logo</div>
                  <p className="hint-text">
                    By uploading your own brand logo any Previews and Invitations created will feature your own
                    branding, rather than the PreviewMe logo. <br />
                    <br /> The logo will be displayed in landscape mode. Height will be capped at 40px and will scale
                    accordingly. We recommend a maximum width of 190px.
                  </p>

                  <div className="example-section">
                    <div className="first-line">
                      <div className="example-logo">
                        <div className="workspace-logo"></div>
                        <div className="workspace-name">Example Logo</div>
                      </div>
                      <div className="ruler vertical"></div>
                      <span className="vertical-metter">40px</span>
                    </div>
                    <div className="second-line">
                      <div className="ruler horizontal"></div>
                      <span className="horizontal-metter">190px</span>
                    </div>
                  </div>

                  <div className="hint-title">Your URL Link</div>
                  <p className="hint-text">
                    If you add a URL link for your logo, that URL will open in a new tab if a user clicks on the logo.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="logo-uploader">
            <div
              className={'hint-popup-container hint-popup-trigger' + (plan ? ' ' : ' disabled-popup')}
            >
              <LogoSelector
                type="logo"
                value={cloudinaryStorageIdData}
                canDelete
                warnBeforeDeleting={false}
                uploadImage={updateOrganizationBrandLogo}
                deleteImage={removeOrganizationLogoImage}
                disabled={plan}
              />
              <div className="image-uploader-hint-popup">
                <div className="hint-popup">
                  <div className="left-arrow-bg"></div>
                  <div className="left-arrow"></div>
                  <div className="hint-title">Plan upgrade required</div>
                  <p className="hint-text">
                    If you’d like to use this feature you need to upgrade your current plan.
                    <Link className="hint-link" to="/plan/select?from=/organization/settings/brand">
                      Upgrade now.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={'hint-popup-container hint-popup-trigger' + (plan ? ' ' : ' disabled-popup')}>
            <FormInput
              name="brandUrl"
              label="URL link for your logo"
              type="text"
              value={brandUrl}
              placeholder="eg. 1234567890"
              onChange={e => {
                setBrandUrl(e.target.value)
              }}
              disabled={plan}
            />
            <div className="button-container modify-margin">
              <LoadingButton
                type="submit"
                isLoading={isUpdatingBrandUrl}
                disabled={!!(plan || brandUrl === organizationBrandSetting?.brandUrl)}
                className={
                  'primary ' +
                  (!!(plan || brandUrl === organizationBrandSetting?.brandUrl) ? ' disabled' : '')
                }
                onClick={updateBrandUrl}
              >
                SAVE
              </LoadingButton>
            </div>
            <div className="input-hint-popup">
              <div className="hint-popup">
                <div className="left-arrow-bg"></div>
                <div className="left-arrow"></div>
                <div className="hint-title">Plan upgrade required</div>
                <p className="hint-text">
                  If you’d like to use this feature you need to upgrade your current plan.
                  <Link className="hint-link" to="/plan/select?from=/organization/settings/brand">
                    Upgrade now.
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="bold-label">
            Favicon
            <div className="personalised-logo info-icon hint-popup-trigger favicon-logo">
              i
              <div className="hint-popup-container">
                <div className="hint-popup">
                  <div className="left-arrow-bg"></div>
                  <div className="left-arrow"></div>
                  <div className="hint-title">Favicon specifications</div>
                  <p className="hint-text">
                    A favicon is a small icon associated with a website, web page, or web application. It’s displayed within the browser tabs and bookmarks bar.
                  </p>
                  <div className="favicon-example-section">
                    <img src="/favicon-example.png" alt="favicon example"/>
                  </div>
                  <p className="hint-text">
                    Acceptable favicon formats are ICO, PNG, and SVG. Your favicon needs to be square. The recommended sizes are 16x16, 32x32, or 48x48 pixels.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={'hint-popup-container hint-popup-trigger' + (plan ? ' ' : ' disabled-popup')}>

            <div className="label">Favicon file</div>
            <div className="logo-uploader">
              <div className={'hint-popup-trigger' + (plan ? ' ' : ' disabled-popup')}>
                <LogoSelector
                  value={faviconCloudinaryStorageIdData}
                  canDelete
                  type="favicon"
                  warnBeforeDeleting={false}
                  uploadImage={updateOrganizationBrandLogoFavicon}
                  deleteImage={removeOrganizationLogoFaviconImage}
                  disabled={plan}
                />
                <div className="image-uploader-hint-popup">
                  <div className="hint-popup">
                    <div className="left-arrow-bg"></div>
                    <div className="left-arrow"></div>
                    <div className="hint-title">Plan upgrade required</div>
                    <p className="hint-text">
                      If you’d like to use this feature you need to upgrade your current plan.
                      <Link className="hint-link" to="/plan/select?from=/organization/settings/brand">
                        Upgrade now.
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={'hint-popup-container hint-popup-trigger' + (plan ? ' ' : ' disabled-popup')}>
              <FormInput
                name="pageTitle"
                label="Browser Tab Title"
                type="pageTitle"
                value={brandModeData.pageTitle}
                placeholder="eg - your business name"
                onChange={updatePageTitle}
                disabled={plan}
              />
              <div className="input-hint-popup">
                <div className="hint-popup">
                  <div className="left-arrow-bg"></div>
                  <div className="left-arrow"></div>
                  <div className="hint-title">Plan upgrade required</div>
                  <p className="hint-text">
                    If you’d like to use this feature you need to upgrade your current plan.
                    <Link className="hint-link" to="/plan/select?from=/organization/settings/brand">
                      Upgrade now.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <SuccessDialog
            isShowing={showUpdateURLPopup}
            text={popupMessage}
            setShowing={setShowUpdateURLPopup}
          />
        </>
      ) : (
        <>
          <LoadingSpinner expand />
        </>
      )}
    </div>
  )
}

export default IntegrationPageDetail
