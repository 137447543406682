import React, { useState } from 'react'
import LazyVideoPlayer from 'components/video/LazyVideoPlayer/LazyVideoPlayer'
import Dropdown from 'components/shared/Dropdown/Dropdown'
import { FlatVideoAssets } from 'state/types'
import './VideoAssetsListMobile.scss'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'

type Props = {
  rows: FlatVideoAssets[]
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  onAddClick: (videoChapterId: string) => Promise<void>
}
const sortOptions = [
  { value: 'title-true', displayValue: 'Chapter Title A-Z' },
  { value: 'title-false', displayValue: 'Chapter Title Z-A' },
  { value: 'previewName-true', displayValue: 'Preview Title A-Z' },
  { value: 'previewName-false', displayValue: 'Preview Title Z-A' },
  { value: 'dateCreated-false', displayValue: 'Newest first' },
  { value: 'dateCreated-true', displayValue: 'Oldest first' },
]

const VideoAssetsListMobile: React.FC<Props> = ({
  rows,
  sortingBy,
  sortingAsc,
  onSortingByChange,
  onSortingAscChange,
  onAddClick,
}) => {
  const selectedValue = sortingBy + '-' + sortingAsc
  const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({});

  const onDropdownChange = (newSelection: string) => {
    const s = newSelection.split('-')
    onSortingByChange?.(s[0])
    onSortingAscChange?.(s[1] === 'true')
  }

  const handleAddClick = async (videoChapterId: string) => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [videoChapterId]: true,
    }));
    try {
      await onAddClick(videoChapterId);
    } finally {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [videoChapterId]: false,
      }));
    }
  };

  return (
    <div className="video-assets-list-mobile">
      <Dropdown
        className="sort-dropdown"
        selectedValue={selectedValue}
        options={sortOptions}
        onChange={onDropdownChange}
        renderSelectedOption={(_, displayValue) => (
          <div className="sort-dropdown-selected-option">
            <span className="sort-by-label">Sort by:</span>
            <span>{displayValue}</span>
          </div>
        )}
      />
      {rows.map(
        ({
          videoChapterId,
          videoChapterName,
          videoTime,
          previewName,
          dateCreated,
          videoUrl,
          audioOnly,
          profileImageStorageId,
        }) => (
          <div key={videoChapterId} className="video-assets-list-card">
            <div className="title-row">
              <span className="video-chapter-name">{videoChapterName}</span>
              <span className="video-chapter-duration">{videoTime ? '[' + videoTime + ']' : undefined}</span>
            </div>
            <div className="preview-name">{previewName}</div>
            <div className="date-created">{dateCreated}</div>
            <LazyVideoPlayer src={videoUrl} audioOnly={audioOnly} profileImageStorageId={profileImageStorageId} />
            <button className="primary outline" onClick={() => handleAddClick(videoChapterId)} disabled={loadingMap[videoChapterId]}>
              {loadingMap[videoChapterId] ? <LoadingSpinner container />  : 'Add'}
            </button>
          </div>
        )
      )}
    </div>
  )
}

export default VideoAssetsListMobile
