import React, { useMemo, useState } from 'react'
import './Pagination.scss'
import { DEFAULT_TABLE_VIEW_ITEMS_PER_PAGE, tableViewTotalItemsPerPage } from 'helpers/constant'

type Props = {
  className?: string
  currentPage: number
  totalCount: number
  pageSize: number
  paginationRange: any
  onPageChange: (page: number) => void
  onChangeTotalItemsPerPage: (page: number) => void
  disableItemsPerPageSettings?: boolean
}

const Pagination: React.FC<Props> = ({
  totalCount,
  currentPage,
  className,
  pageSize,
  onPageChange,
  onChangeTotalItemsPerPage,
  paginationRange,
  disableItemsPerPageSettings
}) => {

  const handleNext = () => {
    onPageChange(currentPage + 1)
  }

  const handlePrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  const handleChangeTotalItemsPerPage = (e: React.FormEvent<HTMLSelectElement>) => {
    onChangeTotalItemsPerPage(+e.currentTarget.value)
  }

  return (
    <ul className={`pagination-container ${className}`}>
      <li className={`pagination-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={handlePrevious}>
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber: any, index: number) => {
        if (typeof pageNumber === 'string') {
          return <li key={`dot-${index}`} className="pagination-item dots">&#8230;</li>
        }
        return (
          <li
            key={pageNumber}
            className={`pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      })}

      <li className={`pagination-item ${currentPage === lastPage ? 'disabled' : ''}`} onClick={handleNext}>
        <div className="arrow right" />
      </li>
      {!disableItemsPerPageSettings &&
        <li>
        <select className='item-per-page' onChange={handleChangeTotalItemsPerPage} defaultValue={pageSize}>
          {tableViewTotalItemsPerPage.map((item, index) => (
            <option key={index} value={item}>{item} / page</option>
          ))}
        </select>
      </li>}
    </ul>
  )
}

export default Pagination
