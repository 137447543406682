import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as previewService from 'services/previewService'
import { Preview } from 'state/types'
import './CreateInvitationPage.scss'
import InvitationEditor from '../InvitationEditor/InvitationEditor'

const CreateInvitationPage = () => {
  const [template, setTemplate] = useState<Preview | undefined>()
  const [isLoading, setLoading] = useState(true)
  const { templateId } = useParams<any>()

  useEffect(() => {
    const loadTemplate = async () => {
      if (templateId) {
        setLoading(true)
        const { ok, data } = await previewService.getTemplateSummary(templateId)
        setLoading(false)
        if (ok && data) {
          setTemplate(data)
        }
      }
    }
    loadTemplate()
  }, [templateId])

  return (
    <FullScreenPageFrame
      customBackground
      showTopBar
      topBarText={template?.name}
      className="create-invitation-page"
      cancelLink="/invitations"
      prevLink={`/invitations/example/${templateId}`}
      notFound={!isLoading && !template}
    >
      <InvitationEditor templateId={templateId} />
    </FullScreenPageFrame>
  )
}

export default CreateInvitationPage
