import React from 'react'
import './PlayPauseIcon.scss'

type Props = {
  isPlaying: boolean
}

const PlayPauseIcon: React.FC<Props> = ({ isPlaying }) => (
  <svg className={'icon play-pause-icon' + (isPlaying ? ' playing' : '')} viewBox="0 0 24 24" width="24" height="24">
    <path
      d={
        isPlaying
          ? 'M7,6L10,6L10,18L7,18L7,6 M13,6L16,6L16,18L13,18L13,6'
          : 'M7,6L12,9L12,15L7,18L7,6 M12,9L17,12L17,12L12,15L12,9'
      }
    />
  </svg>
)

export default PlayPauseIcon
