import React from 'react'
import './VideoAudioSwitch.scss'
import RecordIcon from '../../../assets/icons/RecordIcon'
import MicrophoneIcon from '../../../assets/icons/MicrophoneIcon'
import { RecordingType } from '../../../state/types'
import Tooltip from '../../shared/Tooltip/Tooltip'

/**
 * Component that allows the user to switch between recording video (with camera and mic), and audio (just mic).
 * @constructor
 */

interface Props {
  activeRecordingType: RecordingType
  onChange: (newRecordingType: RecordingType) => void
  audioOnly?: boolean
  videoOnly?: boolean
}

const VideoAudioSwitch = function ({ activeRecordingType, onChange, audioOnly, videoOnly }: Props) {
  const disabled = videoOnly || audioOnly
  const tooltipContent = audioOnly
    ? `This chapter must be in audio format. The 'video' option has been disabled`
    : videoOnly
    ? `This chapter must be in video format. The 'audio' option has been disabled`
    : 'You can switch between recording video and audio'

  const toggleRecordingType = function (newType: RecordingType) {
    if (disabled || activeRecordingType === newType) return
    onChange(newType)
  }

  return (
    <div className="video-audio-switch-container">
      <Tooltip content={tooltipContent}>
        <div className="video-audio-switch">
          <div
            className={`box record ${activeRecordingType === 'video' ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
            onClick={() => toggleRecordingType('video')}
          >
            <RecordIcon />
          </div>
          <div
            className={`box audio ${activeRecordingType === 'audio' ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
            onClick={() => toggleRecordingType('audio')}
          >
            <MicrophoneIcon
              grey={activeRecordingType !== 'audio' && disabled}
              white={activeRecordingType === 'audio'}
            />
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default VideoAudioSwitch
