import BackToEditorIcon from 'assets/icons/BackToEditorIcon'
import BusinessLogoEditor from 'components/preview//BusinessLogoEditor/BusinessLogoEditor'
import PublishedPreviewMobileToolbar from 'components/preview/PublishedPreviewMobileToolbar/PublishedPreviewMobileToolbar'
import PublishReviewDialog from 'components/preview/PublishReviewDialog/PublishReviewDialog'
import SharingDialog from 'components/preview/SharingDialog/SharingDialog'
import ThemeSelector from 'components/preview/ThemeSelector/ThemeSelector'
import UnpublishConfirmationDialog from 'components/preview/UnpublishConfirmationDialog/UnpublishConfirmationDialog'
import { PreviewCategory, PreviewMeObject } from 'helpers/enum'
import { getEditPreviewLink, getPublishedPreviewBackLink } from 'helpers/navigation'
import { isOnPaymentPlanOrAdmin } from 'helpers/subscription'
import { useUrlParams } from 'helpers/urlParams'
import { checkPublishable } from 'helpers/validation'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as previewService from 'services/previewService'
import { Preview } from 'state/types'
import useAuth from 'state/useAuth'
import LimitReachedDialog from '../LimitReachedDialog/LimitReachedDialog'
import './PublishedPreviewToolbar.scss'
import useSubscription from '../../../state/useSubscription'
import * as organizationBrandSettingService from '../../../services/organizationBrandSettingService'
import useWorkspace from '../../../state/useWorkspace'

type Props = {
  preview: Preview
  publish: () => void
  unpublish: () => Promise<void>
  updateTheme: () => void
  updateBusinessLogoImage: (blob: Blob, onProgressChange: (progress: number) => void) => Promise<void>
  removeBusinessLogoImage: () => void
  planType:string
}

const PublishedPreviewToolbar: React.FC<Props> = ({
  preview,
  publish,
  unpublish,
  updateTheme,
  updateBusinessLogoImage,
  removeBusinessLogoImage,
  planType
}) => {
  const [isShareDialogShowing, setShareDialogShowing] = useState(false)
  const [isSuccessDialogShowing, setSuccessDialogShowing] = useState(false)
  const [isLimitReachedDialogShowing, setLimitReachedDialogShowing] = useState(false)
  const [isUnpublishDialogShowing, setUnpublishDialogShowing] = useState(false)
  const [isThemeSelectorShowing, setThemeSelectorShowing] = useState(false)
  const [isBusinessLogoEditorShowing, setBusinessLogoEditorShowing] = useState(false)
  const [publishReviewLink, setPublishReviewLink] = useState('')
  const isPublished = preview.status === 'published'
  const isArchived = preview.archived
  const isSuspended = preview.suspended
  const { from } = useUrlParams('from')
  const { user, subscription } = useAuth()
  useSubscription(true)
  const { push } = useHistory()
  const backLink = getPublishedPreviewBackLink(preview.id, from)
  const backLinkText = from === 'analytics' ? 'Back to analytics' : 'Back to Dashboard'
  const editLink = getEditPreviewLink(preview.id, from)
  const isClosed = preview?.invitation?.closed || preview?.closedTemplate
  const closingDate = preview?.invitation?.endDate || preview?.endDate
  const allowThemeUpdate = !preview?.invitation && !preview.archived
  const allowCustomisation = !preview.invitation
  const [isDataLoaded, setDataLoaded] = useState(false)
  const { organizationsLoaded, currentWorkspace } = useWorkspace(true)
  const [brandModeDataGet, setBrandModeDataGet] = useState('')

  useEffect(() => {
    (async () => {
      try {
        await getOrganizationBrandSettingByOrganization();
      } catch (error) {
        console.error("Error fetching organization brand setting:", error);
      }
    })();


  }, [brandModeDataGet]);

  const getOrganizationBrandSettingByOrganization = async () => {
    if (currentWorkspace?.id) {
      setDataLoaded(false)
      const { data, ok } = await organizationBrandSettingService.getOrganizationBrandSettingByOrganization(
        currentWorkspace.id
      )
      setDataLoaded(true)
      setBrandModeDataGet(data?.brandMode ?? '')
      return ok
    }
  }

  const onTogglePublishClick = async () => {
    if (isPublished) {
      setUnpublishDialogShowing(true)
    } else {
      if ((await showLimitReached())) {
        setLimitReachedDialogShowing(true)
      } else {
        const { isPublishable, reviewLink } = checkPublishable(preview)
        if (isPublishable) {
          publish()
          setSuccessDialogShowing(true)
        } else if (reviewLink) {
          setPublishReviewLink(reviewLink)
        }
      }
    }
  }

  const showLimitReached = async () => {
    const { ok, data } = await previewService.getPreviewCategoryCount();

    if (ok && subscription?.plan?.name === 'Basic' && subscription.plan.status === 'active') {
      const threshold = subscription.plan.type === 'personal' ? 3 : 4;

      const previewCount = subscription.plan.type === 'personal'
        ? data?.individualPreviewsCount ?? 0
        : data?.businessPreviewsCount ?? 0;

      if (previewCount > threshold) {
        return true;
      }
    }

    return false;
  };


  return (
    <>
      <nav className={'published-preview-toolbar' + (isPublished ? ' published' : isSuspended ? ' suspended' : '')}>
        <div className="toolbar-left-colored-panel" />
        <div className="toolbar-content">
          <div className="left-content">
            <div className="preview-status">{isPublished ? 'Published' : isSuspended ? 'Suspended' : 'Draft'}</div>

            {!isArchived && (
              <Link className="toolbar-option edit-button button" to={editLink}>
                Editor
              </Link>
            )}
            {!preview.invitation && !isArchived && (
              <ThemeSelector
                isShowing={isThemeSelectorShowing}
                planType={planType}
                theme={preview.theme}
                onThemeChange={updateTheme}
                setShowing={setThemeSelectorShowing}
                brandModeDataGet={brandModeDataGet}
              />
            )}
            {allowCustomisation && (
              <BusinessLogoEditor
                previewBusinessLogoImageStorageId={preview.businessLogoImageStorageId}
                isBusinessLogoEditorShowing={isBusinessLogoEditorShowing}
                setBusinessLogoEditorShowing={setBusinessLogoEditorShowing}
                updateBusinessLogoImage={updateBusinessLogoImage}
                removeBusinessLogoImage={removeBusinessLogoImage}
              />
            )}
            {isPublished && !isArchived && (
              <button className="toolbar-option share-button" onClick={() => setShareDialogShowing(true)}>
                Share
              </button>
            )}
            {!isArchived &&
              (!isPublished ? (
                <button className="toolbar-option publish-button" onClick={onTogglePublishClick}>
                  Publish
                </button>
              ) : (
                <button className="toolbar-option unpublish-button" onClick={onTogglePublishClick}>
                  Unpublish
                </button>
              ))}
            <Link className="toolbar-option" to={backLink}>
              <span className={'back-link-text'}>{backLinkText}</span>
              <BackToEditorIcon />
            </Link>
          </div>
        </div>
        <div className="toolbar-right-panel" />
        <UnpublishConfirmationDialog
          isShowing={isUnpublishDialogShowing}
          isClosed={isClosed}
          endDate={closingDate}
          invitationOwner={preview?.invitation?.ownerName}
          onClose={() => setUnpublishDialogShowing(false)}
          onOk={unpublish}
        />
        <SharingDialog
          id={preview.id}
          onPublish
          invitationOrgName={preview.invitation?.ownerName}
          invitationSuccessPage={preview.invitationSuccessPage}
          isShowing={isSuccessDialogShowing}
          onClose={() => setSuccessDialogShowing(false)}
          object={PreviewMeObject.Preview}
        />
        <PublishReviewDialog
          isShowing={!!publishReviewLink}
          onClose={() => setPublishReviewLink('')}
          onOk={() => push(publishReviewLink)}
        />
        <SharingDialog
          id={preview.id}
          isShowing={isShareDialogShowing}
          onClose={() => setShareDialogShowing(false)}
          object={PreviewMeObject.Preview}
        />
        <LimitReachedDialog
          showSaveAsDraft={true}
          category={preview.previewCategory!}
          isShowing={isLimitReachedDialogShowing}
          onClose={() => setLimitReachedDialogShowing(false)}
        />
      </nav>
      <PublishedPreviewMobileToolbar
        isPublished={isPublished}
        isArchived={isArchived}
        isSuspended={isSuspended}
        backLink={backLink}
        backLinkText={backLinkText}
        editLink={editLink}
        theme={preview.theme || 'white'}
        businessLogoImageStorageId={preview.businessLogoImageStorageId}
        updateBusinessLogoImage={updateBusinessLogoImage}
        removeBusinessLogoImage={removeBusinessLogoImage}
        onTogglePublishClick={onTogglePublishClick}
        onShareClick={() => setShareDialogShowing(true)}
        updateTheme={updateTheme}
        allowThemeUpdate={allowThemeUpdate}
        allowCustomisation={allowCustomisation}
        planType={planType}
      />
    </>
  )
}

export default PublishedPreviewToolbar
