import DialogCloseIcon from 'assets/icons/DialogCloseIcon'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import FileSelectorButton from 'components/shared/FileSelectorButton/FileSelectorButton'
import ProgressBar from 'components/shared/ProgressBar/ProgressBar'
import { imageFileSizeLimitInBytes, imageFileSizeLimitInMb } from 'helpers/media'
import React, { useState } from 'react'
import ProfileImage from '../ProfileImage/ProfileImage'
import './ProfileImageSelector.scss'
import { getProfileImageUrl } from '../../../helpers/cloudinary'

type Props = {
  value?: string
  localValue?: Blob
  name?: string
  canDelete: boolean
  warnBeforeDeleting: boolean
  deleteConfirmationText?: string
  deleteForbiddenText?: string
  uploadButtonText?: string
  showUploadingLabel?: boolean
  showUseProfileImageButton?: boolean
  uploadImage?: (blob: Blob, onProgressChange: (progress: number) => void) => Promise<void>
  uploadImageLocally?: (blob: Blob) => void
  deleteImage: () => void
  useProfileImage?: () => void
}

const ProfileImageSelector: React.FC<Props> = ({
  value,
  localValue,
  name,
  canDelete,
  warnBeforeDeleting,
  deleteConfirmationText,
  deleteForbiddenText,
  uploadButtonText,
  showUploadingLabel,
  showUseProfileImageButton,
  uploadImage,
  uploadImageLocally,
  deleteImage,
  useProfileImage,
}) => {
  const [isUploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isDeleteDialogShowing, setDeleteDialogShowing] = useState(false)
  const [isDeleteErrorDialogShowing, setDeleteErrorDialogShowing] = useState(false)
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)

  const onSelectedFileChange = async (file: File | null) => {
    if (file) {
      if (file.size > imageFileSizeLimitInBytes) {
        setFileSizeErrorDialogShowing(true)
      } else if (uploadImage) {
        setUploading(true)
        setProgress(0)
        await uploadImage(file, setProgress)
        setUploading(false)
      } else if (uploadImageLocally) {
        setUploading(true)
        await uploadImageLocally(file)
        setUploading(false)
      }
    }
  }

  const onDeleteImageClick = () => {
    if (!canDelete) {
      setDeleteErrorDialogShowing(true)
    } else if (warnBeforeDeleting) {
      setDeleteDialogShowing(true)
    } else {
      deleteImage()
    }
  }

  return (
    <>
      <div className="profile-image-selector">
        <div className="image-container">
          {localValue ? (
            <img className={'business-logo-image'} src={localValue ? URL.createObjectURL(localValue) : undefined} />
          ) : (
            <ProfileImage
              imageUrl={value ? getProfileImageUrl(value) : undefined}
              cssClassName={'uploading-image'}
              name={name}
            />
          )}
          {(value || localValue) && (
            <button type="button" className="close-button plain" onClick={onDeleteImageClick}>
              <DialogCloseIcon />
            </button>
          )}
        </div>
        {!isUploading ? (
          <div className="upload-text-block">
            <FileSelectorButton
              accept="image/*"
              onSelectedFileChange={onSelectedFileChange}
              className="upload-image-button link"
            >
              {`${value || localValue ? 'Replace' : 'Upload'} ${uploadButtonText || 'image'}`}
            </FileSelectorButton>
            <span className="upload-text-small">Max file size is {imageFileSizeLimitInMb} MB</span>
            {showUseProfileImageButton && (
              <>
                <div className="separator">
                  <div className="separator-line" />
                  <div className="separator-text">Or</div>
                  <div className="separator-line" />
                </div>
                <button className="use-profile-image-button link" onClick={useProfileImage}>
                  Use profile image
                </button>
              </>
            )}
          </div>
        ) : (
          <div className="uploading-buttons">
            {showUploadingLabel && <div className="uploading-label">Uploading...</div>}
            <ProgressBar progress={progress} />
          </div>
        )}
      </div>
      <ConfirmationDialog
        isShowing={isDeleteDialogShowing}
        cancelButtonText="No, cancel that"
        okButtonText="Yes, delete this image"
        title="Heads up!"
        isWarning
        onClose={() => setDeleteDialogShowing(false)}
        onOk={deleteImage}
      >
        {deleteConfirmationText}
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isDeleteErrorDialogShowing}
        okButtonText="Ok"
        title="Profile image is required"
        onClose={() => setDeleteErrorDialogShowing(false)}
      >
        {deleteForbiddenText}
      </ConfirmationDialog>
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Image too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
      >
        {`This image is too large. Please choose an image file smaller than ${imageFileSizeLimitInMb} MB.`}
      </ConfirmationDialog>
    </>
  )
}

export default ProfileImageSelector
