import React from 'react'
import TrendArrowUp from 'assets/icons/TrendArrowUp'
import TrendArrowLevel from 'assets/icons/TrendArrowLevel'
import TrendArrowDown from 'assets/icons/TrendArrowDown'
import './AnalyticsSummaryCard.scss'

type Props = {
  title: string
  className: string
  value?: number
  trendPercentage?: number
  icon: React.ReactNode
}

const AnalyticsSummaryCard: React.FC<Props> = ({ title, className, value, trendPercentage, icon }) => {
  return (
    <div className={'analytics-summary-card ' + className}>
      <div className="title">{title}</div>
      <div className="title-spacer" />
      <div className="value">{value !== undefined ? value : ''}</div>
      <div className="value-spacer" />

      <div className="footer-container">
        {icon}
        {trendPercentage && (
          <>
            <div className="trend-percentage">
              {trendPercentage > 0 ? (
                <>
                  <TrendArrowUp />
                  <span className="trend-value trend-up">+{trendPercentage}%</span>
                </>
              ) : trendPercentage < 0 ? (
                <>
                  <TrendArrowDown />
                  <span className="trend-value trend-down">{trendPercentage}%</span>
                </>
              ) : (
                <>
                  <TrendArrowLevel />
                  <span className="trend-value trend-level">No change</span>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AnalyticsSummaryCard
