import { Switch } from '@material-ui/core'
import React, { useState } from 'react'
import './SwitchButton.scss'

type Props = {
  className?: string
  onChange?: (newState: boolean) => void;
  checked?: boolean
  disabled?: boolean
  handleLocally?: boolean //handle local state if true
  id?: string
}

const SwitchButton: React.FC<Props> = ({ onChange, checked = false, disabled = false, id }) => {
  const handleOnClick = () => {
    if (onChange && !disabled) {
      onChange(!checked);
    }
  };

  return (
    <Switch
      id={id}
      className={'switch-button' + (disabled ? '-disabled' : '')}
      onClick={handleOnClick}
      disabled={disabled}
      checked={checked}
    />
  )
}

export default SwitchButton
