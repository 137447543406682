import { getApi, getApiWithAuthorization } from './api'
import { VideoChapter, VideoSuite, VideoWatermark } from '../state/types'
import * as videoCache from '../helpers/videoCache'
import { fetchVideoBlob } from '../helpers/cloudinary'
import { getWorkspaceOrganizationId } from '../helpers/organization'


export const getWatermarkData = async (organizationId: string | undefined, videoId: string | undefined) => {
  const authApi = await getApiWithAuthorization()
  return authApi.get(
    `/api/videoSuite/${organizationId}/videoId/${videoId}`
  )
}

export const updateWatermarkProperties = async (organizationId: string | undefined, id: string | undefined, watermarkPayload: any) => {
  const authApi = await getApiWithAuthorization()
  return authApi.patch(
    `/api/videoSuite/${organizationId}/update/${id}`, watermarkPayload
  )

}

export const removeWatermarkProperties = async (organizationId: string | undefined, id: string | undefined, watermarkPayload: any) => {
  const authApi = await getApiWithAuthorization()
  return authApi.patch(
    `/api/videoSuite/${organizationId}/update/${id}`, watermarkPayload
  )
}