import React, { useState } from 'react';
import Table from 'components/shared/Table/Table'
import LazyVideoPlayer from 'components/video/LazyVideoPlayer/LazyVideoPlayer'
import { FlatVideoAssets } from 'state/types'
import './VideoAssetsTable.scss'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner';
import { useHistory, useParams } from 'react-router-dom'
import { parseIndexParam } from '../../../helpers/urlParams'

type Props = {
  rows: FlatVideoAssets[]
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  onAddClick: (videoChapterId: string) => Promise<void>
}

const VideoAssetsTable: React.FC<Props> = ({
  rows,
  sortingBy,
  sortingAsc,
  onSortingByChange,
  onSortingAscChange,
  onAddClick,
}) => {
  const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({});
  const { previewId, index, fieldIndex } = useParams<any>()
  const chapterIndex = parseIndexParam(fieldIndex) || 0
  const videoTrimEditorPath = `/edit/${previewId}/video/${index}/trim/${chapterIndex}/true`
  const history = useHistory()
  const handleAddClick = async (videoChapterId: string) => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [videoChapterId]: true,
    }));
    try {
      await onAddClick(videoChapterId);
    } finally {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [videoChapterId]: false,
      }));
    }
    history.push(videoTrimEditorPath)
  };

  return (
  <Table
    className="video-assets-table"
    columns={[
      {
        name: 'videoPlayer',
        className: 'video-player-cell',
        render: ({ videoUrl, audioOnly, profileImageStorageId }) => {
          return (
            <LazyVideoPlayer
              src={videoUrl}
              noAutoLoad
              audioOnly={audioOnly}
              profileImageStorageId={profileImageStorageId}
            />
          )
        },
      },
      {
        name: 'title',
        label: 'Chapter Title',
        className: 'asset-title',
        render: ({ videoChapterName, videoTime }) => {
          return (
            <>
              <div className="video-chapter-title">{videoChapterName}</div>
              <div className="video-chapter-duration">{videoTime}</div>
            </>
          )
        },
      },
      {
        name: 'previewName',
        label: 'Preview Title',
        className: 'preview-name',
      },
      {
        name: 'dateCreated',
        label: 'Date created',
        className: 'date-created',
      },
      {
        name: 'addLink',
        className: 'add-link',
        render: ({ videoChapterId }) => (
        <button className="primary outline" onClick={() => handleAddClick(videoChapterId)} disabled={loadingMap[videoChapterId]}>
          {loadingMap[videoChapterId] ? <LoadingSpinner container />  : 'Add'}
        </button>
        ),
      },
    ]}
    rows={rows}
    sortable
    sortingBy={sortingBy}
    sortingAsc={sortingAsc}
    onSortingByChange={onSortingByChange}
    onSortingAscChange={onSortingAscChange}
  />
  );
};

export default VideoAssetsTable
