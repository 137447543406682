import React, { useState } from 'react'
import { ContentBlockField } from 'state/types'
import FormFieldEditor from 'components/preview/FormFieldEditor/FormFieldEditor'
import CrossIcon from 'assets/icons/CrossIcon'
import './GroupInput.scss'
import { UnitType } from '../../../helpers/enum'
import RadioButtonToggle from '../../shared/RadioButtonToggle/RadioButtonToggle'

type Props = {
  previewId: string
  blockIndex: number
  field: ContentBlockField
  register: any
  control: any
  errors?: any
  removeFromList?: () => void
  tagFilter?: Array<string>
}

const GroupInput: React.FC<Props> = ({
  previewId,
  blockIndex,
  field,
  register,
  control,
  errors,
  removeFromList,
  tagFilter,
}) => {
  const [selectedUnitType, setSelectedUnitType] = useState<string>(UnitType.Metric)
  const unitTypeRequired: boolean =
    field.children?.find(f => f.controlType === 'Length' || f.controlType === 'Weight') !== undefined

  return (
    <div className="group-input">
      <div className="group-title-row">
        <div className="group-title">{field.formLabel}</div>
        {removeFromList && (
          <button type="button" className="remove-button plain" onClick={removeFromList}>
            <CrossIcon />
            <span>Remove</span>
          </button>
        )}
      </div>
      {unitTypeRequired && (
        <div className="radio-button-toggle">
          <RadioButtonToggle
            onSelect={setSelectedUnitType}
            selectedOption={selectedUnitType}
            options={Object.values(UnitType)}
          />
        </div>
      )}
      {field.children?.map(child => (
        <FormFieldEditor
          key={child.id}
          selectedUnitType={selectedUnitType as UnitType}
          previewId={previewId}
          blockIndex={blockIndex}
          field={child}
          register={register}
          control={control}
          errors={errors}
          tagFilter={tagFilter}
        />
      ))}
    </div>
  )
}

export default GroupInput
