import { ApisauceInstance } from 'apisauce'
import { createApi, applyAuthorization, getApiWithAuthorization } from './api'
import { PreviewDocument } from 'state/types'

let formDataApi: ApisauceInstance

export const getFormDataApi = () => {
  if (!formDataApi) {
    formDataApi = createApi()
    formDataApi.setHeader('Content-Type', 'multipart/form-data')
  }
  return formDataApi
}

export const uploadFile = async (
  contentBlockFieldId: string,
  file: File,
  onProgressChange: (progress: number) => void
) => {
  const apiWithAuth = await applyAuthorization(getFormDataApi())

  const formData = new FormData()
  formData.append('name', file.name)
  formData.append('type', file.type)
  formData.append('file', file)

  return apiWithAuth.put<string>(`/api/contentBlockField/${contentBlockFieldId}/upload`, formData, {
    onUploadProgress: event => onProgressChange(Math.floor((event.loaded / event.total) * 100)),
  })
}

export const deleteUploadedFile = async (contentBlockFieldId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.delete<void>(`/api/contentBlockField/${contentBlockFieldId}/upload`)
}

export const getAllUploadedFile = async () => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<PreviewDocument[]>(`/api/documents`)
}
