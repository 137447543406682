import { formatDate } from '../../helpers/date'
import DropdownMenu from '../shared/DropdownMenu/DropdownMenu'
import SettingsIcon from '../../assets/icons/SettingsIcon'
import Table from '../shared/Table/Table'
import React from 'react'
import { Invitation } from '../../state/types'
import DropdownMenuOption from '../shared/DropdownMenuOption/DropdownMenuOption'

/**
 * A General Invitations Table to be used by the ArchivedInvitationsTable, and the InvitationTable
 */

interface Props {
  invitations: Invitation[]
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  dropdownMenuContent: { name: string; onSelect: (invitation: Invitation) => void }[]
}

const GeneralInvitationsTable = function ({
  invitations,
  sortingBy,
  sortingAsc,
  onSortingAscChange,
  onSortingByChange,
  dropdownMenuContent,
}: Props) {
  return (
    <Table
      className="invitations-table"
      columns={[
        {
          name: 'name',
          label: 'Invitation title',
          className: 'invitation-title',
        },
        {
          name: 'ownerName',
          label: 'Workspace',
          className: 'organization',
        },
        {
          name: 'endDate',
          label: 'Closing date',
          className: 'closing-date',
          render: invitation => (
            <div className={invitation.closed ? 'closed' : 'open'}>
              {invitation.endDate ? formatDate(invitation.endDate) : '-'}
            </div>
          ),
        },
        {
          name: 'status',
          label: 'Status',
          className: 'status',
          render: invitation =>
            invitation.closed ? 'Closed' : invitation.startDate && !invitation.open ? 'Pending' : 'Open',
        },
        {
          name: 'previewCount',
          label: 'Previews received',
          className: 'preview-count',
        },
        {
          name: 'settings',
          className: 'settings',
          render: invitation => (
            <DropdownMenu
              trigger={<SettingsIcon color={'black'} />}
              menuContent={dropdownMenuContent.map(({ name, onSelect }) => (
                <DropdownMenuOption optionName={name} onSelect={() => onSelect(invitation)} />
              ))}
              horizontalAlignment="right"
            />
          ),
          notLink: true,
        },
      ]}
      rows={invitations}
      rowLink={invitation => `/invitation/${invitation.organizationId}/${invitation.id}`}
      sortable
      sortingBy={sortingBy}
      sortingAsc={sortingAsc}
      onSortingByChange={onSortingByChange}
      onSortingAscChange={onSortingAscChange}
    />
  )
}

export default GeneralInvitationsTable
