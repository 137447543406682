import React from 'react'
import { TagOption } from 'state/types'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import DropdownMenuOption from '../DropdownMenuOption/DropdownMenuOption'
import './TagFilter.scss'

type Props = {
  filterOptions: TagOption[]
  selectedFilterOption: string
  onSelect: (value: string) => void
}

const TagFilter: React.FC<Props> = ({ filterOptions, selectedFilterOption, onSelect }) => {
  const handleFilterOptionSelect = (selectedFilterOption: string) => {
    onSelect(selectedFilterOption)
  }

  return (
    <div className="tag-filter">
      <DropdownMenu
        trigger={
          <button className="button">
            Tags: <b>{filterOptions.find((option: TagOption) => option.id === selectedFilterOption)?.name}</b>
          </button>
        }
        menuContent={
          <>
            {filterOptions.map((option, index) => {
              return (
                <DropdownMenuOption
                  key={index}
                  optionName={option.name}
                  onSelect={() => handleFilterOptionSelect(option.id)}
                  selectedOption={selectedFilterOption === option.id}
                  showCheckbox
                />
              )
            })}
          </>
        }
        horizontalAlignment="left"
      />
    </div>
  )
}

export default TagFilter
