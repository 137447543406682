import { Avatar } from '@material-ui/core'
import React from 'react'
import ProfilePlaceholder from '../ProfilePlaceholder/ProfilePlaceholder'
import './ProfileImage.scss'

type Props = {
  imageUrl?: string
  name?: string
  cssClassName?: string
  isModalHighlight?: boolean
}

const ProfileImage: React.FC<Props> = ({ imageUrl, name, cssClassName, isModalHighlight }) => {
  return (
    <div className={`profile-image ${isModalHighlight && 'modal-highlight'}`}>
      {imageUrl ? (
        <Avatar className={cssClassName} src={imageUrl} variant={'rounded'} />
      ) : (
        <ProfilePlaceholder cssClassName={cssClassName} name={name} />
      )}
    </div>
  )
}

export default ProfileImage
