import PreviewEditorBottomNav from 'components/preview/PreviewEditorBottomNav/PreviewEditorBottomNav'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import { PreviewCategory as PreviewCategoryEnum, PreviewMeAction } from 'helpers/enum'
import { isOnPaymentPlanOrAdmin } from 'helpers/subscription'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as previewService from 'services/previewService'
import { InvitationSuccessPageInfo, PreviewCategory as PreviewCategoryType } from 'state/types'
import useAuth from 'state/useAuth'
import usePreview from 'state/usePreview'
import './PreviewEditorPublishStep.scss'
import { SuccessInformation } from '../../invitation/SuccessInformation/SuccessInformation'

type Props = {
  previewId: string
  prevLink?: string
  isReadyToPublish: boolean
  isPreviewPublished: boolean
  invitationOrgName?: string
  onReviewClick: () => void
  onCancelClick: () => void
  invitationSuccessPage?: InvitationSuccessPageInfo
}

const PreviewEditorPublishStep: React.FC<Props> = ({
  previewId,
  prevLink,
  isReadyToPublish,
  isPreviewPublished,
  invitationOrgName,
  onReviewClick,
  onCancelClick,
  invitationSuccessPage,
}) => {
  const { push } = useHistory()
  const { user, subscription } = useAuth()
  const { isPublishing, publish, preview } = usePreview(previewId)
  const [isCountByCategoryLoading, setCountByCategoryLoading] = useState(false)

  const blockOrPublish = async () => {
    setCountByCategoryLoading(true)
    const { ok, data } = await previewService.getPreviewCategoryCount()
    setCountByCategoryLoading(false)
    if (ok && data) {
      if (
        !isOnPaymentPlanOrAdmin(subscription, user!) &&
        preview?.previewCategory === PreviewCategoryEnum.Business &&
        data.overLimitForFreeBusinessPreviews &&
        preview?.invitation === undefined
      ) {
        blockPublish(PreviewCategoryEnum.Business)
      } else if (
        !isOnPaymentPlanOrAdmin(subscription, user!) &&
        preview?.previewCategory === PreviewCategoryEnum.Individual &&
        data.overLimitForFreeIndividualPreviews &&
        preview?.invitation === undefined
      ) {
        blockPublish(PreviewCategoryEnum.Individual)
      } else {
        publish()
      }
    }
  }

  const blockPublish = async (previewCategory: PreviewCategoryType) => {
    push(`/limitReached?previewId=${previewId}&category=${previewCategory}&action=${PreviewMeAction.Publish}`)
  }

  return (
    <div className={'preview-editor-publish-step' + (isPreviewPublished ? ' published' : '')}>
      <div className="left-column">
        <div className="publish-text">
          {isPreviewPublished ? (
            <SuccessInformation
              previewId={previewId}
              preview={preview}
              invitationSuccessPage={preview?.invitationSuccessPage}
              invitationOrgName={invitationOrgName}
            />
          ) : isReadyToPublish ? (
            <p>
              You're ready to publish your Preview. Take a look at your draft Preview and, if you're happy with it, get
              publishing!
            </p>
          ) : (
            <>
              <p>
                You're almost ready to publish your Preview. You just have to fill in any mandatory fields you missed.
              </p>
              <p>Want us to take you back and highlight the missing information?</p>
            </>
          )}
        </div>
        {!isPreviewPublished && (
          <div className="buttons">
            {isReadyToPublish ? (
              <>
                <Link to={`/preview/${previewId}?from=publish`} className="view-draft-link button">
                  View draft
                </Link>
                <LoadingButton
                  className="publish-preview-button primary"
                  isLoading={isPublishing || isCountByCategoryLoading}
                  onClick={blockOrPublish}
                  type="button"
                >
                  Publish
                </LoadingButton>
              </>
            ) : (
              <>
                <button className="review-errors-button primary" onClick={onReviewClick}>
                  Yes, let's review
                </button>
                <button className="return-to-dashboard-button link" onClick={onCancelClick}>
                  No thanks, I'll finish later
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {!isPreviewPublished && <div className="illustration" />}
      <PreviewEditorBottomNav prevLink={prevLink} />
    </div>
  )
}

export default PreviewEditorPublishStep
