import React, { useState } from 'react'
import { Integration, ViewingSelection } from 'state/types'
import Table from 'components/shared/Table/Table'
import './IntegrationsTable.scss'
import hub_spot_icon from 'assets/hub_spot_icon.png'
import { IntegrationStatus } from 'helpers/enum'
import { IntegrationType } from 'helpers/enum'
import { getDefaultIntegrationName, getIntegrationDocumentationLink, getIntegrationIcon } from 'helpers/integration'
import TableOptionButton from 'components/shared/TableOptionButton/TableOptionButton'
import SwitchButton from 'components/shared/SwitchButton/SwitchButton'
import MoveUpRightArrowIcon from 'assets/icons/MoveUpRightArrowIcon'
import { useHistory } from 'react-router-dom'
import IntegrationsListMobile from '../IntegrationsListMobile/IntegrationsListMobile'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import ArchiveIntegrationConfirmationDialog from '../ArchiveIntegrationConfirmationDialog/ArchiveIntegrationConfirmationDialog'

type Props = {
  integrations: Integration[]
  viewingSelection: ViewingSelection
  modalLoading: boolean
  deleteIntegration: (id: string) => Promise<void>
  updateEnabledIntegration: (id: string, enabled: boolean) => void
  updateArchivedIntegration: (id: string, archived: boolean) => void
}

const IntegrationsTable: React.FC<Props> = ({
  integrations,
  viewingSelection,
  deleteIntegration,
  updateEnabledIntegration,
  updateArchivedIntegration,
}) => {
  const { push } = useHistory()
  const [isDeleteIntegrationDialogShowing, setIsDeleteIntegrationDialogShowing] = useState<boolean>(false)
  const [isArchiveIntegrationConfirmationDialogShowing, setIsArchiveIntegrationConfirmationDialogShowing] =
    useState<boolean>(false)

  const [selectedIntegration, setSelectedIntegration] = useState<Integration | null>(null)
  const renderStatus = (type: IntegrationStatus, archived: boolean) => {
    if (archived)
      return (
        <div className={'status-item archived'}>
          <div className="status-prefix"></div>
          <div className="status-label">Archived</div>
        </div>
      )
    if (type)
      return (
        <div className={'status-item ' + type.toLowerCase()}>
          <div className="status-prefix"></div>
          <div className="status-label">{type[0].toUpperCase() + type.toLowerCase().substring(1)}</div>
        </div>
      )
    return null
  }

  const renderIntegrationDocumentation = (type: IntegrationType) => {
    const link = getIntegrationDocumentationLink(type)
    return (
      <a className="documentation-link" href={link} target="_blank" rel="noopener noreferrer">
        Documentation <MoveUpRightArrowIcon />
      </a>
    )
  }

  const handleUpdateEnabled = (integration: Integration, enabled: boolean) => {
    updateEnabledIntegration(integration.id, enabled)
  }

  const onDeleteIntegrationOptionSelected = (integration: Integration) => {
    setSelectedIntegration(integration)
    setIsDeleteIntegrationDialogShowing(true)
  }

  const onArchiveIntegrationOptionSelected = (integration: Integration) => {
    setSelectedIntegration(integration)
    setIsArchiveIntegrationConfirmationDialogShowing(true)
  }

  return (
    <>
      <Table
        className="integrations-table"
        columns={[
          {
            name: 'name',
            label: 'Integration',
            className: 'integration',
            render: ({ id, name, type }) => (
              <div className="integration-column">
                <img className="integration-icon" src={getIntegrationIcon(type)} />
                <div
                  className="integration-title"
                  onClick={() => {
                    push('/organization/settings/integrations/' + id)
                  }}
                >
                  {name || getDefaultIntegrationName(type)}
                </div>
              </div>
            ),
          },
          {
            name: 'id',
            label: 'ID',
            className: 'id-column',
            render: ({ platformIdOrKey }) => platformIdOrKey,
          },
          {
            name: 'type',
            label: '',
            className: 'documentation-column',
            render: ({ type }) => renderIntegrationDocumentation(type),
          },
          {
            name: 'enabled',
            label: '',
            className: 'switch-column',
            render: integration => (
              <SwitchButton
                disabled={viewingSelection === 'archived'}
                checked={integration?.enabled || false}
                onChange={(value: boolean) => handleUpdateEnabled(integration, value)}
              />
            ),
          },
          {
            name: 'settings',
            label: '',
            className: 'settings',
            render: integration => (
              <TableOptionButton
                options={[
                  { option: 'Edit', onClick: () => push(`/organization/settings/integrations/${integration?.id}`) },
                  {
                    option: 'Delete',
                    onClick: () => {
                      onDeleteIntegrationOptionSelected(integration)
                    },
                    hide: viewingSelection === 'active',
                  },
                  {
                    option: 'Restore',
                    onClick: () => {
                      updateArchivedIntegration(integration.id, false)
                    },
                    hide: viewingSelection === 'active',
                  },
                  {
                    option: 'Archive',
                    onClick: () => {
                      onArchiveIntegrationOptionSelected(integration)
                    },
                    hide: viewingSelection === 'archived',
                  },
                ]}
              />
            ),
          },
        ]}
        rows={integrations}
      />
      <IntegrationsListMobile
        integrations={integrations}
        deleteIntegration={onDeleteIntegrationOptionSelected}
        updateEnabledIntegration={updateEnabledIntegration}
      />
      {selectedIntegration && (
        <ArchiveIntegrationConfirmationDialog
          isShowing={isArchiveIntegrationConfirmationDialogShowing}
          onClose={() => setIsArchiveIntegrationConfirmationDialogShowing(false)}
          onOk={() => updateArchivedIntegration(selectedIntegration.id, true)}
        />
      )}
      {selectedIntegration && (
        <ConfirmationDialog
          className="delete-confirmation-dialog"
          isShowing={isDeleteIntegrationDialogShowing}
          isWarning
          cancelButtonText="No, thanks"
          okButtonText="Yes, delete this Integration"
          title="Heads up!"
          onClose={() => setIsDeleteIntegrationDialogShowing(false)}
          onOk={() => deleteIntegration(selectedIntegration.id)}
        >
          This will permanently delete this Integration. Are you sure you wish to continue?
        </ConfirmationDialog>
      )}
    </>
  )
}

export default IntegrationsTable
