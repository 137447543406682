import React, { useCallback, useState } from 'react'
import './InvitationsActionDropdown.scss'
import DropdownMenu from 'components/shared/DropdownMenu/DropdownMenu'
import DropdownMenuOption from 'components/shared/DropdownMenuOption/DropdownMenuOption'
import { CSVLink } from 'react-csv'
import { InvitationCSV } from 'state/types'
import _ from 'lodash'
import * as invitationService from '../../../services/invitationService'
import { formatDate } from '../../../helpers/date'

type Props = {
  data?: InvitationCSV[]
  fileName: string
  invitationId: string | undefined
  convertToCSV: (data: InvitationCSV[] | undefined) => string | undefined
  fetchInvitationWithAllPreviews: () => Promise<InvitationCSV[] | undefined>
  downloadCSV: (csvContent: string, fileName: string) => void
}

const InvitationsActionDropdown: React.FC<Props> = ({ data = [], fileName , invitationId, convertToCSV, fetchInvitationWithAllPreviews, downloadCSV}) => {
  const onDropdownSelect = useCallback(() => {}, [])

  const handleDownload = async() => {
    const data = await fetchInvitationWithAllPreviews()
    const csv = convertToCSV(data);
    downloadCSV(csv, fileName);
  };

  return (
    <div className="invitations-actions">
      <DropdownMenu
        trigger={
          <div className="three-dots-icon">
            <div className="circle-icon"></div>
            <div className="circle-icon"></div>
            <div className="circle-icon"></div>
          </div>
        }
        menuContent={
          <>
            <DropdownMenuOption optionName="" onSelect={onDropdownSelect} showBottomBorder>
                <button className={'csv-download'} onClick={handleDownload}>Export List to CSV</button>
            </DropdownMenuOption>
          </>
        }
        horizontalAlignment="right"
      />
    </div>
  )
}

export default InvitationsActionDropdown
