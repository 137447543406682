import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CloudinaryUploadResult } from 'state/types'
import useVideoChapter from 'state/useVideoChapter'
import { formatChapterLabel, videoFileSizeLimitInBytes, videoFileSizeLimitInMb } from 'helpers/media'
import { parseIndexParam } from 'helpers/urlParams'
import ScreenRecorder from 'components/video/ScreenRecorder/ScreenRecorder'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import BackLink from 'components/shared/BackLink/BackLink'
import './ScreenRecorderPage.scss'

const ScreenRecorderPage = () => {
  const [isFileSizeErrorDialogShowing, setFileSizeErrorDialogShowing] = useState(false)
  const { previewId, index, fieldIndex } = useParams<any>()
  const chapterIndex = parseIndexParam(fieldIndex) || 0
  const { videoChapter, uploadVideo, saveType } = useVideoChapter(previewId, parseIndexParam(index), chapterIndex)
  const history = useHistory()
  const videoBlockEditorPath = `/edit/${previewId}/block/${index}`
  const videoTrimEditorPath = `/edit/${previewId}/video/${index}/trim/${chapterIndex}/true`

  const handleUploadVideo = (blob: Blob, onProgressChange: (progress: number) => void) => {
    // We limit the recording time, but because different browsers record in different formats,
    // for safety we also do a file size check, because we'd rather show this dialog than have the
    // upload fail mysteriously.
    if (blob.size >= videoFileSizeLimitInBytes) {
      setFileSizeErrorDialogShowing(true)
      return { response: Promise.resolve({ status: 'error' } as CloudinaryUploadResult) }
    } else {
      return uploadVideo(blob, onProgressChange)
    }
  }

  const onUploadSuccess = () => {
    saveType('video')
    history.push(videoTrimEditorPath)
  }

  return (
    <div className="screen-recorder-page">
      <div className="video-recorder-page-header">
        <div className="header-content">
          <div className="left-buttons">
            <BackLink to={videoBlockEditorPath}>Back</BackLink>
          </div>
        </div>
      </div>
      <div className="recorder-container-panel">
        <div className="inner-panel">
          <div className="chapter-name">
            {videoChapter && (
              <>
                <span className="label">{formatChapterLabel(chapterIndex) + ':'}</span>
                <span className="name">{`${videoChapter.name}`}</span>
              </>
            )}
          </div>
          <ScreenRecorder uploadVideo={handleUploadVideo} onUploadSuccess={onUploadSuccess} />
        </div>
        <div className="spacer" />
      </div>
      <ConfirmationDialog
        isShowing={isFileSizeErrorDialogShowing}
        okButtonText="Ok"
        title="Video too large"
        onClose={() => setFileSizeErrorDialogShowing(false)}
        className="video-file-size-error-dialog"
      >
        {`This video is too large. The size limit is ${videoFileSizeLimitInMb} MB.`}
      </ConfirmationDialog>
    </div>
  )
}

export default ScreenRecorderPage
