import InvitationDateInfo from 'components/invitation/InvitationDateInfo/InvitationDateInfo'
import InvitationPreviewsListMobile from 'components/invitation/InvitationPreviewsListMobile/InvitationPreviewsListMobile'
import InvitationPreviewsTable from 'components/invitation/InvitationPreviewsTable/InvitationPreviewsTable'
import SharingDialog from 'components/preview/SharingDialog/SharingDialog'
import BackLink from 'components/shared/BackLink/BackLink'
import CopyableLink from 'components/shared/CopyableLink/CopyableLink'
import CopyableQR from 'components/shared/CopyableQR/CopyableQR'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import OptionsMenuButton from 'components/shared/OptionsMenuButton/OptionsMenuButton'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import {
  InvitationResponseLabel,
  InvitationResponsesLabelFilterOption,
  PreviewMeObject,
  SubscriptionPlan
} from 'helpers/enum'
import {
  getInitialSortingAsc,
  getInitialSortingBy,
  sortingAscKey,
  sortingByKey,
  sortInvitationPreviews,
} from 'helpers/sort'
import { isOnProfessionalOrEnterprisePlanOrAdmin } from 'helpers/subscription'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import useAuth from 'state/useAuth'
import useInvitation from 'state/useInvitation'
import './InvitationDetailPage.scss'
import InvitationsResponseFilters from '../InvitationsResponseFilters/InvitationsResponseFilters'
import { filterInvitationResponses } from '../../../helpers/filter'
import { Invitation, InvitationCSV, PreviewSummary } from '../../../state/types'
import useWorkspace from '../../../state/useWorkspace'
import useSubscription from '../../../state/useSubscription'
import { InvitationUpgradeDialog } from '../InvitationUpgradeDialog/InvitationUpgradeDialog'
import * as invitationService from '../../../services/invitationService'
import { getWorkspaceOrganizationId, getWorkspaceOrganization } from '../../../helpers/organization'
import InvitationsActionDropdown from '../InvitationsActionDropdown/InvitationsActionDropdown'
import { formatDate } from 'helpers/date'
import format from 'date-fns/format'
import useStore from '../../../state/useStore'
import { useUrlParams } from '../../../helpers/urlParams'
import _ from "lodash"
const headers = [
  { label: 'Date Received', key: 'dateReceived' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email Address', key: 'email' },
  { label: 'Preview URL', key: 'previewUrl' },
  { label: 'Starred', key: 'starred' },
  { label: 'Label', key: 'label' },
]

const InvitationDetailPage: React.FC = () => {
  const { state, dispatch } = useStore()
  const [isMenuButtonShowing, setMenuButtonShowing] = useState(false)
  const { page } = useUrlParams('page')
  const { invitationId, organizationId } = useParams<any>()
  const { push } = useHistory()
  const [invitationWithAllPreviews, setInvitationWithAllPreviews] = useState()
  const { organizationsLoaded, setCurrentWorkspaceById } = useWorkspace(true)
  const { isSubscriptionLoading, subscription } = useSubscription(organizationsLoaded)
  const { user } = useAuth()
  const [tableViewCurrentPage, setTableViewCurrentPage] = useState(page ? Number(page) : 1)
  const [selectedLabelFilterOption, setSelectedLabelFilterOption] = useState<string>(
    InvitationResponsesLabelFilterOption.All
  )
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortingBy, setSortingBy] = useState(getInitialSortingBy())
  const [sortingAsc, setSortingAsc] = useState(getInitialSortingAsc())
  const { invitation, isLoading, toggleStarred } = useInvitation(invitationId, tableViewCurrentPage, selectedLabelFilterOption, searchQuery, true, sortingBy, sortingAsc)
  const [showShareDialog, setShareDialog] = useState(false)
  const [shareInvitationId, setShareInvitationId] = useState<string>()
  const [organisationName, setOrganisationName] = useState<string>()
  const [invitationResponsesToShow, setInvitationResponsesToShow] = useState<PreviewSummary[]>([])
  const allInvitationResponses: PreviewSummary[] = invitation && invitation.previews ? invitation.previews : []
  const noPreviews = allInvitationResponses.length === 0
  const [showUpgradeDialog, setShowUpgradeDialog] = useState<boolean>(false)
  const [isInvitationLimitReached, setInvitationLimitReached] = useState(false)
  const [isInvitationCountLoading, setInvitationCountLoading] = useState(true)
  const [allowHardReload, setAllowHardReload] = useState(true)
  const defaultInvitation = state.defaultInvitation
  const [prevInvitation, setPrevInvitation] = useState()
  const [delay, setDelay] = useState(false)
  const [timeoutId, setTimeoutId] = useState()

  const getSortedInvitation = (invitationPreviews: PreviewSummary[]) => {

    if (sortingBy === 'applicants') {
      const sortOrder = sortingAsc ? 'asc' : 'desc';

      return [...invitationPreviews].sort((a, b) => {
        const nameA = `${a.givenName.toLowerCase()} ${a.familyName.toLowerCase()}`;
        const nameB = `${b.givenName.toLowerCase()} ${b.familyName.toLowerCase()}`;

        if (nameA < nameB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (nameA > nameB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });
    } else if (sortingBy === 'starred'){
      return [...invitationPreviews].sort((a,b) => {
        const boolA = a.starred ? 1 : 0;
        const boolB = b.starred ? 1 : 0;

        if (boolA > boolB) {
          return sortingAsc ? -1 : 1;
        }
        if (boolA < boolB) {
          return sortingAsc ? 1 : -1;
        }

        return 0;
      })
    }
    else {
      return invitationPreviews;
    }
  };

  useEffect(() => {
    setTimeout(() =>{
      setDelay(true)
    },500)
  }, [])

  useEffect(() => {
    setSortingBy('firstPublishDate')
  }, [])

  useEffect(() => {
    if(invitation && (invitation?.id !== prevInvitation?.id)){
      dispatch({ type: 'SET_DEFAULT_INVITATION', value: invitation });
    }
    localStorage.setItem('invitation', JSON.stringify(invitation));

    if(localStorage.getItem('invitation')){
      setAllowHardReload(false)
    }
    setPrevInvitation(invitation)
  }, [invitation])

  useEffect(() => {
    const loadInvitations = async () => {
      const workspaceOrganizationId = getWorkspaceOrganizationId()
      if (!workspaceOrganizationId || !subscription) {
        return
      }

      if (subscription?.plan.name === SubscriptionPlan.Enterprise) {
        setInvitationLimitReached(false)
        setInvitationCountLoading(false)
        return
      }
      const { ok, data: allInvitations } = await invitationService.getInvitationSummaries(workspaceOrganizationId)

      if (ok && allInvitations) {
        const currentInvitation = allInvitations.filter(currentInvitation => currentInvitation.id === invitation?.id)[0]
        setInvitationLimitReached(
          currentInvitation?.closed ? allInvitations.filter(invitation => !invitation.closed).length > 1 : false
        )
      }
      setInvitationCountLoading(false)
    }

    loadInvitations()
  }, [invitation, subscription])

  useEffect(() => {
    if (isSubscriptionLoading) {
      return
    }

    if (!organizationId) {
      return
    }

    const hasWorkspaceChanged: boolean = setCurrentWorkspaceById(organizationId)
    if (!hasWorkspaceChanged) {
      push('/notFound')
      return
    }

    setInvitationLimitReached(
      subscription?.plan.name === SubscriptionPlan.Enterprise ? false : isInvitationLimitReached
    )

    if (!isOnProfessionalOrEnterprisePlanOrAdmin(subscription, user!)) {
      push('/invitations')
    }
  }, [isSubscriptionLoading, subscription, user])

  useEffect(() => {
    const allInvitationResponses: PreviewSummary[] | undefined = sortInvitationPreviews(
      invitation?.previews,
      sortingBy,
      sortingAsc
    )
    const labelFilterOption: InvitationResponsesLabelFilterOption =
      selectedLabelFilterOption as InvitationResponsesLabelFilterOption

    if (allInvitationResponses) {
      setInvitationResponsesToShow(filterInvitationResponses(labelFilterOption, allInvitationResponses))
    }
  }, [sortingBy, sortingAsc, allInvitationResponses])

  const handleLabelFilter = (selectedLabelFilterOption: string): void => {
    setTableViewCurrentPage(1)
    const labelFilterOption: InvitationResponsesLabelFilterOption =
      selectedLabelFilterOption as InvitationResponsesLabelFilterOption
    setSelectedLabelFilterOption(selectedLabelFilterOption)
    setInvitationResponsesToShow(filterInvitationResponses(labelFilterOption, allInvitationResponses))
  }

  const onSortingByChange = (sortingBy: string) => {
    setSortingBy(sortingBy)
    localStorage.setItem(sortingByKey, sortingBy)
  }

  const onSortingAscChange = (sortingAsc: boolean) => {
    setSortingAsc(sortingAsc)
    localStorage.setItem(sortingAscKey, sortingAsc.toString())
  }

  const onEditInvitationClick = (invitation: Invitation | undefined) => {
    if (isInvitationLimitReached) {
      setShowUpgradeDialog(true)
    } else {
      push(`/invitation/${invitation?.organizationId}/${invitation?.id}/edit`)
    }
  }

  const onPageChange = (page: number) => {
    setTableViewCurrentPage(page)
  }

  const date = format(new Date(), 'd.MM.yy')

  const generateFileName = useMemo(() => {
    const organisationName = getWorkspaceOrganization()?.name || ''
    const invitationName = invitation?.name || ''
    return (
      organisationName.split(' ').join('') + '_' + invitationName.split(' ').join('') + '_List_Export_' + date + '.csv'
    )
  }, [invitation, getWorkspaceOrganization()?.name, date])

  const onExportSelect = useCallback(() => {}, [])

  const handleSearchQuery = (e: string) => {

    clearTimeout(timeoutId);

    setTimeoutId(setTimeout(() => {
      setTableViewCurrentPage(1);
      setSearchQuery(e);
    }, 300));
  };

  const fetchInvitationWithAllPreviews = async () => {
    let formattedData: InvitationCSV[] | undefined;
    const { data, ok } = await invitationService.getInvitationDetailsWithAllResponses(invitationId)
    formattedData = data?.previews?.map(preview => {
      return {
        dateReceived: formatDate(preview.firstPublishDate),
        email: preview.email,
        lastName: preview.familyName,
        firstName: preview.givenName,
        previewUrl: window.location.host + '/p/' + preview.id,
        starred: preview.starred ? 'yes' : 'no',
        label: preview.label || '',
      }
    })
    return formattedData
  }

  const convertToCSV = (data: InvitationCSV[] | undefined) => {
    if (data?.length === 0) {
      return ''; // Return empty string for empty data
    }

    if(data){
      const headers = Object.keys(data[0]);
      const formattedHeaders = ['Date Received', 'First Name', 'Last Name', 'Email Address', 'Preview URL', 'Starred', 'Label'];
      const rows = data?.map(item => headers.map(header => item[header]));

      return [
        formattedHeaders.join(','), // CSV headers
        ...rows.map(row => row.join(',')) // CSV rows
      ].join('\n');
    }
  };

  const downloadCSV = (csvContent: string | undefined, fileName: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Create a link to the Blob
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      // Append to the document
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      // Cleanup
      document.body.removeChild(link);
    }
  };

  const handleDownload = async() => {
    const data = await fetchInvitationWithAllPreviews()
    const csv = convertToCSV(data);
    downloadCSV(csv, generateFileName);
  };
  return (
    <>
      <InvitationUpgradeDialog isShowing={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} />
      <PageFrame
        className={'invitation-detail-page' + (noPreviews ? ' no-previews' : '')}
        header={
         !allowHardReload && delay &&
           (
            <>
              <SharingDialog
                id={shareInvitationId!}
                isShowing={showShareDialog}
                onClose={() => {
                  setShareDialog(false)
                  setShareInvitationId(undefined)
                }}
                object={PreviewMeObject.Invitation}
                invitationOrgName={organisationName}
              />
              <BackLink to='/invitations'>Back to Invitations</BackLink>
              <div className='main-header-content'>
                <div className='left-column'>
                  <h1>{invitation ? invitation.name : defaultInvitation?.name}</h1>
                  {(subscription?.plan.name === SubscriptionPlan.Enterprise || subscription?.plan.name === SubscriptionPlan.Business || subscription?.plan.name === SubscriptionPlan.Professional) && (
                    <InvitationsActionDropdown
                      data={invitationWithAllPreviews}
                      fileName={generateFileName}
                      invitationId={invitationId}
                      convertToCSV={convertToCSV}
                      fetchInvitationWithAllPreviews={fetchInvitationWithAllPreviews}
                      downloadCSV={downloadCSV}
                    />
                  )}
                </div>
                <div className='right-column'>
                  <div className='edit-link button outline primary' onClick={() => onEditInvitationClick(invitation)}>
                    Edit invitation
                  </div>
                  <OptionsMenuButton
                    isShowing={isMenuButtonShowing}
                    options={[
                      {
                        option: 'Edit',
                        onClick: () => push(`/invitation/${invitation && invitation.organizationId}/${invitation && invitation.id}/edit`)
                      },
                      {
                        option: 'Share',
                        onClick: () => {
                          setShareDialog(true)
                          setShareInvitationId(invitationId)
                        }
                      },
                      {
                        option: (
                          <button className={'csv-download'} onClick={handleDownload}>Export List to CSV</button>
                        ),
                        onClick: onExportSelect
                      }
                    ]}
                    setShowing={setMenuButtonShowing}
                  />
                </div>
              </div>
              <div className='invitation-date-info-row'>
                <InvitationDateInfo
                  organizationId={invitation ? invitation.organizationId: defaultInvitation?.organizationId}
                  invitationId={invitation ?invitation.id: defaultInvitation?.id}
                  startDate={invitation ?invitation.startDate: defaultInvitation?.startDate}
                  endDate={invitation ?invitation.endDate: defaultInvitation?.endDate}
                  open={invitation ? invitation.open: defaultInvitation?.open}
                  closed={invitation ? invitation.closed: defaultInvitation?.closed}
                  isInvitationLimitReached={isInvitationLimitReached}
                  setShowUpgradeDialog={setShowUpgradeDialog}
                />

                <button
                  className='share-button button outline primary'
                  onClick={() => {
                    setShareDialog(true)
                    setShareInvitationId(invitationId)
                    setOrganisationName(invitation && invitation.ownerName)
                  }}
                >
                  Share
                </button>
              </div>
              <div className={'filter-container'}>
                <div className='invitation-response-searchbar'>
                  <input className={'search-filter'} onChange={(e) => handleSearchQuery(e.target.value)} />
                </div>
                <InvitationsResponseFilters selectedLabel={selectedLabelFilterOption} onLabelSelect={handleLabelFilter} />
              </div>
            </>
           )
        }
      >
        {!allowHardReload && (isLoading || isInvitationCountLoading)? (
          <LoadingSpinner container expand />
        ) : invitation?.previews && invitation.previews.length > 0 ? (
          <>
            <InvitationPreviewsTable
              invitationId={invitation.id}
              previews={() => getSortedInvitation(invitation?.previews || [])}
              sortingBy={sortingBy}
              sortingAsc={sortingAsc}
              onSortingByChange={onSortingByChange}
              onSortingAscChange={onSortingAscChange}
              toggleStarred={toggleStarred}
              selectedLabelFilterOption={selectedLabelFilterOption}
              tableViewCurrentPage={tableViewCurrentPage ? tableViewCurrentPage : 1}
              onPageChange={onPageChange}
              previewsCount={invitation.previewCount || 0}
              searchQuery={searchQuery}
            />
            <InvitationPreviewsListMobile
              invitationId={invitation.id}
              previews={invitationResponsesToShow}
              sortingBy={sortingBy}
              sortingAsc={sortingAsc}
              onSortingByChange={onSortingByChange}
              onSortingAscChange={onSortingAscChange}
              toggleStarred={toggleStarred}
            />
          </>
        ) : noPreviews ? (
          <div className="no-previews-container">
             <div className="no-previews-box">
              <div className="no-previews-text">
                { !searchQuery && selectedLabelFilterOption === 'All' ? "There haven't been any Previews submitted for this Invitation yet." : "No results found!"}
              </div>
              {(!invitation?.closed ) ? (
                (!searchQuery && selectedLabelFilterOption === 'All') &&
                <>
                  <div className="share-link-text">Share this link with applicants:</div>
                  <CopyableLink to={`/i/${invitationId}`} />
                  <p>Or</p>
                  <CopyableQR to={`/i/${invitation?.id}`} />
                </>
              ) : (
                <Link
                  className="extend-closing-date-link button outline primary"
                  to={`/invitation/${invitation?.organizationId}/${invitation?.id}/edit`}
                >
                  Extend closing date
                </Link>
              )}
            </div>
          </div>
        ) : null}
      </PageFrame>
    </>
  )
}

export default InvitationDetailPage
