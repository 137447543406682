import AnalyticsMan from 'assets/analytics-man.png'
import React from 'react'
import './AnalyticsCta.scss'
import { WorkspaceType } from '../../../helpers/enum'
import useWorkspace from '../../../state/useWorkspace'

type Props = {
  title: string
  body: string
}

const AnalyticsCta: React.FC<Props> = ({ title, body }) => {
  const { currentWorkspace} = useWorkspace(false)

  return (currentWorkspace?.type !== WorkspaceType.User &&
    <div className="analytics-cta">
      <div className="analytics-cta-content">
        <h2 className="analytics-cta-subtitle">{title}</h2>
        <p>{body}</p>
        <a
          className="button primary"
          href={`/plan/select?from=${encodeURIComponent(window.location.pathname + window.location.search)}`}
        >
          <span>View upgrade options</span>
        </a>
      </div>

      <img className="analytics-cta-image" src={AnalyticsMan} alt="" />
    </div>
  )
}

export default AnalyticsCta
