import { getApi, getApiWithAuthorization } from './api'
import { VideoSuite } from 'state/types'

export const getVideoSuiteByVideoId = async (organizationId:String ,  videoId:String, previewId:String, rawVideoStorageId:String) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<VideoSuite>(`/api/videoSuite/${organizationId}/videoId/${videoId}?previewId=${previewId}&rawVideoStorageId=${rawVideoStorageId}`)
}

export const updateVideoSuite = async (organizationId:string,id: string, data: any) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<string>(
    `/api/videoSuite/${organizationId}/update/${id}`,data
  )
}

export const getVideoSuiteByPreviewId = async (organizationId:String , previewId:String) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<VideoSuite>(`/api/videoSuite/${organizationId}/previewId/${previewId}`)
}

export const removeVideoSuiteById = async (organizationId:String , id:String) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.delete<VideoSuite>(`/api/videoSuite/${organizationId}/delete/${id}`)
}

export const getVideoSuiteByVideoIdPublic = async (videoId:String) => {
  const api = await getApi()
  return api.get<VideoSuite>(`/api/videoSuite/public/videoId/${videoId}`)
}
