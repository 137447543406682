import { create } from 'apisauce'
import { browserInfo } from 'helpers/browser'
import UUID from 'pure-uuid'
import { getApi } from './api'

const browserDetails = browserInfo.browser
const transactionId = new UUID(4).toString()

const getOrCreateDeviceId = () => {
  const deviceIdStorageKey = 'device-id'
  let deviceId = localStorage.getItem(deviceIdStorageKey)
  if (deviceId === null) {
    deviceId = new UUID(4).toString()
    localStorage.setItem(deviceIdStorageKey, deviceId)
  }
  return deviceId
}

const deviceId = getOrCreateDeviceId()
const timezoneOffset = new Date().getTimezoneOffset() / 60
const referrer = document.referrer

const cloudflareApi = create({ baseURL: 'https://www.cloudflare.com' })
const ipApi = create({ baseURL: 'https://ipapi.co' })

let userId: String | undefined
let ipAddress: String | undefined
let geoCity: String | undefined
let geoRegion: String | undefined
let geoCountry: String | undefined

type EventLocation = {
  geoCity: string
  geoRegion: string
  geoCountry: string
}

type EventData = {
  eventType: string
  previewId?: string
  contentBlockId?: string
  contentBlockFieldId?: string
  videoChapterId?: string
  videoTime?: number
}

const setGeoData = async () => {
  const ipResponse = await cloudflareApi.get<string>('/cdn-cgi/trace')
  if (!(ipResponse.ok && ipResponse.data)) return

  // Extract IP address from response string.
  const indexOfIpAddress = ipResponse.data.indexOf('ip=')
  const dataWithoutStart = ipResponse.data.substring(indexOfIpAddress + 3)
  const ipAddress = dataWithoutStart.substring(0, dataWithoutStart.indexOf('\n'))
  const location = await ipApi.get<{ city: string; region: string; country_name: string }>(`/${ipAddress}/json`)

  if (!(location.ok && location.data)) return
  geoCity = location.data.city
  geoRegion = location.data.region
  geoCountry = location.data.country_name
}

export const setEventTrackingUserId = (id?: String) => {
  userId = id
}

export const trackEvent = async (eventData: EventData) => {
  if (ipAddress == null) {
    await setGeoData()
  }

  const trackingEvent = {
    ...eventData,
    transactionId,
    userId,
    ipAddress,
    deviceId,
    geoCity,
    geoRegion,
    geoCountry,
    timezoneOffset,
    browserName: browserDetails.name,
    browserVersion: browserDetails.version,
    osName: browserInfo.os.name,
    osVersion: browserInfo.os.version,
    platformType: browserInfo.platform.type,
    platformVendor: browserInfo.platform.vendor,
    referrer: referrer,
  }

  getApi().post<String>(`/api/event`, trackingEvent)
}
