import * as React from 'react'

const DownloadIcon = () => (
  <svg className="icon" viewBox="0 0 25 23" width="25" height="23">
    <g strokeWidth={1.287} fill="none" fillRule="evenodd">
      <path d="M5.65 16.37c-.08.01-.17.01-.25.01-.08 0-.16 0-.24-.01A4.749 4.749 0 011.1 9.61a4.73 4.73 0 015.15-2.67c.06-3.48 2.9-6.29 6.4-6.29 3.5 0 6.34 2.79 6.41 6.27a4.734 4.734 0 015.04 2.69c.29.61.45 1.29.45 2.01 0 2.55-2 4.62-4.51 4.75" />
      <path d="M19.55 16.37c.08.01.16.01.24.01.08 0 .17 0 .25-.01h-4.38M9.62 16.37H5.16M12.714 21.556V10.735M15.875 19.175l-3.192 3.191-3.192-3.19" />
    </g>
  </svg>
)

export default DownloadIcon
