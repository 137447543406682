import 'url-search-params-polyfill'
import 'intersection-observer'
import 'react-app-polyfill/ie11'
import 'core-js/features/array/find'
import 'core-js/features/array/flat-map'
import 'core-js/features/array/includes'
import 'core-js/features/string/starts-with'
import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/app/App/App'
import TagManager from 'react-gtm-module'
import { gtmId } from 'helpers/config'

const tagManagerArgs = {
  gtmId: gtmId,
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'))
