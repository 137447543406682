import { PreviewViewsByCategory } from 'state/types'

export const event = {
  previewEditor: {
    category: 'Preview editor',
    actions: {
      create: 'Create',
      edit: 'Edit',
      saveDraft: 'Save draft',
      publish: 'Publish',
      unpublish: 'Unpublish',
      editMode: 'View edit mode',
      previewMode: 'View preview mode',
      upload: 'Document upload',
      replace: 'Document replace',
    },
  },
  addVideo: {
    category: 'Add video',
    actions: {
      upload: 'Upload video',
      record: 'Record video without teleprompter',
      recordWithTeleprompter: 'Record video with teleprompter',
    },
  },
  videoEditor: {
    category: 'Video editor',
    actions: {
      cancelUpload: 'Cancel upload',
      upload: 'Upload video',
      playVideo: 'Play video',
      pauseVideo: 'Pause video',
      remove: 'Remove video',
      viewTip: 'View hints & tips',
    },
  },
  videoRecorder: {
    category: 'Video recorder',
    actions: {
      addScript: 'Add script',
      editScript: 'Edit script',
      saveScript: 'Save script',
      testScript: 'Test script',
      hideTeleprompter: 'Hide teleprompter',
      showTeleprompter: 'Show teleprompter',
      setTeleprompterSpeed: 'Set teleprompter speed',
      record: 'Record video',
      recordWithTeleprompter: 'Record video with teleprompter',
      discard: 'Discard video',
      save: 'Save video',
      cancelSave: 'Cancel saving video',
      playRecordedVideo: 'Play recorded video',
      pauseVideo: 'Pause recorded vide',
    },
  },
  videoTrimmer: {
    category: 'Video trimmer',
    actions: {
      trim: 'Trim video',
    },
  },
  publishedPreview: {
    category: 'Published preview',
    actions: {
      playVideo: 'Play video',
      pauseVideo: 'Pause video',
      playVideoChapter: 'Play video chapter',
    },
  },
}

export const viewsByReferrerMapper = (referrers: PreviewViewsByCategory[] | undefined) => {
  let chartReferrerData: { id: string; label: string; value: number }[] = []

  referrers?.forEach((v, i) => {
    const label = v.category
    let labelDomain = getLabelDomain(label)

    labelDomain = checkForTopReferrer(labelDomain)

    let valueIncreased: boolean = false

    for (let j = 0; j < chartReferrerData.length; j++) {
      const currData = chartReferrerData[j]
      if (currData.label === labelDomain) {
        chartReferrerData[j].value++
        valueIncreased = true
        break
      }
    }

    if (!valueIncreased) {
      chartReferrerData.push({ id: labelDomain, label: labelDomain, value: v.count })
    }
  })

  chartReferrerData = getTopReferrers(chartReferrerData)

  return chartReferrerData
}

function getTopReferrers(chartReferrerData: { id: string; label: string; value: number }[]) {
  chartReferrerData.sort((a, b) => (a.value > b.value ? -1 : 1))
  if (chartReferrerData.length > 10) {
    const nonOtherChartReferrerData = chartReferrerData.splice(0, 9)
    const amountsOfOthersToAdd = calculateOthersValueTotal(chartReferrerData)
    chartReferrerData = nonOtherChartReferrerData
    chartReferrerData.push({ id: 'other', label: 'other', value: amountsOfOthersToAdd })
  }
  return chartReferrerData
}

function calculateOthersValueTotal(chartReferrerData: { id: string; label: string; value: number }[]) {
  let accumulator = 0
  chartReferrerData.forEach((data, i) => (accumulator += data.value))
  return accumulator
}

function checkForTopReferrer(labelDomain: string) {
  const topReferrers = ['instagram', 'facebook', 'linkedin', 'slack', 'messenger']
  for (let i = 0; i < topReferrers.length; i++) {
    const currReferrer: string = topReferrers[i]
    if (labelDomain.toLowerCase().includes(currReferrer)) {
      return currReferrer
    }
  }
  return labelDomain
}

function getLabelDomain(label: string) {
  const labelStartIndex = label.indexOf('//') + 2
  const labelWithoutBeginning = label.substring(labelStartIndex)
  const labelLastIndex = labelWithoutBeginning.indexOf('/')
  let labelDomain = labelWithoutBeginning.substring(0, labelLastIndex)

  if (labelDomain === '' && label !== '') {
    labelDomain = label
  }
  if (labelDomain === '') {
    labelDomain = 'Unknown'
  }
  return labelDomain
}
