import React, { useEffect, useRef, useState } from 'react'
import { Preview } from 'state/types'
import * as previewService from 'services/previewService'
import TemplateCard from 'components/preview/TemplateCard/TemplateCard'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import './TemplateCards.scss'

type Props = {
  templateUsageTypesToDisplay: string[]
  previewCategory?: 'individual' | 'business'
  onTemplateSelected: (id: string) => Promise<void>
  isInvitational?:boolean;
}

const TemplateCards: React.FC<Props> = ({ isInvitational, templateUsageTypesToDisplay, previewCategory, onTemplateSelected }) => {
  const [isLoading, setLoading] = useState(true)
  const [templates, setTemplates] = useState<Preview[]>([])
  const [selectedId, setSelectedId] = useState<string | undefined>()
  const mountedRef = useRef(true)
  const excludedTemplatePreview = ["2160087d-8d40-4d22-ab92-d648bd31aac3","ea604839-b607-4bc1-862c-1061c96859b0","8cfb2332-5a7c-489a-b553-33996e83e029","a6646eef-ebc0-480f-8a80-e9eeaf56765d",
  "e657b8ef-6dea-43ab-89e8-fa3c9069d6ee"]
  const excludedTemplateInvitational = ["8c79446f-1b54-4831-9a6a-e6ada7acd023", "1633cd4f-a0bb-4587-95c8-be56702a6411", "61f8b74b-6813-4ba7-8256-38c2cf3c8579", "d379a47f-717a-4477-8a65-190bcd0b9bf6"
    , "c8fc0a4b-7145-4544-bba5-c64a9c554a8b","6070d8ca-7632-44a9-a04b-e5ecabbe3be8"]

  const onTemplateClick = async (id: string) => {
    setSelectedId(id)
    await onTemplateSelected(id)
    if (mountedRef.current) {
      setSelectedId(undefined)
    }
  }

  useEffect(() => {
    const loadTemplates = async () => {
      setLoading(true)
      const { ok, data } = await previewService.getTemplates()
      if (ok && data) {
        const exclusionList = isInvitational ? excludedTemplateInvitational : excludedTemplatePreview
        const templatesToShow = data.filter(
          t =>
            t.templateUsageType &&
            templateUsageTypesToDisplay.includes(t.templateUsageType) &&
            (!previewCategory || t.previewCategory === previewCategory) &&
            !exclusionList.includes(t.id)
        )
        setTemplates(templatesToShow)
      }
      setLoading(false)
    }
    loadTemplates()
  }, [previewCategory])

  useEffect(() => () => {
    mountedRef.current = false
  })

  return isLoading ? (
    <LoadingSpinner container />
  ) : (
    <div className={'template-cards length-' + templates.length}>
      {templates.map(template => (
        <TemplateCard
          key={template.id}
          name={template.name}
          description={template.description}
          buttonText={templateUsageTypesToDisplay.some(type => type === 'invitation') ? 'Select Template' : 'Select'}
          isLoading={selectedId === template.id}
          onClick={() => {
            if (selectedId !== template.id) {
              onTemplateClick(template.id)
            }
          }}
        />
      ))}
    </div>
  )
}

export default TemplateCards
