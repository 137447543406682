import convert from 'convert-units'
import { ImperialLength, ImperialLengthSmall } from '../state/types'

export const convertImperialLengthToMetric = (feet: number, inch: number): number => {
  const feetToMeter: number = convert(feet).from('ft').to('m')
  const inchToMeter: number = convert(inch).from('in').to('m')
  return feetToMeter + inchToMeter
}

export const convertImperialWeightToMetric = (pound: number): number => {
  return convert(pound).from('lb').to('kg')
}

export const convertMetricLengthToImperial = (meter: number): ImperialLength => {
  if (isNaN(meter)) {
    return { feet: NaN, inch: NaN }
  }
  const meterToFeet: number = convert(meter).from('m').to('ft')
  const feetFloatDiff: number = meterToFeet - ~~meterToFeet
  const inch: number = Math.round(convert(feetFloatDiff).from('ft').to('in'))
  return { feet: ~~meterToFeet, inch: ~~inch }
}

export const convertMetricCentimetreLengthToImperial = (centimetre: number): ImperialLengthSmall => {
  if (isNaN(centimetre)) {
    return { inch: NaN }
  }
  const inches: number = convert(centimetre).from('cm').to('in')
  return { inch: +inches.toFixed(2) }
}

export const convertImperialInchLengthToMetricCentimetre = (inch: number): number => {
  return convert(inch).from('in').to('cm')
}

export const convertMetricWeightToImperial = (kilogram: number): number => {
  return convert(kilogram).from('kg').to('lb')
}
