import MoveUpRightArrowIcon from 'assets/icons/MoveUpRightArrowIcon'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import { IntegrationType } from 'helpers/enum'
import { integrationPlatform } from 'helpers/integration'
import React, { useEffect } from 'react'
import './CreateIntegrationDialog.scss'

type Props = {
  openCreateIntegrationDialog: boolean
  onOpenCreateIntegrationDialog: (value: boolean) => void
  createIntegration: (type: IntegrationType) => Promise<void>
  modalLoading: boolean
}

const CreateIntegrationDialog: React.FC<Props> = ({
  openCreateIntegrationDialog,
  onOpenCreateIntegrationDialog,
  createIntegration,
  modalLoading,
}) => {
  useEffect(() => {
    if (openCreateIntegrationDialog) {
    }
  }, [openCreateIntegrationDialog])

  const onClose = async () => {
    onOpenCreateIntegrationDialog(false)
  }

  const handleAddIntegrationClick = (integrationType: IntegrationType) => {
    createIntegration(integrationType)
  }

  const renderIntegrationPlatformCard = () => {
    return (
      <div className="platform-card-container">
        {integrationPlatform.map(platform => (
          <div className="platform-card">
            <img src={platform.icon} alt="" className="platform-icon" />
            <div className="platform-name">{platform.name}</div>
            <button
              type="button"
              className="button primary add_button"
              onClick={() => {
                handleAddIntegrationClick(platform.type)
              }}
            >
              + ADD
            </button>
            <a className="learn-more-link" href={platform.link} target="_blank" rel="noopener noreferrer">
              <span> Learn more</span> <MoveUpRightArrowIcon />
            </a>
          </div>
        ))}
      </div>
    )
  }

  return (
    <CloseableDialog isShowing={openCreateIntegrationDialog} onClose={onClose}>
      <div className="create-integration-dialog">
        {modalLoading && (
          <div className="loader-overlay">
            <div className="create-integration-dialog-loader">
              <LoadingSpinner />
            </div>
          </div>
        )}
        <div className="title">Select an integration</div>
        {renderIntegrationPlatformCard()}
      </div>
    </CloseableDialog>
  )
}

export default CreateIntegrationDialog
