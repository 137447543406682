import React from 'react'
import Table from 'components/shared/Table/Table'
import { FlatDocumentAssets } from 'state/types'
import './DocumentAssetsTable.scss'

type Props = {
  rows: FlatDocumentAssets[]
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  onAddClick: (value?: string, optimistic?: boolean) => Promise<void>
}

const DocumentAssetsTable: React.FC<Props> = ({
  rows,
  sortingBy,
  sortingAsc,
  onSortingByChange,
  onSortingAscChange,
  onAddClick,
}) =>
  rows && (
    <Table
      className="document-assets-table"
      columns={[
        {
          name: 'documentName',
          label: 'Title',
          className: 'asset-title',
        },
        {
          name: 'previewName',
          label: 'Preview Title',
          className: 'preview-name',
        },
        {
          name: 'dateCreated',
          label: 'Date created',
          className: 'date-created',
        },
        {
          name: 'addLink',
          className: 'add-link',
          render: ({ url }) => (
            <button className="primary outline" onClick={() => onAddClick(url)}>
              Add
            </button>
          ),
        },
      ]}
      rows={rows}
      sortable
      sortingBy={sortingBy}
      sortingAsc={sortingAsc}
      onSortingByChange={onSortingByChange}
      onSortingAscChange={onSortingAscChange}
    />
  )

export default DocumentAssetsTable
