import React from 'react'
import { InvitationSuccessPageInfo } from '../../../state/types'
import { getBusinessLogoImageUrl } from '../../../helpers/cloudinary'
import './Title.scss'

interface Props {
  previewType: 'preview' | 'invitation'
  onPublish?: boolean // true if user just published a preview or an invitation
  invitationSuccessPage?: InvitationSuccessPageInfo
  invitationOrgName?: string
}

const Title = function ({ invitationOrgName, invitationSuccessPage, previewType, onPublish }: Props) {
  const onPublishText = function () {
    return (
      <h2>
        Success! Your Preview has been published!
        <br /> Now you can get sharing...
      </h2>
    )
  }

  if (invitationSuccessPage && invitationOrgName) {
    const onReturnLinkClick = (returnLink: string) => {
      if (returnLink.startsWith('https://') || returnLink.startsWith('http://')) {
        window.open(returnLink, '_blank')
      } else {
        window.open('https://' + returnLink, '_blank')
      }
    }
    return (
      <div className="title">
        <div className="org-row">
          {invitationSuccessPage.logo ? (
            <img className="business-logo-image" src={getBusinessLogoImageUrl(invitationSuccessPage.logo)} />
          ) : (
            <h1 className="org-name">{invitationOrgName}</h1>
          )}
        </div>
        {invitationSuccessPage.title ? <h2>{invitationSuccessPage.title}</h2> : onPublishText()}
        <span className="success-message">{invitationSuccessPage.message}</span>
        {invitationSuccessPage.returnLink && (
          <div onClick={() => onReturnLinkClick(invitationSuccessPage.returnLink!)} className="return-link">
            {invitationSuccessPage.returnLinkTitle || invitationSuccessPage.returnLink}
          </div>
        )}
        <span>You can also share your Preview using any of the methods below.</span>
      </div>
    )
  } else if (onPublish) {
    return onPublishText()
  } else {
    return (
      <h2>
        Share your {previewType.charAt(0).toUpperCase()}
        {previewType.slice(1)}
      </h2>
    )
  }
}

export default Title
