import { Invitation, PreviewSummary, User } from 'state/types'
import {
  InvitationResponsesLabelFilterOption,
  InvitationsCreatorFilterOption,
  InvitationsStatusFilterOption,
} from './enum'

export const getLabelFilterOptionAppliedToInvitationResponse = (
  preview: PreviewSummary
): InvitationResponsesLabelFilterOption => {
  return preview.label as InvitationResponsesLabelFilterOption
}

export const getStateFilterOptionAppliedToInvitation = (invitation: Invitation): InvitationsStatusFilterOption => {
  return invitation.closed ? InvitationsStatusFilterOption.Closed : InvitationsStatusFilterOption.Open
}

export const getCreatorFilterOptionAppliedToInvitation = (
  invitation: Invitation,
  user: User
): InvitationsCreatorFilterOption => {
  return invitation.userId === user.id ? InvitationsCreatorFilterOption.JustMe : InvitationsCreatorFilterOption.Everyone
}

export const filterInvitations = (
  selectedStateFilterOption: InvitationsStatusFilterOption,
  selectedCreatorFilterOption: InvitationsCreatorFilterOption,
  allInvitations: Invitation[],
  user: User
): Invitation[] => {
  const filteredInvitations: Invitation[] = []
  allInvitations.forEach(invitation => {
    let addToFilteredInvitations = true
    const invitationStateFilter: InvitationsStatusFilterOption = getStateFilterOptionAppliedToInvitation(invitation)
    if (
      selectedStateFilterOption !== InvitationsStatusFilterOption.All &&
      invitationStateFilter !== selectedStateFilterOption
    ) {
      addToFilteredInvitations = false
    }
    const invitationCreatorFilter: InvitationsCreatorFilterOption = getCreatorFilterOptionAppliedToInvitation(
      invitation,
      user
    )
    if (
      selectedCreatorFilterOption !== InvitationsCreatorFilterOption.Everyone &&
      invitationCreatorFilter !== selectedCreatorFilterOption
    ) {
      addToFilteredInvitations = false
    }

    if (addToFilteredInvitations) {
      filteredInvitations.push(invitation)
    }
  })
  return filteredInvitations
}

export const filterInvitationResponses = (
  selectedLabelFilterOption: InvitationResponsesLabelFilterOption,
  allInvitationResponses: PreviewSummary[]
): PreviewSummary[] => {
  const filteredInvitationResponses: PreviewSummary[] = []
  allInvitationResponses.forEach(preview => {
    let addToFilteredInvitationResponses = true
    const invitationPreviewLabelFilter: InvitationResponsesLabelFilterOption =
      getLabelFilterOptionAppliedToInvitationResponse(preview)
    if (
      selectedLabelFilterOption !== InvitationResponsesLabelFilterOption.All &&
      invitationPreviewLabelFilter !== selectedLabelFilterOption
    ) {
      addToFilteredInvitationResponses = false
    }

    if (addToFilteredInvitationResponses) {
      filteredInvitationResponses.push(preview)
    }
  })

  let sortedDateResponses = filteredInvitationResponses.sort((a, b) => {
    let dateA = new Date(a.firstPublishDate).getTime();
    let dateB = new Date(b.firstPublishDate).getTime();

    return dateB - dateA;
  });

  return sortedDateResponses
}
