import React from 'react'
import Dropdown from 'components/shared/Dropdown/Dropdown'
import './HeaderDropdown.scss'
import { ViewingSelection } from 'state/types'

type Props = {
  selection: ViewingSelection
  activeCount: number
  archivedCount: number
  onSelectionChange: (selection: ViewingSelection) => void
}

const ActiveArchivedHeaderDropdown: React.FC<Props> = ({
  selection,
  activeCount,
  archivedCount,
  onSelectionChange,
}) => {
  const options = [
    { value: 'active', displayValue: 'Integrations' },
    { value: 'archived', displayValue: `Archived` },
  ]

  const onChange = (value: string) => onSelectionChange(value === 'archived' ? 'archived' : 'active')
  return (
    <Dropdown
      id="active-archived-header-dropdown"
      className="active-archived-header-dropdown"
      selectedValue={selection}
      options={options}
      onChange={onChange}
      renderOption={value =>
        value === 'active' ? `Integrations (${activeCount})` : `Archived (${archivedCount})`
      }
    />
  )
}

export default ActiveArchivedHeaderDropdown
