import ProfileImage from '../../../shared/ProfileImage/ProfileImage'
import React from 'react'
import { getProfileImageUrl } from '../../../../helpers/cloudinary'
import './AudioOnlyProfileImage.scss'

interface Props {
  cloudinaryId?: string
}

const AudioOnlyProfileImage = function ({ cloudinaryId }: Props) {
  const cloudinaryStorageId = cloudinaryId
  const imageUrl = cloudinaryStorageId && getProfileImageUrl(cloudinaryStorageId)

  return (
    <div className="audio-only-profile-image">
      <ProfileImage imageUrl={imageUrl} />
    </div>
  )
}

export default AudioOnlyProfileImage
