import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import "./ColorPicker.scss";
import { ColorResult } from "react-color";
import { Link } from "react-router-dom";

type Props = {
  label: string;
  onColorBgChange: any;
  type: string;
  existingValue: string;
  plan: boolean;
};
const ColorPicker: React.FC<Props> = ({ label, onColorBgChange, type, existingValue, plan, }) => {
  const [selectedColor, setSelectedColor] = useState("");
  const [closeButton, setCloseButton] = useState("");

  useEffect(() => {
    setSelectedColor(existingValue? existingValue: "#fff");
  }, [existingValue]);

  const handleColorChange = (color: ColorResult) => {
    let colorSelected = color.hex
    if(color.rgb.a !== 1){
      colorSelected = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
    }
    setSelectedColor(colorSelected)
    onColorBgChange(colorSelected, type);
  };

  const handleCircleClick = () => {
    setCloseButton("open");
  };

  const handleCloseClick = () => {
    setCloseButton("");
  };

  return (
    <>
      <div className="color-picker-container hover-container-disable">
        <div
          className={
            "hint-popup-container hint-popup-trigger" +
            (plan === true ? " disabled-popup" : "")
          }
        >
          <div className="color-container item-container-hover">
            <div className="image-uploader-hint-popup">
              <div className="hint-popup">
                <div className="left-arrow-bg"></div>
                <div className="left-arrow"></div>
                <div className="hint-title">Plan upgrade required</div>
                <p className="hint-text">
                  If you’d like to use this feature you need to upgrade your
                  current plan.
                  <Link
                    className="hint-link"
                    to="/plan/select?from=/organization/settings/brand"
                  >
                    Upgrade now.
                  </Link>
                </p>
              </div>
            </div>
            <div className="color-picker-frame">
              <div className="color-picker-list">
                <div className="color-picker-item">
                  <p>{label}</p>
                  <div
                    style={{
                      background: selectedColor,
                    }}
                    className={`picker-circle ${
                      plan === true ? "disabled" : ""
                    }`}
                    onClick={() => handleCircleClick()}
                  ></div>
                  {closeButton && (
                    <div className="sketch-container">
                      <SketchPicker
                        color={selectedColor}
                        onChange={handleColorChange}
                      />
                      <button
                        className="close-btn-picker"
                        onClick={handleCloseClick}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColorPicker;
