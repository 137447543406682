import React from 'react'
import DOMPurify from 'dompurify'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import './InstructionDialog.scss'

type Props = {
  isShowing: boolean
  onClose: () => void
  instructions: string
}

const InstructionDialog: React.FC<Props> = ({ isShowing, onClose, instructions }) => {
  DOMPurify.addHook('afterSanitizeAttributes', function (el) {
    // set all elements owning target to target=_blank
    if ('target' in el) {
      // @ts-ignore
      el.setAttribute('target', '_blank')
      // @ts-ignore
      el.setAttribute('rel', 'noopener')
    }
  })

  const createMarkup = () => {
    return { __html: DOMPurify.sanitize(instructions) }
  }

  return (
    <CloseableDialog isShowing={isShowing} onClose={onClose}>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </CloseableDialog>
  )
}

export default InstructionDialog
