import React from 'react'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import './LoadingButton.scss'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean
  sideBySideLayout?: boolean // Loading spinner will be to the right of the text rather than replace it.
}

const LoadingButton: React.FC<Props> = ({ children, isLoading, sideBySideLayout, className, disabled, ...rest }) => (
  <button
    className={'loading-button' + (isLoading ? ' loading' : '') + (className ? ' ' + className : '')}
    disabled={isLoading || disabled}
    {...rest}
  >
    {sideBySideLayout ? (
      <>
        <div className="side-spacing" />
        <div>{children}</div>
        <div className="side-spacing">{isLoading && <LoadingSpinner />}</div>
      </>
    ) : (
      <>
        <div className="button-content">{children}</div>
        {isLoading && (
          <div className="spinner-container">
            <LoadingSpinner />
          </div>
        )}
      </>
    )}
  </button>
)

export default LoadingButton
