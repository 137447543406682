import React from 'react'
import './AuthErrorMessage.scss'

type Props = {
  text: string
}

const AuthErrorMessage = ({ text }: Props) => <div className="auth-error-message">{text}</div>

export default AuthErrorMessage
