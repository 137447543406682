import FormInput from 'components/shared/FormInput/FormInput'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import ProfileImageSelector from 'components/shared/ProfileImageSelector/ProfileImageSelector'
import SuccessDialog from 'components/shared/SuccessDialog/SuccessDialog'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import useAuth from 'state/useAuth'
import useWorkspace from 'state/useWorkspace'
import * as yup from 'yup'
import './UserProfileForm.scss'
import { getFullName } from '../../../helpers/user'

type FormData = {
  givenName: string
  familyName: string
  phoneNumber: string
}

const deleteImageConfirmationText =
  'If you remove this image from your profile it will be permanently deleted. Would you like to continue?'

const validationSchema = yup.object().shape({
  givenName: yup.string().required('Please enter your first name'),
  familyName: yup.string().required('Please enter your last name'),
})

const UserProfileForm = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSuccessDialogShowing, setSuccessDialogShowing] = useState(false)
  const { user, updateUser } = useAuth()
  const { updateUserProfileImage, removeUserProfileImage } = useWorkspace(false)

  const { handleSubmit, register, errors } = useForm<FormData>({
    validationSchema,
    defaultValues: {
      givenName: user?.givenName,
      familyName: user?.familyName,
      phoneNumber: user?.phoneNumber,
    },
  })

  const onSubmit = handleSubmit(async ({ givenName, familyName, phoneNumber }) => {
    setSubmitting(true)
    const ok = await updateUser({ givenName, familyName, phoneNumber })
    if (ok) {
      setSuccessDialogShowing(true)
    }
    setSubmitting(false)
  })

  return (
    <form className="user-profile-form" onSubmit={onSubmit} noValidate>
      <ProfileImageSelector
        value={user?.profileImageStorageId}
        name={getFullName(user)}
        canDelete
        warnBeforeDeleting
        deleteConfirmationText={deleteImageConfirmationText}
        uploadButtonText="profile image"
        showUploadingLabel
        uploadImage={updateUserProfileImage}
        deleteImage={removeUserProfileImage}
      />
      <FormInput name="givenName" label="First name" inputRef={register} errors={errors} spellCheck={false} />
      <FormInput name="familyName" label="Last name" inputRef={register} errors={errors} spellCheck={false} />
      <FormInput name="phoneNumber" label="Contact number" inputRef={register} type="tel" />
      <LoadingButton className="save-button primary" isLoading={isSubmitting}>
        Save changes
      </LoadingButton>
      <SuccessDialog isShowing={isSuccessDialogShowing} text="Profile updated" setShowing={setSuccessDialogShowing} />
    </form>
  )
}

export default UserProfileForm
