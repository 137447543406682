import { useEffect } from 'react'

// Calls the given handler when a document click occurs outside the given element.
const useOutsideClick = (elementRef: React.RefObject<HTMLElement>, onOutsideClick: () => void) => {
  useEffect(() => {
    const documentListener = (e: Event) => {
      if (elementRef.current && !elementRef.current.contains(e.target as any)) {
        onOutsideClick()
      }
    }
    document.addEventListener('click', documentListener, true)
    return () => document.removeEventListener('click', documentListener, true)
  }, [elementRef, onOutsideClick])
}

export default useOutsideClick
