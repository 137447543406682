import React, { useEffect, useState } from 'react'
import './InvitationReview.scss'
import LoadingButton from '../../shared/LoadingButton/LoadingButton'
import { InvitationEditorStep, InvitationSuccessPageInfo, Option, TempInvitation } from '../../../state/types'
import * as configService from '../../../services/configService'
import { useHistory } from 'react-router'
import * as imageService from '../../../services/imageService'
import * as invitationService from '../../../services/invitationService'
import useInvitation from '../../../state/useInvitation'
import SuccessDialog from '../../shared/SuccessDialog/SuccessDialog'
import { transformEndDate, transformStartDate } from '../../../helpers/date'
import InvitationEditorStepper from '../InvitationEditorStepper/InvitationEditorStepper'

type Props = {
  index: number
  currentInvitationEditorStepIndex: number
  setCurrentInvitationEditorStepIndex: (value: number) => void
  tempInvitation?: TempInvitation
  updatedBrandingImage: boolean
  isEditing?: boolean
  invitationId?: string
  updatedSuccessPageLogoImage?: boolean
  steps: InvitationEditorStep[]
  showEnterpriseFeatures?: boolean
}

const InvitationReview: React.FC<Props> = ({
  index,
  currentInvitationEditorStepIndex,
  setCurrentInvitationEditorStepIndex,
  tempInvitation,
  updatedBrandingImage,
  isEditing,
  invitationId,
  updatedSuccessPageLogoImage,
  steps,
  showEnterpriseFeatures,
}) => {
  const [hideForm, setHideForm] = useState(currentInvitationEditorStepIndex !== index)
  const [industryOptions, setIndustryOptions] = useState<Option[]>([])
  const [isLoading, setLoading] = useState(false)
  const { push } = useHistory()
  const { update } = useInvitation(invitationId)
  const [isSaveConfirmationDialogShowing, setSaveConfirmationDialogShowing] = useState(false)
  const [stepIndex, setStepIndex] = useState<{ index: number }>({ index: currentInvitationEditorStepIndex })

  useEffect(() => {
    const loadIndustryOptions = async () => setIndustryOptions(await configService.getFieldSetOptions('industry'))
    loadIndustryOptions()
  }, [])

  useEffect(() => {
    setHideForm(currentInvitationEditorStepIndex !== index)
  }, [currentInvitationEditorStepIndex])

  const submitInvitationCreation = async () => {
    setLoading(true)
    if (!tempInvitation) {
      window.location.reload()
      return
    }

    let invitationSuccessPageInfo: InvitationSuccessPageInfo | undefined = undefined

    if (
      tempInvitation.invitationSuccessPage &&
      tempInvitation.invitationSuccessPage.title &&
      tempInvitation.invitationSuccessPage.title.length > 0
    ) {
      invitationSuccessPageInfo = {
        ...tempInvitation.invitationSuccessPage,
        logo: undefined,
      }
    }

    if (invitationSuccessPageInfo && tempInvitation.invitationSuccessPage?.logo) {
      const { storageId } = await imageService.uploadImage(tempInvitation.invitationSuccessPage.logo)
      invitationSuccessPageInfo.logo = storageId
    }

    let brandingImageStorageId: string | undefined
    if (tempInvitation.brandingImage) {
      const { storageId } = await imageService.uploadImage(tempInvitation.brandingImage)
      brandingImageStorageId = storageId
    }

    if (tempInvitation.templateId) {
      const apiPromise = invitationService.createInvitation(
        tempInvitation.templateId,
        tempInvitation.name,
        tempInvitation.ownerName,
        tempInvitation.organizationId,
        tempInvitation.industries,
        transformStartDate(tempInvitation.startDate),
        transformEndDate(tempInvitation.endDate),
        tempInvitation.instructions,
        tempInvitation.sendEmailToInviter,
        brandingImageStorageId,
        invitationSuccessPageInfo,
        tempInvitation.videoCategoryTemplates
      )

      // Feels more important somehow if you have to wait a bit, and our API is too fast.
      const fakeDelay = new Promise(resolve => setTimeout(resolve, 600))
      const [{ ok, data: invitationId }]: any = await Promise.all([apiPromise, fakeDelay])

      if (ok && invitationId) {
        push(`/invitations/created/${invitationId}`)
      } else if (!ok) {
        window.location.reload()
      }
    }

    setLoading(false)
  }

  const submitInvitationEditing = async () => {
    setLoading(true)
    if (!tempInvitation) {
      window.location.reload()
      return
    }

    let brandingImageStorageId: string | undefined

    if (updatedBrandingImage && tempInvitation.brandingImage) {
      const { storageId } = await imageService.uploadImage(tempInvitation.brandingImage)
      brandingImageStorageId = storageId
    } else if (!updatedBrandingImage && tempInvitation.existingBrandingImageId) {
      brandingImageStorageId = tempInvitation.existingBrandingImageId
    }

    let invitationSuccessPageInfo: InvitationSuccessPageInfo | undefined = undefined

    if (
      tempInvitation.invitationSuccessPage &&
      tempInvitation.invitationSuccessPage.title &&
      tempInvitation.invitationSuccessPage.title.length > 0
    ) {
      invitationSuccessPageInfo = {
        ...tempInvitation.invitationSuccessPage,
        logo: undefined,
      }
    }

    if (invitationSuccessPageInfo) {
      if (updatedSuccessPageLogoImage && tempInvitation.invitationSuccessPage?.logo) {
        const { storageId } = await imageService.uploadImage(tempInvitation.invitationSuccessPage.logo)
        invitationSuccessPageInfo.logo = storageId
      } else if (!updatedSuccessPageLogoImage && tempInvitation.invitationSuccessPage?.existingLogoImageId) {
        invitationSuccessPageInfo.logo = tempInvitation.invitationSuccessPage.existingLogoImageId
      }
    }

    if (invitationId) {
      const ok = await update(
        tempInvitation.name,
        tempInvitation.ownerName,
        tempInvitation.industries,
        transformStartDate(tempInvitation.startDate),
        transformEndDate(tempInvitation.endDate),
        tempInvitation.instructions,
        tempInvitation.sendEmailToInviter,
        brandingImageStorageId,
        invitationSuccessPageInfo,
        tempInvitation.videoCategoryTemplates
      )

      if (ok) {
        setSaveConfirmationDialogShowing(true)
      }
    }

    setLoading(false)
  }

  const formatIndustries = (industries: string | undefined) => {
    if (!industries) {
      return
    }
    const industriesArray: string[] = JSON.parse(industries)
    const industriesDisplayValues: string[] = industryOptions
      .filter(industryOption => industriesArray.includes(industryOption.value))
      .map(industryOption => industryOption.displayValue)

    return industriesDisplayValues.join(', ')
  }

  useEffect(() => {
    setCurrentInvitationEditorStepIndex(stepIndex.index)
  }, [stepIndex])

  return (
    <>
      <SuccessDialog
        isShowing={isSaveConfirmationDialogShowing}
        text="Changes saved"
        setShowing={setSaveConfirmationDialogShowing}
      />
      {!hideForm && (
        <div className={'invitation-review'}>
          {showEnterpriseFeatures !== undefined && (
            <InvitationEditorStepper
              steps={steps}
              onClick={setStepIndex}
              showEnterpriseFeatures={showEnterpriseFeatures}
            />
          )}
          <h1>Review & Confirm</h1>
          <div className="section-header">
            <h2>Invitation Details</h2>
            <div className={'edit-button'} onClick={() => setCurrentInvitationEditorStepIndex(0)}>
              Edit
            </div>
          </div>
          <div className={'section'}>
            <div className={'text-review'}>
              <p>Invitation title</p>
              <div className={'user-input'}>{tempInvitation ? tempInvitation.name : '-'}</div>
            </div>
            <div className={'text-review'}>
              <p>Workspace name</p>
              <div className={'user-input'}>{tempInvitation ? tempInvitation.ownerName : '-'}</div>
            </div>
            <div className={'text-review'}>
              <p>Opening date</p>
              <div className={'user-input'}>
                {tempInvitation && tempInvitation.startDate
                  ? `${tempInvitation.startDate.getDate()}/${tempInvitation.startDate.getMonth() + 1}/${tempInvitation.startDate.getFullYear()}`
                  : '-'}
              </div>
            </div>
            <div className={'text-review'}>
              <p>Closing date</p>
              <div className={'user-input'}>
                {tempInvitation && tempInvitation.endDate
                  ? `${tempInvitation.endDate.getDate()}/${tempInvitation.endDate.getMonth() + 1}/${tempInvitation.endDate.getFullYear()}`
                  : '-'}
              </div>
            </div>
            <div className={'text-review'}>
              <p>Relevant industries</p>
              <div className={'user-input'}>
                {tempInvitation && tempInvitation.industries ? formatIndustries(tempInvitation.industries) : '-'}
              </div>
            </div>
            <div className={'text-review'}>
              <p>Instructions</p>
              <div className={'user-input'}>
                {tempInvitation && tempInvitation.instructions ? tempInvitation.instructions : '-'}
              </div>
            </div>
            <div className={'text-review'}>
              <p>Email notifications</p>
              <div className={'user-input'}>{tempInvitation && tempInvitation.sendEmailToInviter ? 'On' : 'Off'}</div>
            </div>
          </div>
          <div className="section-header">
            <h2>Video Chapters</h2>
            <div className={'edit-button'} onClick={() => setCurrentInvitationEditorStepIndex(1)}>
              Edit
            </div>
          </div>
          <div className={'section'}>
            {tempInvitation &&
              tempInvitation.videoCategoryTemplates &&
              tempInvitation.videoCategoryTemplates.map((videoCategoryTemplate, index) => {
                return (
                  <div className={'video-chapter-review'} key={index}>
                    <h4>{`${videoCategoryTemplate.position}. ${videoCategoryTemplate.videoCategoryIdTitle} (${videoCategoryTemplate.formatDisplay})`}</h4>
                    <p>{videoCategoryTemplate.helperText}</p>
                  </div>
                )
              })}
          </div>
          <div className="section-header">
            <h2>Branding</h2>
            <div className={'edit-button'} onClick={() => setCurrentInvitationEditorStepIndex(2)}>
              Edit
            </div>
          </div>
          <div className={'section'}>
            <div className={'image-review'}>
              <p>Logo</p>
              <div className={'user-input'}>
                <img
                  className={'business-logo-image'}
                  src={
                    tempInvitation && tempInvitation.brandingImage
                      ? URL.createObjectURL(tempInvitation.brandingImage)
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
          <div className="section-header">
            <h2>Success Page</h2>
            <div className={'edit-button'} onClick={() => setCurrentInvitationEditorStepIndex(3)}>
              Edit
            </div>
          </div>
          <div className={'section'}>
            <div className={'text-review'}>
              <p>Title</p>
              <div className={'user-input'}>
                {tempInvitation && tempInvitation.invitationSuccessPage && tempInvitation.invitationSuccessPage.title
                  ? tempInvitation.invitationSuccessPage.title
                  : '-'}
              </div>
            </div>
            <div className={'text-review'}>
              <p>Message</p>
              <div className={'user-input'}>
                {tempInvitation && tempInvitation.invitationSuccessPage && tempInvitation.invitationSuccessPage.message
                  ? tempInvitation.invitationSuccessPage.message
                  : '-'}
              </div>
            </div>
            <div className={'text-review'}>
              <p>Return link title</p>
              <div className={'user-input'}>
                {tempInvitation &&
                tempInvitation.invitationSuccessPage &&
                tempInvitation.invitationSuccessPage.returnLinkTitle
                  ? tempInvitation.invitationSuccessPage.returnLinkTitle
                  : '-'}
              </div>
            </div>
            <div className={'text-review'}>
              <p>Return link URL</p>
              <div className={'user-input'}>
                {tempInvitation &&
                tempInvitation.invitationSuccessPage &&
                tempInvitation.invitationSuccessPage.returnLink
                  ? tempInvitation.invitationSuccessPage.returnLink
                  : '-'}
              </div>
            </div>
            <div className={'image-review'}>
              <p>Logo on Success</p>
              <div className={'user-input'}>
                <img
                  className={'business-logo-image'}
                  src={
                    tempInvitation && tempInvitation.invitationSuccessPage && tempInvitation.invitationSuccessPage.logo
                      ? URL.createObjectURL(tempInvitation.invitationSuccessPage.logo)
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
          <div className={'bottom-buttons'}>
            <div className={'prev-button'} onClick={() => setCurrentInvitationEditorStepIndex(index - 1)}>
              {'< PREV'}
            </div>
            <LoadingButton
              className="next-button primary"
              isLoading={isLoading}
              onClick={() => (isEditing ? submitInvitationEditing() : submitInvitationCreation())}
            >
              {isEditing ? 'Save changes' : 'Create my invitation'}
            </LoadingButton>
          </div>
        </div>
      )}
    </>
  )
}

export default InvitationReview
