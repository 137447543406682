import TickIcon from 'assets/icons/TickIcon'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import React, { useEffect, useRef, useState } from 'react'
import './PlanCard.scss'
import { Country, SubscriptionPlan } from '../../../helpers/enum'
import useWorkspace from '../../../state/useWorkspace'
import { Organization, Workspace } from '../../../state/types'

type Props = {
  title: string
  subtitle: string
  price: number
  currency?: string
  previousPlanName?: string
  frequency: string
  features: string[]
  theme: string
  accountType: string
  freeTrial: boolean
  current?: boolean
  onSelect: () => void | Promise<void>
  showVAT?: boolean
  currentPlan?:boolean
  togglePage?: number
  currentWorkspace:Workspace | undefined
}

const PlanCard: React.FC<Props> = ({
  title,
  price,
  subtitle,
  currency,
  frequency,
  features,
  theme,
  accountType,
  freeTrial,
  current,
  previousPlanName,
  onSelect,
  showVAT,
  currentPlan,
  togglePage,
  currentWorkspace
}) => {
  const [isLoading, setLoading] = useState(false)
  const mounted = useRef(true)


  const monthlyPrice = (title: string, price: number) => {
      switch (title){
        case 'Solo'  :  return price
        case 'Business' : return price/3
        case 'Enterprise' : return price/3
        case 'Solo Annual': return price/12
        case 'Business Annual': return price/12
        case 'Enterprise Annual': return price/12
        default: return price
      }
    }

  const onSelectClick = async () => {
    setLoading(true)
    await onSelect()
    if (mounted.current) {
      setLoading(false)
    }
  }

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )
  return (
    <div className={'plan-card ' + accountType + ' ' + theme + (current ? ' current' : '')}>
      { currentPlan && <span className={'current-plan'}>Current Plan</span>}
      <h2 className="title">{title !== SubscriptionPlan.Basic ? title : 'Free'}</h2>
      <p className="subtitle">{subtitle}</p>
      {!currentPlan ?
        <p className='price'>
          {price ? (currency ? currency + monthlyPrice(title, price) : "$"+monthlyPrice(title, price)) + frequency : 'Free'}
        </p>
        :
        <div style={{marginTop: "25px"}}></div>
      }

      {title.includes('Annual') && !currentPlan && <span className={'billed-annually'}>Billed Annually</span>}

      {(!title.includes('Annual') && !title.includes(SubscriptionPlan.Solo))
        && title !== SubscriptionPlan.Basic && !currentPlan && <span className={'billed-annually'}>Billed Quarterly</span>}

      {(!title.includes('Annual') && title.includes(SubscriptionPlan.Solo))
        && title !== SubscriptionPlan.Basic && !currentPlan && <span className={'billed-annually'}>Billed Monthly</span>}

      {title !== 'Basic' ? (
        <b className='previous-plan'>
          Everything in
          <b className={'previous-plan-text ' + theme}> {previousPlanName !== 'Basic' ? previousPlanName : 'Free'}</b>, plus:
        </b>
      ) : (
        <span className="previous-plan">Enjoy the following features:</span>
      )}
      <div className="features">
        {features.map((feature, i) => (
          <div key={i} className="feature-row">
            <TickIcon />
            <div className="feature-text">{feature}</div>
          </div>
        ))}
        {(title === 'Enterprise' || title === "Enterprise Annual")&& (
          <div className="additional-feature-text">Additional team members available on request.</div>
        )}
      </div>

      <LoadingButton className="button" isLoading={isLoading} disabled={current} onClick={onSelectClick}>
        {currentPlan? 'Not Available' : current ? 'Your current plan' : freeTrial ? 'Start 14-Day free trial' : 'Select'}
      </LoadingButton>
    </div>
  )
}

export default PlanCard
