import React from 'react'

const AssetsIcon = () => (
  <svg className="icon" width="19px" height="19px" viewBox="0 0 19 19">
    <g strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-347.000000, -170.000000)">
        <g transform="translate(347.000000, 170.000000)">
          <g>
            <rect x="0.5" y="10.5" width="8" height="8" rx="1"></rect>
            <rect x="10.5" y="10.5" width="8" height="8" rx="1"></rect>
            <rect x="5.5" y="0.5" width="8" height="8" rx="1"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default AssetsIcon
