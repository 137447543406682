import React from 'react'
import CloseableDialog from '../../shared/CloseableDialog/CloseableDialog'
import './BulkPublishErrorDialog.scss'

interface Props {
  onClose: () => void
  unpublishablePreviewsCount: number
}

const BulkPublishErrorDialog = function ({ onClose, unpublishablePreviewsCount }: Props) {
  return (
    <CloseableDialog isShowing={true} onClose={onClose}>
      <div className="bulk-publish-error-dialog">
        <h1>You're almost ready to publish!</h1>
        <span>
          <b>{unpublishablePreviewsCount}</b> of your Previews are missing mandatory fields that are required to
          publish. Please review your drafts before submitting this bulk action again.
        </span>
      </div>
    </CloseableDialog>
  )
}

export default BulkPublishErrorDialog
