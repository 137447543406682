import React from 'react'
import { submitNewPassword } from 'services/authService'
import AuthPage from '../AuthPage/AuthPage'
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm'

const ChangePasswordPage = () => (
  <AuthPage>
    <ChangePasswordForm submitNewPassword={submitNewPassword} />
  </AuthPage>
)

export default ChangePasswordPage
