import PreviewThumbnail from 'components/preview/PreviewThumbnail/PreviewThumbnail'
import TemplateThumbnail from 'components/preview/TemplateThumbnail/TemplateThumbnail'
import ConfirmationDialog from 'components/shared/ConfirmationDialog/ConfirmationDialog'
import FormattedText from 'components/shared/FormattedText/FormattedText'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import PageFrame from 'components/shared/PageFrame/PageFrame'
import { event } from 'helpers/analytics'
import React, { useCallback, useState } from 'react'
import ReactGA from 'react-ga'
import { Link, useHistory } from 'react-router-dom'
import usePreviews from 'state/usePreviews'
import useSubscription from 'state/useSubscription'
import './Dashboard.scss'
import { PreviewsCreatorFilterOption, WorkspaceType } from '../../../helpers/enum'
import Filter from '../../shared/Filter/Filter'
import ActiveArchivedHeaderDropdown from '../../shared/ActiveArchivedHeaderDropdown/ActiveArchivedHeaderDropdown'
import useSelectPreviews from '../../../state/useSelectPreviews'
import SelectAllButton from '../../shared/SelectAllButton/SelectAllButton'
import BulkActionButton from '../../shared/BulkActionButton/BulkActionButton'
import PreviewTable from 'components/preview/PreviewTable/PreviewTable'
import PreviewTablePersonal from 'components/preview/PreviewTable/PreviewTablePersonal'
import { Workspace } from 'state/types'
import G2ReviewComponent from '../G2ReviewComponent/G2ReviewComponent'
import { Button } from '@material-ui/core'
import RecordIcon from '../../../assets/icons/RecordIcon'
import CircleRecordIcon from '../../../assets/icons/CircleRecordIcon'
import * as previewService from '../../../services/previewService'

type Props = {
  organizationsLoaded: boolean
  currentWorkspace: Workspace | undefined
  applyTag: boolean | null
}
const Dashboard: React.FC<Props> = ({ organizationsLoaded, currentWorkspace }) => {
  useSubscription(organizationsLoaded)
  const isWorkspaceTypeUser = currentWorkspace?.type === WorkspaceType.User
  const history = useHistory()
  const {
    isTableViewPreviewsLoading,
    isTileViewPreviewsLoading,
    setTableViewPreviewsLoading,
    setTileViewPreviewsLoading,
    tableViewPreviews,
    tileViewPreviews,
    loadingId,
    removingId,
    viewingSelection,
    previewCount,
    archivedPreviewCount,
    viewMode,
    tableViewCurrentPage,
    creator,
    sortingBy,
    sortingAsc,
    itemCount,
    totalItemPerPage,
    paginationRange,
    setViewingSelection,
    createPreview,
    archivePreview,
    restorePreview,
    publishPreview,
    unpublishPreview,
    checkCanPublish,
    deletePreview,
    duplicatePreview,
    archiveBulkPreviews,
    deleteBulkPreviews,
    publishBulkPreviews,
    setViewMode,
    setTableViewCurrentPage,
    setSortingAsc,
    setSortingBy,
    setCreator,
    loadTileViewPreviews,
    onTotalItemPerPageChange,
    setTotalItemPerPage,
  } = usePreviews(organizationsLoaded)

  const { previewsSelected, selectPreview, bulkSelectPreviews, unselectPreview, clear } = useSelectPreviews({
    viewingSelection,
  })

  const [createPreviewDialogData, setCreatePreviewDialogData] = useState<{
    startMessage: string
    templateId: string
  } | null>(null)

  const onGetStartedClick = (templateId: string, startMessage?: string) => {
    if (startMessage) {
      setCreatePreviewDialogData({ startMessage, templateId })
    } else {
      handleCreatePreview(templateId)
    }
  }

  const handleCreatePreview = (templateId: string) => {
    ReactGA.event({
      category: event.previewEditor.category,
      action: event.previewEditor.actions.create,
      label: templateId,
    })
    return createPreview(templateId)
  }

  const onSortingByChange = (sortingBy: string) => {
    setSortingBy(sortingBy)
  }

  const onSortingAscChange = (sortingAsc: boolean) => {
    setSortingAsc(sortingAsc)
  }

  const handlePageChange = (page: number) => {
    setTableViewCurrentPage(page)
  }


  const renderNoDataView = useCallback(() => {
    const isViewingArchived = viewingSelection === 'archived'
    return (
      <div className={'no-previews-container' + (isViewingArchived ? ' archived' : '')}>
        <div className="no-previews-inner-container">
          <div className="no-previews-text">
            {isViewingArchived
              ? 'You have no archived Previews.'
              : archivedPreviewCount > 0
              ? "Looks like you don't have any Previews. The good news is it's easy to get started!"
              : "Looks like you don't have any Previews yet. The good news is it's easy to get started!"}
          </div>
          {!isViewingArchived && (
            <Link className="caps" to="/create">
              Create a Preview
            </Link>
          )}
        </div>
        {!isViewingArchived && <div className="no-previews-illustration" />}
      </div>
    )
  }, [viewingSelection, archivedPreviewCount])


  return (
    <>
      <PageFrame
        className="dashboard-page"
        header={
          <>
            <ActiveArchivedHeaderDropdown
              selection={viewingSelection}
              activeCount={previewCount}
              archivedCount={archivedPreviewCount}
              onSelectionChange={setViewingSelection}
              pageType="preview"
            />
            <div className={'buttons-container'}>
              <button className='create-preview-link button primary' onClick={() => createPreview(currentWorkspace?.type ===
                WorkspaceType.Organization ? '4e83d4ff-7c23-403d-b024-dea27fea0caa' : '0de2a2cc-7dfd-4b52-9758-6a25b226718c')}>
                <div className={'add-icon'}>+</div>
                <span>Record</span>
                <div className={'record-icon-dashboard'}><CircleRecordIcon /></div>
              </button>
              <button className='create-preview-mobile-link button primary ' onClick={() => createPreview(currentWorkspace?.type ===
              WorkspaceType.Organization ? '4e83d4ff-7c23-403d-b024-dea27fea0caa' : '0de2a2cc-7dfd-4b52-9758-6a25b226718c')}>
                <div className={'record-icon-dashboard'}><CircleRecordIcon /></div>
              </button>

              <Link className='view-templates-link' to='/create'>
                <span>View templates</span>
              </Link>
            </div>
          </>
        }
      >
        <div className='g2-review-container'>
          <G2ReviewComponent />
        </div>
        <>
          <div className='sub-header'>
            <div className='filters-wrapper'>
              {currentWorkspace && currentWorkspace.type === WorkspaceType.Organization && (
                <div className='creator-filter'>
                  <Filter
                    filterName={'Creator'}
                    filterOptions={Object.values(PreviewsCreatorFilterOption)}
                    onSelect={setCreator}
                    selectedFilterOption={creator}
                  />
                </div>
              )}
              <div className="menu-group">
                <SelectAllButton
                  previews={tableViewPreviews}
                  bulkSelectPreviews={bulkSelectPreviews}
                  viewingSelection={viewingSelection}
                />
                {previewsSelected.length > 0 && (
                  <BulkActionButton
                    selectedPreviews={previewsSelected}
                    viewingSelection={viewingSelection}
                    doBulkArchive={archiveBulkPreviews}
                    doBulkDelete={deleteBulkPreviews}
                    doBulkPublish={publishBulkPreviews}
                    checkCanPublish={checkCanPublish}
                    clearSelectedIds={clear}
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            {isWorkspaceTypeUser ? (
              <>
                {!isTableViewPreviewsLoading &&
                  (!tableViewPreviews || tableViewPreviews.length === 0) &&
                  renderNoDataView()}
                {isTableViewPreviewsLoading && (!tableViewPreviews || tableViewPreviews.length === 0) && (
                  <div className="dashboard-loader">
                    <LoadingSpinner container expand />
                  </div>
                )}
                <div className="preview-thumbnails">
                  <PreviewTablePersonal
                    previews={tableViewPreviews}
                    sortingBy={sortingBy}
                    sortingAsc={sortingAsc}
                    onSortingByChange={onSortingByChange}
                    onSortingAscChange={onSortingAscChange}
                    previewsSelected={previewsSelected}
                    onArchive={archivePreview}
                    onRestore={restorePreview}
                    onPublish={publishPreview}
                    onUnpublish={unpublishPreview}
                    checkCanPublish={checkCanPublish}
                    onDelete={deletePreview}
                    onDuplicate={duplicatePreview}
                    bulkSelectPreviews={bulkSelectPreviews}
                    onSelect={selectPreview}
                    onUnselect={unselectPreview}
                    currentPage={tableViewCurrentPage}
                    onPageChange={handlePageChange}
                    itemCount={itemCount}
                    tableViewCurrentPage={tableViewCurrentPage}
                    onTotalItemPerPageChange={onTotalItemPerPageChange}
                    totalItemPerPage={totalItemPerPage}
                    paginationRange={paginationRange}
                  />
                </div>
              </>
            ) : !isTableViewPreviewsLoading && (!tableViewPreviews || tableViewPreviews.length === 0) ? (
              renderNoDataView()
            ) : isTableViewPreviewsLoading && (!tableViewPreviews || tableViewPreviews.length === 0) ? (
              <div className="dashboard-loader">
                <LoadingSpinner container expand />
              </div>
            ) : (
              tableViewPreviews.length !== 0 && (
                <div className="preview-thumbnails">
                  <PreviewTable
                    previews={tableViewPreviews}
                    sortingBy={sortingBy}
                    sortingAsc={sortingAsc}
                    onSortingByChange={onSortingByChange}
                    onSortingAscChange={onSortingAscChange}
                    previewsSelected={previewsSelected}
                    onArchive={archivePreview}
                    onRestore={restorePreview}
                    onPublish={publishPreview}
                    onUnpublish={unpublishPreview}
                    checkCanPublish={checkCanPublish}
                    onDelete={deletePreview}
                    onDuplicate={duplicatePreview}
                    bulkSelectPreviews={bulkSelectPreviews}
                    onSelect={selectPreview}
                    onUnselect={unselectPreview}
                    currentPage={tableViewCurrentPage}
                    onPageChange={handlePageChange}
                    itemCount={itemCount}
                    tableViewCurrentPage={tableViewCurrentPage}
                    onTotalItemPerPageChange={onTotalItemPerPageChange}
                    totalItemPerPage={totalItemPerPage}
                    paginationRange={paginationRange}
                  />
                </div>
              )
            )}
          </div>
        </>

        <ConfirmationDialog
          className="create-preview-dialog"
          isShowing={!!createPreviewDialogData}
          onClose={() => setCreatePreviewDialogData(null)}
          title="Before we begin"
          cancelButtonText="No thanks"
          okButtonText="Yes, let's get started"
          onOk={() => handleCreatePreview(createPreviewDialogData?.templateId!)}
        >
          {createPreviewDialogData?.startMessage && (
            <FormattedText jsonString={createPreviewDialogData?.startMessage} />
          )}
        </ConfirmationDialog>
      </PageFrame>
    </>
  )
}

export default Dashboard
