import React from 'react'
import Table from 'components/shared/Table/Table'
import DownloadIcon from 'assets/icons/DownloadIcon'
import InfoPopup from 'components/shared/InfoPopup/InfoPopup'
import { DocumentAnalytics } from 'state/types'
import './DocumentDownloadsTable.scss'

type Props = {
  data: DocumentAnalytics[]
}

const DocumentDownloadsTable: React.FC<Props> = ({ data }) => (
  <div className="document-downloads-table-card">
    <Table
      className="document-downloads-table"
      columns={[
        {
          name: 'document',
          label: 'Document',
          className: 'document',
          render: ({ name, url }) => {
            return (
              <>
                <div className="document-name">{name}</div>
                <div className="document-type">{url?.substring(url.lastIndexOf('.') + 1).toUpperCase()}</div>
              </>
            )
          },
        },
        {
          name: 'viewLink',
          className: 'view-link',
          render: ({ url }) => {
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <DownloadIcon />
                <span>View</span>
              </a>
            )
          },
        },
        {
          name: 'uniqueViews',
          label: (
            <div className="unique-views-label">
              <span>By unique user</span>
              <InfoPopup>
                The number of downloads by distinct individuals, regardless of how often they downloaded.
              </InfoPopup>
            </div>
          ),
          className: 'unique-views',
        },
        {
          name: 'totalViews',
          label: 'Total',
          className: 'total-views',
        },
      ]}
      rows={data}
    />
  </div>
)

export default DocumentDownloadsTable
