import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  sortFlatDocumentAssets,
  getDocumentAssetsInitialSortingBy,
  getDocumentAssetsInitialSortingAsc,
  documentAssetsSortingByKey,
  documentAssetsSortingAscKey,
} from 'helpers/sort'
import { formatDate } from 'helpers/date'
import { parseIndexParam } from 'helpers/urlParams'
import FullScreenPageFrame from 'components/shared/FullScreenPageFrame/FullScreenPageFrame'
import DocumentAssetsTable from 'components/assets/DocumentAssetsTable/DocumentAssetsTable'
import { getConcatenatedTitlesForPreviewLite } from 'helpers/titles'
import useDocumentAssets from 'state/useDocumentAssets'
import useContentBlockField from 'state/useContentBlockField'

import './DocumentAssetsPage.scss'
import DocumentAssetsListMobile from 'components/assets/DocumentAssetsListMobile/DocumentAssetsListMobile'
import LoadingSpinner from '../../shared/LoadingSpinner/LoadingSpinner'

const DocumentAssetsPage = () => {
  const { push } = useHistory()
  const { previewId, index, fieldIndex } = useParams<any>()
  const { isLoading, documentAssets } = useDocumentAssets()
  const { updateValue } = useContentBlockField(previewId, parseIndexParam(index), parseIndexParam(fieldIndex))

  const [sortingBy, setSortingBy] = useState(getDocumentAssetsInitialSortingBy())
  const [sortingAsc, setSortingAsc] = useState(getDocumentAssetsInitialSortingAsc())

  const onSortingByChange = (sortingBy: string) => {
    setSortingBy(sortingBy)
    localStorage.setItem(documentAssetsSortingByKey, sortingBy)
  }

  const onSortingAscChange = (sortingAsc: boolean) => {
    setSortingAsc(sortingAsc)
    localStorage.setItem(documentAssetsSortingAscKey, sortingAsc.toString())
  }

  const onAddClick = async (value?: string, optimistic?: boolean) => {
    if (value) {
      await updateValue(value, optimistic)
      push(`/edit/${previewId}/block/${index}`)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top of page when component mounts.
  }, [])

  let flatDocumentAssets = documentAssets?.flatMap(previewAssets =>
    previewAssets.contentBlockDtoList.flatMap(contentBlockDto =>
      contentBlockDto.contentBlockFields.map(contentBlockField => {
        const url = contentBlockField.value
        const dateCreated = contentBlockField.createdAt
        return {
          contentBlockFieldId: contentBlockField.id,
          url,
          documentName: url ? decodeURIComponent(url.substring(url.lastIndexOf('/') + 1)) : undefined,
          previewName: getConcatenatedTitlesForPreviewLite(previewAssets.previewLite),
          dateCreated: dateCreated ? formatDate(dateCreated) : undefined,
        }
      })
    )
  )

  const sortedFlatDocumentAssets = sortFlatDocumentAssets(flatDocumentAssets, sortingBy, sortingAsc)

  return (
    <FullScreenPageFrame
      className="document-assets-page"
      cancelLink={`/edit/${previewId}/block/${index}`}
      title="Document Content Assets"
      subtitle="These are documents you've uploaded previously. You can select any of them to re-use, and edit the titles once you've added them."
      whiteBackground
    >
      {isLoading ? (
        <LoadingSpinner fullScreen />
      ) : !isLoading && flatDocumentAssets && flatDocumentAssets.length === 0 ? (
        <div className="no-assets-message">You have no document assets yet.</div>
      ) : (
        <>
          {sortedFlatDocumentAssets && (
            <DocumentAssetsTable
              rows={sortedFlatDocumentAssets!}
              sortingBy={sortingBy}
              sortingAsc={sortingAsc}
              onSortingByChange={onSortingByChange}
              onSortingAscChange={onSortingAscChange}
              onAddClick={onAddClick}
            />
          )}
          <div className="document-assets-page-mobile-content">
            {sortedFlatDocumentAssets && (
              <DocumentAssetsListMobile
                rows={sortedFlatDocumentAssets!}
                sortingBy={sortingBy}
                sortingAsc={sortingAsc}
                onSortingByChange={onSortingByChange}
                onSortingAscChange={onSortingAscChange}
                onAddClick={onAddClick}
              />
            )}
          </div>
        </>
      )}
    </FullScreenPageFrame>
  )
}

export default DocumentAssetsPage
