import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { string } from 'yup'
import { InvitationEditorStep, TempInvitation } from 'state/types'
import FormInput from 'components/shared/FormInput/FormInput'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import FormTextArea from 'components/shared/FormTextArea/FormTextArea'
import './InvitationSuccessPageForm.scss'
import SuccessDialog from '../../shared/SuccessDialog/SuccessDialog'
import ProfileImageSelector from '../../shared/ProfileImageSelector/ProfileImageSelector'
import InvitationEditorStepper from '../InvitationEditorStepper/InvitationEditorStepper'
import * as configService from '../../../services/configService'

type FormData = {
  title?: string
  message?: string
  returnLinkTitle?: string
  returnLink?: string
}

const validationSchema = yup.object().shape({
  message: yup.string(),
  returnLinkTitle: yup.string(),
  returnLink: yup.string(),
  title: yup.string().when(['message', 'returnLinkTitle', 'returnLink'], {
    is: (message, returnLinkTitle, returnLink) =>
      message.length > 0 || returnLinkTitle.length > 0 || returnLink.length > 0,
    then: string().required('This field is required'),
    otherwise: string(),
  }),
})

type Props = {
  defaultValues?: FormData
  setTempInvitation: (value: TempInvitation) => void
  tempInvitation?: TempInvitation
  index: number
  currentInvitationEditorStepIndex: number
  setCurrentInvitationEditorStepIndex: (value: number) => void
  setUpdatedSuccessPageLogoImage: (value: boolean) => void
  steps: InvitationEditorStep[]
  showEnterpriseFeatures?: boolean
}

const InvitationSuccessPageForm: React.FC<Props> = ({
  index,
  defaultValues,
  setTempInvitation,
  tempInvitation,
  currentInvitationEditorStepIndex,
  setCurrentInvitationEditorStepIndex,
  setUpdatedSuccessPageLogoImage,
  steps,
  showEnterpriseFeatures,
}) => {
  const [hideForm, setHideForm] = useState(currentInvitationEditorStepIndex !== index)
  const [isSaveConfirmationDialogShowing, setSaveConfirmationDialogShowing] = useState(false)
  const [logoImage, setLogoImage] = useState<Blob>()
  const [stepIndex, setStepIndex] = useState<{ index: number }>({ index: currentInvitationEditorStepIndex })

  const deleteImageConfirmationText =
    'If you remove this image from your profile it will be permanently deleted. Would you like to continue?'

  const { handleSubmit, register, setError, errors, setValue, reset } = useForm<FormData>({
    validationSchema,
    defaultValues: defaultValues,
  })

  const onFormSubmit = handleSubmit(async ({ title, message, returnLinkTitle, returnLink }) => {
    if (!tempInvitation) {
      return
    }

    if (title?.length === 0 && logoImage) {
      setError('title', 'required', 'This field is required')
      return
    }

    const updatedTempInvitation: TempInvitation = {
      ...tempInvitation,
      invitationSuccessPage: {
        title,
        message,
        returnLinkTitle,
        returnLink,
        logo: logoImage,
        existingLogoImageId: tempInvitation.invitationSuccessPage?.existingLogoImageId,
      },
    }

    reset({
      title: title,
      message: message,
      returnLink: returnLink,
      returnLinkTitle: returnLinkTitle,
    })
    setTempInvitation(updatedTempInvitation)
    if (stepIndex.index !== index) {
      setCurrentInvitationEditorStepIndex(stepIndex.index)
    } else {
      setCurrentInvitationEditorStepIndex(index + 1)
    }
  })

  useEffect(() => {
    if (stepIndex.index !== currentInvitationEditorStepIndex) {
      onFormSubmit()
    }
  }, [stepIndex])

  useEffect(() => {
    if (!defaultValues) {
      return
    }

    setValue([
      { title: defaultValues.title },
      { message: defaultValues.message },
      { returnLink: defaultValues.returnLink },
      { returnLinkTitle: defaultValues.returnLinkTitle },
    ])
  }, [currentInvitationEditorStepIndex])

  useEffect(() => {
    if (!tempInvitation?.invitationSuccessPage?.logo) {
      setLogoImage(undefined)
    }
    setHideForm(currentInvitationEditorStepIndex !== index)
  }, [currentInvitationEditorStepIndex])

  useEffect(() => {
    setLogoImage(tempInvitation?.invitationSuccessPage?.logo)
  }, [tempInvitation?.invitationSuccessPage?.logo])

  const uploadTempSuccessPageLogoImage = (blob: Blob) => {
    setLogoImage(blob)
    setUpdatedSuccessPageLogoImage(true)
  }

  const deleteTempSuccessPageLogoImage = () => {
    setLogoImage(undefined)
    setUpdatedSuccessPageLogoImage(true)
  }

  return (
    <>
      <SuccessDialog
        isShowing={isSaveConfirmationDialogShowing}
        text="Changes saved"
        setShowing={setSaveConfirmationDialogShowing}
      />
      {!hideForm && (
        <div className={'invitation-success-page-form'}>
          {showEnterpriseFeatures !== undefined && (
            <InvitationEditorStepper
              steps={steps}
              onClick={setStepIndex}
              showEnterpriseFeatures={showEnterpriseFeatures}
            />
          )}
          <h1>Create Success Page</h1>
          <div className={'info-text'}>
            <p>
              Create a customised message to applicants who submit a Preview. It appears at the end of the application
              process, heros your branding and guides the applicant back to your website or a website of your choice.
            </p>
          </div>
          <FormInput
            name="title"
            label="Success page title*"
            placeholder="e.g. We've received your Preview"
            inputRef={register}
            errors={errors}
            spellCheck={false}
          />
          <FormTextArea
            name="message"
            label="Success page message (Character limit: 750)"
            maxLength={750}
            textAreaRef={register}
            placeholder="eg. The screening process will begin once applications close on DD/MM/YY. You can make changes to your Preview at any time before that date by logging in to your PreviewMe account."
            noLimit
          />
          <FormInput
            name="returnLinkTitle"
            label="Return link title"
            placeholder="eg. Go back to our website"
            inputRef={register}
            errors={errors}
            spellCheck={false}
          />
          <FormInput
            name="returnLink"
            label="Return link URL"
            placeholder="eg. www.acmeco.com"
            inputRef={register}
            errors={errors}
            spellCheck={false}
          />
          <div className={'upload-image-box'}>
            <div className={'profile-image-selector'}>
              <ProfileImageSelector
                canDelete
                localValue={logoImage}
                warnBeforeDeleting={false}
                deleteConfirmationText={deleteImageConfirmationText}
                uploadButtonText=" logo for success page"
                showUploadingLabel
                uploadImageLocally={uploadTempSuccessPageLogoImage}
                deleteImage={deleteTempSuccessPageLogoImage}
              />
            </div>
          </div>
          <div className={'bottom-buttons'}>
            <div className={'prev-button'} onClick={() => setStepIndex({ index: index - 1 })}>
              {'< PREV'}
            </div>
            <LoadingButton
              className="next-button primary"
              onClick={() => {
                setStepIndex({ index: index + 1 })
              }}
            >
              Next
            </LoadingButton>
          </div>
        </div>
      )}
    </>
  )
}

export default InvitationSuccessPageForm
