import { FieldName } from './enum'

export const FIRST_BLOCK_INDEX = 0

export const INITIAL_VIEWS = 0

export const DEFAULT_TABLE_VIEW_ITEMS_PER_PAGE = 15

export const TILE_VIEW_ITEMS_PER_PAGE = 15

export const tableViewTotalItemsPerPage: number[] = [10, 15, 20]

export const MAX_TOTAL_ITEMS_PER_PAGE_INVITATIONS = 50 //Match limit variable on InvitationController Line 572 for the correct result

export const fieldsToHide: string[] = [
  FieldName.Function,
  FieldName.Recipient,
  FieldName.Subject,
  FieldName.Subtitle,
  FieldName.Title,
]

export const ALGOLIA_PREVIEW_INDEX = 'preview'
export const DEFAULT_DASHBOARD_ALGOLIA_PREVIEW_INDEX = 'preview_status_desc'
export const DEFAULT_INIVTATION_RESPONSES_ALGOLIA_PREVIEW_INDEX = 'preview_applicants_desc'

export const ALGOLIA_INVITATION_INDEX = 'invitation'