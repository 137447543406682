import { getWorkspaceOrganizationId } from 'helpers/organization'
import { Preview, PreviewCountByCategory, PreviewTheme } from 'state/types'
import { getApi, getApiWithAuthorization } from './api'

export const getTemplates = async (magicLinkCodes?: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const magicLinkCodeParamString = magicLinkCodes ? `?magicLinkCodes=${magicLinkCodes}` : ''
  return apiWithAuth.get<Preview[]>(`/api/preview/templates${magicLinkCodeParamString}`)
}

export const getTemplateSummary = async (templateId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.get<Preview>(`/api/preview/template/${templateId}`)
}

export const getMyPreviews = async (archived?: boolean, params?: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.get<{
    previewSummaries: Preview[]
    previewsCount: number
    archivedPreviewsCount: number
    previewSuspended: boolean
    suspendedPreviewsCount: number
    itemCount: number
  }>(
    '/api/preview/summaries' +
      (archived !== undefined ? `?archived=${archived}` : '') +
      (workspaceOrganizationId !== undefined
        ? (archived !== undefined ? `&` : `?`) + `organizationId=${workspaceOrganizationId}&${params ? params : ''}`
        : '')
  )
}

export const getPreviewCategoryCount = async () => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.get<PreviewCountByCategory>(
    '/api/preview/previewCategoryCount' +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const createPreview = async (previewTemplateId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.post<string>(
    `/api/preview/create?previewTemplateId=${previewTemplateId}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getPreviewDetails = async (previewId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.get<Preview>(
    `/api/preview/${previewId}/details` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const getPublishedPreviewDetails = async (previewId: string) => {
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return getApi().get<Preview>(
    `/api/publishedPreview/${previewId}/details` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updatePreviewPublished = async (previewId: string, published: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/${previewId}/updatePublished?published=${published}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updateName = async (previewId: string, name?: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/${previewId}/updateName?name=${name || ''}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updatePreviewBusinessLogoImage = async (previewId: string, storageId?: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/${previewId}/updatePreviewBusinessLogoImage?storageId=${storageId || ''}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updatePreviewArchived = async (previewId: string, archived: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/${previewId}/updateArchived?archived=${archived}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const bulkPreviewArchived = async (previewIds: string[], archived: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/updateArchived?archived=${archived}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : ''),
    { ids: previewIds }
  )
}

export const bulkPreviewPublish = async (previewIds: string[], published: boolean) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/updatePublished?published=${published}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : ''),
    { ids: previewIds }
  )
}

export const deletePreview = async (previewId: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.delete<void>(
    `/api/preview/${previewId}` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : '')
  )
}

export const deleteBulkPreviews = async (previewIds: string[]) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview` + (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : ''),
    { ids: previewIds }
  )
}

export const updateTheme = async (previewId: string, theme?: PreviewTheme) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/${previewId}/updateTheme?theme=${theme || ''}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}
export const updateThemeUponCreate = async (previewId: string, theme: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/${previewId}/updateTheme?theme=${theme || ''}` +
    (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const duplicateOrganizationPreview = async (previewId: String, newTitle: string, newSubTitle?: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.post<Preview>(
    `/api/preview/${previewId}/duplicate` +
      (workspaceOrganizationId !== undefined ? `?organizationId=${workspaceOrganizationId}` : ''),
    { title: newTitle, subTitle: newSubTitle }
  )
}

export const duplicateUserPreview = async (previewId: String) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.post<Preview>(
    `/api/preview/${previewId}/duplicate`
  )
}

export const updateTitle = async (previewId: string, title?: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/${previewId}/updateTitle?title=${title || ''}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updateSubtitle = async (previewId: string, subtitle?: string) => {
  const apiWithAuth = await getApiWithAuthorization()
  const workspaceOrganizationId = getWorkspaceOrganizationId()
  return apiWithAuth.patch<void>(
    `/api/preview/${previewId}/updateSubtitle?subtitle=${subtitle || ''}` +
      (workspaceOrganizationId !== undefined ? `&organizationId=${workspaceOrganizationId}` : '')
  )
}

export const updatePreviewTags = async (previewId: string, organizationId: string, tagIds: string[]) => {
  const apiWithAuth = await getApiWithAuthorization()
  return apiWithAuth.patch<void>(`/api/updateTags/preview/${previewId}/organization/${organizationId}`, tagIds)
}