import React from 'react'
import { Link } from 'react-router-dom'
import { useBodyColor } from 'helpers/useBodyColor'
import CancelIcon from 'assets/icons/CancelIcon'
import BackArrowIcon from 'assets/icons/BackArrowIcon'
import './FullScreenPageFrame.scss'
import InvitationEditorStepper from '../../invitation/InvitationEditorStepper/InvitationEditorStepper'
import { InvitationEditorStep } from '../../../state/types'

type Props = {
  className: string
  cancelLink?: string
  prevLink?: string
  title?: string
  subtitle?: string | React.ReactNode
  notFound?: boolean
  whiteBackground?: boolean
  customBackground?: boolean
  showTopBar?: boolean
  topBarText?: string
}

const FullScreenPageFrame: React.FC<Props> = ({
  className,
  cancelLink,
  prevLink,
  title,
  subtitle,
  notFound,
  whiteBackground,
  customBackground,
  showTopBar,
  topBarText,
  children,
}) => {
  useBodyColor(whiteBackground ? undefined : 'cream', customBackground)

  return (
    <div
      className={
        (showTopBar ? 'full-screen-page-frame-top-bar ' : 'full-screen-page-frame ') +
        className +
        (notFound ? ' not-found' : '')
      }
    >
      <div className="page-content">
        {(cancelLink || prevLink) && (
          <div className="navigation-buttons">
            {cancelLink && (
              <Link to={cancelLink}>
                <CancelIcon />
              </Link>
            )}
            {topBarText && <p className="top-bar-text ">{topBarText}</p>}
            {prevLink && (
              <Link to={prevLink}>
                <BackArrowIcon />
              </Link>
            )}
          </div>
        )}
        {title && <h1>{title}</h1>}
        {subtitle && <div className="subtitle">{subtitle}</div>}
        {children}
      </div>
    </div>
  )
}

export default FullScreenPageFrame
