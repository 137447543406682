import { AppBar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AppHeaderToolbar from '../AppHeaderToolbar/AppHeaderToolbar'
import './AppHeader.scss'
import { Helmet } from 'react-helmet'
import { getBusinessLogoFaviconImageUrl } from '../../../helpers/cloudinary'
import useOrganizationBrandSetting from '../../../state/useOrganizationBrandSetting'


interface Props {
  modalProfileHighlight: boolean
}

const AppHeader = ({ modalProfileHighlight }: Props) => {
  const {
   faviconCloudinaryStorageIdData
  } = useOrganizationBrandSetting();

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" type="image/png" href={getBusinessLogoFaviconImageUrl(faviconCloudinaryStorageIdData ?? '')} />
      </Helmet>
      <AppBar
        className={'app-header-desktop'}
        elevation={0}
        color="secondary"
        position={modalProfileHighlight ? 'absolute' : 'fixed'}
        style={{ zIndex: modalProfileHighlight ? 'auto' : 1100 }} //1100 is default
      >
        <AppHeaderToolbar modalProfileHighlight={modalProfileHighlight} />
      </AppBar>

      <AppBar className={'app-header-tablet'} elevation={0} position="sticky">
        <AppHeaderToolbar />
      </AppBar>
    </>
  )
}

export default AppHeader
