import React, { useEffect, useImperativeHandle, useState } from 'react'
import { createConnector } from 'react-instantsearch-dom'
import { PreviewAlgolia } from 'state/types'
import './SearchBox.scss'

const connectWithQuery = createConnector({
  displayName: 'WidgetWithQuery',
  getProvidedProps(props, searchState, searchResults) {
    // Since the `attributeForMyQuery` searchState entry isn't
    // necessarily defined, we need to default its value.
    const currentRefinement = searchState.attributeForMyQuery || ''

    // Connect the underlying component with the `currentRefinement`
    return { currentRefinement, searchResults }
  },
  refine(props, searchState, nextRefinement) {
    // When the underlying component calls its `refine` prop,
    // we update the searchState with the provided refinement.

    return {
      // `searchState` represents the search state of *all* widgets. We need to extend it
      // instead of replacing it, otherwise other widgets will lose their respective state.
      ...searchState,
      attributeForMyQuery: nextRefinement,
    }
  },
  getSearchParameters(searchParameters, props, searchState) {
    // When the `attributeForMyQuery` state entry changes, we update the query
    return searchParameters.setQuery(searchState.attributeForMyQuery || '')
  },
  cleanUp(props, searchState) {
    // When the widget is unmounted, we omit the entry `attributeForMyQuery`
    // from the `searchState`, then on the next request the query will
    // be empty
    const { attributeForMyQuery, ...nextSearchState } = searchState

    return nextSearchState
  },
})

type Props = {
  currentRefinement: any
  refine: any
  searchResults: any
  setSearchResults?: any
  setSearchKey?: any
  searchKey: string
  hide?: boolean
  cachedUpdatedPreviews?: Map<string, PreviewAlgolia>
  cachedHiddenPreviews?: Set<string>
  setHitsCount: null | ((itemCounts: number) => void)
  setItemCount?: null | ((pageCount: number) => void)
  setCurrentPage?: (currentPage: number) => void
  setLoading?: null | ((isLoading: boolean) => void)
  setNoResult?: null | ((isNoResult: boolean) => void)
  setIsGrantedPermission?: (isGrantedPermission: boolean) => void
}

const MySearchBox: React.FC<Props> = ({
  refine,
  searchResults,
  setSearchResults,
  searchKey,
  setSearchKey,
  cachedUpdatedPreviews,
  cachedHiddenPreviews,
  setHitsCount,
  setItemCount,
  setCurrentPage,
  setLoading,
  setNoResult,
  setIsGrantedPermission,
}) => {
  const [myTimeout, setMyTimeout] = useState<any>(0)

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (setSearchKey) setSearchKey(value)

    if (myTimeout) {
      clearTimeout(myTimeout)
    }

    const tmpTO = setTimeout(() => {
      if (setCurrentPage) setCurrentPage(1)
      refine(value)
    }, 400)
    setMyTimeout(tmpTO)
  }

  useEffect(() => {
    if (searchResults.error && searchResults.results === null && setIsGrantedPermission) {
      setIsGrantedPermission(false)
    }

    if (setHitsCount) setHitsCount(searchResults?.results?.nbHits || 0)
    if (setItemCount) setItemCount(searchResults?.results?.nbHits || 1)

    if (setSearchResults) {
      let hits = searchResults?.results?.hits
      if (cachedHiddenPreviews && cachedHiddenPreviews?.size > 0)
        hits = hits.filter((hit: PreviewAlgolia) => !cachedHiddenPreviews.has(hit.id))

      if (cachedUpdatedPreviews && cachedUpdatedPreviews?.size > 0) {
        const newSearchResultHits = hits.map((hit: PreviewAlgolia) => {
          if (cachedUpdatedPreviews.has(hit.id)) {
            return cachedUpdatedPreviews.get(hit.id)
          }
          return hit
        })
        setSearchResults(newSearchResultHits)
      } else {
        setSearchResults(hits || [])
      }
    } else setSearchResults([])

    if (setLoading) {
      if (searchResults?.results === null) setLoading(true)
      else setLoading(false)
    }
    if (setNoResult) {
      if (searchResults?.results?.nbHits === 0) setNoResult(true)
      else setNoResult(false)
    }
  }, [searchResults, cachedHiddenPreviews])

  const clearSearchKey = () => {
    if (setSearchKey) setSearchKey('')

    if (myTimeout) {
      clearTimeout(myTimeout)
    }

    const tmpTO = setTimeout(() => {
      if (setCurrentPage) setCurrentPage(1)
      refine('')
    }, 400)
    setMyTimeout(tmpTO)
  }

  return (
    <>
      <input
        type="input"
        className="search-box"
        placeholder="Search ..."
        value={searchKey}
        onChange={handleSearchQueryChange}
      />
      <div className="search-wrapper">
        <div className="search-icon">
          <div className="search">
            <div className="search__circle"></div>
            <div className="search__rectangle"></div>
          </div>
        </div>
      </div>
      <div className="delete-icon-wrapper" onClick={clearSearchKey}>
        <div className="delete-icon">
          <div className="oval"></div>
          <div className="line-7-1"></div>
          <div className="line-7"></div>
        </div>
      </div>
    </>
  )
}

export const SearchBox = connectWithQuery(MySearchBox)

const MyCountSearchBox: React.FC<{
  setPreviewsCount?: null | ((itemCount: number) => void)
  searchResults: any
  offsetPreviewCount?: number
}> = ({ searchResults, setPreviewsCount, offsetPreviewCount }) => {
  useEffect(() => {
    if (setPreviewsCount && (!!searchResults?.results?.nbHits || searchResults?.results?.nbHits === 0)) {
      const previewCount = searchResults?.results?.nbHits - (offsetPreviewCount || 0)
      setPreviewsCount(previewCount >= 0 ? previewCount : 0)
    }
  }, [searchResults])

  return null
}

export const CountSearchBox = connectWithQuery(MyCountSearchBox)
