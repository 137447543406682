import React from 'react'
import { parseAvailabilityString } from 'helpers/validation'
import { today } from 'helpers/date'
import { textAreaSizeLimit } from 'components/shared/FormTextArea/FormTextArea'
import DatePicker from 'components/shared/DatePicker/DatePicker'
import './AvailabilitySelector.scss'
import Instructions from '../../shared/Instructions/Instructions'

const options = [
  { value: 'immediately', displayValue: 'Available now' },
  { value: 'date', displayValue: 'Select date' },
]

type Props = {
  name?: string
  label?: string
  instructions?: string | undefined
  placeholder?: string
  value?: string
  error?: string
  errors?: any
  onChange?: (value?: string) => void
}

const AvailabilitySelector: React.FC<Props> = ({
  name,
  label,
  instructions,
  placeholder,
  value,
  error,
  errors,
  onChange,
}) => {
  const { selection, date, notes } = parseAvailabilityString(value)
  const errorToShow = error || (name && errors?.[name]?.message)

  const onSelectionChange = (newSelection: string) => {
    onChange?.(JSON.stringify({ selection: newSelection, date, notes }))
  }

  const onDateChange = (newDate?: Date) => {
    onChange?.(JSON.stringify({ selection, date: newDate, notes }))
  }

  const onNotesChange = (newNotes: string) => {
    onChange?.(JSON.stringify({ selection, date, notes: newNotes }))
  }

  return (
    <div className="form-input-container availability-selector">
      <div className="top-label-row">
        <div>
          <label htmlFor="availability-select">{label || 'Availability'}</label>
          {instructions && <Instructions instructions={instructions} />}
        </div>
        {errorToShow && <div className="availability-error">{errorToShow}</div>}
      </div>
      <div className="input-container">
        <select
          id="availability-select"
          placeholder={placeholder}
          value={selection}
          onChange={e => onSelectionChange(e.target.value)}
        >
          <option key="no-selection" value="">
            {''}
          </option>
          {options.map(({ value, displayValue }) => (
            <option key={value} value={value}>
              {displayValue}
            </option>
          ))}
        </select>
        {selection === 'date' && (
          <DatePicker placeholder="Date" value={date} onChange={onDateChange} fromMonth={today()} />
        )}
      </div>
      <label htmlFor="availability-notes" className="notes-label">
        Notes on Availability
      </label>
      <textarea
        id="availability-notes"
        maxLength={textAreaSizeLimit}
        value={notes}
        onChange={e => onNotesChange(e.target.value)}
      />
      <div className="form-text-area-message">Limited to {textAreaSizeLimit} characters</div>
    </div>
  )
}

export default AvailabilitySelector
