import ProfileImageSelector from 'components/shared/ProfileImageSelector/ProfileImageSelector'
import React from 'react'
import useProfileImage from 'state/useProfileImage'
import './PreviewProfileImageSelector.scss'

type Props = {
  previewId: string
  label: string
  isPreviewPublished: boolean
  blockIndex: number
  fieldIndex: number
}

const deleteConfirmationText =
  'If you remove this image from your preview it will be permanently deleted. Would you like to continue?'

const deleteForbiddenText = 'You must first unpublish your preview to remove this image.'

const PreviewProfileImageSelector = ({ previewId, label, isPreviewPublished, blockIndex, fieldIndex }: Props) => {
  const {
    contentBlockField,
    isValidationActive,
    userProfileImageStorageId,
    uploadImage,
    deleteImage,
    copyFromProfile,
  } = useProfileImage(previewId, blockIndex, fieldIndex)

  const field = contentBlockField!
  const showValidationError = isValidationActive && field.required

  return (
    <div className={'preview-profile-image-selector bordered' + (showValidationError ? ' show-validation-error' : '')}>
      <div className="profile-image-label">{label}</div>
      <ProfileImageSelector
        value={field.value}
        canDelete={!field.required || !isPreviewPublished}
        warnBeforeDeleting={!userProfileImageStorageId || field.value !== userProfileImageStorageId}
        deleteConfirmationText={deleteConfirmationText}
        deleteForbiddenText={deleteForbiddenText}
        showUseProfileImageButton={!!userProfileImageStorageId && field.value !== userProfileImageStorageId}
        uploadImage={uploadImage}
        deleteImage={deleteImage}
        useProfileImage={copyFromProfile}
      />
    </div>
  )
}

export default PreviewProfileImageSelector
