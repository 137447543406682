import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import React, { useEffect, useState } from 'react'
import useAuth from 'state/useAuth'
import './DeleteAccountDialog.scss'

type Props = {
  isShowing: boolean
  planName?: string | undefined
  setShowing: (showing: boolean) => void
}

const DeleteAccountDialog: React.FC<Props> = ({ isShowing, planName, setShowing }) => {
  const [deleteText, setDeleteText] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const { deleteAccount } = useAuth()
  const buttonDisabled = deleteText?.toLowerCase() !== 'delete'

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!buttonDisabled) {
      setSubmitting(true)
      const ok = await deleteAccount()

      if (!ok) {
        setSubmitting(false)
      }
    }
  }

  useEffect(() => {
    if (isShowing) {
      setDeleteText('')
    }
  }, [isShowing])

  return (
    <CloseableDialog className="delete-account-dialog" isShowing={isShowing} onClose={() => setShowing(false)}>
      <form onSubmit={onSubmit}>
        <h2>Delete Account</h2>
        {planName && (
          <p className="cancel-subscription-clause">
            You have an active subscription, are you sure you want to proceed.
          </p>
        )}
        <p>
          This will permanently delete all your Previews, including your videos. We will not be able to restore your
          data.
        </p>
        {planName && (
          <p className="cancel-subscription-clause">Your {planName} Plan will be cancelled effective immediately.</p>
        )}
        <p className="delete-account-instructions">
          Type <b>delete</b> to confirm.
        </p>
        <input
          className="delete-account-input"
          spellCheck={false}
          value={deleteText}
          onChange={e => setDeleteText(e.target.value)}
        />
        <LoadingButton
          className={'delete-account-button primary' + (buttonDisabled ? ' disabled' : '')}
          isLoading={isSubmitting}
          disabled={buttonDisabled}
        >
          Delete account
        </LoadingButton>
      </form>
    </CloseableDialog>
  )
}

export default DeleteAccountDialog
