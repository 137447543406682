import React from 'react'
import Dropdown from 'components/shared/Dropdown/Dropdown'
import { FlatDocumentAssets } from 'state/types'
import './DocumentAssetsListMobile.scss'

type Props = {
  rows: FlatDocumentAssets[]
  sortingBy: string
  sortingAsc: boolean
  onSortingByChange: (sortingBy: string) => void
  onSortingAscChange: (sortingAsc: boolean) => void
  onAddClick: (value?: string, optimistic?: boolean) => Promise<void>
}
const sortOptions = [
  { value: 'documentName-true', displayValue: 'Document Name A-Z' },
  { value: 'documentName-false', displayValue: 'Document Name Z-A' },
  { value: 'previewName-true', displayValue: 'Preview Title A-Z' },
  { value: 'previewName-false', displayValue: 'Preview Title Z-A' },
  { value: 'dateCreated-false', displayValue: 'Newest first' },
  { value: 'dateCreated-true', displayValue: 'Oldest first' },
]

const DocumentAssetsListMobile: React.FC<Props> = ({
  rows,
  sortingBy,
  sortingAsc,
  onSortingByChange,
  onSortingAscChange,
  onAddClick,
}) => {
  const selectedValue = sortingBy + '-' + sortingAsc

  const onDropdownChange = (newSelection: string) => {
    const s = newSelection.split('-')
    onSortingByChange?.(s[0])
    onSortingAscChange?.(s[1] === 'true')
  }

  return (
    <div className="document-assets-list-mobile">
      <Dropdown
        className="sort-dropdown"
        selectedValue={selectedValue}
        options={sortOptions}
        onChange={onDropdownChange}
        renderSelectedOption={(_, displayValue) => (
          <div className="sort-dropdown-selected-option">
            <span className="sort-by-label">Sort by:</span>
            <span>{displayValue}</span>
          </div>
        )}
      />
      {rows.map(({ contentBlockFieldId, url, documentName, previewName, dateCreated }) => (
        <div key={contentBlockFieldId} className="document-assets-list-card">
          <div className="title-row">
            <span className="document-chapter-name">{documentName}</span>
          </div>
          <div className="preview-name">{previewName}</div>
          <div className="date-created">{dateCreated}</div>
          <button className="primary outline" onClick={() => onAddClick(url)}>
            Add
          </button>
        </div>
      ))}
    </div>
  )
}

export default DocumentAssetsListMobile
