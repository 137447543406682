import React from 'react'
import { Switch, Route } from 'react-router-dom'
import LoginPage from 'components/auth/LoginPage/LoginPage'
import LoginSuccessPage from 'components/auth/LoginSuccessPage/LoginSuccessPage'
import RegistrationPage from 'components/auth/RegistrationPage/RegistrationPage'
import ConfirmRegistrationPage from 'components/auth/ConfirmRegistrationPage/ConfirmRegistrationPage'
import ForgotPasswordPage from 'components/auth/ForgotPasswordPage/ForgotPasswordPage'
import ChangePasswordPage from 'components/auth/ChangePasswordPage/ChangePasswordPage'

const AuthRoutes = () => (
  <Switch>
    <Route exact path="/auth/login" component={LoginPage} />
    <Route exact path="/auth/loginSuccess" component={LoginSuccessPage} />
    <Route exact path="/auth/register" component={RegistrationPage} />
    <Route exact path="/auth/confirmRegistration" component={ConfirmRegistrationPage} />
    <Route exact path="/auth/forgotPassword" component={ForgotPasswordPage} />
    <Route exact path="/auth/changePassword" component={ChangePasswordPage} />
  </Switch>
)

export default AuthRoutes
