import React from 'react'
import { parseMonthYearString } from 'helpers/validation'
import './FormMonthYear.scss'
import Instructions from '../Instructions/Instructions'

const months = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
]

type Props = {
  name?: string
  label?: string
  instructions?: string | undefined
  error?: string
  value?: string
  onChange?: (value: string) => void
}

const FormMonthYear: React.FC<Props> = ({ name, value, instructions, onChange, label, error }) => {
  const { month, year } = parseMonthYearString(value)

  const onMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => notifyChange(e.target.value, year)
  const onYearChange = (e: React.ChangeEvent<HTMLInputElement>) => notifyChange(month, e.target.value)
  const notifyChange = (m: string, y: string) => onChange?.(JSON.stringify({ month: m, year: y }))

  return (
    <div className="form-month-year bordered">
      <div className="label-row">
        <div>
          {label && <label>{label}</label>}
          {instructions && <Instructions instructions={instructions} />}
        </div>
        {error && <div className="validation-error">{error}</div>}
      </div>
      <div className="inputs">
        <label className="month">
          Month
          <select value={month} onChange={onMonthChange}>
            <option key="no-selection" value="" />
            {months.map(month => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
        </label>
        <label className="year">
          Year
          <input type="number" pattern="[0-9]*" value={year} onChange={onYearChange} />
        </label>
      </div>
    </div>
  )
}

export default FormMonthYear
