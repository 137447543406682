import React, { useState, useRef, useEffect } from 'react'
import { TagOption } from 'state/types'
import DropdownBase from './DropdownBase'
import CrossIcon from 'assets/icons/CrossIcon'
import './MultiSelect.scss'

type Props = {
  id?: string
  className?: string
  selectedValues?: string[]
  placeholder?: string
  options?: TagOption[]
  searchable?: boolean
  onChange?: (value: string[]) => void
}

const MultiSelect: React.FC<Props> = ({
  id,
  className,
  selectedValues,
  placeholder,
  options,
  searchable,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isPopupShowing, setPopupShowing] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const inputRef = useRef<HTMLInputElement>(null)

  const filteredOptions = options?.filter(option => {
    const isNotAlreadySelected = !selectedValues || !selectedValues.includes(option.id)
    const matchesInputText =
      !inputValue ||
      option.name
        .toLowerCase()
        .split(' ')
        .some(word => word.trim().startsWith(inputValue?.trim()?.toLowerCase()))

    return isNotAlreadySelected && matchesInputText
  })

  const noOptionsText =
    options && options.length > 0
      ? !inputValue
        ? 'No more options available'
        : 'No options found'
      : 'No options available'

  const selectedOptions = selectedValues
    ?.map(selection => options?.find(({ id: s }) => selection === s)!)
    .filter(s => !!s)

  const onInputChange = (newInputValue: string) => {
    setInputValue(newInputValue)
    setPopupShowing(true)
  }

  const onInputKeyDown = (key: string, code: number) => {
    if ((key === 'Backspace' || code === 8) && !inputValue && selectedValues && selectedValues.length > 0) {
      onChange?.(selectedValues.slice(0, selectedValues.length - 1))
    }
  }

  const onOptionSelected = (selectedValue: string) => {
    const newSelectedValues = selectedValues ? [...selectedValues, selectedValue] : [selectedValue]
    if (options && newSelectedValues.length === options.length) {
      setPopupShowing(false)
    }
    setInputValue('')
    onChange?.(newSelectedValues)
  }

  const onRemoveClick = (selectedValue: string) => {
    onChange?.(selectedValues ? selectedValues.filter(v => v !== selectedValue) : [])
  }

  const onBackgroundClick = () => {
    if (searchable) {
      setPopupShowing(true)
      inputRef.current?.focus()
    } else {
      setPopupShowing(!isPopupShowing)
    }
  }

  useEffect(() => {
    if (!isPopupShowing) {
      setInputValue('')
    }
  }, [isPopupShowing])

  useEffect(() => {
    if (inputValue) {
      setSelectedIndex(0)
    }
  }, [inputValue])

  return (
    <DropdownBase
      id={id}
      className={
        'multi-select' +
        (className ? ' ' + className : '') +
        (searchable ? ' searchable' : '') +
        (selectedOptions && selectedOptions?.length > 0 ? ' has-selected-options' : '')
      }
      isPopupShowing={isPopupShowing}
      selectedIndex={selectedIndex}
      options={filteredOptions}
      noOptionsText={noOptionsText}
      setPopupShowing={setPopupShowing}
      setSelectedIndex={setSelectedIndex}
      onOptionSelected={onOptionSelected}
    >
      <div className="wrap-container">
        <div className="clickable-background" onClick={onBackgroundClick} />
        {selectedOptions?.map(({ id, name }) => (
          <div key={id} className="selected-option">
            <span>{name}</span>
            <button
              type="button"
              className="remove-selection-button plain"
              onClick={() => onRemoveClick(id)}
              onMouseDown={e => e.preventDefault()}
              tabIndex={-1}
            >
              <CrossIcon />
            </button>
          </div>
        ))}
        {(searchable || !selectedOptions || selectedOptions.length === 0) && (
          <input
            className="multi-select-input"
            placeholder={!selectedValues || selectedValues.length === 0 ? placeholder : undefined}
            value={inputValue}
            spellCheck={false}
            autoComplete="none"
            ref={inputRef}
            disabled={!searchable}
            onChange={e => onInputChange(e.target.value)}
            onFocus={() => setPopupShowing(true)}
            onBlur={() => setPopupShowing(false)}
            onKeyDown={e => onInputKeyDown(e.key, e.keyCode)}
            onClick={() => setPopupShowing(true)}
          />
        )}
      </div>
      <button
        type="button"
        className="toggle-popup-button plain"
        onClick={() => setPopupShowing(!isPopupShowing)}
        tabIndex={-1}
      />
    </DropdownBase>
  )
}

export default MultiSelect
