import CrossIcon from 'assets/icons/CrossIcon'
import CloseableDialog from 'components/shared/CloseableDialog/CloseableDialog'
import { isMobile } from 'helpers/browser'
import { getWorkspaceOrganization } from 'helpers/organization'
import React, { useEffect, useState } from 'react'
import { InvitedTeamMember, InvitedTeamMemberInputError } from 'state/types'
import useWorkspace from 'state/useWorkspace'
import * as yup from 'yup'
import InviteTeamMembersInput from '../InviteTeamMembersInput/InviteTeamMembersInput'
import './InviteMemberDialog.scss'

type Props = {
  openInviteMemberDialog: boolean
  setOpenInviteMemberDialog: (value: boolean) => void
  onInvitesSent: () => Promise<void>
}

const InviteTeamMembersDialog: React.FC<Props> = ({
  openInviteMemberDialog,
  setOpenInviteMemberDialog,
  onInvitesSent,
}) => {
  const workspaceOrganization = getWorkspaceOrganization()
  const [teamMembers, setTeamMembers] = useState<(InvitedTeamMember | undefined)[]>([undefined])
  const [errors, setErrors] = useState<InvitedTeamMemberInputError[]>([])
  const [invitationsSentToTeamMembers, setInvitationsSentToTeamMembers] = useState<boolean>(false)
  const { sendInvitesToAllTeamMembers } = useWorkspace(false)

  let validationSchema = yup.object({
    invitedTeamMembers: yup.array().of(
      yup.object().shape({
        email: yup.string().email('Must provide a valid email address').required('Please provide the email address'),
        role: yup.string().required('Please provide the role'),
        index: yup.number(),
      })
    ),
  })

  useEffect(() => {
    if (openInviteMemberDialog) {
      setTeamMembers([undefined])
      setErrors([])
      setInvitationsSentToTeamMembers(false)
    }
  }, [openInviteMemberDialog])

  const onClose = async () => {
    if (invitationsSentToTeamMembers) {
      await onInvitesSent()
    }
    setOpenInviteMemberDialog(false)
  }

  const showMessage = (index: number) => {
    let message = ''
    let firstMessageFound = false
    errors.forEach(error => {
      if (!firstMessageFound && error.index === index) {
        message = error.message
        firstMessageFound = true
      }
    })

    return message
  }

  const handleSubmit = () => {
    let errorsAfterValidation: InvitedTeamMemberInputError[] = []

    validationSchema
      .validate({ invitedTeamMembers: teamMembers }, { abortEarly: false })
      .then(async () => {
        setErrors([])
        sendInvitesToAllTeamMembers(teamMembers)
        setInvitationsSentToTeamMembers(true)
      })
      .catch((err: yup.ValidationError) => {
        err.inner.forEach(error => {
          const regexIndex = error.path.match('\\[([0-9]*?)\\]')
          if (regexIndex) {
            errorsAfterValidation.push({ message: error.message, index: parseInt(regexIndex[1]) })
          }
        })
        setErrors(errorsAfterValidation)
      })
  }

  const removeInvitedTeamMembers = (i: number) => {
    let inviteTeamMembersWithRemoval = teamMembers.slice(0, i).concat(teamMembers.slice(i + 1))
    let updatedErrors: InvitedTeamMemberInputError[] = []

    errors.forEach(error => {
      if (error.index !== i) {
        if (error.index > i) {
          updatedErrors.push({ message: error.message, index: error.index - 1 })
        } else {
          updatedErrors.push({ message: error.message, index: error.index })
        }
      }
    })

    setErrors(updatedErrors)
    setTeamMembers(inviteTeamMembersWithRemoval)
  }

  const addInvitedTeamMembers = () => {
    teamMembers.push(undefined)
    setTeamMembers([...teamMembers])
  }

  return (
    <CloseableDialog isShowing={openInviteMemberDialog} onClose={onClose}>
      <div className={'invite-member-dialog'}>
        {invitationsSentToTeamMembers ? (
          <div className={'invites-sent'}>
            <p className="title">Invite(s) sent!</p>
            <button
              type="button"
              className="finished-button button primary"
              onClick={() => {
                onClose()
              }}
            >
              I'm Finished
            </button>
          </div>
        ) : (
          <div className={'invite-member-inputs'}>
            <p className="title">
              Add team member(s) to <b>{workspaceOrganization ? workspaceOrganization.name : ''}</b>
            </p>
            <div
              className={
                (isMobile
                  ? teamMembers.length > 1
                    ? 'stop-overflow '
                    : ''
                  : teamMembers.length > 2
                  ? 'stop-overflow'
                  : '') + ' invite-member-dialog-fields'
              }
            >
              {Array.from(Array(teamMembers.length), (e, i) => {
                return (
                  <div key={i}>
                    {i > 0 && (
                      <div
                        className="remove-button"
                        onClick={() => {
                          removeInvitedTeamMembers(i)
                        }}
                      >
                        <CrossIcon />
                        <span>Remove</span>
                      </div>
                    )}
                    <InviteTeamMembersInput
                      setTeamMembers={setTeamMembers}
                      teamMembers={teamMembers}
                      index={i}
                      errorMessage={showMessage(i)}
                    />
                  </div>
                )
              })}
            </div>

            <button
              type="button"
              className="add-team-members-button"
              onClick={() => {
                addInvitedTeamMembers()
              }}
            >
              {'+ Add team member'}
            </button>

            <button
              type="button"
              className="send-invites-button button primary"
              onClick={() => {
                handleSubmit()
              }}
            >
              send invites
            </button>
          </div>
        )}
      </div>
    </CloseableDialog>
  )
}

export default InviteTeamMembersDialog
