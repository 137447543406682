import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import "./FontPicker.scss";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { ColorResult } from "react-color";
import FontDropdown from "../FontDropdown/FontDropdown";
import { Link } from "react-router-dom";

interface HeadingData {
  color: string;
  font: string;
  displayName:string;
  variant:number
}

type Props = {
  label: string;
  onFontChange: (color: string, font: string, type: string, displayName:string, variant:number) => void;
  type: string;
  existingValue: HeadingData;
  plan: boolean;
};

// Constants defined outside the component:
const INITIAL_COLORS = ["#000"];


const FontPicker: React.FC<Props> = ({ label, onFontChange, type, existingValue, plan, }) => {
  const [selectedColor, setSelectedColor] = useState<string>(
    existingValue?.color ?? ""
  );
  const [selectedOption, setSelectedOption] = useState<string>(
    existingValue?.font ?? ""
  );
  const [displayNameData, setDisplayNameData] = useState<string>(
    existingValue?.displayName ?? ""
  );
  const [variantData, setVariantData] = useState<number>(0);
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const [fontChange, setFontChange] = useState<string>("");

  useEffect(() => {
    setSelectedColor(existingValue?.color ?? '');
    setSelectedOption(existingValue?.font ?? '');
    setVariantData(existingValue?.variant ?? 0);
    setDisplayNameData(existingValue?.displayName ?? '');

  }, [existingValue]);

  const handleSelectChange = (fontName: string,displayName: string,variant: number) => {
    const selectedFont = fontName;
    setSelectedOption(selectedFont);
    setVariantData(variant);
    setDisplayNameData(displayName);
    onFontChange(selectedColor, selectedFont, type, displayName, variant);
  };

  const handleColorChange = (color: ColorResult) => {
    let colorSelected = color.hex
    if(color.rgb.a !== 1){
      colorSelected = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
    }
    setSelectedColor(colorSelected);
    onFontChange(colorSelected, selectedOption, type,displayNameData,variantData);
  };
  const handleColorCircleClick = () => {
    setColorPickerOpen(true);
  };

  const handleColorPickerClose = () => {
    setColorPickerOpen(false);
  };

  return (
    <div className="font-picker-container hover-container-disable">
      <div
        className={
          "hint-popup-container hint-popup-trigger" +
          (plan === true ? " disabled-popup" : "")
        }
      >
        <div className="select-container item-container-hover">
          <div className="image-uploader-hint-popup">
            <div className="hint-popup">
              <div className="left-arrow-bg"></div>
              <div className="left-arrow"></div>
              <div className="hint-title">Plan upgrade required</div>
              <p className="hint-text">
                If you’d like to use this feature you need to upgrade your
                current plan.
                <Link
                  className="hint-link"
                  to="/plan/select?from=/organization/settings/brand"
                >
                  Upgrade now.
                </Link>
              </p>
            </div>
          </div>
          <h4>{label}</h4>
          <FormControl
            className={`select-form-control  ${
              plan === true ? "disabled" : ""
            }`}
          >
            <FontDropdown
              existingValue={existingValue?.displayName}
              onFontChange={handleSelectChange}
            ></FontDropdown>
          </FormControl>
          <div className="color-picker-frame">
            <div className="color-picker-list">
              <div className="color-picker-item">
                <p>Colour</p>
                <div
                  className={`picker-circle ${plan === true ? "disabled" : ""}`}
                  style={{ background: selectedColor }}
                  onClick={handleColorCircleClick}
                ></div>
                {colorPickerOpen && (
                  <div
                    className={`sketch-container ${
                      plan === true ? "disabled" : ""
                    }`}
                  >
                    <SketchPicker
                      color={selectedColor}
                      onChange={handleColorChange}
                    />
                    <button
                      className="close-btn-picker"
                      onClick={handleColorPickerClose}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FontPicker;
