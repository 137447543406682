import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import isAfter from 'date-fns/isAfter'
import * as configService from 'services/configService'
import {
  reverseTransformEndDate,
  reverseTransformStartDate,
  today,
  tomorrow,
  transformEndDate,
  transformStartDate,
  yesterdayMidday,
} from 'helpers/date'
import { InvitationEditorStep, Option, Product, Subscription, TempInvitation } from 'state/types'
import useAuth from 'state/useAuth'
import FormInput from 'components/shared/FormInput/FormInput'
import LoadingButton from 'components/shared/LoadingButton/LoadingButton'
import FormMultiSelect from 'components/shared/FormMultiSelect/FormMultiSelect'
import FormDatePicker from 'components/shared/FormDatePicker/FormDatePicker'
import FormTextArea from 'components/shared/FormTextArea/FormTextArea'
import FormCheckbox from 'components/shared/FormCheckbox/FormCheckbox'
import './InvitationForm.scss'
import { UpgradeToEnterpriseDialog } from '../UpgradeToEnterpriseDialog/UpgradeToEnterpriseDialog'
import { ApiResponse } from 'apisauce'
import * as invitationService from '../../../services/invitationService'
import { getWorkspaceOrganizationId } from '../../../helpers/organization'
import { useHistory } from 'react-router-dom'
import useInvitation from '../../../state/useInvitation'
import SuccessDialog from '../../shared/SuccessDialog/SuccessDialog'
import { InvitationLearnMoreDialog } from '../InvitationLearnMoreDialog/InvitationLearnMoreDialog'
import InvitationEditorStepper from '../InvitationEditorStepper/InvitationEditorStepper'

type FormData = {
  name: string
  ownerName: string
  industries?: string
  startDate?: Date
  endDate?: Date
  instructions?: string
  sendEmailToInviter?: boolean
}

const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required('This field is required'),
    ownerName: yup.string().required('This field is required'),
  })
  .test('dates-make-sense', 'The closing date cannot be earlier than the opening date.', ({ startDate, endDate }) =>
    startDate && endDate ? !isAfter(startDate, endDate) : true
  )

type Props = {
  invitationId?: string
  submitText: string | React.ReactNode
  cancelText?: string
  defaultValues?: FormData
  isEditing?: boolean
  newSubscription?: Subscription
  newProduct?: Product
  changePlan?:(subscription: Subscription, billingType: string | undefined) => Promise<ApiResponse<Subscription, Subscription>>
  onCancel?: () => void
  showEnterpriseFeatures?: boolean
  setShowEnterpriseFeatures?: (value: boolean) => void
  setTempInvitation: (value: TempInvitation) => void
  tempInvitation?: TempInvitation
  templateId?: string
  index: number
  currentInvitationEditorStepIndex: number
  setCurrentInvitationEditorStepIndex: (value: number) => void
  steps: InvitationEditorStep[]
}

const InvitationForm: React.FC<Props> = ({
  invitationId,
  index,
  submitText,
  cancelText,
  defaultValues,
  isEditing,
  onCancel,
  newSubscription,
  newProduct,
  changePlan,
  showEnterpriseFeatures,
  setShowEnterpriseFeatures,
  setTempInvitation,
  tempInvitation,
  templateId,
  currentInvitationEditorStepIndex,
  setCurrentInvitationEditorStepIndex,
  steps,
}) => {
  const [industryOptions, setIndustryOptions] = useState<Option[]>([])
  const [isUpgradeToEnterpriseDialog, setUpgradeToEnterpriseDialog] = useState(false)
  const [isInvitationLearnMoreDialogIsShowing, setInvitationLearnMoreDialogIsShowing] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [hideForm, setHideForm] = useState(currentInvitationEditorStepIndex !== index)
  const [isSaveConfirmationDialogShowing, setSaveConfirmationDialogShowing] = useState(false)
  const [stepIndex, setStepIndex] = useState<{ index: number }>({ index: index })
  const { user } = useAuth()
  const { push } = useHistory()
  const { update } = useInvitation(invitationId)
  const mountedRef = useRef(true)
  const { handleSubmit, register, control, errors, setValue } = useForm<FormData>({
    validationSchema,
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          startDate: reverseTransformStartDate(defaultValues.startDate),
          endDate: reverseTransformEndDate(defaultValues.endDate),
        }
      : { industries: user?.industries, sendEmailToInviter: true },
  })

  const dateRangeError = (errors as any)['undefined']?.message

  const submitInvitationCreation = async (
    organizationId: string,
    name: string,
    ownerName: string,
    industries?: string,
    startDate?: string,
    endDate?: string,
    instructions?: string,
    sendEmailToInviter?: boolean,
    templateId?: string
  ) => {
    if (!templateId) {
      return
    }

    const apiPromise = invitationService.createInvitation(
      templateId,
      name,
      ownerName,
      organizationId,
      industries,
      startDate,
      endDate,
      instructions,
      sendEmailToInviter
    )

    // Feels more important somehow if you have to wait a bit, and our API is too fast.
    const fakeDelay = new Promise(resolve => setTimeout(resolve, 600))
    const [{ ok, data: invitationId }]: any = await Promise.all([apiPromise, fakeDelay])

    if (ok && invitationId) {
      push(`/invitations/created/${invitationId}`)
    } else if (!ok) {
      window.location.reload()
    }
  }

  const submitInvitationEditing = async (
    name: string,
    ownerName: string,
    industries?: string,
    startDate?: string,
    endDate?: string,
    instructions?: string,
    sendEmailToInviter?: boolean
  ) => {
    if (invitationId) {
      const ok = await update(name, ownerName, industries, startDate, endDate, instructions, sendEmailToInviter)
      if (ok) {
        setSaveConfirmationDialogShowing(true)
      }
    }
  }

  const onSubmit = async (
    name: string,
    ownerName: string,
    industries?: string,
    startDate?: string,
    endDate?: string,
    instructions?: string,
    sendEmailToInviter?: boolean
  ) => {
    const workspaceOrganizationId = getWorkspaceOrganizationId()
    if (!workspaceOrganizationId) {
      return
    }

    if (!showEnterpriseFeatures && isEditing) {
      await submitInvitationEditing(name, ownerName, industries, startDate, endDate, instructions, sendEmailToInviter)
      return
    }

    if (!showEnterpriseFeatures && !isEditing) {
      await submitInvitationCreation(
        workspaceOrganizationId,
        name,
        ownerName,
        industries,
        startDate,
        endDate,
        instructions,
        sendEmailToInviter,
        templateId
      )
      return
    }

    setCurrentInvitationEditorStepIndex(currentInvitationEditorStepIndex + 1)
    const updatedTempInvitation: TempInvitation = {
      templateId: templateId,
      name: name,
      ownerName: ownerName,
      organizationId: workspaceOrganizationId,
      industries: industries,
      instructions: instructions,
      sendEmailToInviter: sendEmailToInviter,
      brandingImage: tempInvitation?.brandingImage,
      existingBrandingImageId: tempInvitation?.existingBrandingImageId,
      invitationSuccessPage: tempInvitation?.invitationSuccessPage,
      videoCategoryTemplates: tempInvitation?.videoCategoryTemplates,
    }

    if (startDate) {
      updatedTempInvitation.startDate = new Date(startDate)
    }

    if (endDate) {
      updatedTempInvitation.endDate = new Date(endDate)
    }

    setTempInvitation(updatedTempInvitation)
    if (stepIndex.index !== index) {
      setCurrentInvitationEditorStepIndex(stepIndex.index)
    } else {
      setCurrentInvitationEditorStepIndex(index + 1)
    }
  }

  const onFormSubmit = handleSubmit(
    async ({ name, ownerName, industries, startDate, endDate, instructions, sendEmailToInviter }) => {
      setSubmitting(true)
      await onSubmit(
        name,
        ownerName,
        industries,
        transformStartDate(startDate),
        transformEndDate(endDate),
        instructions,
        sendEmailToInviter
      )

      if (mountedRef.current) {
        setSubmitting(false)
      }
    }
  )

  useEffect(() => {
    setStepIndex({ index: currentInvitationEditorStepIndex })
    const loadIndustryOptions = async () => setIndustryOptions(await configService.getFieldSetOptions('industry'))
    loadIndustryOptions()

    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    setHideForm(currentInvitationEditorStepIndex !== index)
  }, [currentInvitationEditorStepIndex])

  useEffect(() => {
    if (!defaultValues) {
      return
    }

    setValue([
      {
        name: defaultValues.name,
      },
      {
        ownerName: defaultValues.ownerName,
      },
      { industries: defaultValues.industries },
      { startDate: reverseTransformStartDate(defaultValues.startDate) },
      { endDate: reverseTransformEndDate(defaultValues.endDate) },
      { instructions: defaultValues.instructions },
      { sendEmailToInviter: defaultValues.sendEmailToInviter },
    ])
  }, [defaultValues, currentInvitationEditorStepIndex])

  useEffect(() => {
    if (stepIndex.index !== currentInvitationEditorStepIndex) {
      onFormSubmit()
    }
  }, [stepIndex])

  return (
    <>
      <SuccessDialog
        isShowing={isSaveConfirmationDialogShowing}
        text="Changes saved"
        setShowing={setSaveConfirmationDialogShowing}
      />
      {!hideForm && (
        <div className={'invitation-form'}>
          {showEnterpriseFeatures !== undefined && (
            <InvitationEditorStepper
              steps={steps}
              onClick={setStepIndex}
              showEnterpriseFeatures={showEnterpriseFeatures}
            />
          )}
          <h1>Invitation Details</h1>
          <FormInput
            name="name"
            label="Invitation title* (Character limit: 100)"
            maxLength={100}
            placeholder="e.g. Application for General Manager Role"
            inputRef={register}
            errors={errors}
            spellCheck={false}
          />
          <FormInput
            name="ownerName"
            label="Your workspace / business name* (Character limit: 100)"
            maxLength={100}
            placeholder="e.g. Acme Co."
            inputRef={register}
            errors={errors}
            spellCheck={false}
          />
          <div className="date-inputs">
            <div className="date-input-row">
              <Controller
                name="startDate"
                placeholder="Select a date"
                control={control}
                errors={errors}
                setValue={setValue}
                as={
                  <FormDatePicker
                    label="Opening date (invitation opens at the start of the selected day)"
                    fromMonth={today()}
                  />
                }
              />
            </div>
            <div className="date-input-row">
              <Controller
                name="endDate"
                placeholder="Select a date"
                control={control}
                errors={errors}
                setValue={setValue}
                as={
                  <FormDatePicker
                    label="Closing date (invitation closes at the end of the selected day)"
                    fromMonth={isEditing ? yesterdayMidday() : tomorrow()}
                  />
                }
              />
            </div>
          </div>
          <Controller
            name="industries"
            control={control}
            as={
              <FormMultiSelect label="Relevant industries" placeholder="Select industries" options={industryOptions} />
            }
          />
          <FormTextArea
            name="instructions"
            label="Instructions"
            textAreaRef={register}
            placeholder='Tell the applicant what you want to see here. e.g. "Please complete the personal details section, include a short 30-second video &#39;introducing yourself&#39; and upload your CV."'
            noLimit
          />
          {!showEnterpriseFeatures && (
            <div className="upgrade-box">
              <h3 className="title">Want to maximise your Invitation features?</h3>
              <UpgradeToEnterpriseDialog
                isShowing={isUpgradeToEnterpriseDialog}
                onClose={() => setUpgradeToEnterpriseDialog(false)}
                newProduct={newProduct}
                newSubscription={newSubscription}
                changePlan={changePlan}
                showEnterpriseFeatures={showEnterpriseFeatures}
                setShowEnterpriseFeatures={setShowEnterpriseFeatures}
              />
              <InvitationLearnMoreDialog
                isShowing={isInvitationLearnMoreDialogIsShowing}
                onClose={() => setInvitationLearnMoreDialogIsShowing(false)}
                newProduct={newProduct}
                newSubscription={newSubscription}
                changePlan={changePlan}
                setShowEnterpriseFeatures={setShowEnterpriseFeatures}
              />
              <p className="description">
                Upgrading to Enterprise gives you useful extra features when creating Invitations, including the ability
                to:
              </p>
              <ul>
                <li>Add your own branding</li>
                <li>Create a personalised success page</li>
                <li>Request and receive tailored video chapters from applicants</li>
              </ul>
              <div className={'bottom-buttons'}>
                <button
                  type="button"
                  className="primary upgrade-button"
                  onClick={() => setUpgradeToEnterpriseDialog(true)}
                >
                  Upgrade Now
                </button>
                <p className="learn-more-button" onClick={() => setInvitationLearnMoreDialogIsShowing(true)}>
                  Learn more
                </p>
              </div>
            </div>
          )}
          <Controller
            name="sendEmailToInviter"
            control={control}
            as={<FormCheckbox label="Send email when Previews are published" inputRef={register} />}
          />
          {dateRangeError && <div className="date-range-error">{dateRangeError}</div>}
          <div className="buttons">
            {cancelText && (
              <button type="button" className="cancel-button link" onClick={onCancel}>
                {cancelText}
              </button>
            )}
            <LoadingButton
              className="submit-button primary"
              isLoading={isSubmitting}
              onClick={() => setStepIndex({ index: index + 1 })}
            >
              {submitText}
            </LoadingButton>
          </div>
        </div>
      )}
    </>
  )
}

export default InvitationForm
