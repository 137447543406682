import { Status } from 'helpers/enum'
import { useEffect, useState } from 'react'
import * as productService from 'services/productService'
import { Product } from './types'
import { da } from 'date-fns/locale'

const useProduct = (retreiveAllProductsOnLoad: boolean) => {
  const [allProducts, setAllProducts] = useState<Product[]>()

  useEffect(() => {
    const retreiveAllActiveProducts = async () => {
      const { ok, data } = await productService.retreiveAllProducts(Status.Active);
      if (ok && data) {
        setAllProducts(data);
      } else {
        console.error("Error fetching data or data is undefined");
      }
    };

    if (retreiveAllProductsOnLoad) {
      retreiveAllActiveProducts();
    }
  }, [retreiveAllProductsOnLoad]);

  return {
    allProducts,
  }
}

export default useProduct
