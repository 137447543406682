import React, { useRef, useState, useEffect } from 'react'
import VideoPlayer, { Props } from 'components/video/VideoPlayer/VideoPlayer'
import VideoLayoutContainer from 'components/video/VideoLayoutContainer/VideoLayoutContainer'
import { useIntersectionObserver } from 'helpers/intersectionObserver'

const LazyVideoPlayer: React.FC<Props> = ({ ...videoPlayerProps }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { intersects } = useIntersectionObserver(ref)
  const [intersectedOnce, setIntersectedOnce] = useState(false)

  useEffect(() => {
    if (intersects) {
      setIntersectedOnce(true)
    }
  }, [intersects])

  // Until there is an intersection just render an empty VideoLayoutContainer, which will show a black
  // rectangle with the same size as the VideoPlayer.
  return (
    <div className="lazy-video-container" ref={ref}>
      {!intersectedOnce ? <VideoLayoutContainer className="video-player" /> : <VideoPlayer {...videoPlayerProps} />}
    </div>
  )
}

export default LazyVideoPlayer
