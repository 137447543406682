import React from 'react'
import ShareOnLinkedInIcon from 'assets/icons/ShareOnLinkedInIcon'
import ShareOnTwitterIcon from 'assets/icons/ShareOnTwitterIcon'
import ShareOnFacebookIcon from 'assets/icons/ShareOnFacebookIcon'
import ShareWithEmailIcon from 'assets/icons/ShareWithEmailIcon'
import './SharingLinks.scss'

type Props = {
  url: string
  emailBody: string
}

export const linkedinBase = 'https://www.linkedin.com/shareArticle?mini=true'
export const twitterBase = 'https://twitter.com/intent/tweet'
export const facebookBase = 'https://www.facebook.com/sharer.php'

const SharingLinks: React.FC<Props> = ({ url, emailBody }) => (
  <div className="sharing-links">
    {/* I didn't add title, summary, and source params to the URL here because they seem to be ignored by LinkedIn: */}
    <a className="plain linkedin" href={`${linkedinBase}&url=${url}`} target="_blank" rel="noopener noreferrer">
      <ShareOnLinkedInIcon />
    </a>
    <a
      className="plain twitter"
      href={`${twitterBase}?url=${url}&hashtags=previewme`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ShareOnTwitterIcon />
    </a>
    <a className="plain facebook" href={`${facebookBase}?u=${url}`} target="_blank" rel="noopener noreferrer">
      <ShareOnFacebookIcon />
    </a>
    <a className="plain email" href={`mailto:?body=${emailBody}`} target="_blank" rel="noopener noreferrer">
      <ShareWithEmailIcon />
    </a>
  </div>
)

export default SharingLinks
