import React, { useRef, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Option, TagOption } from 'state/types'
import useOutsideClick from 'helpers/useOutsideClick'
import useDocumentKeyPress from 'helpers/useDocumentKeyPress'
import './DropdownBase.scss'

type Props = {
  id?: string
  className?: string
  isPopupShowing: boolean
  selectedIndex: number
  options?: TagOption[]
  noOptionsText?: string
  rootElementProps?: React.HTMLAttributes<HTMLDivElement>
  setPopupShowing: (isPopupShowing: boolean) => void
  setSelectedIndex: (selectedIndex: number) => void
  onOptionSelected: (value: string, type: 'mouse' | 'key') => void
  renderOption?: (value?: string, displayValue?: string) => React.ReactNode | null
  onPopupShowing?: () => void
}

const defaultMaxHeight = 250
const minHeight = 150
const buffer = 20

const DropdownBase: React.FC<Props> = ({
  id,
  className,
  isPopupShowing,
  selectedIndex,
  options,
  noOptionsText,
  rootElementProps,
  children,
  setPopupShowing,
  setSelectedIndex,
  onOptionSelected,
  renderOption,
  onPopupShowing,
}) => {
  const [dropUp, setDropUp] = useState(false)
  const [maxHeight, setMaxHeight] = useState(-1)
  const rootRef = useRef<HTMLDivElement>(null)
  const optionCount = options ? options.length : 0

  const onOptionClick = (selectedValue: string) => onOptionSelected?.(selectedValue, 'mouse')

  useOutsideClick(rootRef, () => setPopupShowing(false))

  useDocumentKeyPress((key, code, e) => {
    const focusWithin = rootRef.current?.contains(document.activeElement)
    if (key === 'Enter' || code === 13) {
      if (focusWithin) {
        e.preventDefault()
      }
      if (isPopupShowing && options?.[selectedIndex]) {
        onOptionSelected?.(options[selectedIndex].id, 'key')
      } else if (!isPopupShowing && focusWithin) {
        setPopupShowing(true)
      }
    } else if ((key === 'Escape' || code === 27) && isPopupShowing) {
      setPopupShowing(false)
    } else if (key === 'ArrowDown' || code === 40) {
      if (isPopupShowing) {
        setSelectedIndex(Math.min(optionCount - 1, Math.max(0, selectedIndex + 1)))
        e.preventDefault()
      } else if (focusWithin) {
        setSelectedIndex(0)
        setPopupShowing(true)
        e.preventDefault()
      }
    } else if (key === 'ArrowUp' || code === 38) {
      if (isPopupShowing) {
        setSelectedIndex(Math.min(optionCount - 1, Math.max(0, selectedIndex - 1)))
        e.preventDefault()
      } else if (focusWithin) {
        e.preventDefault()
      }
    }
  })

  useEffect(() => {
    if (!isPopupShowing) {
      setSelectedIndex(-1)
      setMaxHeight(-1)
    }
  }, [isPopupShowing, setSelectedIndex])

  useEffect(() => {
    rootRef?.current?.querySelector('.option.selected')?.scrollIntoView({ block: 'nearest', inline: 'nearest' })
  }, [selectedIndex])

  useEffect(() => {
    if (selectedIndex > optionCount - 1) {
      setSelectedIndex(optionCount - 1)
    }
  }, [selectedIndex, optionCount, setSelectedIndex])

  useEffect(() => {
    if (isPopupShowing && rootRef.current) {
      const rootBounds = rootRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rootBounds.bottom
      if (spaceBelow < defaultMaxHeight + buffer && spaceBelow >= minHeight + buffer) {
        setMaxHeight(spaceBelow - buffer)
      } else {
        setMaxHeight(-1)
      }
      setDropUp(spaceBelow < minHeight + buffer)
      onPopupShowing?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupShowing])

  return (
    <div
      id={id}
      role="listbox"
      className={
        'dropdown-base' +
        (className ? ' ' + className : '') +
        (isPopupShowing ? ' popup-showing' : '') +
        (dropUp ? ' drop-up' : '')
      }
      ref={rootRef}
      {...rootElementProps}
    >
      {children}
      <CSSTransition in={isPopupShowing} timeout={150} unmountOnExit>
        <div className="popup-anchor">
          <div className="popup">
            <div className="popup-content" style={{ maxHeight: maxHeight > 0 ? maxHeight + 'px' : undefined }}>
              <div className="options">
                {options?.map(({ id, name }, i) => (
                  <button
                    key={id}
                    type="button"
                    className={'option plain' + (i === selectedIndex ? ' selected' : '')}
                    onClick={() => onOptionClick(id)}
                    onMouseDown={e => e.preventDefault()}
                    tabIndex={-1}
                  >
                    {renderOption ? renderOption(id, name) : <span>{name}</span>}
                  </button>
                ))}
                {(!options || options.length === 0) && (
                  <div className="no-options-text">{noOptionsText || 'No options available'}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default DropdownBase
