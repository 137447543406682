import React from 'react'
import './RecordButton.scss'

type Props = {
  recording: boolean
  disabled?: boolean
  onClick: () => void
}

const RecordButton: React.FC<Props> = ({ recording, disabled, onClick }) => (
  <button
    type="button"
    className={'record-button' + (disabled ? ' disabled' : '') + (recording ? ' recording' : '')}
    disabled={disabled}
    onClick={onClick}
  >
    <div className="ring">
      <div className="center" />
    </div>
  </button>
)

export default RecordButton
