import React from 'react'
import InfoPopup from 'components/shared/InfoPopup/InfoPopup'
import { DocumentAnalytics } from 'state/types'
import './DocumentDownloadsListMobile.scss'

type Props = {
  data: DocumentAnalytics[]
}

const DocumentDownloadsListMobile: React.FC<Props> = ({ data }) => (
  <div className="document-downloads-list-mobile">
    {data.map(({ name, url, uniqueViews, totalViews }, i) => (
      <div key={i} className="document-downloads-card">
        <div className="name-row">
          <a className="document-name" href={url} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
          <div className="document-type">{url?.substring(url.lastIndexOf('.') + 1).toUpperCase()}</div>
        </div>
        <div className="data-row">
          <div className="label">
            <span>By unique user</span>
            <InfoPopup>
              The number of downloads by distinct individuals, regardless of how often they downloaded.
            </InfoPopup>
          </div>
          <div className="value">{uniqueViews}</div>
        </div>
        <div className="data-row">
          <div className="label">Total</div>
          <div className="value">{totalViews}</div>
        </div>
      </div>
    ))}
  </div>
)

export default DocumentDownloadsListMobile
