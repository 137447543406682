import React, { useEffect, useRef, useState } from 'react';
import { isVideoPlaying, stopAllOtherVideos } from 'helpers/media';
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner';
import VideoLayoutContainer from 'components/video/VideoLayoutContainer/VideoLayoutContainer';
import PlayPauseButtonOverlay from 'components/video/PlayPauseButtonOverlay/PlayPauseButtonOverlay';
import VideoControls from '../VideoControls/VideoControls';
import './VideoPlayerPublished.scss';
import { createFullLinkConvert } from '../../../helpers/links'
import { SubscriptionPlan } from '../../../helpers/enum'
import useStore from '../../../state/useStore'
import AudioOnlyProfileImage from '../Audio/AudioOnlyProfileImage/AudioOnlyProfileImage'

export type Props = {
  src?: string;
  videoSuiteSelected?:any
  nativeControls?: boolean;
  customControls?: boolean;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  hidePlayPauseButton?: boolean;
  hideCta?:boolean;
  noAutoLoad?: boolean;
  onEnded?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onFullScreenChange: (isFullScreen: boolean) => void;
  selectedChapterWidth?:number;
  onDurationChange?: (duration: number) => void;
  onPlayClickAtEndOfVideo?: () => boolean;
  audioOnly?: boolean;
  profileImageStorageId?: string;
  responseData: {
    watermark: boolean;
    cta: boolean;
    videoFx: boolean;
    images: boolean;
    captions: boolean;
  };
  showLayeredAudioVisuals: boolean;
  currentPlan: string;
};

const VideoPlayerPublished: React.FC<Props> = ({hideCta,videoSuiteSelected, onFullScreenChange, selectedChapterWidth, responseData, src, nativeControls, customControls, videoRef, hidePlayPauseButton, noAutoLoad, onEnded, onPlay, onPause, onDurationChange, onPlayClickAtEndOfVideo, audioOnly, profileImageStorageId, showLayeredAudioVisuals, currentPlan }) => {
  const [canPlay, setCanPlay] = useState(false);
  const [revealed, setRevealed] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const mountedRef = useRef(true);
  const defaultRef = useRef<HTMLVideoElement | null>(null);
  const videoRefToUse = videoRef || defaultRef;
  const [fontSize, setFontSize] = useState('28px');
  const [buttonTextSize, setButtonTextSize] = useState('16px');
  const [textLineheight, setTextLineheight] = useState('24px');
  const [buttonMinWidth, setButtonMinWidth] = useState('186px');
  const [buttonTextHeight, setButtonTextHeight] = useState('40px');
  const [buttonMarginTop, setButtonMarginTop] = useState('5px');

  const [videoWidthItem, setVideoWidthItem] = useState(0);
  const [videoHeightSize, setVideoHeightSize] = useState("");

  // Calls Action Start
  const [isFullScreen, setIsFullScreen] = useState(false);
  let ctaSelected: any[] = [];

  if (videoSuiteSelected?.cta) {
    try {
      ctaSelected = JSON.parse(videoSuiteSelected.cta);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }
  const [contentX, setContentX] = useState(parseInt(ctaSelected[0]?.positionX, 10) || 0);
  const [contentY, setContentY] = useState(parseInt(ctaSelected[0]?.positionY, 10) || 0);
  const [hideCtaItem, setHideCtaItem] = useState(false);
  const { state } = useStore()
  const isOnBasicPlan = currentPlan === SubscriptionPlan.Basic
  const [positionX, setPositionX] = useState<number>(contentX);
  const [positionY, setPositionY] = useState<number>(contentY);
  const [previousVideoWidth] = useState( ctaSelected[0]?.previousVideoWidth || 0);
  const [previousVideoHeight] = useState( ctaSelected[0]?.previousVideoHeight || 0);

  const calculateAdjustedFontSize = (
    initialSize: number,
    videoWidthParam: number,
    videoHeightParam: number,
    fullWidth: number,
    fullHeight: number
  ) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    if (fullWidth > viewportWidth) {
      fullWidth = viewportWidth;
    }
    if (fullHeight > viewportHeight) {
      fullHeight = viewportHeight;
    }

    const isPortrait = videoHeightParam > videoWidthParam;

    const widthRatio = isPortrait ? fullHeight / videoHeightParam : fullWidth / videoWidthParam;
    const heightRatio = isPortrait ? fullWidth / videoWidthParam : fullHeight / videoHeightParam;

    const largerDimensionPercentage = Math.max(heightRatio, widthRatio);
    const adjustedFontSize = `${initialSize * largerDimensionPercentage}px`;

    return adjustedFontSize;
  };

  useEffect(() => {
    async function getVideoSize() {

      const videoElement = document.getElementById('mainVideo');
      const videoWidthData = videoElement?.offsetWidth;
      const videoHeightData = videoElement?.offsetHeight;

      if (videoWidthData !== 650 && videoHeightData !== 488) {
        setHideCtaItem(true)
        const { adjustedWidth, adjustedHeight } = adjustVideoSizeToScreen();
        const videoWidthData = adjustedWidth;
        const videoHeightData = adjustedHeight;
        setVideoWidthItem(videoWidthData || 0);
        const adjustedPositionX = calculateResponsiveness(parseInt(ctaSelected[0]?.positionX, 10), previousVideoWidth, previousVideoHeight, videoWidthData || 0, videoHeightData || 0);
        const adjustedPositionY = calculateResponsiveness(parseInt(ctaSelected[0]?.positionY, 10), previousVideoWidth, previousVideoHeight, videoWidthData || 0, videoHeightData || 0);
        const adjustedFontSize = calculateAdjustedFontSize(28, 650, 488, videoWidthData || 0, videoHeightData || 0);
        const adjustedButtonMarginTop = calculateAdjustedFontSize(5, 650, 488, videoWidthData || 0, videoHeightData || 0);
        const adjustedFontSizeButton = calculateAdjustedFontSize(16, 650, 488, videoWidthData || 0, videoHeightData || 0);
        const adjustedButtonHeight = calculateAdjustedFontSize(40, 650, 488, videoWidthData || 0, videoHeightData || 0);
        const adjustedTextLineheight = calculateAdjustedFontSize(24, 650, 488, videoWidthData || 0, videoHeightData || 0);
        const adjustedButtonMinWidth = calculateAdjustedFontSize(186, 650, 488, videoWidthData || 0, videoHeightData || 0);
        setFontSize(adjustedFontSize);
        setButtonMinWidth(adjustedButtonMinWidth);
        setButtonTextSize(adjustedFontSizeButton);
        setTextLineheight(adjustedTextLineheight);
        setButtonTextHeight(adjustedButtonHeight);
        setContentX(adjustedPositionX);
        setContentY(adjustedPositionY);
        setButtonMarginTop(adjustedButtonMarginTop)
      } else {
        setHideCtaItem(true)
      }
    }

    getVideoSize();
  }, [isFullScreen]);

  useEffect(() => {
    const handleResize = () => {
      if (!isFullScreen) {

        const videoElement = videoRefToUse.current;
        let videoWidth = 0;
        let videoHeight = 0;

        if (videoElement) {
          videoWidth = videoElement.offsetWidth;
          videoHeight = videoElement.offsetHeight;
        }

        if (videoElement) {
          videoWidth = videoElement.offsetWidth;
          videoHeight = videoElement.offsetHeight;
        }

        let videoWidthInitial = 650;
        let videoHeightInitial = 488;
        const adjustedSize = calculateResponsiveness(28, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
        const adjustedButtonTextSize = calculateResponsiveness(16, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
        const adjustedButtonMinWidth = calculateResponsiveness(186, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
        const adjustedButtonHeight = calculateResponsiveness(40, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
        const adjustedButtonMarginTop = calculateResponsiveness(5, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);
        const adjustedPositionX = calculateResponsiveness(parseInt(ctaSelected[0]?.positionX, 10), previousVideoWidth, previousVideoHeight, videoWidth, videoHeight);
        const adjustedPositionY = calculateResponsiveness(parseInt(ctaSelected[0]?.positionY, 10), previousVideoWidth, previousVideoHeight, videoWidth, videoHeight);
        const adjustedTextLineheight = calculateResponsiveness(24, videoWidthInitial, videoHeightInitial, videoWidth, videoHeight);

        setFontSize(adjustedSize + 'px');
        setPositionX(adjustedPositionX);
        setPositionY(adjustedPositionY);
        setTextLineheight(adjustedTextLineheight + 'px')
        setButtonTextSize(adjustedButtonTextSize + 'px');
        setButtonMinWidth(adjustedButtonMinWidth + 'px');
        setButtonMarginTop(adjustedButtonMarginTop + 'px');
        setButtonTextHeight(adjustedButtonHeight + 'px');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const adjustVideoSizeToScreen= () => {
    const body = document.body;

    const videoWidthInitial = 650;
    const videoHeightInitial = 488;

    const screenWidth = body.offsetWidth;
    const screenHeight = body.offsetHeight;

    const aspectRatio = videoWidthInitial / videoHeightInitial;

    let adjustedWidth, adjustedHeight;

    adjustedWidth = screenWidth;
    adjustedHeight = adjustedWidth / aspectRatio;

    if (adjustedHeight > screenHeight) {
      adjustedHeight = screenHeight;
      adjustedWidth = adjustedHeight * aspectRatio;
    }

    return {
      adjustedWidth,
      adjustedHeight
    };
  }



  const toggleFullScreen = async () => {

    let videoWidthInitial = 650;
    let videoHeightInitial = 488;
    if (isFullScreen) {
      onFullScreenChange(false);
      setIsFullScreen(false);
    } else {
      onFullScreenChange(true);
      setIsFullScreen(true);
      await new Promise((resolve) => setTimeout(resolve, 100));
      const { adjustedWidth, adjustedHeight } = adjustVideoSizeToScreen();
      const videoWidthData = adjustedWidth;
      const videoHeightData = adjustedHeight;
      setVideoWidthItem(videoWidthData || 0);
      const adjustedFontSize = calculateAdjustedFontSize(28, videoWidthInitial, videoHeightInitial, videoWidthData || 0, videoHeightData || 0);
      const adjustedButtonMarginTop = calculateAdjustedFontSize(5, videoWidthInitial, videoHeightInitial, videoWidthData || 0, videoHeightData || 0);
      const adjustedFontSizeButton = calculateAdjustedFontSize(16, videoWidthInitial, videoHeightInitial, videoWidthData || 0, videoHeightData || 0);
      const adjustedButtonHeight = calculateAdjustedFontSize(40, videoWidthInitial, videoHeightInitial, videoWidthData || 0, videoHeightData || 0);
      const adjustedTextLineheight = calculateAdjustedFontSize(24, videoWidthInitial, videoHeightInitial, videoWidthData || 0, videoHeightData || 0);
      const adjustedButtonMinWidth = calculateAdjustedFontSize(186, videoWidthInitial, videoHeightInitial, videoWidthData || 0, videoHeightData || 0);
      const adjustedPositionX = calculateResponsiveness(parseInt(ctaSelected[0]?.positionX, 10), previousVideoWidth, previousVideoHeight, videoWidthData || 0, videoHeightData || 0);
      const adjustedPositionY = calculateResponsiveness(parseInt(ctaSelected[0]?.positionY, 10), previousVideoWidth, previousVideoHeight, videoWidthData || 0, videoHeightData || 0);
      setContentX(adjustedPositionX);
      setContentY(adjustedPositionY);
      setFontSize(adjustedFontSize);
      setButtonMinWidth(adjustedButtonMinWidth);
      setButtonTextSize(adjustedFontSizeButton);
      setTextLineheight(adjustedTextLineheight);
      setButtonTextHeight(adjustedButtonHeight);
      setButtonMarginTop(adjustedButtonMarginTop)
    }
  };

// Calls Action End

  const handleDurationChange = () => {
    const duration = videoRefToUse.current?.duration || 0;
    setDuration(duration);
    onDurationChange && onDurationChange(Math.ceil(duration));
  };

  const onTimeUpdate = () => {
    const time = videoRefToUse.current?.currentTime;
    if (customControls && time !== undefined) {
      setCurrentTime(time);
    }
  };

  const togglePlaying = () => {
    const video = videoRefToUse.current;
    if (video) {
      if (!isVideoPlaying(video)) {
        if (video.currentTime >= duration - 0.1) {
          if (onPlayClickAtEndOfVideo) {
            const interrupt = onPlayClickAtEndOfVideo();
            if (interrupt) {
              // E.g. published preview page will unmount this video and play a different one,
              // so we don't need to execute any more logic here.
              return;
            }
          }
          video.currentTime = 0;
        }
        stopAllOtherVideos(video);
        video.play();
        setPlaying(true);
      } else {
        video.pause();
        setPlaying(false);
      }
    }
  };

  const onCurrentTimeChangeRequested = (newTime: number) => {
    const video = videoRefToUse.current;
    if (video) {
      if (isVideoPlaying(video)) {
        video.pause();
        setPlaying(false);
      }
      video.currentTime = newTime;
    }
  };


  useEffect(() => {
    const video = videoRefToUse.current as any;
    if (video) {
      video.disablePictureInPicture = true;
      if (!noAutoLoad) {
        video.load(); // Forces videos to load right away even on mobile.
      }
    }
  }, [src, videoRefToUse, noAutoLoad]);

  useEffect(() => {
    const cleanupFunction = () => {
      const video = videoRefToUse.current;
      if (video) {
        setRevealed(true);
        setCanPlay(false);
      }
    };
    const timeoutId = setTimeout(cleanupFunction, 0);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);


  useEffect(() => {
    const checkFullScreen = () => {
      const isFullScreenNow = !!document.fullscreenElement;
      onFullScreenChange(isFullScreenNow);
      setIsFullScreen(isFullScreenNow);
    };

    document.addEventListener("fullscreenchange", checkFullScreen);

    return () => {
      document.removeEventListener("fullscreenchange", checkFullScreen);
    };
  }, [])

  const parseTimeToFloat = (time: string) => {
    const [minutesPart, secondsPart] = time.split(':');
    const minutes = parseFloat(minutesPart) || 0;
    const seconds = parseFloat(secondsPart) || 0;
    return (minutes * 60) + seconds;
  };

  const calculateResponsiveness = (initialSize: number, videoWidthParam: number, videoHeightParam: number, fullWidth: number, fullHeight: number) => {
    const isPortrait = videoHeightParam > videoWidthParam;
    const widthRatio = isPortrait ? fullHeight / videoHeightParam : fullWidth / videoWidthParam;
    const heightRatio = isPortrait ? fullWidth / videoWidthParam : fullHeight / videoHeightParam;
    return initialSize * Math.min(widthRatio, heightRatio);
  };

  const contentStyle = {
    transform: `translate(${isFullScreen ? contentX : positionX}px, ${isFullScreen ? contentY : positionY}px)`,
  };

  const mainAudioDiv = document.getElementById("mainAudio");
  return (
    <>
      <VideoLayoutContainer className="video-player">
        {audioOnly && showLayeredAudioVisuals && (
          <div style={{ zIndex: '1',display: 'flex', alignItems: 'center', justifyContent: 'center' }} id="mainAudio">
            {mainAudioDiv &&
              <div style={{ width: '54%', height: '74%' }}>
                <AudioOnlyProfileImage cloudinaryId={profileImageStorageId} />
              </div>
            }
          </div>
        )}
        {responseData?.cta && hideCta && hideCtaItem && !isOnBasicPlan && (

          <div className={`${isFullScreen ? 'wrapper-fullscreen' : 'wrapper-drag'}  ${isFullScreen && selectedChapterWidth && selectedChapterWidth > 700 ? 'fullscreen-screen-size' : 'fullscreen-video-size'}`}
               style={{
                 maxWidth: isFullScreen
                   ? `${videoWidthItem}px` : ""
               }}>
            {ctaSelected?.map((cta: any, index: number) => {
              const video = videoRefToUse.current;

              if (video) {
                const parsedFadeIn = cta.fadeIn ? parseTimeToFloat(cta.fadeIn) : 0
                const parsedFadeOut = cta.fadeOut ? parseTimeToFloat(cta.fadeOut) : 0
                const parsedNewStart = cta.newStart ? parseTimeToFloat(cta.newStart) : 0

                const fadeInInSeconds =  parsedFadeIn - parsedNewStart
                const fadeOutInSeconds =  parsedFadeOut - parsedNewStart

                if (video.currentTime >= fadeInInSeconds && video.currentTime < fadeOutInSeconds) {
                  return (
                    <div
                      key={index}
                      className={`${isFullScreen ? 'wrapper-drag fullscreen-container' : ''}  ${isFullScreen && selectedChapterWidth && selectedChapterWidth > 700 ? 'fullscreen-screen-size' : 'fullscreen-video-size'}`}
                      style={{
                        maxWidth: isFullScreen
                          ? `${videoWidthItem}px` : ""
                      }}>

                      <div className="cta-draggable"
                           style={contentStyle}>

                        <div className="trim-container-content" style={{
                          display: isFullScreen ? 'flex': 'inline',
                          minWidth: isFullScreen
                            ? `${videoWidthItem}px` : "500px"
                        }}>
                          <div className="cta-container-item">
                          <div>
                            {cta.textContent && (cta.type === "text" || cta.type === "all") && (
                              <div>
                                {cta.targetLink === "open-new-tab" ? (
                                  <button
                                    className={`button__text ${cta.button ? 'link__cursor' : ''}`}
                                    onClick={() => {
                                      if (cta.linkButton) {
                                        let url = cta.linkButton;
                                        window.open(createFullLinkConvert(url), "_blank");

                                        if (isFullScreen) {
                                          onFullScreenChange(false);
                                          setIsFullScreen(false);
                                        }

                                      }
                                    }}>
                                    <p
                                      style={{
                                        color: cta.textColor,
                                        fontSize:fontSize,
                                        lineHeight:textLineheight,
                                        ...(cta.textDropshadow
                                          ? {
                                            textShadow: `2px 2px 4px ${cta.textDropshadow}`,
                                          }
                                          : {}),
                                      }}
                                      className="text-content"
                                    >  {cta.textContent}
                                    </p>
                                  </button>
                                ) : cta.targetLink === "open-as-popup" ? (
                                  <button
                                    className={`button__text ${cta.button ? 'link__cursor' : ''}`}
                                    onClick={() => {
                                      if (cta.linkButton) {
                                        let url = cta.linkButton;
                                        window.open(createFullLinkConvert(url), "popup", "width=600,height=600");

                                        if (isFullScreen) {
                                          onFullScreenChange(false);
                                          setIsFullScreen(false);
                                        }
                                      }
                                    }}>
                                    <p
                                      style={{
                                        color: cta.textColor,
                                        fontSize:fontSize,
                                        lineHeight:textLineheight,
                                        ...(cta.textDropshadow
                                          ? {
                                            textShadow: `2px 2px 4px ${cta.textDropshadow}`,
                                          }
                                          : {}),
                                      }}
                                      className="text-content"
                                    >  {cta.textContent}
                                    </p>
                                  </button>
                                ) : (
                                  <p
                                    style={{
                                      color: cta.textColor,
                                      fontSize:fontSize,
                                      lineHeight:textLineheight,
                                      ...(cta.textDropshadow
                                        ? {
                                          textShadow: `2px 2px 4px ${cta.textDropshadow}`,
                                        }
                                        : {}),
                                    }}
                                    className="text-content"
                                  >  {cta.textContent}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>


                          <div>
                            {cta.buttonContent && (cta.type === "button" || cta.type === "all") && (
                              <div>
                                {cta.targetLink === "open-new-tab" ? (
                                  <button
                                    onClick={() => {
                                      if (cta.linkButton) {
                                        let url = cta.linkButton;
                                        window.open(createFullLinkConvert(url), "_blank");

                                        if (isFullScreen) {
                                          onFullScreenChange(false);
                                          setIsFullScreen(false);
                                        }

                                      }
                                    }}
                                    className="button-content"
                                    style={{
                                      backgroundColor: cta.buttonColor,
                                      fontSize:buttonTextSize,
                                      height:buttonTextHeight,
                                      minWidth:buttonMinWidth,
                                      marginTop:buttonMarginTop,

                                      ...(cta.buttonDropshadow
                                        ? {
                                          boxShadow: `2px 2px 4px rgba(${parseInt(cta.buttonDropshadow.slice(1, 3), 16)}, ${parseInt(
                                            cta.buttonDropshadow.slice(3, 5),
                                            16
                                          )}, ${parseInt(cta.buttonDropshadow.slice(5, 7), 16)}, 0.5)`,
                                        }
                                        : {}),
                                      color: cta.buttonTextColor,
                                    }}
                                  >
                                    {cta.buttonContent}
                                  </button>
                                ) : cta.targetLink === "open-as-popup" ? (
                                  <button
                                    onClick={() => {
                                      if (cta.linkButton) {
                                        let url = cta.linkButton;
                                        window.open(createFullLinkConvert(url), "popup", "width=600,height=600");
                                        if (isFullScreen) {
                                          onFullScreenChange(false);
                                          setIsFullScreen(false);
                                        }
                                      }
                                    }}
                                    className="button-content"
                                    style={{
                                      backgroundColor: cta.buttonColor,
                                      fontSize:buttonTextSize,
                                      height:buttonTextHeight,
                                      minWidth:buttonMinWidth,
                                      marginTop:buttonMarginTop,
                                      ...(cta.buttonDropshadow
                                        ? {
                                          boxShadow: `2px 2px 4px rgba(${parseInt(cta.buttonDropshadow.slice(1, 3), 16)}, ${parseInt(
                                            cta.buttonDropshadow.slice(3, 5),
                                            16
                                          )}, ${parseInt(cta.buttonDropshadow.slice(5, 7), 16)}, 0.5)`,
                                        }
                                        : {}),
                                      color: cta.buttonTextColor,
                                    }}
                                  >
                                    {cta.buttonContent}
                                  </button>
                                ) : (
                                  <button
                                    className="button-content"
                                    style={{
                                      backgroundColor: cta.buttonColor,
                                      fontSize:buttonTextSize,
                                      height:buttonTextHeight,
                                      minWidth:buttonMinWidth,
                                      marginTop:buttonMarginTop,
                                      ...(cta.buttonDropshadow
                                        ? {
                                          boxShadow: `2px 2px 4px rgba(${parseInt(cta.buttonDropshadow.slice(1, 3), 16)}, ${parseInt(
                                            cta.buttonDropshadow.slice(3, 5),
                                            16
                                          )}, ${parseInt(cta.buttonDropshadow.slice(5, 7), 16)}, 0.5)`,
                                        }
                                        : {}),
                                      color: cta.buttonTextColor,
                                    }}
                                  >
                                    {cta.buttonContent}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>

                        </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }

              }

              return null;
            })}
          </div>
        )}
        <video
          id="mainVideo"
          src={src}
          controlsList="nodownload"
          playsInline
          ref={videoRefToUse}
          controls={nativeControls}
          onDurationChange={handleDurationChange}
          onEnded={() => {
            setPlaying(false);
            onEnded && onEnded();
          }}
          onPlay={() => {
            setPlaying(true);
            onPlay && onPlay();
          }}
          onPause={() => {
            setPlaying(false);
            onPause && onPause();
          }}
          style={{
            width: isFullScreen ? videoWidthItem : '',
            height: isFullScreen ? videoHeightSize : '',
          }}
          onCanPlay={() => setCanPlay(true)}
          onTimeUpdate={onTimeUpdate}
          className={`${revealed ? 'revealed' : ''} ${isFullScreen ? 'video-fullscreen' : ''} ${isFullScreen && selectedChapterWidth && selectedChapterWidth > 700 ? 'fullscreen-screen-size' : 'fullscreen-video-size'}`}
        />

        {!revealed ? (
          <LoadingSpinner container />
        ) : canPlay && !nativeControls && !hidePlayPauseButton && (
          <PlayPauseButtonOverlay isPlaying={isPlaying} togglePlaying={togglePlaying} />
        )}

        {canPlay && !nativeControls && customControls && (
          <VideoControls
            currentTime={currentTime}
            duration={duration}
            onCurrentTimeChangeRequested={onCurrentTimeChangeRequested}
            enterFullscreen={toggleFullScreen}
          />
        )}

      </VideoLayoutContainer>

    </>
  );
};

export default VideoPlayerPublished;
