import apisauce, { ApisauceInstance } from 'apisauce'
import * as config from 'helpers/config'
import { getToken } from 'services/authService'

let api: ApisauceInstance

export const getApi = () => {
  if (!api) {
    api = createApi()
  }
  return api
}

export const getApiWithAuthorization = async () => applyAuthorization(getApi())

export const applyAuthorization = async (api: ApisauceInstance) => {
  const token = await getToken()
  api.setHeader('Authorization', `Bearer ${token}`)
  return api
}

export const createApi = (apiTimeout: number = config.apiTimeout) => {
  const api = apisauce.create({
    baseURL: config.baseUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    timeout: apiTimeout,
  })
  api.axiosInstance.interceptors.response.use(
    response => response,
    error => error
  )
  return api
}
