import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const updateUrlParam = (key: string, value: string | null, history: any, type: 'push' | 'replace' = 'push') => {
  const searchParams = new URLSearchParams(window.location.search)
  if (value) {
    searchParams.append(key, value)
  } else {
    searchParams.delete(key)
  }
  const newSearch = searchParams.toString()
  const newUrl = window.location.pathname + (newSearch ? '?' + newSearch : '')
  if (type === 'push') {
    history.push(newUrl)
  } else {
    if (history.replace) {
      history.replace(newUrl)
    } else if (history.replaceState) {
      history.replaceState(null, null, window.location.origin + newUrl)
    }
  }
}

export const getSearch = (pathNameAndSearch: string) => {
  const queryStart = pathNameAndSearch.indexOf('?')
  if (queryStart === -1) {
    return ''
  } else {
    return pathNameAndSearch.substring(queryStart)
  }
}

export const getUrlParams = (search: string, ...keys: string[]) => {
  const searchParams = new URLSearchParams(search)
  return keys
    .map(key => searchParams.get(key))
    .reduce((result, value, i) => {
      result[keys[i]] = value || ''
      return result
    }, {} as Record<string, string>)
}

export const getUrlParam = (search: string, key: string) => {
  return getUrlParams(search, key)[key]
}

export const addUrlParam = (key: string, value: string, history: any, type?: 'push' | 'replace') => {
  updateUrlParam(key, value, history, type)
}

export const removeUrlParam = (key: string, history: any, type?: 'push' | 'replace') => {
  updateUrlParam(key, null, history, type)
}

export const useUrlParams = (...keys: string[]) => {
  const location = useLocation()
  return getUrlParams(location.search, ...keys)
}

// Passes a URL param to the given callback and then removes the URL param.
export const useUrlParamOnce = (key: string, onParamAvailable: (value: string) => void) => {
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    const urlParams = getUrlParams(location.search, key)
    if (urlParams[key]) {
      onParamAvailable(urlParams[key])
      removeUrlParam(key, history)
    }
  }, [location, history, key, onParamAvailable])
}

export const parseIndexParam = (indexString?: string) => {
  if (indexString) {
    const parsed = parseInt(indexString)
    if (!isNaN(parsed)) {
      return parsed
    }
  }
}
