import * as React from 'react'

const ScreenIcon = () => (
  <svg className="icon" width="22px" height="19px" viewBox="0 0 22 19">
    <g strokeWidth="1.3" fill="none" fillRule="evenodd" transform="translate(1.000000, 1.000000)">
      <line x1="12.5" y1="12.3571429" x2="12.5" y2="16.6428571"></line>
      <line x1="8.5" y1="12.3571429" x2="8.5" y2="16.6428571"></line>
      <line x1="5" y1="16.5" x2="16" y2="16.5"></line>
      <rect x="0" y="0" width="20" height="12"></rect>
    </g>
  </svg>
)

export default ScreenIcon
