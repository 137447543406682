import { AlgoliaResource, WorkspaceType } from 'helpers/enum'
import { getCurrentPlan } from 'helpers/subscription'
import React, { useEffect, useMemo, useState } from 'react'
import { getAlgoliaSearchKey } from 'services/algoliaService'
import useSubscription from 'state/useSubscription'
import useWorkspace from 'state/useWorkspace'
import InvitationDetailPageAlgolia from './InvitationDetailPageAlgolia'
import InvitationDetailPage from './InvitationDetailPage'

const InvitationDetailPageWrapper: React.FC = () => {
  const { organizationsLoaded, currentWorkspace } = useWorkspace(true)
  const { subscription, isPaymentDetailsLoading } = useSubscription(organizationsLoaded)
  const [algoliaSearchApiKey, setAlgoliaSearchApiKey] = useState<string | null>(null)
  const [isGrantedPermission, setIsGrantedPermission] = useState<boolean | null>(false)
  const [isKeyLoaded, setIsKeyLoaded] = useState<boolean>(false)

  const isWorkspaceTypeUser = currentWorkspace?.type === WorkspaceType.User

  useEffect(() => {
    setIsKeyLoaded(false)
    const loadAlgoliaSearchKey = async () => {
      if (!!currentWorkspace?.id) {
        const { data } = await getAlgoliaSearchKey(AlgoliaResource.InvitationResponse, currentWorkspace.id)
        if (data) {
          setIsGrantedPermission(true)
          setIsKeyLoaded(true)
          setAlgoliaSearchApiKey(data)
        }
      }
    }
    loadAlgoliaSearchKey()
  }, [])

  const applyAlgolia = useMemo(() => {
    return (
      !(getCurrentPlan(subscription) === 'Basic' || isWorkspaceTypeUser) &&
      isKeyLoaded &&
      algoliaSearchApiKey !== null &&
      isGrantedPermission
    )
  }, [subscription, isWorkspaceTypeUser, algoliaSearchApiKey, isKeyLoaded, isGrantedPermission])

  return (
    <>
      {subscription && isPaymentDetailsLoading && !applyAlgolia && <InvitationDetailPage />}
      {subscription && isPaymentDetailsLoading && applyAlgolia && algoliaSearchApiKey && (
        <InvitationDetailPage />
        // <InvitationDetailPageAlgolia
        //   algoliaSearchApiKey={algoliaSearchApiKey}
        //   setIsGrantedPermission={setIsGrantedPermission}
        // ></InvitationDetailPageAlgolia>
      )}
    </>
  )
}

export default InvitationDetailPageWrapper
