import React, { useEffect, useRef, useState } from 'react'
import { isVideoPlaying, stopAllOtherVideos } from 'helpers/media'
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner'
import VideoLayoutContainer from 'components/video/VideoLayoutContainer/VideoLayoutContainer'
import PlayPauseButtonOverlay from 'components/video/PlayPauseButtonOverlay/PlayPauseButtonOverlay'
import VideoControls from '../VideoControls/VideoControls'
import './VideoPlayer.scss'
import AudioOnlyProfileImage from '../Audio/AudioOnlyProfileImage/AudioOnlyProfileImage'

export type Props = {
  src?: string
  nativeControls?: boolean
  customControls?: boolean
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>
  hidePlayPauseButton?: boolean
  noAutoLoad?: boolean
  onEnded?: () => void
  onPlay?: () => void
  onPause?: () => void
  onDurationChange?: (duration: number) => void
  onPlayClickAtEndOfVideo?: () => boolean
  audioOnly?: boolean
  profileImageStorageId?: string
}

const VideoPlayer: React.FC<Props> = ({
  src,
  nativeControls,
  customControls,
  videoRef,
  hidePlayPauseButton,
  noAutoLoad,
  onEnded,
  onPlay,
  onPause,
  onDurationChange,
  onPlayClickAtEndOfVideo,
  audioOnly,
  profileImageStorageId,
}) => {
  const [canPlay, setCanPlay] = useState(false)
  const [revealed, setRevealed] = useState(false)
  const [duration, setDuration] = useState(0)
  const [isPlaying, setPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const mountedRef = useRef(true)
  const defaultRef = useRef<HTMLVideoElement | null>(null)
  const videoRefToUse = videoRef || defaultRef

  const handleDurationChange = () => {
    const duration = videoRefToUse.current?.duration || 0
    setDuration(duration)
    onDurationChange && onDurationChange(duration)
  }

  const onTimeUpdate = () => {
    const time = videoRefToUse.current?.currentTime
    if (customControls && time !== undefined) {
      setCurrentTime(time)
    }
  }

  const togglePlaying = () => {
    const video = videoRefToUse.current
    if (video) {
      if (!isVideoPlaying(video)) {
        if (video.currentTime >= duration - 0.1) {
          if (onPlayClickAtEndOfVideo) {
            const interrupt = onPlayClickAtEndOfVideo()
            if (interrupt) {
              // E.g. published preview page will unmount this video and play a different one,
              // so we don't need to execute any more logic here.
              return
            }
          }
          video.currentTime = 0
        }
        stopAllOtherVideos(video)
        video.play()
        setPlaying(true)
      } else {
        video.pause()
        setPlaying(false)
      }
    }
  }

  const onCurrentTimeChangeRequested = (newTime: number) => {
    const video = videoRefToUse.current
    if (video) {
      if (isVideoPlaying(video)) {
        video.pause()
        setPlaying(false)
      }
      video.currentTime = newTime
    }
  }

  const enterFullscreen = () => videoRefToUse.current?.requestFullscreen()

  useEffect(() => {
    const video = videoRefToUse.current as any
    if (video) {
      video.disablePictureInPicture = true // React doesn't recognize this attribute so we set it in JS.
      if (!noAutoLoad) {
        video.load() // Forces videos to load right away even on mobile.
      }
    }
  }, [src, videoRefToUse, noAutoLoad])

  useEffect(() => {
    if (mountedRef.current) {
      setTimeout(() => setRevealed(true), 1)
    }
    return () => {
      mountedRef.current = false
    }
  }, [])

  return (
    <VideoLayoutContainer className="video-player">
      {audioOnly && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '60%', height: '80%' }}>
            <AudioOnlyProfileImage cloudinaryId={profileImageStorageId} />
          </div>
        </div>
      )}
      <video
        src={src}
        hidden={audioOnly}
        controlsList="nodownload"
        playsInline
        ref={videoRefToUse}
        controls={nativeControls}
        onDurationChange={handleDurationChange}
        onEnded={() => {
          setPlaying(false)
          onEnded && onEnded()
        }}
        onPlay={() => {
          setPlaying(true)
          onPlay && onPlay()
        }}
        onPause={() => {
          setPlaying(false)
          onPause && onPause()
        }}
        onCanPlay={() => setCanPlay(true)}
        onTimeUpdate={onTimeUpdate}
        className={revealed ? 'revealed' : undefined}
      />
      {!canPlay && !nativeControls && src ? (
        <LoadingSpinner container />
      ) : canPlay && !nativeControls && !hidePlayPauseButton ? (
        <PlayPauseButtonOverlay isPlaying={isPlaying} togglePlaying={togglePlaying} />
      ) : null}
      {canPlay && !nativeControls && customControls && (
        <VideoControls
          currentTime={currentTime}
          duration={duration}
          onCurrentTimeChangeRequested={onCurrentTimeChangeRequested}
          enterFullscreen={enterFullscreen}
        />
      )}
    </VideoLayoutContainer>
  )
}

export default VideoPlayer
